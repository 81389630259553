import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';

// Lock task queue automation
export const lockTaskQueueAutomation = domain.createEffect<ID, TaskRef>({
    name: 'lockTaskQueueAutomation',
    handler: async (taskId) => {
        return await repository.task().lockTaskQueueAutomation(taskId);
    },
});