import { $client, $clientPaymentAccount, $computedLicence, $quotaUsage } from './states';
import { fetchClient, fetchClientPaymentAccount, fetchComputedLicence, fetchQuotaUsage } from './effects';
import '../pages/ClientBilling/store/init';
import '../pages/ClientBrandProfiles/store/init';
import '../pages/ClientTasks/store/init';
import '../pages/ClientNotes/store/init';
import '../pages/ClientNotifications/store/init';
import '../pages/ClientCredits/store/init';
import '../pages/ClientTaskFeedback/store/init';
import '../pages/ClientLicences/store/init';

// Fetch client
$client.on(fetchClient.doneData, (_, data) => data);

// Fetch client payment account
$clientPaymentAccount.on(fetchClientPaymentAccount.doneData, (_, data) => data);

// Fetch computed licence
$computedLicence.on(fetchComputedLicence.doneData, (_, data) => data);

// Fetch quota usage
$quotaUsage.on(fetchQuotaUsage.doneData, (_, data) => data);
