import styled from 'styled-components';

export const StyledPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    width: 100%;
    overflow-x: auto;
    padding: 0 2rem;
`;
