import repository from 'dy-frontend-http-repository/lib/repository';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskMessageTemplateCategoryFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/inputs';
import { TaskMessageTemplateCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import {
    HydratedTaskMessageTemplateFilterInput,
    TaskMessageTemplateFilterInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/inputs';
import {
    HydratedTaskMessageTemplateResource,
    TaskMessageTemplateListItemResource,
    TaskMessageTemplateResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import domain from './domain';

// Fetch task message template categories
export const fetchTaskMessageTemplateCategories = domain.createEffect<
    { filter?: TaskMessageTemplateCategoryFilterInput },
    CollectionResource<TaskMessageTemplateCategoryListItemResource, {}>
>({
    name: 'fetchTaskMessageTemplateCategories',
    handler: async ({ filter }) => {
        return await repository.taskMessageTemplateCategory().get(filter);
    },
});

// Fetch task message templates
export const fetchTaskMessageTemplates = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: TaskMessageTemplateFilterInput },
    CollectionResource<TaskMessageTemplateListItemResource, {}>
>({
    name: 'fetchTaskMessageTemplates',
    handler: async ({ pagination, filter }) => {
        return await repository.taskMessageTemplate().get(pagination, filter);
    },
});

// Fetch single task message template information
export const fetchHydratedTaskMessageTemplate = domain.createEffect<
    { taskMessageTemplateId: ID; filter: HydratedTaskMessageTemplateFilterInput },
    HydratedTaskMessageTemplateResource
>({
    name: 'fetchHydratedTaskMessageTemplate',
    handler: async ({ taskMessageTemplateId, filter }) => {
        return await repository.taskMessageTemplate().getHydrated(taskMessageTemplateId, filter);
    },
});
