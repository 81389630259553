import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { $client } from '../../store/states';

const ClientStatistics: React.FC = () => {
    const { changeTitle } = usePageTitle('Client Statistics');

    const client = useStore($client);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Statistics - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    return <div>ClientStatistics</div>;
};

export default ClientStatistics;
