import repository from 'dy-frontend-http-repository/lib/repository';
import { AuthSessionResource, AuthSchemaResource } from 'dy-frontend-http-repository/lib/modules/Auth/resources';
import {
    AuthSchemaFilterInput,
    AuthenticateWithEmailAndPasswordInput,
} from 'dy-frontend-http-repository/lib/modules/Auth/inputs';
import domain from './domain';

// Fetch email schema
export const fetchEmailSchema = domain.createEffect<AuthSchemaFilterInput, AuthSchemaResource>({
    name: 'fetchEmailSchema',
    handler: async (filter) => {
        return await repository.auth().getEmailSchema(filter);
    },
});

// Authenticate using email and password pair
export const authenticateViaEmailAndPassword = domain.createEffect<
    AuthenticateWithEmailAndPasswordInput,
    AuthSessionResource
>({
    name: 'authenticateViaEmailAndPassword',
    handler: async (input) => {
        return await repository.auth().authenticateWithEmailAndPassword(input);
    },
});
