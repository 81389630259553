import ConfirmationPopover from '@app/components/ConfirmationPopover';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { openModal } from '@app/containers/modals/store/events';
import { $clientPaymentAccount } from '@app/containers/pages/Client/store/states';
import { Button, Card, Classes, Icon, Intent, Spinner } from '@blueprintjs/core';
import { TaxIdResource } from 'dy-frontend-http-repository/lib/modules/TaxId/resources';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { CountryFlagUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { taxIdsApi } from '../../store/apis';
import { fetchTaxIds, removeTaxId } from '../../store/effects';
import { resetTaxIds } from '../../store/events';
import { $taxIds } from '../../store/states';
import Image from '@components/Image';
import CreateTaxIdModal, { CreateTaxIdModalProps } from '../../modals/CreateTaxIdModal';
import { $permissions } from '@containers/store/states';
import { TaxIdPermission } from 'dy-frontend-permissions/lib/permission';
import NonIdealState from '@app/components/NonIdealState';

type Props = HTMLAttributes<HTMLDivElement>;

const TaxIds: React.FC<Props> = ({ ...props }) => {
    const permissions = useStore($permissions);

    const taxIds = useStore($taxIds);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    const [removingTaxId, setRemovingTaxId] = useState<ID | null>(null);

    useEffect(() => {
        if (!clientPaymentAccount) {
            return;
        }

        fetchTaxIds({ payment_account_id: clientPaymentAccount.id }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, [clientPaymentAccount?.id]);

    useEffect(() => {
        return () => {
            resetTaxIds();
        };
    }, []);

    if (!clientPaymentAccount || !taxIds) {
        // Payment account or tax ids were NOT fetched yet or any resulted in error
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const handleRemoveTaxId = async (taxId: ID) => {
        try {
            setRemovingTaxId(taxId);
            await removeTaxId(taxId);
            taxIdsApi.remove(taxId);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingTaxId(null);
        }
    };

    const renderHeader = () => {
        const handleAddTaxId = () => {
            openModal<CreateTaxIdModalProps>({
                ModalComponent: CreateTaxIdModal,
                data: {
                    paymentAccountId: clientPaymentAccount.id,
                },
            });
        };

        const renderAddButton = () => {
            const isAllowed = permissions.isRoot.taxId || permissions.has(TaxIdPermission.CREATE);
            if (!isAllowed) {
                return null;
            }

            return (
                <Button intent={Intent.PRIMARY} onClick={handleAddTaxId}>
                    Add
                </Button>
            );
        };

        return (
            <Flex direction="row" align="center" justify="space-between">
                <Heading type="h3">Tax IDs</Heading>
                {renderAddButton()}
            </Flex>
        );
    };

    const renderTaxId = (taxId: TaxIdResource) => {
        const isRemovingCurrentTaxId = removingTaxId === taxId.id;

        const renderRemoveButton = () => {
            const isAllowed = permissions.isRoot.taxId || permissions.has(TaxIdPermission.REMOVE);
            if (!isAllowed) {
                return null;
            }

            return (
                <ConfirmationPopover
                    title="Remove client's tax ID?"
                    description="When confirmed, tax ID will be removed from client's tax IDs list"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => handleRemoveTaxId(taxId.id)}
                        >
                            Yes, remove
                        </Button>,
                    ]}
                >
                    <Button
                        minimal
                        loading={isRemovingCurrentTaxId}
                        disabled={removingTaxId !== null}
                        icon="trash"
                        intent={Intent.DANGER}
                    >
                        Remove
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Card compact>
                <Flex direction="row" justify="space-between" align="flex-start">
                    <div>
                        <Flex className="mb-small" direction="row" align="center">
                            <Image
                                className="mr-1"
                                width="25px"
                                height="25px"
                                minHeight="25px"
                                minWidth="25px"
                                maxHeight="25px"
                                maxWidth="25px"
                                src={CountryFlagUtils.getCountryFlagUrlByKey(taxId.country)}
                                alt={taxId.country}
                            />

                            <DevText>
                                {countriesData[taxId.country].countryName} -{' '}
                                <DevText inline muted>
                                    {taxId.type}
                                </DevText>
                            </DevText>
                        </Flex>

                        <DevText>
                            <DevText inline muted>
                                Tax ID:{' '}
                            </DevText>
                            {taxId.value}
                        </DevText>
                    </div>

                    {renderRemoveButton()}
                </Flex>
            </Card>
        );
    };

    const renderTaxIds = () => {
        // Client brand profiles were fetched, but where are no brand profiles existed for this client
        if (taxIds.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            Client do not have any tax IDs associated with them
                        </Heading>
                    }
                />
            );
        }

        return taxIds.items.map((taxId, index) => (
            <div key={taxId.id} className={index === 0 ? '' : 'mt-2'}>
                {renderTaxId(taxId)}
            </div>
        ));
    };

    return (
        <div {...props}>
            <div className="mb-2">{renderHeader()}</div>
            <div>{renderTaxIds()}</div>
        </div>
    );
};

export default TaxIds;
