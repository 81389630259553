import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';

const taskQueueInformation: {
    [taskQueue in TaskQueue]: {
        title: string;
    };
} = {
    [TaskQueue.ACTIVE]: {
        title: 'Active',
    },
    [TaskQueue.BACKLOG]: {
        title: 'Backlog',
    },
    [TaskQueue.DRAFT]: {
        title: 'Draft',
    },
};

export default taskQueueInformation;
