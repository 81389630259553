/*
  Filter accepts:
  - participant_user_id: ID[];                                          // Dynamic filter (TaskParticipantUser[])
  - task_owner_user_id: ID[];                                           // Dynamic filter (AuthorUser[])
  - team_id: ID[];                                                      // Dynamic filter (TeamResource[])
  - negative: TaskClientFeedbackNegativeType[];                                                 // Static filter (keys: score_creativity, score_speed, etc.)
*/

enum PlatformTaskClientFeedbackFilterType {
    CLIENT_USERS = 'CLIENT_USERS',
    TASK_PARTICIPANTS = 'TASK_PARTICIPANTS',
    TEAMS = 'TEAMS',
    NEGATIVE_SCORE = 'NEGATIVE_SCORE',
}

export default PlatformTaskClientFeedbackFilterType;
