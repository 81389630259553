import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { InvoiceResource } from 'dy-frontend-http-repository/lib/modules/Invoice/resources';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { TaxIdResource } from 'dy-frontend-http-repository/lib/modules/TaxId/resources';
import domain from './domain';
import { resetInvoices, resetSubscriptions, resetTaxIds } from './events';

// Client subscriptions
export const $subscriptions = domain
    .createStore<CollectionResource<SubscriptionResource, {}> | null>(null)
    .reset(resetSubscriptions);

// Client invoices
export const $invoices = domain.createStore<CollectionResource<InvoiceResource, {}> | null>(null).reset(resetInvoices);

// Client tax IDs
export const $taxIds = domain.createStore<CollectionResource<TaxIdResource, {}> | null>(null).reset(resetTaxIds);
