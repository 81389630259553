import { repository } from "dy-frontend-http-repository/lib/modules";
import domain from "./domain";
import { PlanType } from "dy-frontend-http-repository/lib/data/enums";
import { PlanResource } from "dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources";
import { CollectionResource } from "dy-frontend-http-repository/lib/data/resources";
import { AvailableCouponResource } from "dy-frontend-http-repository/lib/modules/Coupon/resources";
import { PaymentMethodListItemResource } from "dy-frontend-http-repository/lib/modules/PaymentMethod/resources";
import { CreateSubscriptionSeatsModeInput, CreateSubscriptionUserModeInput } from "dy-frontend-http-repository/lib/modules/Subscription/inputs";
import { SubscriptionRef } from "dy-frontend-http-repository/lib/modules/Subscription/refs";

// Fetch subscription plans by their type
export const fetchSubscriptionPlansByType = domain.createEffect<
    PlanType,
    CollectionResource<PlanResource, {}>
>({
    name: "fetchSubscriptionPlansByType",
    handler: async (type) => {
        return await repository.subscriptionPlan().get(type);
    },
});

// Fetch payment methods belonging to the payment account
export const fetchPaymentMethodsByPaymentAccountId = domain.createEffect<
    ID,
    CollectionResource<PaymentMethodListItemResource, {}>
>({
    name: 'fetchPaymentMethodsByPaymentAccountId',
    handler: async (paymentAccountId) => {
        return await repository.paymentMethod().get(paymentAccountId);
    },
});


// Fetch available coupon
export const fetchAvailableCoupon = domain.createEffect<
    { couponId: string },
    AvailableCouponResource
>({
    name: "fetchAvailableCoupon",
    handler: async ({ couponId }) => {
        return await repository.coupon().getAvailable({ query: couponId });
    },
});

// Create subscription: USER mode
export const createUserModeSubscription = domain.createEffect<
    CreateSubscriptionUserModeInput,
    SubscriptionRef
>({
    name: 'createUserModeSubscription',
    handler: async (input) => {
        return await repository.subscription().createWithUserMode(input);
    },
});


// Create subscription: SEATS mode
export const createSeatsModeSubscription = domain.createEffect<
    CreateSubscriptionSeatsModeInput,
    SubscriptionRef
>({
    name: 'createSeatsModeSubscription',
    handler: async (input) => {
        return await repository.subscription().createWithSeatsMode(input);
    },
});
