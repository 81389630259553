import { $subscriptions, $invoices, $taxIds } from './states';
import { fetchClientPaymentMethods, fetchSubscriptions, fetchInvoices, fetchTaxIds } from './effects';
import { $clientPaymentAccount } from '../../../store/states';

// Client payment account payment methods
$clientPaymentAccount.on(fetchClientPaymentMethods.doneData, (clientPaymentAccount, data) => {
    if (clientPaymentAccount === null) {
        return clientPaymentAccount;
    }

    const clientPaymentAccountCopy = { ...clientPaymentAccount };

    clientPaymentAccountCopy.payment_methods = data.items.map((clientPaymentMethod) => ({
        id: clientPaymentMethod.id,
        external_id: clientPaymentMethod.external_id,
        type: clientPaymentMethod.type,
        card_brand: clientPaymentMethod.card_brand,
        card_country: clientPaymentMethod.card_country,
        card_expiration_month: clientPaymentMethod.card_expiration_month,
        card_expiration_year: clientPaymentMethod.card_expiration_year,
        card_funding_type: clientPaymentMethod.card_funding_type,
        card_last_four: clientPaymentMethod.card_last_four,
    }));

    return clientPaymentAccountCopy;
});

// Client subscriptions
$subscriptions.on(fetchSubscriptions.doneData, (_, data) => data);

// Client invoices
$invoices.on(fetchInvoices.doneData, (_, data) => data);

// Fetch tax IDs
$taxIds.on(fetchTaxIds.doneData, (_, data) => data);
