import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringShouldContainLettersOnlyMessage = 'This field much contains latin letters only';
const onlyLettersStringRegex = /^$|[a-zA-Z]+$/i;
const getStringContainOnlyLettersValidator: GetValidatorFunction<
    { stringShouldContainLettersOnlyMessage?: string },
    string
> = (options = { stringShouldContainLettersOnlyMessage: defaultStringShouldContainLettersOnlyMessage }) => {
    const { stringShouldContainLettersOnlyMessage } = options;

    return async (value) => {
        if (onlyLettersStringRegex.test(value)) {
            // String contains only letters
            return null;
        } else {
            // String contains NOT only letters
            if (stringShouldContainLettersOnlyMessage) {
                return stringShouldContainLettersOnlyMessage;
            } else {
                return defaultStringShouldContainLettersOnlyMessage;
            }
        }
    };
};

export default getStringContainOnlyLettersValidator;
