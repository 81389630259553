import { Button, Classes, Colors, Intent, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import { Endpoints } from '@data/consts';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { planApi } from './store/apis';
import { fetchPlan, removePlan, restorePlan } from './store/effects';
import { resetPlan, resetTaskCategories, resetTaskCategoryGroups } from './store/events';
import { $plan } from './store/states';
import Heading from '@components/Heading';
import Grid from '@components/Grid';
import QuotaList from '@pages/Plan/components/QuotaList';
import TaskCategoryGroupList from '@pages/Plan/components/TaskCategoryGroupList';
import TaskCategoryList from '@pages/Plan/components/TaskCategoryList';
import PublishManager from '@pages/Plan/components/PublishManager';
import PriceList from '@pages/Plan/components/PriceList';
import Info from '@pages/Plan/components/Info';
import Circle from '@components/Circle';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { usePageTitle } from '@app/hooks';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { $permissions } from '@containers/store/states';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const Plan: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.plan) {
        // TODO: Redirect to dashboard
    }

    const { changeTitle } = usePageTitle('Plan');
    const navigate = useNavigate();
    const { planId } = useParams() as { planId: ID };

    const plan = useStore($plan);
    const isPlanRemoving = useStore(removePlan.pending);
    const isPlanRestoring = useStore(restorePlan.pending);

    useEffect(() => {
        fetchPlan(planId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Plan with ID of ${planId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to plans page
                navigate(Endpoints.PLANS);
            }
        });
    }, [planId]);

    useEffect(() => {
        if (!plan) {
            return;
        }

        const breadcrumbItems = [
            { text: 'Plans', onClick: () => navigate(Endpoints.PLANS) },
            { text: `${plan.title} (${plan.type})` },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, [plan?.title]);

    useEffect(() => {
        if (!plan) {
            return;
        }

        changeTitle(`Plan - ${plan.title}`);
    }, [plan?.title]);

    useEffect(() => {
        return () => {
            resetPlan();
            resetTaskCategoryGroups();
            resetTaskCategories();
            resetPageBreadcrumbs();
        };
    }, []);

    if (!plan) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderPlanControls = () => {
        const renderRemovePlanButton = () => {
            const isAllowed = permissions.isRoot.plan || permissions.has(PlanPermission.ARCHIVE);
            if (!isAllowed) {
                return null;
            }

            if (plan.archived_at) {
                // Plan is already archived
                return null;
            }

            const handleRemovePlan = async () => {
                try {
                    await removePlan(planId);
                    planApi.archive();
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure you want to remove plan?"
                    description="When confirmed, plan will be removed"
                    actions={[
                        <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={handleRemovePlan}>
                            Yes, remove plan
                        </Button>,
                    ]}
                >
                    <Button
                        outlined
                        loading={isPlanRemoving}
                        disabled={isPlanRemoving}
                        icon="trash"
                        intent={Intent.DANGER}
                    >
                        Remove plan
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderRestorePlanButton = () => {
            const isAllowed = permissions.isRoot.plan || permissions.has(PlanPermission.RESTORE);
            if (!isAllowed) {
                return null;
            }

            if (!plan.archived_at) {
                // Plan is NOT archived
                return null;
            }

            const handleRestorePlan = async () => {
                try {
                    await restorePlan(plan.id);
                    planApi.restore();
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure you want to restore plan?"
                    description="When confirmed, plan will be restored"
                    actions={[
                        <Button intent={Intent.SUCCESS} className={Classes.POPOVER_DISMISS} onClick={handleRestorePlan}>
                            Yes, restore plan
                        </Button>,
                    ]}
                >
                    <Button
                        outlined
                        loading={isPlanRestoring}
                        disabled={isPlanRestoring}
                        icon="confirm"
                        intent={Intent.SUCCESS}
                    >
                        Restore plan
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Flex direction="row" align="center">
                {renderRemovePlanButton()}
                {renderRestorePlanButton()}
            </Flex>
        );
    };

    const renderHeader = () => {
        const renderLegacyTag = () => {
            if (!plan.is_legacy) {
                return null;
            }

            return (
                <Tooltip content="Plan is marked as legacy">
                    <Tag minimal className="mr-1">
                        Legacy
                    </Tag>
                </Tooltip>
            );
        };

        return (
            <Flex className="mb-2" justify="space-between">
                <Flex align="center">
                    <Circle color={plan.color !== '' ? plan.color : Colors.GRAY2} className="mr-1" />
                    {renderLegacyTag()}
                    <Heading type="h3">{plan.title}</Heading>
                </Flex>
                {renderPlanControls()}
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            <PublishManager />

            {renderHeader()}

            <Grid container>
                <Grid lg={3} xs={12}>
                    <Info />
                </Grid>
                <Grid lg={9} xs={12}>
                    <div className="mb-4">
                        <QuotaList />
                    </div>
                    <div className="mb-4">
                        <TaskCategoryGroupList />
                    </div>
                    <div className="mb-4">
                        <TaskCategoryList />
                    </div>
                    <div className="mb-4">
                        <PriceList />
                    </div>
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default Plan;
