// TODO: `limit upload to 10 files and think about situation whenever user need to upload 10+ files at once (Glad said it should first upload first 10 files and only then upload other files)
// add manager for that which cuts array into arrays which have 10 files and call the
// TODO: whenever testing use throttling to see progress of file uploading
import { checkValidators } from '@app/hooks/validation/functions';
import { ValidatorFunction } from '@app/hooks/validation/types';
import { Intent } from '@blueprintjs/core';
import axios, { Method } from 'axios';
import { FilePurpose } from 'dy-frontend-http-repository/lib/data/enums';
import {
    PendingFileResource,
    PreSignedUploadFormResource,
    UploadIntentResource,
} from 'dy-frontend-http-repository/lib/modules/File/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import { ToastUtils } from '@data/utils';
import { useEffect, useMemo, useState } from 'react';
import { FileStatus } from './enums';
import { FileInformation, FilesChunk, FilesChunks } from './types';

export interface UseUploadProps {
    validators?: ValidatorFunction<File>[];
    onFilesUploadSuccess?: (files: FileInformation[]) => void;
    clearAllFilesOnUploadSuccess?: boolean;
}

export type Props = UseUploadProps;

// TODO: Move to other place
const filesChunksSize = 10;

// TODO: Move to other place
const getFormData = (file: File, uploadedForm: PreSignedUploadFormResource) => {
    const formData = new FormData();

    for (const key in uploadedForm.data) {
        formData.append(key, uploadedForm.data[key]);
    }

    formData.append('file', file);

    return formData;
};
/*
    fileChunks = {
        "gkjg95jg9": {
            "gdfkjgkdfjgh": FileInformation,
            "bij9540jh904": FileInformation,
            "cdfkjgkdfjgh": FileInformation,
            "zigf540jh94c": FileInformation,
            "afgjgkdfzxc": FileInformation,
            "hij9540jhiii": FileInformation,
        },
        "g85485485": {
            "vcxvbtbtbtbb": FileInformation,
            "h934jdkacnfh": FileInformation,
            "lA94JFKDkgj4": FileInformation,
        }
    }
*/

const useUpload = ({ onFilesUploadSuccess, clearAllFilesOnUploadSuccess = true, validators = [] }: Props) => {
    // This state need for situations whenever first chunk is verified, but others are still processing
    const [isUploadingMultipleChunks, setIsUploadingMultipleChunks] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [filesChunks, setFilesChunks] = useState<FilesChunks>({});

    useEffect(() => {
        if (Object.keys(filesChunks).length === 0) {
            // There are no files chunks
            return;
        }

        if (isUploadingMultipleChunks) {
            // Still processing multiple chunks
            return;
        }

        // Check if at least 1 files is being uploaded at the moment, if so -> wait until it is uploaded
        for (const filesChunkKey in filesChunks) {
            const filesChunk = filesChunks[filesChunkKey];
            if (Object.values(filesChunk).some((fileInformation) => fileInformation.status === FileStatus.UPLOADING)) {
                return;
            }
        }

        if (onFilesUploadSuccess) {
            // Call files upload success callback
            onFilesUploadSuccess([...fileInformationArray]);
        }

        if (clearAllFilesOnUploadSuccess) {
            // Clear file chunks
            setFilesChunks({});
        }

        // Set uploading flag to false
        setIsUploading(false);

        // eslint-disable-next-line
    }, [filesChunks, isUploadingMultipleChunks]);

    const handleUploadFileToS3 = async (
        file: File,
        uploadedForm: PreSignedUploadFormResource,
        onUploadProgress: ((progressEvent: any) => void) | undefined
    ) => {
        const formData = getFormData(file, uploadedForm);

        try {
            await axios({
                method: uploadedForm.method as Method,
                url: uploadedForm.action,
                data: formData,
                headers: {
                    'Content-Type': uploadedForm.enctype,
                },
                onUploadProgress,
            });
        } catch (e) {
            throw e;
        }
    };

    const handleUpload = async (purpose: FilePurpose, files: File[]): Promise<PendingFileResource[]> => {
        // Set uploading flag to true
        setIsUploading(true);

        // Generate filesChunkKey
        const filesChunkKey = Math.random().toString(16).slice(2);

        // Create files chunk map
        const filesChunk: FilesChunk = {};
        for (const file of files) {
            // Generate file key
            const fileInformationKey = Math.random().toString(16).slice(2);

            // Create files chunk
            filesChunk[fileInformationKey] = {
                key: fileInformationKey,
                status: FileStatus.UPLOADING,
                progress: 0,
                file: file,
                resource: null,
            };
        }

        // Add new files chunk with file information being of status UPLOADING
        setFilesChunks((prevFilesChunks) => ({
            ...prevFilesChunks,
            [filesChunkKey]: filesChunk,
        }));

        // Prepare signed upload forms
        let signedUploadForms: UploadIntentResource[] = [];
        try {
            signedUploadForms = await repository.file().createForms({
                purpose,
                files: Object.values(filesChunk).map((fileInformation) => ({
                    key: fileInformation.key,
                    content_type: fileInformation.file.type,
                    original_name: fileInformation.file.name,
                    content_length: fileInformation.file.size,
                })),
            });
        } catch (e) {
            // Error while creating signed upload forms, update files chunk file information status to ERROR_CREATING_FILE_FORMS
            setFilesChunks((prevFilesChunks) => {
                const filesChunk = prevFilesChunks[filesChunkKey];

                for (const fileInformation of Object.values(filesChunk)) {
                    fileInformation.status = FileStatus.ERROR_CREATING_FILE_FORMS;
                }

                return {
                    ...prevFilesChunks,
                    [filesChunkKey]: {
                        ...filesChunk,
                    },
                };
            });

            // TODO: if internet dies for some reason form is not dropping and verify is called

            throw new Error('Error during file form creation');
        }

        // Create map pending file ID to file information key
        const resourceIdToFileInformationKeyMap: { [key in ID]: string } = {};
        const resourceIdToResourceMap: { [key in ID]: PendingFileResource } = {};

        // Upload files to S3 from forms
        for (const uploadedFormIntent of signedUploadForms) {
            // Get file information key
            const fileInformationKey = uploadedFormIntent.key;

            // Get file
            const file = filesChunk[uploadedFormIntent.key].file;

            // Get resource
            const resource = uploadedFormIntent.pending_file;

            // Upload file to S3
            try {
                // TODO: for error send wrong file or use wrong form (different content-type)
                // eslint-disable-next-line
                await handleUploadFileToS3(file, uploadedFormIntent.form, (event) => {
                    const { total, loaded } = event;

                    // Calculate & update progress value for file information
                    setFilesChunks((prevFilesChunks) => {
                        const filesChunk = prevFilesChunks[filesChunkKey];
                        const fileInformation = filesChunk[fileInformationKey];

                        fileInformation.progress = loaded / total;

                        return {
                            ...prevFilesChunks,
                            [filesChunkKey]: {
                                ...filesChunk,
                                [fileInformationKey]: fileInformation,
                            },
                        };
                    });
                });

                resourceIdToResourceMap[resource.id] = resource;
                resourceIdToFileInformationKeyMap[resource.id] = fileInformationKey;
            } catch (e) {
                filesChunk[fileInformationKey].status = FileStatus.ERROR_UPLOADING_TO_S3;
            }
        }

        // Verify pending files
        try {
            const verifiedResourcesRefList = await repository
                .file()
                .verifyFiles({ file_ids: Object.keys(resourceIdToFileInformationKeyMap) });

            setFilesChunks((prevFilesChunks) => {
                // Get file information chunk
                const filesChunk = prevFilesChunks[filesChunkKey];

                for (const resourceId of Object.keys(resourceIdToResourceMap)) {
                    // Get resource by ID
                    const resource = resourceIdToResourceMap[resourceId];

                    // Check if resource was verified
                    if (verifiedResourcesRefList.ids.includes(resourceId)) {
                        // Get file information key
                        const fileInformationKey = resourceIdToFileInformationKeyMap[resource.id];

                        // Get file information from file information key
                        const fileInformation = filesChunk[fileInformationKey];

                        fileInformation.resource = resource;
                        fileInformation.status = FileStatus.VERIFIED;
                    } else {
                        // File was NOT verified
                        const fileInformationKey = resourceIdToFileInformationKeyMap[resourceId];

                        // Get file information
                        const fileInformation = filesChunk[fileInformationKey];

                        // Check if verification is the only what went wrong in the chain of "create file forms" -> "upload to S3" -> "verify file"
                        if ([FileStatus.UPLOADING, FileStatus.VERIFIED].includes(fileInformation.status)) {
                            fileInformation.resource = resource;
                            fileInformation.status = FileStatus.ERROR_VERIFICATION;
                        }
                    }
                }

                return {
                    ...prevFilesChunks,
                    [filesChunkKey]: {
                        ...filesChunk,
                    },
                };
            });
        } catch (e) {
            // Set all fileInformation statuses to <code>FilesStatus.ERROR_VERIFICATION</code>
            setFilesChunks((prevFilesChunks) => {
                const filesChunk = prevFilesChunks[filesChunkKey];

                for (const fileInformation of Object.values(filesChunk)) {
                    fileInformation.status = FileStatus.ERROR_VERIFICATION;
                }

                return {
                    ...prevFilesChunks,
                    [filesChunkKey]: {
                        ...filesChunk,
                    },
                };
            });

            throw new Error('Files verification error');
        }

        // Get verified file resources
        const verifiedResources: PendingFileResource[] = Object.values(resourceIdToResourceMap);

        return verifiedResources;
    };

    const handleUploadMultipleFilesChunks = async (
        purpose: FilePurpose,
        files: FileList | File[] | null
    ): Promise<PendingFileResource[]> => {
        // FileList/Files were not provided
        if (files === null) {
            return [];
        }

        // There are no files passed
        if (files.length === 0) {
            return [];
        }

        // Create array of files from FileList/Files
        let filesArr: File[] = [];
        if (files instanceof FileList) {
            filesArr = Array.from(files);
        } else {
            filesArr = files as File[];
        }

        // Validate files
        let validFilesArr: File[] = [];
        if (validators.length > 0) {
            // Validators exist
            for (let i = 0; i < filesArr.length; i++) {
                const file = filesArr[i];

                // Validate file
                const validationResult = await checkValidators(file, validators);
                if (validationResult === null) {
                    // File is valid
                    validFilesArr.push(file);
                } else {
                    // TODO: since useUpload will be put to shared repository it should accept the function to show toast/snackbar, for now it is ok, since we are testing
                    ToastUtils.showToast({ message: validationResult, intent: Intent.DANGER });
                }
            }
        } else {
            // There are no validators passed -> all files are valid
            validFilesArr = filesArr;
        }

        // Upload files by chunks for size 10 each
        let resources: PendingFileResource[] = [];

        // Update multiple chunks flag
        const isMultipleChunksPresent = validFilesArr.length > filesChunksSize;
        setIsUploadingMultipleChunks(isMultipleChunksPresent);

        for (let i = 0; i < validFilesArr.length; i += filesChunksSize) {
            // Get files to upload
            const filesToUpload = validFilesArr.slice(i, i + filesChunksSize);

            // Upload files
            try {
                const uploadedResources = await handleUpload(purpose, filesToUpload);
                resources = resources.concat(uploadedResources);
            } catch (e) {
                throw e;
            }
        }

        // Update multiple chunks flag to false after every chunk being uploaded
        setIsUploadingMultipleChunks(false);

        return resources;
    };

    // Get array of files information
    const fileInformationArray = useMemo(() => {
        if (Object.keys(filesChunks).length === 0) return [];

        let arr: FileInformation[] = [];
        for (const filesChunkKey in filesChunks) {
            const filesChunk = filesChunks[filesChunkKey];
            arr = [...arr, ...Object.values(filesChunk)];
        }

        return arr;
    }, [filesChunks]);

    return {
        files: fileInformationArray,
        isUploading,
        upload: handleUploadMultipleFilesChunks,
        clearFiles: () => setFilesChunks({}),
    };
};

export default useUpload;
