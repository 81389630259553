import AvatarUploaderNEW from '@app/components/AvatarUploaderNEW';
import ImageCropperModal, { ImageCropperModalProps } from '@app/containers/modals/ImageCropperModal';
import { openModal } from '@app/containers/modals/store/events';
import { getFileAllowedTypesValidator, getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import { imageHashPreview } from '@data/consts';
import { brandProfileApi } from '@pages/BrandProfile/store/apis';
import { removeBrandProfileImage, uploadAndSetBrandProfileImage } from '@pages/BrandProfile/store/effects';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { $permissions } from '@containers/store/states';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const brandProfileAvatarValidators = [
    getFileAllowedTypesValidator({ allowedTypes: ['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'] }),
    getFileMaxSizeValidator({ maxSize: 25000000 }),
];

const ImageBlock: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const isUpdateAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);

    const brandProfile = useStore($brandProfile);

    const [isUploading, setIsUploading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    if (!brandProfile) {
        return null;
    }

    const handleRemoveImage = async () => {
        setIsRemoving(true);

        try {
            const brandProfileRef = await removeBrandProfileImage(brandProfile.id);
            brandProfileApi.removeImage();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemoving(false);
        }
    };

    const handleUploadImage = async (file: File) => {
        setIsUploading(true);

        try {
            const brandProfileImageHashRef = await uploadAndSetBrandProfileImage({
                brandProfileId: brandProfile.id,
                file: file,
            });
            brandProfileApi.setImageHash(brandProfileImageHashRef);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSelectFile = (file: File) => {
        const isImage = file.type.split('/')[0] === 'image';
        if (!isImage) {
            alert('Not an image');
            return;
        }

        const isSvg = file.type === 'image/svg+xml';

        // Non-svg files can be adjusted
        if (!isSvg) {
            openModal<ImageCropperModalProps>({
                ModalComponent: ImageCropperModal,
                data: {
                    inputImageUrl: URL.createObjectURL(file),
                    aspect: 1,
                    shape: 'rect',
                    onCropComplete: handleUploadImage,
                },
            });
            return;
        }

        // Svg are uploaded directly
        handleUploadImage(file);
    };

    // Get avatar src
    let avatarSrc: string | null = null;
    if (brandProfile.image_hash) {
        avatarSrc = imageHashPreview.brandProfileImage(brandProfile.image_hash, ImageHashPreviewSize.SM);
    }

    return (
        <AvatarUploaderNEW
            disabled={!isUpdateAllowed}
            loading={isUploading || isRemoving}
            width="100%"
            height="200px"
            borderRadius="8px"
            validators={brandProfileAvatarValidators}
            avatarProps={{
                borderRadius: 'inherit',
                alt: brandProfile.title,
                src: avatarSrc,
            }}
            fileUploadProgressProps={{
                spinnerProps: {
                    size: 70,
                },
            }}
            onFileSelect={handleSelectFile}
            onFileRemove={handleRemoveImage}
            {...props}
        />
    );
};

export default ImageBlock;
