import { $permissions } from '@app/containers/store/states';
import { Button, Icon, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import NonIdealState from '@components/NonIdealState';
import { Endpoints } from '@data/consts';
import { openModal } from '@modals/store/events';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskCategoryItem from './components/TaskCategoryItem';
import { fetchTaskCategories } from './store/effects';
import { resetTaskCategories } from './store/events';
import { $taskCategories } from './store/states';
import Heading from '@components/Heading';
import CreateTaskCategoryModal from './modals/CreateTaskCategoryModal';
import { usePageTitle } from '@app/hooks';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';

const TaskCategories: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.taskCategory) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Request Categories');

    const navigate = useNavigate();

    const taskCategories = useStore($taskCategories);

    const [search, setSearch] = useState('');
    const [isSearchInputTouched, setIsSearchInputTouched] = useState(false);

    const handleLoadPage = () => {
        fetchTaskCategories({ query: search }).catch((e) => {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: 'Request categories were not found',
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        });
    };

    useEffect(() => {
        handleLoadPage();
    }, []);

    useEffect(() => {
        if (!isSearchInputTouched) {
            return;
        }

        const timeoutId = setTimeout(() => handleLoadPage(), 500);

        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        return () => {
            resetTaskCategories();
        };
    }, []);

    const renderCreateTaskCategoryButton = () => {
        const isAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="new-object"
                intent={Intent.PRIMARY}
                onClick={() =>
                    openModal({
                        ModalComponent: CreateTaskCategoryModal,
                    })
                }
            >
                Create category
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <InputGroup
                    leftIcon="search"
                    placeholder="Search task category"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={(e) => setIsSearchInputTouched(true)}
                />

                {renderCreateTaskCategoryButton()}
            </Flex>
        );
    };

    const renderTaskCategoriesList = () => {
        // Task categories were NOT fetched yet
        if (!taskCategories) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Task categories were fetched, but task categories were not created yet
        if (taskCategories.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No task categories were found
                        </Heading>
                    }
                />
            );
        }

        // TODO: add imageSrc whenever files will be implemented on backend
        return (
            <Grid container>
                {taskCategories.items.map((taskCategory) => (
                    <Grid className="mb-2" key={taskCategory.id} lg={3} xs={12}>
                        <TaskCategoryItem
                            category={taskCategory}
                            onClick={() =>
                                navigate(Endpoints.TASK_CATEGORY.replace(':taskCategoryId', `${taskCategory.id}`))
                            }
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Heading className="mb-2" type="h3">
                Task categories
            </Heading>

            {/* Header with search input and "create task category" button */}
            {renderHeader()}

            {/* Task categories list */}
            {renderTaskCategoriesList()}
        </FixedWidthPageContainer>
    );
};

export default TaskCategories;
