import styled, { css } from 'styled-components';
import { CircleProps } from '.';

export const StyledCircle = styled.span<{
    $size: CircleProps['size'];
    $color: CircleProps['color'];
    $borderColor: CircleProps['borderColor'];
}>`
    display: inline-block;
    border-radius: 50%;

    ${({ $size, $color }) => css`
        background: ${$color};

        width: ${$size}px;
        height: ${$size}px;

        min-width: ${$size};
        min-height: ${$size};
        max-width: ${$size};
        max-height: ${$size};
    `}

    ${({ $borderColor }) =>
        $borderColor &&
        css`
            border: 1px solid ${$borderColor};
        `}
`;
