import { Intent, Toaster } from '@blueprintjs/core';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { teamApi } from '../../store/apis';
import { updateTeamParticipant } from '../../store/effects';

const useUpdateTeamParticipantRole = () => {
    const handleUpdateTeamParticipantRole = async ({
        teamId,
        userId,
        role,
    }: {
        teamId: ID;
        userId: ID;
        role: TeamParticipationRole;
    }) => {
        try {
            const updateTeamParticipantResponse = await updateTeamParticipant({ teamId, userId, role: role });

            teamApi.updateParticipationByUserId({ userId: userId, role: role });
        } catch (e) {
            throw e;
        }
    };

    return { updateTeamParticipantRole: handleUpdateTeamParticipantRole };
};

export default useUpdateTeamParticipantRole;
