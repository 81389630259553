import { setTaskMessageTemplateCategoryId } from './events';
import { fetchTaskMessageTemplateCategories, fetchTaskMessageTemplates } from './effects';
import {
    $selectedTaskMessageTemplateCategoryId,
    $taskMessageTemplateCategories,
    $taskMessageTemplates,
} from './states';

$selectedTaskMessageTemplateCategoryId.on(setTaskMessageTemplateCategoryId, (_, data) => data);

$taskMessageTemplateCategories.on(fetchTaskMessageTemplateCategories.doneData, (_, data) => data);
$taskMessageTemplates.on(fetchTaskMessageTemplates.doneData, (_, data) => data);
