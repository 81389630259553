import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { openModal } from '@modals/store/events';
import {
    $selectedTaskMessageTemplateCategoryId,
    $taskMessageTemplateCategories,
} from '@pages/TaskMessageTemplates/store/states';
import CategoryListItem from './components/TaskMessageTemplateCategoryListItem';
import { Button } from '@blueprintjs/core';
import DevText from '@components/Text';
import CreateTaskMessageTemplateCategoryModal
    from '@app/containers/pages/TaskMessageTemplates/modals/CreateTaskMessageTemplateCategoryModal';
import { $permissions } from '@containers/store/states';
import { TaskMessageTemplateCategoryPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskMessageTemplateCategories: React.FC<Props> = (props) => {
    const permission = useStore($permissions);

    const selectedTaskMessageTemplateCategoryId = useStore($selectedTaskMessageTemplateCategoryId);
    const taskMessageTemplateCategories = useStore($taskMessageTemplateCategories);

    // Task message template categories are NOT fetched yet
    if (!taskMessageTemplateCategories) {
        return null;
    }

    const renderCategoriesList = () => {
        // Task message templates were NOT created yet
        if (taskMessageTemplateCategories.items.length === 0) {
            return <DevText muted>Task message template categories do not exist yet</DevText>;
        }

        // TODO: add all category
        return (
            <>
                <CategoryListItem
                    className="mt-2"
                    active={selectedTaskMessageTemplateCategoryId === null}
                    category={null}
                />

                {taskMessageTemplateCategories.items.map((category) => (
                    <CategoryListItem
                        key={category.id}
                        className="mt-2"
                        active={selectedTaskMessageTemplateCategoryId === category.id}
                        category={category}
                    />
                ))}
            </>
        );
    };

    const renderCreateTaskMessageTemplateCategory = () => {
        // Auth
        const isAllowed = permission.isRoot.taskMessageTemplateCategory || permission.has(TaskMessageTemplateCategoryPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                className="mt-1"
                icon="add"
                onClick={() => openModal({ ModalComponent: CreateTaskMessageTemplateCategoryModal })}
            >
                Add category
            </Button>
        );
    };

    return (
        <div {...props}>
            {renderCategoriesList()}
            {renderCreateTaskMessageTemplateCategory()}
        </div>
    );
};

export default TaskMessageTemplateCategories;
