import { PlatformTaskClientFeedbackFilterData } from '../types';

const initialPlatformTaskFilterData: PlatformTaskClientFeedbackFilterData = {
    clients: [],
    negative: [],
    participants: [],
    teams: [],
};

export default initialPlatformTaskFilterData;
