import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    TaskClientFeedbackResource,
    TaskClientFeedbackSummaryResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
import { initialPlatformTaskFilterData } from '../consts';
import { PlatformTaskClientFeedbackFilterData } from '../types';
import domain from './domain';
import { resetIsQueryHydrationFinished, resetPage, resetPlatformTaskClientFeedbackData, resetTaskClientFeedback, resetTaskClientFeedbackSummary } from './events';

/*
  Filter accepts:
  - participant_user_id: ID[];                                          // Dynamic filter (TaskParticipantUser[])
  - task_owner_user_id: ID[];                                           // Dynamic filter (AuthorUser[])
  - team_id: ID[];                                                      // Dynamic filter (TeamResource[])
  - negative: TaskClientFeedbackNegativeType[];                                                 // Static filter (keys: score_creativity, score_speed, etc.)
*/

// Task client feedback
export const $taskClientFeedback = domain
    .createStore<CollectionResource<TaskClientFeedbackResource, {}> | null>(null)
    .reset(resetTaskClientFeedback);

// Task client feedback summary
export const $taskClientFeedbackSummary = domain
    .createStore<TaskClientFeedbackSummaryResource | null>(null)
    .reset(resetTaskClientFeedbackSummary);

// Page
export const $page = domain.createStore<number>(1).reset(resetPage);

// Initialization flag for hydration finished
export const $isQueryHydrationFinished = domain.createStore<boolean>(false).reset(resetIsQueryHydrationFinished);

// Platform task filter data
export const $platformTaskClientFeedbackData = domain
    .createStore<PlatformTaskClientFeedbackFilterData>(initialPlatformTaskFilterData) // TODO: change name for initial const
    .reset(resetPlatformTaskClientFeedbackData);
