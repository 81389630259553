import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import TextAreaFormField from '@components/TextAreaFormField';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { ModalProps } from '@modals/types';
import { coreUserApi } from '@pages/CoreUser/store/apis';
import { updateCoreUserInformation } from '@pages/CoreUser/store/effects';
import { UpdateUserInformationInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';
import React, { useMemo } from 'react';
import { CountrySelectItem, TimezoneSelectItem } from './types';

export interface UpdateCoreUserMainInformationModalProps {
    coreUserId: ID;
    firstName: string;
    lastName: string;
    companyName: string;
    companyPosition: string;
    email: string;
    timezoneName: string;
    phone: string;
    addressCountry: string;
    bio: string;
}

type Props = ModalProps<UpdateCoreUserMainInformationModalProps>;

const coreUserFirstNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const coreUserLastNameValidators = [
    getOptionalStringValidator([
        getStringRequiredValidator(),
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const coreUserSelectedAddressCountryKeyValidators = [];
const coreUserSelectedTimezoneValidators = [];
const coreUserCompanyNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const coreUserCompanyPositionValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const coreUserPhoneValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const coreUserBioValidators = [
    getOptionalStringValidator([
        getStringRequiredValidator(),
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];

const UpdateCoreUserMainInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const coreUserFirstName = useTextFormField({
        id: 'core-user-first-name',
        validators: coreUserFirstNameValidators,
        initialValue: data?.firstName ?? '',
    });

    const coreUserLastName = useTextFormField({
        id: 'core-user-last-name',
        validators: coreUserLastNameValidators,
        initialValue: data?.lastName ?? '',
    });

    const coreUserSelectedAddressCountryKey = useCustomSelectFormField<string | null>({
        id: 'core-user-selected-address-country-key',
        validators: coreUserSelectedAddressCountryKeyValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const coreUserSelectedTimezone = useCustomSelectFormField<string | null>({
        id: 'core-user-selected-timezone',
        validators: coreUserSelectedTimezoneValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const coreUserCompanyName = useTextFormField({
        id: 'core-user-company-name',
        validators: coreUserCompanyNameValidators,
        initialValue: data?.companyName ?? '',
    });

    const coreUserCompanyPosition = useTextFormField({
        id: 'core-user-company-position',
        validators: coreUserCompanyPositionValidators,
        initialValue: data?.companyPosition ?? '',
    });

    const coreUserPhone = useTextFormField({
        id: 'core-user-phone',
        validators: coreUserPhoneValidators,
        initialValue: data?.phone ?? '',
    });

    const coreUserBio = useTextFormField({
        id: 'core-user-bio',
        validators: coreUserBioValidators,
        initialValue: data?.bio ?? '',
    });

    const form = useForm<UserRef>({
        fields: [
            coreUserFirstName,
            coreUserLastName,
            coreUserSelectedAddressCountryKey,
            coreUserSelectedTimezone,
            coreUserCompanyName,
            coreUserCompanyPosition,
            coreUserPhone,
            coreUserBio,
        ],
        apiCall: async () => {
            try {
                const updateCoreUserInformationInput: UpdateUserInformationInput = {
                    first_name: coreUserFirstName.value,
                    last_name: coreUserLastName.value,
                    company_name: coreUserCompanyName.value,
                    company_position: coreUserCompanyPosition.value,
                    address_country: coreUserSelectedAddressCountryKey.value ?? '',
                    timezone_name: coreUserSelectedTimezone.value ?? '',
                    phone: coreUserPhone.value,
                    bio: coreUserBio.value,
                };

                // Update core user information
                const coreUserRef = await updateCoreUserInformation({
                    id: data!.coreUserId,
                    input: updateCoreUserInformationInput,
                });

                coreUserApi.update(updateCoreUserInformationInput);

                return { response: coreUserRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const timezoneNames = useMemo<TimezoneSelectItem[]>(() => {
        const extendedTimezoneNames: TimezoneSelectItem[] = [];

        for (const timezoneName of TimezoneUtils.getTimezoneNames()) {
            extendedTimezoneNames.push({ GMT: TimezoneUtils.getTimezoneGMTValue(timezoneName), name: timezoneName });
        }

        return extendedTimezoneNames;
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderCountryFormFieldSelect = () => {
        const filterItemOption = (query: string, item: CountrySelectItem) => {
            return item.countryName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: CountrySelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.key === coreUserSelectedAddressCountryKey.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.key}
                    text={item.countryName}
                    label={item.key}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: CountrySelectItem) => {
            coreUserSelectedAddressCountryKey.handleChange(item.key);
        };

        return (
            <FormGroup
                label="Country"
                intent={!!coreUserSelectedAddressCountryKey.error ? Intent.DANGER : Intent.NONE}
                helperText={coreUserSelectedAddressCountryKey.error}
            >
                <Select<CountrySelectItem>
                    filterable
                    items={Object.values(countriesData)}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for country',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                        onClose: coreUserSelectedAddressCountryKey.handleClose,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a country',
                        text:
                            coreUserSelectedAddressCountryKey.value &&
                            countriesData[coreUserSelectedAddressCountryKey.value].countryName,
                        icon: 'add',
                    }}
                />
            </FormGroup>
        );
    };

    const renderTimezoneFormFieldSelect = () => {
        const filterItemOption = (query: string, item: TimezoneSelectItem) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: TimezoneSelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.name === coreUserSelectedTimezone.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.name}
                    text={item.name}
                    label={item.GMT}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: TimezoneSelectItem) => {
            coreUserSelectedTimezone.handleChange(item.name);
        };

        return (
            <FormGroup
                label="Time zone"
                intent={!!coreUserSelectedTimezone.error ? Intent.DANGER : Intent.NONE}
                helperText={coreUserSelectedTimezone.error}
            >
                <Select<TimezoneSelectItem>
                    filterable
                    items={timezoneNames}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for time zone',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a timezone',
                        text: coreUserSelectedTimezone.value,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Edit member</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        {/* First name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={coreUserFirstName}
                                formGroupProps={{ label: 'First name' }}
                                inputProps={{ placeholder: 'Enter first name' }}
                            />
                        </Grid>

                        {/* Last name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={coreUserLastName}
                                formGroupProps={{ label: 'Last name' }}
                                inputProps={{ placeholder: 'Enter last name' }}
                            />
                        </Grid>

                        {/* Country */}
                        <Grid lg={12} xs={12}>
                            {renderCountryFormFieldSelect()}
                        </Grid>

                        {/* Company name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={coreUserCompanyName}
                                formGroupProps={{ label: 'Company name' }}
                                inputProps={{ placeholder: 'Enter company name' }}
                            />
                        </Grid>

                        {/* Company position */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={coreUserCompanyPosition}
                                formGroupProps={{ label: 'Company position' }}
                                inputProps={{ placeholder: 'Enter company position' }}
                            />
                        </Grid>

                        {/* Timezone */}
                        <Grid lg={12}>{renderTimezoneFormFieldSelect()}</Grid>

                        {/* Phone number */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={coreUserPhone}
                                formGroupProps={{ label: 'Phone number' }}
                                inputProps={{ placeholder: 'Enter phone number' }}
                            />
                        </Grid>

                        {/* Bio */}
                        <Grid lg={12} xs={12}>
                            <TextAreaFormField
                                field={coreUserBio}
                                formGroupProps={{ label: 'Bio' }}
                                textAreaProps={{ placeholder: 'Enter bio' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateCoreUserMainInformationModal;
