import React, { HTMLAttributes, useState } from 'react';
import DevText from '@components/Text';
import { Collapse, Icon } from '@blueprintjs/core';
import {
    IconContainer,
    TitleContainer,
    Wrapper,
} from '@containers/components/Layout/components/NextMenu/components/MenuItemSection/styled';

export interface MenuItemSectionProps {
    title: string;
    collapsible?: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & MenuItemSectionProps;

const MenuItemSection: React.FC<Props> = ({
    children,
    className,
    title,
    collapsible = false,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    if (!children) {
        return null;
    }

    const handleTitleClick = () => {
        if (!collapsible) {
            return;
        }

        setIsOpen(!isOpen);
    };

    return (
        <Wrapper className={className}>
            <TitleContainer style={{ cursor: collapsible ? 'pointer' : 'inherit' }} onClick={handleTitleClick}>
                <DevText disabled className={`mb-1 mr-1`}>{title}</DevText>
                {collapsible && (
                    <IconContainer>
                        <Icon
                            color={'#8d9197'}
                            className={'mb-1'}
                            icon={isOpen ? 'chevron-down' : 'chevron-up'}
                            size={13}
                        />
                    </IconContainer>

                )}
            </TitleContainer>

            <Collapse isOpen={isOpen}>
                {children}
            </Collapse>
        </Wrapper>
    );
};

export default MenuItemSection;
