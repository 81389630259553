import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { $clientPaymentAccount } from '@app/containers/pages/Client/store/states';
import { Endpoints } from '@app/data/consts';
import { Button, Card } from '@blueprintjs/core';
import { StripeUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const PaymentAccount: React.FC<Props> = (props) => {
    const clientPaymentAccount = useStore($clientPaymentAccount);
    if (!clientPaymentAccount) {
        return null;
    }

    return (
        <Card compact {...props}>
            <Flex direction="row" justify="space-between" align="center">
                <Heading type="h5">Stripe ID: {clientPaymentAccount.external_id}</Heading>
                <Button
                    onClick={() =>
                        window.open(
                            StripeUtils.getCustomerStripeURL(
                                clientPaymentAccount.external_id,
                                process.env.STRIPE_BASE_INFORMATION_URL ?? Endpoints.TASKS
                            )
                        )
                    }
                >
                    View in Stripe
                </Button>
            </Flex>
        </Card>
    );
};

export default PaymentAccount;
