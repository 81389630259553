import React, { HTMLAttributes } from 'react';
import Heading from '@components/Heading';
import { useStore } from 'effector-react';
import GoogleAuthControl from './components/GoogleAuthControl';
import PasswordAuthControl from './components/PasswordAuthControl';
import { $userIdentity } from '../../store/states';

export type Props = HTMLAttributes<HTMLDivElement>;

const AuthenticationMethodList: React.FC<Props> = (props) => {
    const identity = useStore($userIdentity);

    if (!identity) {
        return null;
    }

    return (
        <div {...props}>
            <Heading type="h3" className="mb-1">Authentication methods</Heading>
            <div className="mb-1">
                <PasswordAuthControl />
            </div>
            <div className="mb-1">
                <GoogleAuthControl />
            </div>
        </div>
    );
};

export default AuthenticationMethodList;
