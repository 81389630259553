enum CardType {
    ALIPAY = 'alipay',
    AMERICAN_EXPRESS = 'amex',
    DINERS = 'diners',
    DISCOVER = 'discover',
    ELO = 'elo',
    UNKNOWN = 'unknown',
    HIPER = 'hiper',
    JCB = 'jcb',
    MAESTRO = 'maestro',
    MASTERCARD = 'mastercard',
    MIR = 'mir',
    PAYPAL = 'paypal',
    UNIONPAY = 'unionpay',
    VISA = 'visa',
}

export default CardType;
