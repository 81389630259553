import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    TaskDeliverableCommentResource,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import {
    CreateTaskDeliverableCommentInput,
    TaskDeliverableCommentFilterInput,
    UpdateTaskDeliverableCommentInput,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/inputs';
import { TaskDeliverableCommentRef } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/refs';
import domain from './domain';

// Fetch list of task deliverable comments
export const fetchTaskDeliverableComments = domain.createEffect<
    TaskDeliverableCommentFilterInput,
    CollectionResource<TaskDeliverableCommentResource, {}>
>({
    name: 'fetchTaskDeliverableComments',
    handler: async (filter) => {
        return await repository.taskDeliverableComment().get(filter);
    },
});

// Create task deliverable comment
export const createTaskDeliverableComment = domain.createEffect<
    CreateTaskDeliverableCommentInput,
    TaskDeliverableCommentRef
>({
    name: 'createTaskDeliverableComment',
    handler: async (input) => {
        return await repository.taskDeliverableComment().create(input);
    },
});

// Update task deliverable comment
export const updateTaskDeliverableComment = domain.createEffect<
    { id: ID; input: UpdateTaskDeliverableCommentInput },
    TaskDeliverableCommentRef
>({
    name: 'updateTaskDeliverableComment',
    handler: async ({ id, input }) => {
        return await repository.taskDeliverableComment().update(id, input);
    },
});

// Archive task deliverable comment
export const archiveTaskDeliverableComment = domain.createEffect<ID, TaskDeliverableCommentRef>({
    name: 'archiveTaskDeliverableComment',
    handler: async (id) => {
        return await repository.taskDeliverableComment().archive(id);
    },
});

// Restore archived task deliverable comment
export const restoreTaskDeliverableComment = domain.createEffect<ID, TaskDeliverableCommentRef>({
    name: 'restoreTaskDeliverableComment',
    handler: async (id) => {
        return await repository.taskDeliverableComment().restore(id);
    },
});

// Mark task deliverable comment as resolved
export const markTaskDeliverableCommentAsResolved = domain.createEffect<ID, TaskDeliverableCommentRef>({
    name: 'markTaskDeliverableCommentAsResolved',
    handler: async (id) => {
        return await repository.taskDeliverableComment().markAsResolved(id);
    },
});

// Remove task deliverable comment resolved status
export const removeTaskDeliverableCommentResolvedStatus = domain.createEffect<ID, TaskDeliverableCommentRef>({
    name: 'removeTaskDeliverableCommentResolvedStatus',
    handler: async (id) => {
        return await repository.taskDeliverableComment().removeResolvedStatus(id);
    },
});
