import { SubscriptionCancelType } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';

const subscriptionCancelTypeInformation: { [type in SubscriptionCancelType]: string } = {
    [SubscriptionCancelType.UNKNOWN]: 'Unknown',
    [SubscriptionCancelType.CANCELLATION_REQUESTED]: 'Canceled manually',
    [SubscriptionCancelType.PAYMENT_DISPUTED]: 'Payment disputed',
    [SubscriptionCancelType.PAYMENT_FAILED]: 'Payment failed',
};

export default subscriptionCancelTypeInformation;
