import styled from 'styled-components';
import { Card as DefCard } from '@blueprintjs/core';
import Overlay from '@components/Overlay';

export const StyledOverlay = styled(Overlay)`
    #overlay-content {
        position: fixed;
    }
`;

export const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
`;

export const Card = styled(DefCard)`
    display: flex;
    flex-direction: column;
    padding: 0;

    width: 100%;
    height: 95%;
`;

export const DismissModalPanel = styled.div`
    width: 100%;
    height: 5%;
    cursor: pointer;
    background: transparent;

    transition: background 250ms ease;

    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;
