import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Position, Radio } from '@blueprintjs/core';
import { PlatformClientUserFilterData } from '@pages/Clients/types';
import { initialPlatformClientUserFilterData, platformClientUserFiltersInformation } from '@pages/Clients/consts';
import { PlatformClientUserFilterType } from '@pages/Clients/enums';
import { $isQueryHydrationFinished } from '@pages/Clients/store/states';
import { setPlatformClientUserFilterData } from '@pages/Clients/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';

export interface ClientUserArchivedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

export type Props = ClientUserArchivedPlatformFilterProps;

const includeShutdownClientUsersRadioValue = 'include';
const excludeShutdownClientUsersRadioValue = 'exclude';

const ClientUserArchivedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isShutdownClientUsersVisible, setIsShutdownClientUsersVisible] = useState<
        NonNullable<PlatformClientUserFilterData['isShutdownClientUsersVisible']>
    >(!!platformClientUserFilterData.isShutdownClientUsersVisible);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsShutdownClientUsersVisible(!!platformClientUserFilterData.isShutdownClientUsersVisible);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (!platformClientUserFilterData.isShutdownClientUsersVisible) {
            setIsShutdownClientUsersVisible(!!platformClientUserFilterData.isShutdownClientUsersVisible);
        }
    }, [platformClientUserFilterData.isShutdownClientUsersVisible]);

    const getFilterTagLabel = () => {
        if (isShutdownClientUsersVisible) {
            return 'Include archived clients';
        } else {
            return 'Exclude archived clients ';
        }
    };

    const getFilterTagTitle = () => {
        return 'Archived:';
    };

    const handleRemoveFilter = () => {
        setIsShutdownClientUsersVisible(false);
        setPlatformClientUserFilterData({
            ...platformClientUserFilterData,
            isShutdownClientUsersVisible: initialPlatformClientUserFilterData['isShutdownClientUsersVisible'],
        });
        setIsOpen(false);
        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.ARCHIVED].isPinned) {
            // Platform client user archived filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformClientUserFilterData.isShutdownClientUsersVisible !== isShutdownClientUsersVisible) {
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, isShutdownClientUsersVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
    };

    const renderRow = (key: number) => {
        const selectedRadioValue = isShutdownClientUsersVisible
            ? includeShutdownClientUsersRadioValue
            : excludeShutdownClientUsersRadioValue;

        const handleArchivedFlagVisibility = (value: string) => {
            switch (value) {
                case includeShutdownClientUsersRadioValue:
                    setIsShutdownClientUsersVisible(true);
                    break;
                case excludeShutdownClientUsersRadioValue:
                    setIsShutdownClientUsersVisible(false);
                    break;
            }
        };

        return (
            <li className="mb-1" key={key}>
                <Radio
                    checked={selectedRadioValue === includeShutdownClientUsersRadioValue}
                    className="mb-1"
                    label="Include archived clients"
                    onChange={() => handleArchivedFlagVisibility(includeShutdownClientUsersRadioValue)}
                />

                <Radio
                    checked={selectedRadioValue === excludeShutdownClientUsersRadioValue}
                    label="Exclude archived clients"
                    onChange={() => handleArchivedFlagVisibility(excludeShutdownClientUsersRadioValue)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Archived"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.ARCHIVED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default ClientUserArchivedPlatformFilter;
