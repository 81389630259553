import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringInvalidEmailMessage = "You've provided invalid email";
const emailStringRegex = /^\S+@\S+\.\S+$/;
const getStringContainOnlyLettersValidator: GetValidatorFunction<{ stringInvalidEmailMessage?: string }, string> = (
    options = { stringInvalidEmailMessage: defaultStringInvalidEmailMessage }
) => {
    const { stringInvalidEmailMessage } = options;

    return async (value) => {
        if (emailStringRegex.test(value)) {
            // String valid email
            return null;
        } else {
            // String is not valid email
            if (stringInvalidEmailMessage) {
                return stringInvalidEmailMessage;
            } else {
                return defaultStringInvalidEmailMessage;
            }
        }
    };
};

export default getStringContainOnlyLettersValidator;
