import { IconName } from '@blueprintjs/core';
import { PlatformCoreUserFilterType } from '../enums';

const platformCoreUserFiltersInformation: {
    [type in PlatformCoreUserFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    /* Static */
    [PlatformCoreUserFilterType.ARCHIVED]: {
        title: 'Archived',
        icon: 'remove',
        isPinned: false,
    },

    /* Dynamic */
    [PlatformCoreUserFilterType.TEAMS]: {
        title: 'Teams',
        icon: 'hat',
        isPinned: true,
    },
};

export default platformCoreUserFiltersInformation;
