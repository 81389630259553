import React, { useEffect } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { SessionStorage } from '@data/enums';
import { Endpoints } from '@data/consts';
import { PlatformTaskFilterData, TaskFilterQueryParameters } from './types';
import { PlatformTaskFilter as PlatformTaskFilterValueObject } from './valueObjects';
import { amountOfTasksOnPage } from './consts';
import { $isQueryHydrationFinished, $page, $platformTaskFilterData, $tasks } from './store/states';
import { fetchTasks, hydrateQueryParameters } from './store/effects';
import {
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformTaskFilterData,
    resetTasks,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformTaskFilterData,
} from './store/events';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Pagination from '@components/Pagination';
import PlatformTaskFilter from './components/PlatformTaskFilter';
import TaskList from './components/TaskList';
import { Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';

const Tasks: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.task) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Requests');

    const location = useLocation();
    const navigate = useNavigate();

    const page = useStore($page);
    const tasks = useStore($tasks);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformTaskFilterData = useStore($platformTaskFilterData);
    const isFetchingTasks = useStore(fetchTasks.pending);

    const handleLoadPage = (newPage: number, filter?: TaskFilterInput) => {
        const pageOffset = (newPage - 1) * amountOfTasksOnPage;

        fetchTasks({
            pagination: {
                _pagination: { limit: amountOfTasksOnPage, offset: pageOffset },
            },
            filter,
        }).catch((e) => {
            // TODO: handle error

            console.error(e);
        });
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Empty query string, load from local storage
            const savedUrlQueryParameters = sessionStorage.getItem(SessionStorage.TASK_FILTER);

            if (savedUrlQueryParameters && savedUrlQueryParameters.length > 0) {
                urlQueryParameters = savedUrlQueryParameters;
            }
        }

        // Task filter query parameters
        const taskFilterQueryParameters: Partial<TaskFilterQueryParameters> = qs.parse(urlQueryParameters);

        // Hydration input
        const hydrateQueryInput =
            PlatformTaskFilterValueObject.convertTaskFilterQueryParametersToHydrateQueryInput(
                taskFilterQueryParameters,
            );

        // Platform task filter data
        const platformTaskFilterData: PlatformTaskFilterData = {
            query: taskFilterQueryParameters.query ?? '',
            publishTypes: taskFilterQueryParameters.publishTypes ?? [],
            queues: taskFilterQueryParameters.queues ?? [],
            states: taskFilterQueryParameters.states ?? [],
            clients: [],
            participants: [],
            taskCategories: [],
            workspaces: [],
        };

        // hydrate
        hydrateQueryParameters(hydrateQueryInput)
            .then(({ client_user, core_user, task_category, workspace }) => {
                platformTaskFilterData.clients = client_user ?? [];
                platformTaskFilterData.participants = core_user ?? [];
                platformTaskFilterData.taskCategories = task_category ?? [];
                platformTaskFilterData.workspaces = workspace ?? [];
            })
            .finally(() => {
                const page = taskFilterQueryParameters.page ? parseInt(taskFilterQueryParameters.page) : 1;

                setPage(page);
                setPlatformTaskFilterData(platformTaskFilterData);
                setIsQueryHydrationFinished(true);
            });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            // Get task filter input
            const tasksFilterInput =
                PlatformTaskFilterValueObject.convertPlatformTaskFilterDataToTaskFilterInput(platformTaskFilterData);

            // Get task filter query parameters string
            const taskFilterQueryParametersString = qs.stringify(
                PlatformTaskFilterValueObject.convertTaskFilterInputToTaskFilterQueryParameters(page, tasksFilterInput),
            );

            // Save query parameters to session storage
            sessionStorage.setItem(SessionStorage.TASK_FILTER, taskFilterQueryParametersString);

            // Update URL
            navigate(`${Endpoints.TASKS}?${taskFilterQueryParametersString}`, { replace: true });

            // Fetch task list with new page
            handleLoadPage(page, tasksFilterInput);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformTaskFilterData]);

    useEffect(() => {
        return () => {
            resetTasks();
            resetIsQueryHydrationFinished();
            resetPage();
            resetPlatformTaskFilterData();
        };
    }, []);

    if (!tasks) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Requests</Heading>
            </Flex>
        );
    };

    const renderTasksList = () => {
        // TODO: add logic to check which type of view should be: table or card list
        return <TaskList />;
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (tasks === null) {
            return null;
        }

        // Check if offset paginator exist
        if (tasks.paginator === null) {
            return;
        }

        // Only one page exist
        if (!tasks.paginator.has_more && tasks.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTasks}
                    hasMore={tasks.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTasksOnPage}
                    totalItems={tasks.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformTaskFilter className="mb-2" />

            {/* Tasks list */}
            {renderTasksList()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default Tasks;
