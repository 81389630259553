import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { ClientUserFilterInput } from 'dy-frontend-http-repository/lib/modules/ClientUser/inputs';
import { PlatformClientUserFilterType } from '../../enums';
import { PlatformClientUserFilterData, ClientUserFilterQueryParameters } from '../../types';
import { initialPlatformClientUserFilterData, platformClientUserFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';

export default class PlatformClientUserFilter {
    static convertHydratedQueryParametersToPlatformClientUserFilterData(
        hydratedQueryParameters: HydratedQueryParameters
    ): PlatformClientUserFilterData {
        // Create empty platform client user filter data object
        const platformClientUserFilterData: PlatformClientUserFilterData = { ...initialPlatformClientUserFilterData };

        // Set query
        if (hydratedQueryParameters.query) {
            platformClientUserFilterData.query = hydratedQueryParameters.query;
        }

        /* Static filters */
        if (hydratedQueryParameters.isShutdownClientUsersVisible !== undefined) {
            // Set shutdown core users visible flag
            platformClientUserFilterData.isShutdownClientUsersVisible =
                hydratedQueryParameters.isShutdownClientUsersVisible === 'true' ? true : false;
        }

        if (hydratedQueryParameters.isVerifiedClientUsersVisible !== undefined) {
            // Set verified core users visible flag
            platformClientUserFilterData.isVerifiedClientUsersVisible =
                hydratedQueryParameters.isVerifiedClientUsersVisible === 'true' ? true : false;
        }

        /* Dynamic filters */
        if (hydratedQueryParameters.workspace) {
            // Set workspaces
            platformClientUserFilterData.workspaces = hydratedQueryParameters.workspace;
        }

        if (hydratedQueryParameters.plan) {
            // Set plans
            platformClientUserFilterData.plans = hydratedQueryParameters.plan;
        }

        return platformClientUserFilterData;
    }

    static convertPlatformClientUserFilterDataToClientUserFilterInput(
        platformClientUserFilterData: PlatformClientUserFilterData
    ): ClientUserFilterInput {
        const clientUserFilterInput: ClientUserFilterInput = {};

        if (platformClientUserFilterData.query) {
            // Set query
            clientUserFilterInput.query = platformClientUserFilterData.query;
        }

        /* Static filters */

        // Set shutdown client users visibility flag
        if (platformClientUserFilterData.isShutdownClientUsersVisible !== undefined) {
            clientUserFilterInput.is_shutdown = platformClientUserFilterData.isShutdownClientUsersVisible ? '1' : '0';
        }

        // Set verified client users visibility flag
        if (platformClientUserFilterData.isVerifiedClientUsersVisible !== undefined) {
            clientUserFilterInput.is_verified = platformClientUserFilterData.isVerifiedClientUsersVisible ? '1' : '0';
        }

        /* Dynamic filters */
        if (platformClientUserFilterData.plans.length > 0) {
            // Set workspaces
            clientUserFilterInput.workspace_id = platformClientUserFilterData.workspaces.map((workspace) => workspace.id);
        }

        if (platformClientUserFilterData.plans.length > 0) {
            // Set plans
            clientUserFilterInput.licence_plan_id = platformClientUserFilterData.plans.map((plan) => plan.id);
        }

        return clientUserFilterInput;
    }

    static convertClientUserFilterInputToClientUserFilterQueryParameters = (
        page: number,
        clientUserFilterInput: ClientUserFilterInput
    ): ClientUserFilterQueryParameters => {
        const clientUserFilterQueryParameters: ClientUserFilterQueryParameters = {
            query: '',
            page: '1',
            planIds: [],
            workspaceIds: [],
        };

        // Set page
        clientUserFilterQueryParameters.page = `${page}`;

        // Set shutdown client users visibility flag
        if (clientUserFilterInput.is_shutdown !== undefined) {
            clientUserFilterQueryParameters.isShutdownClientUsersVisible =
                clientUserFilterInput.is_shutdown === '1' ? 'true' : 'false';
        }

        // Set verified client users visibility flag
        if (clientUserFilterInput.is_verified !== undefined) {
            clientUserFilterQueryParameters.isVerifiedClientUsersVisible =
                clientUserFilterInput.is_verified === '1' ? 'true' : 'false';
        }

        // Set workspace IDs
        if (clientUserFilterInput.workspace_id && clientUserFilterInput.workspace_id.length > 0) {
            clientUserFilterQueryParameters.workspaceIds = clientUserFilterInput.workspace_id;
        }

        // Set plan IDs
        if (clientUserFilterInput.licence_plan_id && clientUserFilterInput.licence_plan_id.length > 0) {
            clientUserFilterQueryParameters.planIds = clientUserFilterInput.licence_plan_id;
        }

        return clientUserFilterQueryParameters;
    };

    static convertClientUserFilterQueryParametersToHydrateQueryInput = (
        clientUserFilterQueryParameters: Partial<ClientUserFilterQueryParameters>
    ) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        // Set client user workspace ids
        if (clientUserFilterQueryParameters.workspaceIds && clientUserFilterQueryParameters.workspaceIds.length > 0) {
            hydrateQueryInput.workspace_id = clientUserFilterQueryParameters.workspaceIds;
        }

        // Set client user plan ids
        if (clientUserFilterQueryParameters.planIds && clientUserFilterQueryParameters.planIds.length > 0) {
            hydrateQueryInput.plan_id = clientUserFilterQueryParameters.planIds;
        }

        return hydrateQueryInput;
    };

    static getAppliedPlatformFilters(
        platformClientUserFilterData: PlatformClientUserFilterData
    ): PlatformClientUserFilterType[] {
        const appliedPlatformFilters: PlatformClientUserFilterType[] = [];

        const platformClientUserFilterTypeValues = Object.values(PlatformClientUserFilterType);

        for (let i = 0; i < platformClientUserFilterTypeValues.length; i++) {
            const platformClientUserFilterType = platformClientUserFilterTypeValues[i];

            if (platformClientUserFiltersInformation[platformClientUserFilterType].isPinned) {
                // Platform client user filter is pinned by default
                appliedPlatformFilters.push(platformClientUserFilterType);
                continue;
            }

            switch (platformClientUserFilterType) {
                /* Static */
                case PlatformClientUserFilterType.ARCHIVED:
                    if (platformClientUserFilterData.isShutdownClientUsersVisible !== undefined) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.ARCHIVED);
                    }
                    break;

                case PlatformClientUserFilterType.VERIFIED:
                    if (platformClientUserFilterData.isVerifiedClientUsersVisible !== undefined) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.VERIFIED);
                    }
                    break;
                /* Dynamic */
                // case PlatformClientUserFilterType.WORKSPACES:
                //     if (platformClientUserFilterData.plans.length > 0) {
                //         appliedPlatformFilters.push(PlatformClientUserFilterType.WORKSPACES);
                //     }
                //     break;
                case PlatformClientUserFilterType.LICENCE_PLANS:
                    if (platformClientUserFilterData.plans.length > 0) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.LICENCE_PLANS);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
