import { Button, Icon, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import { openModal } from '@modals/store/events';
import { fetchTaskMessageTemplates } from '@pages/TaskMessageTemplates/store/effects';
import { resetTaskMessageTemplates } from '@pages/TaskMessageTemplates/store/events';
import {
    $selectedTaskMessageTemplateCategoryId,
    $taskMessageTemplates,
} from '@pages/TaskMessageTemplates/store/states';
import { TaskMessageTemplateFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/inputs';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import TaskMessageTemplateListItem from './components/TaskMessageTemplateListItem';
import { amountOfTaskMessageTemplatesOnPage } from './consts';
import Heading from '@components/Heading';
import CreateTaskMessageTemplateModal from '../../modals/CreateTaskMessageTemplateModal';
import { $permissions } from '@containers/store/states';
import { TaskMessageTemplatePermission } from 'dy-frontend-permissions/lib/permission';

const Content: React.FC = () => {
    const permissions = useStore($permissions);

    const selectedTaskMessageTemplateCategoryId = useStore($selectedTaskMessageTemplateCategoryId);
    const taskMessageTemplates = useStore($taskMessageTemplates);
    const isFetchingTaskMessageTemplates = useStore(fetchTaskMessageTemplates.pending);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isSearchInputTouched, setIsSearchInputTouched] = useState(false);

    const handleLoadPage = (newPage: number, search?: string) => {
        // Common filters
        const filter: TaskMessageTemplateFilterInput = {};

        // Check if search was provided
        if (search) {
            filter.query = search;
        }

        // Check if task message template category was selected
        if (selectedTaskMessageTemplateCategoryId) {
            filter.template_category_id = [selectedTaskMessageTemplateCategoryId];
        }

        const pageOffset = (newPage - 1) * amountOfTaskMessageTemplatesOnPage;

        fetchTaskMessageTemplates({
            pagination: {
                _pagination: { limit: amountOfTaskMessageTemplatesOnPage, offset: pageOffset },
            },
            filter,
        }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    };

    useEffect(() => {
        handleLoadPage(page, search);

        // eslint-disable-next-line
    }, [selectedTaskMessageTemplateCategoryId, page]);

    useEffect(() => {
        if (!isSearchInputTouched) {
            return;
        }

        const timeoutId = setTimeout(() => handleLoadPage(page, search), 500);

        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        return () => {
            resetTaskMessageTemplates();
        };
    }, []);

    const renderCreateTaskMessageTemplateButton = () => {
        // Auth
        const isAllowed = permissions.isRoot.taskMessageTemplate || permissions.has(TaskMessageTemplatePermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="new-object"
                intent={Intent.PRIMARY}
                onClick={() =>
                    openModal({
                        ModalComponent: CreateTaskMessageTemplateModal,
                    })
                }
            >
                Create message template
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <InputGroup
                    leftIcon="search"
                    placeholder="Search message template"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={(e) => setIsSearchInputTouched(true)}
                />

                {renderCreateTaskMessageTemplateButton()}
            </Flex>
        );
    };

    const renderTaskMessageTemplatesList = () => {
        // Client brand profiles were NOT fetched yet
        if (!taskMessageTemplates) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client brand profiles were fetched, but where are no brand profiles existed for this client
        if (taskMessageTemplates.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No message templates were found
                        </Heading>
                    }
                />
            );
        }

        // TODO: add imageSrc whenever files will be implemented on backend
        return taskMessageTemplates.items.map((template) => (
            <TaskMessageTemplateListItem className="mt-2" key={template.id} template={template} />
        ));
    };

    const renderPagination = () => {
        // Task message templates were not fetched yet
        if (taskMessageTemplates === null) {
            return null;
        }

        // Check if offset paginator exist
        if (taskMessageTemplates.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskMessageTemplates.paginator.has_more && taskMessageTemplates.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTaskMessageTemplates}
                    hasMore={taskMessageTemplates.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTaskMessageTemplatesOnPage}
                    totalItems={taskMessageTemplates.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            {/* Header with search input and "create task message template" button */}
            {renderHeader()}

            {/* Task message templates list */}
            {renderTaskMessageTemplatesList()}

            {/* Pagination */}
            {renderPagination()}
        </div>
    );
};

export default Content;
