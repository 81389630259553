import React, { HTMLAttributes, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { StyledDropzoneArea } from './styled';

export interface DropzoneAreaProps {
    visible: boolean;
    loading?: boolean;
    multiple?: boolean;
    extensionsToAccept?: string[];
    uploadFiles: (files: File[]) => any;
}
export type Props = HTMLAttributes<HTMLDivElement> & DropzoneAreaProps;

const DropzoneArea: React.FC<Props> = ({
    visible,
    loading,
    multiple,
    extensionsToAccept = [],
    uploadFiles,
    children,
    ...props
}) => {
    const onDrop = useCallback((acceptedFiles: File[]) => uploadFiles(acceptedFiles), [uploadFiles]);
    const { getRootProps, isDragActive } = useDropzone({
        disabled: loading,
        accept: extensionsToAccept.length > 0 ? { accept: extensionsToAccept } : undefined,
        onDrop,
    });

    return (
        <StyledDropzoneArea {...props} {...getRootProps()} $isVisible={visible} $isHovering={isDragActive}>
            {children}
        </StyledDropzoneArea>
    );
};

export default DropzoneArea;
