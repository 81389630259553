import React from 'react';
import { Intent, Tag } from '@blueprintjs/core';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { subscriptionStatesInformation } from '@data/consts';
import {
    checkIfSubscriptionCancellationPending,
    checkIfSubscriptionPaused,
    checkIfSubscriptionPausedExternally,
    checkIfSubscriptionPauseScheduled,
    checkIfSubscriptionTrialPresent,
    checkIfSubscriptionUpdateScheduled,
} from '@data/functions/subscription';

export interface SubscriptionStateTagsProps {
    subscription: SubscriptionResource;
}

export type Props = SubscriptionStateTagsProps;

const SubscriptionStateTags: React.FC<Props> = ({ subscription, ...props }) => {
    const tags: JSX.Element[] = [];

    // Subscription state tag
    tags.push(
        <Tag className="mr-small" key="subscription-state">
            {subscriptionStatesInformation[subscription.state].label}
        </Tag>
    );

    // Awaiting cancellation
    if (checkIfSubscriptionCancellationPending(subscription)) {
        // Subscription will be cancelled in the future
        tags.push(
            <Tag className="mr-small" key="subscription-awaits-cancellation-tag" intent={Intent.WARNING}>
                Awaits cancellation
            </Tag>
        );
    }

    // Paused tag
    if (checkIfSubscriptionPaused(subscription)) {
        // Subscription is paused
        if (checkIfSubscriptionPausedExternally(subscription)) {
            // Subscription paused externally
            tags.push(
                <Tag className="mr-small" key="subscription-paused-externally-tag" icon="pause">
                    Paused externally
                </Tag>
            );
        } else {
            // Subscription paused through the platform
            tags.push(
                <Tag className="mr-small" key="subscription-paused-tag" icon="pause">
                    Paused
                </Tag>
            );
        }
    } else {
        // Subscription pause is scheduled
        if (checkIfSubscriptionPauseScheduled(subscription)) {
            tags.push(
                <Tag className="mr-small" key="subscription-pause-schedule-tag" icon="pause">
                    Pause scheduled
                </Tag>
            );
        }
    }

    // Trial tag
    if (checkIfSubscriptionTrialPresent(subscription)) {
        tags.push(
            <Tag className="mr-small" icon="time" key="subscription-on-trial-tag" intent={Intent.PRIMARY}>
                On Trial
            </Tag>
        );
    }

    // Subscription update scheduled tag
    if (checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription update is scheduled
        tags.push(
            <Tag
                className="mr-small"
                icon="automatic-updates"
                key="subscription-schedule-present"
                intent={Intent.PRIMARY}
            >
                Update scheduled
            </Tag>
        );
    }

    return <React.Fragment {...props}>{tags}</React.Fragment>;
};

export default SubscriptionStateTags;
