import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { initialPlatformCoreUserFilterData, platformCoreUserFiltersInformation } from '../../consts';
import { PlatformCoreUserFilterType } from '../../enums';
import { resetPage, resetPlatformCoreUserFilterData, setPlatformCoreUserFilterData } from '../../store/events';
import { $isQueryHydrationFinished, $platformCoreUserFilterData } from '../../store/states';
import { PlatformCoreUserFilter as PlatformCoreUserFilterValueObject } from '../../valueObjects';
import CoreUserArchivedPlatformFilter from './components/CoreUserArchivedPlatformFilter';
import CoreUserTeamsPlatformFilter from './components/CoreUserTeamsPlatformFilter';
import PlatformCoreUserFilterQueryInput from './components/PlatformCoreUserFilterQueryInput';
import { AddFilterPopoverContent } from './styled';
import DevText from '@components/Text';
import { fetchCoreUsers } from '../../store/effects';

const platformCoreUserFilterTypes = Object.values(PlatformCoreUserFilterType);

export type Props = HTMLAttributes<HTMLDivElement>;

const PlatformCoreUserFilter: React.FC<Props> = (props) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformCoreUserFilterData = useStore($platformCoreUserFilterData);
    const isFetching = useStore(fetchCoreUsers.pending);


    const [query, setQuery] = useState('');
    const [isAddFilterPopoverOpened, setIsAddFilterPopoverOpened] = useState(false);
    const [platformFilters, setPlatformFilters] = useState<PlatformCoreUserFilterType[]>([]);
    const [isLastAddedShouldBeOpenedMode, setIsLastAddedShouldBeOpenedMode] = useState(false);

    const isAllFiltersAdded = platformCoreUserFilterTypes.length === platformFilters.length;

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setPlatformFilters(PlatformCoreUserFilterValueObject.getAppliedPlatformFilters(platformCoreUserFilterData));
            setQuery(platformCoreUserFilterData.query);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    const handleAddFilter = (type: PlatformCoreUserFilterType) => {
        if (!platformFilters.includes(type)) {
            setIsAddFilterPopoverOpened(false);
            setPlatformFilters((filters) => [...filters, type]);
        }
    };

    const renderPlatformCoreUserFilterQueryInput = () => {
        const trimmedCurrentQuery = query.trim();
        const isLastQueryDiffersFromCurrentOne = platformCoreUserFilterData.query.trim() !== trimmedCurrentQuery;

        return (
            <PlatformCoreUserFilterQueryInput
                loading={isFetching && (trimmedCurrentQuery.length !== 0 || isLastQueryDiffersFromCurrentOne)}
                shouldBeSearched={isLastQueryDiffersFromCurrentOne}
                className="mb-2"
                placeholder="Search by member name or ID"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onSearch={(newSearch: string) => {
                    setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, query: newSearch });
                    resetPage();
                }}
            />
        );
    };

    const renderFilterOfType = (type: PlatformCoreUserFilterType, defaultIsOpen: boolean) => {
        const commonPlatformFilterProps = {
            defaultIsOpen,
            platformCoreUserFilterData: platformCoreUserFilterData,
            onRemove: () =>
                setPlatformFilters((platformFilters) =>
                    platformFilters.filter((platformFilterType) => platformFilterType !== type)
                ),
        };

        switch (type) {
            case PlatformCoreUserFilterType.TEAMS:
                return <CoreUserTeamsPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformCoreUserFilterType.ARCHIVED:
                return <CoreUserArchivedPlatformFilter {...commonPlatformFilterProps} />;
            default:
                return null;
        }
    };

    const renderPlatformFilters = () => {
        return platformFilters.map((type, index) => (
            <div key={type}>
                {renderFilterOfType(type, isLastAddedShouldBeOpenedMode && index === platformFilters.length - 1)}
            </div>
        ));
    };

    const renderAddPlatformFilterButton = () => {
        if (isAllFiltersAdded) {
            // No need to show "Add filter" button if all filters are already added
            return null;
        }

        const renderAddFilterPopoverContent = () => {
            const handleClickMenuItem = (platformCoreUserFilterType: PlatformCoreUserFilterType) => {
                if (isLastAddedShouldBeOpenedMode === false) {
                    setIsLastAddedShouldBeOpenedMode(true);
                }

                handleAddFilter(platformCoreUserFilterType);
            };

            return (
                <AddFilterPopoverContent>
                    <DevText muted>Select filter</DevText>

                    <Menu>
                        {Object.entries(platformCoreUserFiltersInformation).map(([key, { icon, title }]) => {
                            const platformCoreUserFilterType = key as PlatformCoreUserFilterType;

                            if (platformFilters.includes(platformCoreUserFilterType)) {
                                // Platform filter is already added, allowing to add it again doesn't make sense
                                return null;
                            }

                            return (
                                <MenuItem
                                    className="mt-1"
                                    key={key}
                                    icon={icon}
                                    text={title}
                                    onClick={() => handleClickMenuItem(platformCoreUserFilterType)}
                                />
                            );
                        })}
                    </Menu>
                </AddFilterPopoverContent>
            );
        };

        return (
            <div>
                <Popover
                    isOpen={isAddFilterPopoverOpened}
                    disabled={!isQueryHydrationFinished}
                    content={renderAddFilterPopoverContent()}
                    onClose={() => setIsAddFilterPopoverOpened(false)}
                >
                    <Button
                        className="mr-1"
                        icon="filter-list"
                        minimal
                        onClick={() => setIsAddFilterPopoverOpened(!isAddFilterPopoverOpened)}
                        loading={!isQueryHydrationFinished}
                    >
                        Add filter
                    </Button>
                </Popover>
            </div>
        );
    };

    const renderResetButton = () => {
        if (
            JSON.stringify(platformCoreUserFilterData).length ===
            JSON.stringify(initialPlatformCoreUserFilterData).length
        ) {
            // Platform core user filter data same as whenever it was initialized
            return null;
        }

        const handleResetButton = () => {
            // Reset page to 1
            resetPage();

            // Reset platform core user filter data to initial platform core user filter data
            resetPlatformCoreUserFilterData();

            // Create array of pinned platform filters
            const initialPlatformFilters: PlatformCoreUserFilterType[] = [];
            for (let i = 0; i < platformCoreUserFilterTypes.length; i++) {
                // Get platform core user filter information for platform core user filter type
                const platformCoreUserFilterInformation =
                    platformCoreUserFiltersInformation[platformCoreUserFilterTypes[i]];

                if (!platformCoreUserFilterInformation.isPinned) {
                    // We only push pinned platform filters
                    continue;
                }

                initialPlatformFilters.push(platformCoreUserFilterTypes[i]);
            }

            setPlatformFilters(initialPlatformFilters);
            setQuery('');
        };

        return (
            <Button minimal icon="trash" intent={Intent.DANGER} onClick={handleResetButton}>
                Reset
            </Button>
        );
    };

    return (
        <div {...props}>
            {renderPlatformCoreUserFilterQueryInput()}
            <Flex gap="8px" flexWrap="wrap">
                {renderPlatformFilters()}
                {renderAddPlatformFilterButton()}
                {renderResetButton()}
            </Flex>
        </div>
    );
};

export default PlatformCoreUserFilter;
