import { ToastUtils } from '@app/data/utils';
import { Intent } from '@blueprintjs/core';
import { useStripe } from '@stripe/react-stripe-js';
import { declineCodeInformation } from 'dy-frontend-shared/lib/data/consts';
// import { declineCodeInformation } from './consts';
// import { StripeDeclineCode } from './enums';

type ProcessPaymentFunctionGenericType = (args?: any) => Promise<any>;
export interface UsePaymentProps<F extends ProcessPaymentFunctionGenericType, R> {
    processPayment: F;
}
export type Props<F extends ProcessPaymentFunctionGenericType, R> = UsePaymentProps<F, R>;
function usePayment<F extends ProcessPaymentFunctionGenericType, R>({ processPayment }: Props<F, R>) {
    const stripe = useStripe();

    const handleProcessPayment = (...args: Parameters<F>): Promise<R> => {
        return new Promise((resolve, reject) => {
            processPayment(...args)
                .then((data) => resolve(data))
                .catch((e) => {
                    if (!stripe) {
                        // TODO: handle error
                        reject(e);
                        return;
                    }
                    // Response
                    const response = e.response;

                    // Data
                    const data = response.data;

                    // TODO: move error types on errors.md to enum/const/etc.
                    if (data.type === 'payment') {
                        const payment = data.payment;

                        if (payment.last_error === null) {
                            // 3Ds secure

                            if (payment.client_secret !== null && payment.payment_method_id) {
                                stripe
                                    .confirmCardPayment(payment.client_secret, {
                                        payment_method: payment.payment_method_id,
                                    })
                                    .then(() => processPayment(args).then((e) => resolve(e)))
                                    .catch((e) => {
                                        // TODO: handle error
                                        console.error(e);
                                        reject(e);
                                    });
                            }
                        } else {
                            // NOT 3Ds secure

                            // Log
                            console.error(e);

                            const errorDeclineCode: string | undefined | null = (e as any).decline_code;
                            if (errorDeclineCode) {
                                // Get message by decline code
                                const declineCodeMessageInformation = declineCodeInformation[errorDeclineCode];
                                if (declineCodeMessageInformation.message) {
                                    // Existing decline code
                                    ToastUtils.showToast({
                                        message: declineCodeMessageInformation,
                                        intent: Intent.DANGER,
                                    });
                                    return;
                                }
                            }

                            // Not existing decline code
                            ToastUtils.showToast({
                                message: 'Unknown payment error occurred, code #2404',
                                intent: Intent.DANGER,
                            });
                        }
                    } else {
                        // TODO: handle error, not payment related error
                        reject(e);
                    }
                });
        });
    };

    return { processPayment: handleProcessPayment };
}

export default usePayment;
