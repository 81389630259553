import InputFormField from '@app/components/InputFormField';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import { TaskUtils } from 'dy-frontend-shared/lib/utils';
import { customDimensionsKey } from 'dy-frontend-shared/lib/utils/TaskUtils/consts';
import React, { useEffect, useRef } from 'react';
import { normalizedTaskCategoryDimensionsApi } from '../../store/apis';
import { updateTaskCategory } from '../../store/effects';
import { ParsedDesignDimensionData } from 'dy-frontend-shared/lib/utils/TaskUtils/types';

export interface UpsertTaskCategoryDimensionsModalProps {
    taskCategoryId: ID;
    title?: string;
    parsedDesignDimensions: ParsedDesignDimensionData;
}

type Props = ModalProps<UpsertTaskCategoryDimensionsModalProps>;

const taskCategoryDimensionsTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const taskCategoryDimensionsValidators = [getStringRequiredValidator()];

const UpsertTaskCategoryDimensionsModal: React.FC<Props> = ({ closeModal, data }) => {
    const modalTitle = data?.title ? 'Update dimensions' : 'Add new dimensions';
    const submitButtonText = data?.title ? 'Update' : 'Add';
    const isCommonDimension = data?.title === customDimensionsKey;

    const normalizedTaskCategoryDimensions = useRef<ParsedDesignDimensionData>(data?.parsedDesignDimensions ?? {});

    const taskCategoryDimensionsTitle = useTextFormField({
        id: 'task-category-dimensions-title',
        validators: taskCategoryDimensionsTitleValidators,
        initialValue: data?.title ?? '',
    });

    const taskCategoryDimensions = useTextFormField({
        id: 'task-category-dimensions',
        validators: taskCategoryDimensionsValidators,
        initialValue: '',
    });

    const form = useForm<TaskCategoryRef>({
        fields: [taskCategoryDimensionsTitle, taskCategoryDimensions],
        apiCall: async () => {
            try {
                const splittedDimensions: string[] = taskCategoryDimensions.value.split('\n');

                if (data!.title) {
                    // Update existing dimensions

                    if (data!.title !== taskCategoryDimensionsTitle.value) {
                        // Title was changed need to create new key/value pair in map and remove old key/value pair
                        normalizedTaskCategoryDimensions.current[taskCategoryDimensionsTitle.value] =
                            splittedDimensions;
                        delete normalizedTaskCategoryDimensions.current[data!.title];
                    } else {
                        // Title did NOT changed
                        normalizedTaskCategoryDimensions.current[taskCategoryDimensionsTitle.value] =
                            splittedDimensions;
                    }
                } else {
                    // Create new dimensions
                    normalizedTaskCategoryDimensions.current[taskCategoryDimensionsTitle.value] = splittedDimensions;
                }

                const taskCategoryRef = await updateTaskCategory({
                    id: data!.taskCategoryId,
                    input: {
                        dimensions: TaskUtils.denormalizeDesignDimensions(normalizedTaskCategoryDimensions.current),
                    },
                });

                normalizedTaskCategoryDimensions.current[taskCategoryDimensionsTitle.value] =
                    normalizedTaskCategoryDimensions.current[taskCategoryDimensionsTitle.value].map((dimension) =>
                        dimension.trim()
                    );

                normalizedTaskCategoryDimensionsApi.update({
                    normalizedDimensions: normalizedTaskCategoryDimensions.current,
                });

                return { response: taskCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        if (!data) {
            // TODO: handle error
            return;
        }

        if (!data.title) {
            taskCategoryDimensions.handleSetValue('');
        } else {
            // Get dimensions for title
            const dimensionsForTitle = normalizedTaskCategoryDimensions.current[data.title];

            if (dimensionsForTitle.length === 0) {
                taskCategoryDimensions.handleSetValue('');
            }

            taskCategoryDimensions.handleSetValue(dimensionsForTitle.join('\n'));
        }

        // eslint-disable-next-line
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderTitleInputBlock = () => {
        if (isCommonDimension) {
            return null;
        }

        return (
            <Grid>
                <InputFormField
                    field={taskCategoryDimensionsTitle}
                    formGroupProps={{ label: 'Title' }}
                    inputProps={{ placeholder: 'Enter dimensions title, i.e. "YouTube banner sizes"' }}
                />
            </Grid>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">{modalTitle}</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        {/* Title */}
                        {renderTitleInputBlock()}

                        {/* Dimensions */}
                        <Grid>
                            <TextAreaFormField
                                field={taskCategoryDimensions}
                                formGroupProps={{ label: 'Dimensions' }}
                                textAreaProps={{ placeholder: 'Example: Square 100 x 100 pixels' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            {submitButtonText}
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertTaskCategoryDimensionsModal;
