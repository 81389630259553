import React from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import {
    PlanTaskCategoryAttachmentResource,
    TaskCategoryGroupResource,
} from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { Button, Card, Intent, Tag } from '@blueprintjs/core';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { openModal } from '@modals/store/events';
import SetPlanTaskCategoryListModal, {
    SetPlanTaskCategoryListModalProps,
} from '@pages/Plan/modals/SetPlanTaskCategoryListModal';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';
import { $permissions } from '@containers/store/states';
import TaskCategoryGroupTag from '@app/containers/components/TaskCategoryGroupTag';

// TODO: consider moving to container for reusing on ClientLicences page
// Section: List of task category attachments
const TaskCategoryList: React.FC = () => {
    const permissions = useStore($permissions);

    const plan = useStore($plan);

    if (!plan) {
        return null;
    }

    const isRootAccess = permissions.has(PlanPermission.ROOT);

    // Renders list of task category groups, associated with task category
    const renderItemGroupList = (itemId: ID, groups: TaskCategoryGroupResource[]) => {
        return (
            <div className="mt-1">
                {groups.map((g, index) => (
                    <TaskCategoryGroupTag
                        className={index === 0 ? '' : 'mt-small'}
                        key={`group-${itemId}-${g.id}`}
                        name={g.title}
                        color={g.color}
                    />
                ))}
            </div>
        );
    };

    // Renders task category attachment item
    const renderItem = (item: PlanTaskCategoryAttachmentResource) => {
        return (
            <Card className="mb-1">
                <DevText>
                    <Tag minimal intent={item.is_excluded ? Intent.DANGER : Intent.PRIMARY} className="mr-1">
                        {item.is_excluded ? 'Excluded' : 'Included'}
                    </Tag>
                    {item.task_category.title}
                </DevText>
                {renderItemGroupList(item.task_category.id, item.task_category.groups)}
            </Card>
        );
    };

    // Renders current task category attachment list
    const renderList = (list: PlanTaskCategoryAttachmentResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No specific request category rules are created...</DevText>
                </div>
            );
        }

        return list.map((i) => <div key={`plan-task-category-${i.task_category.id}`}>{renderItem(i)}</div>);
    };

    // Renders button, invoking attachment list update flow
    const renderUpdateAction = () => {
        const isAllowed = isRootAccess || permissions.has(PlanPermission.ATTACHMENT_MANAGE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() =>
                    openModal<SetPlanTaskCategoryListModalProps>({
                        ModalComponent: SetPlanTaskCategoryListModal,
                        data: {
                            planId: plan.id,
                            initialAttachmentList: plan.task_categories,
                        },
                    })
                }
            >
                Update
            </Button>
        );
    };

    return (
        <div>
            <Flex justify="space-between">
                <Heading type="h4">Specific request category rules</Heading>
                {renderUpdateAction()}
            </Flex>
            <DevText muted className="mb-1">
                Specific request categories which are either included or excluded
            </DevText>
            {renderList(plan.task_categories)}
        </div>
    );
};

export default TaskCategoryList;
