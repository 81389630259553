import repository from 'dy-frontend-http-repository/lib/repository';
import { BrandProfileResource } from 'dy-frontend-http-repository/lib/modules/BrandProfile/resources';
import { UpdateBrandProfileInformationInput } from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import { BrandProfileImageHashRef, BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import domain from './domain';
import {
    BrandProfileAssetFilterInput,
    CreateBrandProfileAssetsBatchInput,
} from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/inputs';
import { BrandProfileAssetListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    BrandProfileAssetRef,
    BrandProfileAssetRefList,
} from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/refs';

// Fetch brand profile
export const fetchBrandProfile = domain.createEffect<ID, BrandProfileResource>({
    name: 'fetchBrandProfile',
    handler: async (id) => {
        return await repository.brandProfile().getById(id);
    },
});

// Fetch brand profile assets
export const fetchBrandProfileAssets = domain.createEffect<
    BrandProfileAssetFilterInput,
    CollectionResource<BrandProfileAssetListItemResource, {}>
>({
    name: 'fetchBrandProfileAssets',
    handler: async (filter) => {
        return await repository.brandProfileAsset().get(filter);
    },
});

// Create brand profile assets
export const createBrandProfileAssets = domain.createEffect<
    CreateBrandProfileAssetsBatchInput,
    BrandProfileAssetRefList
>({
    name: 'createBrandProfileAssets',
    handler: async (input) => {
        return await repository.brandProfileAsset().createBatch(input);
    },
});

// Remove brand profile asset
export const removeBrandProfileAsset = domain.createEffect<ID, BrandProfileAssetRef>({
    name: 'removeBrandProfileAsset',
    handler: async (id) => {
        return await repository.brandProfileAsset().archive(id);
    },
});

// Update brand profile information
export const updateBrandProfileInformation = domain.createEffect<
    { id: ID; input: UpdateBrandProfileInformationInput },
    BrandProfileRef
>({
    name: 'updateBrandProfileInformation',
    handler: async ({ id, input }) => {
        return await repository.brandProfile().update(id, input);
    },
});

// Remove brand profile
export const removeBrandProfile = domain.createEffect<ID, BrandProfileRef>({
    name: 'removeBrandProfile',
    handler: async (id) => {
        return await repository.brandProfile().archive(id);
    },
});

// Restore brand profile
export const restoreBrandProfile = domain.createEffect<ID, BrandProfileRef>({
    name: 'restoreBrandProfile',
    handler: async (brandProfileId) => {
        return await repository.brandProfile().restore(brandProfileId);
    },
});

// Update and set brand profile image
export const uploadAndSetBrandProfileImage = domain.createEffect<
    { brandProfileId: ID; file: File },
    BrandProfileImageHashRef
>({
    name: 'uploadAndSetBrandProfileImage',
    handler: async ({ brandProfileId, file }) => {
        return await repository.brandProfile().uploadAndSetImage(brandProfileId, file);
    },
});

// Remove current brand profile image
export const removeBrandProfileImage = domain.createEffect<ID, BrandProfileRef>({
    name: 'removeBrandProfileImage',
    handler: async (brandProfileId) => {
        return await repository.brandProfile().removeImage(brandProfileId);
    },
});
