import UpdateSubscriptionPaymentMethodModal, {
    UpdateSubscriptionPaymentMethodModalProps,
} from '@app/containers/modals/UpdateSubscriptionPaymentMethodModal';
import { $permissions } from '@app/containers/store/states';
import { checkIfSetSubscriptionPaymentMethodActionAllowed } from '@app/data/functions/subscription';
import { Button, Card, Icon } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { fetchSubscription } from '../../store/effects';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';
import CardPreview from '@app/containers/components/CardPreview';

export interface SubscriptionPaymentMethodProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionPaymentMethodProps;

const SubscriptionPaymentMethod: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    const paymentMethod = subscription.payment_method;

    const renderPaymentMethodInformation = () => {
        if (!paymentMethod) {
            // Subscription payment method is NOT present
            return (
                <Flex direction="row">
                    <Flex direction="row" align="center">
                        <Icon className="mr-1" icon="credit-card" size={30} />
                        <Heading type="h5">Default payment method used</Heading>
                    </Flex>
                </Flex>
            );
        }

        // Subscription payment method is present
        return (
            <Flex direction="row" justify="space-between">
                <div>
                    <Flex direction="row" align="center" className="mb-1">
                        <CardPreview className="mr-1" width="40px" height="30px" type={paymentMethod.card_brand} />
                        <Heading type="h5">
                            {paymentMethod.card_brand.toUpperCase()} ending in *{paymentMethod.card_last_four}
                        </Heading>
                    </Flex>

                    <DevText muted>
                        Expires: {`${paymentMethod.card_expiration_month}`.padStart(2, '0')}/
                        {paymentMethod.card_expiration_year}
                    </DevText>
                </div>

                <DevText muted>
                    {TextFormatUtils.capitalize(paymentMethod.card_funding_type)} card,{' '}
                    {paymentMethod.card_country.toUpperCase()}
                </DevText>
            </Flex>
        );
    };

    const renderUpdateSubscriptionPaymentMethod = () => {
        const isAuthAllowed =
            permissions.isRoot.subscription || permissions.has(SubscriptionPermission.PAYMENT_METHOD_UPDATE);
        const isSetSubscriptionPaymentMethodActionAllowed =
            isAuthAllowed && checkIfSetSubscriptionPaymentMethodActionAllowed(subscription);
        if (!isSetSubscriptionPaymentMethodActionAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() =>
                    openModal<UpdateSubscriptionPaymentMethodModalProps>({
                        ModalComponent: UpdateSubscriptionPaymentMethodModal,
                        data: {
                            paymentAccountId: subscription.payment_account_id,
                            subscriptionId: subscription.id,
                            selectedPaymentMethod: paymentMethod,
                            refetchOnSuccess: () => fetchSubscription(subscription.id),
                        },
                    })
                }
            >
                Update payment method
            </Button>
        );
    };

    return (
        <div {...props}>
            <Flex className="mb-1" align="center" justify="space-between">
                <Heading className="mb-1" type="h4">
                    Payment Method
                </Heading>

                {renderUpdateSubscriptionPaymentMethod()}
            </Flex>

            <Card>{renderPaymentMethodInformation()}</Card>
        </div>
    );
};

export default SubscriptionPaymentMethod;
