import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CursorPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import {
    TaskMessageListItemResource,
    TaskMessageResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import { TaskMessageRef } from 'dy-frontend-http-repository/lib/modules/TaskMessage/refs';
import {
    CreateTaskMessageInput,
    TaskMessageFilterInput,
    UpdateTaskMessageInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessage/inputs';
import domain from './domain';

// Fetch task message list
export const fetchTaskMessages = domain.createEffect<
    { pagination: CursorPaginationInput; filter: TaskMessageFilterInput },
    CollectionResource<TaskMessageListItemResource, {}>
>({
    name: 'fetchTaskMessages',
    handler: async ({ pagination, filter }) => {
        return await repository.taskMessage().get(pagination, filter);
    },
});

// Fetch task message by ID
export const fetchTaskMessage = domain.createEffect<{ taskMessageId: ID }, TaskMessageResource>({
    name: 'fetchTaskMessage',
    handler: async ({ taskMessageId }) => {
        return await repository.taskMessage().getById(taskMessageId);
    },
});

// Create new task message
export const createTaskMessage = domain.createEffect<CreateTaskMessageInput, TaskMessageRef>({
    name: 'createTaskMessage',
    handler: async (input) => {
        return await repository.taskMessage().create(input);
    },
});

// Update task message
export const updateTaskMessage = domain.createEffect<
    { taskMessageId: ID; input: UpdateTaskMessageInput },
    TaskMessageRef
>({
    name: 'updateTaskMessage',
    handler: async ({ taskMessageId, input }) => {
        return await repository.taskMessage().update(taskMessageId, input);
    },
});

// Archive task message
export const removeTaskMessage = domain.createEffect<ID, TaskMessageRef>({
    name: 'removeTaskMessage',
    handler: async (taskMessageId) => {
        return await repository.taskMessage().archive(taskMessageId);
    },
});

// Pin message
export const pinTaskMessage = domain.createEffect<ID, TaskMessageRef>({
    name: 'pinTaskMessage',
    handler: async (taskMessageId) => {
        return await repository.taskMessage().pin(taskMessageId);
    },
});

// Unpin task message
export const unpinTaskMessage = domain.createEffect<ID, TaskMessageRef>({
    name: 'unpinTaskMessage',
    handler: async (taskMessageId) => {
        return await repository.taskMessage().unpin(taskMessageId);
    },
});
