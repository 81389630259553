import ConfirmationPopover from '@app/components/ConfirmationPopover';
import UpdateTaskMessageTemplateModal, {
    UpdateTaskMessageTemplateModalProps,
} from '@app/containers/pages/TaskMessageTemplates/modals/UpdateTaskMessageTemplateModal';
import { Button, Card, Classes, Collapse, IconName, Intent, Tag, Tooltip } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RichText from '@components/RichText';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { taskMessageTemplatesApi } from '@pages/TaskMessageTemplates/store/apis';
import { removeTaskMessageTemplate, restoreTaskMessageTemplate } from '@pages/TaskMessageTemplates/store/effects';
import { TaskMessageTemplateListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import { RichTextUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes, useState } from 'react';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { TaskMessageTemplatePermission } from 'dy-frontend-permissions/lib/permission';

export interface TaskMessageTemplateListItemProps {
    template: TaskMessageTemplateListItemResource;
}

export type Props = TaskMessageTemplateListItemProps & HTMLAttributes<HTMLDivElement>;

const TaskMessageTemplateListItem: React.FC<Props> = ({ template, ...props }) => {
    const permissions = useStore($permissions);

    const [restoringTaskMessageTemplateId, setRestoringTaskMessageTemplateId] = useState<ID | null>(null);
    const [removingTaskMessageTemplateId, setRemovingTaskMessageTemplateId] = useState<ID | null>(null);
    const [isCollapseOpened, setIsCollapseOpened] = useState(false);

    const renderControls = () => {
        let buttonIcon: IconName = 'chevron-down';

        if (isCollapseOpened) {
            buttonIcon = 'chevron-up';
        }

        // Auth
        const isRemoveAllowed = permissions.isRoot.taskMessageTemplate || permissions.has(TaskMessageTemplatePermission.ARCHIVE);
        const isUpdateAllowed = permissions.isRoot.taskMessageTemplate || permissions.has(TaskMessageTemplatePermission.UPDATE);
        const isRestoreAllowed = permissions.isRoot.taskMessageTemplate || permissions.has(TaskMessageTemplatePermission.RESTORE);

        const renderRemoveButton = () => {
            if (template.archived_at) {
                // Task message template was already archived
                return null;
            }

            if (!isRemoveAllowed) {
                return null;
            }

            const handleRemove = async () => {
                setRemovingTaskMessageTemplateId(template.id);

                try {
                    await removeTaskMessageTemplate(template.id);
                    taskMessageTemplatesApi.remove({ taskMessageTemplateId: template.id });
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setRemovingTaskMessageTemplateId(null);
                }
            };

            const isRemovingTaskMessageTemplate = removingTaskMessageTemplateId === template.id;

            return (
                <ConfirmationPopover
                    title="Are you sure you want to remove request message template?"
                    description="When confirmed, request message template will be removed"
                    actions={[
                        <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={handleRemove}>
                            Yes, remove template
                        </Button>,
                    ]}
                >
                    <Tooltip content="Remove message template">
                        <Button
                            minimal
                            loading={isRemovingTaskMessageTemplate}
                            disabled={isRemovingTaskMessageTemplate}
                            className="mr-1"
                            icon="trash"
                            intent={Intent.DANGER}
                        />
                    </Tooltip>
                </ConfirmationPopover>
            );
        };

        const renderRestoreButton = () => {
            if (!template.archived_at) {
                // Task message template was NOT removed yet
                return null;
            }

            if (!isRestoreAllowed) {
                return null;
            }

            const handleRestore = async () => {
                setRestoringTaskMessageTemplateId(template.id);

                try {
                    await restoreTaskMessageTemplate(template.id);
                    taskMessageTemplatesApi.restore({ taskMessageTemplateId: template.id });
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setRestoringTaskMessageTemplateId(null);
                }
            };

            const isRestoringTaskMessageTemplate = restoringTaskMessageTemplateId === template.id;

            return (
                <ConfirmationPopover
                    title="Are you sure you want to restore request message template?"
                    description="When confirmed, request message template will be restored"
                    actions={[
                        <Button intent={Intent.SUCCESS} className={Classes.POPOVER_DISMISS} onClick={handleRestore}>
                            Yes, restore template
                        </Button>,
                    ]}
                >
                    <Button
                        loading={isRestoringTaskMessageTemplate}
                        disabled={isRestoringTaskMessageTemplate}
                        className="mr-1"
                        icon="confirm"
                        intent={Intent.SUCCESS}
                    >
                        Restore
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderUpdateButton = () => {
            if (!isUpdateAllowed) {
                return null;
            }

            const handleOpenUpdateTaskMessageTemplateModal = () => {
                openModal<UpdateTaskMessageTemplateModalProps>({
                    ModalComponent: UpdateTaskMessageTemplateModal,
                    data: {
                        taskMessageTemplateCategoryId: template.category.id,
                        taskMessageTemplateId: template.id,
                        title: template.title,
                        summary: template.summary,
                        content: template.content,
                    },
                });
            };

            return (
                <Button className="mr-1" icon="edit" onClick={handleOpenUpdateTaskMessageTemplateModal}>
                    Edit
                </Button>
            );
        };

        const renderCollapseTaskMessageContentButton = () => {
            return <Button icon={buttonIcon} onClick={() => setIsCollapseOpened((prevValue) => !prevValue)} />;
        };

        return (
            <Flex align="center">
                {renderUpdateButton()}
                {renderRemoveButton()}
                {renderRestoreButton()}
                {renderCollapseTaskMessageContentButton()}
            </Flex>
        );
    };

    return (
        <Card {...props}>
            <Flex justify="space-between">
                <Flex direction="column">
                    <Flex className="mb-1" align="center">
                        <Circle className="mr-1" size="16px" color={template.category.color} />
                        <Heading className="mr-1" type="h4">
                            {template.title}
                        </Heading>
                        {template.archived_at && (
                            <Tag minimal intent={Intent.DANGER}>
                                Archived
                            </Tag>
                        )}
                    </Flex>

                    <DevText muted>{template.summary}</DevText>
                </Flex>

                {renderControls()}
            </Flex>

            <Collapse isOpen={isCollapseOpened}>
                <RichText className="mt-2" value={RichTextUtils.convertHTMLToEditorState(template.content)} />
            </Collapse>
        </Card>
    );
};

export default TaskMessageTemplateListItem;
