import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Checkbox, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { getStringMaxLengthValidator } from '@app/hooks/validation/functions';
import { useForm, useTextFormField } from '@app/hooks';
import InputFormField from '@app/components/InputFormField';
import { changeTaskQueue } from './store/effects';
import { TransitionOpenTaskQueueInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';

export interface ChangeTaskQueueToBacklogModalProps {
    taskId: ID;
    onTaskQueueToBacklog: (input: TransitionOpenTaskQueueInput) => void;
}

type Props = ModalProps<ChangeTaskQueueToBacklogModalProps>;

const commentValidators = [getStringMaxLengthValidator({ maxStringLength: 255 })];

const ChangeTaskQueueToBacklogModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [isQueueAutomationLocked, setIsQueueAutomationLocked] = useState(false);

    const comment = useTextFormField({
        id: 'comment',
        validators: commentValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [comment],
        apiCall: async () => {
            const changeTaskQueueInput: TransitionOpenTaskQueueInput = {
                is_queue_automation_locked: isQueueAutomationLocked,
                queue: TaskQueue.BACKLOG,
                comment: comment.value,
            };

            try {
                const taskRef = await changeTaskQueue({
                    taskId: data?.taskId ?? '0',
                    input: changeTaskQueueInput,
                });

                data?.onTaskQueueToBacklog(changeTaskQueueInput);

                setIsCompleted(true);

                return { response: taskRef };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return null;
        }

        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Task was moved to "Backlog"</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <div className="mb-2">
                    <InputFormField
                        field={comment}
                        formGroupProps={{
                            fill: true,
                            label: 'Comment',
                        }}
                        inputProps={{ placeholder: `i.e. "Designers are too busy to start working on this request"` }}
                    />
                </div>

                <Checkbox
                    checked={isQueueAutomationLocked}
                    label="Lock queue automation after transition?"
                    onChange={() => setIsQueueAutomationLocked((prevValue) => !prevValue)}
                />

                <Flex className="mt-2" align="center" justify="space-between">
                    <DevText muted>Proceed transition to "Backlog"?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={form.isSubmitting}
                            disabled={form.hasFieldErrors}
                            type="submit"
                        >
                            Yes, transition
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Transition task to "Backlog"
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default ChangeTaskQueueToBacklogModal;
