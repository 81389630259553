import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import {
    SubscriptionResource,
    PriceResource as SubscriptionItemPriceResource,
} from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { PriceResource as SubscriptionPlanPriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';

import {
    checkIfSubscriptionTerminated,
    checkIfSubscriptionUpdateScheduled,
    checkIfSubscriptionPaused,
    checkIfPlanTypeAllowedBySubscriptionMode,
    checkIfPricesHaveTheSameRecurringInterval,
} from '..';

/**
 * Check if switch single price allowed
 * e.g. switch of the single price is used for removing the price by settings it's quantity to 0
 * @param price price for checking recurring interval
 * @param subscription subscription
 * @param planType plan type
 * @returns
 */
export default function checkIfSwitchSinglePriceActionAllowed(
    price: SubscriptionItemPriceResource | SubscriptionPlanPriceResource | null,
    subscription: SubscriptionResource,
    planType: PlanType
) {
    if (subscription.mode === SubscriptionMode.MANUAL) {
        // Subscription mode is MANUAL
        return false;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription is terminated
        return false;
    }

    if (checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription have update schedule
        return false;
    }

    if (checkIfSubscriptionPaused(subscription)) {
        // Subscription is paused
        return false;
    }

    if (!checkIfPlanTypeAllowedBySubscriptionMode(planType, subscription.mode)) {
        // Plan type is NOT allowed by subscription mode
        return false;
    }

    if (subscription.items.length > 1) {
        // There are multiple subscription items
        if (!checkIfPricesHaveTheSameRecurringInterval([price, ...subscription.items.map((item) => item.price)])) {
            // Prices do NOT have the same recurring interval
            return false;
        }
    }

    return true;
}
