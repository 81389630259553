import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, FormGroup, Icon, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { Endpoints } from '@data/consts';
import RouterLink from '@components/RouterLink';
import { taskApi } from '@pages/Task/store/apis';
import { reopenTask } from '@pages/Task/store/effects';

export interface ReopenTaskModalProps {
    taskId: ID;
    ownerUserId: ID;
}

type Props = ModalProps<ReopenTaskModalProps>;

const ReopenTaskModal: React.FC<Props> = ({
    closeModal,
    data,
}) => {
    const [isTaskReopening, setIsTaskReopening] = useState(false);
    const [isTaskReopenDone, setIsTaskReopenDone] = useState(false);

    const [reopenReasonText, setReopenReasonText] = useState('');

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    const handleModalClose = () => {
        if (isTaskReopening) {
            return;
        }

        // O_o?
        closeModal?.();
        return null;
    };

    const getNormalizedReopenReason = () => {
        return reopenReasonText.trim();
    };

    const handleReopenFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsTaskReopening(true);

        reopenTask({ taskId: data.taskId, input: { comment: getNormalizedReopenReason() } })
            .then(() => {
                setIsTaskReopenDone(true);
                taskApi.reopen();
            })
            .catch((e) => console.error)
            .finally(() => setIsTaskReopening(false));

        return null;
    };


    const renderReopenForm = () => {
        return (
            <form onSubmit={handleReopenFormSubmit}>
                <DevText running className="mb-3">
                    Request will be moved to "In Revision" state after reopening. Additionally, request will be moved
                    to backlog, and all automatic movements from backlog to active will be locked. You can manually
                    move request to "active" and unlock it on client's "Requests" page, after reopening is done.
                </DevText>

                <FormGroup
                    helperText={`i.e. "Requested by client"`}
                    label="Please, specify reason for reopening"
                    labelInfo="(required)"
                    className="mb-3"
                >
                    <InputGroup
                        placeholder="I reopen this request because..."
                        maxLength={255}
                        value={reopenReasonText}
                        onChange={(e) => setReopenReasonText(e.target.value)}
                    />
                </FormGroup>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed with reopening the request?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={isTaskReopening}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={isTaskReopening}
                            disabled={getNormalizedReopenReason().length === 0}
                            type="submit"
                        >
                            Yes, reopen request
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    const renderReopenSuccess = () => {
        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Request successfully reopened</Heading>
                </Flex>

                <DevText muted className="mb-2">This window can now be closed</DevText>

                <DevText>
                    You can move request from backlog, or unlock it on{' '}
                    <RouterLink
                        className="mr-1"
                        onClick={closeModal}
                        to={Endpoints.CLIENT_TASKS.replace(':clientId', data.ownerUserId)}
                    >
                        client's "Requests" page
                    </RouterLink>
                </DevText>
            </div>
        );
    };

    const renderModalTitleCaption = () => {
        // Initial
        if (!isTaskReopening && !isTaskReopenDone) {
            return (
                <DevText muted>Here is what will happen to request after it is reopened</DevText>
            );
        }

        // Restoring...
        if (isTaskReopening) {
            return (
                <DevText muted>Reopening request...</DevText>
            );
        }

        // Restore done
        if (!isTaskReopening && isTaskReopenDone) {
            return (
                <DevText muted>Request successfully reopened!</DevText>
            );
        }

        return null;
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">Reopen request</Heading>
                        {renderModalTitleCaption()}
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isTaskReopening} />
                </Flex>

                <Divider className="mb-2" />

                {!isTaskReopenDone ? renderReopenForm() : renderReopenSuccess()}
            </Card>
        </Overlay>
    );
};

export default ReopenTaskModal;
