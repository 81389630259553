import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultInvalidDateMessage = 'Invalid date provided';
const getStringDateValidator: GetValidatorFunction<
    {
        invalidDateMessage?: string;
    },
    string
> = (options = { invalidDateMessage: defaultInvalidDateMessage }) => {
    const { invalidDateMessage } = options;

    return async (date) => {
        if (new Date(date).toString() !== 'Invalid Date') {
            // TODO: valid date
            return null;
        } else {
            if (invalidDateMessage) {
                return invalidDateMessage;
            } else {
                return defaultInvalidDateMessage;
            }
        }
    };
};

export default getStringDateValidator;
