import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { checkIfSubscriptionTerminated, checkIfSubscriptionUpdateScheduled } from '../';

/**
 * Check if release subscription all scheduled updates action allowed
 * @param subscription subscription to use in "release subscription all scheduled updates action allowed" check
 * @returns {boolean} "release subscription all scheduled updates action allowed" flag
 */
export default function checkIfReleaseSubscriptionAllScheduledUpdatesActionAllowed(subscription: SubscriptionResource) {
    if (subscription.mode === SubscriptionMode.MANUAL) {
        // Subscription mode is MANUAL
        return false;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription is terminated
        return false;
    }

    if (!checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription do NOT have scheduled update
        return false;
    }

    return true;
}
