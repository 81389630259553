import { AllocateVirtualLicenceInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import {
    VirtualLicenceTaskCategoryGroupAttachmentResource,
    VirtualLicenceQuotaAttachmentResource,
    VirtualLicenceTaskCategoryAttachmentResource,
    VirtualLicenceResource,
} from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import { createApi } from 'effector';
import moment from 'moment';
import { $licences, $virtualLicences } from './states';

// API to manage virtual licences
export const virtualLicencesApi = createApi($virtualLicences, {
    create: (store, payload: { virtualLicenceId: ID; input: AllocateVirtualLicenceInput }) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        // Create moment now in UTC
        const momentNowUtc = moment().utc().format();

        // Create new virtual licence
        const newVirtualLicence: VirtualLicenceResource = {
            id: payload.virtualLicenceId,
            allocated_at: momentNowUtc,
            start_at: momentNowUtc,
            description: payload.input.description,
            end_at: null,
            quotas: [],
            suspended_at: null,
            task_categories: [],
            task_category_groups: [],
            updated_at: momentNowUtc,
            user_id: payload.input.user_id,
        };

        return {
            ...store,
            items: [newVirtualLicence, ...store.items],
        };
    },
    updateQuotaList: (store, payload: { virtualLicenceId: ID; quotas: VirtualLicenceQuotaAttachmentResource[] }) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        // Copy virtual licences
        const copyVirtualLicences: VirtualLicenceResource[] = [...store.items];

        // Find virtual licence
        const foundVirtualLicence = copyVirtualLicences.find(
            (virtualLicence) => virtualLicence.id === payload.virtualLicenceId
        );

        if (!foundVirtualLicence) {
            // Virtual licence was NOT found
            return store;
        }

        // Update virtual licence
        foundVirtualLicence.quotas = payload.quotas;

        return {
            ...store,
            items: copyVirtualLicences,
        };
    },
    updateTaskCategoryGroupList: (
        store,
        payload: { virtualLicenceId: ID; taskCategoryGroups: VirtualLicenceTaskCategoryGroupAttachmentResource[] }
    ) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        // Copy virtual licences
        const copyVirtualLicences: VirtualLicenceResource[] = [...store.items];

        // Find virtual licence
        const foundVirtualLicence = copyVirtualLicences.find(
            (virtualLicence) => virtualLicence.id === payload.virtualLicenceId
        );

        if (!foundVirtualLicence) {
            // Virtual licence was NOT found
            return store;
        }

        // Update virtual licence
        foundVirtualLicence.task_category_groups = payload.taskCategoryGroups;

        return {
            ...store,
            items: copyVirtualLicences,
        };
    },
    updateTaskCategoryList: (
        store,
        payload: { virtualLicenceId: ID; taskCategories: VirtualLicenceTaskCategoryAttachmentResource[] }
    ) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        // Copy virtual licences
        const copyVirtualLicences: VirtualLicenceResource[] = [...store.items];

        // Find virtual licence
        const foundVirtualLicence = copyVirtualLicences.find(
            (virtualLicence) => virtualLicence.id === payload.virtualLicenceId
        );

        if (!foundVirtualLicence) {
            // Virtual licence was NOT found
            return store;
        }

        // Update virtual licence
        foundVirtualLicence.task_categories = payload.taskCategories;

        return {
            ...store,
            items: copyVirtualLicences,
        };
    },
    suspend: (store, virtualLicenceId: ID) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        // Copy licences array
        const copyVirtualLicences = [...store.items];

        // Find
        const foundIndex = copyVirtualLicences.findIndex((virtualLicence) => virtualLicence.id === virtualLicenceId);
        if (foundIndex === -1) {
            return store;
        }

        // Update
        const momentNowUtc = moment().utc().format();
        copyVirtualLicences[foundIndex].suspended_at = momentNowUtc;

        return {
            ...store,
            items: copyVirtualLicences,
        };
    },
    resume: (store, virtualLicenceId: ID) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        // Copy licences array
        const copyVirtualLicences = [...store.items];

        // Find
        const foundIndex = copyVirtualLicences.findIndex((virtualLicence) => virtualLicence.id === virtualLicenceId);
        if (foundIndex === -1) {
            return store;
        }

        // Update
        copyVirtualLicences[foundIndex].suspended_at = null;

        return {
            ...store,
            items: copyVirtualLicences,
        };
    },
    end: (store, virtualLicenceId: ID) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: [...store.items].filter((virtualLicence) => virtualLicence.id !== virtualLicenceId),
        };
    },
});

// API to manage licences
export const licencesApi = createApi($licences, {
    suspend: (store, licenceId: ID) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        // Copy licences array
        const copyLicences = [...store.items];

        // Find
        const foundIndex = copyLicences.findIndex((virtualLicence) => virtualLicence.id === licenceId);
        if (foundIndex === -1) {
            return store;
        }

        // Update
        const momentNowUtc = moment().utc().format();
        copyLicences[foundIndex].suspended_at = momentNowUtc;

        return {
            ...store,
            items: copyLicences,
        };
    },
    resume: (store, licenceId: ID) => {
        if (!store) {
            // Licences were NOT fetched yet
            return store;
        }

        // Copy licences array
        const copyLicences = [...store.items];

        // Find
        const foundIndex = copyLicences.findIndex((virtualLicence) => virtualLicence.id === licenceId);
        if (foundIndex === -1) {
            return store;
        }

        // Update
        copyLicences[foundIndex].suspended_at = null;

        return {
            ...store,
            items: copyLicences,
        };
    },
    shutdown: (store, licenceId: ID) => {
        if (!store) {
            // Virtual licences were NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: [...store.items].filter((licence) => licence.id !== licenceId),
        };
    },
});
