import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Endpoints } from '@data/consts';
import { $isAuthorized } from '@containers/store/states';

const ApplicationAccessRoute: React.FC = ({ children }) => {
    const isAuthorized = useStore($isAuthorized);

    if (!isAuthorized) {
        // User is NOT authorized
        if (window.location.origin === process.env.WORKSPACE_BASE_PLATFORM_URL) {
            // User is at workspace base platform url (without key)

            return <Navigate to={Endpoints.WORKSPACE_ROUTER} />;
        }

        return <Navigate to={Endpoints.AUTHENTICATION} />;
    }

    return <>{children}</>;
};

export default ApplicationAccessRoute;
