import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { createDisabledTaskCategoryFeaturedBlock } from './store/effects';
import { useForm, useTextFormField } from '@app/hooks';
import { CreateTaskCategoryFeaturedBlockInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/inputs';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import InputFormField from '@app/components/InputFormField';
import TextAreaFormField from '@app/components/TextAreaFormField';

const taskCategoryFeaturedBlockTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];

const taskCategoryFeaturedBlockSummaryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];

export interface CreateDisabledTaskCategoryFeaturedBlockModalProps {
    onCreateSuccess: () => void;
}

type Props = ModalProps<CreateDisabledTaskCategoryFeaturedBlockModalProps>;

const CreateDisabledTaskCategoryFeaturedBlockModal: React.FC<Props> = ({ closeModal, data }) => {
    const handleModalClose = () => {
        closeModal?.();
    };

    const taskCategoryFeaturedBlockTitle = useTextFormField({
        id: 'task-category-featured-block-title',
        validators: taskCategoryFeaturedBlockTitleValidators,
        initialValue: '',
    });

    const taskCategoryFeaturedBlockSummary = useTextFormField({
        id: 'task-category-featured-block-summary',
        validators: taskCategoryFeaturedBlockSummaryValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [taskCategoryFeaturedBlockTitle, taskCategoryFeaturedBlockSummary],
        apiCall: async () => {
            try {
                // Create input
                const createTaskCategoryFeaturedBlockInput: CreateTaskCategoryFeaturedBlockInput = {
                    title: taskCategoryFeaturedBlockTitle.value,
                    summary: taskCategoryFeaturedBlockSummary.value,
                };

                // Create disabled task category featured block
                const taskCategoryFeaturedBlockRef = await createDisabledTaskCategoryFeaturedBlock(
                    createTaskCategoryFeaturedBlockInput
                );

                // Update state
                data?.onCreateSuccess();

                // Close modal
                handleModalClose();

                return { response: taskCategoryFeaturedBlockRef };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create request category featured block</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <InputFormField
                        className="mb-2"
                        field={taskCategoryFeaturedBlockTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter featured block title' }}
                    />

                    <TextAreaFormField
                        field={taskCategoryFeaturedBlockSummary}
                        formGroupProps={{ label: 'Summary' }}
                        textAreaProps={{ placeholder: 'Enter featured block summary' }}
                    />

                    <Flex align="center" justify="space-between">
                        <DevText muted>Create featured block?</DevText>
                        <div>
                            <Button minimal onClick={handleModalClose} className="mr-1" disabled={form.isSubmitting}>
                                No, cancel
                            </Button>
                            <Button type="submit" intent={Intent.PRIMARY} loading={form.isSubmitting}>
                                Yes, create
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateDisabledTaskCategoryFeaturedBlockModal;
