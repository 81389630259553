import { $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { customDimensionsKey } from 'dy-frontend-shared/lib/utils/TaskUtils/consts';
import { openModal } from '@modals/store/events';
import { TaskUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import UpsertTaskCategoryDimensionsModal, {
    UpsertTaskCategoryDimensionsModalProps,
} from '../../modals/UpsertTaskCategoryDimensionsModal';
import { normalizedTaskCategoryDimensionsApi } from '../../store/apis';
import { updateTaskCategory } from '../../store/effects';
import { $normalizedTaskCategoryDimensions, $taskCategory } from '../../store/states';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';

const DesignDimensions: React.FC = () => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);
    const normalizedTaskCategoryDimensions = useStore($normalizedTaskCategoryDimensions);

    const [removingDimensionTitle, setRemovingDimensionTitle] = useState<string | null>(null);

    if (!taskCategory) {
        return null;
    }

    if (!normalizedTaskCategoryDimensions) {
        return null;
    }

    const renderDesignDimensions = () => {
        const isUpdateAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);

        // TODO: create universal component to show design dimensions

        // Get design dimensions keys
        // const parsedDesignDimensionsKeys = Object.keys(parsedDesignDimensions);

        // Parsed design dimensions have 1 key, which is default design dimensions and default dimensions exist / 0 keys (should not happen unless parseDesignDimensions logic changes, since default dimensions will always exist on the parsedDesignDimension object)
        // if (
        //     (parsedDesignDimensionsKeys.length === 1 && parsedDesignDimensions.dimensions.length === 0) ||
        //     parsedDesignDimensionsKeys.length < 1
        // ) {
        //     return (
        //         <DevText muted>
        //             Design dimensions do not exist yet
        //         </DevText>
        //     );
        // }

        const renderRemoveDimensionButton = (dimensionTitle: string) => {
            if (dimensionTitle === customDimensionsKey) {
                // Custom dimensions block can't be removed
                return null;
            }

            if (!isUpdateAllowed) {
                return null;
            }

            const handleRemoveDimensions = async () => {
                setRemovingDimensionTitle(dimensionTitle);

                const normalizedDimensionsCopy = { ...normalizedTaskCategoryDimensions };
                delete normalizedDimensionsCopy[dimensionTitle];

                try {
                    await updateTaskCategory({
                        id: taskCategory.id,
                        input: { dimensions: TaskUtils.denormalizeDesignDimensions(normalizedDimensionsCopy) },
                    });

                    normalizedTaskCategoryDimensionsApi.update({ normalizedDimensions: normalizedDimensionsCopy });
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setRemovingDimensionTitle(null);
                }
            };

            const isRemovingDimensionTitle = removingDimensionTitle === dimensionTitle;

            return (
                <ConfirmationPopover
                    title="Are you sure you want to remove this dimensions?"
                    description="When confirmed, this dimension will be removed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRemoveDimensions}
                        >
                            Yes, remove dimension
                        </Button>,
                    ]}
                >
                    <Button
                        minimal
                        loading={isRemovingDimensionTitle}
                        disabled={isRemovingDimensionTitle}
                        className="ml-1"
                        icon="trash"
                        intent={Intent.DANGER}
                    />
                </ConfirmationPopover>
            );
        };

        const renderDimensions = (dimensions: string[]) => {
            if (dimensions.length === 0) {
                return <DevText muted>No dimensions created yet</DevText>;
            }
            return dimensions.map((dimension) => <DevText>{dimension}</DevText>);
        };

        const renderUpdateTaskCategoryDimensionsButton = (modalData: UpsertTaskCategoryDimensionsModalProps) => {
            if (!isUpdateAllowed) {
                return null;
            }

            return (
                <Button
                    icon="edit"
                    onClick={() =>
                        openModal<UpsertTaskCategoryDimensionsModalProps>({
                            ModalComponent: UpsertTaskCategoryDimensionsModal,
                            data: modalData,
                        })
                    }
                >
                    Edit
                </Button>
            );
        };

        return Object.entries(normalizedTaskCategoryDimensions).map(([dimensionTitle, dimensions]) => (
            <React.Fragment key={dimensionTitle}>
                <Flex className="mt-2" justify="space-between">
                    <Flex direction="column">
                        <DevText muted className="mb-small">
                            {TextFormatUtils.capitalize(dimensionTitle)}
                        </DevText>

                        {renderDimensions(dimensions)}
                    </Flex>

                    <Flex align="center">
                        {renderUpdateTaskCategoryDimensionsButton({
                            taskCategoryId: taskCategory.id,
                            title: dimensionTitle,
                            parsedDesignDimensions: normalizedTaskCategoryDimensions,
                        })}

                        {renderRemoveDimensionButton(dimensionTitle)}
                    </Flex>
                </Flex>
            </React.Fragment>
        ));
    };

    const renderCreateTaskCategoryDesignDimensionButton = () => {
        const isCreateAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);
        if (!isCreateAllowed) {
            return null;
        }

        return (
            <Button
                icon="add"
                onClick={() =>
                    openModal<UpsertTaskCategoryDimensionsModalProps>({
                        ModalComponent: UpsertTaskCategoryDimensionsModal,
                        data: {
                            taskCategoryId: taskCategory.id,
                            parsedDesignDimensions: normalizedTaskCategoryDimensions,
                        },
                    })
                }
            >
                Add
            </Button>
        );
    };

    return (
        <Card>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Design Dimensions</Heading>
                {renderCreateTaskCategoryDesignDimensionButton()}
            </Flex>

            {renderDesignDimensions()}
        </Card>
    );
};

export default DesignDimensions;
