import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    CreateTaskCategoryInput,
    TaskCategoryFilterInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Price/resources';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';

// Fetch task categories
export const fetchTaskCategories = domain.createEffect<
    TaskCategoryFilterInput,
    CollectionResource<TaskCategoryListItemResource, {}>
>({
    name: 'fetchTaskCategories',
    handler: async (filter) => {
        return await repository.taskCategory().get(filter);
    },
});

// Fetch price by external ID
export const fetchPriceByExternalId = domain.createEffect<string, PriceResource>({
    name: 'fetchPriceByExternalId',
    handler: async (externalId) => {
        return await repository.price().getByExternalId(externalId);
    },
});

// Create task category
export const createTaskCategory = domain.createEffect<CreateTaskCategoryInput, TaskCategoryRef>({
    name: 'createTaskCategory',
    handler: async (input) => {
        return await repository.taskCategory().create(input);
    },
});
