import Circle from '@app/components/Circle';
import RichText from '@app/components/RichText';
import { $task } from '@app/containers/pages/Task/store/states';
import { resetTaskMessageTemplates } from '@app/containers/pages/TaskMessageTemplates/store/events';
import {
    Button,
    Card,
    Classes,
    Divider,
    Elevation,
    FormGroup,
    Icon,
    InputGroup,
    Intent,
    MenuItem,
    Popover,
    Spinner,
} from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Overlay from '@components/Overlay';
import Pagination from '@components/Pagination';
import richEditorPlugins from '@components/RichEditor/plugins';
import Select from '@components/Select';
import DevText from '@components/Text';
import { ModalProps } from '@modals/types';
import {
    HydratedTaskMessageTemplateResource,
    TaskMessageTemplateListItemResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import { TaskMessageTemplateCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import { RichTextUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { amountOfTaskMessageTemplatesOnPage } from './consts';
import {
    fetchHydratedTaskMessageTemplate,
    fetchTaskMessageTemplateCategories,
    fetchTaskMessageTemplates,
} from './store/effects';
import { resetTaskMessageTemplateCategories } from './store/events';
import { $taskMessageTemplateCategories, $taskMessageTemplates } from './store/states';

export interface TaskMessageTemplatePickerModalProps {
    onUseTemplate: (content: string) => void;
}

type Props = ModalProps<TaskMessageTemplatePickerModalProps>;

const TaskMessageTemplatePickerModal: React.FC<Props> = ({ closeModal, data }) => {
    const task = useStore($task);
    const taskMessageTemplateCategories = useStore($taskMessageTemplateCategories);
    const taskMessageTemplates = useStore($taskMessageTemplates);
    const isFetchingTaskMessageTemplates = useStore(fetchHydratedTaskMessageTemplate.pending);

    const [selectedTaskMessageTemplateCategory, setSelectedTaskMessageTemplateCategory] =
        useState<TaskMessageTemplateCategoryListItemResource | null>(null);
    const [query, setQuery] = useState('');
    const [queryFilter, setQueryFilter] = useState('');
    const [page, setPage] = useState(1);

    const [openedTaskMessageTemplate, setOpenedTaskMessageTemplate] =
        useState<HydratedTaskMessageTemplateResource | null>(null);
    const [openedTaskMessageTemplateId, setOpenedTaskMessageTemplateId] = useState<ID | null>(null);

    const handleFetchTaskMessageTemplates = (newPage: number) => {
        const pageOffset = (newPage - 1) * amountOfTaskMessageTemplatesOnPage;

        fetchTaskMessageTemplates({
            pagination: {
                _pagination: { limit: amountOfTaskMessageTemplatesOnPage, offset: pageOffset },
            },
            filter: {
                is_archived: '0',
                query,
                template_category_id: selectedTaskMessageTemplateCategory
                    ? [selectedTaskMessageTemplateCategory.id]
                    : undefined,
            },
        }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    };

    useEffect(() => {
        // Initial task message template categories
        fetchTaskMessageTemplateCategories({ filter: { is_archived: '0' } }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        handleFetchTaskMessageTemplates(page);

        // eslint-disable-next-line
    }, [page, selectedTaskMessageTemplateCategory]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            handleFetchTaskMessageTemplates(page);
        }
    }, [queryFilter]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setQueryFilter(query);
        }, 400);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [query]);

    useEffect(() => {
        resetTaskMessageTemplateCategories();
        resetTaskMessageTemplates();
    }, []);

    if (!data || !task) {
        closeModal?.();
        return null;
    }

    const handleCloseTaskMessageTemplateInformationPopover = () => {
        setOpenedTaskMessageTemplateId(null);
        setOpenedTaskMessageTemplate(null);
    };

    const handleOpenTaskMessageTemplateInformationPopover = (taskMessageTemplateId: ID) => {
        setOpenedTaskMessageTemplateId(taskMessageTemplateId);

        fetchHydratedTaskMessageTemplate({ taskMessageTemplateId, filter: { task_id: task.id } })
            .then((template) => {
                setTimeout(() => setOpenedTaskMessageTemplate(template), 500);
            })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
                handleCloseTaskMessageTemplateInformationPopover();
            });
    };

    const handleSelectTaskMessageTemplate = (selectedTaskMessageTemplate: HydratedTaskMessageTemplateResource) => {
        data.onUseTemplate(selectedTaskMessageTemplate.content);
        setOpenedTaskMessageTemplateId(null);
        setOpenedTaskMessageTemplate(null);
        closeModal?.();
    };
    const renderTaskMessageTemplateQueryInput = () => {
        return (
            <Box minWidth="300px" className="mr-2">
                <FormGroup label="Search">
                    <InputGroup
                        fill
                        placeholder="Search for template"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </FormGroup>
            </Box>
        );
    };

    const renderTaskMessageTemplateCategorySelect = () => {
        const renderItem = (
            item: TaskMessageTemplateCategoryListItemResource | null,
            { handleClick }: ItemRendererProps
        ) => {
            const isMenuItemActive = item?.id === selectedTaskMessageTemplateCategory?.id;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item ? item.id : 'all'}
                    text={item ? item.title : 'All'}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: TaskMessageTemplateCategoryListItemResource | null) => {
            setSelectedTaskMessageTemplateCategory(item);
        };

        return (
            <Box width="100%">
                <FormGroup label="Category">
                    <Select<TaskMessageTemplateCategoryListItemResource | null>
                        fill
                        disabled={!taskMessageTemplateCategories}
                        items={taskMessageTemplateCategories ? [null, ...taskMessageTemplateCategories.items] : []}
                        itemRenderer={renderItem}
                        onItemSelect={handleItemSelect}
                        popoverProps={{
                            matchTargetWidth: true,
                            usePortal: false,
                        }}
                        selectButtonProps={{
                            fill: true,
                            rightIcon: 'double-caret-vertical',
                            placeholder: 'Select category',
                            text: selectedTaskMessageTemplateCategory
                                ? TextFormatUtils.truncate(selectedTaskMessageTemplateCategory.title, 20)
                                : 'All',
                        }}
                    />
                </FormGroup>
            </Box>
        );
    };

    const renderTaskMessageTemplateInformation = () => {
        if (!openedTaskMessageTemplate) {
            return (
                <Card style={{ width: '500px' }}>
                    <DevText className={Classes.SKELETON}>stub for a title</DevText>
                    <DevText muted className={Classes.SKELETON}>
                        stub for a title
                    </DevText>

                    <Divider className="mt-2 mb-2" />

                    <DevText className={Classes.SKELETON}>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, quidem ipsa. Ad officia
                        vel facere corporis nemo blanditiis quasi, ex libero at numquam expedita. Debitis amet
                        reprehenderit dolores tempora corporis. Lorem ipsum dolor sit amet consectetur
                    </DevText>
                    <Flex className="mt-2" justify="flex-end">
                        <Button outlined className="mr-1" onClick={handleCloseTaskMessageTemplateInformationPopover}>
                            Close
                        </Button>

                        <Button disabled={openedTaskMessageTemplate === null} intent={Intent.PRIMARY}>
                            Use this template
                        </Button>
                    </Flex>
                </Card>
            );
        }

        const taskMessageTemplateContentAsEditorState = RichTextUtils.convertHTMLToEditorState(
            openedTaskMessageTemplate.content
        );

        return (
            <Card style={{ width: '500px' }}>
                <Heading type="h6">{openedTaskMessageTemplate.title}</Heading>
                <DevText muted>{openedTaskMessageTemplate.summary}</DevText>
                <Divider className="mt-1 mb-1" />
                <RichText value={taskMessageTemplateContentAsEditorState} />
                <Flex className="mt-2" justify="flex-end">
                    <Button outlined className="mr-1" onClick={handleCloseTaskMessageTemplateInformationPopover}>
                        Close
                    </Button>
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={() =>
                            handleSelectTaskMessageTemplate({
                                ...openedTaskMessageTemplate,
                                content: new RichTextFormat(
                                    taskMessageTemplateContentAsEditorState,
                                    richEditorPlugins
                                ).toString(),
                            })
                        }
                    >
                        Use this template
                    </Button>
                </Flex>
            </Card>
        );
    };

    const renderTaskMessageTemplate = (taskMessageTemplate: TaskMessageTemplateListItemResource) => {
        return (
            <Popover
                fill
                isOpen={openedTaskMessageTemplateId !== null && openedTaskMessageTemplateId === taskMessageTemplate.id}
                content={renderTaskMessageTemplateInformation()}
            >
                <Card
                    interactive
                    compact
                    elevation={Elevation.ONE}
                    className="mb-1"
                    onClick={() =>
                        openedTaskMessageTemplateId
                            ? handleCloseTaskMessageTemplateInformationPopover()
                            : handleOpenTaskMessageTemplateInformationPopover(taskMessageTemplate.id)
                    }
                >
                    <Flex className="mb-1">
                        <Circle className="mr-small" size="16px" color={taskMessageTemplate.category.color} />
                        <Heading type="h6">{taskMessageTemplate.title}</Heading>
                    </Flex>
                    <DevText muted>{taskMessageTemplate.summary}</DevText>
                </Card>
            </Popover>
        );
    };

    const renderTaskMessageTemplates = () => {
        if (!taskMessageTemplateCategories || !taskMessageTemplates) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (taskMessageTemplates.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No task message templates found
                        </Heading>
                    }
                />
            );
        }

        return taskMessageTemplates.items.map(renderTaskMessageTemplate);
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (!taskMessageTemplates || !taskMessageTemplateCategories) {
            return null;
        }

        // Check if offset paginator exist
        if (taskMessageTemplates.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskMessageTemplates.paginator.has_more && taskMessageTemplates.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex className="mt-2" justify="flex-end">
                <Pagination
                    fetching={isFetchingTaskMessageTemplates}
                    hasMore={taskMessageTemplates.paginator.has_more}
                    page={page}
                    amountOfItemsOnPage={amountOfTaskMessageTemplatesOnPage}
                    totalItems={taskMessageTemplates.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Template picker</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <Flex fullWidth className="mb-1">
                    {renderTaskMessageTemplateQueryInput()}
                    {renderTaskMessageTemplateCategorySelect()}
                </Flex>

                {renderTaskMessageTemplates()}

                {renderPagination()}
            </Card>
        </Overlay>
    );
};

export default TaskMessageTemplatePickerModal;
