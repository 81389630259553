import styled, { css } from 'styled-components';
import { FlexProps } from '@components/Flex';

export const StyledFlex = styled.div<{
    $fullHeight: FlexProps['fullHeight'];
    $fullWidth: FlexProps['fullWidth'];
    $inline: FlexProps['inline'];
    $direction: FlexProps['direction'];
    $align: FlexProps['align'];
    $justify: FlexProps['justify'];
    $flexGrow: FlexProps['flexGrow'];
    $flexWrap: FlexProps['flexWrap'];
    $gap: FlexProps['gap'];
}>`
    /* Full height */
    ${({ theme, $fullHeight }) => {
        // Not full height
        if (!$fullHeight) {
            return null;
        }

        if (typeof $fullHeight === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    height: ${$fullHeight['lg'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    height: ${$fullHeight['md'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    height: ${$fullHeight['sm'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    height: ${$fullHeight['xs'] ? '100%' : 'auto'};
                }
            `;
        } else {
            return css`
                height: 100%;
            `;
        }
    }}

    /* Full with */
    ${({ theme, $fullWidth }) => {
        // Not full width
        if (!$fullWidth) {
            return null;
        }

        if (typeof $fullWidth === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    width: ${$fullWidth['lg'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    width: ${$fullWidth['md'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    width: ${$fullWidth['sm'] ? '100%' : 'auto'};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    width: ${$fullWidth['xs'] ? '100%' : 'auto'};
                }
            `;
        } else {
            return css`
                width: 100%;
            `;
        }
    }}

    /* Inline */
    ${({ theme, $inline }) => {
        // Inline was not passed
        if ($inline === undefined) {
            return null;
        }

        if (typeof $inline === 'object') {
            // Object
            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    display: ${$inline['lg'] ? 'flex-inline' : 'flex'};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    display: ${$inline['md'] ? 'flex-inline' : 'flex'};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    display: ${$inline['sm'] ? 'flex-inline' : 'flex'};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    display: ${$inline['xs'] ? 'flex-inline' : 'flex'};
                }
            `;
        } else {
            return css`
                display: ${$inline ? 'flex-inline' : 'flex'};
            `;
        }
    }}

    /* Direction */
    ${({ theme, $direction }) => {
        // Direction was not passed
        if ($direction === undefined) {
            return null;
        }

        if (typeof $direction === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    flex-direction: ${$direction['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    flex-direction: ${$direction['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    flex-direction: ${$direction['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    flex-direction: ${$direction['xs']};
                }
            `;
        } else {
            return css`
                flex-direction: ${$direction};
            `;
        }
    }}

    /* Align */
    ${({ theme, $align }) => {
        // Align was not passed
        if ($align === undefined) {
            return null;
        }

        if (typeof $align === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    align-items: ${$align['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    align-items: ${$align['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    align-items: ${$align['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    align-items: ${$align['xs']};
                }
            `;
        } else {
            return css`
                align-items: ${$align};
            `;
        }
    }}

    /* Justify */
    ${({ theme, $justify }) => {
        // Align was not passed
        if ($justify === undefined) {
            return null;
        }

        if (typeof $justify === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    justify-content: ${$justify['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    justify-content: ${$justify['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    justify-content: ${$justify['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    justify-content: ${$justify['xs']};
                }
            `;
        } else {
            return css`
                justify-content: ${$justify};
            `;
        }
    }}

    /* Flex grow */
    ${({ theme, $flexGrow }) => {
        // Flex grow was not passed
        if ($flexGrow === undefined) {
            return null;
        }

        if (typeof $flexGrow === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    flex-grow: ${$flexGrow['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    flex-grow: ${$flexGrow['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    flex-grow: ${$flexGrow['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    flex-grow: ${$flexGrow['xs']};
                }
            `;
        } else {
            return css`
                flex-grow: ${$flexGrow};
            `;
        }
    }}

    /* Flex wrap */
    ${({ theme, $flexWrap }) => {
        // Flex wrap was not passed
        if ($flexWrap === undefined) {
            return null;
        }

        if (typeof $flexWrap === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    flex-wrap: ${$flexWrap['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    flex-wrap: ${$flexWrap['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    flex-wrap: ${$flexWrap['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    flex-wrap: ${$flexWrap['xs']};
                }
            `;
        } else {
            return css`
                flex-wrap: ${$flexWrap};
            `;
        }
    }}

    /* Gap */
    ${({ theme, $gap }) => {
        // Flex wrap was not passed
        if ($gap === undefined) {
            return null;
        }

        if (typeof $gap === 'object') {
            // Object

            return css`
                @media screen and (min-width: ${theme.breakpoints.lg}) {
                    gap: ${$gap['lg']};
                }

                @media screen and (max-width: ${theme.breakpoints.md}) {
                    gap: ${$gap['md']};
                }

                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    gap: ${$gap['sm']};
                }

                @media screen and (max-width: ${theme.breakpoints.xs}) {
                    gap: ${$gap['xs']};
                }
            `;
        } else {
            return css`
                gap: ${$gap};
            `;
        }
    }}
`;
