import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { $task } from '../../store/states';
import TaskComplexityManager from './components/TaskComplexityManagement';
import TaskPriorityManagement from './components/TaskPriorityManagement';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
/*
    Show high priority tag (danger + minimal) before task title on task page
*/

const TaskManagement: React.FC = () => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const { changeTitle } = usePageTitle('Request Management');

    const task = useStore($task);

    const [isPriorityManageAllowed, setIsPriorityManageAllowed] = useState(false);
    const [isComplexityManageAllowed, setIsComplexityManageAllowed] = useState(false);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Management - ${task.title}`);
    }, [task?.title]);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission set
        if (permissions.isRoot.task) {
            setIsPriorityManageAllowed(true);
            setIsComplexityManageAllowed(true);
            return;
        }

        // Permission: priority
        if (permissions.has(TaskPermission.PRIORITY_MANAGE)) {
            setIsPriorityManageAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsPriorityManageAllowed(isAssigned && permissions.has(TaskPermission.PRIORITY_MANAGE_MOD_ASSIGNED));
        }

        // Permission: complexity
        if (permissions.has(TaskPermission.COMPLEXITY_MANAGE)) {
            setIsComplexityManageAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsComplexityManageAllowed(isAssigned && permissions.has(TaskPermission.COMPLEXITY_MANAGE_MOD_ASSIGNED));
        }

    }, [task, permissions, me])

    const renderPriorityManage = () => {
        // Ensure allowed
        if (!isPriorityManageAllowed) {
            return null;
        }

        return (
            <TaskPriorityManagement className="mb-2" />
        );
    }

    const renderComplexityManage = () => {
        // Ensure allowed
        if (!isComplexityManageAllowed) {
            return null;
        }

        return <TaskComplexityManager />;
    }

    return (
        <div>
            {renderPriorityManage()}
            {renderComplexityManage()}
        </div>
    );
};

export default TaskManagement;
