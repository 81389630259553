import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Callout, Intent } from '@blueprintjs/core';
import { openModal } from '@app/containers/modals/store/events';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import RestoreTaskModal, { RestoreTaskModalProps } from '@pages/Task/modals/RestoreTaskModal';
import { taskStateInformation } from '@data/consts';
import moment from 'moment';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Archived: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const authorizedUser = useStore($authorizedUser);
    const task = useStore($task);

    if (!authorizedUser) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // Get archived timestamp
    const archivedAt = moment.utc(task.archived_at).format('DD MMM, YYYY');

    const renderTaskRestoreFlowButton = () => {
        if (!permissions.isRoot.task && !permissions.has(TaskPermission.RESTORE)) {
            return null;
        }

        return (
            <Button
                minimal
                icon="history"
                onClick={
                    () => openModal<RestoreTaskModalProps>({
                        ModalComponent: RestoreTaskModal, data: {
                            taskId: task.id,
                            ownerUserId: task.user.id,
                            taskPublishType: task.publish?.type,
                            currentTaskState: task.state,
                            currentTaskQueue: task.queue,
                        },
                    })
                }
            >Restore request</Button>
        );
    };

    // Implicit state: archived
    return (
        <Callout intent={Intent.DANGER} icon="trash">
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Heading type="h4">Request is archived @ {archivedAt}</Heading>
                </Flex>
                <Flex>
                    <div>{renderTaskRestoreFlowButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Before it was archived, request was in "{taskStateInformation[task.state].title}" state
            </DevText>
        </Callout>
    );
};

export default Archived;
