import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultInvalidDirectoryNameMessage = 'Invalid directory name';
const directoryNamRegex = /^(\w+\.?)*\w+$/;
const getStringDirectoryNameValidator: GetValidatorFunction<
    {
        invalidDirectoryNameMessage?: string;
    },
    string
> = (options = { invalidDirectoryNameMessage: defaultInvalidDirectoryNameMessage }) => {
    const { invalidDirectoryNameMessage } = options;

    return async (value) => {
        if (directoryNamRegex.test(value)) {
            // Valid directory name
            return null;
        } else {
            if (invalidDirectoryNameMessage) {
                return invalidDirectoryNameMessage;
            } else {
                return defaultInvalidDirectoryNameMessage;
            }
        }
    };
};

export default getStringDirectoryNameValidator;
