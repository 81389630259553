import { closeAllModals } from '@modals/store/events';
import { LocalStorage } from '@app/data/enums';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { resetNotifications } from '@app/containers/components/Notifications/store/events';
import { resetAuthorizedUser, resetPermissions, resetIsAuthorized, resetWorkspaceInitData } from '@app/containers/store/events';

const useLogout = () => {
    const handleLogout = () => {
        // Clear local storage
        localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorage.REFRESH_TOKEN);

        // Clear repository token
        repository.removeAuthAccessTokenHeader();

        // Reset authorized flag, authorized user and workspace init data
        resetAuthorizedUser();
        resetPermissions();
        resetIsAuthorized();
        resetWorkspaceInitData();
        resetNotifications()
        closeAllModals();
    };

    return handleLogout;
};

export default useLogout;
