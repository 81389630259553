import { CreateCreditWalletTransactionInput } from 'dy-frontend-http-repository/lib/modules/CreditWallet/inputs';
import { CreditWalletTransactionResource } from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import { createApi } from 'effector';
import moment from 'moment';
import { $creditWallet, $creditWalletTransactions } from './states';

// API to manage credit wallet
export const creditWalletApi = createApi($creditWallet, {
    applyCredits: (store, payload: { creditAmount: number }) => {
        if (!store) {
            // Credit wallet is NOT fetched yet
            return store;
        }

        return {
            ...store,
            balance: store.balance + payload.creditAmount,
        };
    },
});

// API to manage credit wallet transactions
export const creditWalletTransactionsApi = createApi($creditWalletTransactions, {
    add: (
        store,
        payload: { transactionId: ID; creditWalletId: ID; userId: ID; input: CreateCreditWalletTransactionInput }
    ) => {
        if (!store) {
            // Credit wallet is NOT fetched yet
            return store;
        }

        const momentNowUtc = moment().utc();
        const newTransactions: CreditWalletTransactionResource = {
            id: payload.transactionId,
            ...payload.input,
            credit_wallet_id: payload.creditWalletId,
            at: momentNowUtc.format(),
            user_id: payload.userId,
        };

        return {
            ...store,
            items: [newTransactions, ...store.items],
        };
    },
});
