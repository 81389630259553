import React, { HTMLAttributes } from 'react';
import Categories from './components/TaskMessageTemplateCategories';

type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    return (
        <div {...props}>
            <Categories />
        </div>
    );
};

export default Aside;
