import { Card, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Box from '@components/Box';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { imageHashPreview } from '@data/consts';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes } from 'react';

export interface TaskCategoryItemProps {
    category: TaskCategoryResource;
}

export type Props = TaskCategoryItemProps & HTMLAttributes<HTMLDivElement>;

const TaskCategoryItem: React.FC<Props> = ({ category, ...props }) => {
    const renderAvatarBlock = () => {
        // Get avatar src
        let avatarSrc: string | null = null;
        if (category.image_hash) {
            avatarSrc = imageHashPreview.taskCategoryImage(category.image_hash, ImageHashPreviewSize.MD);
        }

        return (
            <Box position="relative" borderRadius="inherit">
                <Avatar
                    width="100%"
                    height="260px"
                    borderRadius="inherit"
                    borderBottomLeftRadius="0"
                    borderBottomRightRadius="0"
                    src={avatarSrc}
                    alt={category.title}
                />
            </Box>
        );
    };

    const renderTaskCategoryInformation = () => {
        const renderTaskCategoryPrice = () => {
            if (!category.price) {
                return null;
            }

            return (
                <Tag>{PriceUtils.formatPrice({ price: category.price.unit_amount, shouldDisplayCents: false })}</Tag>
            );
        };

        return (
            <Box className="mt-2">
                <Flex align="center" justify="space-between">
                    <DevText className="mr-1">{category.title}</DevText>
                    {renderTaskCategoryPrice()}
                </Flex>
            </Box>
        );
    };

    return (
        <Card compact interactive {...props}>
            {renderAvatarBlock()}
            {renderTaskCategoryInformation()}
        </Card>
    );
};

export default TaskCategoryItem;
