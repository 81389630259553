import { SubscriptionState } from 'dy-frontend-http-repository/lib/data/enums';

const subscriptionStatesInformation: {
    [state in SubscriptionState]: {
        label: string;
    };
} = {
    [SubscriptionState.ACTIVE]: { label: 'Active' },
    [SubscriptionState.CANCELED]: { label: 'Canceled' },
    [SubscriptionState.INCOMPLETE]: { label: 'Incomplete' },
    [SubscriptionState.INCOMPLETE_EXPIRED]: { label: 'Incomplete expired' },
    [SubscriptionState.PAST_DUE]: { label: 'Past due' },
    [SubscriptionState.TRIALING]: { label: 'Trialing' },
    [SubscriptionState.UNPAID]: { label: 'Unpaid' },
};

export default subscriptionStatesInformation;
