import { Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const StyledCategoryListItem = styled.div<{ $active: boolean }>`
    cursor: pointer;
    padding: 10px;
    min-height: 46px;
    display: flex;

    ${({ $active }) =>
        $active &&
        css`
            background: ${Colors.DARK_GRAY4};
        `}
`;
