import domain from './domain';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { CreateUserPasswordResetInput } from 'dy-frontend-http-repository/lib/modules/UserPasswordReset/inputs';
import { UserPasswordResetRef } from 'dy-frontend-http-repository/lib/modules/UserPasswordReset/refs';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/ClientSignup/refs';
import { UpdateUserPasswordAuthInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';

// Create user password reset
export const createUserPasswordResetIntent = domain.createEffect<CreateUserPasswordResetInput, UserPasswordResetRef>({
    name: 'createUserPasswordResetIntent',
    handler: async (input) => {
        return await repository.userPasswordReset().create(input);
    },
});

// Update password auth
export const updateUserPasswordAuth = domain.createEffect<{ id: ID; input: UpdateUserPasswordAuthInput }, UserRef>({
    name: 'updateUserPasswordAuth',
    handler: async ({ id, input }) => {
        return await repository.user().updatePasswordAuth(id, input);
    },
});