import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CreatePlanInput, PlanFilterInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import PlanResource from 'dy-frontend-http-repository/lib/modules/Plan/resources/PlanResource';
import { PlanRef } from 'dy-frontend-http-repository/lib/modules/Plan/refs';
import domain from './domain';

// Create plan
export const createPlan = domain.createEffect<CreatePlanInput, PlanRef>({
    name: 'createPlan',
    handler: async (input) => {
        return await repository.plan().create(input);
    },
});

// Fetch list of plans
export const fetchPlans = domain.createEffect<PlanFilterInput, CollectionResource<PlanResource, {}>>({
    name: 'fetchPlans',
    handler: async (input) => {
        return await repository.plan().get(input);
    },
});
