import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Collapse, Colors, Divider, Icon, Intent } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import {
    checkIfReleaseSubscriptionAllScheduledUpdatesActionAllowed,
    checkIfSubscriptionUpdateScheduled,
} from '@data/functions/subscription';
import {
    SchedulePhaseItemResource,
    SchedulePhaseResource,
    SubscriptionResource,
} from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { CouponUtils, PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useState } from 'react';
import { fetchSubscription, removeSubscriptionScheduledUpdates } from '../../store/effects';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';
import CardPreview from '@app/containers/components/CardPreview';

export interface SubscriptionScheduledUpdatesInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionScheduledUpdatesInformationProps;

const SubscriptionScheduledUpdatesInformation: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    const [isRemovingScheduledUpdates, setIsRemovingScheduledUpdates] = useState(false);
    const [openedSubscriptionPhasesCollapses, setOpenedSubscriptionPhasesCollapses] = useState<{
        [schedulePhaseStartAt in string]: boolean;
    }>({});

    if (!checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription schedule is NOT present
        return null;
    }

    const handleToggleCollapse = (startAt: ID) => {
        if (openedSubscriptionPhasesCollapses[startAt]) {
            setOpenedSubscriptionPhasesCollapses((prevValue) => {
                const copy = { ...prevValue };
                copy[startAt] = !copy[startAt];
                return copy;
            });
        } else {
            setOpenedSubscriptionPhasesCollapses((prevValue) => {
                const copy = { ...prevValue };
                copy[startAt] = true;
                return copy;
            });
        }
    };

    const renderSubscriptionSchedulePhase = ({
        schedulePhase,
        withCollapse = false,
    }: {
        schedulePhase: SchedulePhaseResource;
        withCollapse: boolean;
    }) => {
        const renderPhaseHeader = () => {
            // Get sub title
            let subTitle = 'When applied: Automatically adjust billing cycle anchor (if needed)';
            // TODO: 'phase_start' should be moved to const or enum
            if (schedulePhase.billing_cycle_anchor_behavior === 'phase_start') {
                subTitle = `When applied: Set billing cycle to ${moment(schedulePhase.start_at).format('D MMM, YYYY')}`;
            }

            return (
                <Flex direction="row" justify="space-between">
                    <div>
                        <Flex className="mb-1" direction="row" align="center">
                            <Heading type="h5">{moment(schedulePhase.start_at).format('D MMM, YYYY')}</Heading>
                            <Icon className="ml-1 mr-1" icon="chevron-right" color={Colors.GRAY2} />
                            <Heading type="h5">{moment(schedulePhase.end_at).format('D MMM, YYYY')}</Heading>
                        </Flex>

                        <DevText muted>{subTitle}</DevText>
                    </div>

                    <DevText muted>Will be applied: {moment(schedulePhase.start_at).fromNow()}</DevText>
                </Flex>
            );
        };

        const renderPhaseItems = () => {
            if (schedulePhase.items.length === 0) {
                return null;
            }

            const renderPhaseItem = (phaseItem: SchedulePhaseItemResource) => {
                const renderUnknownPhaseItem = () => {
                    return (
                        <Flex className="mt-1" direction="row" align="center">
                            <Icon icon="help" className="mr-1" color={Colors.GRAY2} />
                            <Heading muted type="h5" className="mr-1">
                                Unknown additional payment
                            </Heading>
                            <DevText muted>x{phaseItem.quantity}</DevText>
                            <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                            <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                            <DevText muted>Sync price to fetch info</DevText>
                        </Flex>
                    );
                };

                const price = phaseItem.price;

                if (!price) {
                    return renderUnknownPhaseItem();
                }

                // Get currency
                const phaseItemPriceCurrency = price.currency.toUpperCase();

                // Get price for single phase item
                const phaseItemPriceFormatted = PriceUtils.formatPrice({
                    price: price.unit_amount,
                    shouldDisplayCents: true,
                });

                // Get label for recurring interval "2 days", "day", "3 months", etc.
                const phaseItemRecurringInterval = PriceUtils.getRecurringInterval(
                    price.recurring_interval,
                    price.recurring_interval_count,
                );

                const renderPlanPhaseItem = () => {
                    const plan = price.plan!;

                    return (
                        <Flex className="mt-1" direction="row" align="center">
                            <Icon icon="box" className="mr-1" color={plan.color} />
                            <Heading type="h5" className="mr-1">
                                {plan.title}
                            </Heading>
                            <DevText muted>x{phaseItem.quantity}</DevText>
                            <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                            <DevText muted>
                                {phaseItemPriceFormatted} {phaseItemPriceCurrency} / {phaseItemRecurringInterval}
                            </DevText>
                        </Flex>
                    );
                };

                const renderPaymentPhaseItem = () => {
                    return (
                        <Flex className="mt-1" direction="row" align="center">
                            <Icon icon="bank-account" className="mr-1" />
                            <Heading type="h5" className="mr-1">
                                Additional payment: {phaseItemPriceFormatted}
                            </Heading>
                            <DevText muted>x{phaseItem.quantity}</DevText>
                            <Circle className="ml-2 mr-2" size="6px" color={Colors.GRAY2} />
                            <DevText muted>
                                {phaseItemPriceFormatted} {phaseItemPriceCurrency} / {phaseItemRecurringInterval}
                            </DevText>
                        </Flex>
                    );
                };

                if (price.plan) {
                    // Plan related to price
                    return renderPlanPhaseItem();
                } else {
                    // Plan is NOT related to price, meaning it's regular payment item
                    return renderPaymentPhaseItem();
                }
            };

            return (
                <>
                    <Divider className="mt-2 mb-2" />
                    <div>
                        <DevText muted className="mb-1">
                            Subscription items
                        </DevText>

                        {schedulePhase.items.map(renderPhaseItem)}
                    </div>
                </>
            );
        };

        const renderPhaseCoupon = () => {
            const coupon = schedulePhase.coupon;

            if (!coupon) {
                // Phase do NOT have coupon attached
                return null;
            }

            // Get duration text
            const durationText = CouponUtils.getCouponDurationText(coupon);

            // Get price/percentage discount text
            const couponDiscountText = CouponUtils.getCouponDiscountText(coupon);

            return (
                <div className="mt-2">
                    <DevText muted className="mb-1">
                        Coupon
                    </DevText>

                    <Heading type="h5">
                        {couponDiscountText} / {coupon.title} / {durationText}
                    </Heading>
                </div>
            );
        };

        const renderPhasePaymentMethod = () => {
            const renderPaymentMethodLabel = () => {
                const paymentMethod = schedulePhase.payment_method;

                if (!paymentMethod) {
                    return <DevText>Default or current payment method used</DevText>;
                }

                return (
                    <Flex direction="row" align="center">
                        <CardPreview className="mr-1" width="40px" height="30px" type={paymentMethod.card_brand} />
                        <DevText>
                            {paymentMethod.card_brand.toUpperCase()} ending in *{paymentMethod.card_last_four}
                        </DevText>
                    </Flex>
                );
            };

            return (
                <div className="mt-2">
                    <DevText muted className="mb-1">
                        Payment Method
                    </DevText>

                    {renderPaymentMethodLabel()}
                </div>
            );
        };

        const renderPhaseTrial = () => {
            if (!schedulePhase.trial_end_at) {
                // Phase do NOT have trial end date set
                return null;
            }

            return (
                <div className="mt-2">
                    <DevText muted className="mb-1">
                        Trial
                    </DevText>

                    <Heading type="h5">Set trial end {moment(schedulePhase.trial_end_at).fromNow()}</Heading>
                </div>
            );
        };

        if (withCollapse) {
            return (
                <Card
                    interactive
                    className="mb-2"
                    key={schedulePhase.start_at}
                    onClick={() => handleToggleCollapse(schedulePhase.start_at)}
                >
                    {renderPhaseHeader()}
                    <Collapse isOpen={openedSubscriptionPhasesCollapses[schedulePhase.start_at]}>
                        {renderPhaseItems()}
                        {renderPhaseCoupon()}
                        {renderPhasePaymentMethod()}
                        {renderPhaseTrial()}
                    </Collapse>
                </Card>
            );
        } else {
            return (
                <Card className="mb-2" key={schedulePhase.start_at}>
                    {renderPhaseHeader()}
                    {renderPhaseItems()}
                    {renderPhaseCoupon()}
                    {renderPhasePaymentMethod()}
                    {renderPhaseTrial()}
                </Card>
            );
        }
    };

    const renderRemoveScheduledUpdatesButton = () => {
        const isAuthAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.SCHEDULE_RELEASE);
        const isRemoveScheduledUpdatesActionAllowed =
            isAuthAllowed
            && checkIfReleaseSubscriptionAllScheduledUpdatesActionAllowed(subscription);

        if (!isRemoveScheduledUpdatesActionAllowed) {
            return null;
        }

        const handleRemoveScheduledUpdates = async () => {
            setIsRemovingScheduledUpdates(true);

            try {
                await removeSubscriptionScheduledUpdates(subscription.id);
                const refetch = () =>
                    new Promise((resolve, reject) => {
                        setTimeout(
                            () =>
                                fetchSubscription(subscription.id)
                                    .then((r) => resolve(r))
                                    .catch((e) => reject(e)),
                            5000,
                        );
                    });
                await refetch();
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsRemovingScheduledUpdates(false);
            }
        };

        return (
            <ConfirmationPopover
                title="Are you sure you want remove all scheduled updates?"
                description="When confirmed, subscription updates will be removed"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleRemoveScheduledUpdates}
                    >
                        Yes, remove updates
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    loading={isRemovingScheduledUpdates}
                    disabled={isRemovingScheduledUpdates}
                    icon="trash"
                    intent={Intent.DANGER}
                >
                    Remove all scheduled updates
                </Button>
            </ConfirmationPopover>
        );
    };

    return (
        <div {...props}>
            <Flex className="mb-1" align="center" justify="space-between">
                <Heading type="h4">Scheduled Updates</Heading>
                {renderRemoveScheduledUpdatesButton()}
            </Flex>

            {subscription.schedule.phases.map((schedulePhase, index) =>
                renderSubscriptionSchedulePhase({ schedulePhase, withCollapse: index > 0 }),
            )}
        </div>
    );
};

export default SubscriptionScheduledUpdatesInformation;
