import domain from './domain';
import { PlatformClientUserFilterData } from '../types';

// Reset clients
export const resetClients = domain.createEvent('resetClients');

// Set page
export const setPage = domain.createEvent<number>('setPage');

// Set query hydration finished flag
export const setIsQueryHydrationFinished = domain.createEvent<boolean>('setIsQueryHydrationFinished');

// Set client user platform filter data
export const setPlatformClientUserFilterData = domain.createEvent<PlatformClientUserFilterData>(
    'setPlatformClientUserFilterData'
);

// Reset page
export const resetPage = domain.createEvent('resetPage');

// Reset platform client user filter data
export const resetPlatformClientUserFilterData = domain.createEvent('resetPlatformClientUserFilterData');

// Reset is query hydration finished
export const resetIsQueryHydrationFinished = domain.createEvent('resetIsQueryHydrationFinished');
