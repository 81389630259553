import React, { HTMLAttributes } from 'react';
import DatesInformation from './components/DatesInformation';
import Participants from './components/Participants';
import ClientInformation from './components/ClientInformation';
import TaskCategoryInformation from './components/TaskCategoryInformation';
import TaskFeedbackInformation from './components/TaskFeedbackInformation';
import Teams from './components/Teams';

type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    return (
        <div {...props}>
            <TaskFeedbackInformation className="mb-2" />
            <DatesInformation className="mb-2" />
            <TaskCategoryInformation className="mb-2" />
            <ClientInformation className="mb-2" />
            <Participants className="mb-2" />
            <Teams />
        </div>
    );
};

export default Aside;
