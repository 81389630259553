import { repository } from "dy-frontend-http-repository/lib/modules";
import domain from "./domain";
import { OffsetPaginationInput } from "dy-frontend-http-repository/lib/data/inputs";
import { CollectionResource } from "dy-frontend-http-repository/lib/data/resources";
import { WorkspaceUserListItemResource } from "dy-frontend-http-repository/lib/modules/WorkspaceUser/resources";

// Fetch workspace members list
export const fetchWorkspaceMembers = domain.createEffect<
    { pagination: OffsetPaginationInput },
    CollectionResource<WorkspaceUserListItemResource, {}>
>({
    name: "fetchWorkspaceMembers",
    handler: async ({ pagination }) => {
        return await repository.workspaceUser().get(pagination);
    },
});
