import repository from 'dy-frontend-http-repository/lib/repository';
import { PaymentAccountSetupIntentResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { SubscriptionFilterInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { InvoiceResource } from 'dy-frontend-http-repository/lib/modules/Invoice/resources';
import { InvoiceFilterInput } from 'dy-frontend-http-repository/lib/modules/Invoice/inputs';
import domain from './domain';
import { TaxIdFilterInput, CreateTaxIdInput } from 'dy-frontend-http-repository/lib/modules/TaxId/inputs';
import { TaxIdRef } from 'dy-frontend-http-repository/lib/modules/TaxId/refs';
import { TaxIdResource } from 'dy-frontend-http-repository/lib/modules/TaxId/resources';

// Fetch client subscriptions
export const fetchSubscriptions = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: SubscriptionFilterInput },
    CollectionResource<SubscriptionResource, {}>
>({
    name: 'fetchClientSubscriptions',
    handler: async ({ pagination, filter }) => {
        return await repository.subscription().get(pagination, filter);
    },
});

// Fetch client payment methods
export const fetchClientPaymentMethods = domain.createEffect<ID, CollectionResource<PaymentMethodResource, {}>>({
    name: 'fetchClientPaymentMethods',
    handler: async (paymentAccountId) => {
        return await repository.paymentMethod().get(paymentAccountId);
    },
});

// Fetch client invoices
export const fetchInvoices = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: InvoiceFilterInput },
    CollectionResource<InvoiceResource, {}>
>({
    name: 'fetchClientInvoices',
    handler: async ({ pagination, filter }) => {
        return await repository.invoice().get(pagination, filter);
    },
});

// Setup intent
export const setupIntent = domain.createEffect<{ paymentAccountId: ID }, PaymentAccountSetupIntentResource>({
    name: 'setupIntent',
    handler: async ({ paymentAccountId }) => {
        return await repository.paymentAccount().createSetupIntent(paymentAccountId);
    },
});

// Fetch tax IDs
export const fetchTaxIds = domain.createEffect<TaxIdFilterInput, CollectionResource<TaxIdResource, {}>>({
    name: 'fetchTaxIds',
    handler: async (filter) => {
        return await repository.taxId().get(filter);
    },
});

// Create tax ID
export const createTaxId = domain.createEffect<CreateTaxIdInput, TaxIdRef>({
    name: 'createTaxId',
    handler: async (input) => {
        return await repository.taxId().create(input);
    },
});

// Remove tax ID
export const removeTaxId = domain.createEffect<ID, TaxIdRef>({
    name: 'removeTaxId',
    handler: async (taxId) => {
        return await repository.taxId().remove(taxId);
    },
});
