import repository from 'dy-frontend-http-repository/lib/repository';
import { SyncLicenceInput } from 'dy-frontend-http-repository/lib/modules/Licence/inputs';
import { UpdateUserInformationInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';
import { LicenceRef } from 'dy-frontend-http-repository/lib/modules/Licence/refs';
import { ClientUserResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { UserImageHashRef, UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import domain from './domain';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { ComputedLicenceResource } from 'dy-frontend-http-repository/lib/modules/ComputedLicence/resources';
import { QuotaUsageFilterInput } from 'dy-frontend-http-repository/lib/modules/QuotaUsage/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { QuotaUsageResource } from 'dy-frontend-http-repository/lib/modules/QuotaUsage/resources';

// Fetch computed licence
export const fetchComputedLicence = domain.createEffect<ID, ComputedLicenceResource>({
    name: 'fetchComputedLicence',
    handler: async (userId) => {
        return await repository.computedLicence().getForUser(userId);
    },
});

// Fetch computed licence
export const fetchQuotaUsage = domain.createEffect<
    { userId: ID; filter: QuotaUsageFilterInput },
    CollectionResource<QuotaUsageResource, {}>
>({
    name: 'fetchQuotaUsage',
    handler: async ({ userId, filter }) => {
        return await repository.quotaUsage().get(userId, filter);
    },
});

// TODO: refetch licence for user after synchronization
// Synchronize subscription licences
export const synchronizeSubscriptionLicences = domain.createEffect<SyncLicenceInput, LicenceRef>({
    name: 'synchronizeSubscriptionLicences',
    handler: async (input) => {
        return await repository.licence().sync(input);
    },
});

// Fetch client
export const fetchClient = domain.createEffect<ID, ClientUserResource>({
    name: 'fetchClient',
    handler: async (clientId) => {
        return await repository.clientUser().getById(clientId);
    },
});

// Update client information
export const updateClientInformation = domain.createEffect<{ id: ID; input: UpdateUserInformationInput }, UserRef>({
    name: 'updateClientInformation',
    handler: async ({ id, input }) => {
        return await repository.user().update(id, input);
    },
});

// Fetch client payment account
export const fetchClientPaymentAccount = domain.createEffect<ID, PaymentAccountResource>({
    name: 'fetchPaymentAccount',
    handler: async (clientId) => {
        return await repository.paymentAccount().getPaymentAccountAssociatedWithUser(clientId);
    },
});

// Upload user image
// TODO: move?
export const uploadAndSetClientImage = domain.createEffect<{ id: ID; file: File }, UserImageHashRef>({
    name: 'uploadAndSetClientImage',
    handler: async ({ id, file }) => {
        return await repository.user().uploadAndSetImage(id, file);
    },
});

// Remove user image
// TODO: move?
export const removeClientImage = domain.createEffect<ID, UserRef>({
    name: 'removeClientImage',
    handler: async (id) => {
        return await repository.user().removeImage(id);
    },
});