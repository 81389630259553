import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { UserUtils } from '@app/containers/components/Notifications/utils';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import NotificationTemplate from '../../../../NotificationTemplate';
import {
    NotificationResource,
    TaskQueueTransitionedActivityPayloadResource,
    TaskResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';

export interface TaskQueueTransitionedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    task: TaskResource | null;
}

type Props = TaskQueueTransitionedNotificationProps;

const TaskQueueTransitionedNotification: React.FC<Props> = ({ authorizedUserId, notification, task }) => {
    const renderContent = () => {
        if (!task) {
            // This is bad, task is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Request was not found</DevText>;
        }

        const payload: TaskQueueTransitionedActivityPayloadResource | null = notification.activity.payload;
        if (!payload) {
            // This is bad, there should be payload, but for some reason it is not there
            return <DevText color={Colors.RED2}>Payload was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} changed queue for{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', task.id)}
                    >
                        {task.title}
                    </RouterLink>{' '}
                    from {payload.from} to {payload.to}, {payload.reason} reason
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Queue transitioned"
            icon="box"
            at={notification.activity.at}
            content={renderContent()}
        />
    );
};

export default TaskQueueTransitionedNotification;
