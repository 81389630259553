import domain from './domain';

// Reset workspace signup step
export const resetStep = domain.createEvent('resetStep');

// Reset workspace signup data
export const resetWorkspaceSignupData = domain.createEvent('resetWorkspaceSignupData');

// Reset workspace initial data
export const resetWorkspaceInitData = domain.createEvent('resetWorkspaceInitData');

// Reset workspace initial data
export const resetWorkspaceSignupId = domain.createEvent('resetWorkspaceSignupId');
