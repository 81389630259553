import { Popover, Card, Button } from '@blueprintjs/core';
import React, { HTMLAttributes, useState } from 'react';
import DevText from '../Text';

export interface CopyTextButtonProps {
    copyText: string;
    confirmationDelayMS?: number;
}

export type Props = CopyTextButtonProps & HTMLAttributes<HTMLDivElement>;

const CopyTextButton: React.FC<Props> = ({ copyText, confirmationDelayMS = 1500, ...props }) => {
    const [isConfirmationPopoverOpen, setIsConfirmationPopoverOpen] = useState(false);

    const handleCopyText = () => {
        // Copy
        navigator.clipboard.writeText(copyText);

        // Show popover
        if (!isConfirmationPopoverOpen) {
            setIsConfirmationPopoverOpen(true);
            setTimeout(() => {
                setIsConfirmationPopoverOpen(false);
            }, confirmationDelayMS);
        }
    };

    return (
        <Popover
            isOpen={isConfirmationPopoverOpen}
            interactionKind="click"
            content={
                <Card compact>
                    <DevText>Copied</DevText>
                </Card>
            }
            onClose={() => setIsConfirmationPopoverOpen(false)}
            {...props}
        >
            <Button minimal icon="duplicate" onClick={handleCopyText} />
        </Popover>
    );
};

export default CopyTextButton;
