import repository from 'dy-frontend-http-repository/lib/repository';
import { UserImageHashRef, UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import domain from './domain';
import { WorkspaceUserResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceUser/resources';

// Fetch workspace user
export const fetchWorkspaceUser = domain.createEffect<ID, WorkspaceUserResource>({
    name: 'fetchWorkspaceUser',
    handler: async (workspaceUserId) => {
        return await repository.workspaceUser().getById(workspaceUserId);
    },
});

// Upload user image
// TODO: move?
export const uploadAndSetWorkspaceUserImage = domain.createEffect<{ id: ID; file: File }, UserImageHashRef>({
    name: 'uploadAndSetWorkspaceUserImage',
    handler: async ({ id, file }) => {
        return await repository.user().uploadAndSetImage(id, file);
    },
});

// Remove user image
// TODO: move?
export const removeWorkspaceUserImage = domain.createEffect<ID, UserRef>({
    name: 'removeWorkspaceUserImage',
    handler: async (id) => {
        return await repository.user().removeImage(id);
    },
});
