import { TaskPublishType } from 'dy-frontend-http-repository/lib/data/enums';

const taskPublishTypeInformation: {
    [taskPublishType in TaskPublishType]: {
        title: string;
    };
} = {
    [TaskPublishType.CREDIT]: {
        title: 'Credit',
    },
    [TaskPublishType.MANUAL]: {
        title: 'Manual',
    },
    [TaskPublishType.PAYMENT]: {
        title: 'Payment',
    },
    [TaskPublishType.QUOTA]: {
        title: 'Quota',
    },
};

export default taskPublishTypeInformation;
