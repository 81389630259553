import { ReturnValue as TextFormFieldReturnValue } from '@app/hooks/validation/useTextFormField';
import { FormGroup, FormGroupProps, InputGroup, InputGroupProps, Intent } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface InputFormFieldProps {
    field: TextFormFieldReturnValue;
    formGroupProps?: FormGroupProps;
    inputProps?: InputGroupProps;
}

export type Props = HTMLAttributes<HTMLDivElement> & InputFormFieldProps;

const InputFormField: React.FC<Props> = ({ field, formGroupProps, inputProps, ...props }) => {
    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <InputGroup
                fill
                id={field.id}
                intent={!!field.error ? Intent.DANGER : Intent.NONE}
                value={field.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                {...inputProps}
            />
        </FormGroup>
    );
};

export default InputFormField;
