import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import DevText from '@components/Text';
import { IconName } from '@blueprintjs/icons';
import { Endpoints, taskStateInformation } from '@data/consts';
import RouterLink from '@components/RouterLink';
import { taskApi } from '@pages/Task/store/apis';
import { restoreTask } from '@pages/Task/store/effects';

export interface RestoreTaskModalProps {
    taskId: ID;
    ownerUserId: ID;
    taskPublishType: TaskPublishType | null;
    currentTaskState: TaskState;
    currentTaskQueue: TaskQueue;
}

type Props = ModalProps<RestoreTaskModalProps>;

const RestoreTaskModal: React.FC<Props> = ({
    closeModal,
    data,
}) => {
    // Task restoring
    const [isTaskRestoring, setIsTaskRestoring] = useState(false);
    const [isTaskRestoreDone, setIsTaskRestoreDone] = useState(false);

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    // Check: is task published
    const isDraft = data.currentTaskQueue === TaskQueue.DRAFT || data.taskPublishType === null;

    // Check: is delivered
    const isDelivered = data.currentTaskState === TaskState.DELIVERED;

    // Get readable state name
    const readableStateName = taskStateInformation[data.currentTaskState]?.title ?? 'Unknown';

    // Change: will be moved to the backlog?
    const isBacklogMovementChange = (
        // If not draft or delivered...
        !isDraft && !isDelivered

        // ... and not in backlog state already
        && data.currentTaskQueue !== TaskQueue.BACKLOG
    );

    // Change: will be locked in backlog
    const isBacklogAutomationLockChange = (
        // If not draft or delivered...
        !isDraft && !isDelivered

        // ... and published through `quota`
        && data.taskPublishType === TaskPublishType.QUOTA
    );

    const handleModalClose = () => {
        if (isTaskRestoring) {
            return;
        }

        // O_o?
        closeModal?.();
        return null;
    }

    const handleRestoreFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsTaskRestoring(true);

        restoreTask(data.taskId)
            .then(() => {
                setIsTaskRestoreDone(true);

                taskApi.restore();
            })
            .catch((e) => console.error)
            .finally(() => setIsTaskRestoring(false));

        return null;
    };

    const renderExpectedChangeItem = (icon: IconName, title: String, description: String) => {
        return (
            <div>
                <Flex align="center" className="mb-1">
                    <Icon icon={icon} className="mr-1" />
                    <Heading type="h5">{title}</Heading>
                </Flex>
                <DevText muted running>
                    {description}
                </DevText>
            </div>
        );
    };

    const renderExpectedChangesInfo = () => {
        const changes: React.ReactNode[] = [];

        // Default change: restore to <state> state
        changes.push(renderExpectedChangeItem(
            'history',
            `Restore request back to "${readableStateName}" state`,
            `Request was in "${readableStateName}" state before it was archived`,
        ));

        // Backlog movement
        if (isBacklogMovementChange) {
            changes.push(renderExpectedChangeItem(
                'unarchive',
                `Move request to backlog`,
                `
                    To prevent confusion with request order, this request will be moved to backlog.
                    After that, you will be able to manually move it to "active"
                    requests on the client's "Requests" page.
                `,
            ));
        }

        // Backlog queue lock
        if (isBacklogAutomationLockChange) {
            changes.push(renderExpectedChangeItem(
                'lock',
                `Lock request in the backlog`,
                `
                    Client used their subscription plan limits when publishing this request. Such requests are moved
                    from backlog to active automatically. To prevent this potentially unwanted behaviour, we will
                    lock any automatic movement on this request. After restoring request, you will be able to remove
                    this lock on the client's "Requests" page.
                `,
            ));
        }

        return changes.map((item, index) => (
            <div key={`restore-change-${index}`} className="mb-2">{item}</div>
        ));
    };

    const renderRestoreForm = () => {
        return (
            <form onSubmit={handleRestoreFormSubmit}>
                {renderExpectedChangesInfo()}

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed with restoring the request?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={isTaskRestoring}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={isTaskRestoring}
                            type="submit"
                        >
                            Yes, restore request
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    const renderRestoreSuccess = () => {
        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Request successfully restored</Heading>
                </Flex>

                <DevText muted className="mb-2">This window can now be closed</DevText>

                {(isBacklogMovementChange || isBacklogAutomationLockChange) && (
                    <DevText>
                        You can move request from backlog, or unlock it on{' '}
                        <RouterLink
                            className="mr-1"
                            onClick={closeModal}
                            to={Endpoints.CLIENT_TASKS.replace(':clientId', data.ownerUserId)}
                        >
                            client's "Requests" page
                        </RouterLink>
                    </DevText>
                )}
            </div>
        );
    };

    const renderModalTitleCaption = () => {
        // Initial
        if (!isTaskRestoring && !isTaskRestoreDone) {
            return (
                <DevText muted>Here is what will happen to request after it is restored</DevText>
            );
        }

        // Restoring...
        if (isTaskRestoring) {
            return (
                <DevText muted>Restoring request...</DevText>
            );
        }

        // Restore done
        if (!isTaskRestoring && isTaskRestoreDone) {
            return (
                <DevText muted>Request successfully restored!</DevText>
            );
        }

        return null;
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">Restore archived request</Heading>
                        {renderModalTitleCaption()}
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isTaskRestoring} />
                </Flex>

                <Divider className="mb-2" />

                {!isTaskRestoreDone ? renderRestoreForm() : renderRestoreSuccess()}
            </Card>
        </Overlay>
    );
};

export default RestoreTaskModal;
