import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { subscriptionCancelReasonInformation, subscriptionCancelTypeInformation } from '@data/consts';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';

export interface SubscriptionCancellationInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionCancellationInformationProps;

const SubscriptionCancellationInformation: React.FC<Props> = ({ subscription, ...props }) => {
    const cancelation = subscription.cancellation;

    if (!cancelation.is_canceled) {
        // Subscription is NOT cancelled, meaning there is no information for subscription cancelation
        return null;
    }

    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">
                Cancellation Details
            </Heading>

            <Card>
                {/* Canceled at */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="delete"
                    title="Canceled at"
                    text={moment(cancelation.canceled_at).format('D MMM, YYYY - HH:mm')}
                />

                {/* Type */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="selection"
                    title="Type"
                    text={cancelation.type ? subscriptionCancelTypeInformation[cancelation.type] : ''}
                />

                {/* Reason */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="comparison"
                    title="Reason"
                    text={cancelation.reason ? subscriptionCancelReasonInformation[cancelation.reason] : ''}
                />

                {/* Additional comment*/}
                <CommonInformationBlock icon="comment" title="Additional comment" text={cancelation.comment ?? ''} />
            </Card>
        </div>
    );
};

export default SubscriptionCancellationInformation;
