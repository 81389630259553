import { Button, Divider } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { closeAllModals } from '@containers/modals/store/events';
import { useStore } from 'effector-react';
import React from 'react';
import { publishStepsInformation } from '../../consts';
import { PublishTaskStep } from '../../enums';
import { useTaskPublish } from '../../hooks';
import { TaskPublishChoiceType } from '../../hooks/useTaskPublish/enums';
import { changeStep } from '../../store/events';
import { $clientCreditWallet } from '../../store/states';
import { CommonStepProps } from '../../types';
import TaskPublishMethodStepItem, {
    Props as TaskPublishMethodStepItemProps,
} from './components/TaskPublishMethodStepItem';
import Heading from '@components/Heading';

export type Props = CommonStepProps;

const SelectTaskPublishMethodStep: React.FC<Props> = ({ closeModal, data }) => {
    const creditWallet = useStore($clientCreditWallet);
    const { taskPublishChoices } = useTaskPublish({ taskId: data.taskId, creditWallet });

    const renderTaskPublishMethodSteps = () => {
        let quotaStepWasAlreadyAdded = false;

        if (taskPublishChoices.length === 0) {
            // There are no publish choices exist
            closeModal?.();
            return;
        }

        return taskPublishChoices.map((taskPublishChoice) => {
            let taskPublishMethodStepProps: TaskPublishMethodStepItemProps = {
                title: '',
                description: '',
            };

            switch (taskPublishChoice) {
                case TaskPublishChoiceType.ACTIVE_TASK:
                case TaskPublishChoiceType.BACKLOG_TASK: {
                    if (quotaStepWasAlreadyAdded) {
                        return null;
                    }

                    taskPublishMethodStepProps = {
                        title: publishStepsInformation[PublishTaskStep.QUOTA]?.title ?? '',
                        description: publishStepsInformation[PublishTaskStep.QUOTA]?.description ?? '',
                        onClick: () => changeStep(PublishTaskStep.QUOTA),
                    };

                    quotaStepWasAlreadyAdded = true;
                    break;
                }
                case TaskPublishChoiceType.CREDIT:
                    taskPublishMethodStepProps = {
                        title: publishStepsInformation[PublishTaskStep.CREDIT]?.title ?? '',
                        description: publishStepsInformation[PublishTaskStep.CREDIT]?.description ?? '',
                        onClick: () => changeStep(PublishTaskStep.CREDIT),
                    };
                    break;
                case TaskPublishChoiceType.PAYMENT:
                    taskPublishMethodStepProps = {
                        title: publishStepsInformation[PublishTaskStep.PAYMENT]?.title ?? '',
                        description: publishStepsInformation[PublishTaskStep.PAYMENT]?.description ?? '',
                        onClick: () => changeStep(PublishTaskStep.PAYMENT),
                    };
                    break;
                case TaskPublishChoiceType.MANUAL:
                    taskPublishMethodStepProps = {
                        title: publishStepsInformation[PublishTaskStep.MANUAL]?.title ?? '',
                        description: publishStepsInformation[PublishTaskStep.MANUAL]?.description ?? '',
                        onClick: () => changeStep(PublishTaskStep.MANUAL),
                    };
                    break;
            }

            return <TaskPublishMethodStepItem className="mt-2" {...taskPublishMethodStepProps} />;
        });
    };

    return (
        <div>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Select publish method</Heading>
                <Button minimal icon="cross" onClick={() => closeAllModals()} />
            </Flex>

            <Divider className="mb-2" />

            {renderTaskPublishMethodSteps()}
        </div>
    );
};

export default SelectTaskPublishMethodStep;
