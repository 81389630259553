import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CoreUserResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import domain from './domain';
import { resetCoreUser, resetCoreUserOccupancy } from './events';

// Core user information
export const $coreUser = domain.createStore<CoreUserResource | null>(null).reset(resetCoreUser);

// Core user occupancy
export const $coreUserOccupancy = domain.createStore<CollectionResource<UserOccupancyResource, {}> | null>(null).reset(resetCoreUserOccupancy);
