import React from 'react';
import { Card, CardProps, Elevation } from '@blueprintjs/core';
import DevText from '@components/Text';

export interface TaskPublishMethodStepItemProps {
    title: string;
    description: string;
}

export type Props = TaskPublishMethodStepItemProps & CardProps;

const TaskPublishMethodStepItem: React.FC<Props> = ({ title, description, ...props }) => {
    return (
        <Card interactive elevation={Elevation.ONE} {...props}>
            <DevText className="mb-1">{title}</DevText>
            <DevText muted>{description}</DevText>
        </Card>
    );
};

export default TaskPublishMethodStepItem;
