import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { ClientUserListItemResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { initialPlatformClientUserFilterData } from '../consts';
import { PlatformClientUserFilterData } from '../types';
import domain from './domain';
import { resetClients, resetIsQueryHydrationFinished, resetPage, resetPlatformClientUserFilterData } from './events';

// Clients
export const $clients = domain
    .createStore<CollectionResource<ClientUserListItemResource, {}> | null>(null)
    .reset(resetClients);

// Page
export const $page = domain.createStore<number>(1).reset(resetPage);

// Initialization flag for hydration finished
export const $isQueryHydrationFinished = domain.createStore<boolean>(false).reset(resetIsQueryHydrationFinished);

// Platform client user filter data
export const $platformClientUserFilterData = domain
    .createStore<PlatformClientUserFilterData>(initialPlatformClientUserFilterData)
    .reset(resetPlatformClientUserFilterData);
