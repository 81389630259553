import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100%;
`;

export const ImageWrapper = styled.div<{ $cursor?: string }>`
    position: relative;

    height: 100%
    cursor: ${({ $cursor = 'crosshair' }) => $cursor};
`;
