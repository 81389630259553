import { Colors, Tag, TagProps } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { taskStateInformation } from '@data/consts';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import React from 'react';

export interface TaskStateTagProps {
    withCircle?: boolean;
    state: TaskState;
}

export type Props = TagProps & TaskStateTagProps;

const TaskStateTag: React.FC<Props> = ({ withCircle = true, state, ...props }) => {
    const renderCircle = () => {
        if (!withCircle) {
            return null;
        }

        return <Circle className="mr-1" color={Colors.WHITE} size="14px" />;
    };

    return (
        <Tag large style={{ background: taskStateInformation[state].color, color: Colors.WHITE }} {...props}>
            <Flex align="center" justify="flex-start">
                {renderCircle()}

                <DevText inline>{taskStateInformation[state].title}</DevText>
            </Flex>
        </Tag>
    );
};

export default TaskStateTag;
