import React, { HTMLAttributes, useState } from 'react';
import moment from 'moment';
import Flex from '@components/Flex';
import ApplySubscriptionTrialPeriodForm from '../ApplySubscriptionTrialPeriodForm';
import { Button, Card, Colors, Elevation, Icon, Popover } from '@blueprintjs/core';
import DevText from '@components/Text';

export interface ApplySubscriptionTrialPeriodProps {
    trialEndAt: string | null;
    onChangeTrialEndAt: (newTrialEndAt: string | null) => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & ApplySubscriptionTrialPeriodProps;

const ApplySubscriptionTrialPeriod: React.FC<Props> = ({ trialEndAt, onChangeTrialEndAt, ...props }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const handleOpenTrialDaysPopover = () => {
        if (trialEndAt !== null) {
            return;
        }

        setIsPopoverOpen(true);
    };

    const handleCloseTrialDaysPopover = () => {
        setIsPopoverOpen(false);
    };

    const renderApplyTrialPeriodDaysForm = () => {
        return (
            <Card>
                <ApplySubscriptionTrialPeriodForm
                    onSuccess={(subscriptionTrialPeriod) => {
                        onChangeTrialEndAt(subscriptionTrialPeriod);
                        handleCloseTrialDaysPopover();
                    }}
                />
            </Card>
        );
    };

    const isCardInteractive = trialEndAt === null;

    const renderTrialPeriodEndAtInformation = () => {
        if (!trialEndAt) {
            return (
                <Flex align="center">
                    <Icon icon="time" className="mr-1" color={Colors.GRAY2} />
                    <DevText muted>Add trial period</DevText>
                </Flex>
            );
        }

        return (
            <Flex align="center">
                <Icon icon="time" className="mr-1" />
                <DevText>On trial until: {moment(trialEndAt).format('D MMM, YYYY')}</DevText>
            </Flex>
        );
    };

    const renderRemoveTrialDaysButton = () => {
        if (trialEndAt === null) {
            return null;
        }

        const handleRemoveTrialDays = () => {
            if (trialEndAt === null) {
                return;
            }

            onChangeTrialEndAt(null);
        };

        return <Button minimal icon="cross" onClick={handleRemoveTrialDays} />;
    };

    return (
        <Popover
            fill
            usePortal={false}
            isOpen={isPopoverOpen}
            content={renderApplyTrialPeriodDaysForm()}
            onClose={handleCloseTrialDaysPopover}
            {...props}
        >
            <Card compact interactive={isCardInteractive}  className="mb-1" elevation={Elevation.ONE} onClick={handleOpenTrialDaysPopover}>
                <Flex justify="space-between" align="center">
                    {renderTrialPeriodEndAtInformation()}
                    {renderRemoveTrialDaysButton()}
                </Flex>
            </Card>
        </Popover>
    );
};

export default ApplySubscriptionTrialPeriod;
