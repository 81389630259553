import ColorTilePickerFormField from '@app/components/ColorTilePickerFormField';
import { useColorPickerFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { UpdateTaskMessageTemplateCategoryInput } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/inputs';
import { TaskMessageTemplateCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/refs';
import React from 'react';
import TaskMessageTemplateVariablesTable from '../../components/TaskMesageTemplateVariablesTable';
import { taskMessageTemplateCategoriesApi } from '../../store/apis';
import { updateTaskMessageTemplateCategory } from '../../store/effects';

export interface UpdateTaskMessageTemplateCategoryModalProps {
    taskMessageTemplateCategoryId: ID;
    title: string;
    color?: string;
}

type Props = ModalProps<UpdateTaskMessageTemplateCategoryModalProps>;

const taskMessageTemplateCategoryTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const taskMessageTemplateCategoryColorValidators = [getStringRequiredValidator()];

const UpdateTaskMessageTemplateCategoryModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskMessageTemplateCategoryTitle = useTextFormField({
        id: 'task-message-template-category-title',
        validators: taskMessageTemplateCategoryTitleValidators,
        initialValue: data?.title ?? '',
    });

    const taskMessageTemplateCategoryColor = useColorPickerFormField({
        id: 'task-message-template-category-color',
        validators: taskMessageTemplateCategoryColorValidators,
        initialValue: data?.color ?? '',
    });

    const form = useForm<TaskMessageTemplateCategoryRef>({
        fields: [taskMessageTemplateCategoryTitle, taskMessageTemplateCategoryColor],
        apiCall: async () => {
            try {
                const updateTaskMessageTemplateCategoryInput: UpdateTaskMessageTemplateCategoryInput = {
                    title: taskMessageTemplateCategoryTitle.value,
                    color: taskMessageTemplateCategoryColor.value,
                };

                const taskMessageTemplateCategoryRef = await updateTaskMessageTemplateCategory({
                    id: data!.taskMessageTemplateCategoryId,
                    input: updateTaskMessageTemplateCategoryInput,
                });
                taskMessageTemplateCategoriesApi.update({
                    taskMessageTemplateCategoryId: taskMessageTemplateCategoryRef.id,
                    input: updateTaskMessageTemplateCategoryInput,
                });

                return { response: taskMessageTemplateCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update message template category</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        <Grid lg={3} xs={12}>
                            <ColorTilePickerFormField
                                field={taskMessageTemplateCategoryColor}
                                formGroupProps={{
                                    label: 'Color',
                                }}
                            />
                        </Grid>

                        <Grid lg={9}>
                            <InputFormField
                                field={taskMessageTemplateCategoryTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Add
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskMessageTemplateCategoryModal;
