import { CardType } from '../enums';
import alipayImage from '../../assets/icons/cardBrands/alipay.svg';
import amexImage from '../../assets/icons/cardBrands/amex.svg';
import dinersImage from '../../assets/icons/cardBrands/diners.svg';
import discoverImage from '../../assets/icons/cardBrands/discover.svg';
import eloImage from '../../assets/icons/cardBrands/elo.svg';
import genericImage from '../../assets/icons/cardBrands/generic.svg';
import hiperImage from '../../assets/icons/cardBrands/hiper.svg';
import jcbImage from '../../assets/icons/cardBrands/jcb.svg';
import maestroImage from '../../assets/icons/cardBrands/maestro.svg';
import mastercardImage from '../../assets/icons/cardBrands/mastercard.svg';
import mirImage from '../../assets/icons/cardBrands/mir.svg';
import paypalImage from '../../assets/icons/cardBrands/paypal.svg';
import unionpayImage from '../../assets/icons/cardBrands/unionpay.svg';
import visaImage from '../../assets/icons/cardBrands/visa.svg';

const cardTypeIcons: { [cardType in CardType]: any } = {
    [CardType.ALIPAY]: alipayImage,
    [CardType.AMERICAN_EXPRESS]: amexImage,
    [CardType.DINERS]: dinersImage,
    [CardType.DISCOVER]: discoverImage,
    [CardType.ELO]: eloImage,
    [CardType.UNKNOWN]: genericImage,
    [CardType.HIPER]: hiperImage,
    [CardType.JCB]: jcbImage,
    [CardType.MAESTRO]: maestroImage,
    [CardType.MASTERCARD]: mastercardImage,
    [CardType.MIR]: mirImage,
    [CardType.PAYPAL]: paypalImage,
    [CardType.UNIONPAY]: unionpayImage,
    [CardType.VISA]: visaImage,
};

export default cardTypeIcons;
