import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    PickableTaskParticipantUserResource,
    TaskResource,
    TaskStateTransitionResource,
} from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import {
    resetClientPaymentAccount,
    resetTask,
    resetTaskAddons,
    resetTaskComplexity,
    resetTaskPickableParticipantUsers,
    resetTaskStateTransitionLog,
    resetTeams,
} from './events';
import domain from './domain';
import { ResourceAuthContextContainer } from 'dy-frontend-http-repository/lib/data/types';
import { TaskComplexityResource } from 'dy-frontend-http-repository/lib/modules/TaskComplexity/resources';
import { AddonTaskResource } from 'dy-frontend-http-repository/lib/modules/AddonTask/resources';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';

// Task
export const $task = domain.createStore<(TaskResource & ResourceAuthContextContainer) | null>(null).reset(resetTask);

// Task state transition log
export const $taskStateTransitionLog = domain
    .createStore<CollectionResource<TaskStateTransitionResource, {}> | null>(null)
    .reset(resetTaskStateTransitionLog);

// Teams
export const $teams = domain.createStore<CollectionResource<TeamResource, {}> | null>(null).reset(resetTeams);

// Task pickable user
export const $taskPickableParticipantUsers = domain
    .createStore<CollectionResource<PickableTaskParticipantUserResource, {}> | null>(null)
    .reset(resetTaskPickableParticipantUsers);

// Task complexity
export const $taskComplexity = domain
    .createStore<CollectionResource<TaskComplexityResource, {}> | null>(null)
    .reset(resetTaskComplexity);

// Task addons
export const $taskAddons = domain
    .createStore<CollectionResource<AddonTaskResource, {}> | null>(null)
    .reset(resetTaskAddons);

// Client payment account
export const $clientPaymentAccount = domain
    .createStore<PaymentAccountResource | null>(null)
    .reset(resetClientPaymentAccount);
