import domain from './domain';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/ClientSignup/refs';
import { ShutdownUserInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';

// Shutdown user
export const shutdownUser = domain.createEffect<{ id: ID; input: ShutdownUserInput }, UserRef>({
    name: 'shutdownUser',
    handler: async ({ id, input }) => {
        return await repository.user().shutdown(id, input);
    },
});
