import React from 'react';
import { Overlay2, Overlay2Props,  } from '@blueprintjs/core';
import { Content, ContentPositioner } from './styled';

export interface OverlayProps {
    noPadding?: boolean;
    height?: string;
}

export type Props = OverlayProps & Overlay2Props;

const Overlay: React.FC<Props> = ({ noPadding = false, children, onClose, height, ...props }) => {
    return (
        <Overlay2 onClose={onClose}  {...props}>
            <Content id="overlay-content" className="custom-thin-scroll" onClick={onClose}>
                <ContentPositioner
                    id="overlay-content-positioner"
                    $height={height}
                    $noPadding={noPadding}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </ContentPositioner>
            </Content>
        </Overlay2>
    );
};

export default Overlay;
