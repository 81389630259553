import Select from '@app/components/Select';
import { useCustomMultiSelectFormField, useCustomSelectFormField, useForm } from '@app/hooks';
import { ReturnValue } from '@app/hooks/validation/useCustomSelectFormField';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { popularFonts } from '@data/consts';
import { ModalProps } from '@modals/types';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import { BrandProfileFontPrefix } from 'dy-frontend-shared/lib/data/enums';
import React from 'react';
import { brandProfileApi } from '../../store/apis';

export interface UpdateBrandProfileFontsModalProps {
    brandProfileId: ID;
    fonts: string[];
}

type Props = ModalProps<UpdateBrandProfileFontsModalProps>;

const getSelectedItemIndex = (item: string, items: string[]) => {
    return items.findIndex((i) => i === item);
};

const UpdateBrandProfileFontsModal: React.FC<Props> = ({ closeModal, data }) => {
    const primaryFont = useCustomSelectFormField<string>({
        id: 'brand-profile-primary-font',
        validators: [],
        initialValue:
            data?.fonts && data.fonts.length > 0
                ? data.fonts
                      .find((font) => font.startsWith(BrandProfileFontPrefix.PRIMARY))
                      ?.split(BrandProfileFontPrefix.PRIMARY)[1] ?? ''
                : '',
        formatValue: (value) => value,
    });

    const secondaryFont = useCustomSelectFormField<string>({
        id: 'brand-profile-secondary-font',
        validators: [],
        initialValue:
            data?.fonts && data.fonts.length > 0
                ? data.fonts
                      .find((font) => font.startsWith(BrandProfileFontPrefix.SECONDARY))
                      ?.split(BrandProfileFontPrefix.SECONDARY)[1] ??
                  '' ??
                  ''
                : '',
        formatValue: (value) => value,
    });

    const additionalFonts = useCustomMultiSelectFormField<string>({
        id: 'brand-profile-additional-fonts',
        validators: [],
        initialValue:
            data?.fonts && data.fonts.length > 0
                ? data.fonts
                      .filter((font) => font.startsWith(BrandProfileFontPrefix.ADDITIONAL))
                      .map((font) => font.split(BrandProfileFontPrefix.ADDITIONAL)[1])
                : [],
        getSelectedItemIndex,
    });

    const form = useForm<BrandProfileRef>({
        fields: [],
        apiCall: async () => {
            try {
                // Get fonts
                const fonts: string[] = [];
                if (primaryFont.value.trim().length > 0) {
                    fonts.push(`${BrandProfileFontPrefix.PRIMARY}${primaryFont.value}`);
                }

                if (secondaryFont.value.trim().length > 0) {
                    fonts.push(`${BrandProfileFontPrefix.SECONDARY}${secondaryFont.value}`);
                }

                if (additionalFonts.value.length > 0) {
                    additionalFonts.value.forEach(font => fonts.push(`${BrandProfileFontPrefix.ADDITIONAL}${font}`));
                }

                // Update brand profile fonts
                const brandProfileRef = await updateBrandProfileInformation({
                    id: data!.brandProfileId,
                    input: {
                        fonts,
                    },
                });

                // Update brand profile fonts state
                brandProfileApi.update({ fonts });

                return { response: brandProfileRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderFontSelect = (font: ReturnValue<string>, label: string, placeholder: string) => {
        const filterItemOption = (query: string, item: string) => {
            return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            const isActive = item === font.value;

            return <MenuItem active={isActive} key={item} text={item} onClick={handleClick} />;
        };

        const renderCreateItemOption = (
            query: string,
            active: boolean,
            handleClick: React.MouseEventHandler<HTMLElement>
        ) => {
            return (
                <MenuItem
                    shouldDismissPopover={false}
                    active={font.value === query}
                    icon="tick"
                    text={query}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: string) => {
            font.handleChange(item);
        };

        return (
            <FormGroup label={label} intent={!!font.error ? Intent.DANGER : Intent.NONE} helperText={font.error}>
                <Select<string>
                    fill
                    filterable
                    activeItem={font.value}
                    items={popularFonts}
                    createNewItemFromQuery={(query) => query}
                    inputProps={{
                        placeholder: placeholder,
                    }}
                    selectButtonProps={{
                        fill: true,
                        text: font.value ? font.value : placeholder,
                        placeholder: placeholder,
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    itemPredicate={filterItemOption}
                    createNewItemRenderer={renderCreateItemOption}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    const renderAdditionalFontsMultiSelect = () => {
        const handleItemSelect = (item: string) => {
            const selectedItemIndex = getSelectedItemIndex(item, additionalFonts.value);

            if (selectedItemIndex === -1) {
                additionalFonts.handleSetValue([...additionalFonts.value, item]);

                // update popularFonts array
                // check if item already exist in popularFonts array
                if (popularFonts.indexOf(item) === -1) {
                    // add item which do not exist in popularFonts
                    additionalFonts.handleSetValue([...additionalFonts.value, item]);
                }
            } else {
                additionalFonts.handleUnselectItem(item);
            }
        };

        const handleCreateNewItem = (query: string) => {
            const items = query.split(',');
            return items.map((item) => item.trim());
        };

        const renderTag = (item: string) => {
            return item;
        };

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            return (
                <MenuItem
                    key={item}
                    text={item}
                    active={getSelectedItemIndex(item, additionalFonts.value) !== -1}
                    onClick={handleClick}
                />
            );
        };

        const renderNewItem = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
            return <MenuItem icon="add" text={query} active={active} onClick={handleClick} />;
        };

        const singleItemFilter: ItemPredicate<string> = (query, item, _index, exactMatch) => {
            const normalizedTitle = item.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedTitle === normalizedQuery;
            } else {
                // check if "normalizedQuery" is substring of "normalizedTitle" string, if not, then -1 will be returned
                return normalizedTitle.indexOf(normalizedQuery) >= 0;
            }
        };

        return (
            <FormGroup label="Additional fonts">
                <MultiSelect<string>
                    resetOnSelect
                    placeholder="Select or add new additional font"
                    items={popularFonts}
                    selectedItems={additionalFonts.value}
                    createNewItemFromQuery={handleCreateNewItem}
                    createNewItemRenderer={renderNewItem}
                    tagRenderer={renderTag}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={singleItemFilter}
                    onRemove={additionalFonts.handleUnselectItem}
                    tagInputProps={{
                        large: false,
                    }}
                    menuProps={{
                        className: 'custom-thin-scroll',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                    }}
                />
            </FormGroup>
        );

    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update Fonts</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {renderFontSelect(primaryFont, 'Primary font', 'Add primary font')}
                    {renderFontSelect(secondaryFont, 'Secondary font', 'Add secondary font')}
                    {renderAdditionalFontsMultiSelect()}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateBrandProfileFontsModal;
