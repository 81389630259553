import { GetValidatorFunction } from '@app/hooks/validation/types';
import { FileUtils } from 'dy-frontend-shared/lib/utils';

const getDefaultFileTypeNotAllowedMessage = (allowedTypes: string[], file: File) =>
    `Allowed types are: "${allowedTypes.join(', ')}", but file "${file.name}" is of type "${FileUtils.getFileExtension(
        file.name
    )}"`;
const getFileMaxSizeValidator: GetValidatorFunction<
    {
        allowedTypes: string[];
        fileTypeNotAllowedMessageFormatter?: (allowedTypes: string[], file: File) => string;
    },
    File
> = (
    options = {
        allowedTypes: [],
        fileTypeNotAllowedMessageFormatter: getDefaultFileTypeNotAllowedMessage,
    }
) => {
    const { allowedTypes, fileTypeNotAllowedMessageFormatter } = options;

    if (allowedTypes.length === 0) {
        throw new Error('File allowed types were not specified');
    }

    return async (file) => {
        if (allowedTypes.includes(FileUtils.getFileExtension(file.name))) {
            return null;
        } else {
            if (fileTypeNotAllowedMessageFormatter) {
                return fileTypeNotAllowedMessageFormatter(allowedTypes, file);
            } else {
                return getDefaultFileTypeNotAllowedMessage(allowedTypes, file);
            }
        }
    };
};

export default getFileMaxSizeValidator;
