import { AuthSchemaResource } from 'dy-frontend-http-repository/lib/modules/Auth/resources';
import { AuthenticationFormStep } from '../enums';
import domain from './domain';
import { resetAuthenticationFormStep, resetEmailSchema } from './events';

// Authentication form step
export const $authenticationFormStep = domain
    .createStore<AuthenticationFormStep>(AuthenticationFormStep.EMAIL_CHECK)
    .reset(resetAuthenticationFormStep);

// Email schema
export const $emailSchema = domain.createStore<AuthSchemaResource | null>(null).reset(resetEmailSchema);
