import { Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    line-height: 1.7;

    /* ul / ol */
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ol {
        padding-left: 10px;
    }

    & .public-DraftEditor-content {
        color: ${Colors.WHITE};
    }
`;
