import React from 'react';
import Box, { Props as BoxProps } from '@components/Box';
import Flex from '@components/Flex';
import Image, { ImageProps } from '@components/Image';
import { commonAvatarColors } from './consts';
import DevText from '@components/Text';

export interface AvatarProps {
    alt: string;
    src?: ImageProps['src'];
    objectFit?: ImageProps['objectFit'];
}

export type Props = AvatarProps & BoxProps;

const Avatar: React.FC<Props> = ({
    alt,
    src,
    borderRadius = '50%',
    width = '35px',
    height = '35px',
    objectFit = 'cover',
    minHeight = height,
    minWidth = width,
    ...props
}) => {
    if (src && src.trim().length > 0) {
        // Avatar image exist -> show image
        return (
            <Box
                borderRadius={borderRadius}
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
                {...props}
            >
                <Image borderRadius={borderRadius} width="100%" height="100%" objectFit={objectFit} src={src} />
            </Box>
        );
    } else {
        // Avatar do NOT exist -> show first letter of alt
        const altFirstLetter = alt[0];
        return (
            <Box
                borderRadius={borderRadius}
                width={width}
                height={height}
                minWidth={minWidth}
                minHeight={minHeight}
                backgroundColor={commonAvatarColors[alt.length % commonAvatarColors.length]}
                {...props}
            >
                <Flex fullHeight fullWidth align="center" justify="center">
                    <DevText inline>{altFirstLetter}</DevText>
                </Flex>
            </Box>
        );
    }
};

export default Avatar;
