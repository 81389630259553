import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { $permissions } from '@app/containers/store/states';
import { usePageTitle } from '@app/hooks';
import { Button, Card, Classes, Icon, Intent, Spinner } from '@blueprintjs/core';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import { openModal } from '@modals/store/events';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import UpsertTaskCategoryGroupModal, { UpsertTaskCategoryGroupModalProps } from './modals/UpsertTaskCategoryGroupModal';
import { taskCategoryGroupsApi } from './store/apis';
import { fetchTaskCategoryGroups, removeTaskCategoryGroup, restoreTaskCategoryGroup } from './store/effects';
import { resetTaskCategoryGroups } from './store/events';
import { $taskCategoryGroups } from './store/states';
import { TaskCategoryGroupPermission } from 'dy-frontend-permissions/lib/permission';
import TaskCategoryGroupTag from '@app/containers/components/TaskCategoryGroupTag';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { useNavigate } from 'react-router-dom';
import { ToastUtils } from '@app/data/utils';
import { Endpoints } from '@app/data/consts';

const TaskCategoryGroups: React.FC = () => {
    const navigate = useNavigate();

    const permissions = useStore($permissions);

    if (!permissions.isEnabled.taskCategoryGroup) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Request Category Groups');

    const taskCategoryGroups = useStore($taskCategoryGroups);

    const [removingTaskCategoryGroupId, setRemovingTaskCategoryGroupId] = useState<ID | null>(null);
    const [restoringTaskCategoryGroupId, setRestoringTaskCategoryGroupId] = useState<ID | null>(null);

    useEffect(() => {
        fetchTaskCategoryGroups().catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: 'Request category groups were not found',
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        });
    }, []);

    useEffect(() => {
        return () => {
            resetTaskCategoryGroups();
        };
    }, []);

    const renderTaskCategoryGroupList = () => {
        if (!taskCategoryGroups) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (taskCategoryGroups.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No task category groups were found
                        </Heading>
                    }
                />
            );
        }

        const renderTaskCategoryGroupListItem = (taskCategoryGroup: TaskCategoryGroupListItemResource) => {
            const renderUpdateTaskCategoryGroupButton = () => {
                const isAllowed =
                    permissions.isRoot.taskCategoryGroup || permissions.has(TaskCategoryGroupPermission.UPDATE);
                if (!isAllowed) {
                    return null;
                }

                return (
                    <Button
                        icon="edit"
                        onClick={() =>
                            openModal<UpsertTaskCategoryGroupModalProps>({
                                ModalComponent: UpsertTaskCategoryGroupModal,
                                data: {
                                    taskCategoryGroupId: taskCategoryGroup.id,
                                    title: taskCategoryGroup.title,
                                    color: taskCategoryGroup.color,
                                },
                            })
                        }
                    >
                        Update
                    </Button>
                );
            };

            const renderRemoveTaskCategoryGroupButton = () => {
                if (taskCategoryGroup.archived_at) {
                    // Task category is already archived
                    return null;
                }

                const isAllowed =
                    permissions.isRoot.taskCategoryGroup || permissions.has(TaskCategoryGroupPermission.ARCHIVE);
                if (!isAllowed) {
                    return null;
                }

                const handleRemoveTaskCategoryGroup = async () => {
                    setRemovingTaskCategoryGroupId(taskCategoryGroup.id);

                    try {
                        await removeTaskCategoryGroup(taskCategoryGroup.id);
                        taskCategoryGroupsApi.archive({ taskCategoryGroupId: taskCategoryGroup.id });
                    } catch (e) {
                        // TODO: handle error
                        console.error(e);
                    } finally {
                        setRemovingTaskCategoryGroupId(null);
                    }
                };

                const isRemovingTaskCategoryGroup = removingTaskCategoryGroupId === taskCategoryGroup.id;

                return (
                    <ConfirmationPopover
                        title="Are you sure want to remove request category group?"
                        description="When confirmed, request category group will be removed"
                        actions={[
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS}
                                onClick={handleRemoveTaskCategoryGroup}
                            >
                                Yes, remove group
                            </Button>,
                        ]}
                    >
                        <Button
                            outlined
                            loading={isRemovingTaskCategoryGroup}
                            disabled={isRemovingTaskCategoryGroup}
                            icon="trash"
                            className="ml-1"
                            intent={Intent.DANGER}
                        />
                    </ConfirmationPopover>
                );
            };

            const renderRestoreTaskCategoryGroupButton = () => {
                if (!taskCategoryGroup.archived_at) {
                    // Task category group is NOT archived
                    return null;
                }

                const isAllowed =
                    permissions.isRoot.taskCategoryGroup || permissions.has(TaskCategoryGroupPermission.RESTORE);
                if (!isAllowed) {
                    return null;
                }

                const handleRestoreTaskCategoryGroup = async () => {
                    setRestoringTaskCategoryGroupId(taskCategoryGroup.id);

                    try {
                        await restoreTaskCategoryGroup(taskCategoryGroup.id);
                        taskCategoryGroupsApi.restore({ taskCategoryGroupId: taskCategoryGroup.id });
                    } catch (e) {
                        // TODO: handle error
                        console.error(e);
                    } finally {
                        setRestoringTaskCategoryGroupId(null);
                    }
                };

                const isRestoringTaskCategoryGroup = restoringTaskCategoryGroupId === taskCategoryGroup.id;

                return (
                    <ConfirmationPopover
                        title="Are you sure want to restore request category group?"
                        description="When confirmed, request category group will be restored"
                        actions={[
                            <Button
                                intent={Intent.SUCCESS}
                                className={Classes.POPOVER_DISMISS}
                                onClick={handleRestoreTaskCategoryGroup}
                            >
                                Yes, restore group
                            </Button>,
                        ]}
                    >
                        <Button
                            outlined
                            loading={isRestoringTaskCategoryGroup}
                            disabled={isRestoringTaskCategoryGroup}
                            className="ml-1"
                            icon="confirm"
                            intent={Intent.SUCCESS}
                        >
                            Restore
                        </Button>
                    </ConfirmationPopover>
                );
            };

            return (
                <Card className="mb-2" key={taskCategoryGroup.id}>
                    <Flex justify="space-between" align="center">
                        <TaskCategoryGroupTag name={taskCategoryGroup.title} color={taskCategoryGroup.color} />

                        <Flex align="center">
                            {renderUpdateTaskCategoryGroupButton()}
                            {renderRemoveTaskCategoryGroupButton()}
                            {renderRestoreTaskCategoryGroupButton()}
                        </Flex>
                    </Flex>
                </Card>
            );
        };

        return taskCategoryGroups.items.map(renderTaskCategoryGroupListItem);
    };

    const renderCreateTaskCategoryGroupButton = () => {
        const isAllowed = permissions.isRoot.taskCategoryGroup || permissions.has(TaskCategoryGroupPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="new-object"
                onClick={() =>
                    openModal<UpsertTaskCategoryGroupModalProps>({
                        ModalComponent: UpsertTaskCategoryGroupModal,
                    })
                }
            >
                Create category group
            </Button>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Request category groups</Heading>
                {renderCreateTaskCategoryGroupButton()}
            </Flex>

            {renderTaskCategoryGroupList()}
        </FixedWidthPageContainer>
    );
};

export default TaskCategoryGroups;
