import moment from 'moment';
import { createApi } from 'effector';
import {
    CreateTaskMessageTemplateCategoryInput,
    UpdateTaskMessageTemplateCategoryInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/inputs';
import { TaskMessageTemplateCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import {
    CreateTaskMessageTemplateInput,
    UpdateTaskMessageTemplateInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/inputs';
import { TaskMessageTemplateListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import { $taskMessageTemplateCategories, $taskMessageTemplates } from './states';

export const taskMessageTemplateCategoriesApi = createApi($taskMessageTemplateCategories, {
    add: (store, payload: { taskMessageTemplateCategoryId: ID; input: CreateTaskMessageTemplateCategoryInput }) => {
        if (!store) {
            // Task message template categories were NOT fetched yet
            return store;
        }

        const momentNowUtc = moment().utc().format();
        const taskMessageTemplateCategory: TaskMessageTemplateCategoryListItemResource = {
            id: payload.taskMessageTemplateCategoryId,
            title: payload.input.title,
            color: payload.input.color ?? '#fff', // TODO: make color NOT optional
            archived_at: null,
            created_at: momentNowUtc,
        };

        return {
            ...store,
            items: [taskMessageTemplateCategory, ...store.items],
        };
    },

    update: (store, payload: { taskMessageTemplateCategoryId: ID; input: UpdateTaskMessageTemplateCategoryInput }) => {
        if (!store) {
            // Task message template categories were NOT fetched yet
            return store;
        }

        // Copy task message template category items
        const taskMessageCategoryItems = [...store.items];

        // Find task message template category index in array
        const foundTaskMessageCategoryItemIndex = taskMessageCategoryItems.findIndex(
            (taskMessageCategoryItem) => taskMessageCategoryItem.id === payload.taskMessageTemplateCategoryId
        );

        if (foundTaskMessageCategoryItemIndex === -1) {
            return store;
        }

        // If task message template category index found, get task message template category at index
        const foundTaskMessageCategory = taskMessageCategoryItems[foundTaskMessageCategoryItemIndex];

        // Create updated drafted task
        const updatedTaskMessageCategoryItem: TaskMessageTemplateCategoryListItemResource = {
            ...foundTaskMessageCategory,
            ...payload.input,
        };

        // Replace task message template category with updated one at found index
        taskMessageCategoryItems[foundTaskMessageCategoryItemIndex] = updatedTaskMessageCategoryItem;

        return {
            ...store,
            items: taskMessageCategoryItems,
        };
    },

    remove: (store, payload: { taskMessageTemplateCategoryId: ID }) => {
        if (!store) {
            // Task message template categories were NOT fetched yet
            return store;
        }

        // Copy task message template category items
        const taskMessageCategoryItems = [...store.items];

        // Find task message template category index in array
        const foundTaskMessageCategoryItemIndex = taskMessageCategoryItems.findIndex(
            (taskMessageCategoryItem) => taskMessageCategoryItem.id === payload.taskMessageTemplateCategoryId
        );

        if (foundTaskMessageCategoryItemIndex === -1) {
            return store;
        }

        // Get current date in UTC
        const momentNowUtc = moment().utc().format();

        // Update task message category archived at date
        taskMessageCategoryItems[foundTaskMessageCategoryItemIndex].archived_at = momentNowUtc;

        return {
            ...store,
            items: taskMessageCategoryItems,
        };
    },

    restore: (store, payload: { taskMessageTemplateCategoryId: ID }) => {
        if (!store) {
            // Task message template categories were NOT fetched yet
            return store;
        }

        // Copy task message template category items
        const taskMessageCategoryItems = [...store.items];

        // Find task message template category index in array
        const foundTaskMessageCategoryItemIndex = taskMessageCategoryItems.findIndex(
            (taskMessageCategoryItem) => taskMessageCategoryItem.id === payload.taskMessageTemplateCategoryId
        );

        if (foundTaskMessageCategoryItemIndex === -1) {
            return store;
        }

        // Update task message category archived at date
        taskMessageCategoryItems[foundTaskMessageCategoryItemIndex].archived_at = null;

        return {
            ...store,
            items: taskMessageCategoryItems,
        };
    },
});

export const taskMessageTemplatesApi = createApi($taskMessageTemplates, {
    add: (
        store,
        payload: {
            taskMessageTemplateId: ID;
            selectedTaskMessageTemplateCategoryId: ID | null;
            taskMessageCategory: TaskMessageTemplateCategoryListItemResource;
            input: CreateTaskMessageTemplateInput;
        }
    ) => {
        if (!store) {
            // Task message template categories were NOT fetched yet
            return store;
        }

        let taskMessageTemplate: TaskMessageTemplateListItemResource | null = null;

        if (
            !payload.selectedTaskMessageTemplateCategoryId ||
            payload.selectedTaskMessageTemplateCategoryId === payload.taskMessageCategory.id
        ) {
            // All templates are shown OR filter for category exists and category by which filtering is the same as newly created template category
            const momentNowUtc = moment().utc().format();
            taskMessageTemplate = {
                id: payload.taskMessageTemplateId,
                title: payload.input.title,
                category: payload.taskMessageCategory,
                content: payload.input.content ?? '',
                summary: payload.input.summary ?? '',
                archived_at: null,
                created_at: momentNowUtc,
            };
        }

        return {
            ...store,
            ...(store.paginator
                ? {
                      paginator: {
                          ...store.paginator,
                          total: store.paginator.total !== null ? store.paginator.total + 1 : null,
                      },
                  }
                : {}),
            items: taskMessageTemplate ? [taskMessageTemplate, ...store.items] : [...store.items],
        };
    },

    update: (
        store,
        payload: {
            taskMessageTemplateId: ID;
            taskMessageCategory: TaskMessageTemplateCategoryListItemResource;
            input: UpdateTaskMessageTemplateInput;
        }
    ) => {
        if (!store) {
            // Task message templates were NOT fetched yet
            return store;
        }

        // Copy task message template items
        const taskMessageItems = [...store.items];

        // Find task message template index in array
        const foundTaskMessageItemIndex = taskMessageItems.findIndex(
            (taskMessageItem) => taskMessageItem.id === payload.taskMessageTemplateId
        );

        if (foundTaskMessageItemIndex === -1) {
            return store;
        }

        // If task message template index found, get task message template at index
        const foundTaskMessage = taskMessageItems[foundTaskMessageItemIndex];

        // Create updated drafted task
        const updatedTaskMessageItem: TaskMessageTemplateListItemResource = {
            ...foundTaskMessage,
            id: payload.taskMessageTemplateId,
            title: payload.input.title,
            category: payload.taskMessageCategory,
            content: payload.input.content ?? '',
            summary: payload.input.summary ?? '',
        };

        // Replace task message template with updated one at found index
        taskMessageItems[foundTaskMessageItemIndex] = updatedTaskMessageItem;

        return {
            ...store,
            items: taskMessageItems,
        };
    },

    remove: (store, payload: { taskMessageTemplateId: ID }) => {
        if (!store) {
            // Task message templates were NOT fetched yet
            return store;
        }

        // Copy task message template  items
        const taskMessageItems = [...store.items];

        // Find task message template  index in array
        const foundTaskMessageItemIndex = taskMessageItems.findIndex(
            (taskMessageItem) => taskMessageItem.id === payload.taskMessageTemplateId
        );

        if (foundTaskMessageItemIndex === -1) {
            return store;
        }

        // Get current date in UTC
        const momentNowUtc = moment().utc().format();

        // Update task message  archived at date
        taskMessageItems[foundTaskMessageItemIndex].archived_at = momentNowUtc;

        // TODO: here need to think what to do, because after removal, paginator total will decreased only if archived are not shown, if they are shown, then total will not be decreasing

        return {
            ...store,
            items: taskMessageItems,
        };
    },

    restore: (store, payload) => {
        if (!store) {
            // Task message templates were NOT fetched yet
            return store;
        }

        // Copy task message template  items
        const taskMessageItems = [...store.items];

        // Find task message template  index in array
        const foundTaskMessageItemIndex = taskMessageItems.findIndex(
            (taskMessageItem) => taskMessageItem.id === payload.taskMessageTemplateId
        );

        if (foundTaskMessageItemIndex === -1) {
            return store;
        }

        // Update task message  archived at date
        taskMessageItems[foundTaskMessageItemIndex].archived_at = null;

        // TODO: here need to think what to do, because after restoring paginator, total will increase only if archived are not shown, if they are shown, then total will not increase, also if we can restore task message template it means that archived once are always shown, need to ask backend developer

        return {
            ...store,
            items: taskMessageItems,
        };
    },
});
