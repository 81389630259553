import React, { HTMLAttributes } from 'react';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import Flex from '@components/Flex';
import { Card, Icon } from '@blueprintjs/core';
import DevText from '@components/Text';
import CardPreview from '@app/containers/components/CardPreview';

export interface PaymentMethodListItemProps {
    paymentMethod: PaymentMethodResource;
}

export type Props = PaymentMethodListItemProps & HTMLAttributes<HTMLDivElement>;

const PaymentMethodListItem: React.FC<Props> = ({ paymentMethod, ...props }) => {
    const renderExpirationMonth = () => {
        const expirationMonth: string | number = `${paymentMethod.card_expiration_month}`.padStart(2, '0');

        return (
            <DevText muted>
                {expirationMonth}/{paymentMethod.card_expiration_year}
            </DevText>
        );
    };

    return (
        <Card compact {...props}>
            <Flex direction="row" align="center">
                <CardPreview className="mr-1" width="60px" height="45px" type={paymentMethod.card_brand} />

                <div>
                    <DevText className="mb-small">
                        {paymentMethod.card_brand.toUpperCase()} **** {paymentMethod.card_last_four}
                    </DevText>
                    {renderExpirationMonth()}
                </div>
            </Flex>
        </Card>
    );
};

export default PaymentMethodListItem;
