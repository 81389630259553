import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    TaskClientFeedbackSummaryResource,
    TaskClientFeedbackResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
import domain from './domain';
import { resetTaskClientFeedback, resetTaskClientFeedbackSummary } from './events';

// Task client feedback
export const $taskClientFeedback = domain
    .createStore<CollectionResource<TaskClientFeedbackResource, {}> | null>(null)
    .reset(resetTaskClientFeedback);

// Task client feedback summary
export const $taskClientFeedbackSummary = domain
    .createStore<TaskClientFeedbackSummaryResource | null>(null)
    .reset(resetTaskClientFeedbackSummary);
