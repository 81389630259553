import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    ClientUserFilterDataFilterInput,
    CoreUserFilterDataFilterInput,
    HydrateFilterDataFilterInput,
    TeamFilterDataFilterInput,
} from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import {
    ClientUserResource,
    CoreUserResource,
    HydratedFilterResource,
    TeamResource,
} from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import {
    TaskClientFeedbackResource,
    TaskClientFeedbackSummaryResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';

// Get task client feedback summary
export const fetchTaskClientFeedback = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: TaskClientFeedbackFilterInput },
    CollectionResource<TaskClientFeedbackResource, {}>
>({
    name: 'fetchTaskClientFeedback',
    handler: async ({ pagination, filter }) => {
        return await repository.taskClientFeedback().getList(pagination, filter);
    },
});

// Get task client feedback list
export const fetchTaskClientFeedbackSummary = domain.createEffect<
    { filter: TaskClientFeedbackFilterInput },
    TaskClientFeedbackSummaryResource
>({
    name: 'fetchTaskClientFeedbackSummary',
    handler: async ({ filter }) => {
        return await repository.taskClientFeedback().getSummary(filter);
    },
});

// Hydrate query parameters
export const hydrateQueryParameters = domain.createEffect<HydrateFilterDataFilterInput, HydratedFilterResource>({
    name: 'hydrateQueryParameters',
    handler: async (input) => {
        return await repository.filter().hydrateData(input);
    },
});

// Fetch client user data
export const fetchClientUserData = domain.createEffect<
    ClientUserFilterDataFilterInput,
    CollectionResource<ClientUserResource, {}>
>({
    name: 'fetchClientUserData',
    handler: async (input) => {
        return await repository.filter().getClientUserData(input);
    },
});

// Fetch core user data
export const fetchCoreUserData = domain.createEffect<
    CoreUserFilterDataFilterInput,
    CollectionResource<CoreUserResource, {}>
>({
    name: 'fetchCoreUserData',
    handler: async (input) => {
        return await repository.filter().getCoreUserData(input);
    },
});

// Fetch team data
export const fetchTeamData = domain.createEffect<TeamFilterDataFilterInput, CollectionResource<TeamResource, {}>>({
    name: 'fetchTeamData',
    handler: async (input) => {
        return await repository.filter().getTeamData(input);
    },
});
