import moment from 'moment';
import { createApi } from 'effector';
import { BrandProfileResource } from 'dy-frontend-http-repository/lib/modules/BrandProfile/resources';
import { $brandProfile, $brandProfileAssets } from './states';
import { BrandProfileAssetListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import { BrandProfileImageHashRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';

// API to manage brand profile
export const brandProfileApi = createApi($brandProfile, {
    update: (store, payload: Partial<BrandProfileResource>) => {
        if (!store) {
            // Brand profile was NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload,
        };
    },

    remove: (store) => {
        if (!store) {
            // Brand profile was NOT fetched yet
            return store;
        }

        return {
            ...store,
            archived_at: moment().utc().format(),
        };
    },

    restore: (store) => {
        if (!store) {
            // Brand profile was NOT fetched yet
            return store;
        }

        return {
            ...store,
            archived_at: null,
        };
    },

    // Set image
    // TODO: move?
    setImageHash: (store, payload: BrandProfileImageHashRef) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: payload.hash,
        };
    },

    // Remove image
    // TODO: move?
    removeImage: (store) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: null,
        };
    },
});

// API to manage brand profile assets state locally
export const brandProfileAssetsApi = createApi($brandProfileAssets, {
    // Add brand profile assets
    addBrandProfileAssets: (store, payload: BrandProfileAssetListItemResource[]) => {
        // Skip if brand profile assets were NOT fetched yet
        if (!store) {
            return store;
        }

        return {
            ...store,
            items: [...payload, ...store.items],
        };
    },
    // Remove brand profile asset
    removeBrandProfileAsset: (store, payload: ID) => {
        // Skip if brand profile was NOT fetched yet
        if (!store) {
            return store;
        }

        const items = [...store.items];

        const itemIndex = items.findIndex((item) => item.id === payload);

        if (itemIndex === -1) {
            return store;
        }

        items.splice(itemIndex, 1);

        return {
            ...store,
            items,
        };
    },
});
