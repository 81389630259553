import { useState, useCallback } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';

export type DefaultMap = { [key in string | number]: any };
export interface UseMapFormFieldReturnValue<MapValue = DefaultMap> {
    handleChange: (map: MapValue) => void;
}

export type ReturnValue<MapValue extends DefaultMap = DefaultMap> = DefaultFormFieldReturnValue<MapValue> &
    UseMapFormFieldReturnValue<MapValue>;
export type Props<MapValue extends DefaultMap = DefaultMap> = DefaultFormFieldProps<MapValue>;
function useMapFormField<MapValue extends DefaultMap = DefaultMap>({
    id,
    initialValue,
    validators,
}: Props<MapValue>): ReturnValue<MapValue> {
    const [map, setMap] = useState(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (map: MapValue) => {
            // Set new value
            setMap(map);

            // Check validity for a new value
            setError(await checkValidators<MapValue>(map, validators));
        },
        [validators]
    );

    const hasError = useCallback(async () => {
        // Check validity for a field
        const err = await checkValidators(map, validators);

        // Set validation result
        setError(err);

        return !!err;
    }, [map, validators]);

    const reset = () => {
        // Reset value
        setMap(initialValue);
    };

    return {
        id,
        value: map,
        error,
        reset,
        hasError,
        handleChange,
    };
}

export default useMapFormField;
