import { PlatformTaskFilterData } from '../types';

const initialPlatformTaskFilterData: PlatformTaskFilterData = {
    query: '',
    clients: [],
    participants: [],
    publishTypes: [],
    queues: [],
    states: [],
    taskCategories: [],
    workspaces: [],
};

export default initialPlatformTaskFilterData;
