import ColorTilePicker from '@app/components/ColorTilePicker';
import {
    useColorPickerFormField,
    useCustomMultiSelectFormField,
    useCustomSelectFormField,
    useForm,
    useHTMLSelectFormField,
    usePageTitle,
    useTextArrayFormField,
    useTextFormField,
} from '@app/hooks';
import {
    getArrayMaxLengthValidator,
    getArrayMinLengthValidator,
    getArrayShouldNotBeEmptyValidator,
    getOptionalStringValidator,
    getStringContainOnlyLettersValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
    getStringWebURLValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, FormGroup, HTMLSelect, InputGroup, Intent, MenuItem, TextArea } from '@blueprintjs/core';
import { ItemPredicate, ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Select from '@components/Select';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { ToastUtils } from '@data/utils';
import React from 'react';

const loremIpsum =
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, quidem ipsa. Ad officia vel facere corporis nemo blanditiis quasi, ex libero at numquam expedita. Debitis amet reprehenderit dolores tempora corporis. Lorem ipsum dolor sit amet consectetur';

// Create fields validation configurations
const titleValidators = [
    getStringRequiredValidator(),
    getStringContainOnlyLettersValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 20 }),
];
const descriptionValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 50 }),
        getStringMaxLengthValidator({ maxStringLength: 100 }),
    ]),
];
const ageIntervalValidators = [getStringRequiredValidator()];
const selectedAddressCountryKeyValidators = [getStringRequiredValidator()];
const selectedFilmsValidators = [
    getArrayShouldNotBeEmptyValidator(),
    getArrayMinLengthValidator({ minArrayLength: 1 }),
    getArrayMaxLengthValidator({ maxArrayLength: 3 }),
];
const socialLinksValidators = [getOptionalStringValidator([getStringWebURLValidator()])];
const colorValidators = [getStringRequiredValidator()];

// Select types
type SelectCountryItem = { key: string; countryName: string };

// MultiSelect types
type MultiSelectFilmItem = { id: number; author: string; title: string };
const films: MultiSelectFilmItem[] = [
    { id: 0, author: 'Gey Jackson', title: 'Assholes in Vegas' },
    { id: 1, author: 'Gimm Jaak', title: 'Chimichangas' },
    { id: 2, author: 'Jarrs Sllkf', title: 'Pie' },
    { id: 3, author: 'Gerold Joe', title: 'Dumplings' },
    { id: 4, author: 'Larry Garry', title: 'Crazy Frog' },
    { id: 5, author: 'Vladimir Chimichanga', title: 'Red dead redemption' },
];

// MultiSelect function
const getSelectedItemIndex = (item: MultiSelectFilmItem, items: MultiSelectFilmItem[]) => {
    return items.findIndex((i) => i.id === item.id);
};

const Playground: React.FC = () => {
    usePageTitle('Playground');

    const title = useTextFormField({ id: 'form-title', validators: titleValidators, initialValue: '' });

    const description = useTextFormField({
        id: 'form-description',
        validators: descriptionValidators,
        initialValue: '',
    });

    const ageInterval = useHTMLSelectFormField({ id: 'form-age', validators: ageIntervalValidators, initialValue: '' });

    const selectedAddressCountryKey = useCustomSelectFormField<string | null>({
        id: 'form-selected-address-country-key',
        validators: selectedAddressCountryKeyValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const selectedFilms = useCustomMultiSelectFormField<MultiSelectFilmItem>({
        id: 'form-selected-films',
        validators: selectedFilmsValidators,
        initialValue: [],
        getSelectedItemIndex,
    });

    const socialLinks = useTextArrayFormField({
        id: 'form-social-links',
        validators: socialLinksValidators,
        initialValue: [''],
    });

    const color = useColorPickerFormField({
        id: 'form-color',
        validators: colorValidators,
        initialValue: '',
    });

    const form = useForm<any[]>({
        fields: [title, description, ageInterval, selectedAddressCountryKey, selectedFilms, socialLinks, color],
        apiCall: () =>
            new Promise((resolve) =>
                resolve({ response: [title, description, ageInterval, selectedAddressCountryKey, selectedFilms] })
            ),
        onSuccess: (response) => console.log(response),
        onFailure: (message) => console.error(message),
    });

    const renderCountrySelect = () => {
        const filterItemOption = (query: string, item: SelectCountryItem) => {
            return item.countryName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: SelectCountryItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.key === selectedAddressCountryKey.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.key}
                    text={item.countryName}
                    label={item.key}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: SelectCountryItem) => {
            selectedAddressCountryKey.handleChange(item.key);
        };

        return (
            <Select<SelectCountryItem>
                filterable
                items={Object.values(countriesData)}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                itemPredicate={filterItemOption}
                inputProps={{
                    placeholder: 'Search for country',
                }}
                popoverProps={{
                    matchTargetWidth: true,
                    usePortal: false,
                    onClose: selectedAddressCountryKey.handleClose,
                }}
                selectButtonProps={{
                    fill: true,
                    rightIcon: 'double-caret-vertical',
                    placeholder: 'Select a country',
                    text: selectedAddressCountryKey.value && countriesData[selectedAddressCountryKey.value].countryName,
                    icon: 'add',
                }}
            />
        );
    };

    const renderFavoriteFilmsMultiSelect = () => {
        const renderTag = (item: MultiSelectFilmItem) => {
            return item.title;
        };

        const renderItem = (item: MultiSelectFilmItem, { handleClick }: ItemRendererProps) => {
            return (
                <MenuItem
                    key={item.id}
                    text={item.title}
                    active={getSelectedItemIndex(item, selectedFilms.value) !== -1}
                    onClick={handleClick}
                />
            );
        };

        const singleItemFilter: ItemPredicate<MultiSelectFilmItem> = (query, item, _index, exactMatch) => {
            const normalizedTitle = item.title.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedTitle === normalizedQuery;
            } else {
                // check if "normalizedQuery" is substring of "normalizedTitle" string, if not, then -1 will be returned
                return normalizedTitle.indexOf(normalizedQuery) >= 0;
            }
        };

        return (
            <MultiSelect<MultiSelectFilmItem>
                resetOnSelect={true}
                placeholder="Select request category groups"
                items={films}
                selectedItems={selectedFilms.value}
                tagRenderer={renderTag}
                tagInputProps={{ large: false }}
                itemRenderer={renderItem}
                itemPredicate={singleItemFilter}
                onItemSelect={selectedFilms.handleChange}
                onRemove={selectedFilms.handleUnselectItem}
                menuProps={{
                    className: 'custom-thin-scroll',
                }}
                popoverProps={{
                    matchTargetWidth: true,
                    onClose: selectedFilms.handleClose,
                }}
            />
        );
    };

    const renderSocialMediaLinksInputs = () => {
        const normalizeReferenceLinks = (referenceLinks: string[]) => {
            const normalizedReferenceLinks = referenceLinks.filter((x) => x.trim().length !== 0);
            normalizedReferenceLinks.push('');
            return normalizedReferenceLinks;
        };

        const updateReferenceLinkAtIndex = (index: number, referenceLink: string) => {
            let newReferenceLinks = [...socialLinks.value];
            newReferenceLinks[index] = referenceLink;
            newReferenceLinks = normalizeReferenceLinks(newReferenceLinks);
            socialLinks.handleChange(newReferenceLinks);
        };

        const renderReferenceLinkInput = (link: string, index: number) => {
            return (
                <FormGroup
                    intent={!!socialLinks.errors[index] ? Intent.DANGER : Intent.NONE}
                    helperText={socialLinks.errors[index]}
                >
                    <InputGroup
                        fill
                        intent={!!socialLinks.errors[index] ? Intent.DANGER : Intent.NONE}
                        className="mb-1"
                        placeholder="Enter social media URL..."
                        value={link}
                        onChange={(e) => updateReferenceLinkAtIndex(index, e.target.value)}
                        onBlur={() => socialLinks.handleBlur(index)}
                    />
                </FormGroup>
            );
        };

        return (
            <FormGroup label="Reference links" helperText="Extra fields will be added as you type">
                {socialLinks.value.map(renderReferenceLinkInput)}
            </FormGroup>
        );
    };

    const handleThrowAnError = () => {
        throw Error('This is test error from Playground page');
    };

    return (
        <FixedWidthPageContainer>
            <div className="mb-4">
                <Button
                    className="mr-1"
                    onClick={() => {
                        ToastUtils.showToast({ message: loremIpsum, intent: Intent.PRIMARY });
                    }}
                >
                    Show primary toaster
                </Button>

                <Button
                    onClick={() => {
                        ToastUtils.showToast({ message: loremIpsum, intent: Intent.DANGER });
                    }}
                >
                    Show danger toaster
                </Button>

                <Button
                    className="mr-1"
                    onClick={() => {
                        handleThrowAnError();
                    }}
                >
                    Throw an error
                </Button>
            </div>

            <Card>
                <form onSubmit={form.handleFormSubmit}>
                    {/* Title */}
                    <FormGroup
                        label="Title"
                        intent={!!title.error ? Intent.DANGER : Intent.NONE}
                        helperText={title.error}
                    >
                        <InputGroup
                            fill
                            intent={!!title.error ? Intent.DANGER : Intent.NONE}
                            placeholder="Enter title..."
                            value={title.value}
                            id={title.id}
                            onChange={title.handleChange}
                            onBlur={title.handleBlur}
                        />
                    </FormGroup>

                    {/* Description */}
                    <FormGroup
                        label="Description"
                        intent={!!description.error ? Intent.DANGER : Intent.NONE}
                        helperText={description.error}
                    >
                        <TextArea
                            fill
                            intent={!!description.error ? Intent.DANGER : Intent.NONE}
                            placeholder="Enter description..."
                            value={description.value}
                            id={description.id}
                            minLength={6}
                            onChange={description.handleChange}
                            onBlur={description.handleBlur}
                        />
                    </FormGroup>

                    {/* Age interval */}
                    <FormGroup
                        label="Age interval"
                        intent={!!ageInterval.error ? Intent.DANGER : Intent.NONE}
                        helperText={ageInterval.error}
                    >
                        <HTMLSelect
                            fill
                            value={ageInterval.value}
                            id={description.id}
                            onChange={(e) => ageInterval.handleChange(e.target.value)}
                            onBlurCapture={() => ageInterval.handleClose()}
                        >
                            <option value="">Select age interval</option>
                            <option value="0-18">From 0 to 18</option>
                            <option value="19 - 40">From 19 to 40</option>
                            <option value="41 - 60">From 41 to 60</option>
                            <option value="60+">60+</option>
                        </HTMLSelect>
                    </FormGroup>

                    {/* Country select*/}
                    <FormGroup
                        label="Country"
                        intent={!!selectedAddressCountryKey.error ? Intent.DANGER : Intent.NONE}
                        helperText={selectedAddressCountryKey.error}
                    >
                        {renderCountrySelect()}
                    </FormGroup>

                    {/* Favorite film select*/}
                    <FormGroup
                        label="Favorite films"
                        intent={!!selectedFilms.error ? Intent.DANGER : Intent.NONE}
                        helperText={selectedFilms.error}
                    >
                        {renderFavoriteFilmsMultiSelect()}
                    </FormGroup>

                    {/* Social links */}
                    {renderSocialMediaLinksInputs()}

                    <FormGroup
                        label="Color"
                        intent={!!color.error ? Intent.DANGER : Intent.NONE}
                        helperText={color.error}
                    >
                        <ColorTilePicker
                            color={color.value}
                            onChange={color.handleChange}
                            onRemove={color.handleRemove}
                        />
                    </FormGroup>

                    <Flex justify="flex-end">
                        <Button
                            disabled={form.hasFieldErrors || form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Submit
                        </Button>
                    </Flex>
                </form>
            </Card>
        </FixedWidthPageContainer>
    );
};

export default Playground;
