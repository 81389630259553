import { Card, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { fetchClientPaymentAccount } from '../../store/effects';
import { $clientPaymentAccount, $task } from '../../store/states';
import { publishSteps } from './consts';
import {
    fetchClientComputedLicence,
    fetchClientCreditWallet,
    fetchClientQuotaUsage,
    fetchTaskCategory,
} from './store/effects';
import { resetClientComputedLicence, resetClientQuotaUsage, resetStep } from './store/events';
import { $clientComputedComputedLicence, $clientCreditWallet, $clientQuotaUsage, $step, $taskCategory } from './store/states';

export interface PublishTaskModalProps {
    taskId: ID;
}

type Props = ModalProps<PublishTaskModalProps>;

const PublishTaskModal: React.FC<Props> = ({ closeModal, data }) => {
    const task = useStore($task);
    const clientPaymentAccount = useStore($clientPaymentAccount);
    const clientCreditWallet = useStore($clientCreditWallet);
    const clientQuotaUsage = useStore($clientQuotaUsage);
    const clientComputedLicence = useStore($clientComputedComputedLicence);
    const taskCategory = useStore($taskCategory);
    const step = useStore($step);

    useEffect(() => {
        if (!task) {
            return;
        }

        const handleInitialize = async () => {
            try {
                if (!clientPaymentAccount) {
                    // Fetch client payment account & payment methods
                    await fetchClientPaymentAccount(task.user.id);
                }

                // Fetch client credit wallet
                await fetchClientCreditWallet(task.user.id);

                // Fetch computed licence
                await fetchClientComputedLicence(task.user.id);

                // Fetch quota usage
                await fetchClientQuotaUsage({
                    clientId: task.user.id,
                    filter: {
                        quota_type: Object.values(QuotaType),
                    },
                });

                // Fetch task category
                await fetchTaskCategory(task.category.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        };

        handleInitialize();

        // eslint-disable-next-line
    }, [task?.user.id]);

    useEffect(() => {
        return () => {
            resetClientComputedLicence();
            resetClientQuotaUsage();
            resetStep();
        };
    }, []);

    if (!task) {
        // Task was NOT fetched yet
        return null;
    }

    if (!data) {
        // Modal data was NOT provided
        closeModal?.();
        return null;
    }

    const renderContent = () => {
        let content;
        if (!clientPaymentAccount || !clientComputedLicence || !clientQuotaUsage || !taskCategory || !clientCreditWallet) {
            content = (
                <Flex direction="row" align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        } else {
            // Get current publish step
            const PublishStepComponent = publishSteps[step];

            content = <PublishStepComponent closeModal={closeModal} data={data} />;
        }

        return <Card style={{ width: '558px' }}>{content}</Card>;
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default PublishTaskModal;
