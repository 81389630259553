import { UserImageHashRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import { createApi } from 'effector';
import { $authorizedUser } from './states';

// API to manage authorized user
export const authorizedUserApi = createApi($authorizedUser, {
    setImageHash: (store, payload: UserImageHashRef) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            user: {
                ...store.user,
                image_hash: payload.hash,
            },
        };
    },
});
