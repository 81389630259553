import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { UserNoteListItemResource } from 'dy-frontend-http-repository/lib/modules/UserNote/resources';
import { resetClientNotes, resetEditingClientNote } from './events';
import domain from './domain';

// Client notes
export const $clientNotes = domain
    .createStore<CollectionResource<UserNoteListItemResource, {}> | null>(null)
    .reset(resetClientNotes);

// Client note which is being edited
export const $editingClientNote = domain
    .createStore<UserNoteListItemResource | null>(null)
    .reset(resetEditingClientNote);
