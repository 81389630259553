import defaultIcon from '@app/assets/icons/file-extensions/default.png';
import otf from '@app/assets/icons/file-extensions/otf.png';
import f3ds from '@app/assets/icons/file-extensions/3ds.png';
import f7z from '@app/assets/icons/file-extensions/7z.png';
import aac from '@app/assets/icons/file-extensions/aac.png';
import ai from '@app/assets/icons/file-extensions/ai.png';
import avi from '@app/assets/icons/file-extensions/avi.png';
import bmp from '@app/assets/icons/file-extensions/bmp.png';
import cad from '@app/assets/icons/file-extensions/cad.png';
import cdr from '@app/assets/icons/file-extensions/cdr.png';
import css from '@app/assets/icons/file-extensions/css.png';
import csv from '@app/assets/icons/file-extensions/csv.png';
import dmg from '@app/assets/icons/file-extensions/dmg.png';
import doc from '@app/assets/icons/file-extensions/doc.png';
import docx from '@app/assets/icons/file-extensions/docx.png';
import eps from '@app/assets/icons/file-extensions/eps.png';
import fig from '@app/assets/icons/file-extensions/fig.png';
import fla from '@app/assets/icons/file-extensions/fla.png';
import flv from '@app/assets/icons/file-extensions/flv.png';
import fontExtension from '@app/assets/icons/file-extensions/font.png';
import gif from '@app/assets/icons/file-extensions/gif.png';
import gslides from '@app/assets/icons/file-extensions/gslides.png';
import html from '@app/assets/icons/file-extensions/html.png';
import ico from '@app/assets/icons/file-extensions/ico.png';
import indd from '@app/assets/icons/file-extensions/indd.png';
import iso from '@app/assets/icons/file-extensions/iso.png';
import jpg from '@app/assets/icons/file-extensions/jpg.png';
import js from '@app/assets/icons/file-extensions/js.png';
import key from '@app/assets/icons/file-extensions/key.png';
import m4v from '@app/assets/icons/file-extensions/m4v.png';
import midi from '@app/assets/icons/file-extensions/midi.png';
import mkv from '@app/assets/icons/file-extensions/mkv.png';
import mov from '@app/assets/icons/file-extensions/mov.png';
import mp3 from '@app/assets/icons/file-extensions/mp3.png';
import mpeg from '@app/assets/icons/file-extensions/mpeg.png';
import mp4 from '@app/assets/icons/file-extensions/mp4.png';
import ogg from '@app/assets/icons/file-extensions/ogg.png';
import mpg from '@app/assets/icons/file-extensions/mpg.png';
import pdf from '@app/assets/icons/file-extensions/pdf.png';
import php from '@app/assets/icons/file-extensions/php.png';
import png from '@app/assets/icons/file-extensions/png.png';
import ppt from '@app/assets/icons/file-extensions/ppt.png';
import pptx from '@app/assets/icons/file-extensions/pptx.png';
import ps from '@app/assets/icons/file-extensions/ps.png';
import psd from '@app/assets/icons/file-extensions/psd.png';
import pttx from '@app/assets/icons/file-extensions/pttx.png';
import rar from '@app/assets/icons/file-extensions/rar.png';
import raw from '@app/assets/icons/file-extensions/raw.png';
import sketch from '@app/assets/icons/file-extensions/sketch.png';
import sql from '@app/assets/icons/file-extensions/sql.png';
import svg from '@app/assets/icons/file-extensions/svg.png';
import tif from '@app/assets/icons/file-extensions/tif.png';
import ttf from '@app/assets/icons/file-extensions/ttf.png';
import txt from '@app/assets/icons/file-extensions/txt.png';
import wav from '@app/assets/icons/file-extensions/wav.png';
import wma from '@app/assets/icons/file-extensions/wma.png';
import wmv from '@app/assets/icons/file-extensions/wmv.png';
import xls from '@app/assets/icons/file-extensions/xls.png';
import xlsx from '@app/assets/icons/file-extensions/xlsx.png';
import xml from '@app/assets/icons/file-extensions/xml.png';
import zip from '@app/assets/icons/file-extensions/zip.png';

const fileExtensionImages = {
    default: defaultIcon,
    otf: otf,
    '3ds': f3ds,
    '7z': f7z,
    aac: aac,
    ai: ai,
    avi: avi,
    bmp: bmp,
    cad: cad,
    cdr: cdr,
    css: css,
    csv: csv,
    dmg: dmg,
    doc: doc,
    docx: docx,
    eps: eps,
    fig: fig,
    fla: fla,
    flv: flv,
    tte: fontExtension,
    dfont: fontExtension,
    gif: gif,
    gslides: gslides,
    html: html,
    ico: ico,
    indd: indd,
    iso: iso,
    jpg: jpg,
    jpeg: jpg,
    js: js,
    key: key,
    m4v: m4v,
    midi: midi,
    mkv: mkv,
    mov: mov,
    mp3: mp3,
    mpeg: mpeg,
    mpg: mpg,
    mp4: mp4,
    ogg: ogg,
    pdf: pdf,
    php: php,
    png: png,
    ppt: ppt,
    pptx: pptx,
    ps: ps,
    psd: psd,
    pttx: pttx,
    rar: rar,
    raw: raw,
    sketch: sketch,
    sql: sql,
    svg: svg,
    tif: tif,
    ttf: ttf,
    txt: txt,
    wav: wav,
    wma: wma,
    wmv: wmv,
    xls: xls,
    xlsx: xlsx,
    xml: xml,
    zip: zip,
};

export default fileExtensionImages;
