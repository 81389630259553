import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { taskQueueInformation } from '@data/consts';
import { PlatformTaskFilterData } from '@pages/Tasks/types';
import { platformFiltersInformation } from '@pages/Tasks/consts';
import { PlatformTaskFilterType } from '@pages/Tasks/enums';
import { $isQueryHydrationFinished } from '@pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@pages/Tasks/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';

export interface TaskQueuesPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskQueuesPlatformFilterProps;

const TaskQueuesPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedTaskQueues, setSelectedTaskQueues] = useState<PlatformTaskFilterData['queues']>(
        platformTaskFilterData.queues
    );

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedTaskQueues(platformTaskFilterData.queues);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.queues.length === 0) {
            setSelectedTaskQueues([]);
        }
    }, [platformTaskFilterData.queues]);

    const getFilterTagLabel = () => {
        if (selectedTaskQueues.length === 0) return '';

        return selectedTaskQueues
            .map((queue, index) => {
                if (index === selectedTaskQueues.length - 1) {
                    // Last element
                    return `${taskQueueInformation[queue].title}`;
                }

                return `${taskQueueInformation[queue].title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedTaskQueues.length > 0) {
            // At least 1 task queue selected
            return 'Queues:';
        }

        return 'Queues';
    };

    const handleRemoveFilter = () => {
        if (selectedTaskQueues.length !== 0) {
            // At least 1 task queue was selected

            setSelectedTaskQueues([]);

            if (platformTaskFilterData.queues.length !== 0) {
                // Reset task queue to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, queues: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUES].isPinned) {
            // Platform task queue filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (key: PlatformTaskFilterData['queues'][number]) => {
        if (selectedTaskQueues.includes(key)) {
            // Task queue is already checked
            setSelectedTaskQueues(selectedTaskQueues.filter((queue) => queue !== key));
        } else {
            // Task queue is NOT checked yet
            setSelectedTaskQueues([...selectedTaskQueues, key]);
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedTaskQueues) !== JSON.stringify(platformTaskFilterData.queues)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, queues: selectedTaskQueues });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUES].isPinned) {
            return;
        }

        if (selectedTaskQueues.length === 0) {
            onRemove();
        }
    };

    const renderRow = (key: PlatformTaskFilterData['queues'][number]) => {
        return (
            <li className="mb-1" key={key}>
                <Checkbox
                    checked={selectedTaskQueues.includes(key)}
                    className="mr-2"
                    label={taskQueueInformation[key].title}
                    onClick={() => handleCheckboxClick(key)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['queues'][number]>
            isOpen={isOpen}
            removable={selectedTaskQueues.length > 0}
            title="Queues"
            position={Position.BOTTOM_LEFT}
            list={Object.values(TaskQueue)}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUES].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskQueuesPlatformFilter;
