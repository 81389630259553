import { GetValidatorFunction } from '@app/hooks/validation/types';

// Is array size greater then
const defaultMinArrayLength = 1;
const getDefaultArrayLengthValueWentBeyondMinValueMessage = (minArrayLength: number) =>
    `Minimum amount of items is ${minArrayLength}`;

const getArrayMinLengthValidator: GetValidatorFunction<
    { minArrayLength: number; arrayLengthValueWentBeyondMinValueMessageFormatter?: (minArrayLength: number) => string },
    unknown[]
> = (
    options = {
        minArrayLength: defaultMinArrayLength,
        arrayLengthValueWentBeyondMinValueMessageFormatter: getDefaultArrayLengthValueWentBeyondMinValueMessage,
    }
) => {
    const { minArrayLength, arrayLengthValueWentBeyondMinValueMessageFormatter } = options;

    if (minArrayLength <= 0) {
        throw new Error(`Validator getArrayMinLengthValidator expects positive value, but got ${minArrayLength}`);
    }

    return async (value) => {
        if (value.length >= minArrayLength) {
            // Passed validation
            return null;
        } else {
            // Did NOT pass validation
            if (arrayLengthValueWentBeyondMinValueMessageFormatter) {
                return arrayLengthValueWentBeyondMinValueMessageFormatter(minArrayLength);
            } else {
                return getDefaultArrayLengthValueWentBeyondMinValueMessage(minArrayLength);
            }
        }
    };
};

export default getArrayMinLengthValidator;
