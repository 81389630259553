import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CreditWalletResource, CreditWalletTransactionResource } from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import domain from './domain';
import { resetCreditWallet, resetCreditWalletTransactions } from './events';

// Credit wallet
export const $creditWallet = domain.createStore<CreditWalletResource | null>(null).reset(resetCreditWallet);

// Credit wallet transactions
export const $creditWalletTransactions = domain.createStore<CollectionResource<CreditWalletTransactionResource, {}> | null>(null).reset(resetCreditWalletTransactions);
