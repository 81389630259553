import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { subscriptionModeAllowedPlanTypesInformation } from '@data/consts';

/**
 * Check if plan type allowed by subscription mode
 * e.g. can be used to check if plan type allowed to become new subscription item
 * @param planType plan type which is used to check if plan type allowed by subscription mode
 * @param subscriptionMode subscription mode
 * @returns {boolean} result of check (is plan price allowed by subscription mode)
 */
export default function checkIfPlanTypeAllowedBySubscriptionMode(
    planType: PlanType,
    subscriptionMode: SubscriptionMode
) {
    const allowedPlanTypes: PlanType[] = subscriptionModeAllowedPlanTypesInformation[subscriptionMode];
    return allowedPlanTypes.includes(planType);
}
