import PlanTag from '@app/containers/components/PlanTag';
import { usePageTitle } from '@app/hooks';
import { Button, Colors, Icon, Intent, Spinner, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import RouterLink from '@components/RouterLink';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { SessionStorage } from '@data/enums';
import { openModal } from '@modals/store/events';
import { ClientUserFilterInput } from 'dy-frontend-http-repository/lib/modules/ClientUser/inputs';
import { ClientUserListItemResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlatformClientUserFilter from './components/PlatformClientUserFilter';
import { amountOfClientsOnPage, initialPlatformClientUserFilterData } from './consts';
import CreateClientModal from './modals/CreateClientModal';
import { fetchClients, hydrateQueryParameters } from './store/effects';
import {
    resetClients,
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformClientUserFilterData,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformClientUserFilterData,
} from './store/events';
import { $clients, $isQueryHydrationFinished, $page, $platformClientUserFilterData } from './store/states';
import { ClientUserFilterQueryParameters, PlatformClientUserFilterData } from './types';
import { PlatformClientUserFilter as PlatformClientUserFilterValueObject } from './valueObjects';
import { $permissions } from '@containers/store/states';
import { ClientUserPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType, LicenceState } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import LicenceTag from '@app/containers/components/LicenceTag';

const Clients: React.FC = () => {
    const permissions = useStore($permissions);

    usePageTitle('Clients');

    const navigate = useNavigate();
    const location = useLocation();

    const page = useStore($page);
    const clients = useStore($clients);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformClientUserFilterData = useStore($platformClientUserFilterData);
    const isFetchingClients = useStore(fetchClients.pending);

    const handleLoadPage = async (newPage: number, filter?: ClientUserFilterInput) => {
        const pageOffset = (newPage - 1) * amountOfClientsOnPage;

        try {
            await fetchClients({
                pagination: {
                    _pagination: { limit: amountOfClientsOnPage, offset: pageOffset },
                },
                filter,
            });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Clients were not found`,
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Empty query string, load from local storage
            const savedUrlQueryParameters = sessionStorage.getItem(SessionStorage.CLIENT_USER_FILTER);

            if (savedUrlQueryParameters && savedUrlQueryParameters.length > 0) {
                urlQueryParameters = savedUrlQueryParameters;
            }
        }

        // Client user filter query parameters
        const clientUserFilterParameters: Partial<ClientUserFilterQueryParameters> = qs.parse(urlQueryParameters);

        // Hydration input
        const hydrateQueryInput =
            PlatformClientUserFilterValueObject.convertClientUserFilterQueryParametersToHydrateQueryInput(
                clientUserFilterParameters
            );

        // Platform client user filter data
        let platformClientUserData: PlatformClientUserFilterData = { ...initialPlatformClientUserFilterData };

        // hydrate
        hydrateQueryParameters(hydrateQueryInput)
            .then(({ workspace, plan }) => {
                platformClientUserData.workspaces = workspace ?? [];
                platformClientUserData.plans = plan ?? [];

                platformClientUserData =
                    PlatformClientUserFilterValueObject.convertHydratedQueryParametersToPlatformClientUserFilterData({
                        ...clientUserFilterParameters,
                        workspace: workspace ?? [],
                        plan: plan ?? [],
                    });
            })
            .finally(() => {
                const page = clientUserFilterParameters.page ? parseInt(clientUserFilterParameters.page) : 1;

                setPage(page);
                setPlatformClientUserFilterData(platformClientUserData);
                setIsQueryHydrationFinished(true);
            });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            // Get client user filter input
            const clientUserFilterInput =
                PlatformClientUserFilterValueObject.convertPlatformClientUserFilterDataToClientUserFilterInput(
                    platformClientUserFilterData
                );

            // Get client user filter query parameters string
            const clientUserFilterQueryParametersString = qs.stringify(
                PlatformClientUserFilterValueObject.convertClientUserFilterInputToClientUserFilterQueryParameters(
                    page,
                    clientUserFilterInput
                )
            );

            // Save query parameters to session storage
            sessionStorage.setItem(SessionStorage.CLIENT_USER_FILTER, clientUserFilterQueryParametersString);

            // Update URL
            navigate(`${Endpoints.CLIENTS}?${clientUserFilterQueryParametersString}`, { replace: true });

            // Fetch client user list with new page
            handleLoadPage(page, clientUserFilterInput);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformClientUserFilterData]);

    useEffect(() => {
        return () => {
            resetClients();
            resetPage();
            resetIsQueryHydrationFinished();
            resetPlatformClientUserFilterData();
        };
    }, []);

    if (!clients) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        const renderAddButton = () => {
            if (!permissions.isRoot.clientUser && !permissions.has(ClientUserPermission.CREATE)) {
                return null;
            }

            return (
                <Button
                    intent={Intent.PRIMARY}
                    icon="add"
                    onClick={() => openModal({ ModalComponent: CreateClientModal })}
                >
                    Add client
                </Button>
            );
        };

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Clients</Heading>

                {renderAddButton()}
            </Flex>
        );
    };

    const renderClientsTable = () => {
        if (clients.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client users were found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Email</th>
                        <th>Plans</th>
                        <th>Registered</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const renderClientInformation = (client: ClientUserListItemResource) => {
                const renderClientCompanyInformation = () => {
                    let companyInformation = '';

                    if (client.company_name.trim().length > 0) {
                        companyInformation = client.company_name;

                        if (client.company_position.trim().length > 0) {
                            companyInformation = `${client.company_position} at ${client.company_name}`;
                        }
                    }

                    if (companyInformation.length === 0) {
                        return null;
                    }

                    return <DevText muted>{companyInformation}</DevText>;
                };

                let avatarSrc: string | null = null;
                if (client.image_hash) {
                    avatarSrc = imageHashPreview.userImage(client.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <Flex>
                        <Avatar className="mr-1" alt={client.first_name} src={avatarSrc} width="42px" height="42px" />
                        <Flex direction="column" justify="center">
                            <Flex align="center">
                                <RouterLink
                                    className="mr-1"
                                    color={Colors.WHITE}
                                    to={Endpoints.CLIENT_TASKS.replace(':clientId', client.id)}
                                >
                                    {client.first_name} {client.last_name}
                                </RouterLink>
                            </Flex>

                            {renderClientCompanyInformation()}
                        </Flex>
                    </Flex>
                );
            };

            const renderClientPlansInformation = (client: ClientUserListItemResource) => {
                if (client.licences.length === 0) {
                    // No licences, meaning there are no plans attached for this client
                    return <DevText muted>No plans</DevText>;
                }

                return (
                    <>
                        {client.licences.map((licence) => {
                            const plan = licence.plan;

                            return (
                                <LicenceTag
                                    suspended={licence.state === LicenceState.SUSPENDED}
                                    className="mr-small mb-small"
                                    key={licence.id}
                                    name={plan.title}
                                    color={plan.color}
                                    quantity={licence.quantity}
                                >
                                    {plan.title}
                                </LicenceTag>
                            );
                        })}
                    </>
                );
            };

            // TODO: add MBG tag
            const renderRow = (client: ClientUserListItemResource) => {
                return (
                    <tr key={client.id}>
                        <TableCell verticalAlign="middle">{renderClientInformation(client)}</TableCell>
                        <TableCell verticalAlign="middle">
                            <DevText>{client.email}</DevText>
                            {client.verified_at === null && (
                                <Tag minimal className="mt-small">
                                    Unverified
                                </Tag>
                            )}
                        </TableCell>
                        <TableCell verticalAlign="middle">{renderClientPlansInformation(client)}</TableCell>
                        <TableCell verticalAlign="middle">
                            <DevText>{moment(client.created_at).format('DD.MM.YYYY')}</DevText>
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{clients.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingClients}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (clients === null) {
            return null;
        }

        // Check if offset paginator exist
        if (clients.paginator === null) {
            return;
        }

        // Only one page exist
        if (!clients.paginator.has_more && clients.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingClients}
                    hasMore={clients.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfClientsOnPage}
                    totalItems={clients.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformClientUserFilter className="mb-2" />

            {/* Clients table */}
            {renderClientsTable()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default Clients;
