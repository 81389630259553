import React, { HTMLAttributes } from 'react';
import Flex from '@components/Flex';

export interface NonIdealStateProps {
    title?: React.ReactNode;
    description?: React.ReactNode;
    icon?: React.ReactNode;
    action?: React.ReactNode;
    layout?: 'vertical' | 'horizontal';
}

export type Props = NonIdealStateProps & Omit<HTMLAttributes<HTMLDivElement>, 'title'>;

const NonIdealState: React.FC<Props> = ({ title, description, icon, action, layout = 'vertical', ...props }) => {
    const renderVerticalLayout = () => {
        return (
            <Flex direction="column" align="center">
                {icon}
                {title}
                {description}
                {action}
            </Flex>
        );
    };

    const renderHorizontalLayout = () => {
        const isTextBlockVisible = title || description;

        return (
            <Flex>
                {icon}

                {isTextBlockVisible && (
                    <Flex direction="column">
                        {title}
                        {description}
                    </Flex>
                )}

                {action}
            </Flex>
        );
    };

    const renderContent = () => {
        switch (layout) {
            case 'vertical':
                return renderVerticalLayout();
            case 'horizontal':
                return renderHorizontalLayout();
        }
    };

    return <div {...props}>{renderContent()}</div>;
};

export default NonIdealState;
