import React from 'react';
import { Intent, Spinner, SpinnerProps } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Box, { Props as BoxProps } from '@components/Box';

export interface FileUploadProgressProps {
    progress?: number;
    spinnerProps?: SpinnerProps;
}

export type Props = FileUploadProgressProps & BoxProps;

const FileUploadProgress: React.FC<Props> = ({
    progress: propProgress = undefined,
    spinnerProps = { intent: Intent.PRIMARY, size: 40 },
    ...props
}) => {
    let progress: number | undefined;
    if (propProgress) {
        progress = propProgress * 100;
    }

    return (
        <Box {...props}>
            <Flex fullWidth fullHeight justify="center" align="center">
                <Spinner value={progress} {...spinnerProps} />
            </Flex>
        </Box>
    );
};

export default FileUploadProgress;
