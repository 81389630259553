import React, { useEffect } from 'react';
import { BreadcrumbProps, Intent, Spinner } from '@blueprintjs/core';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Endpoints } from '@data/consts';
import { $client, $clientPaymentAccount } from './store/states';
import { fetchClient, fetchClientPaymentAccount, fetchComputedLicence, fetchQuotaUsage } from './store/effects';
import { resetClient, resetClientPaymentAccount, resetComputedLicence, resetQuotaUsage } from './store/events';
import Aside from './components/Aside';
import Tabs from './components/Tabs';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Grid from '@components/Grid';
import Flex from '@components/Flex';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { HTTPErrorType, QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const Client: React.FC = () => {
    const { changeTitle } = usePageTitle('Client');

    const navigate = useNavigate();
    const { clientId } = useParams() as { clientId: ID };

    /**
     * TODO: Here an inside child components, there is no check that user === me. While this is NOT probable situation
     *  (meaning that client somehow navigating internal dashboard), I think this is still needs to be done in the future
     */
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.clientUser) {
        // TODO: Redirect to dashboard
    }

    const client = useStore($client);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    useEffect(() => {
        return () => {
            resetClient();
            resetClientPaymentAccount();
            resetPageBreadcrumbs();
            resetQuotaUsage();
            resetComputedLicence();
        };
    }, [clientId]);

    useEffect(() => {
        if (!client) {
            return;
        }

        const breadcrumbItems: BreadcrumbProps[] = [
            {
                text: 'Clients',
                onClick: () => navigate(Endpoints.CLIENTS),
            },
            { text: `${client.first_name} ${client.last_name}` },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, [client?.id]);

    useEffect(() => {
        fetchClient(clientId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Client with ID of ${clientId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to clients page
                navigate(Endpoints.CLIENTS);
            }
        });
    }, [clientId]);

    useEffect(() => {
        if (!client) {
            return;
        }

        // Fetch computed licence
        fetchComputedLicence(client.id).catch((e) => {
            // TODO: handle error
            console.error(e);
        });

        // Fetch quota usage
        fetchQuotaUsage({
            userId: client.id,
            filter: {
                quota_type: [...Object.values(QuotaType)],
            },
        }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, [client]);

    // Fetch payment account
    useEffect(() => {
        if (!client) {
            // Client was NOT fetched yet
            return;
        }

        const isPaymentAccountAllowed = permissions.isEnabled.paymentAccount;
        if (!client.initial_verification_at || !isPaymentAccountAllowed) {
            // Client was NOT initially verified at, meaning they'll NOT have payment account yet, it only being created after verification
            return;
        }

        if (clientPaymentAccount) {
            // After client payment account is fetched where is no need to fetch it again
            return;
        }

        fetchClientPaymentAccount(client.id).catch((e) => {
            // TODO: handle error
            console.error(e.response.data);
        });

        // eslint-disable-next-line
    }, [client?.id, client?.initial_verification_at]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    if (!client) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <FixedWidthPageContainer>
            <Grid container>
                <Grid lg={3} xs={12}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9} xs={12}>
                    <Tabs className="mb-2" />
                    <Outlet />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default Client;
