import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    height: 100%;
    width: 300px;
    min-width: 300px;
    padding: 15px;
    overflow-y: auto;
    background-color: #1C2127;
`;
