import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import { UserVerificationMethod } from 'dy-frontend-http-repository/lib/modules/UserVerification/enums';
import Flex from '@components/Flex';
import { Button, Callout, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import Overlay from '@components/Overlay';
import { createUserVerificationIntent } from './store/effects';
import { useForm } from '@app/hooks';

export interface SendUserVerificationModalProps {
    userId: ID;
    userEmail: string;
}

type Props = ModalProps<SendUserVerificationModalProps>;

/**
 * NOTE:
 *  - This modal currently handles only 'email' method user verification intents
 *  - Ideally, modal should have method selector (even there is only 1 method now)
 *  - After successful submit of the form, based on the selected method, there must be a possibility to show
 *    additional step. For example: when (in possible future) 'phone' user verification is created, there is a need
 *    to show an additional step, where you can enter received code.
 */
const SendUserVerificationModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);

    const form = useForm({
        fields: [],
        apiCall: async () => {
            try {
                const userVerificationRef = await createUserVerificationIntent({
                    user_id: data?.userId ?? '0',
                    method: UserVerificationMethod.EMAIL,
                });
                setIsCompleted(true);
            } catch (e) {
                throw e;
            }

            return { response: true };
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return;
        }

        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Verification link successfully sent</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    Verification link sent to email: {data.userEmail}
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <Callout intent={Intent.PRIMARY} className="mb-2">
                    Verification link will be sent to email: {data.userEmail}
                </Callout>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Send verification link?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} loading={form.isSubmitting} type="submit">
                            Yes, send verification link
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Send verification link
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default SendUserVerificationModal;
