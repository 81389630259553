import moment from 'moment';

const useTeamWorkingHours = () => {
    const getDateFromWorkingHours = (workingHours: string): Date => {
        const splittedWorkingHours = workingHours.split(':');

        // Get hours, minutes, seconds as a numbers
        const hours = splittedWorkingHours[0] ? parseInt(splittedWorkingHours[0]) : 0;
        const minutes = splittedWorkingHours[1] ? parseInt(splittedWorkingHours[1]) : 0;
        const seconds = splittedWorkingHours[2] ? parseInt(splittedWorkingHours[2]) : 0;

        return moment().set('hours', hours).set('minutes', minutes).set('seconds', seconds).toDate();
    };

    const getWorkingHoursFromDate = (workingHours: Date | null): string => {
        if (!workingHours) {
            return '00:00:00';
        }

        // Get hours, minutes, seconds
        const hours = workingHours.getHours();
        const minutes = workingHours.getMinutes();
        const seconds = workingHours.getSeconds();

        // Format hours, minutes, seconds to look like '00', not '0'
        const formattedHours = `${hours}`.padStart(2, '0');
        const formattedMinutes = `${minutes}`.padStart(2, '0');
        const formattedSeconds = `${seconds}`.padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    return { getWorkingHoursFromDate, getDateFromWorkingHours };
};

export default useTeamWorkingHours;
