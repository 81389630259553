import styled, { css } from 'styled-components';
import { PlatformListFilterWithDynamicSearchProps } from './';

export const List = styled.ul<{ $maxListHeight?: PlatformListFilterWithDynamicSearchProps<any>['maxListHeight'] }>`
    padding: 0;
    margin: 0;
    list-style: none;

    li + li {
        margin-top: 8px;
    }

    ${({ $maxListHeight }) =>
        $maxListHeight &&
        css`
            max-height: ${$maxListHeight}px;
            overflow-y: auto;
        `}
`;

export const Content = styled.div<{ $maxHeight?: PlatformListFilterWithDynamicSearchProps<any>['maxHeight'] }>`
    padding: 16px;
    min-width: 320px;

    ${({ $maxHeight }) =>
        $maxHeight &&
        css`
            max-height: ${$maxHeight}px;
            overflow-y: auto;
        `}
`;
