import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (containerClassName = 'main-content') => {
    const { pathname } = useLocation();

    useEffect(() => {
        const container = document.querySelector(`.${containerClassName}`);

        if (container) {
            container.scroll({
                top: 0,
                left: 0,
            });
        }
    }, [pathname, containerClassName]);
};

export default useScrollToTop;
