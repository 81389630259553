import styled from 'styled-components';
import { Navbar as BlueprintNavbar, Colors } from '@blueprintjs/core';

export const Navbar = styled(BlueprintNavbar)`
    &&& {
        background-color: ${Colors.DARK_GRAY2};
        box-shadow: none;
    }

    position: sticky;
    top: 0;
`;

export const UserInfoBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
