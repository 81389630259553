import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { $coreUser } from '../../store/states';

const CoreUserStatistics: React.FC = () => {
    const { changeTitle } = usePageTitle('Member Statistics');

    const coreUser = useStore($coreUser);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Member Statistics - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    return <div>CoreUserStatistics</div>;
};

export default CoreUserStatistics;
