import React, { HTMLAttributes } from 'react';
import { StyledTableCell } from './styled';

export interface TableCellProps {
    verticalAlign?: 'baseline' | 'top' | 'middle' | 'bottom' | 'sub' | 'text-top';
}

export type Props = TableCellProps & HTMLAttributes<HTMLTableCellElement>;

const TableCell: React.FC<Props> = ({ verticalAlign = 'top', ...props }) => {
    return <StyledTableCell $verticalAlign={verticalAlign} {...props} />;
};

export default TableCell;
