import moment from 'moment/moment';
import { transitionTaskState } from '@pages/Task/store/effects';
import { taskApi, taskStateTransitionLogApi } from '@pages/Task/store/apis';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { $authorizedUser } from '@app/containers/store/states';
import { useStore } from 'effector-react';

// export interface UseTaskStateTransitionProps {
//     onStateTransitionDone: () => void;
// }

const useTaskStateTransition = () => {
    const me = useStore($authorizedUser);

    const handleTransitionTaskState = async (
        taskId: ID,
        state: TaskState,
        comment = '',
    ) => {
        try {
            const now = moment().utc().format();
            const process = await transitionTaskState({
                id: taskId,
                input: { state, comment }
            })

            taskApi.transitionState({ state });

            taskStateTransitionLogApi.update([
                {
                    comment: '',
                    applied_at: now,
                    is_forced: false,
                    state: state,
                    user: me ? {
                        first_name: me.user.first_name,
                        id: me.user.id,
                        last_name: me.user.last_name,
                        image_hash: me.user.image_hash,
                        type: me.user.type,
                    } : null,
                },
            ]);

        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    return { handleTransitionTaskState };
};

export default useTaskStateTransition;
