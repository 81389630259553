import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import {
    getStringRequiredValidator,
    getStringMinLengthValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueMaxValidator,
} from '@app/hooks/validation/functions';
import { useForm, useTextFormField } from '@app/hooks';
import InputFormField from '@app/components/InputFormField';
import { createCreditWalletTransaction } from '../../store/effects';
import { ToastUtils } from '@app/data/utils';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { CreateCreditWalletTransactionInput } from 'dy-frontend-http-repository/lib/modules/CreditWallet/inputs';
import { creditWalletApi, creditWalletTransactionsApi } from '../../store/apis';

export interface RemoveCreditWalletCreditsModalProps {
    creditWalletId: ID;
    clientId: ID;
    balance: number;
}

type Props = ModalProps<RemoveCreditWalletCreditsModalProps>;

const creditAmountValidators = [
    getStringRequiredValidator(),
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
];
const summaryValidators = [getStringRequiredValidator(), getStringMinLengthValidator({ minStringLength: 5 })];

const RemoveCreditWalletCreditsModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);

    const creditAmount = useTextFormField({
        id: 'credit-amount',
        validators: [
            ...creditAmountValidators,
            getStringNumericValueMinValidator({ minValue: 1 }),
            getStringNumericValueMaxValidator({ maxValue: data?.balance ?? 1 }),
        ],
        initialValue: '',
    });

    const summary = useTextFormField({
        id: 'credit-transaction-summary',
        validators: summaryValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [summary],
        apiCall: async () => {
            try {
                const creditAmountNumber = parseInt(creditAmount.value);

                const createCreditWalletTransactionInput: CreateCreditWalletTransactionInput = {
                    amount: creditAmountNumber > 0 ? -creditAmountNumber : creditAmountNumber,
                    summary: summary.value,
                };

                const transactionRef = await createCreditWalletTransaction({
                    creditWalletId: data?.creditWalletId ?? '0',
                    input: createCreditWalletTransactionInput,
                });

                creditWalletApi.applyCredits({ creditAmount: createCreditWalletTransactionInput.amount });
                creditWalletTransactionsApi.add({
                    creditWalletId: data?.creditWalletId ?? '0',
                    userId: data?.clientId ?? '0',
                    transactionId: transactionRef.id,
                    input: createCreditWalletTransactionInput,
                });

                setIsCompleted(true);

                return { response: true };
            } catch (e) {
                // TODO: handle error
                throw e;
            }
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
            ToastUtils.showToast({
                message: e,
                intent: Intent.DANGER,
            });
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return null;
        }

        // Completed without error
        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Removed {Math.abs(parseInt(creditAmount.value))} credits</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    Transaction should appear in the transactions list by now
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <div className="mb-2">
                    <InputFormField
                        field={creditAmount}
                        formGroupProps={{
                            fill: true,
                            label: 'Credit(s) amount',
                            helperText: creditAmount.error ?? `Current balance: ${data.balance}`,
                        }}
                        inputProps={{ placeholder: 'Enter amount of credits to remove' }}
                    />
                </div>

                <div className="mb-2">
                    <TextAreaFormField
                        field={summary}
                        formGroupProps={{
                            fill: true,
                            label: 'Summary',
                        }}
                        textAreaProps={{ placeholder: 'Enter summary' }}
                    />
                </div>

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed credits withdrawal?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={form.isSubmitting}
                            disabled={form.hasFieldErrors}
                            type="submit"
                        >
                            Yes, remove
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Remove credits</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default RemoveCreditWalletCreditsModal;
