import React, { HTMLAttributes } from 'react';
import DevText from '@components/Text';

interface DesignDimensionsListItemProps {
    designDimensionTitle: string;
    designDimensions: string[];
}

type Props = DesignDimensionsListItemProps & HTMLAttributes<HTMLDivElement>;

const DesignDimensionsListItem: React.FC<Props> = ({ designDimensionTitle, designDimensions, ...props }) => {
    return (
        <div {...props}>
            <DevText muted className="mb-small">
                {designDimensionTitle}
            </DevText>

            {designDimensions.map((designDimension) => (
                <DevText>{designDimension}</DevText>
            ))}
        </div>
    );
};

export default DesignDimensionsListItem;
