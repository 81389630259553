import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { taskApi } from '@pages/Task/store/apis';
import { removeTask, restoreTask } from '@pages/Task/store/effects';

export interface ArchiveTaskModalProps {
    taskId: ID;
}

type Props = ModalProps<ArchiveTaskModalProps>;

const ArchiveTaskModal: React.FC<Props> = ({
    closeModal,
    data,
}) => {
    // Task restoring
    const [isTaskArchiving, setIsTaskArchiving] = useState(false);

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    const handleArchiveFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsTaskArchiving(true);

        removeTask(data.taskId)
            .then(() => {
                taskApi.remove();
                closeModal?.();
            })
            .catch((e) => console.error)
            .finally(() => setIsTaskArchiving(false));

        return null;
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">Archive this request?</Heading>
                        <DevText muted>Proceeding will mark task as archived, and remove from active list</DevText>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isTaskArchiving} />
                </Flex>

                <DevText running className="mb-2">
                    Request will be removed from client's current requests. Archived requests are usually mean that
                    request was created by mistake, or client decided that they do not want to proceed with it.
                    After archiving, you will be able to restore this request at any time to it's previous state.
                </DevText>

                <form onSubmit={handleArchiveFormSubmit}>
                    <Flex align="center" justify="space-between">
                        <DevText muted>Proceed with archiving?</DevText>
                        <div>
                            <Button minimal onClick={closeModal} className="mr-1" disabled={isTaskArchiving}>
                                No, cancel
                            </Button>
                            <Button
                                intent={Intent.PRIMARY}
                                loading={isTaskArchiving}
                                type="submit"
                            >
                                Yes, archive request
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default ArchiveTaskModal;
