import React, { forwardRef } from 'react';
import  {default as DefReactPlayer,  ReactPlayerProps } from 'react-player/lazy';
import { Wrapper } from './styled';

export interface VideoViewerProps {
    withAspectRatio?: boolean;
    aspectRationX?: number;
    aspectRationY?: number;
}

export type Props = ReactPlayerProps & VideoViewerProps;

const ReactPlayer = DefReactPlayer as unknown as React.FC<ReactPlayerProps>

const VideoViewer = forwardRef<any, Props>(
    ({ className = '', withAspectRatio = true, aspectRationX = 16, aspectRationY = 9, ...props }, ref) => {
        const getPaddingFromAspectRation = () => {
            if (aspectRationY === 0 || aspectRationX === 0 || !withAspectRatio) {
                return '0';
            }

            return `${(aspectRationY / aspectRationX) * 100}%`;
        };

        return (
            <Wrapper className={className} aspectRationPadding={getPaddingFromAspectRation()}>
                <ReactPlayer className="react-player" controls={true} ref={ref} {...props} />
            </Wrapper>
        );
    }
);

export default VideoViewer;
