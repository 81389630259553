import { useCallback, useState } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldReturnValue, DefaultFormFieldProps, ValidatorFunction, ValidatorResult } from '../types';

export interface UseCustomSelectFormFieldReturnValue<Value> {
    handleChange: (value: Value) => void;
    handleClose: () => void;
}

export interface UseCustomSelectFormFieldProps<Value> {
    formatValue: (value: Value) => string;
}

export type ReturnValue<Value = any> = DefaultFormFieldReturnValue<Value> & UseCustomSelectFormFieldReturnValue<Value>;
export type Props<Value> = DefaultFormFieldProps<string, Value> & UseCustomSelectFormFieldProps<Value>;

function useCustomSelectFormField<Value>({
    id,
    initialValue,
    validators,
    formatValue,
}: Props<Value>): ReturnValue<Value> {
    const [value, setValue] = useState<Value>(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (val: Value) => {
            setValue(val);
            setError(await checkValidators(formatValue(val), validators));
        },
        [validators]
    );

    const handleClose = useCallback(async () => {
        setError(await checkValidators(formatValue(value), validators));
    }, [value, validators]);

    const hasError = useCallback(async () => {
        const err = await checkValidators(formatValue(value), validators);
        setError(err);

        return !!err;
    }, [value, validators]);

    const reset = () => {
        setValue(initialValue);
    };

    return {
        id,
        value,
        error,
        reset,
        hasError,
        handleChange,
        handleClose,
    };
}

export default useCustomSelectFormField;
