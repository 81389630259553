import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringShouldBeURLMessage = 'URL is not valid';
const webURLRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const getStringWebURLValidator: GetValidatorFunction<{ stringShouldBeURLMessage?: string }, string> = (
    options = { stringShouldBeURLMessage: defaultStringShouldBeURLMessage }
) => {
    const { stringShouldBeURLMessage } = options;

    webURLRegex.lastIndex = 0;
    return async (value) => {
        if (webURLRegex.test(value)) {
            // String is valid web URL
            return null;
        } else {
            // String is NOT valid web URL
            if (stringShouldBeURLMessage) {
                return stringShouldBeURLMessage;
            } else {
                return defaultStringShouldBeURLMessage;
            }
        }
    };
};

export default getStringWebURLValidator;
