import { AvatarInformation } from '@app/components/AvatarStack/types';
import { Button, Card, CardProps, Colors, Icon, Intent, Popover, Tag, Tooltip } from '@blueprintjs/core';
import AvatarStack from '@components/AvatarStack';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import DeadlineTag from '@containers/components/TaskDeadlineTag';
import TaskStateTag from '@containers/components/TaskStateTag';
import { Endpoints, imageHashPreview, taskStateInformation } from '@data/consts';
import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { OpenTaskListData } from 'dy-frontend-shared/lib/data/types';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import moment from 'moment';
import React, { forwardRef, HTMLAttributes } from 'react';

export interface OpenTaskCardProps {
    hideClientInformation?: boolean;
    task: TaskResource;
    taskData: OpenTaskListData;
    headerRenderer?: () => JSX.Element | null;
    controlMenuRenderer?: () => JSX.Element | null;
}

export type Props = OpenTaskCardProps & HTMLAttributes<HTMLDivElement> & CardProps;

const OpenTaskCard = forwardRef<HTMLDivElement, Props>(
    ({ hideClientInformation = false, task, taskData, style, headerRenderer, controlMenuRenderer, ...props }, ref) => {
        const renderCategory = () => {
            return (
                <RouterLink to={Endpoints.TASK_CATEGORY.replace(':taskCategoryId', task.category.id)}>
                    <DevText muted>
                        <Icon className="mr-1" icon="new-grid-item" size={15} />
                        {task.category.title}
                    </DevText>
                </RouterLink>
            );
        };

        const renderTitle = () => {
            let taskLink = Endpoints.TASK_MESSAGES;

            // If task delivered or staff is not check task information yet then go to information page
            if ([TaskState.DRAFT, TaskState.PUBLISHED, TaskState.DELIVERED].includes(task.state)) {
                taskLink = Endpoints.TASK_INFORMATION;
            }

            return (
                <RouterLink color={Colors.WHITE} to={taskLink.replace(':taskId', task.id)}>
                    <Heading type="h5">{task.title}</Heading>
                </RouterLink>
            );
        };

        const renderClientInformation = () => {
            if (hideClientInformation) {
                return null;
            }
            const user = taskData.users[task.user_id];

            if (!user) {
                return null;
            }

            let linkText = `${user.first_name} ${user.last_name}`;

            // If company name exist, then use it as a text for a link
            if (user.company_name.trim().length !== 0) {
                linkText += ` (${user.company_name})`;
            }

            return (
                <div className="mr-3">
                    <RouterLink
                        to={Endpoints.CLIENT_TASKS.replace(':clientId', user.id)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <DevText color={Colors.WHITE}>
                            <Icon icon="person" className="mr-small mb-unit" size={15} />
                            {linkText}
                        </DevText>
                    </RouterLink>

                    {/* TODO: do pausing whenever it's implemented on backend */}
                    {/* <SubscriptionPausedTag className="mr-1" restoreAt="2023-07-06T17:53:23Z" /> */}
                </div>
            );
        };

        const renderParticipants = () => {
            if (task.participants.length === 0) {
                return (
                    <DevText color={Colors.ORANGE4}>
                        <Icon icon="warning-sign" className="mr-1" />
                        No participants assigned
                    </DevText>
                );
            }

            const avatars: AvatarInformation[] = [];
            task.participants.forEach((participant) => {
                // Get user
                const user = taskData.participant_users[participant.user_id];

                if (user) {
                    // Get avatar src
                    let avatarSrc: string | null = null;
                    if (user.image_hash) {
                        avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
                    }

                    avatars.push({
                        id: user.id,
                        name: `${user.first_name} ${user.last_name}`,
                        src: avatarSrc,
                    });
                }
            });

            return <AvatarStack tight={false} avatars={avatars} avatarSize={30} />;
        };

        const renderPausedHeader = () => {
            return (
                <Flex direction="row" align="center">
                    <Tooltip content={`Before pause, request was in "${taskStateInformation[task.state].title}" state`}>
                        <Tag large={false} className="mr-small" icon="pause" intent={Intent.WARNING}>
                            Paused
                        </Tag>
                    </Tooltip>

                    <DevText muted>
                        Paused {moment(task.paused_at).format('D MMM, YYYY')} ({moment(task.paused_at).fromNow()})
                    </DevText>
                </Flex>
            );
        };

        const renderHeader = () => {
            if (task.paused_at !== null) {
                // Task is paused
                return renderPausedHeader();
            }

            const renderLockedTag = () => {
                if (!task.is_queue_automation_locked) {
                    // Not locked
                    return null;
                }

                return (
                    <Tooltip content="Request won't be moved automatically">
                        <Tag large={false} className="mr-1" icon="lock" intent={Intent.PRIMARY}>
                            Locked
                        </Tag>
                    </Tooltip>
                );
            };

            const renderPriorityTag = () => {
                if (!task.is_priority_elevated) {
                    // Normal priority
                    return null;
                }

                return (
                    <Tag minimal className="mr-1" icon="generate" intent={Intent.DANGER}>
                        Priority
                    </Tag>
                );
            };

            const renderControls = () => {
                if (!controlMenuRenderer) {
                    // No controls provided
                    return null;
                }

                const controlMenuContent = controlMenuRenderer();
                if (!controlMenuContent) {
                    return null;
                }

                return (
                    <Popover content={controlMenuContent}>
                        <Button minimal icon="more" />
                    </Popover>
                );
            };

            const renderStateTag = () => {
                if (task.queue === TaskQueue.BACKLOG) {
                    return null;
                }

                return <TaskStateTag className="mr-1" withCircle={false} large={false} state={task.state} />;
            };

            return (
                <Flex direction="row" align="center" justify="space-between">
                    <Flex direction="row" align="center">
                        {renderLockedTag()}
                        {renderStateTag()}
                        {renderPriorityTag()}
                        {/* TODO: finalized at date */}
                        <DeadlineTag deadlineAt={task.deadline_at} finalizedAt={null} />
                    </Flex>

                    {renderControls()}
                </Flex>
            );
        };

        const renderPublishInformation = () => {
            if (!task.publish) {
                // Could NOT find publish information
                return null;
            }

            const finalizedAtFormatted = moment(task.publish.finalized_at).format('D MMM, YYYY');

            switch (task.publish.type) {
                case TaskPublishType.MANUAL:
                    return (
                        <DevText muted>
                            <Icon icon="add-location" className="mr-1" size={15} />
                            Published manually: {finalizedAtFormatted}
                        </DevText>
                    );
                case TaskPublishType.CREDIT:
                    return (
                        <DevText muted>
                            <Icon icon="layout-circle" className="mr-1" size={15} />
                            Published via credits: {finalizedAtFormatted}
                        </DevText>
                    );
                case TaskPublishType.PAYMENT:
                    return (
                        <DevText muted>
                            <Icon icon="bank-account" className="mr-1" size={15} />
                            Published via payment: {finalizedAtFormatted}
                        </DevText>
                    );
                case TaskPublishType.QUOTA:
                    return (
                        <DevText muted>
                            Published: {finalizedAtFormatted}
                        </DevText>
                    );
            }
        };

        return (
            <Card compact ref={ref} style={{ height: '100%', ...style }} {...props}>
                <Flex fullHeight direction="column" align="stretch" justify="space-between">
                    {headerRenderer?.()}

                    {/* (Paused + Lock + State) tags + Deadline + Controls */}
                    <div className="mb-1">{renderHeader()}</div>

                    {/* Title */}
                    <div className="mb-1">{renderTitle()}</div>

                    {/* Client + Category */}
                    <Flex className="mb-2" direction="row" align="center">
                        {renderClientInformation()}
                        {renderCategory()}
                    </Flex>

                    {/* Participants + Publish info */}
                    <Flex direction="row" justify="space-between" align="center">
                        {renderParticipants()}
                        {renderPublishInformation()}
                    </Flex>
                </Flex>
            </Card>
        );
    }
);

export default OpenTaskCard;
