const commonAvatarColors = [
    '#B83211',
    '#F5498B',
    '#7C327C',
    '#5642A6',
    '#0C5174',
    '#007067',
    '#1D7324',
    '#8EB125',
    '#D1980B',
    '#946638',
];

export default commonAvatarColors;
