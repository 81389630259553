import { Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { $task } from '@pages/Task/store/states';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';

export type Props = HTMLAttributes<HTMLDivElement>;

const DesignExtensions: React.FC<Props> = (props) => {
    const task = useStore($task);

    if (!task) {
        return null;
    }

    const renderDeliverableExtensions = () => {
        let deliverableExtensionsContent;

        // Task deliverable extensions were NOT provided
        if (task.extensions.length === 0) {
            deliverableExtensionsContent = <DevText muted>Not provided</DevText>;
        } else {
            deliverableExtensionsContent = (
                <Flex align="center">
                    {task.extensions.map((extension) => (
                        <Tag className="mr-small" key={extension}>
                            {extension.toUpperCase()}
                        </Tag>
                    ))}
                </Flex>
            );
        }

        return (
            <div>
                <DevText muted className="mb-small">
                    Deliverables
                </DevText>

                {deliverableExtensionsContent}
            </div>
        );
    };

    const renderSourceFileExtensions = () => {
        let sourceFileExtensionsContent;

        // Task source file extensions were NOT provided
        if (task.source_extensions.length === 0) {
            sourceFileExtensionsContent = <DevText muted>Not provided</DevText>;
        } else {
            sourceFileExtensionsContent = (
                <Flex align="center">
                    {task.source_extensions.map((sourceExtension) => (
                        <Tag className="mr-small" key={sourceExtension}>
                            {sourceExtension.toUpperCase()}
                        </Tag>
                    ))}
                </Flex>
            );
        }

        return (
            <div>
                <DevText muted className="mb-small">
                    Source files
                </DevText>

                {sourceFileExtensionsContent}
            </div>
        );
    };

    return (
        <div {...props}>
            <Heading type="h4" className="mb-2">
                Design extensions
            </Heading>
            <Grid container>
                <Grid lg={6}>{renderDeliverableExtensions()}</Grid>
                <Grid lg={6}>{renderSourceFileExtensions()}</Grid>
            </Grid>
        </div>
    );
};

export default DesignExtensions;
