import { repository } from 'dy-frontend-http-repository/lib/modules';
import {
    UpdateLicencePlanInput,
} from 'dy-frontend-http-repository/lib/modules/Licence/inputs';
import { LicenceRef } from 'dy-frontend-http-repository/lib/modules/Licence/refs';
import domain from './domain';

// Update licence plan
export const updateLicencePlan = domain.createEffect<{ id: ID; input: UpdateLicencePlanInput }, LicenceRef>({
    name: 'updateLicencePlan',
    handler: async ({ id, input }) => {
        return await repository.licence().updatePlan(id, input);
    },
});
