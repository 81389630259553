import { $permissions } from '@app/containers/store/states';
import { Button, Card, Colors, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { TeamParticipationResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { useRemoveTeamParticipant, useUpdateTeamParticipantRole } from '../../hooks';
import { $team } from '../../store/states';
import { TeamPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const ManagersInformation: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { removeTeamParticipant } = useRemoveTeamParticipant();
    const { updateTeamParticipantRole } = useUpdateTeamParticipantRole();

    const team = useStore($team);

    const [userIdPendingActionResult, setUserIdPendingActionResult] = useState<ID | null>(null);

    if (!team) {
        return null;
    }

    const handleRemoveTeamParticipant = (userId: ID) => {
        setUserIdPendingActionResult(userId);

        removeTeamParticipant({ teamId: team.id, userId })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            })
            .finally(() => setUserIdPendingActionResult(null));
    };

    const handleUpdateTeamParticipant = (userId: ID, role: TeamParticipationRole) => {
        setUserIdPendingActionResult(userId);

        updateTeamParticipantRole({ teamId: team.id, userId, role })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            })
            .finally(() => setUserIdPendingActionResult(null));
    };

    const managerRoles = [TeamParticipationRole.MANAGER];
    const managers = team.participants.filter((participant) => managerRoles.includes(participant.role));

    const renderManagerControlsButton = ({ user }: TeamParticipationResource) => {
        const isManagementAllowed = permissions.isRoot.team || permissions.has(TeamPermission.PARTICIPATION_MANAGE);
        if (!isManagementAllowed) {
            return null;
        }

        const renderManagerControlsMenu = () => {
            const renderPromoteManagerToTeamLeadMenuItem = () => {
                // Get lead participant
                const leadParticipant = team.participants.find(
                    (participant) => participant.role === TeamParticipationRole.LEAD,
                );

                if (leadParticipant) {
                    return null;
                }

                return (
                    <MenuItem
                        disabled={userIdPendingActionResult !== null}
                        icon="star"
                        text="Promote to team lead"
                        onClick={() => handleUpdateTeamParticipant(user.id, TeamParticipationRole.LEAD)}
                    />
                );
            };

            const renderDowngradeManagerToMemberMenuItem = () => {
                return (
                    <MenuItem
                        disabled={userIdPendingActionResult !== null}
                        icon="inherited-group"
                        text="Downgrade to member"
                        onClick={() => handleUpdateTeamParticipant(user.id, TeamParticipationRole.MEMBER)}
                    />
                );
            };

            const renderRemoveManagerFromTeamMenuItem = () => {
                return (
                    <MenuItem
                        disabled={userIdPendingActionResult !== null}
                        text="Remove from team"
                        icon="trash"
                        intent={Intent.DANGER}
                        onClick={() => handleRemoveTeamParticipant(user.id)}
                    />
                );
            };

            return (
                <Menu>
                    {renderPromoteManagerToTeamLeadMenuItem()}
                    {renderDowngradeManagerToMemberMenuItem()}
                    {renderRemoveManagerFromTeamMenuItem()}
                </Menu>
            );
        };

        return (
            <Popover content={renderManagerControlsMenu()}>
                <Button
                    disabled={userIdPendingActionResult !== null}
                    loading={userIdPendingActionResult === user.id}
                    minimal
                    icon="more"
                />
            </Popover>
        );
    };

    const renderManagerItem = (teamParticipation: TeamParticipationResource) => {
        const { user, role } = teamParticipation;

        // Get avatar src
        let avatarSrc: string | null = null;
        if (user.image_hash) {
            avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
        }

        return (
            <div className="mt-2" key={`manager-${user.id}`}>
                <Flex align="center" justify="space-between">
                    <Flex direction="row">
                        <Avatar width="42px" height="42px" className="mr-1" src={avatarSrc} alt={user.first_name} />
                        <Flex align="flex-start" direction="column">
                            <RouterLink className="mr-1" color={Colors.WHITE}
                                        to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', user.id)}>
                                {user.first_name} {user.last_name}
                            </RouterLink>
                            <DevText muted>{TextFormatUtils.capitalize(role)}</DevText>
                        </Flex>
                    </Flex>

                    {renderManagerControlsButton(teamParticipation)}
                </Flex>
            </div>
        );
    };

    const renderManagersList = () => {
        if (managers.length === 0) {
            return <DevText muted>No managers assigned</DevText>;
        }

        return managers.map(renderManagerItem);
    };

    return (
        <Card {...props}>
            <Heading className="mb-2" type="h4">
                Managers
            </Heading>

            {renderManagersList()}
        </Card>
    );
};

export default ManagersInformation;
