import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { Colors, Icon, IconName, Tooltip } from '@blueprintjs/core';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface NotificationTemplateProps {
    // Icon associated with notification
    icon: IconName;

    // Notification title
    title: string;

    // When notification occurred
    at: string;

    // Notification content
    content: React.ReactNode;
}

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'content'> & NotificationTemplateProps;

const NotificationTemplate: React.FC<Props> = ({ icon, title, at, content, ...props }) => {
    return (
        <div {...props}>
            <Flex className="mb-1" direction="row" justify="space-between" align="center">
                <Flex direction="row" align="center">
                    <Icon className="mr-1" icon={icon} color={Colors.GRAY3} />
                    <DevText muted>{title}</DevText>
                </Flex>

                <Tooltip content={moment(at).fromNow()}>
                    <DevText muted>{moment(at).format('HH:mm')}</DevText>
                </Tooltip>
            </Flex>

            {content}
        </div>
    );
};

export default NotificationTemplate;
