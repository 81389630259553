import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { CreateCreditWalletTransactionInput } from 'dy-frontend-http-repository/lib/modules/CreditWallet/inputs';
import { CreditWalletRef, CreditWalletTransactionRef } from 'dy-frontend-http-repository/lib/modules/CreditWallet/refs';
import {
    CreditWalletResource,
    CreditWalletTransactionResource,
} from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import domain from './domain';

// Get credit wallet associated with user
export const fetchCreditWalletForUser = domain.createEffect<ID, CreditWalletResource>({
    name: 'fetchCreditWalletForUser',
    handler: async (userId) => {
        return await repository.creditWallet().getForUser(userId);
    },
});

// Get credit wallet associated with workspace
export const fetchCreditWalletForWorkspace = domain.createEffect<ID, CreditWalletResource>({
    name: 'fetchCreditWalletForWorkspace',
    handler: async (workspaceId) => {
        return await repository.creditWallet().getForWorkspace(workspaceId);
    },
});

// Create and commit new transaction to wallet
export const createCreditWalletTransaction = domain.createEffect<
    { creditWalletId: ID; input: CreateCreditWalletTransactionInput },
    CreditWalletTransactionRef
>({
    name: 'createCreditWalletTransaction',
    handler: async ({ creditWalletId, input }) => {
        return await repository.creditWallet().createTransaction(creditWalletId, input);
    },
});

// Fetch list of credit wallet transactions
export const fetchCreditWalletTransactions = domain.createEffect<
    { creditWalletId: ID; pagination: OffsetPaginationInput },
    CollectionResource<CreditWalletTransactionResource, {}>
>({
    name: 'fetchCreditWalletTransactions',
    handler: async ({ creditWalletId, pagination }) => {
        return await repository.creditWallet().getTransactions(creditWalletId, pagination);
    },
});
