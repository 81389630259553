import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    PickableTeamParticipantUserFilterInput,
    UpdateTeamInformationInput,
} from 'dy-frontend-http-repository/lib/modules/Team/inputs';
import { TeamRef } from 'dy-frontend-http-repository/lib/modules/Team/refs';
import {
    PickableTeamParticipantUserResource,
    TeamResource,
} from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { TeamOccupancyFilterInput } from 'dy-frontend-http-repository/lib/modules/TeamOccupancy/inputs';
import { TeamOccupancyResource } from 'dy-frontend-http-repository/lib/modules/TeamOccupancy/resources';
import { UserOccupancyFilterInput } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/inputs';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';

// Fetch team pickable participant users
export const fetchPickableTeamParticipantUsers = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: PickableTeamParticipantUserFilterInput },
    CollectionResource<PickableTeamParticipantUserResource, {}>
>({
    name: 'fetchPickableTeamParticipantUsers',
    handler: async ({ pagination, filter }) => {
        return await repository.team().getPickableParticipantUsers(pagination, filter);
    },
});

// Assign users as a participant
export const assignTeamParticipantUsers = domain.createEffect<
    {
        teamId: ID;
        userIds: ID[];
        role: TeamParticipationRole;
    },
    TeamRef
>({
    name: 'assignTeamParticipantUsers',
    handler: async ({ teamId, userIds, role }) => {
        return await repository.team().assignParticipantUsers(teamId, {
            user_ids: userIds,
            role: role,
        });
    },
});

// Fetch team
export const fetchTeam = domain.createEffect<ID, TeamResource>({
    name: 'fetchTeam',
    handler: async (teamId) => {
        return await repository.team().getById(teamId);
    },
});

// Update team
export const updateTeamInformation = domain.createEffect<
    {
        teamId: ID;
        input: UpdateTeamInformationInput;
    },
    TeamRef
>({
    name: 'updateTeamInformation',
    handler: async ({ teamId, input }) => {
        return await repository.team().update(teamId, input);
    },
});

// Archive team
export const archiveTeam = domain.createEffect<ID, TeamRef>({
    name: 'archiveTeam',
    handler: async (teamId) => {
        return await repository.team().archive(teamId);
    },
});

// Restore team
export const restoreTeam = domain.createEffect<ID, TeamRef>({
    name: 'restoreTeam',
    handler: async (teamId) => {
        return await repository.team().restore(teamId);
    },
});

// Remove user from team participants
export const removeTeamParticipant = domain.createEffect<{ teamId: ID; userId: ID }, TeamRef>({
    name: 'removeTeamParticipant',
    handler: async ({ teamId, userId }) => {
        return await repository.team().removeParticipation(teamId, userId);
    },
});

// Update user team participation
export const updateTeamParticipant = domain.createEffect<
    {
        teamId: ID;
        userId: ID;
        role: TeamParticipationRole;
    },
    TeamRef
>({
    name: 'updateTeamParticipant',
    handler: async ({ teamId, userId, role }) => {
        return await repository.team().updateParticipation(teamId, userId, {
            role: role,
        });
    },
});

// Fetch team occupancy
export const fetchTeamOccupancy = domain.createEffect<
    TeamOccupancyFilterInput,
    CollectionResource<TeamOccupancyResource, {}>
>({
    name: 'fetchTeamOccupancy',
    handler: async (filter) => {
        return await repository.teamOccupancy().get(filter);
    },
});

// Fetch team members occupancy
export const fetchUserOccupancy = domain.createEffect<
    UserOccupancyFilterInput,
    CollectionResource<UserOccupancyResource, {}>
>({
    name: 'fetchUserOccupancy',
    handler: async (filter) => {
        return await repository.userOccupancy().get(filter);
    },
});
