import repository from 'dy-frontend-http-repository/lib/repository';
import { SetTaskComplexityParametersInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';

// Update task complexity
export const updateTaskComplexity = domain.createEffect<
    { taskId: ID; input: SetTaskComplexityParametersInput },
    TaskRef
>({
    name: 'updateTaskComplexity',
    handler: async ({ taskId, input }) => {
        return await repository.task().setTaskComplexity(taskId, input);
    },
});
