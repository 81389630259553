import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { $client } from '@app/containers/pages/Client/store/states';
import { Card } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useMemo, useState } from 'react';
import Circle from '@components/Circle';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { imageHashPreview } from '@app/data/consts';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import AvatarUploaderNEW from '@app/components/AvatarUploaderNEW';
import { getFileAllowedTypesValidator, getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import ImageCropperModal, { ImageCropperModalProps } from '@app/containers/modals/ImageCropperModal';
import { openModal } from '@app/containers/modals/store/events';
import { clientApi } from '@app/containers/pages/Client/store/apis';
import { removeClientImage, uploadAndSetClientImage } from '@app/containers/pages/Client/store/effects';
import PlanTag from '@app/containers/components/PlanTag';
import { LicenceState } from 'dy-frontend-http-repository/lib/data/enums';
import LicenceTag from '@app/containers/components/LicenceTag';

const clientAvatarValidators = [
    getFileAllowedTypesValidator({ allowedTypes: ['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'] }),
    getFileMaxSizeValidator({ maxSize: 25000000 }),
];

export type Props = HTMLAttributes<HTMLDivElement>;

const ClientBadge: React.FC<Props> = ({ ...props }) => {
    const client = useStore($client);

    const [isUploading, setIsUploading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    if (!client) {
        // Client is NOT initialized yet
        return null;
    }

    const handleRemoveImage = async () => {
        setIsRemoving(true);

        try {
            const userRef = await removeClientImage(client.id);
            clientApi.removeImage();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemoving(false);
        }
    };

    const handleUploadImage = async (file: File) => {
        setIsUploading(true);

        try {
            const userImageHashRef = await uploadAndSetClientImage({ id: client.id, file: file });
            clientApi.setImageHash(userImageHashRef);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSelectFile = (file: File) => {
        const isImage = file.type.split('/')[0] === 'image';
        if (!isImage) {
            alert('Not an image');
            return;
        }

        const isSvg = file.type === 'image/svg+xml';

        // Non-svg files can be adjusted
        if (!isSvg) {
            openModal<ImageCropperModalProps>({
                ModalComponent: ImageCropperModal,
                data: {
                    inputImageUrl: URL.createObjectURL(file),
                    aspect: 1,
                    shape: 'round',
                    onCropComplete: handleUploadImage,
                },
            });
            return;
        }

        // Svg are uploaded directly
        handleUploadImage(file);
    };

    const renderAvatar = () => {
        // Get avatar alt
        const avatarAlt = `${client.first_name} ${client.last_name}`;

        // Get avatar src
        let avatarSrc: string | null = null;
        if (client.image_hash) {
            avatarSrc = imageHashPreview.userImage(client.image_hash, ImageHashPreviewSize.SM);
        }

        return (
            <AvatarUploaderNEW
                loading={isUploading || isRemoving}
                width="70px"
                height="70px"
                validators={clientAvatarValidators}
                avatarProps={{
                    alt: avatarAlt,
                    src: avatarSrc,
                }}
                fileUploadProgressProps={{
                    spinnerProps: {
                        size: 40,
                    },
                }}
                onFileSelect={handleSelectFile}
                onFileRemove={handleRemoveImage}
            />
        );
    };

    const renderCompanyPosition = () => {
        // Check if company position no empty
        if (client.company_position.trim().length === 0) {
            return null;
        }

        return <DevText muted>{client.company_position}</DevText>;
    };

    const renderPlans = () => {
        let content;

        if (client.licences.length === 0) {
            content = <DevText muted>Pay-as-you-go</DevText>;
        } else {
            content = client.licences.map((licence, index) => {
                const plan = licence.plan;

                return (
                    <LicenceTag
                        suspended={licence.state === LicenceState.SUSPENDED}
                        id={plan.id}
                        className={index === 0 ? '' : 'mt-1'}
                        key={licence.id}
                        name={plan.title}
                        color={plan.color}
                        quantity={licence.quantity}
                    />
                );
            });
        }

        return <div className="mb-2">{content}</div>;
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center">
                <div className="mr-1">{renderAvatar()}</div>

                <Flex direction="column">
                    <Heading className="mb-small" type="h4">
                        {client.first_name} {client.last_name}
                    </Heading>
                    <DevText>{client.company_name}</DevText>

                    {renderCompanyPosition()}
                </Flex>
            </Flex>

            <div>
                {renderPlans()}

                <Flex className="mb-1" align="center" justify="space-between">
                    <DevText muted>Last activity:</DevText>
                    {/* TODO: replace with last_activity date whenever implemented on backend */}
                    <DevText className="ml-1">{moment(client.created_at).format('D MMMM YYYY')}</DevText>
                </Flex>

                <Flex align="center" justify="space-between">
                    <DevText muted>Registered:</DevText>
                    <DevText className="ml-1">{moment(client.created_at).format('D MMMM YYYY')}</DevText>
                </Flex>
            </div>
        </Card>
    );
};

export default ClientBadge;
