import React, { HTMLAttributes } from 'react';
import { StyledCircle } from './styled';

// TODO: can be removed and replaced with Box components or updated with use of Box component

export interface CircleProps {
    color: string;
    borderColor?: string;
    size?: string;
}

export type Props = CircleProps & HTMLAttributes<HTMLSpanElement>;

const Circle: React.FC<Props> = ({ color, borderColor, size = '16px', ...props }) => {
    return <StyledCircle $size={size} $color={color} $borderColor={borderColor} {...props} />;
};

export default Circle;
