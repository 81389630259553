import { fetchTasks } from './effects';
import { $page, $tasks, $isQueryHydrationFinished, $platformTaskFilterData } from './states';
import { setIsQueryHydrationFinished, setPlatformTaskFilterData, setPage } from './events';

// Set page
$page.on(setPage, (_, data) => data);

// Tasks fetch
$tasks.on(fetchTasks.doneData, (_, data) => data);

// Query hydration finished flag
$isQueryHydrationFinished.on(setIsQueryHydrationFinished, (_, data) => data);

// Platform task filter data
$platformTaskFilterData.on(setPlatformTaskFilterData, (_, data) => data);
