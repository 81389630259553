import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { createApi } from 'effector';
import { $taskCategoryAddons } from './states';

// Api to manage task category addons
export const taskCategoryAddonsApi = createApi($taskCategoryAddons, {
    initialize: (
        store,
        payload: {
            taskCategoryAddons: TaskCategoryAddonResource[];
            excludeIds: ID[];
        }
    ) => {
        return payload.taskCategoryAddons.filter((addon) => !payload.excludeIds.includes(addon.id));
    },
});
