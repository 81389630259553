import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription update schedule present
 * @param subscription subscription for which update schedule should be checked
 * @returns {boolean} subscription schedule present flag
 */
export default function checkIfSubscriptionUpdateScheduled(subscription: SubscriptionResource) {
    return subscription.schedule.is_present;
}
