import { $clientPaymentAccount } from '@app/containers/pages/Client/store/states';
import { Button, Card, Icon, Spinner, Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import DevText from '@components/Text';
import { InvoiceResource } from 'dy-frontend-http-repository/lib/modules/Invoice/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import {
    amountOfClientInvoicesOnPage,
    invoiceBillingReasonInformation,
    invoiceStateDescriptionInformation,
    invoiceStateInformation,
} from '../../consts';
import { fetchInvoices } from '../../store/effects';
import { $invoices } from '../../store/states';

export type Props = HTMLAttributes<HTMLDivElement>;

const Invoices: React.FC<Props> = (props) => {
    const clientPaymentAccount = useStore($clientPaymentAccount);
    const clientInvoices = useStore($invoices);
    const isFetchingClientInvoices = useStore(fetchInvoices.pending);

    const [page, setPage] = useState(1);

    const handleLoadInvoicesPage = async (newPage: number) => {
        if (!clientPaymentAccount) return;

        const pageOffset = (newPage - 1) * amountOfClientInvoicesOnPage;

        try {
            await fetchInvoices({
                pagination: {
                    _pagination: { limit: amountOfClientInvoicesOnPage, offset: pageOffset },
                },
                filter: { payment_account_id: clientPaymentAccount.id },
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    useEffect(() => {
        handleLoadInvoicesPage(page);

        // eslint-disable-next-line
    }, [page]);

    const renderInvoice = (invoice: InvoiceResource) => {
        return (
            <Card compact className="mt-2" key={invoice.id}>
                <Flex justify="space-between">
                    <div>
                        <Flex align="center">
                            <Tag className="mr-1">{invoiceStateInformation[invoice.state]}</Tag>

                            <Heading type="h6" className="mr-1">
                                {invoiceBillingReasonInformation[invoice.billing_reason]}
                            </Heading>

                            <DevText muted>
                                {PriceUtils.formatPrice({ price: invoice.total, shouldDisplayCents: true })}
                            </DevText>
                        </Flex>

                        <DevText muted>{invoiceStateDescriptionInformation[invoice.state]}</DevText>
                        <DevText>
                            {moment(invoice.created_at).format('D MMMM YYYY, HH:mm')} (
                            {moment(invoice.created_at).fromNow()})
                        </DevText>
                        <DevText muted>{invoice.external_id}</DevText>
                    </div>

                    <Flex>
                        {invoice.pdf_url && (
                            <Button
                                icon="download"
                                onClick={() => {
                                    const openedWindow = window.open(invoice.pdf_url as string);

                                    openedWindow?.focus();
                                }}
                            >
                                PDF
                            </Button>
                        )}
                        {invoice.hosted_url && (
                            <Button className="ml-1" onClick={() => window.open(invoice.hosted_url as string)}>
                                View
                            </Button>
                        )}
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderInvoices = () => {
        // Client invoices were NOT fetched yet
        if (!clientInvoices) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client invoices were fetched, but where are no invoices existed for this client
        if (clientInvoices.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client invoices were found
                        </Heading>
                    }
                />
            );
        }

        return <div>{clientInvoices.items.map(renderInvoice)}</div>;
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (clientInvoices === null) {
            return null;
        }

        // Check if offset paginator exist
        if (clientInvoices.paginator === null) {
            return;
        }

        // Only one page exist
        if (!clientInvoices.paginator.has_more && clientInvoices.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex className="mt-2" justify="flex-end">
                <Pagination
                    fetching={isFetchingClientInvoices}
                    hasMore={clientInvoices.paginator.has_more}
                    page={page}
                    amountOfItemsOnPage={amountOfClientInvoicesOnPage}
                    totalItems={clientInvoices.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div {...props}>
            <Heading type="h3">Invoices</Heading>

            {renderInvoices()}
            {renderPagination()}
        </div>
    );
};

export default Invoices;
