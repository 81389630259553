import {
    fetchSubscriptionPlans,
    fetchUserPaymentAccount,
    fetchWorkspacePaymentAccount,
    fetchPaymentMethods,
} from './effects';
import { $paymentAccount, $subscriptionPlans, $paymentMethods } from './states';

// Fetch user payment account
$paymentAccount.on(fetchWorkspacePaymentAccount.doneData, (_, data) => data);

// Fetch payment methods
$paymentMethods.on(fetchPaymentMethods.doneData, (_, data) => data);

// Fetch user payment account
$paymentAccount.on(fetchUserPaymentAccount.doneData, (_, data) => data);

// Fetch subscription plans
$subscriptionPlans.on(fetchSubscriptionPlans.doneData, (_, data) => data);
