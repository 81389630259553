import { Intent, Toaster } from '@blueprintjs/core';
import { teamApi } from '../../store/apis';
import { restoreTeam } from '../../store/effects';

const useRestoreTeam = () => {
    const handleRestoreTeam = async ({ teamId }: { teamId: ID }) => {
        try {
            const restoreTeamResponse = await restoreTeam(teamId);

            teamApi.restoreTeam();
        } catch (e) {
            throw e;
        }
    };

    return { restoreTeam: handleRestoreTeam };
};

export default useRestoreTeam;
