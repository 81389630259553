import { $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import UpdateTaskCategoryAttachedGroups, {
    UpdateTaskCategoryAttachedGroupsModalProps,
} from '../../modals/UpdateTaskCategoryAttachedGroupsModal';
import { $taskCategory } from '../../store/states';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';
import TaskCategoryGroupTag from '@app/containers/components/TaskCategoryGroupTag';

export type Props = HTMLAttributes<HTMLDivElement>;

const Groups: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);

    if (!taskCategory) {
        return null;
    }

    const renderUpdateAttachedTaskCategoryGroups = () => {
        const isAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.GROUP_MANAGE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateTaskCategoryAttachedGroupsModalProps>({
                        ModalComponent: UpdateTaskCategoryAttachedGroups,
                        data: {
                            taskCategoryId: taskCategory.id,
                            selectedTaskCategoryGroupIds: taskCategory.groups.map((group) => group.id),
                        },
                    })
                }
            >
                Edit
            </Button>
        );
    };

    const renderTaskCategoryGroupList = () => {
        if (taskCategory.groups.length === 0) {
            return <DevText muted>No groups attached yet</DevText>;
        }

        return taskCategory.groups.map((group, index) => (
            <TaskCategoryGroupTag
                key={group.id}
                className={index === 0 ? '' : 'mt-1'}
                color={group.color}
                name={group.title}
            />
        ));
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Groups</Heading>
                {renderUpdateAttachedTaskCategoryGroups()}
            </Flex>

            {renderTaskCategoryGroupList()}
        </Card>
    );
};

export default Groups;
