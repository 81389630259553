import { Button, Card, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import IconTextBlock from '@components/IconTextBlock';
import DevText from '@components/Text';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React from 'react';
import { setSelectedTaskDeliverable } from '../../store/events';
import { $selectedTaskDeliverable } from '../../store/states';
import { Wrapper } from './styled';

export interface TaskDeliverableFooterProps {
    taskDeliverables: TaskDeliverableResource[];
}

type Props = TaskDeliverableFooterProps;

const TaskDeliverableFooter: React.FC<Props> = ({ taskDeliverables, ...props }) => {
    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    if (!selectedTaskDeliverable) {
        return null;
    }

    const selectedTaskDeliverableIndex = taskDeliverables.findIndex((d) => d.id === selectedTaskDeliverable.id);

    const renderSelectPreviousTaskDeliverableButton = () => {
        const isCurrentSelectedTaskDeliverableFirst = selectedTaskDeliverableIndex === 0;

        const handleSelectPreviousTaskDeliverable = () => {
            if (isCurrentSelectedTaskDeliverableFirst) {
                // First element
                setSelectedTaskDeliverable(taskDeliverables[taskDeliverables.length - 1]);
                return;
            }

            setSelectedTaskDeliverable(taskDeliverables[selectedTaskDeliverableIndex - 1]);
        };

        return <Button minimal icon="chevron-left" onClick={handleSelectPreviousTaskDeliverable} />;
    };

    const renderTaskDeliverableCounter = () => {
        return (
            <DevText>
                {selectedTaskDeliverableIndex + 1} / {taskDeliverables.length}
            </DevText>
        );
    };

    const renderSelectNextTaskDeliverableButton = () => {
        const isCurrentSelectedTaskDeliverableLast = selectedTaskDeliverableIndex === taskDeliverables.length - 1;

        const handleSelectNextTaskDeliverable = () => {
            if (isCurrentSelectedTaskDeliverableLast) {
                // Last element
                setSelectedTaskDeliverable(taskDeliverables[0]);
                return;
            }

            setSelectedTaskDeliverable(taskDeliverables[selectedTaskDeliverableIndex + 1]);
        };

        return <Button minimal icon="chevron-right" onClick={handleSelectNextTaskDeliverable} />;
    };

    const renderSelectTaskDeliverableControls = () => {
        if (taskDeliverables.length <= 1) {
            return null;
        }

        return (
            <Flex className="ml-1" direction="row" align="center">
                <div className="mr-1">{renderSelectPreviousTaskDeliverableButton()}</div>
                <div className="mr-1">{renderTaskDeliverableCounter()}</div>
                <div>{renderSelectNextTaskDeliverableButton()}</div>
            </Flex>
        );
    };

    const renderFileInformation = () => {
        if (!selectedTaskDeliverable.file) {
            console.error('Task deliverable is of type file, but file is NOT provided');
            return null;
        }

        const handleCopyFileUrl = () => {
            if (!selectedTaskDeliverable.file) {
                return;
            }

            navigator.clipboard.writeText(selectedTaskDeliverable.file.url);
        };

        const handleDownloadFile = () => {
            if (!selectedTaskDeliverable.file) {
                return;
            }

            const win = window.open(selectedTaskDeliverable.file.url);

            if (win) {
                win.focus();
            }
        };

        return (
            <>
                <Flex align="center" justify="flex-start">
                    <IconTextBlock className="mr-2" icon="document" text={selectedTaskDeliverable.file.extension} />

                    <div>
                        <DevText>{selectedTaskDeliverable.title}</DevText>
                        <DevText muted>
                            {FileUtils.getFormattedFileSize(selectedTaskDeliverable.file.size)} -{' '}
                            {moment(selectedTaskDeliverable.created_at).format('D MMMM HH:mm')}
                        </DevText>
                    </div>
                </Flex>

                <Flex align="center" justify="flex-start">
                    <Popover
                        content={
                            <Card>
                                <DevText>Copied</DevText>
                            </Card>
                        }
                    >
                        <Button minimal icon="duplicate" className="mr-2" onClick={handleCopyFileUrl}>
                            Copy shareable link
                        </Button>
                    </Popover>

                    <Button minimal onClick={handleDownloadFile}>
                        Download
                    </Button>

                    {renderSelectTaskDeliverableControls()}
                </Flex>
            </>
        );
    };

    const renderEmbedInformation = () => {
        return (
            <Flex align="center" justify="space-between">
                <Flex direction="row" align="center">
                    {/* TODO: depending on type of embed we need to change the text "G-SLIDE"/"G-FOLDER", etc. */}
                    <IconTextBlock className="mr-2" icon="text-highlight" text="G-DRIVE" />
                    <DevText>{selectedTaskDeliverable.title}</DevText>
                </Flex>

                {renderSelectTaskDeliverableControls()}
            </Flex>
        );
    };

    const renderTextInformation = () => {
        return (
            <Flex align="center" justify="space-between">
                <Flex direction="row" align="center">
                    <IconTextBlock className="mr-2" icon="search-text" text="TEXT" />
                    <DevText>{selectedTaskDeliverable.title}</DevText>
                </Flex>

                {renderSelectTaskDeliverableControls()}
            </Flex>
        );
    };

    const renderTaskDeliverableInformation = () => {
        switch (selectedTaskDeliverable.type) {
            case TaskDeliverableType.FILE:
                return renderFileInformation();
            case TaskDeliverableType.EMBED:
                return renderEmbedInformation();
            case TaskDeliverableType.TEXT:
                return renderTextInformation();
            default:
                return null;
        }
    };

    return (
        <Wrapper className="mt-2" {...props}>
            {renderTaskDeliverableInformation()}
        </Wrapper>
    );
};

export default TaskDeliverableFooter;
