import React, { HTMLAttributes, useRef, useState } from 'react';
import { StyledFixedHeightCollapse } from './styled';

export interface FixedHeightCollapseProps {
    isCollapsed: boolean;
    maxHeight: number;
    renderCollapseControl: () => JSX.Element;
}

export type Props = HTMLAttributes<HTMLDivElement> & FixedHeightCollapseProps;

const FixedHeightCollapse: React.FC<Props> = ({
    isCollapsed,
    maxHeight,
    renderCollapseControl,
    children,
    ...props
}) => {
    const [fixedCollapseElement, setFixedCollapseElement] = useState<HTMLDivElement | null>(null);

    const renderFixedHeightCollapse = () => {
        // Reference was NOT initialized yet
        if (fixedCollapseElement === null) {
            return null;
        }

        // Content inside of collapse is bigger then collapse max height which means that content is catted and we need to show collapse control to allow user to show full content inside of collapse
        if (fixedCollapseElement.scrollHeight > maxHeight) {
            return renderCollapseControl();
        }
    };

    return (
        <div {...props}>
            <StyledFixedHeightCollapse
                $isCollapsed={isCollapsed}
                className="mb-1"
                $maxHeight={maxHeight}
                ref={(element) => setFixedCollapseElement(element)}
            >
                {children}
            </StyledFixedHeightCollapse>
            {renderFixedHeightCollapse()}
        </div>
    );
};

export default FixedHeightCollapse;
