import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { ModalProps } from '@app/containers/modals/types';
import { useForm, useTextFormField } from '@app/hooks';
import { getStringRequiredValidator, getStringMaxLengthValidator } from '@app/hooks/validation/functions';
import { Card, Button, Divider, Intent } from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import { AllocateVirtualLicenceInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { useStore } from 'effector-react';
import React from 'react';
import { virtualLicencesApi } from '../../store/apis';
import { createVirtualLicence } from './store/effects';

const virtualLicenceDescriptionValidators = [
    getStringRequiredValidator(),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];

export interface CreateVirtualLicenceModalProps {
    userId: ID;
}
export type Props = ModalProps<CreateVirtualLicenceModalProps>;

const CreateVirtualLicenceModal: React.FC<Props> = ({ closeModal, data }) => {
    const isCreatingVirtualLicence = useStore(createVirtualLicence.pending);

    const virtualLicenceDescription = useTextFormField({
        id: 'virtual-licence-description',
        initialValue: '',
        validators: virtualLicenceDescriptionValidators,
    });

    const handleModalClose = () => {
        if (isCreatingVirtualLicence) {
            return;
        }

        closeModal?.();
    };

    const form = useForm({
        fields: [virtualLicenceDescription],
        apiCall: async () => {
            try {
                if (!data) {
                    throw new Error('Could not find data');
                }

                // Create input
                const createVirtualLicenceInput: AllocateVirtualLicenceInput = {
                    user_id: data?.userId,
                    description: virtualLicenceDescription.value,
                };

                // Create virtual licence
                const virtualLicenceRef = await createVirtualLicence(createVirtualLicenceInput);

                // Update state
                virtualLicencesApi.create({ virtualLicenceId: virtualLicenceRef.id, input: createVirtualLicenceInput });

                handleModalClose();
            } catch (e) {
                throw e;
            }

            return { response: true };
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create customization</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isCreatingVirtualLicence} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <TextAreaFormField
                        field={virtualLicenceDescription}
                        formGroupProps={{ label: 'Description' }}
                        textAreaProps={{ placeholder: 'Enter customization description' }}
                    />

                    <Flex className="mt-2" direction="row" justify="flex-end">
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={isCreatingVirtualLicence}>
                            No, cancel
                        </Button>
                        <Button type="submit" intent={Intent.PRIMARY} loading={isCreatingVirtualLicence}>
                            Yes, create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateVirtualLicenceModal;
