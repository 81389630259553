import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { ClientUserResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { ComputedLicenceResource } from 'dy-frontend-http-repository/lib/modules/ComputedLicence/resources';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { QuotaUsageResource } from 'dy-frontend-http-repository/lib/modules/QuotaUsage/resources';
import domain from './domain';
import { resetClient, resetClientPaymentAccount, resetComputedLicence, resetQuotaUsage } from './events';

// Client
export const $client = domain.createStore<ClientUserResource | null>(null).reset(resetClient);

// Client payment account
export const $clientPaymentAccount = domain
    .createStore<PaymentAccountResource | null>(null)
    .reset(resetClientPaymentAccount);

// Computed licence
export const $computedLicence = domain.createStore<ComputedLicenceResource | null>(null).reset(resetComputedLicence);

// Quota usage
export const $quotaUsage = domain
    .createStore<CollectionResource<QuotaUsageResource, {}> | null>(null)
    .reset(resetQuotaUsage);
