import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';
import domain from './domain';
import { resetSubscriptionPlans, resetPaymentAccount, resetPaymentMethods } from './events';

// Payment account
export const $paymentAccount = domain.createStore<PaymentAccountResource | null>(null).reset(resetPaymentAccount);

// Payment account
export const $paymentMethods = domain
    .createStore<CollectionResource<PaymentMethodResource, {}> | null>(null)
    .reset(resetPaymentMethods);

// Subscription plans
export const $subscriptionPlans = domain
    .createStore<CollectionResource<PlanResource, {}> | null>(null)
    .reset(resetSubscriptionPlans);
