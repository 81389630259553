import domain from './domain';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { CreateUserVerificationInput } from 'dy-frontend-http-repository/lib/modules/UserVerification/inputs';
import { UserVerificationRef } from 'dy-frontend-http-repository/lib/modules/UserVerification/refs';

// Create user verification
export const createUserVerificationIntent = domain.createEffect<CreateUserVerificationInput, UserVerificationRef>({
    name: 'createUserVerificationIntent',
    handler: async (input) => {
        return await repository.userVerification().create(input);
    },
});