import { Button, ButtonProps, Classes, Colors, Icon, IconName } from '@blueprintjs/core';
import React from 'react';
import DevText from '@components/Text';

export interface PlatformFilterButtonProps {
    title: string;
    label: string;
    icon: IconName;
}

export type Props = Omit<ButtonProps, 'icon'> & PlatformFilterButtonProps;

const PlatformFilterButton: React.FC<Props> = ({ title, label, icon, loading, ...props }) => {
    const iconClassName = ['mb-unit mr-1', loading ? Classes.SKELETON : ''].join(' ');
    const titleClassName = [label.trim().length !== 0 ? 'mr-1' : '', loading ? Classes.SKELETON : ''].join(' ');
    const labelClassName = loading ? Classes.SKELETON : '';

    return (
        <Button minimal style={{ background: Colors.DARK_GRAY1, borderRadius: '2px' }} {...props}>
            <Icon className={iconClassName} icon={icon} size={14} />

            <DevText inline muted className={titleClassName}>
                {title}
            </DevText>

            <DevText inline className={labelClassName}>
                {label}
            </DevText>
        </Button>
    );
};

export default PlatformFilterButton;
