import domain from './domain';

// Reset virtual licences
export const resetVirtualLicences = domain.createEvent('resetVirtualLicences');

// Reset licences
export const resetLicences = domain.createEvent('resetLicences');

// Reset task category groups
export const resetTaskCategoryGroups = domain.createEvent('resetTaskCategoryGroups');

// Reset task categories
export const resetTaskCategories = domain.createEvent('resetTaskCategories');
