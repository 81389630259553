import styled, { css } from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const Wrapper = styled.div<{ aspectRationPadding: string }>`
    width: 100%;
    height: 100%;

    ${({ aspectRationPadding }) => css`
        .html5-video-player {
            background-color: ${Colors.DARK_GRAY4} !important;
        }

        .site-as-giant-card {
            background-color: ${Colors.DARK_GRAY4} !important;
        }

        .react-player {
            width: 100% !important;
            height: 100% !important;
            padding-top: ${aspectRationPadding};
            position: relative;
        }
    `}

    .react-player > * {
        position: absolute;
        top: 0;
        left: 0;
    }

    & video {
        &:focus {
            outline: none;
        }
    }
`;
