import { useCustomSelectFormField, useForm } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import { pricePlanVisibilityInformation } from '@data/consts';
import { ModalProps } from '@modals/types';
import { PricePlanVisibility } from 'dy-frontend-http-repository/lib/data/enums';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceRef } from 'dy-frontend-http-repository/lib/modules/Price/refs';
import React, { useState } from 'react';
import { planApi } from '../../store/apis';
import { updatePriceToPlanAttachment } from '../../store/effects';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';

export interface UpsertPlanToPriceAttachmentModalProps {
    planId: ID;
    price: PriceResource;
}

type Props = ModalProps<UpsertPlanToPriceAttachmentModalProps>;

const planVisibilityValidators = [getStringRequiredValidator()];

const UpsertPlanToPriceAttachmentModal: React.FC<Props> = ({ closeModal, data }) => {
    const planPriceVisibility = useCustomSelectFormField<string | null>({
        id: 'plan-price-visibility',
        validators: planVisibilityValidators,
        initialValue: data?.price ? data.price.plan_visibility : null,
        formatValue: (value) => value ?? '',
    });

    const [isPriceLegacy, setIsPriceLegacy] = useState(data?.price?.is_legacy ?? false);

    const form = useForm<PriceRef>({
        fields: [planPriceVisibility],
        apiCall: async () => {
            try {
                const updatePriceToPlanAttachmentInput: UpdatePricePlanAttachmentDataInput = {
                    plan_id: data!.planId,
                    is_legacy: isPriceLegacy,
                    plan_visibility: planPriceVisibility.value,
                };

                // Update plan price
                const priceRef = await updatePriceToPlanAttachment({
                    priceId: data!.price.id,
                    input: updatePriceToPlanAttachmentInput,
                });
                planApi.updatePrice({
                    priceId: priceRef.id,
                    input: updatePriceToPlanAttachmentInput,
                    price: data!.price,
                });

                return { response: priceRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update price to plan attachment</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {/* Plan price visibility */}
                    <SelectFormField
                        items={Object.values(PricePlanVisibility)}
                        field={planPriceVisibility}
                        formGroupProps={{ label: 'Price visibility' }}
                        selectProps={{ selectButtonProps: { placeholder: 'Select price visibility' } }}
                        itemToReadableFormatter={(item) => pricePlanVisibilityInformation[item]}
                    />

                    {/* Is price legacy */}
                    <FormGroup>
                        <Checkbox
                            label="Is price legacy"
                            checked={isPriceLegacy}
                            onChange={() => {
                                setIsPriceLegacy((prevValue) => !prevValue);
                            }}
                        />
                    </FormGroup>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertPlanToPriceAttachmentModal;
