import { TaskDeliverablesParsedInformation } from '@app/containers/pages/Task/types';
import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { createApi } from 'effector';
import { $taskDeliverablesTree } from './states';

export const taskDeliverablesTreeApi = createApi($taskDeliverablesTree, {
  insert: (store, payload: { taskDeliverables: TaskDeliverableResource[] }) => {
      const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
          deliverables: {},
          directories: {},
      };

      if (payload.taskDeliverables.length === 0) {
          // No task deliverables
          return taskDeliverablesInformation;
      }

      if (store) {
          // Task deliverables were already initialized before
          taskDeliverablesInformation.deliverables = { ...store.deliverables };
          taskDeliverablesInformation.directories = { ...store.directories };
      }

      for (let i = 0; i < payload.taskDeliverables.length; i++) {
          const deliverable = { ...payload.taskDeliverables[i] };
          const deliverablePath = deliverable.path;

          // Deliverable insertion
          taskDeliverablesInformation.deliverables = TaskDeliverablesTreeUtils.insertTaskDeliverable({
              deliverable,
              deliverablePath: deliverablePath,
              deliverablesMap: taskDeliverablesInformation.deliverables,
              skipInsertion: deliverable.archived_at !== null,
          });

          // Directory insertion
          if (deliverablePath !== '/' && deliverablePath.length > 1) {
              // Path is NOT "/" and there are characters exists after slash "/directory/mycooldeliverable"

              // Insert directory
              taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.insertDirectory({
                  directoryPath: deliverablePath,
                  directoriesMap: taskDeliverablesInformation.directories,
                  skipInsertion: deliverable.archived_at !== null,
              });
          }
      }

      return taskDeliverablesInformation;
  },
});