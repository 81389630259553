import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { OpenTaskListParticipantFilterInput } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/input';
import { OpenTaskListResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';

// Fetches open task list by owner ID
export const fetchCoreUserOpenTaskList = domain.createEffect<
    { filter: OpenTaskListParticipantFilterInput },
    OpenTaskListResource
>({
    name: 'fetchCoreUserOpenTaskList',
    handler: async ({ filter }) => {
        return await repository.openTaskList().getByParticipantUser(filter);
    },
});
