import { $coupon, $paymentMethods, $subscriptionPlans } from "./states";
import { fetchAvailableCoupon, fetchPaymentMethodsByPaymentAccountId, fetchSubscriptionPlansByType } from "./effects";

// Subscription plan data
$subscriptionPlans.on(
    fetchSubscriptionPlansByType.doneData,
    (_, data) => data,
);

// Payment method data
$paymentMethods.on(
    fetchPaymentMethodsByPaymentAccountId.doneData,
    (_, data) => data,
);

// Coupon data
$coupon.on(
    fetchAvailableCoupon.doneData,
    (_, data) => data,
);
