import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringShouldBeNumericValueMessage = 'This field should be number';
const defaultStringShouldBeNumericValueOfTypeIntMessage = "This field number can't have decimal part";

const getStringNumericValueOfTypeIntValidator: GetValidatorFunction<
    { stringShouldBeNumericValueMessage?: string; stringShouldBeNumericValueOfTypeIntMessage?: string },
    string
> = (
    options = {
        stringShouldBeNumericValueMessage: defaultStringShouldBeNumericValueMessage,
        stringShouldBeNumericValueOfTypeIntMessage: defaultStringShouldBeNumericValueOfTypeIntMessage,
    }
) => {
    const { stringShouldBeNumericValueMessage } = options;
    const { stringShouldBeNumericValueOfTypeIntMessage } = options;

    return async (value) => {
        if (isNaN(value as any)) {
            // Value is NOT number
            if (stringShouldBeNumericValueMessage) {
                return stringShouldBeNumericValueMessage;
            } else {
                return defaultStringShouldBeNumericValueMessage;
            }
        } else {
            // Value is number
            const parsedNumericString = parseFloat(value);
            if (Number.isInteger(parsedNumericString)) {
                // Number is of type integer
                return null;
            } else {
                // Number is NOT of type integer
                if (stringShouldBeNumericValueOfTypeIntMessage) {
                    return stringShouldBeNumericValueOfTypeIntMessage;
                } else {
                    return defaultStringShouldBeNumericValueOfTypeIntMessage;
                }
            }
        }
    };
};

export default getStringNumericValueOfTypeIntValidator;
