import styled, { css } from 'styled-components';

export const StyledTaskKanbanColumn = styled.div`
    height: 100%;
    width: 300px;
    min-width: 300px;
    margin-right: 8px;

    display: flex;
    flex-direction: column;
`;

export const TaskKanbanCardList = styled.div`
    max-height: 100%;
    overflow-x: hidden;
    /* overflow-y: scroll; */
`;

export const Header = styled.div<{ $borderColor: string }>`
    margin-right: 8px;
    padding-bottom: 8px;
    width: 100%;

    ${({ $borderColor }) => css`
        border-bottom: 5px solid ${$borderColor};
    `};
`;
