import Circle from '@app/components/Circle';
import Flex from '@app/components/Flex';
import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Endpoints } from '@app/data/consts';
import { Colors } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface TaskCategoryGroupTagProps {
    name: string;
    color?: string;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskCategoryGroupTagProps;

const TaskCategoryGroupTag: React.FC<Props> = ({ id, name, color = Colors.GRAY2, ...props }) => {
    return (
        <Flex direction="row" align="center" {...props}>
            <Circle className="mr-1" color={color} />
            <DevText>{name}</DevText>
        </Flex>
    );
};

export default TaskCategoryGroupTag;
