import styled from 'styled-components';

export const TitleContainer = styled.div`
    padding: 0 9px;
    display: flex;
    align-items: center;
`;

export const IconContainer = styled.div``;

export const Wrapper = styled.div`
    ${IconContainer} {
        display: none;
    }

    &:hover ${IconContainer} {
        display: block;
    }
`;
