import React from 'react';
import { ContentState } from 'draft-js';
import { default as BaseLink } from '@components/Link';
import { Colors } from '@blueprintjs/core';

interface LinkProps {
    entityKey: string;
    contentState: ContentState;
}

type Props = LinkProps;

const Link: React.FC<Props> = ({ contentState, entityKey, children }) => {
    const { url } = contentState.getEntity(entityKey).getData();

    return (
        <BaseLink href={url} target="_blank" color={Colors.BLUE2}>
            {children}
        </BaseLink>
    );
};

export default Link;
