import React, { HTMLAttributes } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { Wrapper } from './styled';
import DevText from '@components/Text';

export interface IconTextBlockProps {
    icon: IconName;
    text: string;
    iconSize?: number;
    backgroundColor?: string;
}

export type Props = HTMLAttributes<HTMLDivElement> & IconTextBlockProps;

const IconTextBlock: React.FC<Props> = ({ text, icon, backgroundColor = 'inherit', iconSize = 25, ...props }) => {
    return (
        <Wrapper $backgroundColor={backgroundColor} {...props}>
            <Flex direction="column" justify="center" align="center">
                <Icon className="mb-small" icon={icon} size={iconSize} />
                <DevText>{text}</DevText>
            </Flex>
        </Wrapper>
    );
};

export default IconTextBlock;
