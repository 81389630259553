import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { Button, Card, Classes, Intent, Tooltip } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import RichText from '@components/RichText';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { botName, Endpoints, imageHashPreview } from '@data/consts';
import { UserNoteListItemResource } from 'dy-frontend-http-repository/lib/modules/UserNote/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import moment from 'moment';
import React, { HTMLAttributes, useState } from 'react';
import { clientNotesApi } from '../../store/apis';
import { removeClientNote } from '../../store/effects';
import { setEditingClientNote } from '../../store/events';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { UserNotePermission } from 'dy-frontend-permissions/lib/permission';

export interface ClientNoteListItemProps {
    editingNote: UserNoteListItemResource | null;
    note: UserNoteListItemResource;
}

export type Props = ClientNoteListItemProps & HTMLAttributes<HTMLDivElement>;

const ClientNoteListItem: React.FC<Props> = ({ editingNote, note, ...props }) => {
    const permissions = useStore($permissions);

    const [removingClientNoteId, setRemovingClientNoteId] = useState<ID | null>(null);

    const isRootAccess = permissions.has(UserNotePermission.ROOT);

    const handleRemoveClientNote = async () => {
        setRemovingClientNoteId(note.id);

        try {
            await removeClientNote(note.id);
            clientNotesApi.remove({ clientNoteId: note.id });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingClientNoteId(null);
        }
    };

    const renderNoteAuthorInformation = () => {
        // TODO: output bot name to some global place since it'll be used not only here

        // Get author name
        let noteAuthorName = botName;
        let noteAuthorNameContent = <DevText>{noteAuthorName}</DevText>;
        if (note.author_user) {
            noteAuthorName = `${note.author_user.first_name} ${note.author_user.last_name}`;
            noteAuthorNameContent = (
                <RouterLink to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', note.author_user.id)}>
                    {noteAuthorName}
                </RouterLink>
            );
        }

        let avatarSrc: string | null = null;
        if (note.author_user?.image_hash) {
            avatarSrc = imageHashPreview.userImage(note.author_user.image_hash, ImageHashPreviewSize.SM);
        }

        return (
            <Flex>
                <Avatar width="45px" height="45px" className="mr-1" alt={noteAuthorName} src={avatarSrc} />
                <div>
                    {noteAuthorNameContent}
                    <DevText muted>
                        {moment(note.created_at).format('D MMMM YYYY')} ({moment(note.created_at).fromNow()})
                    </DevText>
                </div>
            </Flex>
        );
    };

    const isRemovingClientNoteListItem = removingClientNoteId === note.id;

    const isUpdateAllowed = isRootAccess || permissions.has(UserNotePermission.CONTENT_UPDATE);
    const isRemoveAllowed = isRootAccess || permissions.has(UserNotePermission.ARCHIVE);

    return (
        <Card {...props}>
            <Flex className="mb-2" justify="space-between" align="center">
                {renderNoteAuthorInformation()}
                <Flex align="center">
                    {isUpdateAllowed && (
                        <Button
                            disabled={editingNote !== null && editingNote.id === note.id}
                            className="mr-1"
                            onClick={() => setEditingClientNote(note)}
                        >
                            Edit
                        </Button>
                    )}

                    {isRemoveAllowed && (
                        <ConfirmationPopover
                            title="Are you sure you want to remove client note?"
                            description="When confirmed, client note will be removed"
                            actions={[
                                <Button
                                    intent={Intent.DANGER}
                                    className={Classes.POPOVER_DISMISS}
                                    onClick={handleRemoveClientNote}
                                >
                                    Yes, remove
                                </Button>,
                            ]}
                        >
                            <Tooltip content="Remove client note">
                                <Button
                                    minimal
                                    loading={isRemovingClientNoteListItem}
                                    disabled={isRemovingClientNoteListItem}
                                    icon="trash"
                                    intent={Intent.DANGER}
                                />
                            </Tooltip>
                        </ConfirmationPopover>
                    )}
                </Flex>
            </Flex>

            <RichText value={note.content} />
        </Card>
    );
};

export default ClientNoteListItem;
