import styled, { css } from 'styled-components';
import { TableCellProps } from './';

export const StyledTableCell = styled.td<{ $verticalAlign: Exclude<TableCellProps['verticalAlign'], undefined> }>`
    &&& {
        ${({ $verticalAlign }) => css`
            vertical-align: ${$verticalAlign};
        `}
    }
`;
