import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringNumericValueMaxValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import moment from 'moment';
import React, { HTMLAttributes, useEffect, useRef } from 'react';

export interface ApplySubscriptionTrialPeriodFormProps {
    onSuccess: (trialEndAt: string) => void;
}
export type Props = Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> & ApplySubscriptionTrialPeriodFormProps;

const subscriptionTrialPeriodDaysValidators = [
    getStringRequiredValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 1 }),
    getStringNumericValueMaxValidator({ maxValue: 1095 }),
];

const ApplySubscriptionTrialPeriodForm: React.FC<Props> = ({ onSuccess, ...props }) => {
    const trialPeriodDaysInputRef = useRef<HTMLInputElement | null>(null);

    const subscriptionTrialPeriodDays = useTextFormField({
        id: 'subscription-trial-period',
        initialValue: '',
        validators: subscriptionTrialPeriodDaysValidators,
    });

    const form = useForm({
        fields: [subscriptionTrialPeriodDays],
        apiCall: async () => {
            return { response: true };
        },
        onSuccess: () => {
            onSuccess(moment().add(subscriptionTrialPeriodDays.value, 'days').format());
        },
    });

    useEffect(() => {
        if (trialPeriodDaysInputRef.current === null) {
            return;
        }

        trialPeriodDaysInputRef.current.focus();
    }, [trialPeriodDaysInputRef]);

    const renderSubscriptionTrialPeriodDaysInputFormField = () => {
        // Get form group text intent
        const formFieldIntent = subscriptionTrialPeriodDays.error !== null ? Intent.DANGER : Intent.NONE;

        // Get form group text
        let formGroupText = '';
        if (subscriptionTrialPeriodDays.error !== null) {
            formGroupText = subscriptionTrialPeriodDays.error;
        } else {
            if (subscriptionTrialPeriodDays.value.length > 0) {
                formGroupText = `Trial will end: ${moment()
                    .add(subscriptionTrialPeriodDays.value, 'days')
                    .format('D MMM, YYYY')}`;
            }
        }

        return (
            <FormGroup label="Trial days" helperText={formGroupText} intent={formFieldIntent}>
                <InputGroup
                    leftIcon="time"
                    placeholder="Enter trial days"
                    intent={formFieldIntent}
                    value={subscriptionTrialPeriodDays.value}
                    onChange={subscriptionTrialPeriodDays.handleChange}
                    onBlur={subscriptionTrialPeriodDays.handleBlur}
                    inputRef={trialPeriodDaysInputRef}
                />
            </FormGroup>
        );
    };

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            {renderSubscriptionTrialPeriodDaysInputFormField()}
            <Flex className="mt-2" justify="flex-end">
                <Button disabled={form.hasFieldErrors} loading={form.isSubmitting} type="submit">
                    Apply Trial
                </Button>
            </Flex>
        </form>
    );
};

export default ApplySubscriptionTrialPeriodForm;
