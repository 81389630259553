import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { $team } from '../../store/states';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';

export type Props = HTMLAttributes<HTMLDivElement>;

const TeamInformation: React.FC<Props> = (props) => {
    const team = useStore($team);

    if (!team) {
        return null;
    }

    // Get working hours
    const workingHoursStart = team.working_hours_start.slice(0, team.working_hours_start.lastIndexOf(':'));
    const workingHoursEnd = team.working_hours_end.slice(0, team.working_hours_start.lastIndexOf(':'));

    return (
        <Card {...props}>
            <Heading type="h4" className="mb-2">
                Information
            </Heading>

            <CommonInformationBlock className="mb-2" title="Country" text={countriesData[team.country].countryName} />
            <CommonInformationBlock className="mb-2" title="Timezone" text={team.timezone_name} />
            <CommonInformationBlock
                className="mb-2"
                title="Working hours"
                text={`${workingHoursStart} - ${workingHoursEnd}`}
            />
            <CommonInformationBlock title="About" text={team.summary} />
        </Card>
    );
};

export default TeamInformation;
