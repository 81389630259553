import { Button, Colors, Intent } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { taskApi } from '@pages/Task/store/apis';
import { removeTaskParticipant } from '@pages/Task/store/effects';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import React, { HTMLAttributes, useState } from 'react';

export interface ParticipantListItemProps {
    canRemove: boolean;
    id: ID;
    taskId: ID;
    src?: string | null;
    name: string;
    jobPosition: string;
}

export type Props = HTMLAttributes<HTMLDivElement> & ParticipantListItemProps;

const ParticipantListItem: React.FC<Props> = ({ canRemove, id, taskId, src, name, jobPosition, ...props }) => {
    const [isRemovingParticipant, setIsRemovingParticipant] = useState(false);

    const renderRemoveTaskParticipant = () => {
        if (!canRemove) {
            return null;
        }

        const handleRemoveTaskParticipant = () => {
            setIsRemovingParticipant(true);

            // TODO: Add error handling
            removeTaskParticipant({ taskId: taskId, userId: id })
                .then(() => {
                    taskApi.removeParticipationByUserId({ userId: id });
                })
                .catch((err) => {
                    // TODO: handle error
                    console.error(err);
                })
                .finally(() => setIsRemovingParticipant(false));
        };

        return (
            <Button
                loading={isRemovingParticipant}
                minimal
                intent={Intent.DANGER}
                icon="cross"
                onClick={handleRemoveTaskParticipant}
            />
        );
    };

    // Get avatar src
    let avatarSrc: string | null = null;
    if (src) {
        avatarSrc = imageHashPreview.userImage(src, ImageHashPreviewSize.SM);
    }

    return (
        <Flex justify="space-between" {...props}>
            <Flex>
                <Avatar className="mr-1" width="42px" height="42px" alt={name} src={avatarSrc} />

                <Flex justify="center" direction="column">
                    <RouterLink color={Colors.WHITE} to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', `${id}`)}>
                        {name}
                    </RouterLink>
                    {jobPosition && <DevText muted>{jobPosition}</DevText>}
                </Flex>
            </Flex>

            {renderRemoveTaskParticipant()}
        </Flex>
    );
};

export default ParticipantListItem;
