import { TabItem } from '@app/data/types';
import { Endpoints } from '@data/consts';

const tabs: TabItem[] = [
    { icon: 'document', value: Endpoints.TASK_INFORMATION, label: 'Briefing' },
    { icon: 'send-message', value: Endpoints.TASK_MESSAGES, label: 'Messages' },
    { icon: 'unarchive', value: Endpoints.TASK_DELIVERABLES, label: 'Deliverables' },
    { icon: 'cog', value: Endpoints.TASK_MANAGEMENT, label: 'Management' },
];

export default tabs;
