import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { fetchSubscription } from './store/effects';
import { resetSubscription } from './store/events';
import { $subscription } from './store/states';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import SubscriptionCancellationInformation from './components/SubscriptionCancellationInformation';
import SubscriptionPeriodInformation from './components/SubscriptionPeriodInformation';
import SubscriptionAdditionalInformation from './components/SubscriptionAdditionalInformation';
import SubscriptionPauseScheduleInformation from './components/SubscriptionPauseScheduleInformation';
import SubscriptionItems from './components/SubscriptionItems';
import SubscriptionTrialInformation from './components/SubscriptionTrialInformation';
import SubscriptionPaymentMethod from './components/SubscriptionPaymentMethod';
import SubscriptionDiscount from './components/SubscriptionDiscount';
import SubscriptionScheduledUpdatesInformation from './components/SubscriptionScheduledUpdatesInformation';
import SubscriptionHeader from './components/SubscriptionHeader';
import SubscriptionCancellationBar from './components/SubscriptionCancellationBar';
import { Divider, Intent, Spinner } from '@blueprintjs/core';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';

const Subscription: React.FC = () => {
    const navigate = useNavigate();

    const permissions = useStore($permissions);

    if (!permissions.isEnabled.subscription) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Subscription');

    const { subscriptionId } = useParams() as { subscriptionId: ID };

    const subscription = useStore($subscription);

    useEffect(() => {
        fetchSubscription(subscriptionId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Subscription with ID of ${subscriptionId} not found`,
                    intent: Intent.DANGER,
                });

                // Go to clients page
                navigate(Endpoints.CLIENTS);
            }
        });
    }, [subscriptionId]);

    useEffect(() => {
        return () => {
            resetSubscription();
        };
    }, []);

    if (!subscription) {
        // Subscription was NOT fetched yet

        return (
            <Flex direction="row" justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <FixedWidthPageContainer>
            <SubscriptionHeader className="mb-2" subscription={subscription} />
            <Divider className="mb-2" />
            <SubscriptionCancellationBar className="mb-2" subscription={subscription} />

            <Grid container>
                <Grid lg={3} xs={12}>
                    <SubscriptionCancellationInformation className="mb-3" subscription={subscription} />
                    <SubscriptionPeriodInformation className="mb-3" subscription={subscription} />
                    <SubscriptionAdditionalInformation className="mb-3" subscription={subscription} />
                </Grid>
                <Grid lg={9} xs={12}>
                    <SubscriptionPauseScheduleInformation className="mb-3" subscription={subscription} />
                    <SubscriptionItems className="mb-3" subscription={subscription} />
                    <SubscriptionTrialInformation className="mb-3" subscription={subscription} />
                    <SubscriptionPaymentMethod className="mb-3" subscription={subscription} />
                    <SubscriptionDiscount className="mb-3" subscription={subscription} />
                    <SubscriptionScheduledUpdatesInformation subscription={subscription} />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default Subscription;
