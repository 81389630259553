enum FileStatus {
    UPLOADING = 'uploading',
    VERIFIED = 'verified',

    ERROR_CREATING_FILE_FORMS = 'error_creating_file_forms',
    ERROR_UPLOADING_TO_S3 = 'error_uploading_to_s3',
    ERROR_VERIFICATION = 'error_verification',
}

export default FileStatus;
