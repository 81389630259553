import { $permissions } from '@app/containers/store/states';
import { usePayment } from '@app/hooks';
import {
    checkValidators,
    getArrayMinLengthValidator,
    getArrayShouldNotBeEmptyValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import AddSubscriptionPriceControl from '@containers/components/AddSubscriptionPriceControl';
import ApplySubscriptionCouponControl from '@containers/components/ApplySubscriptionCouponControl';
import ApplySubscriptionTrialPeriodControl from '@containers/components/ApplySubscriptionTrialPeriodControl';
import SelectedSubscriptionPrice from '@containers/components/SelectedSubscriptionPrice';
import { Endpoints, subscriptionModeAllowedPlanTypesInformation } from '@data/consts';
import { checkIfPricesHaveTheSameRecurringInterval } from '@data/functions/subscription';
import { ModalProps } from '@modals/types';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { AvailableCouponResource } from 'dy-frontend-http-repository/lib/modules/Coupon/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { CreateSubscriptionInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { PlanResource, PriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    createSubscription,
    fetchPaymentMethods,
    fetchSubscriptionPlans,
    fetchUserPaymentAccount,
    fetchWorkspacePaymentAccount,
    processLatestSubscription,
} from './store/effects';
import { resetPaymentAccount, resetSubscriptionPlans } from './store/events';
import { $paymentAccount, $paymentMethods, $subscriptionPlans } from './store/states';
import Heading from '@components/Heading';
import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';
import CardPreview from '@app/containers/components/CardPreview';

const selectedSubscriptionPricesValidators = [
    getArrayShouldNotBeEmptyValidator(),
    getArrayMinLengthValidator({ minArrayLength: 1 }),
];

const selectedSubscriptionPriceQuantityValidators = [
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 0 }),
];

export interface CreateSubscriptionModalProps {
    userId: ID;
    userType: UserType;
    subscriptionMode: SubscriptionMode;
    refetchOnSuccess?: () => void;
}

type Props = ModalProps<CreateSubscriptionModalProps>;

const CreateSubscriptionModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const { processPayment } = usePayment<
        typeof processLatestSubscription,
        Awaited<ReturnType<typeof processLatestSubscription>>
    >({
        processPayment: processLatestSubscription,
    });

    const paymentAccount = useStore($paymentAccount);
    const paymentMethods = useStore($paymentMethods);
    const subscriptionPlans = useStore($subscriptionPlans);

    const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);

    const [selectedSubscriptionPricesQuantity, setSelectedSubscriptionPricesQuantity] = useState<{
        [priceId in ID]: string;
    }>({});
    const [selectedSubscriptionPricesQuantityError, setSelectedSubscriptionPricesQuantityError] = useState<
        string | null
    >(null);

    const [selectedSubscriptionPrices, setSelectedSubscriptionPrices] = useState<PriceResource[]>([]);
    const [selectedSubscriptionPricesError, setSelectedSubscriptionPricesError] = useState<string | null>(null);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodResource | null>(null);

    const [trialEndAt, setTrialEndAt] = useState<string | null>(null);
    const [coupon, setCoupon] = useState<AvailableCouponResource | null>(null);

    const hasFieldErrors = selectedSubscriptionPricesError !== null || selectedSubscriptionPricesQuantityError !== null;

    const isDescriptionAllowed =
        permissions.isRoot.subscription || permissions.has(SubscriptionPermission.CREATE_OPT_DESCRIPTION);
    const isCouponAllowed =
        permissions.isRoot.subscription || permissions.has(SubscriptionPermission.CREATE_OPT_COUPON);
    const isTrialAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.CREATE_OPT_TRIAL);
    const isBillingCycleAnchorAllowed =
        permissions.isRoot.subscription || permissions.has(SubscriptionPermission.CREATE_OPT_BILLING_CYCLE_ANCHOR);
    const isBackdateAllowed =
        permissions.isRoot.subscription || permissions.has(SubscriptionPermission.CREATE_OPT_BACKDATE);

    const priceIdToPlanResourceMap = useMemo<{ [priceId in ID]: PlanResource }>(() => {
        if (!subscriptionPlans) {
            return {};
        }

        const map: { [priceId in ID]: PlanResource } = {};
        subscriptionPlans.items.forEach((plan) =>
            plan.prices.forEach((price) => {
                map[price.id] = plan;
            })
        );
        return map;
    }, [subscriptionPlans]);

    useEffect(() => {
        // Fetch payment account
        if (!data) {
            return;
        }

        switch (data.userType) {
            case UserType.CLIENT:
                fetchUserPaymentAccount({ userId: data.userId }).catch((e) => {
                    // TODO: handle error
                    console.error(e);
                });
                break;
            case UserType.WORKSPACE:
                fetchWorkspacePaymentAccount({ workspaceId: data.userId }).catch((e) => {
                    // TODO: handle error
                    console.error(e);
                });
        }
    }, []);

    useEffect(() => {
        if (!data) {
            return;
        }

        // Get plan type by subscription mode
        const allowedPlanTypes = subscriptionModeAllowedPlanTypesInformation[data.subscriptionMode];
        if (allowedPlanTypes.length === 0) {
            // There are 0 allowed plans for this subscription mode;
            return;
        }

        fetchSubscriptionPlans(allowedPlanTypes[0]).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!paymentAccount) {
            return;
        }

        fetchPaymentMethods(paymentAccount.id).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, [paymentAccount]);

    useEffect(() => {
        // Validate subscription price quantities
        const validatePriceQuantities = async () => {
            const priceQuantities = Object.values(selectedSubscriptionPricesQuantity);

            for (let i = 0; i < priceQuantities.length; i++) {
                const priceQuantity = priceQuantities[i];

                // Validate price quantity
                const validationResult = await checkValidators<string>(
                    `${priceQuantity}`,
                    selectedSubscriptionPriceQuantityValidators
                );

                setSelectedSubscriptionPricesQuantityError(validationResult);

                if (validationResult !== null) {
                    // There is error with quantity
                    break;
                }
            }
        };

        validatePriceQuantities();
    }, [selectedSubscriptionPricesQuantity]);

    useEffect(() => {
        // Validate subscription prices
        const validatePrices = async () => {
            // Check default validators
            const validationResult = await checkValidators<PriceResource[]>(
                selectedSubscriptionPrices,
                selectedSubscriptionPricesValidators
            );

            if (!validationResult) {
                const hasPriceWhichNeedsToBePayed = !!selectedSubscriptionPrices.find((price) => price.unit_amount > 0);
                if (!!paymentMethods && paymentMethods.items.length === 0 && hasPriceWhichNeedsToBePayed) {
                    // User have 0 payment methods, but price(s) with unit_amount of more then 0 were selected
                    setSelectedSubscriptionPricesError(
                        'User have no payment method attached to pay for selected prices'
                    );
                } else {
                    setSelectedSubscriptionPricesError(null);
                }
            } else {
                setSelectedSubscriptionPricesError(validationResult);
            }
        };

        validatePrices();
    }, [selectedSubscriptionPrices]);

    useEffect(() => {
        return () => {
            resetPaymentAccount();
            resetSubscriptionPlans();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const handleCreateSubscription = async () => {
        try {
            if (hasFieldErrors) {
                throw new Error('Not all fields are valid');
            }

            if (!paymentAccount) {
                throw new Error('Payment account is not initialized');
            }

            setIsCreatingSubscription(true);

            // Format items object for create subscription input
            const priceItems: CreateSubscriptionInput['items'] = [];
            for (let i = 0; i < selectedSubscriptionPrices.length; i++) {
                const price = selectedSubscriptionPrices[i];

                // Get price quantity
                const priceQuantityNumeric = parseInt(selectedSubscriptionPricesQuantity[price.id]);

                if (isNaN(priceQuantityNumeric) || priceQuantityNumeric < 1) {
                    // Price quantity it not a numeric value OR less then 1
                    continue;
                }

                priceItems.push({ price_id: price.id, quantity: priceQuantityNumeric });
            }

            if (priceItems.length === 0) {
                // After formatting items there are 0 selected prices which passed validation
                throw new Error(
                    'Provided items are not valid, it can happen if you did not provide any items or provided wrong item quantity'
                );
            }

            // Get coupon ID
            let couponId: ID | null = null;
            if (isCouponAllowed) {
                if (coupon) {
                    couponId = coupon.id;
                }
            }

            // Create subscription
            const subscriptionRef = await createSubscription({
                mode: data.subscriptionMode,
                payment_account_id: paymentAccount.id,
                payment_method_id: selectedPaymentMethod ? selectedPaymentMethod.id : null,
                items: priceItems,
                trial_end_at: isTrialAllowed ? trialEndAt : null,
                coupon_id: couponId,
            });

            if (trialEndAt === null) {
                await processPayment({
                    subscriptionId: subscriptionRef.id,
                    input: { payment_method_id: selectedPaymentMethod ? selectedPaymentMethod.id : null },
                });
            }

            // Refetch if refetch function provided
            data.refetchOnSuccess?.();

            // Close modal
            closeModal?.();

            // Redirect to newly created subscription
            navigate(Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscriptionRef.id));
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsCreatingSubscription(false);
        }
    };

    const renderSelectedSubscriptionPrices = () => {
        const renderSelectedSubscriptionPrice = (price: PriceResource, isLastElement: boolean) => {
            const plan = priceIdToPlanResourceMap[price.id];

            const handleRemoveSubscriptionPrice = () => {
                setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                    const newQuantities = { ...prevQuantities };
                    newQuantities[price.id] = '0';
                    return newQuantities;
                });

                setSelectedSubscriptionPrices((prevPrices) => {
                    return prevPrices.filter((p) => p.id !== price.id);
                });
            };

            const handleQuantityChange = (newQuantity: string) => {
                setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                    const newQuantities = { ...prevQuantities };
                    newQuantities[price.id] = newQuantity;
                    return newQuantities;
                });
            };

            return (
                <SelectedSubscriptionPrice
                    className={!isLastElement ? 'mb-2' : ''}
                    key={price.id}
                    quantity={selectedSubscriptionPricesQuantity[price.id]}
                    price={price}
                    plan={plan}
                    onRemoveQuantity={handleRemoveSubscriptionPrice}
                    onChangeQuantity={handleQuantityChange}
                />
            );
        };

        return (
            <div>
                <FormGroup
                    label="Prices"
                    intent={
                        !!selectedSubscriptionPricesQuantityError || !!selectedSubscriptionPricesError
                            ? Intent.DANGER
                            : Intent.NONE
                    }
                    helperText={selectedSubscriptionPricesError ?? selectedSubscriptionPricesQuantityError}
                >
                    {selectedSubscriptionPrices.map((price, index) =>
                        renderSelectedSubscriptionPrice(price, index === selectedSubscriptionPrices.length - 1)
                    )}
                </FormGroup>
            </div>
        );
    };

    const renderAddSubscriptionPriceButton = () => {
        if (!subscriptionPlans) {
            return null;
        }

        const handleSelectPrice = (price: PriceResource) => {
            setSelectedSubscriptionPrices((prevPrices) => [...prevPrices, price]);
            setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                let newQuantities = { ...prevQuantities };
                if (newQuantities[price.id]) {
                    newQuantities[price.id] = '1';
                } else {
                    newQuantities = { ...newQuantities, [price.id]: '1' };
                }
                return newQuantities;
            });
        };

        return (
            <AddSubscriptionPriceControl
                selectedPrices={selectedSubscriptionPrices}
                plans={subscriptionPlans.items}
                onSelectPrice={handleSelectPrice}
                shouldPriceBeActive={(price) =>
                    checkIfPricesHaveTheSameRecurringInterval([price, ...selectedSubscriptionPrices])
                }
            />
        );
    };

    const renderSubscriptionPaymentMethodSelect = () => {
        if (paymentMethods!.items.length === 0) {
            return null;
        }

        const renderItem = (item: PaymentMethodResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.id === selectedPaymentMethod?.id;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.id}
                    text={`${item.card_brand.toUpperCase()} ending in *${item.card_last_four}`}
                    label={`${`${item.card_expiration_month}`.padStart(2, '0')}/${item.card_expiration_year}`}
                    icon={<CardPreview width="30px" height="25px" type={item.card_brand} />}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: PaymentMethodResource) => {
            setSelectedPaymentMethod(item);
        };

        return (
            <>
                <FormGroup label="Payment method">
                    <Select<PaymentMethodResource>
                        items={paymentMethods!.items}
                        itemRenderer={renderItem}
                        onItemSelect={handleItemSelect}
                        popoverProps={{
                            matchTargetWidth: true,
                            usePortal: false,
                        }}
                        selectButtonProps={{
                            fill: true,
                            rightIcon: 'double-caret-vertical',
                            placeholder: 'Select payment method',
                            icon: 'credit-card',
                            text:
                                selectedPaymentMethod &&
                                `${selectedPaymentMethod.card_brand.toUpperCase()} ending in *${
                                    selectedPaymentMethod.card_last_four
                                }`,
                            label:
                                selectedPaymentMethod &&
                                `${`${selectedPaymentMethod.card_expiration_month}`.padStart(2, '0')}/${
                                    selectedPaymentMethod.card_expiration_year
                                }`,
                        }}
                    />
                </FormGroup>

                <Divider className="mt-2 mb-2" />
            </>
        );
    };

    const renderAdditionalSubscriptionControls = () => {
        if (!isCouponAllowed && !isTrialAllowed) {
            return null;
        }

        const renderSubscriptionTrialControl = () => {
            if (!isTrialAllowed) {
                return null;
            }

            return <ApplySubscriptionTrialPeriodControl trialEndAt={trialEndAt} onChangeTrialEndAt={setTrialEndAt} />;
        };

        // const renderSubscriptionCouponControl = () => {
        //     if (!isCouponAllowed) {
        //         return null;
        //     }

        //     return <ApplySubscriptionCouponControl coupon={coupon} onChangeCoupon={setCoupon} />;
        // };

        return (
            <FormGroup label="Additional settings">
                {renderSubscriptionTrialControl()}
                {/* {renderSubscriptionCouponControl()} */}
            </FormGroup>
        );
    };

    const renderContent = () => {
        if (!paymentAccount || !subscriptionPlans || !paymentMethods) {
            // Either payment account, payment methods or subscription plans were NOT initialized yet
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        return (
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create subscription</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderSelectedSubscriptionPrices()}
                {renderAddSubscriptionPriceButton()}

                <Divider className="mt-2 mb-2" />

                {renderSubscriptionPaymentMethodSelect()}
                {renderAdditionalSubscriptionControls()}

                <Flex className="mt-2" justify="flex-end">
                    <Button outlined className="mr-1" onClick={closeModal}>
                        Cancel
                    </Button>

                    <Button
                        loading={isCreatingSubscription}
                        disabled={hasFieldErrors}
                        intent={Intent.PRIMARY}
                        onClick={handleCreateSubscription}
                    >
                        Create subscription
                    </Button>
                </Flex>
            </Card>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default CreateSubscriptionModal;
