import domain from './domain';

// Reset client subscriptions
export const resetSubscriptions = domain.createEvent('resetSubscriptions');

// Reset client invoice
export const resetInvoices = domain.createEvent('resetInvoices');

// Reset tax IDs
export const resetTaxIds = domain.createEvent('resetTaxIds');
