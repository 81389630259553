import React, { HTMLAttributes } from 'react';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import { Card } from '@blueprintjs/core';
import DevText from '@components/Text';

export interface PlanItemProps {
    plan: PlanResource;
}

export type Props = PlanItemProps & HTMLAttributes<HTMLDivElement>;

const PlanItem: React.FC<Props> = ({ plan, ...props }) => {
    return (
        <Card interactive {...props}>
            <Flex className="mb-1" align="center">
                <Circle className="mr-1" color={plan.color} size="16px" />
                <DevText>{plan.title}</DevText>
            </Flex>

            <DevText muted>{plan.summary}</DevText>
        </Card>
    );
};

export default PlanItem;
