import React, { HTMLAttributes } from 'react';
import Addons from './components/Addons';
import Images from './components/Images';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { TaskCategoryAddonPermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const isTaskCategoryAddonsAuthModuleEnabled = (
        permissions.has(TaskCategoryAddonPermission._ENABLED)
        || permissions.has(TaskCategoryAddonPermission.ROOT)
    );

    return (
        <div {...props}>
            <Images className="mb-2" />
            {isTaskCategoryAddonsAuthModuleEnabled && (
                <Addons />
            )}
        </div>
    );
};

export default Aside;
