import React, { HTMLAttributes, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'effector-react';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import Flex from '@components/Flex';
import { StyledCard } from './styled';
import { $permissions } from '@app/containers/store/states';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import ColorTilePicker from '@app/components/ColorTilePicker';
import Grid from '@app/components/Grid';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

const Colors: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    const [isColorsInitialized, setIsColorsInitialized] = useState(false);
    const [isColorsChanged, setIsColorsChanged] = useState(false);
    const [primaryColors, setPrimaryColors] = useState<{ [key in string]: string }>({});
    const [secondaryColors, setSecondaryColors] = useState<{ [key in string]: string }>({});

    useEffect(() => {
        // If initialization completed there is no need to do it again
        if (isColorsInitialized) return;

        if (brandProfile) {
            const formattedPrimaryColors = {};
            const formattedSecondaryColors = {};

            // Format object for primary colors, where key is unique ID used for key in the list and value is color itself
            brandProfile.colors_primary.forEach((color) => {
                const colorId = uuidv4();

                formattedPrimaryColors[colorId] = color;
            });

            // Format object for secondary colors, where key is unique ID used for key in the list and value is color itself
            brandProfile.colors_secondary.forEach((color) => {
                const colorId = uuidv4();

                formattedSecondaryColors[colorId] = color;
            });

            setPrimaryColors(formattedPrimaryColors);
            setSecondaryColors(formattedSecondaryColors);
            setIsColorsInitialized(true);
        }
    }, [isColorsInitialized, brandProfile]);

    useEffect(() => {
        if (isColorsChanged) {
            handleUpdateBrandProfileColors();
        }
        // eslint-disable-next-line
    }, [isColorsChanged, primaryColors, secondaryColors]);

    if (!brandProfile) {
        return null;
    }

    // Root permissions
    const isUpdateAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);

    const handleUpdateBrandProfileColors = () => {
        updateBrandProfileInformation({
            id: brandProfile.id,
            input: { colors_primary: Object.values(primaryColors), colors_secondary: Object.values(secondaryColors) },
        }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    };

    const handleAddColor = (
        color: string,
        dispatch: React.Dispatch<
            React.SetStateAction<{
                [x: string]: string;
            }>
        >,
    ) => {
        dispatch((prevColors) => {
            // Copy object
            const colorsCopy = { ...prevColors };

            // Generate colorId
            const colorId = uuidv4();

            // Set new color
            colorsCopy[colorId] = color;

            return colorsCopy;
        });

        if (!isColorsChanged) {
            setIsColorsChanged(true);
        }
    };

    const handleChangeColor = (
        colorId: string,
        color: string,
        dispatch: React.Dispatch<
            React.SetStateAction<{
                [x: string]: string;
            }>
        >,
    ) => {
        dispatch((prevColors) => {
            // Copy object
            const colorsCopy = { ...prevColors };

            // Set new color
            colorsCopy[colorId] = color;

            return colorsCopy;
        });

        if (!isColorsChanged) {
            setIsColorsChanged(true);
        }
    };

    const handleRemoveColor = (
        colorId: string,
        dispatch: React.Dispatch<
            React.SetStateAction<{
                [x: string]: string;
            }>
        >,
    ) => {
        dispatch((prevColors) => {
            // Copy object
            const colorsCopy = { ...prevColors };

            // Check if color exist
            if (colorsCopy[colorId]) {
                // Remove color
                delete colorsCopy[colorId];
            }

            return colorsCopy;
        });

        if (!isColorsChanged) {
            setIsColorsChanged(true);
        }
    };

    const renderColors = (
        colors: {
            [x: string]: string;
        },
        handler: React.Dispatch<
            React.SetStateAction<{
                [x: string]: string;
            }>
        >,
    ) => {
        const jsxColors = Object.entries(colors).map(([colorId, color]) => {
            return (
                <Grid className="mb-1" key={colorId} lg={4} xs={6}>
                    <ColorTilePicker
                        color={color}
                        onChange={
                            isUpdateAllowed
                                ? (color) => handleChangeColor(colorId, color, handler)
                                : undefined
                        }
                        onRemove={isUpdateAllowed ? () => handleRemoveColor(colorId, handler) : undefined}
                    />
                </Grid>
            );
        });

        if (isUpdateAllowed) {
            // Add color picker for new color
            jsxColors.push(
                <Grid className="mb-1" lg={4} xs={6}>
                    <ColorTilePicker
                        color=""
                        colorTileProps={{ emptyColorLabel: 'Set color' }}
                        onChange={(color) => handleAddColor(color, handler)}
                    />
                </Grid>,
            );
        }

        return jsxColors;
    };

    return (
        <StyledCard {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4" className="mt-small">
                    Colors
                </Heading>
            </Flex>

            <DevText muted className="mb-1">
                Primary colors
            </DevText>

            <Grid container>{renderColors(primaryColors, setPrimaryColors)}</Grid>

            <DevText muted className="mb-1">
                Secondary colors
            </DevText>

            <Grid container>{renderColors(secondaryColors, setSecondaryColors)}</Grid>
        </StyledCard>
    );
};

export default Colors;
