import { Button, Icon, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { $task } from '@pages/Task/store/states';
import { HTTPErrorType, UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskMessageFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskMessage/inputs';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { taskMessagesApi } from '../../store/apis';
import { fetchTaskMessages } from '../../store/effects';
import { $taskMessages } from '../../store/states';
import BotMessage from './components/BotMessage';
import TaskMessage from './components/TaskMessage';
import { amountOfTaskMessagesOnPage } from './consts';
import Heading from '@components/Heading';
import { Endpoints } from '@app/data/consts';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskMessageList: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { taskId } = useParams() as { taskId: ID };

    const me = useStore($authorizedUser);
    const task = useStore($task);
    const taskMessages = useStore($taskMessages);
    const isFetching = useStore(fetchTaskMessages.pending);

    const [isAuthorizedUserParticipant, setIsAuthorizedUserParticipant] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        setIsAuthorizedUserParticipant(task.participants.find((p) => p.user.id === me.user.id) !== undefined);
    }, [task, me]);

    const handleFetchNext = useCallback(async () => {
        if (!task) return new Promise(() => '');

        // Get common filter
        const filter: TaskMessageFilterInput = {
            task_id: task.id,
            is_archived: '0',
        };

        // Get cursor
        let cursor: undefined | ID;
        if (taskMessages && taskMessages.cursor && taskMessages.cursor.next_cursor_start !== null) {
            cursor = taskMessages.cursor.next_cursor_start;
        }

        try {
            // Fetch task messages
            const taskMessages = await fetchTaskMessages({
                pagination: {
                    _cursor: {
                        direction: 'desc',
                        limit: amountOfTaskMessagesOnPage,
                        start: cursor,
                    },
                },
                filter,
            });

            // Add task messages at the end
            taskMessagesApi.addAtTailAfterSuccessfulFetch({ taskMessages });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Task messages for task with ID of ${taskId} were not found`,
                    intent: Intent.DANGER,
                });

                // Go to task information page
                navigate(Endpoints.TASK_INFORMATION.replace(':taskId', taskId));
            }
        }
    }, [task, taskMessages]);

    useEffect(() => {
        handleFetchNext();
    }, []);

    useEffect(() => {
        // This happens in the situation whenever all messages were removed, but there are more messages to show, which should be fetched
        if (taskMessages && taskMessages.items.length === 0 && taskMessages.cursor && taskMessages.cursor.has_more) {
            handleFetchNext();
        }
    }, [taskMessages]);

    if (!task) {
        return null;
    }

    if (!taskMessages) {
        return (
            <Flex align="center" justify="center">
                <Spinner />
            </Flex>
        );
    }

    // Task messages were fetched, but where are NO task messages existed for this task
    if (taskMessages && taskMessages.items.length === 0 && taskMessages.cursor && !taskMessages.cursor.has_more) {
        return (
            <NonIdealState
                icon={<Icon className="mb-2" icon="search" size={70} />}
                title={
                    <Heading className="mb-1" type="h4">
                        No task messages were found
                    </Heading>
                }
            />
        );
    }

    const renderTaskMessage = (taskMessage: TaskMessageListItemResource) => {
        // Bot message
        if (!taskMessage.user) {
            // TODO: have to get resource as props

            return (
                <BotMessage
                    isAuthorizedUserParticipant={isAuthorizedUserParticipant}
                    className="mt-2"
                    taskMessage={taskMessage}
                />
            );
        }

        // "right" alignment for core users, "left" alignment for client users
        let alignment: 'right' | 'left' = 'right'; // TODO: add task message dependency on Component alignment type

        if (taskMessage.user.type === UserType.CLIENT) {
            alignment = 'left';
        }

        return (
            <TaskMessage
                isAuthorizedUserParticipant={isAuthorizedUserParticipant}
                className="mt-2"
                isAuthorizedUserAuthor={me!.user.id === taskMessage.user.id}
                alignment={alignment}
                taskMessage={taskMessage}
            />
        );
    };

    return (
        <div {...props}>
            {taskMessages?.items.map(renderTaskMessage)}
            {taskMessages && taskMessages.cursor && taskMessages.cursor.has_more && (
                <Flex className="mt-2" justify="center" align="center">
                    <Button loading={isFetching} outlined icon="refresh" rightIcon="refresh" onClick={handleFetchNext}>
                        Show more
                    </Button>
                </Flex>
            )}
        </div>
    );
};

export default TaskMessageList;
