import { PlatformTaskFilterData } from '../types';
import domain from './domain';

// Set query hydration finished flag
export const setIsQueryHydrationFinished = domain.createEvent<boolean>('setIsQueryHydrationFinished');

// Set page
export const setPage = domain.createEvent<number>('setPage');

// Set task platform task filter data
export const setPlatformTaskFilterData = domain.createEvent<PlatformTaskFilterData>('setPlatformTaskFilterData');

// Reset page
export const resetPage = domain.createEvent('resetPage');

// Reset tasks
export const resetTasks = domain.createEvent('resetTasks');

// Reset platform task filter data
export const resetPlatformTaskFilterData = domain.createEvent('resetPlatformTaskFilterData');

// Reset query hydration finished flag
export const resetIsQueryHydrationFinished = domain.createEvent('resetIsQueryHydrationFinished');
