import Box from '@app/components/Box';
import { environmentInformation } from '@app/data/consts';
import { Environment } from '@app/data/enums';
import React, { HTMLAttributes } from 'react';
import DevText from '@components/Text';

export interface EnvironmentInformationProps {
    environment: Environment | null;
}

export type Props = HTMLAttributes<HTMLDivElement> & EnvironmentInformationProps;

const EnvironmentInformation: React.FC<Props> = ({ environment }) => {
    let environmentColor = '#C6C6C7';
    let environmentName = 'Unknown environment';

    if (!environment || environment === Environment.PRODUCTION) {
        // Do not show environment information if environment is not provided or environment is production
        return null;
    }

    const environmentConfiguration = environmentInformation[environment];
    if (environmentConfiguration) {
        // Environment which was provided is a valid environment and there is configuration exists for it

        environmentColor = environmentConfiguration.color;
        environmentName = environmentConfiguration.name;
    } else {
        // Environment which was provided is a NOT valid environment
        return null;
    }

    return (
        <Box backgroundColor={environmentColor}>
            <DevText align="center">{environmentName}</DevText>
        </Box>
    );
};

export default EnvironmentInformation;
