import styled, { css } from 'styled-components';
import { Colors, HTMLTable } from '@blueprintjs/core';
import { BlueprintVersion } from '@data/consts';

export const StyledTable = styled(HTMLTable)<{ $loading: boolean }>`
    &&& {
        width: 100%;

        ${({ theme }) => css`
            thead th:first-child {
                border-top-left-radius: 6px;
            }

            thead th:last-child {
                border-top-right-radius: 6px;
            }

            tbody tr:last-child > td:first-child {
                border-bottom-left-radius: 6px;
            }

            tbody tr:last-child > td:last-child {
                border-bottom-right-radius: 6px;
            }

            thead th {
                background: ${Colors.DARK_GRAY1};
            }

            &.${BlueprintVersion}-html-table.${BlueprintVersion}-html-table-striped tbody tr:nth-child(even) td {
                /* background: ${Colors.DARK_GRAY2}; */
            }

            &.${BlueprintVersion}-html-table.${BlueprintVersion}-html-table-striped tbody tr:nth-child(odd) td {
                /* background: ${Colors.DARK_GRAY4}; */
            }

            th,
            td {
                padding: 16px !important;
                color: ${Colors.WHITE};
            }
        `}

        ${({ $loading }) =>
            $loading
                ? css`
                      opacity: 0.4;
                  `
                : css`
                      opacity: 1;
                  `}
    }
`;
