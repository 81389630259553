import domain from './domain';
import { Modal } from '@modals/types';

export const $stack = domain.createStore<Modal[]>([]);

/* 
    For file preview test, use:
    // Video
    // https://yetistorage.fra1.digitaloceanspaces.com/sandbox/public/uploads/970/1642042/Hd09wZVJYTAGlHuFT52XSpFm8o8cgIYcSTMj8Rd3JH.mp4

    // PDFs
    // https://yetistorage.fra1.digitaloceanspaces.com/sandbox/public/uploads/4/1612971/Rxt4QlPcZSLkWYtxnJB2xiIzIv2HRUbSEnriFWoj.pdf

    // Images
    // Small - https://smaller-pictures.appspot.com/images/dreamstime_xxl_65780868_small.jpg
    // Large - https://yetistorage.fra1.digitaloceanspaces.com/sandbox/public/uploads/4/1619017/rtjEFM1cjp2TkW54wY2oa7QJAL7Pgs5dpNnOSE4RrT.jpg
 */
