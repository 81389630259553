import styled, { css } from 'styled-components';

const FullWidth = css`
    width: 100%;
    height: 100%;
`;

export const Wrapper = styled.div`
    ${FullWidth}
`;

export const SpinnerWrapper = styled.div`
    ${FullWidth}

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Iframe = styled.iframe`
    ${FullWidth}

    border: none;
    box-shadow: none;
`;
