import {
    checkValidators,
    getArrayMinLengthValidator,
    getArrayShouldNotBeEmptyValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import AddSubscriptionPriceControl from '@containers/components/AddSubscriptionPriceControl';
import SelectedSubscriptionPrice from '@containers/components/SelectedSubscriptionPrice';
import SubscriptionUpdateAnchorSelector from '@containers/components/SubscriptionUpdateAnchorSelector';
import { subscriptionModeAllowedPlanTypesInformation } from '@data/consts';
import { checkIfPricesHaveTheSameRecurringInterval } from '@data/functions/subscription';
import { ModalProps } from '@modals/types';
import { SubscriptionUpdateAnchor } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { UpdateSubscriptionItemsBatchInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { PlanResource, PriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchSubscriptionPlans, updateSubscriptionItems } from './store/effects';
import { resetSubscriptionPlans } from './store/events';
import { $subscriptionPlans } from './store/states';
import Heading from '@components/Heading';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

const selectedSubscriptionPricesValidators = [
    getArrayShouldNotBeEmptyValidator(),
    getArrayMinLengthValidator({ minArrayLength: 1 }),
];

const selectedSubscriptionPriceQuantityValidators = [
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 0 }),
];

export interface UpdateSubscriptionItemsModalProps {
    subscription: SubscriptionResource;
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<UpdateSubscriptionItemsModalProps>;

const UpdateSubscriptionItemsModal: React.FC<Props> = ({ closeModal, data }) => {
    const subscriptionPlans = useStore($subscriptionPlans);

    const [isUpdatingSubscriptionItems, setIsUpdatingSubscriptionItems] = useState(false);

    const [selectedSubscriptionPricesQuantity, setSelectedSubscriptionPricesQuantity] = useState<{
        [priceId in ID]: string;
    }>({});
    const [selectedSubscriptionPricesQuantityError, setSelectedSubscriptionPricesQuantityError] = useState<
        string | null
    >(null);
    const [selectedSubscriptionPrices, setSelectedSubscriptionPrices] = useState<PriceResource[]>([]);
    const [selectedSubscriptionPricesError, setSelectedSubscriptionPricesError] = useState<string | null>(null);
    const [updateAnchor, setUpdateAnchor] = useState<SubscriptionUpdateAnchor | null>(null);
    const [updateAnchorError, setUpdateAnchorError] = useState<string | null>(null);
    const [updateAnchorAt, setUpdateAnchorAt] = useState<Date | null>(null);
    const [updateAnchorAtError, setUpdateAnchorAtError] = useState<string | null>(null);

    const hasFieldErrors =
        selectedSubscriptionPricesError !== null ||
        selectedSubscriptionPricesQuantityError !== null ||
        updateAnchorError !== null ||
        updateAnchorAtError !== null;

    const priceIdToPlanResourceMap = useMemo<{ [priceId in ID]: PlanResource }>(() => {
        if (!subscriptionPlans) {
            return {};
        }

        const map: { [priceId in ID]: PlanResource } = {};
        subscriptionPlans.items.forEach((plan) =>
            plan.prices.forEach((price) => {
                map[price.id] = plan;
            })
        );
        return map;
    }, [subscriptionPlans]);

    useEffect(() => {
        // Fetch subscription plans to get prices
        if (!data) {
            return;
        }

        // Get plan type by subscription mode
        const allowedPlanTypes = subscriptionModeAllowedPlanTypesInformation[data.subscription.mode];
        if (allowedPlanTypes.length === 0) {
            // There are 0 allowed plans for this subscription mode;
            closeModal?.();
            return;
        }

        fetchSubscriptionPlans(allowedPlanTypes[0]).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        // Initialize prices
        if (!data) {
            return;
        }

        const prices: PriceResource[] = [];
        const priceQuantities: { [priceId in ID]: string } = {};

        for (let i = 0; i < data.subscription.items.length; i++) {
            const subscriptionItem = data.subscription.items[i];
            const price = subscriptionItem.price;

            if (!price || !price.plan) {
                // Price do NOT exist or price is not attached to plan
                continue;
            }

            // This is done in order to not do O(n^2), it's shit, but it works for now since this fields are not being used and it's O(n)
            prices.push({ ...price, external_id: 'unknown', plan_visibility: 'unknown', is_legacy: false });
            priceQuantities[price.id] = `${subscriptionItem.quantity}`;
        }

        setSelectedSubscriptionPrices(prices);
        setSelectedSubscriptionPricesQuantity(priceQuantities);
    }, [subscriptionPlans]);

    useEffect(() => {
        // Validate subscription price quantities
        const validatePriceQuantities = async () => {
            const priceQuantities = Object.values(selectedSubscriptionPricesQuantity);

            for (let i = 0; i < priceQuantities.length; i++) {
                const priceQuantity = priceQuantities[i];

                // Validate price quantity
                const validationResult = await checkValidators<string>(
                    `${priceQuantity}`,
                    selectedSubscriptionPriceQuantityValidators
                );

                setSelectedSubscriptionPricesQuantityError(validationResult);

                if (validationResult !== null) {
                    // There is error with quantity
                    break;
                }
            }
        };

        validatePriceQuantities();
    }, [selectedSubscriptionPricesQuantity]);

    useEffect(() => {
        // Validate subscription prices
        const validatePrices = async () => {
            const validationResult = await checkValidators<PriceResource[]>(
                selectedSubscriptionPrices,
                selectedSubscriptionPricesValidators
            );

            setSelectedSubscriptionPricesError(validationResult);
        };

        validatePrices();
    }, [selectedSubscriptionPrices]);

    useEffect(() => {
        return () => {
            resetSubscriptionPlans();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const handleUpdateSubscription = async () => {
        try {
            if (hasFieldErrors) {
                throw new Error('Not all fields are valid');
            }

            setIsUpdatingSubscriptionItems(true);

            // Format items object for create subscription input
            const priceItems: UpdateSubscriptionItemsBatchInput['items'] = [];
            for (let i = 0; i < selectedSubscriptionPrices.length; i++) {
                const price = selectedSubscriptionPrices[i];
                // Get price quantity
                const priceQuantityNumeric = parseInt(selectedSubscriptionPricesQuantity[price.id]);

                if (isNaN(priceQuantityNumeric) || priceQuantityNumeric < 1) {
                    // Price quantity it not a numeric value OR less then 1
                    continue;
                }

                priceItems.push({ price_id: price.id, quantity: priceQuantityNumeric });
            }

            // Update old selected prices quantity
            for (let i = 0; i < data.subscription.items.length; i++) {
                // Get price
                const price = data.subscription.items[i].price;

                if (!price) {
                    continue;
                }

                // Get price quantity
                const priceQuantityNumeric = parseInt(selectedSubscriptionPricesQuantity[price.id]);

                // Check if price was updated (Should be in selectedSubscriptionPrices)
                const foundUpdatedPrice = selectedSubscriptionPrices.find((p) => p.id === price.id);

                if (!foundUpdatedPrice) {
                    // Subscription item price was removed, should add to input with quantity of 0
                    priceItems.push({ price_id: price.id, quantity: priceQuantityNumeric });
                }
            }

            if (priceItems.length === 0) {
                // After formatting items there are 0 selected prices which passed validation
                throw new Error(
                    'Provided items are not valid, it can happen if you did not provide any items or provided wrong item quantity'
                );
            }

            // Update subscription
            const subscriptionRef = await updateSubscriptionItems({
                subscriptionId: data.subscription.id,
                input: {
                    update_anchor: updateAnchor!,
                    update_anchor_at: updateAnchorAt ? moment(updateAnchorAt).utc().format() : null,
                    items: priceItems,
                },
            });

            // Refetch if refetch function provided
            if (data.refetchOnSuccess) {
                const refetch = () =>
                    new Promise((resolve, reject) => {
                        setTimeout(
                            () =>
                                data.refetchOnSuccess &&
                                data
                                    .refetchOnSuccess()
                                    .then((r) => resolve(r))
                                    .catch((e) => reject(e)),
                            5000
                        );
                    });
                const refetchResult = await refetch();
            }

            closeModal?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUpdatingSubscriptionItems(false);
        }
    };

    const renderSelectedSubscriptionPrices = () => {
        const renderSelectedSubscriptionPrice = (price: PriceResource, isLastElement: boolean) => {
            const plan = priceIdToPlanResourceMap[price.id];

            const handleRemoveSubscriptionPrice = () => {
                setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                    const newQuantities = { ...prevQuantities };
                    newQuantities[price.id] = '0';
                    return newQuantities;
                });

                setSelectedSubscriptionPrices((prevPrices) => {
                    return prevPrices.filter((p) => p.id !== price.id);
                });
            };

            const handleQuantityChange = (newQuantity: string) => {
                setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                    const newQuantities = { ...prevQuantities };
                    newQuantities[price.id] = newQuantity;
                    return newQuantities;
                });
            };

            return (
                <SelectedSubscriptionPrice
                    className={!isLastElement ? 'mb-2' : ''}
                    key={price.id}
                    quantity={selectedSubscriptionPricesQuantity[price.id]}
                    price={price}
                    plan={plan}
                    onRemoveQuantity={handleRemoveSubscriptionPrice}
                    onChangeQuantity={handleQuantityChange}
                />
            );
        };

        return (
            <div>
                <FormGroup
                    label="Prices"
                    intent={
                        !!selectedSubscriptionPricesQuantityError || !!selectedSubscriptionPricesError
                            ? Intent.DANGER
                            : Intent.NONE
                    }
                    helperText={selectedSubscriptionPricesError ?? selectedSubscriptionPricesQuantityError}
                >
                    {selectedSubscriptionPrices.map((price, index) =>
                        renderSelectedSubscriptionPrice(price, index === selectedSubscriptionPrices.length - 1)
                    )}
                </FormGroup>
            </div>
        );
    };

    const renderAddSubscriptionPriceButton = () => {
        if (!subscriptionPlans) {
            return null;
        }

        const handleSelectPrice = (price: PriceResource) => {
            setSelectedSubscriptionPrices((prevPrices) => [...prevPrices, price]);
            setSelectedSubscriptionPricesQuantity((prevQuantities) => {
                let newQuantities = { ...prevQuantities };
                if (newQuantities[price.id]) {
                    newQuantities[price.id] = '1';
                } else {
                    newQuantities = { ...newQuantities, [price.id]: '1' };
                }
                return newQuantities;
            });
        };

        return (
            <AddSubscriptionPriceControl
                selectedPrices={selectedSubscriptionPrices}
                plans={subscriptionPlans.items}
                onSelectPrice={handleSelectPrice}
                shouldPriceBeActive={(price) =>
                    checkIfPricesHaveTheSameRecurringInterval([price, ...selectedSubscriptionPrices])
                }
            />
        );
    };

    const renderContent = () => {
        if (!subscriptionPlans) {
            // Either subscription plans were NOT initialized yet
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        return (
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update subscription items</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderSelectedSubscriptionPrices()}
                {renderAddSubscriptionPriceButton()}

                <Divider className="mt-2 mb-2" />

                <SubscriptionUpdateAnchorSelector
                    updateAnchor={updateAnchor}
                    updateAnchorError={updateAnchorError}
                    updateAnchorAt={updateAnchorAt}
                    updateAnchorAtError={updateAnchorAtError}
                    onChangeUpdateAnchor={setUpdateAnchor}
                    onChangeUpdateAnchorAt={setUpdateAnchorAt}
                    onChangeUpdateAnchorError={setUpdateAnchorError}
                    onChangeUpdateAnchorAtError={setUpdateAnchorAtError}
                />

                <Flex className="mt-2" justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        loading={isUpdatingSubscriptionItems}
                        disabled={hasFieldErrors}
                        onClick={handleUpdateSubscription}
                    >
                        Update subscription items
                    </Button>
                </Flex>
            </Card>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default UpdateSubscriptionItemsModal;
