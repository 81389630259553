import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';

export const ScrollContainerWrapper = styled.div`
    position: relative;

    flex-grow: 1;
`;

export const StyledScrollContainer = styled(ScrollContainer)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: hidden;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;
