import domain from './domain';

// Reset plan
export const resetPlan = domain.createEvent('resetPlan');

// Reset task category groups
export const resetTaskCategoryGroups = domain.createEvent('resetTaskCategoryGroups');

// Reset task categories
export const resetTaskCategories = domain.createEvent('resetTaskCategories');
