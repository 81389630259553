import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { usePageTitle } from '@app/hooks';
import { taskKanbanColumns } from './consts';
import { $taskKanbanBoard, $taskKanbanBoardFormatted } from './store/states';
import { fetchTaskKanbanBoard } from './store/effects';
import { resetTaskKanbanBoard } from './store/events';
import { taskKanbanBoardFormattedApi } from './store/apis';
import PageContainer from '@components/PageContainer';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import TaskKanbanColumn from './components/TaskKanbanColumn';
import { ScrollContainerWrapper, StyledScrollContainer } from './styled';
import { Icon, Intent, Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { Endpoints } from '@app/data/consts';
import { useNavigate } from 'react-router-dom';

const TaskKanbanBoard: React.FC = () => {
    // TODO: check that all pages use usePageTitle hook
    usePageTitle('Kanban board');
    const navigate = useNavigate();

    const taskKanbanBoard = useStore($taskKanbanBoard);
    const taskKanbanBoardFormatted = useStore($taskKanbanBoardFormatted);

    useEffect(() => {
        fetchTaskKanbanBoard()
            .then((taskKanbanBoard) => taskKanbanBoardFormattedApi.initialize({ taskKanbanBoard }))
            .catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Request kanban board tasks were not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to tasks page
                    navigate(Endpoints.TASKS);
                }
            });
    }, []);

    useEffect(() => {
        return () => {
            resetTaskKanbanBoard();
        };
    }, []);

    const renderTaskKanbanBoard = () => {
        // Task kanban board was NOT fetched yet
        if (!taskKanbanBoard || !taskKanbanBoardFormatted) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Task kanban board was fetched, but where are no tasks there
        if (taskKanbanBoard.tasks.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No tasks were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <ScrollContainerWrapper>
                <StyledScrollContainer hideScrollbars={false} vertical={false} className="custom-thin-scroll">
                    {taskKanbanColumns.map((taskState) => (
                        <TaskKanbanColumn
                            taskKanbanBoardFormatted={taskKanbanBoardFormatted}
                            taskState={taskState}
                            tasks={Object.values(taskKanbanBoardFormatted.tasks[taskState])}
                        />
                    ))}
                </StyledScrollContainer>
            </ScrollContainerWrapper>
        );
    };

    return <PageContainer className="custom-thin-scroll">{renderTaskKanbanBoard()}</PageContainer>;
};

export default TaskKanbanBoard;
