import React, { HTMLAttributes, ReactNode } from 'react';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import FilePreview from '@app/components/FilePreview';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { Colors, Icon, Tooltip } from '@blueprintjs/core';
import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import Box from '@app/components/Box';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';

export interface TaskMessageDeliverableAttachmentProps {
    taskDeliverable: TaskDeliverableResource;
    actions?: ReactNode;
}

type Props = HTMLAttributes<HTMLDivElement> & TaskMessageDeliverableAttachmentProps;

const TaskMessageDeliverableAttachment: React.FC<Props> = ({ taskDeliverable, actions, ...props }) => {
    const renderPreview = () => {
        if (taskDeliverable.file) {
            // Task deliverable has file attached to it
            return (
                <FilePreview
                    width="100%"
                    height="100%"
                    minWidth="100%"
                    minHeight="100%"
                    objectFit="cover"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    style={{ cursor: 'pointer' }}
                    extension={taskDeliverable.file.extension}
                    src={taskDeliverable.file.preview_url}
                />
            );
        }

        // Get icon
        let icon: JSX.Element = <></>;
        switch (taskDeliverable.type) {
            case TaskDeliverableType.BLANK:
                icon = <Icon icon="circle" size={42} />;
                break;
            case TaskDeliverableType.TEXT:
                icon = <Icon icon="text-highlight" size={42} />;
                break;
            case TaskDeliverableType.EMBED:
                icon = <Icon icon="insert" size={42} />;
                break;
            default:
                icon = <Icon icon="error" size={42} />;
        }

        return (
            <Box width="56px" height="56px" minHeight="56px" minWidth="56px" cursor="pointer">
                <Flex fullHeight fullWidth align="center" justify="center">
                    {icon}
                </Flex>
            </Box>
        );
    };

    return (
        <Box borderRadius="8px" backgroundColor={Colors.DARK_GRAY1} {...props}>
            <Flex align="center">
                <Box width="64px" height="64px" minWidth="64px" minHeight="64px">
                    <Flex fullHeight align="center" justify="center">
                        {renderPreview()}
                    </Flex>
                </Box>

                <Box width="100%" padding="8px 16px">
                    <Flex align="center" justify="space-between">
                        <Flex className="mr-2" direction="column">
                            {/* File name */}
                            <Tooltip content={taskDeliverable.title}>
                                <DevText>{TextFormatUtils.truncate(taskDeliverable.title, 70)}</DevText>
                            </Tooltip>

                            {/* File size*/}
                            <DevText muted>
                                {moment(taskDeliverable.approved_at).format('D MMM, YYYY')}
                            </DevText>
                        </Flex>

                        {actions && <Flex align="center">{actions}</Flex>}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default TaskMessageDeliverableAttachment;
