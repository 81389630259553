import { repository } from 'dy-frontend-http-repository/lib/modules';
import { CreateTaskCategoryFeaturedBlockInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/inputs';
import { TaskCategoryFeaturedBlockRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/refs';
import domain from './domain';

// Create new disabled task category featured block
export const createDisabledTaskCategoryFeaturedBlock = domain.createEffect<
    CreateTaskCategoryFeaturedBlockInput,
    TaskCategoryFeaturedBlockRef
>({
    name: 'createDisabledTaskCategoryFeaturedBlock',
    handler: async (input) => {
        return await repository.taskCategoryFeaturedBlock().create(input);
    },
});
