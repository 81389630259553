import Grid from '@app/components/Grid';
import DevText from '@app/components/Text';
import { openModal } from '@app/containers/modals/store/events';
import { rootTaskDeliverablesPathLabel } from '@app/containers/pages/Task/consts';
import TaskDeliverableViewerModal, {
    TaskDeliverableViewerModalProps,
} from '@app/containers/pages/Task/modals/TaskDeliverableViewerModal';
import { Button, BreadcrumbProps, Breadcrumb, Breadcrumbs } from '@blueprintjs/core';
import {
    TaskDeliverableAttachment,
    TaskDeliverableResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import React, { HTMLAttributes } from 'react';
import TaskMessageDeliverableAttachment from '../TaskMessageDeliverableAttachment';

export interface TaskMessageDeliverableAttachmentsProps {
    isAuthorizedUserParticipant?: boolean;
    taskId: ID;
    attachments: TaskDeliverableAttachment[];
}
export type Props = HTMLAttributes<HTMLDivElement> & TaskMessageDeliverableAttachmentsProps;

const TaskMessageDeliverableAttachments: React.FC<Props> = ({
    isAuthorizedUserParticipant = false,
    taskId,
    attachments,
    ...props
}) => {
    if (attachments.length === 0) {
        return null;
    }

    const renderViewDeliverableButton = (deliverable: TaskDeliverableResource) => {
        const handleViewFile = () => {
            openModal<TaskDeliverableViewerModalProps>({
                ModalComponent: TaskDeliverableViewerModal,
                data: {
                    taskDeliverableId: deliverable.id,
                    taskDeliverables: attachments.map((deliverable) => ({
                        id: deliverable.task_deliverable.id,
                        created_at: deliverable.task_deliverable.approved_at ?? '',
                        archived_at: deliverable.task_deliverable.archived_at,
                        replaced_at: deliverable.task_deliverable.replaced_at,
                        approved_at: deliverable.task_deliverable.approved_at,
                        task_id: taskId,
                        parent_task_deliverable: null,
                        file: deliverable.task_deliverable.file,
                        path: deliverable.task_deliverable.path,
                        text_content: deliverable.task_deliverable.text_content,
                        title: deliverable.task_deliverable.title,
                        type: deliverable.task_deliverable.type,
                        url: deliverable.task_deliverable.url,
                        user: { id: 'Unknown', first_name: 'Unknown', last_name: 'Unknown', image_hash: null },
                    })),
                    taskId: taskId,
                    isAssignedToTask: isAuthorizedUserParticipant,
                },
            });
        };

        return <Button minimal icon="eye-open" onClick={() => handleViewFile()} />;
    };

    const renderBreadcrumb = ({ text, ...restProps }: BreadcrumbProps) => {
        return (
            <Breadcrumb {...restProps}>
                <DevText muted style={{ fontSize: '14px' }} color="inherit">
                    {text}
                </DevText>
            </Breadcrumb>
        );
    };

    const renderDeliverableDirectoryBlock = (path: string, taskDeliverables: TaskDeliverableResource[]) => {
        // Collect breadcrumbs
        const breadcrumbItems: BreadcrumbProps[] = [];
        breadcrumbItems.push({ text: rootTaskDeliverablesPathLabel });
        if (path !== '/') {
            const parsedPath = path.split('/');
            for (let i = 1; i < parsedPath.length; i++) {
                breadcrumbItems.push({
                    text: parsedPath[i],
                });
            }
        }

        return (
            <div key={path} className="mb-2">
                <Breadcrumbs
                    items={breadcrumbItems}
                    breadcrumbRenderer={renderBreadcrumb}
                    currentBreadcrumbRenderer={renderBreadcrumb}
                />

                <Grid container>
                    {taskDeliverables.map((deliverable, index) => (
                        <Grid className={index === 0 || index === 1 ? '' : 'mt-2'} key={deliverable.id} lg={6} xs={12}>
                            <TaskMessageDeliverableAttachment
                                taskDeliverable={deliverable}
                                actions={renderViewDeliverableButton(deliverable)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    // Collect directory blocks
    const directoryBlocks: { [path in string]: TaskDeliverableResource[] } = {};
    for (const attachment of attachments) {
        const deliverable = attachment.task_deliverable;

        if (directoryBlocks[deliverable.path]) {
            // Path exist
            directoryBlocks[deliverable.path].push(deliverable);
        } else {
            directoryBlocks[deliverable.path] = [deliverable];
        }
    }

    const directoryBlockUI: JSX.Element[] = [];

    for (const path in directoryBlocks) {
        // Get file data for path
        const fileData = Object.values(directoryBlocks[path]);

        // Add block to render
        directoryBlockUI.push(renderDeliverableDirectoryBlock(path, fileData));
    }

    return <div {...props}>{directoryBlockUI}</div>;
};

export default TaskMessageDeliverableAttachments;
