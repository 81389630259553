import { OverlayToaster, Position, ToastProps } from '@blueprintjs/core';

class ToastUtils {
    private static toast = OverlayToaster.createAsync({ position: Position.TOP_RIGHT });

    static showToast(toast: ToastProps) {
        const defaultToastProps: Partial<ToastProps> = { timeout: 20000 };
        ToastUtils.toast.then((toaster) => toaster.show({ ...defaultToastProps, ...toast }));
    }
}

export default ToastUtils;
