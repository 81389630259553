import { Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $isArchived: boolean; $isChildComment: boolean; $isHighlighted: boolean }>`
    padding: 16px 0 16px 0;

    ${({ $isChildComment }) =>
        $isChildComment &&
        css`
            padding: 8px 0 8px 24px;
            border-left: 2px solid ${Colors.DARK_GRAY5};
        `};

    ${({ $isHighlighted }) => $isHighlighted && css`
        box-shadow: 0 0 0 2px ${Colors.BLUE2};
    `}
`;
