import { fetchTaskDeliverableComments } from './effects';
import {
    setHighlightedPointMarkerTaskDeliverableCommentId,
    setHighlightedTaskDeliverableCommentId,
    setIsImageContentFitInPage,
    setPointMarker,
    setQueuedVideoTimelinePositionMS,
    setSelectedTaskDeliverable,
    setTimeMarker,
} from './events';
import {
    $highlightedPointMarkerTaskDeliverableCommentId,
    $highlightedTaskDeliverableCommentId,
    $isImageContentFitInPage,
    $pointMarker,
    $queuedVideoTimelinePositionMS,
    $selectedTaskDeliverable,
    $taskDeliverableComments,
    $timeMarker,
} from './states';

// Fetch task deliverable comments
$taskDeliverableComments.on(fetchTaskDeliverableComments.doneData, (_, data) => data);

// Set selected task deliverable
$selectedTaskDeliverable.on(setSelectedTaskDeliverable, (_, data) => data);

// Set time marker
$timeMarker.on(setTimeMarker, (_, data) => data);

// Set point marker
$pointMarker.on(setPointMarker, (_, data) => data);

// Set highlighted task deliverable comment ID
$highlightedTaskDeliverableCommentId.on(setHighlightedTaskDeliverableCommentId, (_, data) => data);

// Set highlighted point marker task deliverable comment ID
$highlightedPointMarkerTaskDeliverableCommentId.on(
    setHighlightedPointMarkerTaskDeliverableCommentId,
    (_, data) => data
);

// Set queued video timeline position
$queuedVideoTimelinePositionMS.on(setQueuedVideoTimelinePositionMS, (_, data) => data);

// Set image content fit in page flag
$isImageContentFitInPage.on(setIsImageContentFitInPage, (_, data) => data);
