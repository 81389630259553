import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import { openModal } from '@app/containers/modals/store/events';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import PublishTaskModal, { PublishTaskModalProps } from '../../../../modals/PublishTaskModal';
import { TaskPublishPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Draft: React.FC<Props> = (props) => {
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const permissions = useStore($permissions);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }


    const renderTaskPublishFlowButton = () => {
        const isRoot = permissions.has(TaskPublishPermission.ROOT);

        // Ensure enabled or has root permissions
        if (!(permissions.has(TaskPublishPermission._ENABLED) || isRoot)) {
            return null;
        }

        // Ensure has permission or root
        if (!(permissions.has(TaskPublishPermission.CREATE) || isRoot)) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="send-to-graph"
                onClick={
                    () => openModal<PublishTaskModalProps>({
                        ModalComponent: PublishTaskModal, data: { taskId: task.id },
                    })
                }
            >Publish request</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Draft</Heading>
                <Flex>
                    <div>{renderTaskPublishFlowButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Task is not yet published</DevText>
        </Card>
    );
};

export default Draft;
