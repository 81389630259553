import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Alignment, Button, Card, Divider, Elevation, Icon, Intent, RadioCard, RadioGroup } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { getStringEmailValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { useForm, useTextFormField } from '@app/hooks';
import InputFormField from '@app/components/InputFormField';
import { createUserEmailChangeIntent, fetchAuthenticationSchemaByEmail, setUserEmail } from './store/effects';
import { ChangeUserEmailMethod } from './enums';
import ChangeMethod from './enums/ChangeUserEmailMethod';
import ChangeUserEmailMethod from './enums/ChangeUserEmailMethod';
import { ToastUtils } from '@app/data/utils';

export interface ChangeUserEmailModalProps {
    userId: ID;
    workspaceId: ID | null;
    currentEmail: string;
    onChangeEmailSuccess: (email: string) => void;
    isManualAllowed: boolean;
    isFlowAllowed: boolean;
}

type Props = ModalProps<ChangeUserEmailModalProps>;

const emailValidators = [getStringRequiredValidator(), getStringEmailValidator()];

// TODO: Prevent submitting of the form, if: email is invalid, email is not unique, email matches current one
const ChangeUserEmailModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [errorDescription, setErrorDescription] = useState('');

    // Selected method of email change
    const [method, setMethod] = useState<ChangeUserEmailMethod>(
        data && data.isManualAllowed ? ChangeUserEmailMethod.Immediate : ChangeUserEmailMethod.Verification
    );

    // Next email
    const nextEmail = useTextFormField({
        id: 'nextEmail',
        validators: emailValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [nextEmail],
        apiCall: async () => {
            try {
                const email = nextEmail.value.toLowerCase();

                const authSchema = await fetchAuthenticationSchemaByEmail({
                    filter: { email, workspace_id: data?.workspaceId ?? undefined },
                });

                if (authSchema.is_found) {
                    throw new Error(`Email "${email}" you've provided have already been taken`);
                }

                switch (method) {
                    // Immediate
                    case ChangeUserEmailMethod.Immediate:
                        {
                            const userRef = await setUserEmail({
                                id: data?.userId ?? '0',
                                input: { email: email },
                            });
                            data?.onChangeEmailSuccess(email);
                        }
                        break;
                    // Verification
                    case ChangeUserEmailMethod.Verification:
                        {
                            const userEmailChangeRef = await createUserEmailChangeIntent({
                                user_id: data?.userId ?? '0',
                                email,
                            });
                        }
                        break;
                }

                setIsCompleted(true);

                return { response: true };
            } catch (e) {
                // TODO: handle error
                throw e;
            }
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
            setErrorDescription(e);
            ToastUtils.showToast({
                message: e,
                intent: Intent.DANGER,
            });
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    // Renders message when email either changed immediately, or email change is requested
    const renderProcessComplete = () => {
        if (!isCompleted) {
            return null;
        }

        // Completed with an error
        if (errorDescription !== '') {
            return (
                <div>
                    <Flex align="center" className="mb-2">
                        <Icon icon="cross" size={35} intent={Intent.DANGER} className="mr-1" />
                        <Heading type="h5">Error occurred</Heading>
                    </Flex>
                    <DevText muted className="mb-2">
                        Please close this window, and try again
                    </DevText>
                    <DevText muted className="mb-2">
                        Error details: {errorDescription}
                    </DevText>
                </div>
            );
        }

        // Completed without error
        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">
                        {method === ChangeMethod.Verification
                            ? `Confirmation sent to "${nextEmail.value}"`
                            : `Email changed to "${nextEmail.value}"`}
                    </Heading>
                </Flex>
                <DevText muted className="mb-2">
                    Please follow verification link sent to new email to complete email change
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <div className="mb-3">
                    <Heading type="h5" className="mb-2">
                        How to change email?
                    </Heading>
                    <RadioGroup
                        disabled={form.isSubmitting}
                        selectedValue={method}
                        onChange={(e) => setMethod(e.currentTarget.value as ChangeMethod)}
                        className="control-card-group-row"
                    >
                        {data.isManualAllowed && (
                            <RadioCard
                                elevation={Elevation.TWO}
                                alignIndicator={Alignment.RIGHT}
                                label="Immediately"
                                value={ChangeMethod.Immediate}
                            >
                                <DevText className="mb-1">Immediately</DevText>
                                <DevText muted>Without additional verification</DevText>
                            </RadioCard>
                        )}

                        <RadioCard
                            elevation={Elevation.TWO}
                            alignIndicator={Alignment.RIGHT}
                            label="With Verification"
                            value={ChangeMethod.Verification}
                        >
                            <DevText className="mb-1">With Verification</DevText>
                            <DevText muted>Verification link will be sent to provided email</DevText>
                        </RadioCard>
                    </RadioGroup>
                </div>

                <div className="mb-2">
                    <InputFormField
                        field={nextEmail}
                        formGroupProps={{
                            fill: true,
                            label: 'Enter new email',
                            helperText: nextEmail.error ?? `Current email: ${data.currentEmail}`,
                        }}
                        inputProps={{ placeholder: 'i.e. new@example.com' }}
                    />
                </div>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed with changing email?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={form.isSubmitting}
                            disabled={form.hasFieldErrors}
                            type="submit"
                        >
                            Yes, change email
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Change email
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default ChangeUserEmailModal;
