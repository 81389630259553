import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultEmptyArrayMessage = 'You need to select at least 1 item';

const getArrayShouldNotBeEmptyValidator: GetValidatorFunction<{ emptyArrayMessage?: string }, unknown[]> = (
    options = { emptyArrayMessage: defaultEmptyArrayMessage }
) => {
    const { emptyArrayMessage } = options;

    return async (value) => {
        if (value.length > 0) {
            // Array is NOT empty
            return null;
        } else {
            // String is empty
            if (emptyArrayMessage) {
                return emptyArrayMessage;
            } else {
                return defaultEmptyArrayMessage;
            }
        }
    };
};

export default getArrayShouldNotBeEmptyValidator;
