import { Card, Colors, Icon } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { CouponUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface SubscriptionDiscountProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionDiscountProps;

const SubscriptionDiscount: React.FC<Props> = ({ subscription, ...props }) => {
    // Get discount
    const discount = subscription.discount;

    // Get coupon
    const coupon = discount.coupon;

    if (!discount.is_present) {
        // Subscription discount is NOT present
        return null;
    }

    if (!coupon) {
        // Discount coupon is NOT present
        return null;
    }

    const getDiscountStartAtDate = () => {
        let discountStartAtFormatted = 'unknown';

        if (discount.start_at !== null) {
            discountStartAtFormatted = moment(discount.start_at).format('D MMM, YYYY');
        }

        return discountStartAtFormatted;
    };

    const getDiscountEndAtDate = () => {
        let discountEndAtFormatted = 'removed';

        if (discount.end_at !== null) {
            discountEndAtFormatted = moment(discount.end_at).format('D MMM, YYYY');
        }

        return discountEndAtFormatted;
    };

    const getDiscountTitle = () => {
        let discountTitle = discount.external_id;

        if (coupon) {
            discountTitle = `Coupon: ${coupon.title}`;
        }

        return discountTitle;
    };

    const renderCouponSubTitle = () => {
        const discountEndAtDate = getDiscountEndAtDate();

        if (coupon) {
            const couponDiscount = CouponUtils.getCouponDiscountText(coupon);

            return (
                <Flex direction="row" align="center">
                    <DevText muted>{couponDiscount}</DevText>
                    <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                    <DevText muted>Valid until {discountEndAtDate}</DevText>
                </Flex>
            );
        }

        return (
            <Flex direction="row" align="center">
                <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                <DevText muted>Coupon details are unknown, sync it to get info</DevText>
                <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                <DevText muted>Valid until {discountEndAtDate}</DevText>
            </Flex>
        );
    };

    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">
                Discount
            </Heading>

            <Card>
                <Flex direction="row" justify="space-between">
                    <div>
                        <Flex className="mb-1" direction="row" align="center">
                            <Icon className="mr-1" icon="tag" />
                            <Heading type="h5">{getDiscountTitle()}</Heading>
                        </Flex>
                        {renderCouponSubTitle()}
                    </div>

                    <DevText muted>Applied: {getDiscountStartAtDate()}</DevText>
                </Flex>
            </Card>
        </div>
    );
};

export default SubscriptionDiscount;
