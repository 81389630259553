import { InvoiceState } from 'dy-frontend-http-repository/lib/data/enums';

// TODO: adjust label texts with backend developer
const invoiceStateInformation: {
    [state in InvoiceState]: string;
} = {
    [InvoiceState.DRAFT]: 'Draft',
    [InvoiceState.OPEN]: 'Open',
    [InvoiceState.PAID]: 'Paid',
    [InvoiceState.UNCOLLECTIBLE]: 'Uncollectible',
    [InvoiceState.VOID]: 'Void',
};

export default invoiceStateInformation;
