import { $permissions } from '@app/containers/store/states';
import { Button, Card, Colors, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { useUpdateTeamParticipantRole } from '../../hooks';
import { teamApi } from '../../store/apis';
import { removeTeamParticipant } from '../../store/effects';
import { $team } from '../../store/states';
import { TeamPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const LeadParticipantInformation: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { updateTeamParticipantRole } = useUpdateTeamParticipantRole();

    const team = useStore($team);

    const [isChangingLeadParticipantRole, setIsChangingLeadParticipantRole] = useState(false);
    const [isRemovingLeadParticipantFromTeam, setIsRemovingLeadParticipantFromTeam] = useState(false);

    if (!team) {
        return null;
    }

    const handleUpdateTeamParticipant = async (userId: ID, role: TeamParticipationRole) => {
        setIsChangingLeadParticipantRole(true);

        try {
            await updateTeamParticipantRole({ teamId: team.id, userId, role });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsChangingLeadParticipantRole(false);
        }
    };

    const handleRemoveTeamParticipantFromTeam = async (userId: ID) => {
        setIsRemovingLeadParticipantFromTeam(true);

        try {
            await removeTeamParticipant({ teamId: team.id, userId });

            teamApi.removeParticipationByUserId({ userId });
        } catch (e) {
            // TODO: handle error;
            console.error(e);
        } finally {
            setIsRemovingLeadParticipantFromTeam(false);
        }
    };

    const renderLeadParticipantControlsButton = (leadParticipantUserId: ID) => {
        const isManagementAllowed = permissions.isRoot.team || permissions.has(TeamPermission.PARTICIPATION_MANAGE);
        if (!isManagementAllowed) {
            return null;
        }

        const renderLeadParticipantControlsMenu = () => {
            const renderDowngradeLeadParticipantToManager = () => {
                return (
                    <MenuItem
                        disabled={isChangingLeadParticipantRole}
                        icon="clean"
                        text="Downgrade to manager"
                        onClick={() =>
                            handleUpdateTeamParticipant(leadParticipantUserId, TeamParticipationRole.MANAGER)
                        }
                    />
                );
            };

            const renderDowngradeLeadParticipantToMember = () => {
                return (
                    <MenuItem
                        disabled={isChangingLeadParticipantRole}
                        icon="inherited-group"
                        text="Downgrade to member"
                        onClick={() => handleUpdateTeamParticipant(leadParticipantUserId, TeamParticipationRole.MEMBER)}
                    />
                );
            };

            const renderRemoveLeadParticipantFromTeam = () => {
                return (
                    <MenuItem
                        disabled={isRemovingLeadParticipantFromTeam}
                        icon="trash"
                        text="Remove from team"
                        intent={Intent.DANGER}
                        onClick={() => handleRemoveTeamParticipantFromTeam(leadParticipantUserId)}
                    />
                );
            };

            return (
                <Menu>
                    {renderDowngradeLeadParticipantToManager()}
                    {renderDowngradeLeadParticipantToMember()}
                    {renderRemoveLeadParticipantFromTeam()}
                </Menu>
            );
        };

        return (
            <Popover content={renderLeadParticipantControlsMenu()}>
                <Button loading={isChangingLeadParticipantRole} minimal icon="more" />
            </Popover>
        );
    };

    const renderContent = () => {
        let content;

        // Get lead participant
        const leadParticipant = team.participants.find(
            (participant) => participant.role === TeamParticipationRole.LEAD,
        );

        // Get avatar src
        let avatarSrc: string | null = null;
        if (leadParticipant?.user.image_hash) {
            avatarSrc = imageHashPreview.userImage(leadParticipant.user.image_hash, ImageHashPreviewSize.SM);
        }

        if (leadParticipant) {
            // Lead participant do NOT exist
            content = (
                <Flex justify="space-between">
                    <Flex>
                        <Avatar
                            className="mr-1"
                            width="42px"
                            height="42px"
                            src={avatarSrc}
                            alt={leadParticipant.user.first_name}
                        />

                        <Flex align="flex-start" direction="column">
                            <RouterLink
                                className="mr-1"
                                color={Colors.WHITE}
                                to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', leadParticipant.user.id)}
                            >
                                {leadParticipant.user.first_name} {leadParticipant.user.last_name}
                            </RouterLink>
                            <DevText muted>{TextFormatUtils.capitalize(leadParticipant.role)}</DevText>
                        </Flex>
                    </Flex>

                    {renderLeadParticipantControlsButton(leadParticipant.user.id)}
                </Flex>
            );
        } else {
            content = <DevText muted>No lead assigned</DevText>;
        }

        return content;
    };

    return (
        <Card {...props}>
            <Heading type="h4" className="mb-2">
                Lead
            </Heading>

            {renderContent()}
        </Card>
    );
};

export default LeadParticipantInformation;
