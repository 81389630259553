import { planTypeInformation } from '@app/data/consts';
import { Button, Classes, Colors, InputGroup, Intent, Position } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilterWithDynamicSearch from '@components/PlatformListFilterWithDynamicSearch';
import { platformClientUserFiltersInformation } from '@pages/Clients/consts';
import { PlatformClientUserFilterType } from '@pages/Clients/enums';
import { fetchPlanData } from '@pages/Clients/store/effects';
import { setPlatformClientUserFilterData } from '@pages/Clients/store/events';
import { $isQueryHydrationFinished } from '@pages/Clients/store/states';
import { PlatformClientUserFilterData } from '@pages/Clients/types';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import DevText from '@components/Text';

export interface ClientUserPlansPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

export type Props = ClientUserPlansPlatformFilterProps;

const ClientUserPlansPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [query, setQuery] = useState('');
    const [isClientUserPlansSearching, setIsClientUserPlansSearching] = useState(false);
    const [selectedClientUserPlans, setSelectedClientUserPlans] = useState<PlatformClientUserFilterData['plans']>([]);
    const [foundClientUserPlans, setFoundClientUserPlans] = useState<PlatformClientUserFilterData['plans']>([]);

    const searchInputRef = useRef<null | HTMLInputElement>(null);

    useEffect(() => {
        if (query.trim().length === 0) {
            setFoundClientUserPlans([]);
        }
    }, [query]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedClientUserPlans(platformClientUserFilterData.plans);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (query.trim().length !== 0) {
            fetchPlanData({
                query,
                exclude_id: selectedClientUserPlans.map((plan) => plan.id),
            }).then((plans) => setFoundClientUserPlans(plans.items));
        }

        handleFocusInput();

        // eslint-disable-next-line
    }, [selectedClientUserPlans]);

    useEffect(() => {
        setSelectedClientUserPlans(platformClientUserFilterData.plans);
        setFoundClientUserPlans([]);
    }, [platformClientUserFilterData.plans]);

    const getFilterTagTitle = () => {
        return selectedClientUserPlans.length > 0 ? 'Plans:' : 'Plans';
    };

    const getFilterTagLabel = () => {
        if (selectedClientUserPlans.length === 0) return '';

        return selectedClientUserPlans
            .map((plan, index) => {
                if (index === selectedClientUserPlans.length - 1) {
                    // Last element
                    return `${plan.title}`;
                }

                return `${plan.title}, `;
            })
            .join('');
    };

    const handleRemoveFilter = () => {
        if (platformClientUserFilterData.plans.length !== 0) {
            // Reset core user plans to empty array
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, plans: [] });
        }

        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.LICENCE_PLANS].isPinned) {
            // Platform core user plans filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleSearch = (searchValue: string = query) => {
        setIsClientUserPlansSearching(true);

        if (searchValue.trim().length !== 0) {
            // Search value is NOT empty string

            // Fetch users matched by searchValue
            fetchPlanData({
                query: searchValue,
                exclude_id: selectedClientUserPlans.map((plan) => plan.id),
            })
                .then((plans) => setFoundClientUserPlans(plans.items))
                .finally(() => setTimeout(() => setIsClientUserPlansSearching(false), 1000));
        }
    };

    const handleAddClientUserPlan = (newPlan: PlanResource) => {
        if (!selectedClientUserPlans.find((plan) => plan.id === newPlan.id)) {
            // Plan user with such ID was not added yet
            setSelectedClientUserPlans([newPlan, ...selectedClientUserPlans]);
        }
    };

    const handleRemoveCoreUserPlan = (id: ID) => {
        setSelectedClientUserPlans(selectedClientUserPlans.filter((plan) => plan.id !== id));
    };

    const handleFocusInput = () => {
        if (searchInputRef.current !== null) {
            // Focus input
            searchInputRef.current.focus();
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedClientUserPlans) !== JSON.stringify(platformClientUserFilterData.plans)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformClientUserFilterData({
                ...platformClientUserFilterData,
                plans: selectedClientUserPlans,
            });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
        setQuery('');

        if (platformClientUserFiltersInformation[PlatformClientUserFilterType.LICENCE_PLANS].isPinned) {
            return;
        }

        if (selectedClientUserPlans.length === 0) {
            onRemove();
        }
    };

    const renderSearchInput = () => {
        return (
            <InputGroup
                leftIcon="search"
                placeholder="Search for plan"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputRef={searchInputRef}
            />
        );
    };

    const renderSkeletonRow = (id: ID) => {
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <div>
                        <DevText className={Classes.SKELETON}>Plan title</DevText>
                        <DevText className={Classes.SKELETON}>type</DevText>
                    </div>

                    <Button minimal icon="cross" className={Classes.SKELETON} intent={Intent.DANGER} />
                </Flex>
            </li>
        );
    };

    const renderSelectedCoreUserPlanRow = ({ id, title, color, type }: PlanResource) => {
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <div>
                        <Flex align="center">
                            <Circle className="mr-1" size="14px" color={color} />
                            <DevText>{title}</DevText>
                        </Flex>

                        <DevText muted>{planTypeInformation[type]}</DevText>
                    </div>

                    <Button minimal icon="cross" intent={Intent.DANGER} onClick={() => handleRemoveCoreUserPlan(id)} />
                </Flex>
            </li>
        );
    };

    const renderFoundCoreUserPlanRow = ({ id, title, color, type }: PlanResource) => {
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <div>
                        <Flex align="center">
                            <Circle className="mr-1" size="14px" color={color} />
                            <DevText>{title}</DevText>
                        </Flex>

                        <DevText muted>{planTypeInformation[type]}</DevText>
                    </div>

                    <Button
                        minimal
                        icon="plus"
                        intent={Intent.SUCCESS}
                        onClick={() =>
                            handleAddClientUserPlan({
                                id,
                                title,
                                color,
                                type,
                            })
                        }
                    />
                </Flex>
            </li>
        );
    };

    const renderEmptyList = () => {
        return (
            <div>
                <Flex className="mb-1" align="center">
                    <Circle className="mr-1" size="16px" color={Colors.GRAY2} />
                    <DevText>No results</DevText>
                </Flex>
                <DevText muted>Please try different search query</DevText>
            </div>
        );
    };

    return (
        <PlatformListFilterWithDynamicSearch<PlanResource>
            loading={isClientUserPlansSearching && query.trim().length > 0}
            isOpen={isOpen}
            removable={selectedClientUserPlans.length > 0}
            title="Plans"
            position={Position.BOTTOM_LEFT}
            search={query}
            maxListHeight={318}
            selectedList={selectedClientUserPlans}
            foundList={foundClientUserPlans}
            skeletonList={Array.from({ length: 3 }, (_, index) => `${index + 1}`)}
            selectedItemRenderer={renderSelectedCoreUserPlanRow}
            foundItemRenderer={renderFoundCoreUserPlanRow}
            emptyListRenderer={renderEmptyList}
            searchInputRenderer={renderSearchInput}
            skeletonItemRenderer={renderSkeletonRow}
            onSearch={handleSearch}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
            onOpened={handleFocusInput}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.LICENCE_PLANS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilterWithDynamicSearch>
    );
};

export default ClientUserPlansPlatformFilter;
