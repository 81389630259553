import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { ModalProps } from '@app/containers/modals/types';
import { taskApi } from '@app/containers/pages/Task/store/apis';
import { Card, Button, Slider, Intent, Divider } from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { updateTaskComplexity } from './store/effects';

export interface UpdateTaskComplexityModalProps {
    taskId: ID;
    currentTaskComplexityMultiplier: number;
}

export type Props = ModalProps<UpdateTaskComplexityModalProps>;

const UpdateTaskComplexityModal: React.FC<Props> = ({ closeModal, data }) => {
    const isUpdatingTaskComplexity = useStore(updateTaskComplexity.pending);

    const [complexityMultiplier, setComplexityMultiplier] = useState(data?.currentTaskComplexityMultiplier ?? 10);

    const handleCloseModal = () => {
        if (isUpdatingTaskComplexity) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleCloseModal();
        return null;
    }

    const handleUpdateTaskComplexity = async () => {
        try {
            // Update task complexity
            await updateTaskComplexity({ taskId: data.taskId, input: { multiplier: complexityMultiplier } });

            // Update task complexity state
            taskApi.updateComplexityMultiplier(Math.round(complexityMultiplier) / 100);

            // Close modal
            handleCloseModal();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderComplexityMultiplierSlider = () => {
        const minTaskComplexityMultiplier = 10;
        const maxTaskComplexityMultiplier = 200;
        const stepSize = 10;
        const labelStepSize = 190;

        return (
            <Slider
                value={complexityMultiplier}
                stepSize={stepSize}
                labelStepSize={labelStepSize}
                min={minTaskComplexityMultiplier}
                max={maxTaskComplexityMultiplier}
                onChange={setComplexityMultiplier}
                labelRenderer={(multiplier) => `${multiplier}%`}
            />
        );
    };

    return (
        <Overlay isOpen onClose={handleCloseModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <Heading type="h4" className="mb-1">
                        Update complexity multiplier
                    </Heading>

                    <Button minimal icon="cross" onClick={handleCloseModal} />
                </Flex>

                <Divider className="mb-2" />

                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <DevText muted>Less complex</DevText>
                    <DevText muted>More complex</DevText>
                </Flex>

                {renderComplexityMultiplierSlider()}

                <Flex className="mt-2" justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>

                    <Button
                        disabled={data.currentTaskComplexityMultiplier === complexityMultiplier}
                        loading={isUpdatingTaskComplexity}
                        intent={Intent.PRIMARY}
                        onClick={handleUpdateTaskComplexity}
                    >
                        Update
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskComplexityModal;
