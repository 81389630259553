import ColorTilePickerFormField from '@app/components/ColorTilePickerFormField';
import Grid from '@app/components/Grid';
import InputFormField from '@app/components/InputFormField';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { Endpoints } from '@app/data/consts';
import { useColorPickerFormField, useForm, useTextFormField } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createPlan } from '../../store/effects';

type Props = ModalProps;

const planTitleValidators = [getStringRequiredValidator()];
const planSummaryValidators = [getStringRequiredValidator()];
const planColorValidators = [getStringRequiredValidator()];

const CreatePlanModal: React.FC<Props> = ({ closeModal }) => {
    const navigate = useNavigate();

    const planColor = useColorPickerFormField({
        id: 'color',
        validators: planColorValidators,
        initialValue: '',
    });

    const planTitle = useTextFormField({
        id: 'plan-title',
        validators: planTitleValidators,
        initialValue: '',
    });

    const planSummary = useTextFormField({
        id: 'plan-summary',
        validators: planSummaryValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [planColor, planTitle, planSummary],

        apiCall: async () => {
            try {
                const planRef = await createPlan({
                    title: planTitle.value,
                    summary: planSummary.value,
                    color: planColor.value,
                    type: PlanType.USER,
                });

                // Redirect to newly created plan page
                navigate(Endpoints.PLAN.replace(':planId', planRef.id));

                // Close modal
                closeModal?.();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create Plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>

                        <Grid lg={9}>
                            {/* Title */}
                            <InputFormField
                                field={planTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        <Grid lg={3}>
                            {/* Color */}
                            <ColorTilePickerFormField
                                field={planColor}
                                formGroupProps={{ label: 'Color' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Summary */}
                            <TextAreaFormField
                                field={planSummary}
                                formGroupProps={{ label: 'Summary' }}
                                textAreaProps={{ placeholder: 'Enter summary' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreatePlanModal;
