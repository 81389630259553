import { PlatformClientUserFilterData } from '../types';

const initialPlatformClientUserFilterData: PlatformClientUserFilterData = {
    query: '',
    plans: [],
    workspaces: [],
    isShutdownClientUsersVisible: undefined,
    isVerifiedClientUsersVisible: undefined,
};

export default initialPlatformClientUserFilterData;
