import domain from './domain';

// Reset payment account
export const resetPaymentAccount = domain.createEvent('resetPaymentAccount');

// Reset payment methods
export const resetPaymentMethods = domain.createEvent('resetPaymentMethods');

// Reset subscription plans
export const resetSubscriptionPlans = domain.createEvent('resetSubscriptionPlans');
