import React, { HTMLAttributes } from 'react';
import { FormGroup, FormGroupProps, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import { ReturnValue as CustomSelectFormFieldReturnValue } from '@app/hooks/validation/useCustomSelectFormField';
import Select, { Props as SelectProps } from '@components/Select';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';

export interface SelectFormFieldProps {
    items: string[];
    field: CustomSelectFormFieldReturnValue<string | null>;
    formGroupProps?: FormGroupProps;
    selectProps?: Partial<SelectProps<string>>;
    itemToReadableFormatter?: (item: string) => string;
}

export type Props = HTMLAttributes<HTMLDivElement> & SelectFormFieldProps;

const SelectFormField: React.FC<Props> = ({
    items,
    field,
    formGroupProps,
    selectProps,
    itemToReadableFormatter,
    ...props
}) => {
    const getReadableItem = (item: string) => {
        if (itemToReadableFormatter) {
            return itemToReadableFormatter(item);
        } else {
            return TextFormatUtils.capitalize(item);
        }
    };

    const getSelectButtonText = () => {
        let selectButtonText;

        if (field.value) {
            selectButtonText = getReadableItem(field.value);
        }

        return selectButtonText;
    };

    const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
        const isMenuItemActive = item === field.value;
        const menuItemText = getReadableItem(item);

        return <MenuItem active={isMenuItemActive} key={item} text={menuItemText} onClick={handleClick} />;
    };

    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <Select<string>
                items={items}
                itemRenderer={renderItem}
                onItemSelect={field.handleChange}
                {...selectProps}
                popoverProps={{
                    matchTargetWidth: true,
                    usePortal: false,
                    onClose: field.handleClose,
                    ...selectProps?.popoverProps,
                }}
                selectButtonProps={{
                    fill: true,
                    rightIcon: 'double-caret-vertical',
                    icon: 'add',
                    text: getSelectButtonText(),
                    ...selectProps?.selectButtonProps,
                }}
            />
        </FormGroup>
    );
};

export default SelectFormField;
