import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { OpenTaskListResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { SwapTaskQueueInput, TransitionOpenTaskQueueInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { createApi } from 'effector';
import { generateKeyBetween } from 'fractional-indexing';
import { OpenTaskListData } from 'dy-frontend-shared/lib/data/types';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import { $clientOpenTaskListData } from './states';

const openTaskQueuePositionSortComparator = (a: TaskResource, b: TaskResource) => {
    const formattedA = a.queue_position ?? '';
    const formattedB = b.queue_position ?? '';

    if (formattedA < formattedB) {
        return -1;
    }

    if (formattedA > formattedB) {
        return 1;
    }

    return 0;
};

export const clientOpenTaskListDataApi = createApi($clientOpenTaskListData, {
    initialize: (store, payload: { openTaskList: OpenTaskListResource }) => {
        const openTaskListData: OpenTaskListData = {
            tasks: {
                [OpenTaskType.ACTIVE]: {},
                [OpenTaskType.BACKLOG]: {},
                [OpenTaskType.PAUSED]: {},
            },
            users: {},
            participant_users: {},
            plans: {},
            teams: {},
        };

        // Initialize tasks
        payload.openTaskList.tasks.forEach((task) => {
            let taskType: OpenTaskType | null = null;

            if (task.paused_at) {
                // Paused
                taskType = OpenTaskType.PAUSED;
            } else {
                // NOT paused
                switch (task.queue) {
                    case TaskQueue.ACTIVE:
                        taskType = OpenTaskType.ACTIVE;
                        break;
                    case TaskQueue.BACKLOG:
                        taskType = OpenTaskType.BACKLOG;
                        break;
                }
            }

            if (taskType) {
                openTaskListData.tasks[taskType][task.id] = task;
            }
        });

        for (let openTaskType in openTaskListData.tasks) {
            // Get task map for open task type
            const tasksMap = openTaskListData.tasks[openTaskType as OpenTaskType];
            if (!tasksMap) {
                // Task map was not found for open task type
                return;
            }

            // Sort tasks by queue position
            const tasks = Object.values(tasksMap).sort(openTaskQueuePositionSortComparator);

            // Create new task map
            const newTaskMap: { [taskId in ID]: TaskResource } = {};
            tasks.forEach((task) => {
                newTaskMap[task.id] = task;
            });

            // Insert new task map
            openTaskListData.tasks[openTaskType as OpenTaskType] = newTaskMap;
        }

        // Initialize users
        payload.openTaskList.users.forEach((user) => {
            openTaskListData.users[user.id] = user;
        });

        // Initialize participant users
        payload.openTaskList.participant_users.forEach((participantUser) => {
            openTaskListData.participant_users[participantUser.id] = participantUser;
        });

        // Initialize plans
        payload.openTaskList.plans.forEach((plan) => {
            openTaskListData.plans[plan.id] = plan;
        });

        // Initialize teams
        payload.openTaskList.teams.forEach((team) => {
            openTaskListData.teams[team.id] = team;
        });

        return openTaskListData;
    },

    changeTaskQueue: (store, payload: { type: OpenTaskType; taskId: ID; input: TransitionOpenTaskQueueInput }) => {
        if (!store) {
            // Not initialized yet
            return null;
        }

        // Get tasks from which task will be moved
        const tasksByType = Object.values(store.tasks[payload.type]);

        // Get tasks to which task will be moved AND tasks to queue open task type
        let moveToTasks: TaskResource[] = [];
        let moveToTasksType: OpenTaskType = OpenTaskType.PAUSED;
        switch (payload.input.queue) {
            case TaskQueue.ACTIVE:
                moveToTasksType = OpenTaskType.ACTIVE;
                moveToTasks = Object.values(store.tasks[moveToTasksType]);
                break;
            case TaskQueue.BACKLOG:
                moveToTasksType = OpenTaskType.BACKLOG;
                moveToTasks = Object.values(store.tasks[moveToTasksType]);
                break;
        }

        // Get task index
        const taskIndex = tasksByType.findIndex((task) => task.id === payload.taskId);
        if (taskIndex === -1) {
            // Task index was NOT found
            return store;
        }

        // Get task by index
        const task = { ...tasksByType[taskIndex] };

        // Remove task from queue
        tasksByType.splice(taskIndex, 1);

        // Update task queue automation locked flag
        task.is_queue_automation_locked = payload.input.is_queue_automation_locked;

        if (task.queue === TaskQueue.ACTIVE && payload.input.queue === TaskQueue.BACKLOG) {
            // From active to backlog, should be last task

            const lastTask: TaskResource | null = moveToTasks[moveToTasks.length - 1] ?? null;

            // Generate new queue position
            const nextKey = generateKeyBetween(lastTask ? lastTask.queue_position : null, null);

            // Update task queue position
            task.queue_position = nextKey;

            // Update task queue
            task.queue = TaskQueue.BACKLOG;

            // Insert task in the list
            moveToTasks = [...moveToTasks, task];
        } else if (task.queue === TaskQueue.BACKLOG && payload.input.queue === TaskQueue.ACTIVE) {
            // From backlog to active, should be first task
            const firstTask: TaskResource | null = moveToTasks[0] ?? null;

            // Generate new queue position
            const nextKey = generateKeyBetween(null, firstTask ? firstTask.queue_position : null);

            // Update task queue position
            task.queue_position = nextKey;

            // Update task queue
            task.queue = TaskQueue.ACTIVE;

            // Insert task in the list
            moveToTasks = [task, ...moveToTasks];
        }

        // Create tasks by type map
        const tasksByTypeMap: { [taskId in ID]: TaskResource } = {};
        for (const task of tasksByType) {
            tasksByTypeMap[task.id] = task;
        }

        // Create move to tasks map
        const sortedMoveToTasks = moveToTasks.sort(openTaskQueuePositionSortComparator);
        const sortedMoveToTasksMap: { [taskId in ID]: TaskResource } = {};
        for (const task of sortedMoveToTasks) {
            sortedMoveToTasksMap[task.id] = task;
        }

        return {
            ...store,
            tasks: {
                ...store.tasks,
                [payload.type]: tasksByTypeMap,
                [moveToTasksType]: sortedMoveToTasks,
            },
        };
    },

    lockQueueAutomation: (store, payload: { taskId: ID; type: OpenTaskType }) => {
        if (!store) {
            return null;
        }

        // Get task by type
        const tasks = Object.values(store.tasks[payload.type]);

        // Find task index for task which queue automation should be toggled
        const foundTaskIndex = tasks.findIndex((task) => task.id === payload.taskId);

        if (foundTaskIndex === -1) {
            // Task was NOT found
            return store;
        }
        // Set new queue position for task
        tasks[foundTaskIndex].is_queue_automation_locked = true;

        // Create new task map
        const tasksMap: { [taskId in ID]: TaskResource } = {};
        for (const task of tasks) {
            tasksMap[task.id] = task;
        }

        return {
            ...store,
            tasks: {
                ...store.tasks,
                [payload.type]: tasksMap,
            },
        };
    },

    unlockQueueAutomation: (store, payload: { taskId: ID; type: OpenTaskType }) => {
        if (!store) {
            return null;
        }

        // Get task by type
        const tasks = Object.values(store.tasks[payload.type]);

        // Find task index for task which queue automation should be toggled
        const foundTaskIndex = tasks.findIndex((task) => task.id === payload.taskId);

        if (foundTaskIndex === -1) {
            // Task was NOT found
            return store;
        }
        // Set new queue position for task
        tasks[foundTaskIndex].is_queue_automation_locked = false;

        // Create new task map
        const tasksMap: { [taskId in ID]: TaskResource } = {};
        for (const task of tasks) {
            tasksMap[task.id] = task;
        }

        return {
            ...store,
            tasks: {
                ...store.tasks,
                [payload.type]: tasksMap,
            },
        };
    },

    swapTasks: (store, payload: { type: OpenTaskType; dragIndex: number; hoverIndex: number }) => {
        if (!store) {
            return null;
        }

        // Get task by type
        const tasks = Object.values(store.tasks[payload.type]);

        // Get drag task
        const dragTask = tasks[payload.dragIndex] ? { ...tasks[payload.dragIndex] } : null;

        // Get hover task
        const hoverTask = tasks[payload.hoverIndex] ? { ...tasks[payload.hoverIndex] } : null;

        if (!dragTask || !hoverTask) {
            return store;
        }

        // Swap tasks
        tasks[payload.dragIndex] = hoverTask;
        tasks[payload.hoverIndex] = dragTask;

        // Create new task map
        const tasksMap: { [taskId in ID]: TaskResource } = {};
        for (const task of tasks) {
            tasksMap[task.id] = task;
        }

        return {
            ...store,
            tasks: {
                ...store.tasks,
                [payload.type]: tasksMap,
            },
        };
    },

    swapTaskQueue: (
        store,
        payload: {
            activeTaskId: ID;
            backlogTaskId: ID;
            isQueueAutomationLockPreserved: boolean;
        }
    ) => {
        if (!store) {
            return store;
        }

        // Copy active & backlog tasks
        const copyActiveTasks = [...Object.values(store.tasks.active)];
        const copyBacklogTasks = [...Object.values(store.tasks.backlog)];

        // Find active & backlog task indices
        const activeTaskIndex = copyActiveTasks.findIndex((task) => task.id === payload.activeTaskId);
        const backlogTaskIndex = copyBacklogTasks.findIndex((task) => task.id === payload.backlogTaskId);

        if (activeTaskIndex === -1 || backlogTaskIndex === -1) {
            // Not found
            return store;
        }

        // Get tasks
        const activeTask = copyActiveTasks[activeTaskIndex];
        const backlogTask = copyBacklogTasks[backlogTaskIndex];

        // Update data
        activeTask.queue = TaskQueue.BACKLOG;
        backlogTask.queue = TaskQueue.ACTIVE;
        if (payload.isQueueAutomationLockPreserved) {
            const tempQueueAutomationLocked = activeTask.is_queue_automation_locked;
            activeTask.is_queue_automation_locked = backlogTask.is_queue_automation_locked;
            backlogTask.is_queue_automation_locked = tempQueueAutomationLocked;
        } else {
            activeTask.is_queue_automation_locked = false;
            backlogTask.is_queue_automation_locked = false;
        }

        // Swap tasks
        const tempTask = { ...activeTask };
        copyActiveTasks[activeTaskIndex] = backlogTask;
        copyBacklogTasks[backlogTaskIndex] = tempTask;

        // Create new task maps
        const activeTasksMap: { [taskId in ID]: TaskResource } = {};
        const backlogTasksMap: { [taskId in ID]: TaskResource } = {};
        copyActiveTasks.forEach((task) => (activeTasksMap[task.id] = task));
        copyBacklogTasks.forEach((task) => (backlogTasksMap[task.id] = task));

        return {
            ...store,
            tasks: {
                ...store.tasks,
                active: activeTasksMap,
                backlog: backlogTasksMap,
            },
        };
    },
});
