import { Button, Intent, Popover, PopoverProps } from '@blueprintjs/core';
import Flex from '@components/Flex';
import React, { ReactNode, useEffect } from 'react';
import { Content, List } from './styled';
import DevText from '@components/Text';

export interface PlatformListFilterWithDynamicSearchProps<T> {
    loading?: boolean;
    removable?: boolean;
    title: string;
    search: string;
    maxHeight?: number;
    maxListHeight?: number;
    skeletonList?: ID[];
    foundList: T[];
    selectedList: T[];
    foundItemRenderer: (item: T) => JSX.Element;
    emptyListRenderer: () => JSX.Element;
    selectedItemRenderer: (item: T) => JSX.Element;
    searchInputRenderer: () => JSX.Element;
    skeletonItemRenderer: (id: ID) => JSX.Element;
    onSearch: (newSearch: string) => void;
    onApply: () => void;
    onRemove: () => void;
}

export type Props<T> = PlatformListFilterWithDynamicSearchProps<T> &
    Omit<PopoverProps, 'content'> & { children: ReactNode };

function PlatformListFilterWithDynamicSearch<T>({
    loading = false,
    removable = true,
    title,
    search,
    maxHeight,
    maxListHeight,
    skeletonList = [],
    foundList,
    selectedList,
    foundItemRenderer,
    emptyListRenderer,
    selectedItemRenderer,
    searchInputRenderer,
    skeletonItemRenderer,
    onSearch,
    onApply,
    onRemove,
    onClose,

    children,
    ...props
}: Props<T>) {
    useEffect(() => {
        const timeoutId = setTimeout(() => onSearch(search), 300);

        return () => {
            clearTimeout(timeoutId);
        };

        // eslint-disable-next-line
    }, [search]);

    const handleClosePopover = (e: React.SyntheticEvent<HTMLElement, Event>) => {
        onApply();
        onClose && onClose(e);
    };

    const renderSelectedList = () => {
        if (selectedList.length === 0) {
            return null;
        }

        return (
            <List className="custom-thin-scroll mb-1" $maxListHeight={maxListHeight}>
                {selectedList.map((item) => selectedItemRenderer(item))}
            </List>
        );
    };

    const renderSearchInput = () => searchInputRenderer();

    const renderFoundList = () => {
        if (loading) {
            return <List>{skeletonList.map((item) => skeletonItemRenderer(item))}</List>;
        } else {
            if (foundList.length > 0) {
                return (
                    <List className="custom-thin-scroll" $maxListHeight={maxListHeight}>
                        {foundList.map((item) => foundItemRenderer(item))}
                    </List>
                );
            } else {
                if (search.trim().length > 0) {
                    return emptyListRenderer();
                }
            }
        }

        return null;
    };

    const renderPopoverContent = () => {
        const buttonVisibility = removable ? 'visible' : 'hidden';

        return (
            <Content $maxHeight={maxHeight}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <DevText muted>{title}</DevText>

                    <Button
                        minimal
                        icon="trash"
                        intent={Intent.DANGER}
                        style={{ visibility: buttonVisibility }}
                        onClick={onRemove}
                    />
                </Flex>
                {renderSelectedList()}
                <div className="mb-2">{renderSearchInput()}</div>
                {renderFoundList()}
            </Content>
        );
    };

    return (
        <Popover content={renderPopoverContent()} onClose={handleClosePopover} {...props}>
            {children}
        </Popover>
    );
}

export default PlatformListFilterWithDynamicSearch;
