/*
  Filter accepts:
  - query: string;                                                      // Search input (string)
  - team_id: ID[];                                                      // List with dynamic search (Team)
  - is_shutdown: '1' | '0';                                             // List (Flag filter)
*/

enum PlatformCoreUserFilterType {
    TEAMS = 'TEAMS',
    ARCHIVED = 'ARCHIVED',
}

export default PlatformCoreUserFilterType;
