import VideoViewer from '@components/VideoViewer';
import {
    $queuedVideoTimelinePositionMS,
    $selectedTaskDeliverable,
} from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import { UnitUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { useSetTimeMarker } from '../../../../hooks';
import { resetQueuedVideoTimelinePositionMS } from '../../../../store/events';
import { Wrapper } from './styled';

const TaskDeliverableVideoViewer: React.FC = () => {
    const { handleSetTimeMarker } = useSetTimeMarker();

    const videoPlayerRef = useRef<ReactPlayer | null>(null);

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);
    const queuedVideoTimelinePositionMS = useStore($queuedVideoTimelinePositionMS);

    useEffect(() => {
        if (!queuedVideoTimelinePositionMS) {
            // Timeline position is NOT set or was reset
            return;
        }

        if (videoPlayerRef.current) {
            // Reference to video player exist
            videoPlayerRef.current.seekTo(UnitUtils.msToSec(queuedVideoTimelinePositionMS), 'seconds');
        }

        // Reset queued video timeline position
        resetQueuedVideoTimelinePositionMS();
    }, [queuedVideoTimelinePositionMS]);

    if (!selectedTaskDeliverable || !selectedTaskDeliverable.file) {
        return null;
    }
    const handleOnVideoPlayerProgress = (state: OnProgressProps) => {
        handleSetTimeMarker(state.playedSeconds);
    };

    return (
        <Wrapper>
            <VideoViewer
                withAspectRatio
                url={selectedTaskDeliverable.file.url}
                ref={videoPlayerRef}
                onProgress={handleOnVideoPlayerProgress}
                // onReady={handleOnVideoPlayerReady}
            />
        </Wrapper>
    );
};

export default TaskDeliverableVideoViewer;
