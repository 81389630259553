import { PriceRecurringInterval } from 'dy-frontend-http-repository/lib/data/enums';
import { PriceResource as SubscriptionPriceResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { PriceResource as SubscriptionPlanPriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';

/**
 * Check if all prices have the same recurring interval & recurring interval count
 * @param prices subscription items prices or subscription plan prices
 * @returns {boolean} "all prices recurring interval the same" flag
 */
export default function checkIfPricesHaveTheSameRecurringInterval(
    prices: (SubscriptionPriceResource | SubscriptionPlanPriceResource | null)[]
) {
    if (prices.length === 0) {
        return true;
    }

    let isAllPricesRecurringIntervalTheSame = true;
    let priceRecurringInterval: PriceRecurringInterval | null = null;
    let priceRecurringIntervalCount: number | null = null;

    for (let i = 0; i < prices.length; i++) {
        const price = prices[i];

        if (price === null) {
            // Price do NOT exist
            return false;
        }

        if (priceRecurringInterval === null || priceRecurringIntervalCount === null) {
            // recurring interval & recurring interval current were NOT initialized yet
            priceRecurringInterval = price.recurring_interval;
            priceRecurringIntervalCount = price.recurring_interval_count;
        } else {
            // recurring interval & recurring interval already have been initialized
            if (
                price.recurring_interval !== priceRecurringInterval ||
                price.recurring_interval_count !== priceRecurringIntervalCount
            ) {
                isAllPricesRecurringIntervalTheSame = false;
                break;
            }
        }
    }

    return isAllPricesRecurringIntervalTheSame;
}
