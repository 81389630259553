import testBrandProfileImage from '@assets/test_category.jpeg';
import { Card, Intent, Tag } from '@blueprintjs/core';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Image from '@components/Image';
import DevText from '@components/Text';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface BrandProfileListItemProps {
    imageSrc: string | null;
    archivedAt: string | null;
    title: string;
    createdAt: string;
}

export type Props = BrandProfileListItemProps & HTMLAttributes<HTMLDivElement>;

const BrandProfileListItem: React.FC<Props> = ({ imageSrc, title, createdAt, archivedAt, ...props }) => {
    const renderBrandProfileImage = () => {
        return (
            <Image
                width="100%"
                height="200px"
                borderTopLeftRadius="inherit"
                borderTopRightRadius="inherit"
                objectFit="cover"
                src={imageSrc ?? testBrandProfileImage}
            />
        );
    };

    const renderArchivedTag = () => {
        if (!archivedAt) {
            return null;
        }

        return (
            <Tag minimal className="mr-small" intent={Intent.DANGER}>
                Archived
            </Tag>
        );
    };

    return (
        <Card compact interactive {...props}>
            {renderBrandProfileImage()}

            <Box className='mt-2'>
                <Flex align="center">
                    {renderArchivedTag()}
                    <DevText>{title}</DevText>
                </Flex>
                <DevText muted>{moment(createdAt).fromNow()}</DevText>
            </Box>
        </Card>
    );
};

export default BrandProfileListItem;
