import PasswordLockButton from '@app/components/PasswordLockButton';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringEmailValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, IconName, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { Endpoints } from '@data/consts';
import { ModalProps } from '@modals/types';
import { createClient } from '@pages/Clients/store/effects';
import { ClientUserRef } from 'dy-frontend-http-repository/lib/modules/ClientUser/refs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = ModalProps;

const clientFirstNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const clientLastNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const clientCompanyNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];

const clientEmailValidators = [getStringRequiredValidator(), getStringEmailValidator()];

const clientPasswordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const CreateClientModal: React.FC<Props> = ({ closeModal }) => {
    const navigate = useNavigate();

    const clientFirstName = useTextFormField({
        id: 'client-first-name',
        validators: clientFirstNameValidators,
        initialValue: '',
    });

    const clientLastName = useTextFormField({
        id: 'client-last-name',
        validators: clientLastNameValidators,
        initialValue: '',
    });

    const clientCompanyName = useTextFormField({
        id: 'client-company-name',
        validators: clientCompanyNameValidators,
        initialValue: '',
    });

    const clientEmail = useTextFormField({
        id: 'client-email',
        validators: clientEmailValidators,
        initialValue: '',
    });

    const clientPassword = useTextFormField({
        id: 'client-password',
        validators: clientPasswordValidators,
        initialValue: '',
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const form = useForm<ClientUserRef>({
        fields: [clientFirstName, clientLastName, clientCompanyName, clientEmail, clientPassword],
        apiCall: async () => {
            try {
                const clientUserRef = await createClient({
                    first_name: clientFirstName.value,
                    last_name: clientLastName.value,
                    company_name: clientCompanyName.value,
                    email: clientEmail.value,
                    password: clientPassword.value,
                });

                return { response: clientUserRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: clientUserRef }) => {
            closeModal?.();
            navigate(Endpoints.CLIENT_TASKS.replace(':clientId', clientUserRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create client account</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        <Grid lg={6}>
                            <InputFormField
                                field={clientFirstName}
                                formGroupProps={{ label: 'First name' }}
                                inputProps={{ placeholder: 'Enter first name' }}
                            />
                        </Grid>
                        <Grid lg={6}>
                            <InputFormField
                                field={clientLastName}
                                formGroupProps={{ label: 'Last name' }}
                                inputProps={{ placeholder: 'Enter last name' }}
                            />
                        </Grid>
                        <Grid lg={12}>
                            <InputFormField
                                field={clientCompanyName}
                                formGroupProps={{ label: 'Company name' }}
                                inputProps={{ placeholder: 'Enter company name' }}
                            />
                        </Grid>
                        <Grid lg={12}>
                            <InputFormField
                                field={clientEmail}
                                formGroupProps={{ label: 'Email' }}
                                inputProps={{ placeholder: 'Enter email' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            <InputFormField
                                field={clientPassword}
                                formGroupProps={{ label: 'Password' }}
                                inputProps={{
                                    type: isPasswordVisible ? 'text' : 'password',
                                    placeholder: 'Enter password',
                                    rightElement: (
                                        <PasswordLockButton
                                            isPasswordVisible={isPasswordVisible}
                                            changePasswordVisibility={setIsPasswordVisible}
                                        />
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateClientModal;
