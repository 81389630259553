import domain from './domain';
import { Modal } from '@modals/types';

export const openModalEvent = domain.createEvent<Omit<Modal, 'id'>>('openModalEvent');
export function openModal<T>(input: Omit<Modal<T>, 'id'>) {
  openModalEvent(input);
}

export const closeModal = domain.createEvent<string>('closeModal');

export const closeAllModals = domain.createEvent('closeAllModals');
