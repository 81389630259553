import React from 'react';
import { useStore } from 'effector-react';
import { $tasks } from '../../store/states';
import TaskCard from '@containers/components/TaskCard';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import Grid from '@components/Grid';
import { Icon, Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { fetchTasks } from '../../store/effects';

const TaskList: React.FC = () => {
    const tasks = useStore($tasks);
    const isFetchingTasks = useStore(fetchTasks.pending);

    // Tasks were NOT fetched yet
    if (!tasks) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    // Tasks were fetched, but where are no tasks existed for this client
    if (tasks.items.length === 0) {
        return (
            <NonIdealState
                icon={<Icon className="mb-2" icon="search" size={70} />}
                title={
                    <Heading className="mb-1" type="h4">
                        No tasks were found
                    </Heading>
                }
            />
        );
    }

    // TODO: add imageSrc whenever files will be implemented on backend
    // TODO: add opacity overlay over task card list whenever tasks are fetching
    return (
        <Grid container style={{ opacity: isFetchingTasks ? 0.4 : 1 }}>
            {tasks.items.map((task) => (
                <Grid className="mb-2" key={task.id} lg={4}>
                    <TaskCard task={task} />
                </Grid>
            ))}
        </Grid>
    );
};

export default TaskList;
