import { IconName } from '@blueprintjs/core';
import { PlatformClientUserFilterType } from '../enums';

const platformClientUserFiltersInformation: {
    [type in PlatformClientUserFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    /* Static */
    [PlatformClientUserFilterType.ARCHIVED]: {
        title: 'Archived',
        icon: 'remove',
        isPinned: false,
    },

    [PlatformClientUserFilterType.VERIFIED]: {
        title: 'Verified',
        icon: 'endorsed',
        isPinned: false,
    },

    /* Dynamic */
    // [PlatformClientUserFilterType.WORKSPACES]: {
    //     title: 'Workspaces',
    //     icon: 'applications',
    //     isPinned: true,
    // },

    [PlatformClientUserFilterType.LICENCE_PLANS]: {
        title: 'Plans',
        icon: 'properties',
        isPinned: true,
    },
};

export default platformClientUserFiltersInformation;
