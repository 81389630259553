import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';

const taskKanbanColumns: TaskState[] = [
    TaskState.PUBLISHED,
    TaskState.PICKUP_READY,
    TaskState.IN_PROGRESS,
    TaskState.INTERNAL_QA,
    TaskState.CLIENT_REVIEW,
    TaskState.REVISION_REQUIRED,
    TaskState.IN_REVISION,
    TaskState.PRE_DELIVERED,
];

export default taskKanbanColumns;
