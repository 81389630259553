import React, { useEffect } from 'react';
import { BreadcrumbProps, Intent, Spinner } from '@blueprintjs/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Outlet } from 'react-router-dom';
import { $coreUser } from './store/states';
import { fetchCoreUser, fetchCoreUserOccupancy } from './store/effects';
import { resetCoreUser, resetCoreUserOccupancy } from './store/events';
import Aside from './components/Aside';
import Tabs from './components/Tabs';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Grid from '@components/Grid';
import Flex from '@components/Flex';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { Endpoints } from '@app/data/consts';
import { usePageTitle } from '@app/hooks';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const CoreUser: React.FC = () => {
    const { changeTitle } = usePageTitle('Member');

    const navigate = useNavigate();
    const { coreUserId } = useParams() as { coreUserId: ID };

    const coreUser = useStore($coreUser);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        const breadcrumbItems: BreadcrumbProps[] = [
            {
                text: 'Members',
                onClick: () => navigate(Endpoints.CORE_USERS),
            },
            { text: `${coreUser.first_name} ${coreUser.last_name}` },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, [coreUser?.id]);

    useEffect(() => {
        fetchCoreUser(coreUserId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Member with ID of ${coreUserId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to core users page
                navigate(Endpoints.CORE_USERS);
            }
        });
    }, [coreUserId]);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Member - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    useEffect(() => {
        fetchCoreUserOccupancy({ user_id: [coreUserId] }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, [coreUserId]);

    useEffect(() => {
        return () => {
            resetCoreUser();
            resetCoreUserOccupancy();
            resetPageBreadcrumbs();
        };
    }, []);

    if (!coreUser) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <FixedWidthPageContainer>
            <Grid container>
                <Grid lg={3} xs={12}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9} xs={12}>
                    <Tabs className="mb-2" />
                    <Outlet />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default CoreUser;
