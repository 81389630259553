import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import InputFormField from '@app/components/InputFormField';
import { ModalProps } from '@app/containers/modals/types';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
} from '@app/hooks/validation/functions';
import { Card, Button, Divider, Intent } from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import { UpdateUserNotificationEmailInput } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import React from 'react';
import { notificationEmailsApi } from '../../store/apis';
import { updateNotificationEmail } from './store/effects';

const firstNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const lastNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];

export interface UpdateNotificationEmailModalProps {
    notificationEmailId: ID;
    name: string;
}

export type Props = ModalProps<UpdateNotificationEmailModalProps>;

const UpdateNotificationEmailModal: React.FC<Props> = ({ closeModal, data }) => {
    const firstName = useTextFormField({
        id: 'first-name',
        validators: firstNameValidators,
        initialValue: data ? data.name.substring(0, data.name.indexOf(' ')) : '',
    });

    const lastName = useTextFormField({
        id: 'last-name',
        validators: lastNameValidators,
        initialValue: data ? data.name.substring(data.name.indexOf(' ') + 1) : '',
    });

    const form = useForm({
        fields: [firstName, lastName],
        apiCall: async () => {
            try {
                // Create input
                const updateNotificationEmailInput: UpdateUserNotificationEmailInput = {
                    name: `${firstName.value} ${lastName.value}`.trim(),
                };

                // Update notification email
                const notificationEmailRef = await updateNotificationEmail({
                    id: data?.notificationEmailId ?? '0',
                    input: updateNotificationEmailInput,
                });

                // Update local state
                notificationEmailsApi.update({ id: notificationEmailRef.id, input: updateNotificationEmailInput });

                // Close modal
                closeModal?.();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update notification email</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        {/* First name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={firstName}
                                formGroupProps={{ label: 'First name' }}
                                inputProps={{ placeholder: 'Enter first name' }}
                            />
                        </Grid>

                        {/* Last name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={lastName}
                                formGroupProps={{ label: 'Last name' }}
                                inputProps={{ placeholder: 'Enter last name' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex className="mt-2" direction="row" justify="flex-end">
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={form.isSubmitting}
                            disabled={form.isSubmitting || form.hasFieldErrors}
                            type="submit"
                        >
                            Yes, update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateNotificationEmailModal;
