import { GetValidatorFunction } from '@app/hooks/validation/types';
import richEditorPlugins from '@components/RichEditor/plugins';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';

const defaultMinRichStringLength = 2;
const getDefaultRichStringLengthWentBeyondMinRichStringLengthMessage = (minRichStringLength: number) =>
    `String should be greater or equal to ${minRichStringLength}`;

const getRichStringMinLengthValidator: GetValidatorFunction<
    {
        minRichStringLength: number;
        richStringLengthWentBeyondMinRichStringLengthValueMessageFormatter?: (minRichStringLength: number) => string;
    },
    string
> = (
    options = {
        minRichStringLength: defaultMinRichStringLength,
        richStringLengthWentBeyondMinRichStringLengthValueMessageFormatter:
            getDefaultRichStringLengthWentBeyondMinRichStringLengthMessage,
    }
) => {
    const { minRichStringLength, richStringLengthWentBeyondMinRichStringLengthValueMessageFormatter } = options;

    if (minRichStringLength <= 0) {
        throw new Error(
            `Validator getRichStringMinLengthValidator expects positive value, but got ${minRichStringLength}`
        );
    }

    return async (value) => {
        if (new RichTextFormat(value, richEditorPlugins).getContentAsPlainText().length >= minRichStringLength) {
            // Passed validation
            return null;
        } else {
            // Did NOT pass validation
            if (richStringLengthWentBeyondMinRichStringLengthValueMessageFormatter) {
                return richStringLengthWentBeyondMinRichStringLengthValueMessageFormatter(minRichStringLength);
            } else {
                return getDefaultRichStringLengthWentBeyondMinRichStringLengthMessage(minRichStringLength);
            }
        }
    };
};

export default getRichStringMinLengthValidator;
