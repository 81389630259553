import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import {
    CreateWorkspaceSignupInput,
    FinalizeWorkspaceSignupInput,
} from 'dy-frontend-http-repository/lib/modules/WorkspaceSignup/inputs';
import { WorkspaceRef, WorkspaceSignupRef } from 'dy-frontend-http-repository/lib/modules/WorkspaceSignup/refs';
import { WorkspaceKeyAvailabilityResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceKey/resources';
import { WorkspaceInitDataResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceInitData/resources';

// Fetch workspace by key
export const fetchWorkspaceByKey = domain.createEffect<{ workspaceKey: string }, WorkspaceInitDataResource>({
    name: 'fetchWorkspaceByKey',
    handler: async ({ workspaceKey }) => {
        return await repository.workspaceInitData().getByKey(workspaceKey);
    },
});

// Check workspace key availability
export const checkWorkspaceKeyAvailability = domain.createEffect<
    { workspaceKey: string },
    WorkspaceKeyAvailabilityResource
>({
    name: 'checkWorkspaceKeyAvailability',
    handler: async ({ workspaceKey }) => {
        return await repository.workspaceKey().getAvailability(workspaceKey);
    },
});

// Create new workspace signup
export const createWorkspaceSignup = domain.createEffect<CreateWorkspaceSignupInput, WorkspaceSignupRef>({
    name: 'createWorkspaceSignup',
    handler: async (input) => {
        return await repository.workspaceSignup().create(input);
    },
});

// Generate new workspace signup verification
export const generateWorkspaceSignupVerification = domain.createEffect<{ workspaceSignupId: ID }, WorkspaceSignupRef>({
    name: 'generateWorkspaceSignupVerification',
    handler: async ({ workspaceSignupId }) => {
        return await repository.workspaceSignup().generateVerification(workspaceSignupId);
    },
});

// Finalize workspace signup
export const finalizeWorkspaceSignup = domain.createEffect<
    { workspaceSignupId: ID; input: FinalizeWorkspaceSignupInput },
    WorkspaceRef
>({
    name: 'finalizeWorkspaceSignup',
    handler: async ({ workspaceSignupId, input }) => {
        return await repository.workspaceSignup().finalize(workspaceSignupId, input);
    },
});
