import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import TextAreaFormField from '@components/TextAreaFormField';
import { subscriptionCancelReasonInformation } from '@data/consts';
import { ModalProps } from '@modals/types';
import { SubscriptionCancelReason } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import React from 'react';
import { cancelSubscription } from './store/effects';
import Heading from '@components/Heading';

export interface CancelSubscriptionModalProps {
    subscriptionId: ID;
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<CancelSubscriptionModalProps>;

const subscriptionCancellationReasonValidators = [getStringRequiredValidator()];
const subscriptionCancellationCommentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 1000 }),
];

const CancelSubscriptionModal: React.FC<Props> = ({ closeModal, data }) => {
    const subscriptionCancellationReason = useCustomSelectFormField<string | null>({
        id: 'subscription-cancellation-reason',
        initialValue: null,
        formatValue: (date) => (date ? date.toString() : ''),
        validators: subscriptionCancellationReasonValidators,
    });

    const subscriptionCancellationComment = useTextFormField({
        id: 'subscription-pause-unused-period',
        initialValue: '',
        validators: subscriptionCancellationCommentValidators,
    });

    const form = useForm({
        fields: [subscriptionCancellationReason, subscriptionCancellationComment],
        apiCall: async () => {
            try {
                const subscriptionRef = await cancelSubscription({
                    subscriptionId: data!.subscriptionId,
                    input: {
                        reason: subscriptionCancellationReason.value! as SubscriptionCancelReason,
                        comment: subscriptionCancellationComment.value!,
                    },
                });

                if (data?.refetchOnSuccess) {
                    const refetchResponse = await data!.refetchOnSuccess();
                }

                return { response: subscriptionRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Cancel subscription</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <form onSubmit={form.handleFormSubmit}>
                    <SelectFormField
                        field={subscriptionCancellationReason}
                        items={Object.values(SubscriptionCancelReason)}
                        formGroupProps={{ label: 'Reason' }}
                        selectProps={{ selectButtonProps: { placeholder: 'Select subscription cancellation reason' } }}
                        itemToReadableFormatter={(item) => subscriptionCancelReasonInformation[item]}
                    />

                    <TextAreaFormField
                        field={subscriptionCancellationComment}
                        formGroupProps={{ label: 'Comment' }}
                        textAreaProps={{ placeholder: 'Enter comment' }}
                    />

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Close
                        </Button>

                        <Button disabled={form.hasFieldErrors} loading={form.isSubmitting} type="submit">
                            Cancel subscription
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CancelSubscriptionModal;
