import { useCustomMultiSelectFormField, useForm } from '@app/hooks';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRendererProps, MultiSelect } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { UpdateTaskCategoryInput } from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import React, { useState } from 'react';
import { taskCategoryApi } from '../../store/apis';
import { updateTaskCategory } from '../../store/effects';
import { commonDeliverableExtensions, commonSourceExtensions } from './consts';

export interface UpsertTaskCategoryExtensionsModalProps {
    taskCategoryId: ID;
    extensionMode: 'deliverable' | 'source';
    selectedExtensions: string[];
}

type Props = ModalProps<UpsertTaskCategoryExtensionsModalProps>;

const selectedExtensionsValidators = [];
const getSelectedItemIndex = (item: string, items: string[]) => {
    const selectedItemIndex = items.indexOf(item);
    return selectedItemIndex;
};
const UpsertTaskCategoryExtensionsModal: React.FC<Props> = ({ closeModal, data }) => {
    const initializeExtensionItems = (selectedItems: string[] = []) => {
        if (!data) {
            return [];
        }

        const extensionMode = data.extensionMode;
        switch (extensionMode) {
            case 'deliverable':
                const deliverableExtensionsArray = [...commonDeliverableExtensions, ...selectedItems];
                return deliverableExtensionsArray.filter(
                    (ext, index) => deliverableExtensionsArray.indexOf(ext) === index
                );
            case 'source':
                const sourceExtensionsArray = [...commonSourceExtensions, ...selectedItems];
                return sourceExtensionsArray.filter((ext, index) => sourceExtensionsArray.indexOf(ext) === index);
            default:
                return [];
        }
    };

    const initializeSelectedExtensionItems = (selectedItems: string[]) => {
        if (!selectedItems || selectedItems.length === 0) {
            // Selected items were not passed
            return [];
        }

        return selectedItems;
    };

    const [extensionItems, setExtensionItems] = useState<string[]>(
        initializeExtensionItems(data?.selectedExtensions ?? [])
    );

    const selectedExtensions = useCustomMultiSelectFormField<string>({
        id: 'form-selected-extensions',
        validators: selectedExtensionsValidators,
        initialValue: initializeSelectedExtensionItems(data?.selectedExtensions ?? []),
        getSelectedItemIndex,
    });

    const form = useForm<TaskCategoryRef>({
        fields: [selectedExtensions],
        apiCall: async () => {
            try {
                let updateTaskCategoryInput: UpdateTaskCategoryInput;

                switch (data!.extensionMode) {
                    case 'deliverable':
                        updateTaskCategoryInput = { extensions: selectedExtensions.value };
                        break;
                    case 'source':
                        updateTaskCategoryInput = { source_extensions: selectedExtensions.value };
                        break;
                }

                const taskCategoryRef = await updateTaskCategory({
                    id: data!.taskCategoryId,
                    input: updateTaskCategoryInput,
                });

                taskCategoryApi.update({ input: updateTaskCategoryInput });

                return { response: taskCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderExtensionsMultiSelect = () => {
        const handleUnselectItem = (item: string) => {
            const selectedItemIndex = getSelectedItemIndex(item, selectedExtensions.value);

            if (selectedItemIndex === -1) {
                return;
            }

            // update extensionItems array
            // check if item existed in commonExtensions with which extensionItems was initialized
            const commonItems =
                data.extensionMode === 'deliverable' ? commonDeliverableExtensions : commonSourceExtensions;
            const commonItemIndex = commonItems.indexOf(item);
            if (commonItemIndex === -1) {
                // item was NOT in commonItems with which extensionItems was initialized

                // remove item from extensionItems
                setExtensionItems((prevItems) => {
                    const copiedArray = prevItems.slice();
                    copiedArray.splice(commonItemIndex, 1);
                    return copiedArray;
                });
            }

            const copiedArray = selectedExtensions.value.slice();
            copiedArray.splice(selectedItemIndex, 1);
            selectedExtensions.handleSetValue(copiedArray);
        };

        const handleItemSelect = (item: string) => {
            const selectedItemIndex = getSelectedItemIndex(item, selectedExtensions.value);

            if (selectedItemIndex === -1) {
                selectedExtensions.handleSetValue([...selectedExtensions.value, item]);

                // update extensionItems array
                // check if item already exist in extensionItems array
                if (extensionItems.indexOf(item) === -1) {
                    // add item which do not exist in extensionItems
                    setExtensionItems((prevValue) => [...prevValue, item]);
                }
            } else {
                handleUnselectItem(item);
            }
        };

        const handleCreateNewItem = (query: string) => {
            const items = query.split(',');
            return items.map((item) => item.trim());
        };

        const renderTag = (item: string) => {
            return item.toUpperCase();
        };

        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            return (
                <MenuItem
                    key={item}
                    text={item.toUpperCase()}
                    active={getSelectedItemIndex(item, selectedExtensions.value) !== -1}
                    onClick={handleClick}
                />
            );
        };

        const renderNewItem = (query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) => {
            return <MenuItem icon="add" text={query.toUpperCase()} active={active} onClick={handleClick} />;
        };

        const singleItemFilter: ItemPredicate<string> = (query, item, _index, exactMatch) => {
            const normalizedTitle = item.toLowerCase();
            const normalizedQuery = query.toLowerCase();

            if (exactMatch) {
                return normalizedTitle === normalizedQuery;
            } else {
                // check if "normalizedQuery" is substring of "normalizedTitle" string, if not, then -1 will be returned
                return normalizedTitle.indexOf(normalizedQuery) >= 0;
            }
        };

        return (
            <MultiSelect<string>
                resetOnSelect={true}
                placeholder="Select or add new extension"
                items={extensionItems}
                selectedItems={selectedExtensions.value}
                createNewItemFromQuery={handleCreateNewItem}
                createNewItemRenderer={renderNewItem}
                tagRenderer={renderTag}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                itemPredicate={singleItemFilter}
                onRemove={handleUnselectItem}
                tagInputProps={{ large: false }}
                menuProps={{
                    className: 'custom-thin-scroll',
                }}
                popoverProps={{
                    matchTargetWidth: true,
                }}
            />
        );
    };

    return (
        <Overlay isOpen canEscapeKeyClose onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update Extensions</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        <Grid>
                            {/* Deliverables/Source extensions */}
                            <FormGroup label={data.extensionMode === 'source' ? 'Source Extensions' : 'Extensions'}>
                                {renderExtensionsMultiSelect()}
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertTaskCategoryExtensionsModal;
