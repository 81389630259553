import { createApi } from 'effector';
import { $notifications } from './states';
import { NotificationListResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';

export const notificationsApi = createApi($notifications, {
    // Add at tail after successful fetch
    addAtTailAfterSuccessfulFetch: (store, payload: { notifications: NotificationListResource }) => {
        // Skip if notifications were NOT fetched yet
        if (!store) {
            return { ...payload.notifications };
        }

        return {
            ...store,
            cursor: payload.notifications.cursor,
            entity: {
                task: {
                    ...(store.entity.task ?? {}),
                    ...(payload.notifications.entity.task ?? {}),
                },
                task_deliverable: {
                    ...(store.entity.task_deliverable ?? {}),
                    ...(payload.notifications.entity.task_deliverable ?? {}),
                },
                task_deliverable_comment: {
                    ...(store.entity.task_deliverable_comment ?? {}),
                    ...(payload.notifications.entity.task_deliverable_comment ?? {}),
                },
                task_message: {
                    ...(store.entity.task_message ?? {}),
                    ...(payload.notifications.entity.task_message ?? {}),
                },
            },
            list: [...store.list, ...payload.notifications.list],
        };
    },
});
