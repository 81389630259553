import styled, { css } from 'styled-components';

export const Wrapper = styled.ul`
    display: flex;
    cursor: pointer;
`;

// export const AvatarWrapper = styled.li<{
//     $isTight: boolean;
// }>`
//     border-radius: 50%;
//     ${({ $isTight }) =>
//         $isTight
//             ? css`
//                   & + & {
//                       margin-left: -7px;
//                   }
//               `
//             : css`
//                   margin: 0 3px 0 0;
//               `}
// `;

// export const AvatarCounter = styled.li<{ $size: number }>`
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     border-radius: 50%;

//     ${({ theme, $size }) => css`
//         width: ${$size}px;
//         height: ${$size}px;
//         min-width: ${$size}px;
//         min-height: ${$size}px;
//         max-width: ${$size}px;
//         max-height: ${$size}px;
//         background: ${Colors.DARK_GRAY2};
//     `}
// `;
