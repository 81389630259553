import domain from './domain';
import { PublishTaskStep } from '../enums';

// Change step
export const changeStep = domain.createEvent<PublishTaskStep>('changeStep');

// Reset step
export const resetStep = domain.createEvent('resetStep');

// Reset task category
export const resetTaskCategory = domain.createEvent('resetTaskCategory');

// // Reset client payment account
// export const resetClientPaymentAccount = domain.createEvent('resetClientPaymentAccount');

// Reset client credit wallet
export const resetClientCreditWallet = domain.createEvent('resetClientCreditWallet');

// // Reset client payment methods
// export const resetClientPaymentMethods = domain.createEvent('...');

// Reset client payment account
export const resetClientQuotaUsage = domain.createEvent('resetClientQuotaUsage');

// Reset client payment account
export const resetClientComputedLicence = domain.createEvent('resetClientComputedLicence');
