import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Callout, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useForm } from '@app/hooks';
import { shutdownUser } from './store/effects';

export interface ShutdownUserModalProps {
    userId: ID;
    // TODO: use reason enum here
    onShutdownUserSuccess: (reason: string) => void;
}

type Props = ModalProps<ShutdownUserModalProps>;

// TODO: Add ability to select reason for shutdown
const ShutdownUserModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);

    const form = useForm({
        fields: [],
        apiCall: async () => {
            // TODO: use reason enum here
            const shutdownReason = 'unknown';

            try {
                const a = await shutdownUser({ id: data?.userId ?? '0', input: { reason: shutdownReason } });
                data?.onShutdownUserSuccess(shutdownReason);
                setIsCompleted(true);
            } catch (e) {
                throw e;
            }

            return { response: true };
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return null;
        }

        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Account is shutdown</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    User account is now shutdown
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <Callout intent={Intent.WARNING} className="mb-2">
                    Warning! You about to shutdown this user account
                </Callout>

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed with account shutdown?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button intent={Intent.DANGER} loading={form.isSubmitting} type="submit">
                            Yes, shutdown this account
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Shutdown account
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default ShutdownUserModal;
