import React, { useEffect, useState } from 'react';
import { ModalProps } from '@modals/types';
import {
    PlanTaskCategoryAttachmentResource,
    TaskCategoryGroupResource,
} from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Elevation, FormGroup, HTMLSelect, Intent, Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { PlanTaskCategoryAttachmentInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import { fetchTaskCategories, updatePlanTaskCategoryAttachmentList } from '@pages/Plan/store/effects';
import { planApi } from '@pages/Plan/store/apis';
import { $taskCategories } from '../../store/states';
import { useStore } from 'effector-react';
import TaskCategoryGroupTag from '@app/containers/components/TaskCategoryGroupTag';

export interface SetPlanTaskCategoryListModalProps {
    planId: ID;
    initialAttachmentList: PlanTaskCategoryAttachmentResource[];
}

type Props = ModalProps<SetPlanTaskCategoryListModalProps>;

const SetPlanTaskCategoryListModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskCategories = useStore($taskCategories);

    const [isProcessing, setIsProcessing] = useState(false);
    const [attachments, setAttachments] = useState<PlanTaskCategoryAttachmentResource[] | null>(null);
    const [selectedTaskCategoryId, setSelectedTaskCategoryId] = useState<ID | null>(null);

    useEffect(() => {
        fetchTaskCategories().catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        if (!data) {
            handleModalClose();
            return;
        }

        // Skip if already initialized
        if (attachments !== null) {
            return;
        }

        setAttachments(data.initialAttachmentList);
    }, [data]);

    const handleModalClose = () => {
        if (isProcessing) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const handleSubmit = () => {
        if (attachments === null) {
            return;
        }

        // Create output data
        const nextAttachmentList: PlanTaskCategoryAttachmentInput[] = attachments.map((i) => ({
            task_category_id: i.task_category.id,
            is_excluded: i.is_excluded,
        }));

        // Send data
        setIsProcessing(true);
        updatePlanTaskCategoryAttachmentList({ planId: data.planId, input: nextAttachmentList })
            .then(() => {
                // Touch API
                planApi.updateTaskCategoryList(attachments);

                // Close modal
                closeModal?.();
            })
            .catch(console.error) // TODO: Handle error
            .finally(() => setIsProcessing(false));
    };

    // Updates is_excluded field of a specific attachment
    const handleIncludeModeChange = (index: number, isIncluded: boolean) => {
        if (!attachments) {
            return;
        }

        const next = [...attachments];
        next[index].is_excluded = !isIncluded;
        setAttachments(next);

        console.log(next);
    };

    // Removes attachment by index
    const handleRemoveAttachment = (index: number) => {
        if (!attachments) {
            return;
        }

        setAttachments(attachments.filter((_, i) => i !== index));
    };

    // Add new attachment
    const handleAddAttachment = () => {
        if (!attachments) {
            return;
        }

        if (!taskCategories) {
            return;
        }

        const foundTaskCategory = taskCategories.items.find((category) => category.id === selectedTaskCategoryId);

        if (!foundTaskCategory) {
            return;
        }

        setAttachments([
            ...attachments,
            {
                is_excluded: false,
                task_category: {
                    id: foundTaskCategory.id,
                    archived_at: foundTaskCategory.archived_at,
                    groups: foundTaskCategory.groups.map((category) => ({
                        id: category.id,
                        color: category.color,
                        archived_at: null,
                        title: category.title,
                    })),
                    is_enabled: foundTaskCategory.is_enabled,
                    title: foundTaskCategory.title,
                },
            },
        ]);
    };

    const renderAttachmentItem = (index: number, item: PlanTaskCategoryAttachmentResource) => {
        const renderGroupList = (groups: TaskCategoryGroupResource[]) => {
            if (!groups.length) {
                return <DevText muted>Does not belong to any group</DevText>;
            }

            return (
                <div className="mt-small">
                    {groups.map((g) => (
                        <TaskCategoryGroupTag
                            key={`category-${item.task_category.id}-g-${g.id}`}
                            name={g.title}
                            color={g.color}
                        />
                    ))}
                </div>
            );
        };

        return (
            <Card compact elevation={Elevation.TWO}>
                <Flex align="center" justify="space-between">
                    <div>
                        <DevText>{item.task_category.title}</DevText>
                        {renderGroupList(item.task_category.groups)}
                    </div>

                    <Flex align="center">
                        <div className="mr-2">
                            <Button
                                small
                                disabled={isProcessing}
                                minimal={item.is_excluded}
                                intent={item.is_excluded ? Intent.NONE : Intent.PRIMARY}
                                className="mr-small"
                                onClick={() => handleIncludeModeChange(index, true)}
                            >
                                Included
                            </Button>
                            <Button
                                small
                                disabled={isProcessing}
                                minimal={!item.is_excluded}
                                intent={item.is_excluded ? Intent.DANGER : Intent.NONE}
                                className="mr-small"
                                onClick={() => handleIncludeModeChange(index, false)}
                            >
                                Excluded
                            </Button>
                        </div>
                        <Button disabled={isProcessing} icon="cross" onClick={() => handleRemoveAttachment(index)} />
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderAttachmentList = () => {
        if (attachments === null) {
            return null;
        }

        if (attachments.length === 0) {
            return <DevText muted>No request categories added</DevText>;
        }

        return attachments.map((i, index) => (
            <div key={`attachment-category-${i.task_category.id}`} className="mb-1">
                {renderAttachmentItem(index, i)}
            </div>
        ));
    };

    const renderTaskCategoryPicker = () => {
        if (!attachments) {
            return null;
        }

        if (!taskCategories) {
            return null;
        }

        const availableTaskCategories = taskCategories.items.filter(
            (category) => !attachments.find((a) => a.task_category.id === category.id)
        );

        return (
            <FormGroup label="Select request category to add">
                <Flex>
                    <HTMLSelect
                        fill
                        disabled={!taskCategories || availableTaskCategories.length === 0}
                        className="mr-1"
                        value={String(selectedTaskCategoryId)}
                        onChange={(e) => setSelectedTaskCategoryId(e.target.value)}
                    >
                        <option value={undefined}>Select request category</option>
                        {availableTaskCategories.map((taskCategory) => (
                            <option key={`qt-${taskCategory.id}`} value={taskCategory.id}>
                                {taskCategory.title}
                            </option>
                        ))}
                    </HTMLSelect>

                    <Button
                        disabled={selectedTaskCategoryId === null}
                        onClick={() => {
                            if (selectedTaskCategoryId === null) {
                                return;
                            }

                            handleAddAttachment();
                            setSelectedTaskCategoryId(null);
                        }}
                    >
                        Add
                    </Button>
                </Flex>
            </FormGroup>
        );
    };

    if (!attachments) {
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Specific plan request category rules</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isProcessing} />
                </Flex>

                <Divider className="mb-2" />

                <div className="mb-2">{renderTaskCategoryPicker()}</div>

                <Divider className="mb-2" />

                <div className="mb-2">{renderAttachmentList()}</div>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Save about request categories?</DevText>
                    <div>
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={isProcessing}>
                            No, cancel
                        </Button>
                        <Button intent={Intent.PRIMARY} loading={isProcessing} onClick={handleSubmit}>
                            Yes, save data
                        </Button>
                    </div>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default SetPlanTaskCategoryListModal;
