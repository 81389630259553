import { useForm, useTextFormField } from '@app/hooks';
import { getStringMaxLengthValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Intent, Switch, Tag, TextArea } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { $authorizedUser } from '@containers/store/states';
import {
    pointMarkerByTaskDeliverableCommentIdMapApi,
    taskDeliverableCommentsApi,
} from '@pages/Task/modals/TaskDeliverableViewerModal/store/apis';
import {
    createTaskDeliverableComment,
    updateTaskDeliverableComment,
} from '@pages/Task/modals/TaskDeliverableViewerModal/store/effects';
import {
    $pointMarker,
    $selectedTaskDeliverable,
    $timeMarker,
} from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import {
    CreateTaskDeliverableCommentInput,
    UpdateTaskDeliverableCommentInput,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/inputs';
import { TaskDeliverableCommentRef } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/refs';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { FileUtils, TimeUtils, UnitUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { resetPointMarker, resetTimeMarker } from '../../../../store/events';

export interface UpsertTaskDeliverableCommentFormProps {
    parentTaskDeliverableCommentId: ID | null; // This one will be null whenever adding "root" comment and NOT null whenever adding "reply" comment
    comment: TaskDeliverableCommentResource | null; // This one will be null whenever creating new comment and NOT null whenever updating existing comment
    onSubmittingComplete?: () => void;
    onCloseForm?: () => void;
    isPublicCommentAllowed?: boolean;
    isInternalCommentAllowed?: boolean;
}

export type Props = UpsertTaskDeliverableCommentFormProps & HTMLAttributes<HTMLFormElement>;

const taskDeliverableCommentContentValidators = [
    getStringRequiredValidator(),
    getStringMaxLengthValidator({ maxStringLength: 1000 }),
];

const UpsertTaskDeliverableCommentForm: React.FC<Props> = ({
    parentTaskDeliverableCommentId,
    comment,
    onSubmittingComplete,
    onCloseForm,
    isPublicCommentAllowed = false,
    isInternalCommentAllowed = false,
    ...props
}) => {
    const me = useStore($authorizedUser);
    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);
    const timeMarker = useStore($timeMarker);
    const pointMarker = useStore($pointMarker);

    const taskDeliverableCommentContent = useTextFormField({
        id: 'task-deliverable-comment-content',
        validators: taskDeliverableCommentContentValidators,
        initialValue: comment ? comment.content : '',
    });

    const [withMarker, setWithMarker] = useState(false);
    const [isTaskDeliverableCommentInternal, setIsTaskDeliverableCommentInternal] = useState<boolean | null>(
        comment ? comment.is_internal : false
    );
    const [isCommentVisibilityLocked, setIsCommentVisibilityLocked] = useState(false);

    useEffect(() => {
        // Skip if both options are allowed
        if (isPublicCommentAllowed && isInternalCommentAllowed) {
            setIsCommentVisibilityLocked(false);
            return;
        }

        // Force set to specific visibility option, and lock ability to switch
        if (isPublicCommentAllowed) {
            setIsTaskDeliverableCommentInternal(false);
            setIsCommentVisibilityLocked(true);
            return;
        }
        if (isInternalCommentAllowed) {
            setIsTaskDeliverableCommentInternal(true);
            setIsCommentVisibilityLocked(true);
        }

    }, [isPublicCommentAllowed, isInternalCommentAllowed])

    const form = useForm<TaskDeliverableCommentRef>({
        fields: [taskDeliverableCommentContent],
        apiCall: async () => {
            try {
                if (!selectedTaskDeliverable) {
                    throw new Error('Task deliverable is not selected');
                }

                if (!me) {
                    throw new Error('User is not authorized');
                }

                let taskDeliverableCommentRef: TaskDeliverableCommentRef | null = null;

                if (comment) {
                    // Updating existing task deliverable comment

                    // Create input
                    const updateTaskDeliverableCommentInput: UpdateTaskDeliverableCommentInput = {
                        content: taskDeliverableCommentContent.value,
                        is_internal: !!isTaskDeliverableCommentInternal,
                        marker_point: comment.marker_point,
                        marker_point_area: null,
                        marker_time: comment.marker_time,
                    };

                    // Update task deliverable comment
                    taskDeliverableCommentRef = await updateTaskDeliverableComment({
                        id: comment.id,
                        input: updateTaskDeliverableCommentInput,
                    });

                    // Update local state for list of task deliverable comments
                    taskDeliverableCommentsApi.updateTaskDeliverableComment({
                        id: taskDeliverableCommentRef.id,
                        parentTaskDeliverableCommentId: parentTaskDeliverableCommentId,
                        input: updateTaskDeliverableCommentInput,
                    });

                    // Clear content field
                    taskDeliverableCommentContent.clear();
                } else {
                    // Creating new task deliverable comment

                    // Create input
                    const createTaskDeliverableCommentInput: CreateTaskDeliverableCommentInput = {
                        parent_task_deliverable_comment_id: parentTaskDeliverableCommentId,
                        task_deliverable_id: selectedTaskDeliverable.id,
                        content: taskDeliverableCommentContent.value,
                        is_internal: !!isTaskDeliverableCommentInternal,
                        marker_time: withMarker && timeMarker !== null ? Math.floor(timeMarker) : undefined,
                        marker_point: withMarker && pointMarker !== null ? pointMarker : undefined,
                    };

                    // Create comment
                    taskDeliverableCommentRef = await createTaskDeliverableComment(createTaskDeliverableCommentInput);

                    // Update local state for list of task deliverable comments
                    taskDeliverableCommentsApi.createTaskDeliverableComment({
                        id: taskDeliverableCommentRef.id,
                        user: me.user,
                        input: createTaskDeliverableCommentInput,
                    });

                    // Add new marker to task deliverable comments markers map
                    pointMarkerByTaskDeliverableCommentIdMapApi.add({
                        taskDeliverableId: taskDeliverableCommentRef.id,
                        markerPoint: withMarker ? pointMarker : null,
                    });

                    // Clear content field
                    taskDeliverableCommentContent.clear();

                    // Reset attach marker flag
                    setWithMarker(false);

                    // Reset markers
                    resetTimeMarker();
                    resetPointMarker();
                }

                return { response: taskDeliverableCommentRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            if (onSubmittingComplete) {
                onSubmittingComplete();
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    // TODO: [AUTH]
    const isUpdateAllowed = true;
    const isCreateAllowed = true;
    const isCreateInternalAllowed = true;

    if (!isCreateAllowed && !isUpdateAllowed) {
        // User can't create internal or public task deliverable comment
        return null;
    }

    // Check if task deliverable update allowed
    if (comment) {
        if (!isUpdateAllowed) {
            // Comment for update is present but update is NOT allowed
            return null;
        }
    }

    const renderTaskDeliverableCommentVisibilitySwitch = () => {
        return (
            <Switch
                label="Private"
                checked={!!isTaskDeliverableCommentInternal}
                disabled={isCommentVisibilityLocked}
                onChange={() => setIsTaskDeliverableCommentInternal((prevValue) => !prevValue)}
            />
        );
    };

    const renderAttachMarkerSwitch = (disabled: boolean) => {
        return (
            <Switch
                disabled={disabled}
                label="Attach marker"
                checked={withMarker}
                onChange={() => setWithMarker((prevValue) => !prevValue)}
            />
        );
    };

    const renderTimeMarkerControls = () => {
        return (
            <Flex className="mb-1" align="center" justify="space-between">
                {renderAttachMarkerSwitch(timeMarker === null)}
                <Tag>{TimeUtils.toHHMMSS(`${UnitUtils.msToSec(timeMarker ?? 0)}`)}</Tag>
            </Flex>
        );
    };

    const renderPointMarkerControls = () => {
        return <div className="mb-1">{renderAttachMarkerSwitch(pointMarker === null)}</div>;
    };

    const renderMarkerControls = () => {
        if (!selectedTaskDeliverable) {
            // Task deliverable is NOT fetched yet
            return null;
        }

        if (!selectedTaskDeliverable.file) {
            // File is NOT present on task deliverable
            return null;
        }

        if (parentTaskDeliverableCommentId) {
            // Do NOT allow add markers for "reply" comments
            return null;
        }

        if (comment) {
            // Do NOT allow update markers for existed comment
            return null;
        }

        if (FileUtils.isImage(selectedTaskDeliverable.file.extension)) {
            // Image or GIF
            return renderPointMarkerControls();
        }

        if (FileUtils.isVideo(selectedTaskDeliverable.file.extension)) {
            // Video
            return renderTimeMarkerControls();
        }

        if (FileUtils.isPdf(selectedTaskDeliverable.file.extension)) {
            // PDF
            return renderPointMarkerControls();
        }

        return null;
    };

    const renderMainControls = () => {
        const renderCloseFormButton = () => {
            if (!onCloseForm) {
                return null;
            }

            return (
                <Button minimal className="mr-1" icon="cross" intent={Intent.DANGER} onClick={onCloseForm}>
                    Close
                </Button>
            );
        };

        const renderSubmitFormButton = () => {
            let submitButtonText = 'Send';

            if (comment) {
                submitButtonText = 'Update';
            }

            return (
                <Button
                    disabled={form.hasFieldErrors}
                    loading={form.isSubmitting}
                    type="submit"
                    intent={Intent.PRIMARY}
                >
                    {submitButtonText}
                </Button>
            );
        };

        return (
            <Flex align="center" justify="space-between">
                {renderTaskDeliverableCommentVisibilitySwitch()}
                <Flex align="center">
                    {renderCloseFormButton()}
                    {renderSubmitFormButton()}
                </Flex>
            </Flex>
        );
    };

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            <TextArea
                fill
                className="mb-1"
                placeholder="Enter your comment"
                value={taskDeliverableCommentContent.value}
                onChange={taskDeliverableCommentContent.handleChange}
                onBlur={taskDeliverableCommentContent.handleBlur}
            />

            {renderMarkerControls()}
            {renderMainControls()}
        </form>
    );
};

export default UpsertTaskDeliverableCommentForm;
