import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultMaxStringLength = 100;
const getDefaultStringLengthWentBeyondMaxStringLengthMessage = (maxStringLength: number) =>
    `String should be less or equal to ${maxStringLength}`;

const getStringMaxLengthValidator: GetValidatorFunction<
    {
        maxStringLength: number;
        stringLengthWentBeyondMaxStringLengthValueMessageFormatter?: (maxStringLength: number) => string;
    },
    string
> = (
    options = {
        maxStringLength: defaultMaxStringLength,
        stringLengthWentBeyondMaxStringLengthValueMessageFormatter:
            getDefaultStringLengthWentBeyondMaxStringLengthMessage,
    }
) => {
    const { maxStringLength, stringLengthWentBeyondMaxStringLengthValueMessageFormatter } = options;

    if (maxStringLength <= 0) {
        throw new Error(`Validator getStringMaxLengthValidator expects positive value, but got ${maxStringLength}`);
    }

    return async (value) => {
        if (value.length <= maxStringLength) {
            // Passed validation
            return null;
        } else {
            // Did NOT pass validation
            if (stringLengthWentBeyondMaxStringLengthValueMessageFormatter) {
                return stringLengthWentBeyondMaxStringLengthValueMessageFormatter(maxStringLength);
            } else {
                return getDefaultStringLengthWentBeyondMaxStringLengthMessage(maxStringLength);
            }
        }
    };
};

export default getStringMaxLengthValidator;
