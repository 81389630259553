import StripeForm from '@app/containers/components/StripeForm';
import { Button, Card, Divider } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import React from 'react';
import { fetchClientPaymentMethods } from '../../store/effects';

export interface AddPaymentMethodModalProps {
    paymentAccountId: ID;
}

type Props = ModalProps<AddPaymentMethodModalProps>;

const AddPaymentMethodModal: React.FC<Props> = ({ closeModal, data }) => {
    if (!data) {
        closeModal?.();
        return null;
    }

    const handleFetchClientPaymentMethods = async () => {
        try {
            await fetchClientPaymentMethods(data.paymentAccountId);
            closeModal?.();
        } catch (e) {
            throw e;
        }
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Add new payment method</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <StripeForm
                    paymentAccountId={data.paymentAccountId}
                    onCreatePaymentMethod={handleFetchClientPaymentMethods}
                    buttonTitle="Add payment method"
                />
            </Card>
        </Overlay>
    );
};

export default AddPaymentMethodModal;
