import DevText from '@app/components/Text';
import { Callout, Intent } from '@blueprintjs/core';
import { NotificationResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import React from 'react';
import { UserUtils } from '../../utils';
import NotificationTemplate from '../NotificationTemplate';

export interface GenericNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
}

type Props = GenericNotificationProps;

const GenericNotification: React.FC<Props> = ({ authorizedUserId, notification }) => {
    const renderContent = () => {
        return (
            <Callout icon={null} intent={Intent.WARNING}>
                <DevText monospace>- Type: {notification.activity.type}</DevText>

                <DevText monospace>
                    - Entity: {notification.activity.entity_type} / {notification.activity.entity_id}
                </DevText>

                <DevText monospace>
                    - Actor: {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)}
                </DevText>
            </Callout>
        );
    };

    return (
        <NotificationTemplate
            title="Unknown notification"
            icon="help"
            at={notification.activity.at}
            content={renderContent()}
        />
    );
};

export default GenericNotification;
