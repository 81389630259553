import domain from "./domain";
import { resetCoupon, resetSubscriptionCreateModalData } from "./events";
import { CollectionResource } from "dy-frontend-http-repository/lib/data/resources";
import { PlanResource } from "dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources";
import { AvailableCouponResource } from "dy-frontend-http-repository/lib/modules/Coupon/resources";
import { PaymentMethodListItemResource } from "dy-frontend-http-repository/lib/modules/PaymentMethod/resources";

// List of available subscription plans
export const $subscriptionPlans = domain
    .createStore<CollectionResource<PlanResource, {}> | null>(null)
    .reset(resetSubscriptionCreateModalData);

// List of available payment methods
export const $paymentMethods = domain
    .createStore<CollectionResource<PaymentMethodListItemResource, {}> | null>(null)
    .reset(resetSubscriptionCreateModalData);


// Resolved coupon
export const $coupon = domain
    .createStore<AvailableCouponResource | null>(null)
    .reset(resetSubscriptionCreateModalData, resetCoupon);
