import { GetValidatorFunction, ValidatorFunction } from '@app/hooks/validation/types';
import checkValidators from '../../checkValidators';

const getOptionalStringValidator: GetValidatorFunction<ValidatorFunction<string>[], string> = (validators = []) => {
    return async (value) => {
        if (value.length === 0) {
            return null;
        }

        return await checkValidators(value, validators);
    };
};

export default getOptionalStringValidator;
