import { TabItem } from '@app/data/types';
import { Endpoints } from '@data/consts';

const tabs: TabItem[] = [
    { icon: 'saved', value: Endpoints.CORE_USER_TASKS, label: 'Requests' },
    { icon: 'chart', value: Endpoints.CORE_USER_TASK_FEEDBACK, label: 'Client Feedback' },
    // { icon: 'clipboard', value: Endpoints.CORE_USER_ESCALATION_LOG, label: 'Escalation log' },
    // { icon: 'chart', value: Endpoints.CORE_USER_STATISTICS, label: 'Statistics' },
    { icon: 'cog', value: Endpoints.CORE_USER_SETTINGS, label: 'Settings' },
    // { icon: 'key', value: Endpoints.CORE_USER_AUTH_ROLES, label: 'Auth Roles' },
];

export default tabs;
