import React, { HTMLAttributes } from 'react';
import { FormGroup, FormGroupProps, Intent } from '@blueprintjs/core';
import { ReturnValue as RichTextFormFieldReturnValue } from '@app/hooks/validation/useRichTextFormField';
import RichEditor, { Props as RichEditorProps } from '@components/RichEditor';

export interface RichEditorFormFieldProps {
    field: RichTextFormFieldReturnValue;
    formGroupProps?: FormGroupProps;
    richEditorProps?: Partial<RichEditorProps>;
}

export type Props = HTMLAttributes<HTMLDivElement> & RichEditorFormFieldProps;

const RichEditorFormField: React.FC<Props> = ({ field, formGroupProps, richEditorProps, ...props }) => {
    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <RichEditor
                error={!!field.error}
                value={field.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                {...richEditorProps}
            />
        </FormGroup>
    );
};

export default RichEditorFormField;
