import { TaskMessageResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import domain from './domain';

// Set editing task message
export const setEditingTaskMessage = domain.createEvent<TaskMessageResource | null>('setEditingTaskMessage');

// Reset editing task message
export const resetEditingTaskMessage = domain.createEvent('resetEditingTaskMessage');

// Reset task messages
export const resetTaskMessages = domain.createEvent('resetTaskMessages');
