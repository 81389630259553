import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringNumericValueMax = Infinity;
const defaultStringShouldBeNumericValueMessage = 'This field should be number';
const getDefaultNumericValueWentBeyondMaxValueMessage = (maxValue: number) =>
    `Number should be less or equal to ${maxValue}`;
const getStringNumericValueMaxValidator: GetValidatorFunction<
    {
        maxValue: number;
        stringShouldBeNumericValueMessage?: string;
        numericValueWentBeyondMaxValueMessageFormatter?: (minValue: number) => string;
    },
    string
> = (
    options = {
        maxValue: defaultStringNumericValueMax,
        stringShouldBeNumericValueMessage: defaultStringShouldBeNumericValueMessage,
        numericValueWentBeyondMaxValueMessageFormatter: getDefaultNumericValueWentBeyondMaxValueMessage,
    }
) => {
    const { maxValue, stringShouldBeNumericValueMessage, numericValueWentBeyondMaxValueMessageFormatter } = options;
    return async (value) => {
        if (isNaN(value as any)) {
            // Value is NOT number
            if (stringShouldBeNumericValueMessage) {
                return stringShouldBeNumericValueMessage;
            } else {
                return defaultStringShouldBeNumericValueMessage;
            }
        } else {
            // Value is number
            const parsedNumericValue = parseFloat(value);

            if (parsedNumericValue <= maxValue) {
                // Value passed min check
                return null;
            } else {
                // Value did NOT pass min check
                if (numericValueWentBeyondMaxValueMessageFormatter) {
                    return numericValueWentBeyondMaxValueMessageFormatter(maxValue);
                } else {
                    return getDefaultNumericValueWentBeyondMaxValueMessage(maxValue);
                }
            }
        }
    };
};

export default getStringNumericValueMaxValidator;
