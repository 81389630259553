import React, { HTMLAttributes } from 'react';
import { StyledPageContainer } from './styled';

export type Props = HTMLAttributes<HTMLDivElement>;

const PageContainer: React.FC<Props> = ({ children, ...props }) => {
    return <StyledPageContainer {...props}>{children}</StyledPageContainer>;
};

export default PageContainer;
