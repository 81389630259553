import { useEffect, useState } from 'react';

const usePageTitle = (
    title: string,
    defaultTitle = 'Internal Platform',
    shouldChangeToDefaultTitleOnUnmount = false
) => {
    const [currentTitle, setCurrentTitle] = useState<string>(title);

    useEffect(() => {
        document.title = currentTitle;
    }, [currentTitle]);

    useEffect(() => {
        return () => {
            if (shouldChangeToDefaultTitleOnUnmount) {
                document.title = defaultTitle;
            }
        };
    }, [defaultTitle, shouldChangeToDefaultTitleOnUnmount]);

    return {
        changeTitle: setCurrentTitle,
    };
};

export default usePageTitle;
