import styled, { css } from 'styled-components';
import { BoxProps } from './';

export const StyledBox = styled.div<{
    $position: BoxProps['position'];
    $zIndex: BoxProps['zIndex'];
    $top: BoxProps['top'];
    $right: BoxProps['right'];
    $bottom: BoxProps['bottom'];
    $left: BoxProps['left'];
    $width: BoxProps['width'];
    $height: BoxProps['height'];
    $maxWidth: BoxProps['maxWidth'];
    $maxHeight: BoxProps['maxHeight'];
    $minWidth: BoxProps['minWidth'];
    $minHeight: BoxProps['minHeight'];
    $backgroundColor: BoxProps['backgroundColor'];
    $backgroundColorOnHover: BoxProps['backgroundColorOnHover'];
    $borderRadius: BoxProps['borderRadius'];
    $borderTopLeftRadius: BoxProps['borderTopLeftRadius'];
    $borderTopRightRadius: BoxProps['borderTopRightRadius'];
    $borderBottomLeftRadius: BoxProps['borderBottomLeftRadius'];
    $borderBottomRightRadius: BoxProps['borderBottomRightRadius'];
    $padding: BoxProps['padding'];
    $paddingTop: BoxProps['paddingTop'];
    $paddingRight: BoxProps['paddingRight'];
    $paddingBottom: BoxProps['paddingBottom'];
    $paddingLeft: BoxProps['paddingLeft'];
    $margin: BoxProps['margin'];
    $marginTop: BoxProps['marginTop'];
    $marginRight: BoxProps['marginRight'];
    $marginBottom: BoxProps['marginBottom'];
    $marginLeft: BoxProps['marginLeft'];
    $borderColor: BoxProps['borderColor'];
    $borderSize: BoxProps['borderSize'];
    $cursor: BoxProps['cursor'];
}>`
    ${({ $position }) =>
        $position &&
        css`
            position: ${$position};
        `}

    ${({ $zIndex }) =>
        $zIndex &&
        css`
            z-index: ${$zIndex};
        `}


    ${({ $top }) =>
        $top &&
        css`
            top: ${$top};
        `}

    ${({ $right }) =>
        $right &&
        css`
            right: ${$right};
        `}

    ${({ $bottom }) =>
        $bottom &&
        css`
            bottom: ${$bottom};
        `}

    ${({ $left }) =>
        $left &&
        css`
            left: ${$left};
        `}

    ${({ $width }) =>
        $width &&
        css`
            width: ${$width};
        `}

    ${({ $height }) =>
        $height &&
        css`
            height: ${$height};
        `}
        
    ${({ $maxWidth }) =>
        $maxWidth &&
        css`
            max-width: ${$maxWidth};
        `}

    ${({ $maxHeight }) =>
        $maxHeight &&
        css`
            max-height: ${$maxHeight};
        `}

    ${({ $minWidth }) =>
        $minWidth &&
        css`
            min-width: ${$minWidth};
        `}

    ${({ $minHeight }) =>
        $minHeight &&
        css`
            min-height: ${$minHeight};
        `}

    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            background: ${$backgroundColor};
        `}

    ${({ $backgroundColorOnHover }) =>
        $backgroundColorOnHover &&
        css`
            &:hover {
                background: ${$backgroundColorOnHover};
            }
        `}

    ${({ $borderRadius }) =>
        $borderRadius &&
        css`
            border-radius: ${$borderRadius};
        `}

    ${({ $borderTopLeftRadius }) =>
        $borderTopLeftRadius &&
        css`
            border-top-left-radius: ${$borderTopLeftRadius};
        `}

    ${({ $borderTopRightRadius }) =>
        $borderTopRightRadius &&
        css`
            border-top-right-radius: ${$borderTopRightRadius};
        `}

    ${({ $borderBottomLeftRadius }) =>
        $borderBottomLeftRadius &&
        css`
            border-bottom-left-radius: ${$borderBottomLeftRadius};
        `}

    ${({ $borderBottomRightRadius }) =>
        $borderBottomRightRadius &&
        css`
            border-bottom-right-radius: ${$borderBottomRightRadius};
        `}

    ${({ $padding }) =>
        $padding &&
        css`
            padding: ${$padding};
        `}

    ${({ $paddingTop }) =>
        $paddingTop &&
        css`
            padding-top: ${$paddingTop};
        `}

    ${({ $paddingRight }) =>
        $paddingRight &&
        css`
            padding-right: ${$paddingRight};
        `}

    ${({ $paddingBottom }) =>
        $paddingBottom &&
        css`
            padding-bottom: ${$paddingBottom};
        `}

    ${({ $paddingLeft }) =>
        $paddingLeft &&
        css`
            padding-left: ${$paddingLeft};
        `}

    ${({ $margin }) =>
        $margin &&
        css`
            margin: ${$margin};
        `}

    ${({ $marginTop }) =>
        $marginTop &&
        css`
            margin-top: ${$marginTop};
        `}

    ${({ $marginRight }) =>
        $marginRight &&
        css`
            margin-right: ${$marginRight};
        `}

    ${({ $marginBottom }) =>
        $marginBottom &&
        css`
            margin-bottom: ${$marginBottom};
        `}

    ${({ $marginLeft }) =>
        $marginLeft &&
        css`
            margin-left: ${$marginLeft};
        `}    

    ${({ $cursor }) =>
        $cursor &&
        css`
            cursor: ${$cursor};
        `}    

    ${({ $borderColor, $borderSize }) => {
        if (!$borderColor) {
            return;
        }

        let borderSize = 1;

        if ($borderSize) {
            borderSize = $borderSize;
        }

        return css`
            box-shadow: 0px 0px 0px ${borderSize}px ${$borderColor};
        `;
    }}
`;
