import PageTabs from '@app/components/PageTabs';
import React, { HTMLAttributes } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './consts';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { Endpoints } from '@data/consts';

type Props = HTMLAttributes<HTMLDivElement>;

const Tabs: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { coreUserId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const getFormattedPath = (path: string) => {
        return path.replace(':coreUserId', coreUserId!);
    };

    // List of skipped tab router
    const ignoreTabRoutes: string[] = [];

    // Auth: task
    if (!permissions.isEnabled.task) {
        ignoreTabRoutes.push(Endpoints.CORE_USER_TASK_FEEDBACK);
    }

    const collectedTabs = tabs.filter((tab) => !ignoreTabRoutes.includes(tab.value))

    return (
        <PageTabs
            pathname={pathname}
            visibleAmount={7}
            tabs={collectedTabs}
            onSelectTab={navigate}
            pathFormatter={getFormattedPath}
            {...props}
        />
    );
};

export default Tabs;
