import { Alignment, Button, Card, Classes, Icon, Intent, Spinner, Switch } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { openModal } from '@modals/store/events';
import { $client, $computedLicence, $quotaUsage } from '@pages/Client/store/states';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import CreateTaskModal, { CreateTaskModalProps } from './modals/CreateTaskModal';
import { clientOpenTaskListDataApi } from './store/apis';
import { changeTaskPositionWithinSameQueue, fetchClientOpenTaskList } from './store/effects';
import { resetClientOpenTaskListData } from './store/events';
import { $clientOpenTaskListData } from './store/states';
import NonIdealState from '@app/components/NonIdealState';
import { useNavigate, useParams } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { PlatformTaskFilter } from '@app/containers/pages/Tasks/valueObjects';
import qs from 'qs';
import DraggableOpenTaskCard from './components/DraggableOpenTaskCard';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import Grid from '@app/components/Grid';
import DevText from '@app/components/Text';
import { HTTPErrorType, QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { ToastUtils } from '@app/data/utils';

const ClientTasks: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Client Tasks');

    const navigate = useNavigate();
    const client = useStore($client);
    const computedLicence = useStore($computedLicence);
    const quotaUsage = useStore($quotaUsage);

    const clientOpenTaskListData = useStore($clientOpenTaskListData);
    const isChangingTaskPositionWithinSameQueue = useStore(changeTaskPositionWithinSameQueue.pending);

    const [isActiveTaskArrangementTurnedOn, setIsActiveTaskArrangementTurnedOn] = useState(false);
    const [isBacklogTaskArrangementTurnedOn, setIsBacklogTaskArrangementTurnedOn] = useState(false);

    useEffect(() => {
        if (!client) {
            return;
        }

        // No permissions
        if (!permissions.isEnabled.task) {
            return;
        }

        fetchClientOpenTaskList({ filter: { user_id: client.id } })
            .then((openTaskList) => clientOpenTaskListDataApi.initialize({ openTaskList }))
            .catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Requests for client with ID of ${client.id} were not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to client tasks page
                    navigate(Endpoints.CLIENTS);
                }
            });

        return () => {
            resetClientOpenTaskListData();
        };

        // eslint-disable-next-line
    }, [client?.id]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Tasks - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    if (!permissions.isEnabled.task) {
        return null;
    }

    if (!client) {
        return null;
    }

    if (!clientOpenTaskListData) {
        // Client open task list data is NOT fetched yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        const handleNavigateToAllClientTasks = () => {
            const taskFilterQueryParametersString = qs.stringify(
                PlatformTaskFilter.convertTaskFilterInputToTaskFilterQueryParameters(1, { owner_user_id: [client.id] })
            );
            console.log('taskFilterQueryParametersString: ', taskFilterQueryParametersString);
            navigate(`${Endpoints.TASKS}?${taskFilterQueryParametersString}`);
        };

        const renderCreateNewTaskButton = () => {
            if (!permissions.isRoot.task && !permissions.has(TaskPermission.CREATE_DRAFT)) {
                return null;
            }

            return (
                <Button
                    icon="new-object"
                    intent={Intent.PRIMARY}
                    onClick={() =>
                        openModal<CreateTaskModalProps>({
                            ModalComponent: CreateTaskModal,
                            data: {
                                userId: client.id,
                            },
                        })
                    }
                >
                    Create new request
                </Button>
            );
        };

        return (
            <Flex justify="space-between" align="center">
                <Heading type="h3">Current clients requests</Heading>
                <div>
                    {permissions.isEnabled.task && (
                        <Button minimal className="mr-1" onClick={handleNavigateToAllClientTasks}>
                            View all requests
                        </Button>
                    )}
                    {renderCreateNewTaskButton()}
                </div>
            </Flex>
        );
    };

    const renderTasksList = (tasks: TaskResource[], openTaskType: OpenTaskType, isDraggable: boolean) => {
        return tasks.map((task, index) => (
            <DraggableOpenTaskCard
                draggingEffectProcessing={isChangingTaskPositionWithinSameQueue}
                draggable={isDraggable}
                index={index}
                type={openTaskType}
                canBeMovedUp={index !== 0}
                canBeMovedDown={index !== tasks.length - 1}
                key={task.id}
                className={index === 0 ? '' : 'mt-1'}
                task={task}
                taskData={clientOpenTaskListData}
            />
        ));
    };

    const renderDragAndDropSwitch = (
        value: boolean,
        dispatch: React.Dispatch<React.SetStateAction<boolean>>,
        isVisible = true,
        label = 'Change request order'
    ) => {
        // Ensure allowed
        if (!permissions.isRoot.task && !permissions.has(TaskPermission.QUEUE_POSITION_MANAGE)) {
            return null;
        }

        if (!isVisible) {
            return null;
        }
        return (
            <Flex>
                {isChangingTaskPositionWithinSameQueue && <Spinner className="mr-1" size={18} />}
                <Switch
                    alignIndicator={Alignment.RIGHT}
                    label={label}
                    checked={value}
                    onChange={() => dispatch((prevValue) => !prevValue)}
                />
            </Flex>
        );
    };

    const renderPausedTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        const isDraggable = false;

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Paused
                </Heading>

                {renderTasksList(tasks, OpenTaskType.PAUSED, isDraggable)}
            </div>
        );
    };

    const renderActiveTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        const isDragAndDropSwitchVisible = tasks.length > 1;
        const isDraggable = tasks.length > 1 && isActiveTaskArrangementTurnedOn;

        return (
            <div>
                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <Heading type="h3">Active</Heading>

                    {renderDragAndDropSwitch(
                        isActiveTaskArrangementTurnedOn,
                        setIsActiveTaskArrangementTurnedOn,
                        isDragAndDropSwitchVisible
                    )}
                </Flex>

                {renderTasksList(tasks, OpenTaskType.ACTIVE, isDraggable)}
            </div>
        );
    };

    const renderBacklogTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        const isDragAndDropSwitchVisible = tasks.length > 1;
        const isDraggable = tasks.length > 1 && isBacklogTaskArrangementTurnedOn;

        return (
            <div>
                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <Heading type="h3">Backlog</Heading>

                    {renderDragAndDropSwitch(
                        isBacklogTaskArrangementTurnedOn,
                        setIsBacklogTaskArrangementTurnedOn,
                        isDragAndDropSwitchVisible
                    )}
                </Flex>

                {renderTasksList(tasks, OpenTaskType.BACKLOG, isDraggable)}
            </div>
        );
    };

    const renderOpenTasks = () => {
        const activeTasks = Object.values(clientOpenTaskListData.tasks[OpenTaskType.ACTIVE]);
        const backlogTasks = Object.values(clientOpenTaskListData.tasks[OpenTaskType.BACKLOG]);
        const pausedTasks = Object.values(clientOpenTaskListData.tasks[OpenTaskType.PAUSED]);

        const isTaskListEmpty = ![activeTasks, backlogTasks, pausedTasks].some((tasks) => tasks.length > 0);
        if (isTaskListEmpty) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            Clients not not have any active requests at the moment
                        </Heading>
                    }
                />
            );
        }

        return (
            <div>
                <div className="mb-3">{renderPausedTasks(pausedTasks)}</div>
                <div className="mb-3">{renderActiveTasks(activeTasks)}</div>
                <div>{renderBacklogTasks(backlogTasks)}</div>
            </div>
        );
    };

    const renderTaskCounterCards = () => {
        const isLoading = quotaUsage === null || computedLicence === null;

        let currentActiveTasks = 0;
        let currentBacklogTasks = 0;
        let totalActiveTasks = 0;
        let totalBacklogTasks = 0;

        if (quotaUsage && computedLicence) {
            currentActiveTasks =
                quotaUsage.items.find((quota) => quota.quota_type === QuotaType.USER_ACTIVE_TASK)?.value ?? 0;
            currentBacklogTasks =
                quotaUsage.items.find((quota) => quota.quota_type === QuotaType.USER_BACKLOG_TASK)?.value ?? 0;
            totalActiveTasks =
                computedLicence.quotas.find((quota) => quota.type === QuotaType.USER_ACTIVE_TASK)?.value ?? 0;
            totalBacklogTasks =
                computedLicence.quotas.find((quota) => quota.type === QuotaType.USER_BACKLOG_TASK)?.value ?? 0;
        }

        return (
            <Grid container>
                <Grid lg={6}>
                    <Card>
                        <Heading className="mb-small" type="h4">
                            Active requests
                        </Heading>
                        <Heading type="h4" className={['mb-1', isLoading ? Classes.SKELETON : ''].join(' ')}>
                            {currentActiveTasks} / {totalActiveTasks}
                        </Heading>
                        <DevText muted>Covered by current plan</DevText>
                    </Card>
                </Grid>
                <Grid lg={6}>
                    <Card>
                        <Heading className="mb-small" type="h4">
                            Backlog requests
                        </Heading>
                        <Heading type="h4" className={['mb-1', isLoading ? Classes.SKELETON : ''].join(' ')}>
                            {currentBacklogTasks} / {totalBacklogTasks}
                        </Heading>
                        <DevText muted>Covered by current plan</DevText>
                    </Card>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <div className="mb-2">{renderHeader()}</div>
            <div className="mb-2">{renderTaskCounterCards()}</div>
            {renderOpenTasks()}
        </div>
    );
};

export default ClientTasks;
