import React from 'react';
import { useStore } from 'effector-react';
import { $selectedTaskDeliverable } from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import RichText from '@components/RichText';

const TaskDeliverableTextViewer: React.FC = () => {
    const selectedTaskDeliverableViewer = useStore($selectedTaskDeliverable);

    if (!selectedTaskDeliverableViewer) {
        return null;
    }

    return <RichText value={selectedTaskDeliverableViewer.text_content} />;
};

export default TaskDeliverableTextViewer;
