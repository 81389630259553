import { $permissions } from '@app/containers/store/states';
import { usePageTitle } from '@app/hooks';
import {
    Button,
    Classes,
    Colors,
    Icon,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Spinner,
    Tag,
    Tooltip,
} from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import RouterLink from '@components/RouterLink';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { TeamParticipationResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { useRemoveTeamParticipant, useUpdateTeamParticipantRole } from '../../hooks';
import { $team, $userOccupancyByTeamParticipantUserIdMap } from '../../store/states';
import { TeamPermission } from 'dy-frontend-permissions/lib/permission';
import OccupancyRate from '@app/containers/components/OccupancyRate';

const TeamMembers: React.FC = () => {
    const permissions = useStore($permissions);

    usePageTitle('Team Members');

    const team = useStore($team);

    const { removeTeamParticipant } = useRemoveTeamParticipant();
    const { updateTeamParticipantRole } = useUpdateTeamParticipantRole();
    const userOccupancyByTeamParticipantUserIdMap = useStore($userOccupancyByTeamParticipantUserIdMap);

    const [userIdPendingActionResult, setUserIdPendingActionResult] = useState<ID | null>(null);

    if (!team) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const memberRoles = [TeamParticipationRole.MEMBER];
    const members = team.participants.filter((participant) => memberRoles.includes(participant.role));

    if (members.length === 0) {
        return (
            <NonIdealState
                icon={<Icon className="mb-2" icon="search" size={70} />}
                title={
                    <Heading className="mb-1" type="h4">
                        Team do not have any members yet
                    </Heading>
                }
            />
        );
    }

    const handleRemoveTeamParticipant = (userId: ID) => {
        setUserIdPendingActionResult(userId);

        removeTeamParticipant({ teamId: team.id, userId })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            })
            .finally(() => setUserIdPendingActionResult(null));
    };

    const handleUpdateTeamParticipant = (userId: ID, role: TeamParticipationRole) => {
        setUserIdPendingActionResult(userId);

        updateTeamParticipantRole({ teamId: team.id, userId, role })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            })
            .finally(() => setUserIdPendingActionResult(null));
    };

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th>Member</th>
                    <th>Latest clients</th>
                    <th>Active requests</th>
                    <th>Occupancy rate</th>
                    <th>Latest activity</th>
                    <th></th>
                </tr>
            </thead>
        );
    };

    const renderTableBody = () => {
        const renderMemberControlsButton = ({ user }: TeamParticipationResource) => {
            const isManagementAllowed = permissions.isRoot.team || permissions.has(TeamPermission.PARTICIPATION_MANAGE);
            if (!isManagementAllowed) {
                return null;
            }

            const renderMemberControlsMenu = () => {
                const renderPromoteMemberToTeamLeadMenuItem = () => {
                    // Get lead participant
                    const leadParticipant = team.participants.find(
                        (participant) => participant.role === TeamParticipationRole.LEAD
                    );

                    if (leadParticipant) {
                        return null;
                    }

                    return (
                        <MenuItem
                            icon="star"
                            text="Promote to team lead"
                            onClick={() => handleUpdateTeamParticipant(user.id, TeamParticipationRole.LEAD)}
                        />
                    );
                };

                const renderPromoteMemberToTeamManagerMenuItem = () => {
                    return (
                        <MenuItem
                            disabled={userIdPendingActionResult !== null}
                            icon="clean"
                            text="Promote to team manager"
                            onClick={() => handleUpdateTeamParticipant(user.id, TeamParticipationRole.MANAGER)}
                        />
                    );
                };

                const renderRemoveMemberFromTeamMenuItem = () => {
                    return (
                        <MenuItem
                            disabled={userIdPendingActionResult !== null}
                            icon="trash"
                            text="Remove from team"
                            intent={Intent.DANGER}
                            onClick={() => handleRemoveTeamParticipant(user.id)}
                        />
                    );
                };

                return (
                    <Menu>
                        {renderPromoteMemberToTeamLeadMenuItem()}
                        {renderPromoteMemberToTeamManagerMenuItem()}
                        {renderRemoveMemberFromTeamMenuItem()}
                    </Menu>
                );
            };

            return (
                <Popover content={renderMemberControlsMenu()}>
                    <Button
                        disabled={userIdPendingActionResult !== null}
                        loading={userIdPendingActionResult === user.id}
                        minimal
                        icon="more"
                    />
                </Popover>
            );
        };

        const renderTeamUserOccupancy = (userOccupancy: UserOccupancyResource | null) => {
            if (!userOccupancyByTeamParticipantUserIdMap) {
                return (
                    <>
                        <div className={`mb-small ${Classes.SKELETON}`}>
                            <Tag>Score: 0</Tag>
                        </div>
                    </>
                );
            }

            if (!userOccupancy) {
                return <DevText muted>No data yet</DevText>;
            }

            return (
                <>
                    <div className="mb-small">
                        <Tooltip
                            fill
                            content={
                                <div>
                                    <DevText>Total complexity: {userOccupancy.total_task_complexity}</DevText>
                                    <DevText>Average complexity: {userOccupancy.average_task_complexity}</DevText>
                                    <DevText>Max total complexity: {userOccupancy.max_total_task_complexity}</DevText>
                                </div>
                            }
                        >
                            <OccupancyRate interactive score={userOccupancy.score} />
                        </Tooltip>
                    </div>
                </>
            );
        };

        const renderRow = (teamParticipation: TeamParticipationResource) => {
            const { user } = teamParticipation;

            let userOccupancy: UserOccupancyResource | null = null;
            if (userOccupancyByTeamParticipantUserIdMap) {
                const occupancy = userOccupancyByTeamParticipantUserIdMap[user.id];
                if (occupancy) {
                    userOccupancy = occupancy;
                }
            }

            // Get avatar src
            let avatarSrc: string | null = null;
            if (user.image_hash) {
                avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
            }

            console.log('teamParticipation: ', teamParticipation);

            return (
                <tr key={user.id}>
                    {/* User info */}
                    <TableCell verticalAlign="middle">
                        <Flex align="center">
                            <Avatar
                                width="42px"
                                height="42px"
                                className="mr-1"
                                alt={`${user.first_name} ${user.last_name}`}
                                src={avatarSrc}
                            />
                            <Flex direction="column">
                                <Flex align="center">
                                    <RouterLink
                                        className="mr-1"
                                        color={Colors.WHITE}
                                        to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', user.id)}
                                    >
                                        {user.first_name} {user.last_name}
                                    </RouterLink>
                                </Flex>

                                <DevText muted>
                                    {user.company_position.length > 0 ? user.company_position : 'Team member'}
                                </DevText>
                            </Flex>
                        </Flex>
                    </TableCell>

                    {/* TODO: Latest clients */}
                    <TableCell verticalAlign="middle">
                        <DevText muted>No data</DevText>
                    </TableCell>

                    {/* TODO: Active requests */}
                    <TableCell verticalAlign="middle">
                        <DevText muted>No data</DevText>
                    </TableCell>

                    {/* Occupancy */}
                    <TableCell verticalAlign="middle">{renderTeamUserOccupancy(userOccupancy)}</TableCell>

                    {/* TODO: Latest activity */}
                    <TableCell verticalAlign="middle">
                        <DevText muted>No data</DevText>
                    </TableCell>

                    {/* Action */}
                    <TableCell verticalAlign="middle">{renderMemberControlsButton(teamParticipation)}</TableCell>
                </tr>
            );
        };

        return <tbody>{members.map(renderRow)}</tbody>;
    };

    return (
        <Table striped>
            {renderTableHeader()}
            {renderTableBody()}
        </Table>
    );
};

export default TeamMembers;
