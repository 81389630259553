import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { Button, Icon, Intent } from '@blueprintjs/core';
import { openModal } from '@modals/store/events';
import Grid from '@components/Grid';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import PaymentMethodListItem from './components/PaymentMethodListItem';
import Heading from '@components/Heading';
import AddPaymentMethodModal, { AddPaymentMethodModalProps } from '../../modals/AddPaymentMethodModal';
import { $clientPaymentAccount } from '@app/containers/pages/Client/store/states';
import { $permissions } from '@containers/store/states';

export type Props = HTMLAttributes<HTMLDivElement>;

const PaymentMethods: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    // Client payment account was not fetched yet
    if (!clientPaymentAccount) {
        return null;
    }

    const renderClientPaymentMethods = () => {
        // Check if payment methods exist
        if (clientPaymentAccount.payment_methods.length === 0) {
            return (
                <NonIdealState
                    className="mt-2"
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No payment methods were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <Grid container>
                {clientPaymentAccount.payment_methods.map((paymentMethod) => (
                    <Grid className="mt-2" key={paymentMethod.id} lg={4} xs={12}>
                        <PaymentMethodListItem paymentMethod={paymentMethod} />
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderCreatePaymentMethodButton = () => {
        // TODO: add permission
        const isAllowed = true;
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                onClick={() =>
                    openModal<AddPaymentMethodModalProps>({
                        ModalComponent: AddPaymentMethodModal,
                        data: {
                            paymentAccountId: clientPaymentAccount.id,
                        },
                    })
                }
            >
                Add
            </Button>
        );
    };

    return (
        <div {...props}>
            <Flex align="center" justify="space-between">
                <Heading type="h3">Payment methods</Heading>
                {renderCreatePaymentMethodButton()}
            </Flex>

            {renderClientPaymentMethods()}
        </div>
    );
};

export default PaymentMethods;
