import React, { HTMLAttributes } from 'react';
import { taskStateInformation } from '@data/consts';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/TaskKanban/resources';
import { TaskKanbanBoardFormatted } from '../../types';
import Flex from '@components/Flex';
import TaskKanbanCard from './components/TaskKanbanCard';
import { Header, StyledTaskKanbanColumn, TaskKanbanCardList } from './styled';
import DevText from '@components/Text';

export interface TaskKanbanColumnProps {
    taskState: TaskState;
    tasks: TaskResource[];
    taskKanbanBoardFormatted: TaskKanbanBoardFormatted;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskKanbanColumnProps;

const TaskKanbanColumn: React.FC<Props> = ({ taskState, tasks, taskKanbanBoardFormatted, ...props }) => {
    const { title, color } = taskStateInformation[taskState];

    const renderHeader = () => {
        return (
            <Header className="mb-2" $borderColor={color}>
                <Flex fullWidth align="center" justify="space-between">
                    <DevText>{title}</DevText>
                    <DevText muted>{tasks.length}</DevText>
                </Flex>
            </Header>
        );
    };

    const renderTaskKanbanCardList = () => {
        // TODO: sort by latest_activity_at field whenever backend developer add it
        // const sortedTaskKanbanList = tasks.sort((a, b) => {
        //     if (a.latest_activity_at === b.latest_activity_at) {
        //         return 0;
        //     } else if (a.latest_activity_at === null) {
        //         return 1;
        //     } else if (b.latest_activity_at === null) {
        //         return -1;
        //     } else {
        //         return moment(a.latest_activity_at).diff(b.latest_activity_at) < 0 ? 1 : -1;
        //     }
        // });

        return (
            <TaskKanbanCardList className="custom-thin-scroll">
                {tasks.map((task) => (
                    <TaskKanbanCard
                        key={task.id}
                        className="mb-2"
                        task={task}
                        color={color}
                        taskKanbanBoardFormatted={taskKanbanBoardFormatted}
                    />
                ))}
            </TaskKanbanCardList>
        );
    };

    return (
        <StyledTaskKanbanColumn {...props}>
            {/* Header */}
            {renderHeader()}

            {/* Cards list */}
            {renderTaskKanbanCardList()}
        </StyledTaskKanbanColumn>
    );
};

export default TaskKanbanColumn;
