import Heading from '@components/Heading';
import DevText from '@components/Text';
import { $task } from '@pages/Task/store/states';
import { TaskUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useMemo } from 'react';
import DesignDimensionsListItem from './components/DesignDimensionsListItem';

export type Props = HTMLAttributes<HTMLDivElement>;

const DesignDimensions: React.FC<Props> = (props) => {
    const task = useStore($task);

    const parsedDesignDimensions = useMemo(() => {
        if (!task) {
            return {};
        }

        return TaskUtils.normalizeDesignDimensions(task.dimensions);
    }, [task]);

    if (!task) {
        return null;
    }

    let designDimensionsContent;

    // Get design dimensions keys
    const parsedDesignDimensionsKeys = Object.keys(parsedDesignDimensions);

    // Task category design dimensions do NOT exist
    if (
        (parsedDesignDimensionsKeys.length === 1 && parsedDesignDimensions.dimensions.length === 0) ||
        parsedDesignDimensionsKeys.length < 1
    ) {
        designDimensionsContent = <DevText muted>Not provided</DevText>;
    } else {
        designDimensionsContent = Object.entries(parsedDesignDimensions).map(([dimensionTitle, dimensions]) => {
            // This will happen whenever there are no default design dimension, but there are non default dimensions exist
            if (dimensionTitle === 'dimensions' && dimensions.length === 0) {
                return null;
            }

            return (
                <DesignDimensionsListItem
                    className="mt-1"
                    key={dimensionTitle}
                    designDimensionTitle={TextFormatUtils.capitalize(dimensionTitle)}
                    designDimensions={dimensions}
                />
            );
        });
    }

    return (
        <div {...props}>
            <Heading type="h4" className="mb-2">
                Design dimensions
            </Heading>

            {designDimensionsContent}
        </div>
    );
};

export default DesignDimensions;
