import { useFetchAuthorizedUser, useLogout, useScrollToTop } from '@app/hooks';
import { fetchAuthorizedUserPermissions, fetchWorkspaceInitialDataByWorkspaceKey } from '@containers/store/effects';
import { $authorizedUser, $permissions, $workspaceInitData } from '@containers/store/states';
import Endpoints, { RouteTypes } from '@data/consts/endpoints';
import { LocalStorage } from '@data/enums';
import repository from 'dy-frontend-http-repository/lib/repository';
import { WorkspaceUrlUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { createSearchParams, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import SignIn from '@pages/Authentication';

import Client from '@pages/Client';
import ClientBilling from '@pages/Client/pages/ClientBilling';
import ClientBrandProfiles from '@pages/Client/pages/ClientBrandProfiles';
import ClientCredits from '@pages/Client/pages/ClientCredits';
import ClientNotes from '@pages/Client/pages/ClientNotes';
import ClientNotifications from '@pages/Client/pages/ClientNotifications';
import ClientSettings from '@pages/Client/pages/ClientSettings';
import ClientStatistics from '@pages/Client/pages/ClientStatistics';
import ClientTasks from '@pages/Client/pages/ClientTasks';
import ClientTaskFeedback from '../pages/Client/pages/ClientTaskFeedback';
import Clients from '@pages/Clients';

import CoreUserTasks from '@app/containers/pages/CoreUser/pages/CoreUserTasks';
import CoreUser from '@pages/CoreUser';
import CoreUserEscalationLog from '@pages/CoreUser/pages/CoreUserEscalationLog';
import CoreUserSettings from '@pages/CoreUser/pages/CoreUserSettings';
import CoreUserStatistics from '@pages/CoreUser/pages/CoreUserStatistics';
import CoreUsers from '@pages/CoreUsers';

import BrandProfile from '@pages/BrandProfile';

import Task from '@pages/Task';
import TaskDeliverables from '@pages/Task/pages/TaskDeliverables';
import TaskDeliverablesAll from '@pages/Task/pages/TaskDeliverables/pages/TaskDeliverablesAll';
import TaskDeliverablesApproved from '@pages/Task/pages/TaskDeliverables/pages/TaskDeliverablesApproved';
import TaskBriefing from '@pages/Task/pages/TaskInformation';
import TaskManagement from '@pages/Task/pages/TaskManagement';
import TaskMessages from '@pages/Task/pages/TaskMessages';
import Tasks from '@pages/Tasks';

import TaskClientFeedback from '@pages/TaskClientFeedback';

import Plan from '@pages/Plan';
import Plans from '@pages/Plans';

import Playground from '@pages/Playground';

import TaskCategories from '@containers/pages/TaskCategories';
import TaskCategoryGroups from '@containers/pages/TaskCategoryGroups';
import TaskMessageTemplates from '@pages/TaskMessageTemplates';

import TaskCategory from '@pages/TaskCategory';

import TaskKanbanBoard from '@pages/TaskKanbanBoard';

import Team from '@pages/Team';
import TeamMembers from '@pages/Team/pages/TeamMembers';
import TeamTasks from '@pages/Team/pages/TeamTasks';
import Teams from '@pages/Teams';

import Subscription from '@pages/Subscription';

import FullScreenSpinner from '@components/FullScreenSpinner';
import Layout from '@containers/components/Layout';
import ApplicationAccessRoute from './components/ApplicationAccessRoute';
import CoreUserTaskFeedback from '../pages/CoreUser/pages/CoreUserTaskFeedback';
import TeamTaskFeedback from '../pages/Team/pages/TeamTaskFeedback';
import ClientLicences from '../pages/Client/pages/ClientLicences';
import TaskCategoryFeaturedBlocks from '../pages/TaskCategoryFeaturedBlocks';

const AppRoutes: React.FC = () => {
    useScrollToTop();
    const logout = useLogout();
    const navigate = useNavigate();
    const location = useLocation();
    const fetchAuthorizedUser = useFetchAuthorizedUser();

    const me = useStore($authorizedUser);
    const workspaceInitData = useStore($workspaceInitData);
    const permissions = useStore($permissions);

    const [isInitialized, setIsInitialized] = useState(false);
    const [isReady, setIsReady] = useState(false);

    console.log('permissions: ', permissions);

    // TODO: output it to hooks with async function to avoid .then nesting for better readability
    useEffect(() => {
        const accessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

        if (accessToken === null) {
            // User do NOT have accessToken in localStorage

            setIsInitialized(true);
        } else {
            // User has accessToken in localStorage

            // Set access token
            repository.setAuthAccessTokenHeader(accessToken);

            // Try to fetch authorized user
            fetchAuthorizedUser()
                .then((e) => {
                    fetchAuthorizedUserPermissions()
                        .then(() => {
                            if (window.location.origin === process.env.INTERNAL_PLATFORM_URL) {
                                // User is at DotYeti internal platform

                                setIsInitialized(true);
                                return;
                            }

                            if (window.location.origin === process.env.WORKSPACE_BASE_PLATFORM_URL) {
                                // User is at workspace base platform url (without key)

                                setIsInitialized(true);
                                return;
                            }

                            if (!workspaceInitData) {
                                // Workspace init data was NOT fetched yet
                                fetchWorkspaceInitialDataByWorkspaceKey(
                                    WorkspaceUrlUtils.getWorkspaceKeyFromLocation(
                                        process.env.WORKSPACE_BASE_PLATFORM_WITH_WORKSPACE_KEY_URL ?? ''
                                    )
                                )
                                    .catch((e) => {
                                        // TODO: handle error
                                    })
                                    .finally(() => setIsInitialized(true));
                            }
                        })
                        .catch((e) => {
                            // TODO: handle error
                            console.error(e);
                        });
                })
                .catch((e) => {
                    // Access token invalid and need to be cleared from localStorage

                    logout();
                    console.error(e);
                    setIsInitialized(true);
                });
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Decide initial endpoint navigate to

        if (isInitialized) {
            // Routes were initialized

            let nextEndpoint: string;
            let nextEndpointSearch = '';

            // Parse query parameters
            const parsedQueryParameters = qs.parse(location.search, { ignoreQueryPrefix: true });

            // Get redirect url
            const { redirect, ...otherParsedQueryParameters } = parsedQueryParameters;

            // Get home endpoints
            const homeEndpoints = ['', '/', Endpoints.APP, `${Endpoints.APP}/`];

            if (me) {
                // Access token was valid and authorized user was successfully fetched
                nextEndpointSearch = qs.stringify(otherParsedQueryParameters);

                if (location.pathname.startsWith(RouteTypes.AUTH)) {
                    // User currently on the authentication page trying to log in into platform
                    nextEndpoint = Endpoints.TASKS;
                } else {
                    console.log('location.pathname: ', location.pathname);
                    if (homeEndpoints.some((endpoint) => endpoint === location.pathname)) {
                        // Do not allow going to /app, redirect to open tasks page
                        nextEndpoint = Endpoints.TASKS;
                    } else {
                        // Not /, meaning let's just go the pathname provided
                        nextEndpoint = location.pathname;
                    }
                }
            } else {
                // Access token did NOT exist OR access token is NOT valid

                if (window.location.origin === process.env.WORKSPACE_BASE_PLATFORM_URL) {
                    // User is at workspace base platform url (without key)
                    nextEndpoint = location.pathname;
                } else {
                    // User at some page, but he will be redirected to authentication page with ?redirect= query parameter to the page he is currently on

                    // Get path to redirect, we don't want to allow to redirect to / page, instead to open tasks page
                    let pathToRedirect = location.pathname;
                    if (homeEndpoints.some((endpoint) => endpoint === location.pathname)) {
                        pathToRedirect = Endpoints.TASKS;
                    }

                    nextEndpoint = Endpoints.AUTHENTICATION;
                    nextEndpointSearch =
                        location.pathname !== Endpoints.AUTHENTICATION
                            ? createSearchParams({ redirect: pathToRedirect }).toString()
                            : '';
                }
            }

            setIsReady(true);

            console.log('nextEndpoint: ', nextEndpoint);
            console.log('nextEndpointSearch: ', nextEndpointSearch);

            navigate({
                pathname: nextEndpoint,
                search: nextEndpointSearch,
            });
        }

        // eslint-disable-next-line
    }, [isInitialized, me]);

    if (!isReady) {
        return <FullScreenSpinner />;
    }

    return (
        <Routes>
            <Route path={Endpoints.AUTHENTICATION} element={<SignIn />} />

            <Route
                path={Endpoints.APP}
                element={
                    <ApplicationAccessRoute>
                        <Layout />
                    </ApplicationAccessRoute>
                }
            >
                {/* Clients */}
                <Route path={Endpoints.CLIENTS} element={<Clients />} />
                <Route path={Endpoints.CLIENT} element={<Client />}>
                    <Route path={Endpoints.CLIENT_NOTES} element={<ClientNotes />} />
                    <Route path={Endpoints.CLIENT_BILLING} element={<ClientBilling />} />
                    <Route path={Endpoints.CLIENT_BRAND_PROFILES} element={<ClientBrandProfiles />} />
                    <Route path={Endpoints.CLIENT_SETTINGS} element={<ClientSettings />} />
                    <Route path={Endpoints.CLIENT_STATISTICS} element={<ClientStatistics />} />
                    <Route path={Endpoints.CLIENT_TASKS} element={<ClientTasks />} />
                    <Route path={Endpoints.CLIENT_NOTIFICATIONS} element={<ClientNotifications />} />
                    <Route path={Endpoints.CLIENT_CREDITS} element={<ClientCredits />} />
                    <Route path={Endpoints.CLIENT_TASK_FEEDBACK} element={<ClientTaskFeedback />} />
                    <Route path={Endpoints.CLIENT_LICENCES} element={<ClientLicences />} />
                </Route>

                {/* Core users */}
                <Route path={Endpoints.CORE_USERS} element={<CoreUsers />} />
                <Route path={Endpoints.CORE_USER} element={<CoreUser />}>
                    <Route path={Endpoints.CORE_USER_TASKS} element={<CoreUserTasks />} />
                    <Route path={Endpoints.CORE_USER_ESCALATION_LOG} element={<CoreUserEscalationLog />} />
                    <Route path={Endpoints.CORE_USER_STATISTICS} element={<CoreUserStatistics />} />
                    <Route path={Endpoints.CORE_USER_SETTINGS} element={<CoreUserSettings />} />
                    <Route path={Endpoints.CORE_USER_TASK_FEEDBACK} element={<CoreUserTaskFeedback />} />
                </Route>

                {/* Tasks */}
                <Route path={Endpoints.TASKS} element={<Tasks />} />
                <Route path={Endpoints.TASK_KANBAN_BOARD} element={<TaskKanbanBoard />} />
                <Route path={Endpoints.TASK} element={<Task />}>
                    <Route path={Endpoints.TASK_INFORMATION} element={<TaskBriefing />} />
                    <Route path={Endpoints.TASK_MESSAGES} element={<TaskMessages />} />

                    {/* Task deliverables */}
                    <Route path={Endpoints.TASK_DELIVERABLES} element={<TaskDeliverables />}>
                        <Route path={Endpoints.TASK_DELIVERABLES_ALL} element={<TaskDeliverablesAll />} />
                        <Route path={Endpoints.TASK_DELIVERABLES_APPROVED} element={<TaskDeliverablesApproved />} />
                    </Route>
                    <Route path={Endpoints.TASK_MANAGEMENT} element={<TaskManagement />} />
                </Route>

                {/* Task client feedback */}
                <Route path={Endpoints.TASK_CLIENT_FEEDBACK} element={<TaskClientFeedback />} />

                {/* Plans */}
                <Route path={Endpoints.PLANS} element={<Plans />} />
                <Route path={Endpoints.PLAN} element={<Plan />} />

                {/* Brand profile */}
                <Route path={Endpoints.BRAND_PROFILE} element={<BrandProfile />} />

                {/* TaskCategories */}
                <Route path={Endpoints.TASK_CATEGORIES} element={<TaskCategories />} />
                <Route path={Endpoints.TASK_CATEGORY} element={<TaskCategory />} />
                <Route path={Endpoints.TASK_CATEGORY_GROUPS} element={<TaskCategoryGroups />} />
                <Route path={Endpoints.TASK_CATEGORY_FEATURED_BLOCKS} element={<TaskCategoryFeaturedBlocks />} />

                {/* Task message templates */}
                <Route path={Endpoints.TASK_MESSAGE_TEMPLATES} element={<TaskMessageTemplates />} />

                {/* Teams */}
                <Route path={Endpoints.TEAMS} element={<Teams />} />
                <Route path={Endpoints.TEAM} element={<Team />}>
                    <Route path={Endpoints.TEAM_MEMBERS} element={<TeamMembers />} />
                    <Route path={Endpoints.TEAM_TASKS} element={<TeamTasks />} />
                    <Route path={Endpoints.TEAM_TASK_FEEDBACK} element={<TeamTaskFeedback />} />
                </Route>

                {/* Subscription */}
                <Route path={Endpoints.SUBSCRIPTION} element={<Subscription />} />

                {/*Test */}
                <Route path={Endpoints.PLAYGROUND} element={<Playground />} />
            </Route>

            {/* TODO: fallback route */}
            <Route path="*" element={<Navigate to={Endpoints.HOME} />} />
        </Routes>
    );
};

export default AppRoutes;
