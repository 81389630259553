import { Button, Card, Classes, Icon, Intent } from '@blueprintjs/core';
import ConfirmationPopover from '@components/ConfirmationPopover';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import SendUserVerificationModal, { SendUserVerificationModalProps } from '../../modals/SendUserVerificationModal';
import { userIdentityApi } from '../../store/apis';
import { removeUserVerification, verifyUser } from '../../store/effects';
import { $userIdentity } from '../../store/states';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { ClientUserPermission, CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';

export interface VerificationManagementProps {
    onVerifyUserSuccess?: () => void;
    onRemoveUserVerificationSuccess?: () => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & VerificationManagementProps;

const VerificationManagement: React.FC<Props> = ({
    onVerifyUserSuccess,
    onRemoveUserVerificationSuccess,
    ...props
}) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    const isRemoving = useStore(removeUserVerification.pending);
    const isVerifying = useStore(verifyUser.pending);

    if (!identity || !me) {
        return null;
    }

    const isAllowed = {
        manual: false,
        flow: false,
    };
    if (identity.user_id === me.user.id) {
        // Self permissions
        isAllowed.manual = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.VERIFICATION_MANAGE);
        isAllowed.flow = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.VERIFICATION_FLOW);

    } else if ((identity.type === UserType.CLIENT) && permissions.isEnabled.clientUser) {
        // Client permission
        isAllowed.manual = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.VERIFICATION_MANAGE);
        isAllowed.flow = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.VERIFICATION_FLOW);

    } else if ((identity.type === UserType.CORE) && permissions.isEnabled.coreUser) {
        // Core permissions
        isAllowed.manual = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.VERIFICATION_MANAGE);
        isAllowed.flow = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.VERIFICATION_FLOW);
    }

    const handleRemoveVerification = async () => {
        try {
            await removeUserVerification(identity.user_id);
            userIdentityApi.removeVerification();
            onRemoveUserVerificationSuccess?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const handleManuallyVerify = async () => {
        try {
            await verifyUser(identity.user_id);
            userIdentityApi.verify();
            onVerifyUserSuccess?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    // Control card when user is verified
    const renderVerifiedStateControlCard = () => {
        const verifiedAt = moment(identity.verified_at);

        const renderRemoveVerificationButton = () => {
            if (!isAllowed.manual) {
                return null;
            }

            return (
                <ConfirmationPopover
                    title="Remove verification?"
                    description="When confirmed, verification status of this user will be removed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRemoveVerification}
                        >
                            Yes, remove verification
                        </Button>,
                    ]}
                >
                    <Button
                        minimal
                        outlined={isRemoving}
                        loading={isRemoving}
                        disabled={isRemoving}
                        intent={Intent.DANGER}
                    >
                        Remove verification
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="tick-circle" intent={Intent.SUCCESS} className="mr-1" size={15} />
                        <Heading type="h4">Account is verified</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderRemoveVerificationButton()}
                    </Flex>
                </Flex>
                <DevText muted>Account was verified on {verifiedAt.format('MMM D, YYYY')}</DevText>
            </Card>
        );
    };

    // Control card when user is not verified
    const renderNotVerifiedStateControlCard = () => {
        const renderManuallyVerifyButton = () => {
            if (!isAllowed.manual) {
                return null;
            }

            return (
                <ConfirmationPopover
                    title="Mark user as verified?"
                    description="When confirmed, user will be marked as verified, without additional actions"
                    actions={[
                        <Button
                            intent={Intent.PRIMARY}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleManuallyVerify}
                        >
                            Yes, verify
                        </Button>,
                    ]}
                >
                    <Button className="mr-1" loading={isVerifying} disabled={isVerifying}>
                        Manually verify
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderSendVerificationButton = () => {
            if (!isAllowed.flow) {
                return null;
            }

            return (
                <Button
                    disabled={isVerifying}
                    onClick={() =>
                        openModal<SendUserVerificationModalProps>({
                            ModalComponent: SendUserVerificationModal,
                            data: {
                                userId: identity.user_id,
                                userEmail: identity.email,
                            },
                        })
                    }
                >
                    Send verification
                </Button>
            );
        };

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="cross-circle" className="mr-1" size={15} />
                        <Heading type="h4">Account is not verified</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderManuallyVerifyButton()}
                        {renderSendVerificationButton()}
                    </Flex>
                </Flex>
                <DevText muted>Account is not verified yet</DevText>
            </Card>
        );
    };

    return (
        <div {...props}>
            <Heading type="h3" className="mb-1">
                Verification
            </Heading>
            {identity.verified_at !== null ? renderVerifiedStateControlCard() : renderNotVerifiedStateControlCard()}
        </div>
    );
};

export default VerificationManagement;
