import React from 'react';
import { CardExpiryElement, CardExpiryElementProps } from '@stripe/react-stripe-js';
import { StripeCardExpiryElementOptions } from '@stripe/stripe-js';
import { Colors } from '@blueprintjs/core';

export type Props = CardExpiryElementProps;

const CardExpirationDateInput: React.FC<Props> = ({ options, ...props }) => {
    const defaultOptions: StripeCardExpiryElementOptions = {
        style: {
            base: {
                color: Colors.WHITE,
                '::placeholder': {
                    color: Colors.GRAY2,
                },
            },
            invalid: {
                color: Colors.RED2,
            },
        },
    };

    return <CardExpiryElement options={{ ...defaultOptions, ...options }} {...props} />;
};

export default CardExpirationDateInput;
