import { MeResource, PermissionContainerResource } from 'dy-frontend-http-repository/lib/modules/Me/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import { WorkspaceInitDataResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceInitData/resources';
import domain from './domain';

// Fetch authorized user
export const fetchAuthorizedUser = domain.createEffect<void, MeResource>({
    name: 'fetchAuthorizedUser',
    async handler() {
        return repository.me().get();
    },
});

// Fetches current user list of permissions
export const fetchAuthorizedUserPermissions = domain.createEffect<void, PermissionContainerResource>({
    name: 'fetchAuthorizedUserPermissions',
    async handler() {
        return repository.me().getPermissions();
    },
});

// Fetch workspace initial data by workspace key
export const fetchWorkspaceInitialDataByWorkspaceKey = domain.createEffect<string, WorkspaceInitDataResource>({
    name: 'fetchWorkspaceInitialDataByWorkspaceKey',
    handler: async (workspaceKey) => {
        return await repository.workspaceInitData().getByKey(workspaceKey);
    },
});
