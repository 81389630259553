import { useState, useCallback } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';

export interface UseTimeFormFieldReturnValue {
    handleChange: (date: Date | null) => void;
    handleBlur: () => void;
}

export interface UseTimeFormFieldProps {
    formatValue: (value: Date | null) => string;
}

export type ReturnValue = DefaultFormFieldReturnValue<Date | null> & UseTimeFormFieldReturnValue;
export type Props = DefaultFormFieldProps<string, Date | null> & UseTimeFormFieldProps;
function useTimeFormField({ id, initialValue, validators, formatValue }: Props): ReturnValue {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (date: Date | null) => {
            // Set new value
            setValue(date);

            // Check validity for a new value
            setError(await checkValidators(formatValue(date), validators));
        },

        // eslint-disable-next-line
        [validators]
    );

    const handleBlur = useCallback(async () => {
        // Check validity for a field
        setError(await checkValidators(formatValue(value), validators));

        // eslint-disable-next-line
    }, [value, validators]);

    const hasError = useCallback(async () => {
        // Check validity for a field
        const err = await checkValidators(formatValue(value), validators);

        // Set validation result
        setError(err);

        return !!err;

        // eslint-disable-next-line
    }, [value, validators]);

    const reset = () => {
        // Reset value
        setValue(initialValue);
    };

    return {
        id,
        value,
        error,
        reset,
        hasError,
        handleChange,
        handleBlur,
    };
}

export default useTimeFormField;
