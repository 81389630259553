import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription cancellation is pending
 * @param subscription subscription for which cancellation pending should be checked
 * @returns {boolean} subscription cancellation pending flag
 */
export default function checkIfSubscriptionCancellationPending(subscription: SubscriptionResource) {
    const cancellation = subscription.cancellation;

    if (cancellation.canceled_at) {
        // Subscription is already cancelled
        return false;
    }

    if (cancellation.pending_at) {
        // Cancellation is pending
        return true;
    } else {
        // Cancellation is NOT pending
        return false;
    }
}
