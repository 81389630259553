import {
    AddonTaskPermission,
    AuthRolePermission,
    BrandProfileAssetPermission,
    BrandProfilePermission,
    ClientUserPermission,
    CoreUserPermission,
    CouponPermission,
    LicencePermission,
    PaymentAccountPermission,
    PaymentMethodPermission,
    PlanPermission,
    PricePermission,
    SelfUserPermission,
    SubscriptionPermission,
    TaskCategoryAddonPermission,
    TaskCategoryFeaturedBlockPermission,
    TaskCategoryGroupPermission,
    TaskCategoryPermission,
    TaskDeliverableCommentPermission,
    TaskDeliverablePermission,
    TaskMessagePermission,
    TaskMessageTemplateCategoryPermission,
    TaskMessageTemplatePermission,
    TaskPermission,
    TaskPublishPermission,
    TaxIdPermission,
    TeamPermission,
    UserNotePermission,
    UserNotificationEmailPermission,
    VirtualLicencePermission,
} from 'dy-frontend-permissions/lib/permission';

class PermissionContainer {
    private permissions: Set<string>;

    // Cached calculations of the auth module enabled
    public isEnabled = {
        addonTask: false,
        authRole: false,
        brandProfileAsset: false,
        brandProfile: false,
        clientUser: false,
        coreUser: false,
        coupon: false,
        licence: false,
        paymentAccount: false,
        paymentMethod: false,
        plan: false,
        price: false,
        subscription: false,
        taskCategoryAddon: false,
        taskCategoryFeaturedBlock: false,
        taskCategoryGroup: false,
        taskCategory: false,
        taskDeliverableComment: false,
        taskDeliverable: false,
        taskMessage: false,
        taskMessageTemplateCategory: false,
        taskMessageTemplate: false,
        task: false,
        taskPublish: false,
        taxId: false,
        team: false,
        userNote: false,
        userNotificationEmail: false,
        virtualLicence: false,
    };

    // Cached calculations of the auth module has root permissions
    public isRoot = {
        addonTask: false,
        authRole: false,
        brandProfileAsset: false,
        brandProfile: false,
        clientUser: false,
        coreUser: false,
        coupon: false,
        licence: false,
        paymentAccount: false,
        paymentMethod: false,
        plan: false,
        price: false,
        selfUser: false,
        subscription: false,
        taskCategoryAddon: false,
        taskCategoryFeaturedBlock: false,
        taskCategoryGroup: false,
        taskCategory: false,
        taskDeliverableComment: false,
        taskDeliverable: false,
        taskMessage: false,
        taskMessageTemplateCategory: false,
        taskMessageTemplate: false,
        task: false,
        taskPublish: false,
        taxId: false,
        team: false,
        userNote: false,
        userNotificationEmail: false,
        virtualLicence: false,
    };

    constructor(list: string[]) {
        this.permissions = new Set<string>(list);

        // Skip rest of checks if empty list is provided
        if (this.permissions.size === 0) {
            return;
        }

        // addonTask
        this.isRoot.addonTask = this.permissions.has(AddonTaskPermission.ROOT);
        this.isEnabled.addonTask = this.isRoot.addonTask || this.permissions.has(AddonTaskPermission._ENABLED);

        // authRole
        this.isRoot.authRole = this.permissions.has(AuthRolePermission.ROOT);
        this.isEnabled.authRole = this.isRoot.authRole || this.permissions.has(AuthRolePermission._ENABLED);

        // brandProfileAsset
        this.isRoot.brandProfileAsset = this.permissions.has(BrandProfileAssetPermission.ROOT);
        this.isEnabled.brandProfileAsset =
            this.isRoot.brandProfileAsset || this.permissions.has(BrandProfileAssetPermission._ENABLED);

        // brandProfile
        this.isRoot.brandProfile = this.permissions.has(BrandProfilePermission.ROOT);
        this.isEnabled.brandProfile = this.isRoot.brandProfile || this.permissions.has(BrandProfilePermission._ENABLED);

        // clientUser
        this.isRoot.clientUser = this.permissions.has(ClientUserPermission.ROOT);
        this.isEnabled.clientUser = this.isRoot.clientUser || this.permissions.has(ClientUserPermission._ENABLED);

        // coreUser
        this.isRoot.coreUser = this.permissions.has(CoreUserPermission.ROOT);
        this.isEnabled.coreUser = this.isRoot.coreUser || this.permissions.has(CoreUserPermission._ENABLED);

        // coupon
        this.isRoot.coupon = this.permissions.has(CouponPermission.ROOT);
        this.isEnabled.coupon = this.isRoot.coupon || this.permissions.has(CouponPermission._ENABLED);

        // licence
        this.isRoot.licence = this.permissions.has(LicencePermission.ROOT);
        this.isEnabled.licence = this.isRoot.licence || this.permissions.has(LicencePermission._ENABLED);

        // paymentAccount
        this.isRoot.paymentAccount = this.permissions.has(PaymentAccountPermission.ROOT);
        this.isEnabled.paymentAccount =
            this.isRoot.paymentAccount || this.permissions.has(PaymentAccountPermission._ENABLED);

        // paymentMethod
        this.isRoot.paymentMethod = this.permissions.has(PaymentMethodPermission.ROOT);
        this.isEnabled.paymentMethod =
            this.isRoot.paymentMethod || this.permissions.has(PaymentMethodPermission._ENABLED);

        // plan
        this.isRoot.plan = this.permissions.has(PlanPermission.ROOT);
        this.isEnabled.plan = this.isRoot.plan || this.permissions.has(PlanPermission._ENABLED);

        // price
        this.isRoot.price = this.permissions.has(PricePermission.ROOT);
        this.isEnabled.price = this.isRoot.price || this.permissions.has(PricePermission._ENABLED);

        // selfUser
        this.isRoot.selfUser = this.permissions.has(SelfUserPermission.ROOT);

        // subscription
        this.isRoot.subscription = this.permissions.has(SubscriptionPermission.ROOT);
        this.isEnabled.subscription = this.isRoot.subscription || this.permissions.has(SubscriptionPermission._ENABLED);

        // taskCategoryAddon
        this.isRoot.taskCategoryAddon = this.permissions.has(TaskCategoryAddonPermission.ROOT);
        this.isEnabled.taskCategoryAddon =
            this.isRoot.taskCategoryAddon || this.permissions.has(TaskCategoryAddonPermission._ENABLED);

        // taskCategoryFeaturedBlock
        this.isRoot.taskCategoryFeaturedBlock = this.permissions.has(TaskCategoryFeaturedBlockPermission.ROOT);
        this.isEnabled.taskCategoryFeaturedBlock =
            this.isRoot.taskCategoryFeaturedBlock || this.permissions.has(TaskCategoryFeaturedBlockPermission._ENABLED);

        // taskCategoryGroup
        this.isRoot.taskCategoryGroup = this.permissions.has(TaskCategoryGroupPermission.ROOT);
        this.isEnabled.taskCategoryGroup =
            this.isRoot.taskCategoryGroup || this.permissions.has(TaskCategoryGroupPermission._ENABLED);

        // taskCategory
        this.isRoot.taskCategory = this.permissions.has(TaskCategoryPermission.ROOT);
        this.isEnabled.taskCategory = this.isRoot.taskCategory || this.permissions.has(TaskCategoryPermission._ENABLED);

        // taskDeliverableComment
        this.isRoot.taskDeliverableComment = this.permissions.has(TaskDeliverableCommentPermission.ROOT);
        this.isEnabled.taskDeliverableComment =
            this.isRoot.taskDeliverableComment || this.permissions.has(TaskDeliverableCommentPermission._ENABLED);

        // taskDeliverable
        this.isRoot.taskDeliverable = this.permissions.has(TaskDeliverablePermission.ROOT);
        this.isEnabled.taskDeliverable =
            this.isRoot.taskDeliverable || this.permissions.has(TaskDeliverablePermission._ENABLED);

        // taskMessage
        this.isRoot.taskMessage = this.permissions.has(TaskMessagePermission.ROOT);
        this.isEnabled.taskMessage = this.isRoot.taskMessage || this.permissions.has(TaskMessagePermission._ENABLED);

        // taskMessageTemplateCategory
        this.isRoot.taskMessageTemplateCategory = this.permissions.has(TaskMessageTemplateCategoryPermission.ROOT);
        this.isEnabled.taskMessageTemplateCategory =
            this.isRoot.taskMessageTemplateCategory ||
            this.permissions.has(TaskMessageTemplateCategoryPermission._ENABLED);

        // taskMessageTemplate
        this.isRoot.taskMessageTemplate = this.permissions.has(TaskMessageTemplatePermission.ROOT);
        this.isEnabled.taskMessageTemplate =
            this.isRoot.taskMessageTemplate || this.permissions.has(TaskMessageTemplatePermission._ENABLED);

        // task
        this.isRoot.task = this.permissions.has(TaskPermission.ROOT);
        this.isEnabled.task = this.isRoot.task || this.permissions.has(TaskPermission._ENABLED);

        // taskPublish
        this.isRoot.taskPublish = this.permissions.has(TaskPublishPermission.ROOT);
        this.isEnabled.taskPublish = this.isRoot.taskPublish || this.permissions.has(TaskPublishPermission._ENABLED);

        // taxId
        this.isRoot.taxId = this.permissions.has(TaxIdPermission.ROOT);
        this.isEnabled.taxId = this.isRoot.taxId || this.permissions.has(TaxIdPermission._ENABLED);

        // team
        this.isRoot.team = this.permissions.has(TeamPermission.ROOT);
        this.isEnabled.team = this.isRoot.team || this.permissions.has(TeamPermission._ENABLED);

        // userNote
        this.isRoot.userNote = this.permissions.has(UserNotePermission.ROOT);
        this.isEnabled.userNote = this.isRoot.userNote || this.permissions.has(UserNotePermission._ENABLED);

        // userNotificationEmail
        this.isRoot.userNotificationEmail = this.permissions.has(UserNotificationEmailPermission.ROOT);
        this.isEnabled.userNotificationEmail =
            this.isRoot.userNotificationEmail || this.permissions.has(UserNotificationEmailPermission._ENABLED);

        // virtualLicence
        this.isRoot.virtualLicence = this.permissions.has(VirtualLicencePermission.ROOT);
        this.isEnabled.virtualLicence =
            this.isRoot.virtualLicence || this.permissions.has(VirtualLicencePermission._ENABLED);
    }

    // Proxy to Set's `has` method
    has(key: string): boolean {
        return this.permissions.has(key);
    }
}

export default PermissionContainer;
