import { Intent, Toaster } from '@blueprintjs/core';
import { teamApi } from '../../store/apis';
import { removeTeamParticipant } from '../../store/effects';

const useRemoveTeamParticipant = () => {
    const handleRemoveTeamParticipant = async ({ teamId, userId }: { teamId: ID; userId: ID }) => {
        try {
            const removeTeamParticipantResponse = await removeTeamParticipant({ teamId, userId });

            teamApi.removeParticipationByUserId({ userId: userId });
        } catch (e) {
            throw e;
        }
    };

    return { removeTeamParticipant: handleRemoveTeamParticipant };
};

export default useRemoveTeamParticipant;
