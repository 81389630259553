import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringNotEqualToStringMessage = 'Not valid field chosen';
const getStringNotEqualToStringValidator: GetValidatorFunction<
    { stringToCompareWith: string; stringNotEqualToStringMessage?: string },
    string
> = (options = { stringToCompareWith: '', stringNotEqualToStringMessage: defaultStringNotEqualToStringMessage }) => {
    const { stringToCompareWith, stringNotEqualToStringMessage } = options;

    return async (value) => {
        if (value !== stringToCompareWith) {
            // String are NOT equal
            return null;
        } else {
            // Strings are equal
            if (stringNotEqualToStringMessage) {
                return stringNotEqualToStringMessage;
            } else {
                return defaultStringNotEqualToStringMessage;
            }
        }
    };
};

export default getStringNotEqualToStringValidator;
