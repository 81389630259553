import React, { HTMLAttributes, useState } from 'react';
import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import {
    checkIfResumeSubscriptionActionAllowed,
    checkIfSubscriptionPaused,
    checkIfSubscriptionPausedExternally,
    checkIfUpdateSubscriptionPauseScheduleActionAllowed,
} from '@data/functions/subscription';
import { openModal } from '@modals/store/events';
import { fetchSubscription, removeSubscriptionPause } from '../../store/effects';
import Flex from '@components/Flex';
import { Button, Card, Classes, Colors, Icon, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import UpdateSubscriptionPauseScheduleModal, {
    UpdateSubscriptionPauseScheduleModalProps,
} from '@app/containers/modals/UpdateSubscriptionPauseScheduleModal';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

export interface SubscriptionPauseScheduleInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionPauseScheduleInformationProps;

const SubscriptionPauseScheduleInformation: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    const [isResumingSubscription, setIsResumingSubscription] = useState(false);

    if (!checkIfSubscriptionPaused(subscription)) {
        // Subscription was NOT paused
        return null;
    }

    const renderSubscriptionPausedExternallyInformation = () => {
        // Subscription was paused from Stripe

        // Unpause date is unknown (external_resume_at)
        let subscriptionPauseExternalResumeScheduleAtFormatted = 'Paused indefinitely';

        if (subscription.pause.external_resume_at !== null) {
            // Unpause date is known
            subscriptionPauseExternalResumeScheduleAtFormatted = `Paused until: ${moment(
                subscription.pause.external_resume_at,
            ).format('D MMM, YYYY - HH:mm')}`;
        }

        return (
            <div>
                <Flex className="mb-1" direction="row" align="center">
                    <Icon className="mr-1" icon="pause" />
                    <Heading type="h5">{subscriptionPauseExternalResumeScheduleAtFormatted}</Heading>
                </Flex>

                <Flex direction="row" align="flex-start">
                    <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                    <DevText muted>
                        Paused manually in Stripe. No unused period will be added after resume. Use Stripe dashboard to
                        resume
                    </DevText>
                </Flex>
            </div>
        );
    };

    const renderSubscriptionPauseThroughPlatformInformation = () => {
        // Subscription was paused from platform

        // Get formatted subscription pause scheduled at date
        const subscriptionPauseReleaseScheduledAtFormatted = moment(
            subscription.pause_schedule.release_scheduled_at,
        ).format('D MMM, YYYY - HH:mm');

        // Get time till pause happen
        const subscriptionPauseWillHappenIn = moment().to(
            moment().add(subscription.pause_schedule.unused_period, 'seconds'),
            true,
        );

        return (
            <div>
                <Flex direction="row" className="mb-1" align="center">
                    <Icon className="mr-1" icon="pause" />
                    <Heading type="h5">Paused until: {subscriptionPauseReleaseScheduledAtFormatted}</Heading>
                </Flex>

                <DevText muted>Unused period, added after resume: {subscriptionPauseWillHappenIn}</DevText>
            </div>
        );
    };

    const renderSubscriptionPauseMainInformation = () => {
        if (checkIfSubscriptionPausedExternally(subscription)) {
            // Subscription was paused from Stripe
            return renderSubscriptionPausedExternallyInformation();
        }

        // Subscription was paused from platform
        return renderSubscriptionPauseThroughPlatformInformation();
    };

    const renderSubscriptionPausedAtLabel = () => {
        let pausedAt: string | null = null;

        if (subscription.pause_schedule.is_active) {
            pausedAt = moment(subscription.pause_schedule.activated_at).format('D MMM, YYYY');
        }

        if (!pausedAt) {
            // Pause activated date do NOT exist
            return null;
        }

        return <DevText muted>Paused at: {pausedAt}</DevText>;
    };

    const renderResumeSubscriptionButton = () => {
        const isResumeSubscriptionActionAllowed = checkIfResumeSubscriptionActionAllowed(subscription);
        if (!isResumeSubscriptionActionAllowed) {
            return null;
        }

        const isAuthAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.PAUSE_RELEASE);
        if (!isAuthAllowed) {
            return null;
        }

        const handleResumeSubscription = async () => {
            setIsResumingSubscription(true);

            try {
                await removeSubscriptionPause(subscription.id);
                await fetchSubscription(subscription.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsResumingSubscription(false);
            }
        };

        return (
            <ConfirmationPopover
                title="Are you sure you would like to resume this subscription?"
                description="When confirmed, subscription will be resumed"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleResumeSubscription}
                    >
                        Yes, resume
                    </Button>,
                ]}
            >
                <Button loading={isResumingSubscription} disabled={isResumingSubscription} icon="play" className="mr-1">
                    Resume now
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderUpdateSubscriptionPauseButton = () => {
        const isUpdateSubscriptionPauseActionAllowed =
            checkIfUpdateSubscriptionPauseScheduleActionAllowed(subscription);
        if (!isUpdateSubscriptionPauseActionAllowed) {
            return null;
        }

        const isAuthAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.PAUSE_UPDATE);
        if (!isAuthAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() =>
                    openModal<UpdateSubscriptionPauseScheduleModalProps>({
                        ModalComponent: UpdateSubscriptionPauseScheduleModal,
                        data: {
                            subscriptionId: subscription.id,
                            pauseSchedule: subscription.pause_schedule,
                            refetchOnSuccess: () => fetchSubscription(subscription.id),
                        },
                    })
                }
            >
                Update pause details
            </Button>
        );
    };

    return (
        <div {...props}>
            <Flex className="mb-1" direction="row" justify="space-between" align="center">
                <Heading type="h4">Pause Details</Heading>

                <Flex direction="row" align="center">
                    {renderResumeSubscriptionButton()}
                    {renderUpdateSubscriptionPauseButton()}
                </Flex>
            </Flex>

            <Card>
                <Flex direction="row" justify="space-between">
                    {renderSubscriptionPauseMainInformation()}
                    {renderSubscriptionPausedAtLabel()}
                </Flex>
            </Card>
        </div>
    );
};

export default SubscriptionPauseScheduleInformation;
