import { TaskClientFeedbackNegativeType } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/enums';

const taskClientFeedbackNegativeTypeInformation: { [key in TaskClientFeedbackNegativeType]: { title: string } } = {
    [TaskClientFeedbackNegativeType.COMMUNICATION]: {
        title: 'Communication',
    },
    [TaskClientFeedbackNegativeType.CREATIVITY]: {
        title: 'Creativity',
    },
    [TaskClientFeedbackNegativeType.QUALITY]: {
        title: 'Quality',
    },
    [TaskClientFeedbackNegativeType.SPEED]: {
        title: 'Speed',
    },
};

export default taskClientFeedbackNegativeTypeInformation;
