import { TaskKanbanResource } from 'dy-frontend-http-repository/lib/modules/TaskKanban/resources';
import { TaskKanbanBoardFormatted } from '../types';
import domain from './domain';
import { resetTaskKanbanBoard, resetTaskKanbanBoardFormatted } from './events';

// Task kanban board
export const $taskKanbanBoard = domain.createStore<TaskKanbanResource | null>(null).reset(resetTaskKanbanBoard);

// Task kanban board formatted
export const $taskKanbanBoardFormatted = domain
    .createStore<TaskKanbanBoardFormatted | null>(null)
    .reset(resetTaskKanbanBoardFormatted);
