import { Button, Card, Classes, Colors, Intent, Popover } from '@blueprintjs/core';
import React, { HTMLAttributes, useState } from 'react';
import { ChromePicker } from 'react-color';
import ColorTile, { ColorTitleProps } from '../ColorTile';
import Flex from '../Flex';

export interface ColorTilePickerProps {
    // Initial color value
    color: string;

    // Props for color tile which is used to show color block & input with color value
    colorTileProps?: Omit<ColorTitleProps, 'color'>;

    // On change callback which is called whenever user click "Apply" button
    onChange?: (color: string) => void;

    // On remove callback which is called whenever user click "Remove" button
    onRemove?: () => void;
}

export type Props = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & ColorTilePickerProps;

// This component is used to pick color & show color square block and color's hash value
const ColorTilePicker: React.FC<Props> = ({ color: initialColor, colorTileProps, onChange, onRemove, ...props }) => {
    const isReadOnly = !onChange;

    const [color, setColor] = useState(initialColor);

    const handleApplyColor = () => {
        if (!onChange) {
            // onChange callback is NOT passed
            return;
        }

        onChange(color);
    };

    const renderRemoveColorButton = () => {
        if (!onRemove) {
            // No callback for removing, meaning do NOT show button
            return;
        }

        return (
            <Button minimal icon="trash" className={Classes.POPOVER_DISMISS} intent={Intent.DANGER} onClick={onRemove}>
                Remove
            </Button>
        );
    };

    const renderColorTilePickerPopoverContent = () => {
        return (
            <Card>
                <div className="mb-2">
                    <ChromePicker
                        disableAlpha
                        color={color}
                        onChange={(color) => setColor(color.hex)}
                        styles={{
                            default: {
                                picker: {
                                    backgroundColor: Colors.DARK_GRAY1,
                                    fontFamily: 'inherit',
                                },
                            },
                        }}
                    />
                </div>

                <Flex justify="flex-end">
                    <div className="mr-auto">{renderRemoveColorButton()}</div>
                    <div>
                        <Button
                            minimal
                            className={`${Classes.POPOVER_DISMISS} mr-1`}
                            onClick={() => setColor(initialColor)}
                        >
                            Close
                        </Button>

                        <Button intent={Intent.PRIMARY} className={Classes.POPOVER_DISMISS} onClick={handleApplyColor}>
                            Apply
                        </Button>
                    </div>
                </Flex>
            </Card>
        );
    };

    return (
        <Popover disabled={isReadOnly} content={renderColorTilePickerPopoverContent()} {...props}>
            <ColorTile interactive={!isReadOnly} color={color} {...colorTileProps} />
        </Popover>
    );
};

export default ColorTilePicker;
