import ConfirmationPopover from '@app/components/ConfirmationPopover';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { taskApi } from '@app/containers/pages/Task/store/apis';
import { $task } from '@app/containers/pages/Task/store/states';
import { Button, Card, Classes, Colors, Icon, Intent } from '@blueprintjs/core';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { elevateTaskPriority, removeTaskPriorityElevation } from '../../store/effects';

type Props = HTMLAttributes<HTMLDivElement>;

const TaskPriorityManagement: React.FC<Props> = ({ ...props }) => {
    const task = useStore($task);

    const isElevatingTaskPriority = useStore(elevateTaskPriority.pending);
    const isRemovingTaskPriorityElevation = useStore(removeTaskPriorityElevation.pending);

    if (!task) {
        return null;
    }

    const isTaskPriorityMutationBlocked =
        task.archived_at !== null ||
        task.paused_at !== null ||
        [TaskState.DRAFT, TaskState.DELIVERED, TaskState.PRE_DELIVERED].some((state) => state === task.state);

    const handleElevateTaskPriority = async () => {
        try {
            await elevateTaskPriority(task.id);
            taskApi.prioritize();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const handleRemoveTaskPriorityElevation = async () => {
        try {
            await removeTaskPriorityElevation(task.id);
            taskApi.removePrioritization();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderTaskPriorityElevationFlagInformation = () => {
        const renderTitle = () => {
            return <Heading type="h4">Request priority</Heading>;
        };

        const renderFlagLabel = () => {
            if (task.is_priority_elevated) {
                // Prioritized
                return (
                    <Flex align="center">
                        <Icon className="mr-small" icon="generate" intent={Intent.DANGER} />
                        <DevText color={Colors.RED5}>High priority</DevText>
                    </Flex>
                );
            } else {
                // Normal prioritization
                return <DevText muted>Request have normal priority</DevText>;
            }
        };

        return (
            <div>
                <div className="mb-1">{renderTitle()}</div>
                {renderFlagLabel()}
            </div>
        );
    };

    const renderRemoveTaskPriorityElevationButton = () => {
        if (isTaskPriorityMutationBlocked) {
            return null;
        }

        return (
            <ConfirmationPopover
                title="Remove request priority?"
                description="When confirmed, request priority will be switched to normal"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleRemoveTaskPriorityElevation()}
                    >
                        Yes, remove
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    loading={isRemovingTaskPriorityElevation}
                    disabled={isRemovingTaskPriorityElevation}
                    icon="chevron-down"
                    intent={Intent.DANGER}
                >
                    Remove priority
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderElevateTaskPriorityButton = () => {
        if (isTaskPriorityMutationBlocked) {
            return null;
        }

        return (
            <ConfirmationPopover
                title="Would you like to prioritize request?"
                description="When confirmed, request priority will be switched to high"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleElevateTaskPriority()}
                    >
                        Yes, prioritize
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    loading={isElevatingTaskPriority}
                    disabled={isElevatingTaskPriority}
                    icon="generate"
                    intent={Intent.PRIMARY}
                >
                    Prioritize request
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderTaskPriorityElevationButton = () => {
        if (task.is_priority_elevated) {
            // Prioritized
            return renderRemoveTaskPriorityElevationButton();
        } else {
            // Normal prioritization
            return renderElevateTaskPriorityButton();
        }
    };

    return (
        <Card compact {...props}>
            <Flex direction="row" justify="space-between">
                {renderTaskPriorityElevationFlagInformation()}
                {renderTaskPriorityElevationButton()}
            </Flex>
        </Card>
    );
};

export default TaskPriorityManagement;
