import React from 'react';
import { PublishTaskStep } from '../enums';
import PublishTaskViaCreditStep from '../components/PublishTaskViaCreditStep';
import PublishTaskViaManualStep from '../components/PublishTaskViaManualStep';
import PublishTaskViaPaymentStep from '../components/PublishTaskViaPaymentStep';
import PublishTaskViaQuotaStep from '../components/PublishTaskViaQuotaStep';
import SelectTaskPublishMethodStep from '../components/SelectTaskPublishMethodStep';
import { CommonStepProps } from '../types';

const publishSteps: { [key in PublishTaskStep]: React.FC<CommonStepProps> } = {
    [PublishTaskStep.PUBLISH_METHOD_SELECT]: SelectTaskPublishMethodStep,
    [PublishTaskStep.MANUAL]: PublishTaskViaManualStep,
    [PublishTaskStep.PAYMENT]: PublishTaskViaPaymentStep,
    [PublishTaskStep.QUOTA]: PublishTaskViaQuotaStep,
    [PublishTaskStep.CREDIT]: PublishTaskViaCreditStep,
};

export default publishSteps;
