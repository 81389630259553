import { repository } from 'dy-frontend-http-repository/lib/modules';
import { VirtualLicenceTaskCategoryAttachmentInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { VirtualLicenceRef } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/refs';
import domain from './domain';

// Applies virtual licence task category attachment list
export const updateVirtualLicenceTaskCategoryAttachmentList = domain.createEffect<
    { virtualLicenceId: ID; input: VirtualLicenceTaskCategoryAttachmentInput[] },
    VirtualLicenceRef
>({
    name: 'updateVirtualLicenceTaskCategoryAttachmentList',
    handler: async ({ virtualLicenceId, input }) => {
        return await repository.virtualLicence().setTaskCategoryAttachmentList(virtualLicenceId, input);
    },
});
