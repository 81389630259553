import { useState, useCallback } from 'react';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';
import { checkValidators } from '../functions';

export interface UseHTMLSelectFormFieldReturnValue<Value> {
    handleChange: (value: Value) => void;
    handleClose: () => void;
}

export type ReturnValue<Value = any> = DefaultFormFieldReturnValue<Value> & UseHTMLSelectFormFieldReturnValue<Value>;
export type Props<Value> = DefaultFormFieldProps<Value>;

function useHTMLSelectFormField({ id, initialValue, validators }: Props<string>): ReturnValue<string> {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (val: string) => {
            setValue(val);
            setError(await checkValidators(val, validators));
        },
        [validators]
    );

    const handleClose = useCallback(async () => {
        setError(await checkValidators(value, validators));
    }, [value, validators]);

    const hasError = useCallback(async () => {
        const err = await checkValidators(value, validators);
        setError(err);

        return !!err;
    }, [value, validators]);

    const reset = () => {
        setValue(initialValue);
    };

    return {
        id,
        value,
        error,
        reset,
        hasError,
        handleChange,
        handleClose,
    };
}

export default useHTMLSelectFormField;
