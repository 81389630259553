import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { $coreUser } from '../../store/states';
import CoreUserBadge from './components/CoreUserBadge';
import CoreUserOccupancyInformation from './components/CoreUserOccupancyInformation';
import Information from './components/Information';

export type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    const coreUser = useStore($coreUser);

    if (!coreUser) {
        return null;
    }

    return (
        <div {...props}>
            <CoreUserBadge className="mb-2" />
            <Information className="mb-2" />
            <CoreUserOccupancyInformation />
        </div>
    );
};

export default Aside;
