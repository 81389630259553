import { useForm, useTextArrayFormField } from '@app/hooks';
import { getOptionalStringValidator, getStringWebURLValidator } from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { UpdateBrandProfileInformationInput } from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import React from 'react';
import { brandProfileApi } from '../../store/apis';

export interface UpdateBrandProfileSocialMediaInformationModalProps {
    brandProfileId: ID;
    links: string[];
}

type Props = ModalProps<UpdateBrandProfileSocialMediaInformationModalProps>;

const socialLinksValidators = [getOptionalStringValidator([getStringWebURLValidator()])];

// TODO: have to initialize state for links by parsing links inside of links array, not by <code>data?.links[0..n]</code>
const UpdateBrandProfileSocialMediaInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const socialLinks = useTextArrayFormField({
        id: 'form-social-links',
        validators: socialLinksValidators,
        initialValue: data?.links ? [...data.links, ''] : [''],
    });

    const form = useForm<BrandProfileRef>({
        fields: [socialLinks],
        apiCall: async () => {
            const updateBrandProfileInformationInput: UpdateBrandProfileInformationInput = {
                links: socialLinks.value.filter((link) => link.trim().length > 0),
            };

            const brandProfileRef = await updateBrandProfileInformation({
                id: data!.brandProfileId,
                input: updateBrandProfileInformationInput,
            });

            brandProfileApi.update(updateBrandProfileInformationInput);

            return { response: brandProfileRef };
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderSocialMediaLinksInputs = () => {
        const normalizeReferenceLinks = (referenceLinks: string[]) => {
            const normalizedReferenceLinks = referenceLinks.filter((x) => x.trim().length !== 0);
            normalizedReferenceLinks.push('');
            return normalizedReferenceLinks;
        };

        const updateReferenceLinkAtIndex = (index: number, referenceLink: string) => {
            let newReferenceLinks = [...socialLinks.value];
            newReferenceLinks[index] = referenceLink;
            newReferenceLinks = normalizeReferenceLinks(newReferenceLinks);
            socialLinks.handleChange(newReferenceLinks);
        };

        const renderReferenceLinkInput = (link: string, index: number) => {
            return (
                <InputGroup
                    fill
                    intent={!!socialLinks.errors[index] ? Intent.DANGER : Intent.NONE}
                    className="mb-1"
                    placeholder="Enter social media URL..."
                    value={link}
                    onChange={(e) => updateReferenceLinkAtIndex(index, e.target.value)}
                    onBlur={() => socialLinks.handleBlur(index)}
                />
            );
        };

        return (
            <FormGroup label="Reference links" helperText="Extra fields will be added as you type">
                {socialLinks.value.map(renderReferenceLinkInput)}
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Edit Social Media</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {renderSocialMediaLinksInputs()}

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateBrandProfileSocialMediaInformationModal;
