import { $permissions } from '@app/containers/store/states';
import { BreadcrumbProps, Button, Classes, Divider, Intent, Spinner } from '@blueprintjs/core';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import { Endpoints } from '@data/consts';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Aside from './components/Aside';
import DesignDimensions from './components/DesignDimensions';
import DesignExtensions from './components/DesignExtensions';
import Groups from './components/Groups';
import Information from './components/Information';
import { normalizedTaskCategoryDimensionsApi, taskCategoryApi } from './store/apis';
import { fetchTaskCategory, removeTaskCategory, restoreTaskCategory } from './store/effects';
import {
    resetNormalizedTaskCategoryDimensions,
    resetTaskCategory,
    resetTaskCategoryAddons,
    resetTaskCategoryGroups,
} from './store/events';
import { $taskCategory } from './store/states';
import Heading from '@components/Heading';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { usePageTitle } from '@app/hooks';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const TaskCategory: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.taskCategory) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Request Category');

    const navigate = useNavigate();
    const { taskCategoryId } = useParams() as { taskCategoryId: ID };

    const taskCategory = useStore($taskCategory);
    const isTaskCategoryRemoving = useStore(removeTaskCategory.pending);
    const isTaskCategoryRestoring = useStore(restoreTaskCategory.pending);

    useEffect(() => {
        const taskCategoryBreadcrumbs: BreadcrumbProps[] = [
            { text: 'Request categories', onClick: () => navigate(Endpoints.TASK_CATEGORIES) },
            { text: 'Category details' },
        ];

        setPageBreadcrumbs(taskCategoryBreadcrumbs);
    }, []);

    useEffect(() => {
        fetchTaskCategory(taskCategoryId)
            .then((category) => {
                normalizedTaskCategoryDimensionsApi.initialize({ dimensions: category.dimensions });
            })
            .catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Request category with ID of ${taskCategoryId} was not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to task categories page
                    navigate(Endpoints.TASK_CATEGORIES);
                }
            });
    }, [taskCategoryId]);

    useEffect(() => {
        return () => {
            resetTaskCategory();
            resetTaskCategoryGroups();
            resetTaskCategoryAddons();
            resetNormalizedTaskCategoryDimensions();
            resetPageBreadcrumbs();
        };
    }, []);

    if (!taskCategory) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        const renderRemoveTaskCategoryButton = () => {
            if (taskCategory.archived_at) {
                // Task category is already archived
                return null;
            }

            const isArchiveAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.ARCHIVE);
            if (!isArchiveAllowed) {
                return null;
            }

            const handleRemoveTaskCategory = async () => {
                try {
                    await removeTaskCategory(taskCategory.id);
                    taskCategoryApi.archive();
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure want to remove request category?"
                    description="When confirmed, request category will be removed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRemoveTaskCategory}
                        >
                            Yes, remove category
                        </Button>,
                    ]}
                >
                    <Button outlined minimal loading={isTaskCategoryRemoving} icon="trash" intent={Intent.DANGER}>
                        Remove request category
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderRestoreTaskCategoryButton = () => {
            if (!taskCategory.archived_at) {
                // Task category is NOT archived
                return null;
            }

            const isRestoreAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.RESTORE);
            if (!isRestoreAllowed) {
                return null;
            }

            const handleRestoreTaskCategory = async () => {
                try {
                    await restoreTaskCategory(taskCategory.id);
                    taskCategoryApi.restore();
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure you want to restore request category?"
                    description="When confirmed, request category will be restored"
                    actions={[
                        <Button
                            intent={Intent.SUCCESS}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRestoreTaskCategory}
                        >
                            Yes, restore category
                        </Button>,
                    ]}
                >
                    <Button
                        outlined
                        loading={isTaskCategoryRestoring}
                        disabled={isTaskCategoryRestoring}
                        intent={Intent.SUCCESS}
                    >
                        Restore task category
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Flex className="mb-2" justify="space-between">
                <Heading type="h3">{taskCategory.title}</Heading>

                <Flex direction="row">
                    {renderRemoveTaskCategoryButton()}
                    {renderRestoreTaskCategoryButton()}
                </Flex>
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {renderHeader()}

            <Divider className="mb-2" />

            <Grid container>
                <Grid lg={3}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9}>
                    <Information className="mb-2" />
                    <Groups className="mb-2" />
                    <DesignExtensions className="mb-2" />
                    <DesignDimensions />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default TaskCategory;
