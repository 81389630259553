import Image from '@components/Image';
import { $selectedTaskDeliverable } from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import { useStore } from 'effector-react';
import React, { useRef, useState } from 'react';
import { useSetPointMarker } from '../../../../hooks';
import PointMarkerBlock from '../PointMarkerBlock';
import { ImageWrapper, Wrapper } from './styled';

const TaskDeliverableImageViewer: React.FC = () => {
    const { handleSetPointMarker } = useSetPointMarker();

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const ref = useRef<HTMLDivElement | null>(null);

    if (!selectedTaskDeliverable || !selectedTaskDeliverable.file) {
        return null;
    }

    // TODO: need to check for initialization to make sure container is rendered, so markers will not be in the wrong positions

    return (
        <Wrapper>
            <ImageWrapper ref={ref} onClick={handleSetPointMarker}>
                <Image
                    width="100%"
                    height="100%"
                    src={selectedTaskDeliverable.file.url}
                    onLoad={() => setIsImageLoaded(true)}
                />
                {isImageLoaded && <PointMarkerBlock containerRef={ref} />}
            </ImageWrapper>
        </Wrapper>
    );
};

export default TaskDeliverableImageViewer;
