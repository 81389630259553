import { repository } from 'dy-frontend-http-repository/lib/modules';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import { UpdateTaskCategoryFeaturedBlockInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/inputs';
import domain from './domain';

// Update task category featured block
export const updateTaskCategoryFeaturedBlock = domain.createEffect<
    { taskCategoryFeaturedBlockId: ID; input: UpdateTaskCategoryFeaturedBlockInput },
    TaskCategoryRef
>({
    name: 'updateTaskCategoryFeaturedBlock',
    handler: async ({ taskCategoryFeaturedBlockId, input }) => {
        return await repository.taskCategoryFeaturedBlock().update(taskCategoryFeaturedBlockId, input);
    },
});
