import PageTabs from '@app/components/PageTabs';
import React, { HTMLAttributes } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './consts';

type Props = HTMLAttributes<HTMLDivElement>;

const Tabs: React.FC<Props> = (props) => {
    const { teamId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const getFormattedPath = (path: string) => {
        return path.replace(':teamId', teamId!);
    };

    return (
        <PageTabs
            pathname={pathname}
            visibleAmount={7}
            tabs={tabs}
            onSelectTab={navigate}
            pathFormatter={getFormattedPath}
            {...props}
        />
    );
};

export default Tabs;
