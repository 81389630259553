import { repository } from 'dy-frontend-http-repository/lib/modules';
import { VirtualLicenceQuotaAttachmentInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { VirtualLicenceRef } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/refs';
import domain from './domain';

// Update quota attachment list
export const updateQuotaAttachmentList = domain.createEffect<
    { virtualLicenceId: ID; input: VirtualLicenceQuotaAttachmentInput[] },
    VirtualLicenceRef
>({
    name: 'updateQuotaAttachmentList',
    handler: async ({ virtualLicenceId, input }) => {
        return await repository.virtualLicence().setQuotaAttachmentList(virtualLicenceId, input);
    },
});
