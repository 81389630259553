import UserOccupancyByTeamParticipantUserIdMap from '../types/UserOccupancyByTeamParticipantUserIdMap';
import domain from './domain';

// Set user occupancy by team participant user id
export const setUserOccupancyByTeamParticipantUserIdMap = domain.createEvent<UserOccupancyByTeamParticipantUserIdMap>(
    'setUserOccupancyByTeamParticipantUserIdMap'
);

// Reset pickable team participant users
export const resetTeamPickableParticipantUsers = domain.createEvent('resetTeamPickableParticipantUsers');

// Reset team state
export const resetTeam = domain.createEvent('resetTeam');

// Reset team occupancy
export const resetTeamOccupancy = domain.createEvent('resetTeamOccupancy');

// Reset team members occupancy
export const resetUserOccupancyByTeamParticipantUserIdMap = domain.createEvent(
    'resetUserOccupancyByTeamParticipantUserIdMap'
);
