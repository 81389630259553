import React, { HTMLAttributes } from 'react';

export interface ListCollapseProps<T> {
    isCollapsed: boolean;
    maxSize: number;
    list: T[];
    itemRenderer: (item: T) => JSX.Element;
    renderCollapseControl: (hiddenItemsAmount: number) => JSX.Element;
}

export type Props<T> = HTMLAttributes<HTMLDivElement> & ListCollapseProps<T>;

function ListCollapse<T>({ isCollapsed, maxSize, list, itemRenderer, renderCollapseControl, ...props }: Props<T>) {
    const isCollapsible = list.length > maxSize;

    const renderContent = () => {
        let listToRender = list;
        let hiddenItemsAmount = list.length;

        if (isCollapsed) {
            if (isCollapsible) {
                listToRender = list.slice(0, maxSize);
                hiddenItemsAmount -= maxSize;
            }
        }

        return (
            <>
                <div>{listToRender.map(itemRenderer)}</div>
                {isCollapsible && <div className="mt-1">{renderCollapseControl(hiddenItemsAmount)}</div>}
            </>
        );
    };

    return <div {...props}>{renderContent()}</div>;
}

export default ListCollapse;
