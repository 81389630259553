import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { ModalProps } from '@app/containers/modals/types';
import { Card, Button, Divider, Intent } from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import { useStore } from 'effector-react';
import React from 'react';
import { updateLicencePlan } from './store/effects';

export interface UpdateLicencePlanModalProps {
    licenceId: ID;
}

export type Props = ModalProps<UpdateLicencePlanModalProps>;

const UpdateLicencePlanModal: React.FC<Props> = ({ closeModal, data }) => {
    const isUpdatingLicencePlan = useStore(updateLicencePlan.pending);

    const handleModalClose = () => {
        if (isUpdatingLicencePlan) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update licence plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isUpdatingLicencePlan} />
                </Flex>

                <Divider className="mb-2" />

                <Flex className="mt-2" direction="row" justify="flex-end">
                    <Button minimal onClick={handleModalClose} className="mr-1" disabled={isUpdatingLicencePlan}>
                        No, cancel
                    </Button>
                    <Button intent={Intent.PRIMARY} loading={isUpdatingLicencePlan}>
                        Yes, create
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default UpdateLicencePlanModal;
