import styled, { css } from 'styled-components';
import { FixedHeightCollapseProps } from './';

export const StyledFixedHeightCollapse = styled.div<{
    $isCollapsed: FixedHeightCollapseProps['isCollapsed'];
    $maxHeight: FixedHeightCollapseProps['maxHeight'];
}>`
    max-height: none;
    overflow: hidden;

    ${({ $isCollapsed, $maxHeight }) =>
        $isCollapsed &&
        css`
            max-height: ${$maxHeight}px;
        `}
`;
