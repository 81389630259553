import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';

export interface SubscriptionPeriodInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionPeriodInformationProps;

const SubscriptionPeriodInformation: React.FC<Props> = ({ subscription, ...props }) => {
    const currentPeriod = subscription.current_period;

    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">Information</Heading>

            <Card>
                {/* Subscription period start */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="calendar"
                    title="Current period start"
                    text={moment(currentPeriod.start_at).format('D MMM, YYYY')}
                />

                {/* Subscription period end */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="calendar"
                    title="Current period end"
                    text={`${moment(currentPeriod.end_at).format('D MMM, YYYY')} - ${moment(
                        currentPeriod.end_at,
                    ).fromNow()}`}
                />

                {/* Billing Anchor Date */}

                <CommonInformationBlock
                    icon="calendar"
                    title="Billing Anchor Date"
                    text={moment(subscription.billing_cycle_anchor_at).format('D MMM, YYYY')}
                />
            </Card>
        </div>
    );
};

export default SubscriptionPeriodInformation;
