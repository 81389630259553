import React, { HTMLAttributes, useEffect } from 'react';
import { Intent, Spinner } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import { $userIdentity } from './store/states';
import { fetchUserIdentity } from './store/effects';
import { resetUserIdentity } from './store/events';
import Flex from '@components/Flex';
import AuthenticationMethodList from './components/AuthenticationMethodList';
import DangerZone from './components/DangerZone';
import EmailManagement from './components/EmailManagement';
import VerificationManagement from './components/VerificationManagement';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import RoleManagement from '@containers/components/UserSettings/components/RoleManagement';

export interface UserSettingsProps {
    userId: ID | null;
    onChangeEmailSuccess: (email: string) => void;
    onVerifyUserSuccess?: () => void;
    onRemoveUserVerificationSuccess?: () => void;
}

type Props = HTMLAttributes<HTMLDivElement> & UserSettingsProps;

const UserSettings: React.FC<Props> = ({
    userId,
    onVerifyUserSuccess,
    onChangeEmailSuccess,
    onRemoveUserVerificationSuccess,
    ...props
}) => {
    const navigate = useNavigate();

    const userIdentity = useStore($userIdentity);

    useEffect(() => {
        if (!userId) {
            // User is NOT fetched yet
            return;
        }

        if (userIdentity && userIdentity.user_id === userId) {
            // User identity have already been fetched & userId did NOT change
            return;
        }

        fetchUserIdentity(userId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `User identity for user with ID of ${userId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to core users page
                navigate(Endpoints.TASKS);
            }
        });
    }, [userId, userIdentity]);

    useEffect(() => {
        return () => {
            resetUserIdentity();
        };
    }, []);

    if (!userId) {
        return null;
    }

    if (!userIdentity) {
        return (
            <Flex justify="center">
                <Spinner className="mt-3" />
            </Flex>
        );
    }

    return (
        <div {...props}>
            <VerificationManagement
                className="mb-3"
                onVerifyUserSuccess={onVerifyUserSuccess}
                onRemoveUserVerificationSuccess={onRemoveUserVerificationSuccess}
            />
            <RoleManagement className="mb-3" />
            <EmailManagement className="mb-3" onChangeEmailSuccess={onChangeEmailSuccess} />
            <AuthenticationMethodList className="mb-3" />
            <DangerZone />
        </div>
    );
};

export default UserSettings;
