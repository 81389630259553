import React, { HTMLAttributes } from 'react';
import Heading from '@components/Heading';
import { Button, Card, Classes, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { useStore } from 'effector-react';
import ConfirmationPopover from '@components/ConfirmationPopover';
import { $userIdentity } from '../../../../store/states';
import { disableUserGoogleAuth } from '../../../../store/effects';
import { userIdentityApi } from '../../../../store/apis';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { ClientUserPermission, CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

export type Props = HTMLAttributes<HTMLDivElement>;

const GoogleAuthControl: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    const isDisabling = useStore(disableUserGoogleAuth.pending);

    if (!identity || !me) {
        return null;
    }

    const isAllowed = {
        setup: false,
        remove: false,
    };
    if (identity.user_id === me.user.id) {
        // Self permissions
        isAllowed.setup = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_GOOGLE_SETUP);
        isAllowed.remove = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_GOOGLE_REMOVE);

    } else if ((identity.type === UserType.CLIENT) && permissions.isEnabled.clientUser) {
        // Client permission
        isAllowed.setup = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_GOOGLE_SETUP);
        isAllowed.remove = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_GOOGLE_REMOVE);

    } else if ((identity.type === UserType.CORE) && permissions.isEnabled.coreUser) {
        // Core permissions
        isAllowed.setup = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_GOOGLE_SETUP);
        isAllowed.remove = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_GOOGLE_REMOVE);
    }

    const handleDisable = async () => {
        try {
            await disableUserGoogleAuth(identity.user_id);
            userIdentityApi.disableGoogleAuth();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    // Renders control card for this auth method, when method is set up
    const renderIsSetupStateControlCard = () => {
        const renderRemoveButton = () => {
            if (!isAllowed.remove) {
                return null;
            }

            return (
                <ConfirmationPopover
                    title="Disable 'Login with Google' auth?"
                    description="When confirmed, user won't be able to login using 'Login with Google' button"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleDisable}
                        >
                            Yes, disable
                        </Button>,
                    ]}
                >
                    <Button minimal outlined={isDisabling} loading={isDisabling} disabled={isDisabling}
                            intent={Intent.DANGER}>
                        Disable
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="tick-circle" className="mr-1" intent={Intent.SUCCESS} size={15} />
                        <Heading type="h4">Login with Google</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderRemoveButton()}
                    </Flex>
                </Flex>
                <DevText muted>User can login using "Login with Google" button</DevText>
            </Card>
        );
    };

    // Renders control card when auth is not set up
    const renderNotSetupStateControlCard = () => {
        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="cross-circle" className="mr-1" size={15} />
                        <Heading type="h4">Login with Google</Heading>
                    </Flex>
                </Flex>
                <DevText muted>Google account is not associated with the account</DevText>
            </Card>
        );
    };

    return <>{identity.is_google_auth_setup ? renderIsSetupStateControlCard() : renderNotSetupStateControlCard()}</>;
};

export default GoogleAuthControl;
