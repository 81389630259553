import { Button, Card, Icon } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import ChangeUserEmailModal, { ChangeUserEmailModalProps } from '../../modals/ChangeUserEmailModal';
import { userIdentityApi } from '../../store/apis';
import { $userIdentity } from '../../store/states';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { ClientUserPermission, CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

export interface EmailManagementProps {
    onChangeEmailSuccess: (email: string) => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & EmailManagementProps;

const EmailManagement: React.FC<Props> = ({ onChangeEmailSuccess, ...props }) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    if (!identity || !me) {
        return null;
    }

    const isAllowed = {
        manual: false,
        flow: false,
    };
    if (identity.user_id === me.user.id) {
        // Self permissions
        isAllowed.manual = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.EMAIL_UPDATE_MANUAL);
        isAllowed.flow = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.EMAIL_UPDATE_FLOW);

    } else if ((identity.type === UserType.CLIENT) && permissions.isEnabled.clientUser) {
        // Client permission
        isAllowed.manual = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.EMAIL_UPDATE_MANUAL);
        isAllowed.flow = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.EMAIL_UPDATE_FLOW);

    } else if ((identity.type === UserType.CORE) && permissions.isEnabled.coreUser) {
        // Core permissions
        isAllowed.manual = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.EMAIL_UPDATE_MANUAL);
        isAllowed.flow = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.EMAIL_UPDATE_FLOW);
    }

    // List of email authenticator names, which are blocking email from changing
    const emailLockAuthenticatorNameList: string[] = [];

    // Google authentication blocks email change
    if (identity.is_google_auth_setup) {
        emailLockAuthenticatorNameList.push('Google');
    }

    // Control card, when email can be changed (not locked by one of the authentication methods)
    const renderChangeableEmailControlCard = () => {
        const renderUpdateEmailButton = () => {
            if (!isAllowed.flow && !isAllowed.manual) {
                return null;
            }

            return (
                <Button
                    onClick={() =>
                        openModal<ChangeUserEmailModalProps>({
                            ModalComponent: ChangeUserEmailModal,
                            data: {
                                userId: identity.user_id,
                                workspaceId: identity.workspace_id,
                                currentEmail: identity.email,
                                onChangeEmailSuccess: (email) => {
                                    userIdentityApi.setEmail({ email });
                                    onChangeEmailSuccess(email);
                                },
                                isManualAllowed: isAllowed.manual,
                                isFlowAllowed: isAllowed.flow,
                            },
                        })
                    }
                >
                    Change email
                </Button>
            );
        }

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Heading type="h4">{identity.email}</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderUpdateEmailButton()}
                    </Flex>
                </Flex>
                <DevText muted>This is email is associated with user account, and can be used to log in</DevText>
            </Card>
        );
    };

    // Control card, when email is locked by one of the authentication methods
    const renderLockedEmailControlCard = () => {
        const authenticatorList = emailLockAuthenticatorNameList.join(', ');

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="lock" className="mr-1" size={15} />
                        <Heading type="h4">{identity.email}</Heading>
                    </Flex>
                </Flex>
                <DevText muted>
                    Email is controlled by authentication method: {authenticatorList}. To change email, please remove
                    this authentication method first
                </DevText>
            </Card>
        );
    };

    return (
        <div {...props}>
            <Heading type="h3" className="mb-1">
                Email
            </Heading>
            {emailLockAuthenticatorNameList.length === 0
                ? renderChangeableEmailControlCard()
                : renderLockedEmailControlCard()}
        </div>
    );
};

export default EmailManagement;
