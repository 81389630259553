import {
    NotificationListResource,
    NotificationResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import React from 'react';
import TaskDeadlineApproachingNotification from '../components/notifications/task/deadline/TaskDeadlineApproachingNotification';
import TaskDeadlineExceededNotification from '../components/notifications/task/deadline/TaskDeadlineExceededNotification';
import TaskDeadlineRemovedNotification from '../components/notifications/task/deadline/TaskDeadlineRemovedNotification';
import TaskDeadlineUpdatedNotification from '../components/notifications/task/deadline/TaskDeadlineUpdatedNotification';
import TaskParticipationCreatedNotification from '../components/notifications/task/participation/TaskParticipationCreatedNotification';
import TaskParticipationRemovedNotification from '../components/notifications/task/participation/TaskParticipationRemovedNotification';
import TaskPauseAppliedNotification from '../components/notifications/task/pause/TaskPauseAppliedNotification';
import TaskPauseResumedNotification from '../components/notifications/task/pause/TaskPauseResumedNotification';
import TaskPriorityElevatedNotification from '../components/notifications/task/priority/TaskPriorityElevatedNotification';
import TaskPriorityRemovedNotification from '../components/notifications/task/priority/TaskPriorityRemovedNotification';
import TaskQueueTransitionedNotification from '../components/notifications/task/queue/TaskQueueTransitionedNotification';
import TaskStateTransitionedNotification from '../components/notifications/task/state/TaskStateTransitionedNotification';
import TaskArchivedNotification from '../components/notifications/task/TaskArchivedNotification';
import TaskDeliveredNotification from '../components/notifications/task/TaskDeliveredNotification';
import TaskPublishedNotification from '../components/notifications/task/TaskPublishedNotification';
import TaskReopenedNotification from '../components/notifications/task/TaskReopenedNotification';
import TaskRestoredNotification from '../components/notifications/task/TaskRestoredNotification';
import TaskDeliverableCommentCreatedNotification from '../components/notifications/taskDeliverableComment/TaskDeliverableCommentCreatedNotification';
import TaskDeliverableCommentResolvedNotification from '../components/notifications/taskDeliverableComment/TaskDeliverableCommentResolvedNotification';
import TaskMessageCreatedNotification from '../components/notifications/taskMessage/TaskMessageCreatedNotification';
import TaskMessagePinnedNotification from '../components/notifications/taskMessage/TaskMessagePinnedNotification';
import UserNoteCreatedNotification from '../components/notifications/userNote/UserNoteCreatedNotification';

// TODO: put in types + split to different maps by entity then connect here into one big map
const notificationFactory: {
    [activityType in string]: (
        notification: NotificationResource,
        entity: NotificationListResource['entity'],
        authorizedUserId: ID
    ) => React.ReactElement;
} = {
    'task.published': (notification, entity, authorizedUserId) => (
        <TaskPublishedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskPublish={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.delivered': (notification, entity, authorizedUserId) => (
        <TaskDeliveredNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.deadline.approaching': (notification, entity, authorizedUserId) => (
        <TaskDeadlineApproachingNotification
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.deadline.exceeded': (notification, entity, authorizedUserId) => (
        <TaskDeadlineExceededNotification
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.participation.created': (notification, entity, authorizedUserId) => (
        <TaskParticipationCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.participation.removed': (notification, entity, authorizedUserId) => (
        <TaskParticipationRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.state.transitioned': (notification, entity, authorizedUserId) => (
        <TaskStateTransitionedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.pause.applied': (notification, entity, authorizedUserId) => (
        <TaskPauseAppliedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.pause.released': (notification, entity, authorizedUserId) => (
        <TaskPauseResumedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.archived': (notification, entity, authorizedUserId) => (
        <TaskArchivedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.restored': (notification, entity, authorizedUserId) => (
        <TaskRestoredNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.reopened': (notification, entity, authorizedUserId) => (
        <TaskReopenedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.queue.transitioned': (notification, entity, authorizedUserId) => (
        <TaskQueueTransitionedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.deadline.set': (notification, entity, authorizedUserId) => (
        <TaskDeadlineUpdatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.deadline.removed': (notification, entity, authorizedUserId) => (
        <TaskDeadlineRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.priority.elevated': (notification, entity, authorizedUserId) => (
        <TaskPriorityElevatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task.priority.removed': (notification, entity, authorizedUserId) => (
        <TaskPriorityRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task_message.new': (notification, entity, authorizedUserId) => (
        <TaskMessageCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskMessage={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task_message.pinned': (notification, entity, authorizedUserId) => (
        <TaskMessagePinnedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskMessage={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task_deliverable_comment.new': (notification, entity, authorizedUserId) => (
        <TaskDeliverableCommentCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskDeliverableComment={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'task_deliverable_comment.resolved': (notification, entity, authorizedUserId) => (
        <TaskDeliverableCommentResolvedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskDeliverableComment={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
    'user_note.new': (notification, entity, authorizedUserId) => (
        <UserNoteCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            userNote={entity[notification.activity.entity_type][notification.activity.entity_id] ?? null}
        />
    ),
};

export default notificationFactory;
