import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
    getStringWebURLValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import SelectFormField from '@components/SelectFormField';
import TextAreaFormField from '@components/TextAreaFormField';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { ModalProps } from '@modals/types';
import { UpdateUserInformationInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';
import React, { useMemo } from 'react';
import { clientApi } from '../../store/apis';
import { updateClientInformation } from '../../store/effects';
import { companyBusinessTypes } from './consts';
import { CountrySelectItem, TimezoneSelectItem } from './types';

export interface UpdateClientMainInformationModalProps {
    clientId: ID;
    firstName: string;
    lastName: string;
    email: string;
    timezoneName: string;
    website: string;
    companyBusinessType: string;
    companyName: string;
    companyPosition: string;
    phone: string;
    addressCountry: string;
    addressCity: string;
    addressPostalCode: string;
    addressFirstLine: string;
    addressSecondLine: string;
    bio: string;
}

type Props = ModalProps<UpdateClientMainInformationModalProps>;

const clientFirstNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const clientLastNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const clientSelectedAddressCountryKeyValidators = [];
const clientSelectedTimezoneValidators = [];
const clientWebsiteValidators = [getOptionalStringValidator([getStringWebURLValidator()])];
const clientCompanyBusinessTypeValidators = [];
const clientCompanyNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientCompanyPositionValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientPhoneValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientAddressPostalCodeValidators = [
    getOptionalStringValidator([
        getStringRequiredValidator(),
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientAddressCityValidators = [
    getOptionalStringValidator([
        getStringRequiredValidator(),
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientAddressFirstLineValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientAddressSecondLineValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const clientBioValidators = [
    getOptionalStringValidator([
        getStringRequiredValidator(),
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];

const UpdateClientMainInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const clientFirstName = useTextFormField({
        id: 'client-first-name',
        validators: clientFirstNameValidators,
        initialValue: data?.firstName ?? '',
    });

    const clientLastName = useTextFormField({
        id: 'client-last-name',
        validators: clientLastNameValidators,
        initialValue: data?.lastName ?? '',
    });

    const clientSelectedAddressCountryKey = useCustomSelectFormField<string | null>({
        id: 'client-selected-address-country-key',
        validators: clientSelectedAddressCountryKeyValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const clientSelectedTimezone = useCustomSelectFormField<string | null>({
        id: 'client-selected-timezone',
        validators: clientSelectedTimezoneValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const clientWebsite = useTextFormField({
        id: 'client-website',
        validators: clientWebsiteValidators,
        initialValue: data?.website ?? '',
    });

    const clientCompanyBusinessType = useCustomSelectFormField<string | null>({
        id: 'client-company-business-type',
        validators: clientCompanyBusinessTypeValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const clientCompanyName = useTextFormField({
        id: 'client-company-name',
        validators: clientCompanyNameValidators,
        initialValue: data?.companyName ?? '',
    });

    const clientCompanyPosition = useTextFormField({
        id: 'client-company-position',
        validators: clientCompanyPositionValidators,
        initialValue: data?.companyPosition ?? '',
    });

    const clientPhone = useTextFormField({
        id: 'client-phone',
        validators: clientPhoneValidators,
        initialValue: data?.phone ?? '',
    });

    const clientAddressPostalCode = useTextFormField({
        id: 'client-address-postal-code',
        validators: clientAddressPostalCodeValidators,
        initialValue: data?.addressPostalCode ?? '',
    });

    const clientAddressCity = useTextFormField({
        id: 'client-address-city',
        validators: clientAddressCityValidators,
        initialValue: data?.addressCity ?? '',
    });

    const clientAddressFirstLine = useTextFormField({
        id: 'client-address-first-line',
        validators: clientAddressFirstLineValidators,
        initialValue: data?.addressFirstLine ?? '',
    });

    const clientAddressSecondLine = useTextFormField({
        id: 'client-address-second-line',
        validators: clientAddressSecondLineValidators,
        initialValue: data?.addressSecondLine ?? '',
    });

    const clientBio = useTextFormField({
        id: 'client-bio',
        validators: clientBioValidators,
        initialValue: data?.bio ?? '',
    });

    const form = useForm<UserRef>({
        fields: [
            clientFirstName,
            clientLastName,
            clientSelectedAddressCountryKey,
            clientSelectedTimezone,
            clientWebsite,
            clientCompanyBusinessType,
            clientCompanyName,
            clientCompanyPosition,
            clientPhone,
            clientAddressPostalCode,
            clientAddressCity,
            clientAddressFirstLine,
            clientAddressSecondLine,
            clientBio,
        ],
        apiCall: async () => {
            try {
                const updateClientInformationInput: UpdateUserInformationInput = {
                    first_name: clientFirstName.value,
                    last_name: clientLastName.value,
                    address_country: clientSelectedAddressCountryKey.value ?? undefined,
                    timezone_name: clientSelectedTimezone.value ?? undefined,
                    website: clientWebsite.value,
                    company_business_type: clientCompanyBusinessType.value ?? undefined,
                    company_name: clientCompanyName.value,
                    company_position: clientCompanyPosition.value,
                    phone: clientPhone.value,
                    address_city: clientAddressCity.value,
                    address_postal_code: clientAddressPostalCode.value,
                    address_first_line: clientAddressFirstLine.value,
                    address_second_line: clientAddressSecondLine.value,
                    bio: clientBio.value,
                };

                const userRef = await updateClientInformation({
                    id: data!.clientId,
                    input: updateClientInformationInput,
                });

                clientApi.update({ ...updateClientInformationInput });
                closeModal?.();

                return { response: userRef };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const timezoneNames = useMemo<TimezoneSelectItem[]>(() => {
        const extendedTimezoneNames: TimezoneSelectItem[] = [];

        for (const timezoneName of TimezoneUtils.getTimezoneNames()) {
            extendedTimezoneNames.push({ GMT: TimezoneUtils.getTimezoneGMTValue(timezoneName), name: timezoneName });
        }

        return extendedTimezoneNames;
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderCountryFormFieldSelect = () => {
        const filterItemOption = (query: string, item: CountrySelectItem) => {
            return item.countryName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: CountrySelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.key === clientSelectedAddressCountryKey.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.key}
                    text={item.countryName}
                    label={item.key}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: CountrySelectItem) => {
            clientSelectedAddressCountryKey.handleChange(item.key);
        };

        return (
            <FormGroup
                label="Country"
                intent={!!clientSelectedAddressCountryKey.error ? Intent.DANGER : Intent.NONE}
                helperText={clientSelectedAddressCountryKey.error}
            >
                <Select<CountrySelectItem>
                    filterable
                    items={Object.values(countriesData)}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for country',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                        onClose: clientSelectedAddressCountryKey.handleClose,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a country',
                        text:
                            clientSelectedAddressCountryKey.value &&
                            countriesData[clientSelectedAddressCountryKey.value].countryName,
                        icon: 'add',
                    }}
                />
            </FormGroup>
        );
    };

    const renderTimezoneFormFieldSelect = () => {
        const filterItemOption = (query: string, item: TimezoneSelectItem) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: TimezoneSelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.name === clientSelectedTimezone.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.name}
                    text={item.name}
                    label={item.GMT}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: TimezoneSelectItem) => {
            clientSelectedTimezone.handleChange(item.name);
        };

        return (
            <FormGroup
                label="Time zone"
                intent={!!clientSelectedTimezone.error ? Intent.DANGER : Intent.NONE}
                helperText={clientSelectedTimezone.error}
            >
                <Select<TimezoneSelectItem>
                    filterable
                    items={timezoneNames}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for time zone',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a timezone',
                        text: clientSelectedTimezone.value,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Edit client</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        {/* First name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={clientFirstName}
                                formGroupProps={{ label: 'First name' }}
                                inputProps={{ placeholder: 'Enter first name' }}
                            />
                        </Grid>

                        {/* Last name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={clientLastName}
                                formGroupProps={{ label: 'Last name' }}
                                inputProps={{ placeholder: 'Enter last name' }}
                            />
                        </Grid>

                        {/* Country */}
                        <Grid lg={12} xs={12}>
                            {renderCountryFormFieldSelect()}
                        </Grid>

                        {/* Company name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={clientCompanyName}
                                formGroupProps={{ label: 'Company name' }}
                                inputProps={{ placeholder: 'Enter company name' }}
                            />
                        </Grid>

                        {/* Company position */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={clientCompanyPosition}
                                formGroupProps={{ label: 'Company position' }}
                                inputProps={{ placeholder: 'Enter company position' }}
                            />
                        </Grid>

                        {/* Timezone */}
                        <Grid lg={12}>{renderTimezoneFormFieldSelect()}</Grid>

                        {/* Website */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={clientWebsite}
                                formGroupProps={{ label: 'Website' }}
                                inputProps={{ placeholder: 'Enter website' }}
                            />
                        </Grid>

                        {/* Type of business */}
                        <Grid lg={6} xs={12}>
                            <SelectFormField
                                field={clientCompanyBusinessType}
                                items={companyBusinessTypes}
                                formGroupProps={{ label: 'Type of business' }}
                                selectProps={{ selectButtonProps: { placeholder: 'Select business type' } }}
                            />
                        </Grid>

                        {/* Phone number */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={clientPhone}
                                formGroupProps={{ label: 'Phone number' }}
                                inputProps={{ placeholder: 'Enter phone number' }}
                            />
                        </Grid>

                        {/* Postal code */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={clientAddressPostalCode}
                                formGroupProps={{ label: 'Postal code' }}
                                inputProps={{ placeholder: 'Enter postal code' }}
                            />
                        </Grid>

                        {/* City */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={clientAddressCity}
                                formGroupProps={{ label: 'City' }}
                                inputProps={{ placeholder: 'Enter city' }}
                            />
                        </Grid>

                        {/* First address */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={clientAddressFirstLine}
                                formGroupProps={{ label: 'Street address 1' }}
                                inputProps={{ placeholder: 'Enter street address 1' }}
                            />
                        </Grid>

                        {/* Second address */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={clientAddressSecondLine}
                                formGroupProps={{ label: 'Street address 2' }}
                                inputProps={{ placeholder: 'Enter street address 2' }}
                            />
                        </Grid>

                        {/* Bio */}
                        <Grid lg={12} xs={12}>
                            <TextAreaFormField
                                field={clientBio}
                                formGroupProps={{ label: 'Bio' }}
                                textAreaProps={{ placeholder: 'Enter bio' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateClientMainInformationModal;
