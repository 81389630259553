import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { checkIfSubscriptionTrialPresent } from '@data/functions/subscription';
import Flex from '@components/Flex';
import { Card, Icon } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';

export interface SubscriptionTrialInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionTrialInformationProps;

const SubscriptionTrialInformation: React.FC<Props> = ({ subscription, ...props }) => {
    const trial = subscription.trial;

    if (!checkIfSubscriptionTrialPresent(subscription)) {
        // Subscription has no trial
        return null;
    }

    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">
                Trial Period
            </Heading>

            <Card>
                <Flex direction="row" justify="space-between">
                    <div>
                        <Flex direction="row" align="center">
                            <Icon size={16} icon="time" className="mr-1" />
                            <Heading type="h5">Until: {moment(trial.end_at).format('D MMM, YYYY')}</Heading>
                        </Flex>

                        <DevText muted>Ends {moment(trial.end_at).fromNow()}</DevText>
                    </div>

                    <DevText muted>Started: {moment(trial.start_at).format('D MMM, YYYY')}</DevText>
                </Flex>
            </Card>
        </div>
    );
};

export default SubscriptionTrialInformation;
