/*
  Filter accepts:
  - query: string;                                                      // Search input (string)
  - workspace_id: ID[];                                                 // List with dynamic search
  - licence_plan_id: ID[];                                              // List with dynamic search 
  - is_shutdown: '1' | '0';                                             // List (Flag filter)
  - is_verified: '1' | '0';                                             // List (Flag filter)
*/

enum PlatformClientUserFilterType {
    // WORKSPACES = 'WORKSPACES',
    LICENCE_PLANS = 'LICENCE_PLANS',
    ARCHIVED = 'ARCHIVED',
    VERIFIED = 'VERIFIED',
}

export default PlatformClientUserFilterType;
