import {
    AddUserNotificationEmailInput,
    UpdateUserNotificationEmailInput,
} from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import { UserNotificationEmailResource } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/resources';
import { createApi } from 'effector';
import moment from 'moment';
import { $notificationEmails } from './states';

// Api to manage notification emails
export const notificationEmailsApi = createApi($notificationEmails, {
    create: (store, payload: { id: ID; input: AddUserNotificationEmailInput }) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        // Create new moment
        const momentNowUtc = moment().utc().format();

        // Create notification email
        const newNotificationEmail: UserNotificationEmailResource = {
            id: payload.id,
            created_at: momentNowUtc,
            email: payload.input.email,
            name: payload.input.name ?? '',
            updated_at: momentNowUtc,
            user_id: payload.input.user_id,
            verification_last_generated_at: payload.input.is_verification_code_create_enabled ? momentNowUtc : null,
            verified_at: payload.input.is_verified ? momentNowUtc : null,
        };

        return {
            ...store,
            items: [newNotificationEmail, ...store.items],
        };
    },

    update: (store, payload: { id: ID; input: UpdateUserNotificationEmailInput }) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        // Copy notification emails
        const copyNotificationEmails = [...store.items];

        // Find notification email
        const foundNotificationEmailIndex = copyNotificationEmails.findIndex(
            (notificationEmail) => notificationEmail.id === payload.id
        );
        if (foundNotificationEmailIndex === -1) {
            // Notification emails was NOT found
            return store;
        }

        // Get found notification email
        const foundNotificationEmail = copyNotificationEmails[foundNotificationEmailIndex];

        // Update notification email
        foundNotificationEmail.name = payload.input.name;

        return {
            ...store,
            items: copyNotificationEmails,
        };
    },
    remove: (store, notificationEmailId: ID) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        return {
            ...store,
            items: [...store.items].filter((notificationEmail) => notificationEmail.id !== notificationEmailId),
        };
    },
    removeVerification: (store, notificationEmailId: ID) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        // Copy notification emails
        const copyNotificationEmails = [...store.items];

        // Find notification email
        const foundNotificationEmailIndex = copyNotificationEmails.findIndex(
            (notificationEmail) => notificationEmail.id === notificationEmailId
        );
        if (foundNotificationEmailIndex === -1) {
            // Notification emails was NOT found
            return store;
        }

        // Get found notification email
        const foundNotificationEmail = copyNotificationEmails[foundNotificationEmailIndex];

        // Create new moment
        const momentNowUtc = moment().utc().format();

        // Remove notification email verification
        foundNotificationEmail.verified_at = null;

        return {
            ...store,
            items: copyNotificationEmails,
        };
    },
    generateVerificationCode: (store, notificationEmailId: ID) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        // Copy notification emails
        const copyNotificationEmails = [...store.items];

        // Find notification email
        const foundNotificationEmailIndex = copyNotificationEmails.findIndex(
            (notificationEmail) => notificationEmail.id === notificationEmailId
        );
        if (foundNotificationEmailIndex === -1) {
            // Notification emails was NOT found
            return store;
        }

        // Get found notification email
        const foundNotificationEmail = copyNotificationEmails[foundNotificationEmailIndex];

        // Create new moment
        const momentNowUtc = moment().utc().format();

        // Remove notification email verification
        foundNotificationEmail.verification_last_generated_at = momentNowUtc;

        return {
            ...store,
            items: copyNotificationEmails,
        };
    },
    verify: (store, notificationEmailId: ID) => {
        if (!store) {
            // Notification emails are NOT fetched yet
            return null;
        }

        // Copy notification emails
        const copyNotificationEmails = [...store.items];

        // Find notification email
        const foundNotificationEmailIndex = copyNotificationEmails.findIndex(
            (notificationEmail) => notificationEmail.id === notificationEmailId
        );
        if (foundNotificationEmailIndex === -1) {
            // Notification emails was NOT found
            return store;
        }

        // Get found notification email
        const foundNotificationEmail = copyNotificationEmails[foundNotificationEmailIndex];

        // Create new moment
        const momentNowUtc = moment().utc().format();

        // Remove notification email verification
        foundNotificationEmail.verified_at = momentNowUtc;

        return {
            ...store,
            items: copyNotificationEmails,
        };
    },
});
