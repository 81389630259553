import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { taskStateInformation } from '@data/consts';
import { PlatformTaskFilterData } from '@pages/Tasks/types';
import { platformFiltersInformation } from '@pages/Tasks/consts';
import { PlatformTaskFilterType } from '@pages/Tasks/enums';
import { $isQueryHydrationFinished } from '@pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@pages/Tasks/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';
import Circle from '@components/Circle';
import Flex from '@components/Flex';

export interface TaskStatesPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskStatesPlatformFilterProps;

const TaskStatesPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedStates, setSelectedStates] = useState<PlatformTaskFilterData['states']>(
        platformTaskFilterData.states
    );

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedStates(platformTaskFilterData.states);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.states.length === 0) {
            setSelectedStates([]);
        }
    }, [platformTaskFilterData.states]);

    const getFilterTagLabel = () => {
        if (selectedStates.length === 0) return '';

        return selectedStates
            .map((state, index) => {
                if (index === selectedStates.length - 1) {
                    // Last element
                    return `${taskStateInformation[state].title}`;
                }

                return `${taskStateInformation[state].title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedStates.length > 0) {
            // At least 1 task state selected
            return 'States:';
        }

        return 'States';
    };

    const handleRemoveFilter = () => {
        if (selectedStates.length !== 0) {
            // At least 1 task state was selected

            setSelectedStates([]);

            if (platformTaskFilterData.states.length !== 0) {
                // Reset task states to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, states: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_STATES].isPinned) {
            // Platform task state filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (key: PlatformTaskFilterData['states'][number]) => {
        if (selectedStates.includes(key)) {
            // Task state is already checked
            setSelectedStates(selectedStates.filter((state) => state !== key));
        } else {
            // Task state is NOT checked yet
            setSelectedStates([...selectedStates, key]);
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedStates) !== JSON.stringify(platformTaskFilterData.states)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, states: selectedStates });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_STATES].isPinned) {
            return;
        }

        if (selectedStates.length === 0) {
            onRemove();
        }
    };

    const renderRow = (key: PlatformTaskFilterData['states'][number]) => {
        return (
            <li className="mb-1" key={key}>
                <Flex justify="space-between" align="center">
                    <Checkbox
                        checked={selectedStates.includes(key)}
                        className="mr-2"
                        label={taskStateInformation[key].title}
                        onClick={() => handleCheckboxClick(key)}
                    />
                    <Circle size="11px" color={taskStateInformation[key].color} />
                </Flex>
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['states'][number]>
            isOpen={isOpen}
            removable={selectedStates.length > 0}
            title="States"
            position={Position.BOTTOM_LEFT}
            list={Object.values(TaskState)}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_STATES].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskStatesPlatformFilter;
