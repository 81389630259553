import React, { HTMLAttributes } from 'react';
import { AdaptabilityProperties } from './types';
import { StyledFlex } from './styled';

type FullHeight = boolean;
type FullWidth = boolean;
type Inline = boolean;
type FlexGrow = number | string;
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
type Direction = 'column-reverse' | 'column' | 'row-reverse' | 'row';
type AlignItems =
    | 'normal'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'baseline'
    | 'stretch';
type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
type Gap = string;

export interface FlexProps {
    fullHeight?: FullHeight | AdaptabilityProperties<FullHeight>;
    fullWidth?: FullWidth | AdaptabilityProperties<FullWidth>;
    inline?: Inline | AdaptabilityProperties<Inline>;
    flexGrow?: FlexGrow | AdaptabilityProperties<FlexGrow>;
    flexWrap?: FlexWrap | AdaptabilityProperties<FlexWrap>;
    direction?: Direction | AdaptabilityProperties<Direction>;
    align?: AlignItems | AdaptabilityProperties<AlignItems>;
    justify?: JustifyContent | AdaptabilityProperties<JustifyContent>;
    gap?: Gap | AdaptabilityProperties<Gap>;
}

export type Props = HTMLAttributes<HTMLDivElement> & FlexProps;

const Flex: React.FC<Props> = ({
    inline = false,
    fullHeight = false,
    fullWidth = false,
    flexWrap = 'nowrap',
    align = 'flex-start',
    justify = 'flex-start',
    direction = 'row',
    gap,
    flexGrow,
    ...props
}) => (
    <StyledFlex
        $flexGrow={flexGrow}
        $fullHeight={fullHeight}
        $fullWidth={fullWidth}
        $flexWrap={flexWrap}
        $inline={inline}
        $direction={direction}
        $align={align}
        $justify={justify}
        $gap={gap}
        {...props}
    />
);

export default Flex;
