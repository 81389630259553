import { CursorPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { NotificationListResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import domain from './domain';

// Fetch task message list
export const fetchNotifications = domain.createEffect<{ pagination: CursorPaginationInput }, NotificationListResource>({
    name: 'fetchNotifications',
    handler: async ({ pagination }) => {
        return await repository.notification().get(pagination);
    },
});
