import { Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Document, pdfjs, DocumentProps, PageProps } from 'react-pdf';
import { Wrapper, Page, PageNumber } from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PdfViewerProps {
    src: string;
    className?: string;
    documentProps?: DocumentProps;
    pageProps?: PageProps;
}

export type Props = PdfViewerProps;

const PdfViewer: React.FC<Props> = ({ src, className = '', pageProps, documentProps }) => {
    const { onDocumentLoadSuccess, ...documentPropsToSpread } = documentProps ?? {};
    const [numPages, setNumPages] = useState<number | null>(null);

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        onDocumentLoadSuccess?.(numPages);
    };

    return (
        <Wrapper className={className}>
            <Document
                file={src}
                onLoadSuccess={handleDocumentLoadSuccess}
                loading={() => <Spinner size={60} />}
                {...documentPropsToSpread}
            >
                {Array.from(new Array(numPages), (_, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        loading={() => <Spinner size={60} />}
                        {...pageProps}
                    >
                        <PageNumber>{index + 1}</PageNumber>
                    </Page>
                ))}
            </Document>
        </Wrapper>
    );
};

export default PdfViewer;
