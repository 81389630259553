import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import {
    ApproveTaskDeliverablesInput,
    ArchiveTaskDeliverablesInput,
    ChangeTaskDeliverablesPathLocation,
    CreateTaskDeliverablesInput,
    RestoreTaskDeliverablesInput,
    TaskDeliverableFilterInput,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import { TaskDeliverableRefList } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/refs';
import domain from './domain';

// Fetch list of task deliverables
export const fetchTaskDeliverables = domain.createEffect<
    TaskDeliverableFilterInput,
    CollectionResource<TaskDeliverableResource, {}>
>({
    name: 'fetchTaskDeliverables',
    handler: async (filter) => {
        return await repository.taskDeliverable().get(filter);
    },
});

// Fetch single task deliverable by ID
export const fetchTaskDeliverableById = domain.createEffect<ID, TaskDeliverableResource>({
    name: 'fetchTaskDeliverableById',
    handler: async (id) => {
        return await repository.taskDeliverable().getById(id);
    },
});

// Create multiple task deliverables
export const createTaskDeliverablesBatch = domain.createEffect<CreateTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'createTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().createBatch(input);
    },
});

// Approve task deliverables
export const approveTaskDeliverablesBatch = domain.createEffect<ApproveTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'approveTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().approveBatch(input);
    },
});

// Archive task deliverables
export const archiveTaskDeliverablesBatch = domain.createEffect<ArchiveTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'archiveTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().archiveBatch(input);
    },
});

// Restore task deliverables
export const restoreTaskDeliverablesBatch = domain.createEffect<RestoreTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'restoreTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().restoreBatch(input);
    },
});

// Change location path of multiple task deliverables
export const moveTaskDeliverablesBatchToDirectory = domain.createEffect<
    ChangeTaskDeliverablesPathLocation,
    TaskDeliverableRefList
>({
    name: 'moveTaskDeliverablesBatchToDirectory',
    handler: async (input) => {
        return await repository.taskDeliverable().changePathLocation(input);
    },
});
