import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`;

export const PdfWrapper = styled.div<{ $cursor?: string }>`
    position: relative;
    cursor: ${({ $cursor = 'crosshair' }) => $cursor};
`;

export const Positioner = styled.div`
    place-self: stretch flex-start;
    margin: auto;
`;
