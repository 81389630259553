import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export interface PickupReadyProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & PickupReadyProps;

const PickupReady: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isInProgressAllowed, setIsInProgressAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsInProgressAllowed(
            permissions.isRoot.task
            || permissions.has(TaskPermission.STATE_TRANSITION_IN_PROGRESS),
        );
    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            const res = await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    // TODO: Render helper action - assign participants if none assigned
    // TODO: Set deadline - if none assigned

    const renderInProgressTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isInProgressAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="cycle"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_PROGRESS}
                onClick={() => handleTransitionToState(TaskState.IN_PROGRESS)}
            >In Progress</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Ready for work</Heading>
                <Flex>
                    <div>{renderInProgressTransitionButton()}</div>
                </Flex>
            </Flex>
            <DevText muted>
                Request is ready for work. When ready to start working, move it to the "In Progress" status
            </DevText>
            <DevText muted>Before starting, read the briefing and study the brand profile carefully</DevText>
        </Card>
    );
};

export default PickupReady;
