import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';

export interface SubscriptionAdditionalInformationProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionAdditionalInformationProps;

const SubscriptionAdditionalInformation: React.FC<Props> = ({ subscription, ...props }) => {
    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">
                Additional Info
            </Heading>

            <Card>
                {/* External Stripe ID */}
                <CommonInformationBlock
                    className="mb-2"
                    icon="share"
                    title="Stripe ID"
                    text={subscription.external_id}
                />

                {/* Last sync timestamp */}
                <CommonInformationBlock
                    icon="feed-subscribed"
                    title="Last sync timestamp"
                    text={moment(subscription.last_sync_at).format('D MMM, YYYY - HH:mm')}
                />
            </Card>
        </div>
    );
};

export default SubscriptionAdditionalInformation;
