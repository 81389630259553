import React, { HTMLAttributes } from 'react';
import { VirtualLicenceTaskCategoryGroupAttachmentResource } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import { Card, Colors, Elevation, Intent, Tag } from '@blueprintjs/core';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Circle from '@components/Circle';

export interface VirtualLicenceTaskCategoryGroupListProps {
    taskCategoryGroupAttachments: VirtualLicenceTaskCategoryGroupAttachmentResource[];
}
export type Props = HTMLAttributes<HTMLDivElement> & VirtualLicenceTaskCategoryGroupListProps;

// TODO: consider moving to container for reusing on ClientLicences page
// Section: List of task category group attachments
const VirtualLicenceTaskCategoryGroupList: React.FC<Props> = ({ taskCategoryGroupAttachments, ...props }) => {
    // Renders single task category group attachment item
    const renderItem = (item: VirtualLicenceTaskCategoryGroupAttachmentResource) => {
        return (
            <Card compact className="mb-1" elevation={Elevation.ONE}>
                <Flex align="center">
                    <Tag minimal intent={item.is_excluded ? Intent.DANGER : Intent.PRIMARY} className="mr-1">
                        {item.is_excluded ? 'Excluded' : 'Included'}
                    </Tag>

                    <Flex align="center">
                        <Circle
                            color={
                                item.task_category_group.color !== '' ? item.task_category_group.color : Colors.GRAY2
                            }
                            className="mr-small"
                        />
                        <DevText>{item.task_category_group.title}</DevText>
                    </Flex>
                </Flex>
            </Card>
        );
    };

    // Renders current task category group attachment item list
    const renderList = (list: VirtualLicenceTaskCategoryGroupAttachmentResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No request category groups added...</DevText>
                </div>
            );
        }

        return list.map((attachment) => (
            <div key={`virtual-licence-task-category-group-${attachment.task_category_group.id}`}>
                {renderItem(attachment)}
            </div>
        ));
    };

    return (
        <div {...props}>
            <Heading type="h6" className="mb-small">
                Request category groups
            </Heading>
            <DevText muted className="mb-1">
                Request category group rules
            </DevText>

            {renderList(taskCategoryGroupAttachments)}
        </div>
    );
};

export default VirtualLicenceTaskCategoryGroupList;
