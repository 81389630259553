import ConfirmationPopover from '@app/components/ConfirmationPopover';
import FixedWidthPageContainer from '@app/components/FixedWidthPageContainer';
import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import Pagination from '@app/components/Pagination';
import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { openModal } from '@app/containers/modals/store/events';
import { Endpoints } from '@app/data/consts';
import { usePageTitle } from '@app/hooks';
import {
    Button,
    Card,
    Classes,
    Elevation,
    Icon,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Spinner,
    Tag,
} from '@blueprintjs/core';
import {
    TaskCategoryFeaturedBlockItemResource,
    TaskCategoryFeaturedBlockResource,
    TaskCategoryGroupResource,
    TaskCategoryResource,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { fetchClientBrandProfiles } from '../Client/pages/ClientBrandProfiles/store/effects';
import { amountOfTaskCategoryFeaturedBlocksOnPage } from './consts';
import CreateDisabledTaskCategoryFeaturedBlockModal, {
    CreateDisabledTaskCategoryFeaturedBlockModalProps,
} from './modals/CreateDisabledTaskCategoryFeaturedBlockModal';
import UpdateTaskCategoryFeaturedBlockModal, {
    UpdateTaskCategoryFeaturedBlockModalProps,
} from './modals/UpdateTaskCategoryFeaturedBlockModal';
import { taskCategoryFeaturedBlocksApi } from './store/apis';
import {
    fetchTaskCategoryFeaturedBlocks,
    removeTaskCategoryFeaturedBlock,
    restoreTaskCategoryFeaturedBlock,
} from './store/effects';
import { resetTaskCategories, resetTaskCategoryFeaturedBlocks } from './store/events';
import { $taskCategoryFeaturedBlocks } from './store/states';
import { $permissions } from '@containers/store/states';
import { TaskCategoryFeaturedBlockPermission } from 'dy-frontend-permissions/lib/permission';
import NonIdealState from '@app/components/NonIdealState';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { ToastUtils } from '@app/data/utils';
import { useNavigate } from 'react-router-dom';
import TaskCategoryGroupTag from '@app/containers/components/TaskCategoryGroupTag';

const TaskCategoryFeaturedBlocks: React.FC = () => {
    const navigate = useNavigate();

    const permissions = useStore($permissions);

    if (!permissions.isEnabled.taskCategoryFeaturedBlock) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Request category featured blocks');

    const taskCategoryFeaturedBlocks = useStore($taskCategoryFeaturedBlocks);
    const isFetching = useStore(fetchClientBrandProfiles.pending);
    const isRemoving = useStore(removeTaskCategoryFeaturedBlock.pending);
    const isRestoring = useStore(restoreTaskCategoryFeaturedBlock.pending);

    const [page, setPage] = useState(1);

    const handleLoadPage = async (newPage: number) => {
        const pageOffset = (newPage - 1) * amountOfTaskCategoryFeaturedBlocksOnPage;

        try {
            await fetchTaskCategoryFeaturedBlocks({
                pagination: {
                    _pagination: {
                        offset: pageOffset,
                        limit: amountOfTaskCategoryFeaturedBlocksOnPage,
                    },
                },
            });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: 'Request category featured blocks were not found',
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    useEffect(() => {
        handleLoadPage(page);

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        return () => {
            resetTaskCategoryFeaturedBlocks();
            resetTaskCategories();
        };
    }, []);

    const renderCreateButton = () => {
        const isAllowed =
            permissions.isRoot.taskCategoryFeaturedBlock || permissions.has(TaskCategoryFeaturedBlockPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        const handleCreate = () => {
            openModal<CreateDisabledTaskCategoryFeaturedBlockModalProps>({
                ModalComponent: CreateDisabledTaskCategoryFeaturedBlockModal,
                data: {
                    onCreateSuccess: () => (page === 1 ? handleLoadPage(1) : setPage(1)),
                },
            });
        };

        return (
            <Button icon="add" intent={Intent.PRIMARY} onClick={handleCreate}>
                Create featured block
            </Button>
        );
    };

    const renderTaskCategoryFeaturedBlocks = () => {
        if (!taskCategoryFeaturedBlocks) {
            return (
                <Flex direction="row" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (taskCategoryFeaturedBlocks.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            Featured categories were not found
                        </Heading>
                    }
                />
            );
        }

        const renderMoreButton = (block: TaskCategoryFeaturedBlockResource) => {
            // TODO: add permissions
            const isUpdateAllowed =
                permissions.isRoot.taskCategoryFeaturedBlock ||
                permissions.has(TaskCategoryFeaturedBlockPermission.UPDATE);
            const isRemoveAllowed =
                permissions.isRoot.taskCategoryFeaturedBlock ||
                permissions.has(TaskCategoryFeaturedBlockPermission.ARCHIVE);
            const isRestoreAllowed =
                permissions.isRoot.taskCategoryFeaturedBlock ||
                permissions.has(TaskCategoryFeaturedBlockPermission.RESTORE);
            if (!isUpdateAllowed && !isRemoveAllowed && !isRestoreAllowed) {
                return null;
            }

            const handleUpdate = async () => {
                openModal<UpdateTaskCategoryFeaturedBlockModalProps>({
                    ModalComponent: UpdateTaskCategoryFeaturedBlockModal,
                    data: {
                        taskCategoryFeaturedBlockId: block.id,
                        selectedTaskCategoryIds: block.items.map((attachment) => attachment.task_category.id),
                        isEnabled: block.is_enabled,
                        position: block.position,
                        summary: block.summary,
                        title: block.title,
                    },
                });
            };

            const handleRemove = async () => {
                try {
                    // Remove task category featured block
                    const taskCategoryFeaturedBlockRef = await removeTaskCategoryFeaturedBlock(block.id);

                    // Update local state
                    taskCategoryFeaturedBlocksApi.remove(taskCategoryFeaturedBlockRef.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            const handleRestore = async () => {
                try {
                    // Restore task category featured block
                    const taskCategoryFeaturedBlockRef = await restoreTaskCategoryFeaturedBlock(block.id);

                    // Update local state
                    taskCategoryFeaturedBlocksApi.restore(taskCategoryFeaturedBlockRef.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            };

            const renderUpdateMenuItem = () => {
                if (!isUpdateAllowed) {
                    return null;
                }

                return <MenuItem shouldDismissPopover={false} icon="edit" text="Update" onClick={handleUpdate} />;
            };

            const renderRemoveMenuItem = () => {
                if (block.archived_at !== null) {
                    // Task category featured block is already archived
                    return null;
                }

                if (!isRemoveAllowed) {
                    return null;
                }

                return (
                    <ConfirmationPopover
                        fill
                        title="Remove featured block?"
                        description="When confirmed, featured block will be removed"
                        actions={[
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS_OVERRIDE}
                                onClick={handleRemove}
                            >
                                Yes, remove
                            </Button>,
                        ]}
                    >
                        <MenuItem
                            shouldDismissPopover={false}
                            disabled={isRemoving}
                            icon="trash"
                            text="Remove"
                            intent={Intent.DANGER}
                        />
                    </ConfirmationPopover>
                );
            };

            const renderRestoreMenuItem = () => {
                if (block.archived_at === null) {
                    // Task category featured block is NOT archived, can't restore
                    return null;
                }

                if (!isRestoreAllowed) {
                    return null;
                }

                return (
                    <ConfirmationPopover
                        fill
                        title="Restore featured block?"
                        description="When confirmed, featured block will be restored"
                        actions={[
                            <Button
                                intent={Intent.SUCCESS}
                                onClick={handleRestore}
                                className={Classes.POPOVER_DISMISS_OVERRIDE}
                            >
                                Yes, restore
                            </Button>,
                        ]}
                    >
                        <MenuItem
                            shouldDismissPopover={false}
                            disabled={isRestoring}
                            icon="confirm"
                            text="Restore"
                            intent={Intent.SUCCESS}
                        />
                    </ConfirmationPopover>
                );
            };

            const renderMoreButtonMenu = () => {
                return (
                    <Menu>
                        {renderUpdateMenuItem()}
                        {renderRemoveMenuItem()}
                        {renderRestoreMenuItem()}
                    </Menu>
                );
            };

            return (
                <Popover content={renderMoreButtonMenu()}>
                    <Button minimal icon="more" className={Classes.POPOVER_DISMISS} />
                </Popover>
            );
        };

        const renderTaskCategoryGroups = (groups: TaskCategoryGroupResource[]) => {
            return (
                <Flex direction="row" align="center">
                    {groups.map((group) => (
                        <TaskCategoryGroupTag
                            className="mr-small"
                            key={group.id}
                            name={group.title}
                            color={group.color}
                        >
                            {group.title}
                        </TaskCategoryGroupTag>
                    ))}
                </Flex>
            );
        };

        const renderTaskCategory = (taskCategory: TaskCategoryResource) => {
            return (
                <Card compact elevation={Elevation.ONE}>
                    <DevText>
                        <RouterLink
                            style={{ color: 'inherit' }}
                            to={Endpoints.TASK_CATEGORY.replace(':taskCategoryId', taskCategory.id)}
                        >
                            {taskCategory.title}
                        </RouterLink>
                    </DevText>

                    {renderTaskCategoryGroups(taskCategory.groups)}
                </Card>
            );
        };

        const renderTaskCategories = (taskCategoryFeaturedBlockItems: TaskCategoryFeaturedBlockItemResource[]) => {
            if (taskCategoryFeaturedBlockItems.length === 0) {
                return <DevText muted>Featured block do not have any request categories attached yet</DevText>;
            }

            // Get sorted task categories
            const taskCategories = taskCategoryFeaturedBlockItems
                .sort((a, b) => a.position - b.position)
                .map((item) => item.task_category);

            return (
                <Grid container>
                    {taskCategories.map((taskCategory) => (
                        <Grid lg={12} xs={12} key={taskCategory.id} className="mt-2">
                            {renderTaskCategory(taskCategory)}
                        </Grid>
                    ))}
                </Grid>
            );
        };

        const renderTaskCategoryFeaturedBlock = (block: TaskCategoryFeaturedBlockResource) => {
            return (
                <Card>
                    <Flex direction="row" align="center" justify="space-between">
                        <Flex direction="row" align="center">
                            {block.archived_at !== null && (
                                <Tag minimal className="mr-small" intent={Intent.DANGER}>
                                    Archived
                                </Tag>
                            )}
                            {!block.is_enabled && (
                                <Tag minimal className="mr-small" intent={Intent.DANGER}>
                                    Disabled
                                </Tag>
                            )}
                            <Heading type="h4">{block.title}</Heading>
                        </Flex>

                        {renderMoreButton(block)}
                    </Flex>
                    <DevText className="mb-1" muted>
                        {block.summary}
                    </DevText>

                    {renderTaskCategories(block.items)}
                </Card>
            );
        };

        return taskCategoryFeaturedBlocks.items.map((block, index) => (
            <div key={block.id} className={index === 0 ? '' : 'mt-2'}>
                {renderTaskCategoryFeaturedBlock(block)}
            </div>
        ));
    };

    const renderPagination = () => {
        // Task category featured blocks is not fetched yet
        if (taskCategoryFeaturedBlocks === null) {
            return null;
        }

        // Check if offset paginator exist
        if (taskCategoryFeaturedBlocks.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskCategoryFeaturedBlocks.paginator.has_more && taskCategoryFeaturedBlocks.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetching}
                    hasMore={taskCategoryFeaturedBlocks.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTaskCategoryFeaturedBlocksOnPage}
                    totalItems={taskCategoryFeaturedBlocks.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Flex className="mb-2" direction="row" justify="space-between" align="center">
                <Heading type="h3">Featured request categories</Heading>
                {renderCreateButton()}
            </Flex>

            <div className="mb-2">{renderTaskCategoryFeaturedBlocks()}</div>

            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default TaskCategoryFeaturedBlocks;
