import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { OpenTaskListResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { createApi } from 'effector';
import { OpenTaskListData } from 'dy-frontend-shared/lib/data/types';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import { $teamOpenTaskListData } from './states';

const openTaskQueuePositionSortComparator = (a: TaskResource, b: TaskResource) => {
    const formattedA = a.queue_position ?? '';
    const formattedB = b.queue_position ?? '';

    if (formattedA < formattedB) {
        return -1;
    }

    if (formattedA > formattedB) {
        return 1;
    }

    return 0;
};

export const teamOpenTaskListDataApi = createApi($teamOpenTaskListData, {
    initialize: (store, payload: { openTaskList: OpenTaskListResource }) => {
        const openTaskListData: OpenTaskListData = {
            tasks: {
                [OpenTaskType.ACTIVE]: {},
                [OpenTaskType.BACKLOG]: {},
                [OpenTaskType.PAUSED]: {},
            },
            users: {},
            participant_users: {},
            plans: {},
            teams: {},
        };

        // Initialize tasks
        payload.openTaskList.tasks.forEach((task) => {
            let taskType: OpenTaskType | null = null;

            if (task.paused_at) {
                // Paused
                taskType = OpenTaskType.PAUSED;
            } else {
                // NOT paused
                switch (task.queue) {
                    case TaskQueue.ACTIVE:
                        taskType = OpenTaskType.ACTIVE;
                        break;
                    case TaskQueue.BACKLOG:
                        taskType = OpenTaskType.BACKLOG;
                        break;
                }
            }

            if (taskType) {
                openTaskListData.tasks[taskType][task.id] = task;
            }
        });

        for (let openTaskType in openTaskListData.tasks) {
            // Get task map for open task type
            const tasksMap = openTaskListData.tasks[openTaskType as OpenTaskType];
            if (!tasksMap) {
                // Task map was not found for open task type
                return;
            }

            // Sort tasks by queue position
            const tasks = Object.values(tasksMap).sort(openTaskQueuePositionSortComparator);

            // Create new task map
            const newTaskMap: { [taskId in ID]: TaskResource } = {};
            tasks.forEach((task) => {
                newTaskMap[task.id] = task;
            });

            // Insert new task map
            openTaskListData.tasks[openTaskType as OpenTaskType] = newTaskMap;
        }

        // Initialize users
        payload.openTaskList.users.forEach((user) => {
            openTaskListData.users[user.id] = user;
        });

        // Initialize participant users
        payload.openTaskList.participant_users.forEach((participantUser) => {
            openTaskListData.participant_users[participantUser.id] = participantUser;
        });

        // Initialize plans
        payload.openTaskList.plans.forEach((plan) => {
            openTaskListData.plans[plan.id] = plan;
        });

        // Initialize teams
        payload.openTaskList.teams.forEach((team) => {
            openTaskListData.teams[team.id] = team;
        });

        return openTaskListData;
    },
});
