import styled from 'styled-components';
import backgroundImg from '@app/assets/login-background.png';

export const Wrapper = styled.div`
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-image: url(${backgroundImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;
