import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import {
    CreateTaskCategoryGroupInput,
    UpdateTaskCategoryGroupInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/inputs';
import { TaskCategoryGroupRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/refs';

// Fetch task category groups
export const fetchTaskCategoryGroups = domain.createEffect<
    void,
    CollectionResource<TaskCategoryGroupListItemResource, {}>
>({
    name: 'fetchTaskCategoryGroups',
    handler: async () => {
        return await repository.taskCategoryGroup().get();
    },
});

// Create task category group
export const createTaskCategoryGroup = domain.createEffect<CreateTaskCategoryGroupInput, TaskCategoryGroupRef>({
    name: 'createTaskCategoryGroup',
    handler: async (input) => {
        return await repository.taskCategoryGroup().create(input);
    },
});

// Update task category group
export const updateTaskCategoryGroup = domain.createEffect<
    { taskCategoryGroupId: ID; input: UpdateTaskCategoryGroupInput },
    TaskCategoryGroupRef
>({
    name: 'updateTaskCategoryGroup',
    handler: async ({ taskCategoryGroupId, input }) => {
        return await repository.taskCategoryGroup().update(taskCategoryGroupId, input);
    },
});

// Remove task category group
export const removeTaskCategoryGroup = domain.createEffect<ID, TaskCategoryGroupRef>({
    name: 'removeTaskCategoryGroup',
    handler: async (taskCategoryGroupId) => {
        return await repository.taskCategoryGroup().archive(taskCategoryGroupId);
    },
});

// Restore task category group
export const restoreTaskCategoryGroup = domain.createEffect<ID, TaskCategoryGroupRef>({
    name: 'restoreTaskCategoryGroup',
    handler: async (taskCategoryGroupId) => {
        return await repository.taskCategoryGroup().restore(taskCategoryGroupId);
    },
});
