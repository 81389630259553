import { FileInformation } from '@app/hooks/useUpload/types';
import { FileResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { FileUtils } from 'dy-frontend-shared/lib/utils';

export default class FileInformationToTaskFileResourceMapper {
    public static transform(fileInformation: FileInformation): FileResource | null {
        const file = fileInformation.file;
        const resource = fileInformation.resource;

        if (!resource) {
            return null;
        }

        return {
            id: resource.id,
            url: resource.url,
            preview_url: resource.preview_url,
            extension: FileUtils.getFileExtension(file.name),
            mime: file.type,
            original_name: file.name,
            size: file.size,
        };
    }
}
