import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import {
    checkIfSubscriptionTerminated,
    checkIfSubscriptionPausedExternally,
    checkIfSubscriptionUpdateScheduled,
} from '../';

/**
 * Check if pause subscription action allowed
 * @param subscription subscription to use in "pause subscription action allowed" check
 * @returns {boolean} "pause subscription action allowed" flag
 */
export default function checkIfPauseSubscriptionActionAllowed(subscription: SubscriptionResource) {
    if (subscription.mode === SubscriptionMode.MANUAL) {
        // Subscription mode is MANUAL
        return false;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription is terminated
        return false;
    }

    if (checkIfSubscriptionPausedExternally(subscription)) {
        // Subscription is paused externally
        return false;
    }

    if (checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription update is scheduled
        return false;
    }

    return true;
}
