import { Colors, Tooltip } from '@blueprintjs/core';
import Box, { Props as BoxProps } from '@components/Box';
import Circle from '@components/Circle';
import FilePreview from '@components/FilePreview';
import FileUploadProgress from '@components/FileUploadProgress';
import Flex from '@components/Flex';
import React, { ReactNode } from 'react';
import DevText from '@components/Text';
import { FileUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';

export interface FileLineTilePreviewProps {
    fileName: string;
    fileSize: number;
    extension: string;
    loading?: boolean;
    progress?: number;
    src?: string | null;
    alt?: string;
    actions?: ReactNode;
}

export type Props = FileLineTilePreviewProps & BoxProps;

const FileTilePreview: React.FC<Props> = ({
    fileName,
    fileSize,
    extension,
    loading = false,
    progress = 0,
    src,
    alt,
    actions,
    ...props
}) => {
    const renderPreviewBlockContent = () => {
        if (loading) {
            return <FileUploadProgress width="20px" height="20px" progress={progress} />;
        } else {
            return (
                <FilePreview
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    width="100%"
                    height="100%"
                    minWidth="100%"
                    minHeight="100%"
                    objectFit="cover"
                    extension={extension}
                    src={src}
                    alt={alt}
                />
            );
        }
    };

    const renderFileSize = () => {
        return (
            <DevText inline muted>
                {FileUtils.getFormattedFileSize(fileSize)}
            </DevText>
        );
    };

    const renderExtension = () => {
        return (
            <DevText inline muted>
                {extension.toUpperCase()}
            </DevText>
        );
    };

    return (
        <Box borderRadius="8px" backgroundColor={Colors.DARK_GRAY1} {...props}>
            <Flex align="center">
                <Box width="64px" height="64px" minWidth="64px" minHeight="64px">
                    <Flex fullHeight align="center" justify="center">
                        {renderPreviewBlockContent()}
                    </Flex>
                </Box>

                <Box width="100%" padding="8px 16px">
                    <Flex align="center" justify="space-between">
                        <Flex className="mr-2" direction="column">
                            {/* File name */}
                            <Tooltip content={fileName}>
                                <DevText>{TextFormatUtils.truncate(fileName, 30)}</DevText>
                            </Tooltip>

                            {/* File size*/}
                            <Flex align="center">
                                {renderFileSize()}
                                <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                                {renderExtension()}
                            </Flex>
                        </Flex>

                        {actions && <Flex align="center">{actions}</Flex>}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default FileTilePreview;
