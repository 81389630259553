import UserSettings from '@app/containers/components/UserSettings';
import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { coreUserApi } from '../../store/apis';
import { $coreUser } from '../../store/states';

const CoreUserSettings: React.FC = () => {
    const { changeTitle } = usePageTitle('Member Settings');

    const coreUser = useStore($coreUser);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Member Settings - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    if (!coreUser) {
        return null;
    }

    return (
        <UserSettings
            userId={coreUser.id}
            onChangeEmailSuccess={(email) => coreUserApi.update({ email })}
            onVerifyUserSuccess={coreUserApi.verify}
            onRemoveUserVerificationSuccess={coreUserApi.removeVerification}
        />
    );
};

export default CoreUserSettings;
