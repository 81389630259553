import InputGroupSpinnerElement from '@app/components/InputGroupSpinnerElement';
import OccupancyRate from '@app/containers/components/OccupancyRate';
import { useDebouncedState } from '@app/hooks';
import { Button, Card, Icon, InputGroup, Intent, Spinner, Tag } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Overlay from '@components/Overlay';
import Pagination from '@components/Pagination';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { imageHashPreview, userRoleInformation } from '@data/consts';
import { ModalProps } from '@modals/types';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { PickableTeamParticipantUserResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useAssignTeamParticipants } from '../../hooks';
import { fetchPickableTeamParticipantUsers, fetchUserOccupancy } from '../../store/effects';
import { resetTeamPickableParticipantUsers } from '../../store/events';
import { $team, $teamPickableParticipantUsers } from '../../store/states';
import { amountOfParticipantsOnPage } from './consts';

type Props = ModalProps;

const AddTeamParticipantUsersModal: React.FC<Props> = ({ closeModal }) => {
    const { assignTeamParticipants } = useAssignTeamParticipants();

    const team = useStore($team);
    const teamPickableParticipantUsers = useStore($teamPickableParticipantUsers);
    const isFetchingPickableTeamParticipantUsers = useStore(fetchPickableTeamParticipantUsers.pending);

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebouncedState<string>(query, 1000);
    const [selectedUsers, setSelectedUsers] = useState<PickableTeamParticipantUserResource[]>([]);
    const [userOccupancyByUserId, setUserOccupancyByUserId] = useState<
        { [userId in ID]: UserOccupancyResource } | null
    >(null);

    useEffect(() => {
        if (!teamPickableParticipantUsers || !userOccupancyByUserId) {
            return;
        }

        if (page === 1) {
            handleLoadPage(page);
        } else {
            setPage(1);
        }
    }, [debouncedQuery]);

    useEffect(() => {
        handleLoadPage(page);
    }, [page]);

    useEffect(() => {
        return () => {
            resetTeamPickableParticipantUsers();
        };
    }, []);

    if (!team) {
        closeModal?.();
        return null;
    }

    const handleLoadPage = async (newPage: number) => {
        const pageOffset = (newPage - 1) * amountOfParticipantsOnPage;

        try {
            const pickableTeamParticipantUser = await fetchPickableTeamParticipantUsers({
                pagination: {
                    _pagination: { limit: amountOfParticipantsOnPage, offset: pageOffset },
                },
                filter: {
                    team_id: team.id,
                    query: debouncedQuery,
                },
            });

            // Get user occupancy for fetched team participant users
            const isParticipantUsersPresent = pickableTeamParticipantUser.items.length > 0;
            if (isParticipantUsersPresent) {
                let map: { [userId in ID]: UserOccupancyResource } = {};
                const userOccupancy = await fetchUserOccupancy({
                    user_id: pickableTeamParticipantUser.items.map((user) => user.id),
                });

                for (let i = 0; i < userOccupancy.items.length; i++) {
                    const occupancy = userOccupancy.items[i];
                    map[occupancy.user_id] = occupancy;
                }

                setUserOccupancyByUserId(map);
            }
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const addUserToSelected = (user: PickableTeamParticipantUserResource) => {
        setSelectedUsers((selected) => [...selected, user]);
    };

    const removeUserFromSelected = (user: PickableTeamParticipantUserResource) => {
        setSelectedUsers((selected) => selected.filter((x) => x.id !== user.id));
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Skip if selected users are empty for some reason
        if (!team || selectedUsers.length === 0) {
            closeModal?.();
            return;
        }

        try {
            await assignTeamParticipants({
                teamId: team.id,
                selectedUsers,
                role: TeamParticipationRole.MEMBER,
            });

            closeModal?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderHeader = () => {
        return (
            <>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h3">Select users to add</Heading>

                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <InputGroup
                    large
                    leftIcon="search"
                    placeholder="Search for users..."
                    value={query}
                    leftElement={
                        <InputGroupSpinnerElement
                            loading={isFetchingPickableTeamParticipantUsers && query.trim().length !== 0}
                        />
                    }
                    onChange={(e) => setQuery(e.target.value)}
                />
            </>
        );
    };

    // Selected users
    const renderSelectedUsers = () => {
        if (selectedUsers.length === 0) {
            return null;
        }

        const renderAsTagList = () =>
            selectedUsers.map((user) => (
                <Tag
                    key={`selected-user-${user.id}`}
                    className="mr-1 mb-1"
                    onRemove={() => removeUserFromSelected(user)}
                >
                    {user.first_name} {user.last_name}
                </Tag>
            ));

        return <div className="mb-1">{renderAsTagList()}</div>;
    };

    const renderPickableUsersTable = () => {
        if (!teamPickableParticipantUsers || !userOccupancyByUserId) {
            // Fetching user to attach to team & user occupancy
            return <Spinner />;
        }

        if (teamPickableParticipantUsers.items.length === 0) {
            // No users to attach to team
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No users can be added to this team
                        </Heading>
                    }
                />
            );
        }

        // Header
        const renderTableHeader = () => {
            return (
                <thead>
                <tr>
                    <th>User</th>
                    <th>Occupancy</th>
                    <th style={{ width: '25%' }}>Action</th>
                </tr>
                </thead>
            );
        };

        // Data table
        const renderTableBody = () => {
            const renderRow = (user: PickableTeamParticipantUserResource) => {
                // Returns flag is user is selected for assignment
                // TODO: Optimize
                const isSelected = (userId: ID) => {
                    return selectedUsers.map((x) => x.id).includes(userId);
                };

                // Returns flag if user is already assigned
                // TODO: Optimize
                const isMember = (userId: ID) => {
                    return team?.participants.map((x) => x.user.id).includes(userId);
                };

                const renderActions = () => {
                    // Already a member
                    if (isMember(user.id)) {
                        return (
                            <Button disabled intent={Intent.SUCCESS} outlined icon="endorsed">
                                Member
                            </Button>
                        );
                    }

                    // Is selected
                    return !isSelected(user.id) ? (
                        <Button intent={Intent.PRIMARY} outlined icon="add" onClick={() => addUserToSelected(user)}>
                            Select
                        </Button>
                    ) : (
                        <Button
                            intent={Intent.DANGER}
                            outlined
                            icon="remove"
                            onClick={() => removeUserFromSelected(user)}
                        >
                            Remove
                        </Button>
                    );
                };

                const renderUserOccupancyInformation = () => {
                    const userOccupancy = userOccupancyByUserId[user.id];
                    if (!userOccupancy) {
                        return <DevText muted>No data</DevText>;
                    }

                    return <OccupancyRate score={userOccupancy.score} />;
                };

                // Get avatar src
                let avatarSrc: string | null = null;
                if (user.image_hash) {
                    avatarSrc = imageHashPreview.userImage(user.image_hash, ImageHashPreviewSize.SM);
                }

                let description = userRoleInformation[user.role].label ?? 'Can be assigned';
                if (user.company_position.length > 0) {
                    description += `, ${user.company_position}`;
                }

                return (
                    <tr key={`pickable-user-${user.id}`}>
                        <TableCell verticalAlign="middle">
                            <Flex align="center">
                                <Avatar
                                    className="mr-1"
                                    width="42px"
                                    height="42px"
                                    alt={`${user.first_name} ${user.last_name}`}
                                    src={avatarSrc}
                                />
                                <Flex direction="column">
                                    <Flex align="center">
                                        <DevText>
                                            {user.first_name} {user.last_name}
                                        </DevText>
                                    </Flex>

                                    <DevText muted>
                                        {description}
                                    </DevText>
                                </Flex>
                            </Flex>
                        </TableCell>
                        <TableCell verticalAlign="middle">{renderUserOccupancyInformation()}</TableCell>
                        <TableCell verticalAlign="middle">{renderActions()}</TableCell>
                    </tr>
                );
            };

            return <tbody>{teamPickableParticipantUsers?.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingPickableTeamParticipantUsers} className="mb-1">
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        if (!userOccupancyByUserId) {
            // User occupancy by user ID is not initialized yet
            return null;
        }

        if (teamPickableParticipantUsers === null || teamPickableParticipantUsers.paginator === null) {
            // Skip if not loaded or no pagination is supported
            return null;
        }

        if (!teamPickableParticipantUsers.paginator.has_more && teamPickableParticipantUsers.paginator.offset === 0) {
            // Only 1 page exists
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingPickableTeamParticipantUsers}
                    hasMore={teamPickableParticipantUsers.paginator.has_more}
                    className="mb-2"
                    page={page}
                    amountOfItemsOnPage={amountOfParticipantsOnPage}
                    totalItems={teamPickableParticipantUsers.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '800px' }}>
                {/* Header */}
                <div className="mb-2">{renderHeader()}</div>

                {/* Selected users */}
                {renderSelectedUsers()}

                {/* Pickable users table */}
                {renderPickableUsersTable()}

                {/* Render pagination */}
                {renderPagination()}

                <form onSubmit={handleFormSubmit}>
                    <Flex justify="flex-end">
                        <Button className="mr-1" minimal onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={selectedUsers.length === 0}
                            type="submit"
                            icon="add"
                            intent={selectedUsers.length !== 0 ? Intent.PRIMARY : Intent.NONE}
                        >
                            {selectedUsers.length !== 0 ? 'Add selected' : 'Select users to add'}
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default AddTeamParticipantUsersModal;
