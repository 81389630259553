import domain from "./domain";
import { resetWorkspaceBilling } from "./events";
import { PaymentAccountResource } from "dy-frontend-http-repository/lib/modules/PaymentAccount/resources";
import { PaymentMethodListItemResource } from "dy-frontend-http-repository/lib/modules/PaymentMethod/resources";
import { CollectionResource } from "dy-frontend-http-repository/lib/data/resources";
import { SubscriptionListItemResource } from "dy-frontend-http-repository/lib/modules/Subscription/resources";
import { TaxIdResource } from "dy-frontend-http-repository/lib/modules/TaxId/resources";

// Payment account
export const $workspacePaymentAccount = domain
    .createStore<PaymentAccountResource | null>(null)
    .reset(resetWorkspaceBilling);

// Payment method list
export const $workspacePaymentMethods = domain
    .createStore<CollectionResource<PaymentMethodListItemResource, {}> | null>(null)
    .reset(resetWorkspaceBilling);

// Subscription list
export const $workspaceSubscriptions = domain
    .createStore<CollectionResource<SubscriptionListItemResource, {}> | null>(null)
    .reset(resetWorkspaceBilling);

// Tax ID list
export const $workspaceTaxIds = domain
    .createStore<CollectionResource<TaxIdResource, {}> | null>(null)
    .reset(resetWorkspaceBilling);
