import { repository } from 'dy-frontend-http-repository/lib/modules';
import { VirtualLicenceTaskCategoryGroupAttachmentInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { VirtualLicenceRef } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/refs';
import domain from './domain';

// Applies virtual licence task category group attachment list
export const updateVirtualLicenceTaskCategoryGroupAttachmentList = domain.createEffect<
    { virtualLicenceId: ID; input: VirtualLicenceTaskCategoryGroupAttachmentInput[] },
    VirtualLicenceRef
>({
    name: 'updateVirtualLicenceTaskCategoryGroupAttachmentList',
    handler: async ({ virtualLicenceId, input }) => {
        return await repository.virtualLicence().setTaskCategoryGroupAttachmentList(virtualLicenceId, input);
    },
});
