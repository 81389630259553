import React from 'react';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { useStore } from 'effector-react';
import { $task } from '@app/containers/pages/Task/store/states';
import { Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import TeamTag from '@app/containers/components/TeamTag';

const Teams: React.FC = () => {
    const task = useStore($task);

    if (!task) {
        return null;
    }

    const renderNoTeamsAttachedTitle = () => {
        return <DevText muted>No teams attached yet</DevText>;
    };

    const renderTeamList = () => {
        if (task.participants.length === 0) {
            // No participants attached to task, so there are no teams which can be shown
            return renderNoTeamsAttachedTitle();
        }

        // TODO: put it to memo with participants dependency
        const teamsMap: { [teamId in ID]: TeamResource } = {};
        task.participants.forEach((participant) => {
            const participantTeamParticipation = participant.user.team_participation;
            if (participantTeamParticipation.length > 0) {
                participantTeamParticipation.forEach((teamParticipation) => {
                    teamsMap[teamParticipation.team.id] = teamParticipation.team;
                });
            }
        });

        if (Object.values(teamsMap).length === 0) {
            return renderNoTeamsAttachedTitle();
        }

        return Object.values(teamsMap).map((team) => (
            <TeamTag className="mt-2" key={team.id} id={team.id} color={team.color} name={team.title} />
        ));
    };

    return (
        <Card>
            <Heading className="mb-2" type="h4">
                Teams
            </Heading>

            {renderTeamList()}
        </Card>
    );
};

export default Teams;
