import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';

// Elevate task priority
export const elevateTaskPriority = domain.createEffect<ID, TaskRef>({
    name: 'elevateTaskPriority',
    handler: async (taskId) => {
        return await repository.task().elevatePriority(taskId);
    },
});

// Remove task priority elevation
export const removeTaskPriorityElevation = domain.createEffect<ID, TaskRef>({
    name: 'removeTaskPriorityElevation',
    handler: async (taskId) => {
        return await repository.task().removePriorityElevation(taskId);
    },
});
