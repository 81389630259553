import React, { HTMLAttributes } from 'react';
import AvatarBlock from './components/AvatarBlock';
import Information from './components/Information';
import SocialMedia from './components/SocialMedia';

type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    return (
        <div {...props}>
            <AvatarBlock className="mb-2" />
            <Information className="mb-2" />
            <SocialMedia />
        </div>
    );
};

export default Aside;
