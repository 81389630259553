import moment from 'moment';
import { useStore } from 'effector-react';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { CreateTaskDeliverablesInput } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { $authorizedUser } from '@containers/store/states';
import { createTaskDeliverablesBatch } from '../../store/effects';
import { taskDeliverablesTreeApi } from '../../store/apis';

const useCreateEmbedTaskDeliverable = () => {
    const authorizedUser = useStore($authorizedUser);

    const handleCreateEmbedTaskDeliverable = async ({
        taskId,
        path,
        title,
        url,
    }: {
        taskId: ID;
        path: string;
        title: string;
        url: string;
    }) => {
        if (!authorizedUser) {
            // TODO: handle error
            return;
        }

        // Create input
        const createTaskDeliverablesBatchInputItem: CreateTaskDeliverablesInput['items'][number] = {
            path,
            type: TaskDeliverableType.EMBED,
            title,
            url,
        };

        try {
            const createTaskDeliverablesBatchResponse = await createTaskDeliverablesBatch({
                task_id: taskId,
                items: [createTaskDeliverablesBatchInputItem],
            });

            // Create task deliverable
            const momentDateNowFormatted = moment().utc().format();
            const taskDeliverable: TaskDeliverableResource = {
                id: createTaskDeliverablesBatchResponse.ids[0],
                task_id: taskId,
                approved_at: null,
                archived_at: null,
                replaced_at: null,
                parent_task_deliverable: null,
                created_at: momentDateNowFormatted,
                title: createTaskDeliverablesBatchInputItem.title!,
                path: createTaskDeliverablesBatchInputItem.path,
                type: createTaskDeliverablesBatchInputItem.type,
                text_content: '',
                url: createTaskDeliverablesBatchInputItem.url!,
                file: null,
                user: {
                    id: authorizedUser.user.id,
                    first_name: authorizedUser.user.first_name,
                    last_name: authorizedUser.user.last_name,
                    image_hash: authorizedUser.user.image_hash
                },
            };

            // Insert task deliverables data
            taskDeliverablesTreeApi.insert({ taskDeliverables: [taskDeliverable] });
        } catch (e) {
            throw e;
        }
    };

    return { createEmbedTaskDeliverable: handleCreateEmbedTaskDeliverable };
};

export default useCreateEmbedTaskDeliverable;
