import { fetchCoreUser, fetchCoreUserOccupancy } from './effects';
import { $coreUser, $coreUserOccupancy } from './states';

import '../pages/CoreUserTaskFeedback/store/init';

// Fetch core user
$coreUser.on(fetchCoreUser.doneData, (_, data) => data);

// Fetch core user
$coreUserOccupancy.on(fetchCoreUserOccupancy.doneData, (_, data) => data);
