import { Spinner, Icon } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';
import Box from '../Box';
import Flex from '../Flex';

// This component is used for Inputs to show "search" icon whenever not loading and spinner whenever is loading, can be inserted into leftElement prop of the Input

export interface InputGroupSpinnerElementProps {
    // Is input group fetching flag
    loading?: boolean;
}

type Props = HTMLAttributes<HTMLElement> & InputGroupSpinnerElementProps;

const InputGroupSpinnerElement: React.FC<Props> = ({ loading }) => {
    if (loading) {
        // TODO: width and height is specified for InputGroup with prop "large=true", need to do one for "large=false"
        return (
            <Box width="40px" height="41px">
                <Flex fullWidth fullHeight align="center" justify="center">
                    <Spinner size={16} />
                </Flex>
            </Box>
        );
    } else {
        return <Icon icon="search" size={16} />;
    }
};

export default InputGroupSpinnerElement;
