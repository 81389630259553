import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;

    width: 100%;
`;

export const Content = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    width: 100%;
    height: 100vh;
    padding-bottom: 30px;
    overflow-y: scroll;
    background: ${Colors.DARK_GRAY2};
`;
