import { SubscriptionState } from 'dy-frontend-http-repository/lib/data/enums';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription terminated
 * @param subscription subscription which should be checked for termination
 * @returns {boolean} subscription termination flag
 */
export default function checkIfSubscriptionTerminated(subscription: SubscriptionResource) {
    const terminatedStates = [SubscriptionState.CANCELED, SubscriptionState.INCOMPLETE_EXPIRED];
    return terminatedStates.includes(subscription.state);
}
