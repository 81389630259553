import React from 'react';
import { CardCvcElement, CardCvcElementProps } from '@stripe/react-stripe-js';
import { StripeCardCvcElementOptions } from '@stripe/stripe-js';
import { Colors } from '@blueprintjs/core';

export type Props = CardCvcElementProps;

const CardCvcInput: React.FC<Props> = ({ options, ...props }) => {
    const defaultOptions: StripeCardCvcElementOptions = {
        style: {
            base: {
                color: Colors.WHITE,
                '::placeholder': {
                    color: Colors.GRAY2,
                },
            },
            invalid: {
                color: Colors.RED2,
            },
        },
    };

    return <CardCvcElement options={{ ...defaultOptions, ...options }} {...props} />;
};

export default CardCvcInput;
