import { BreadcrumbProps } from '@blueprintjs/core';
import domain from './domain';

// Toggle dev mode
export const toggleDevModeFlag = domain.createEvent('toggleDevModeFlag');

// Set page breadcrumbs
export const setPageBreadcrumbs = domain.createEvent<BreadcrumbProps[] | null>('setPageBreadcrumbs');

// Reset authorized flag
export const resetIsAuthorized = domain.createEvent('resetIsAuthorized');

// Reset workspace init data
export const resetWorkspaceInitData = domain.createEvent('resetWorkspaceInitData');

// Reset authorized user
export const resetAuthorizedUser = domain.createEvent('resetAuthorizedUser');

// Reset page breadcrumbs
export const resetPageBreadcrumbs = domain.createEvent('resetPageBreadcrumbs');

// Reset permissions
export const resetPermissions = domain.createEvent('resetPermissions');
