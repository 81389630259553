import { taskDeliverablesTreeApi } from '../../store/apis';

const useCreateDirectory = () => {
    const handleCreateDirectory = ({
        directoryPath,
        directoryName,
    }: {
        directoryPath: string;
        directoryName: string;
    }) => {
        taskDeliverablesTreeApi.createDirectory({ directoryPath, directoryName });
    };

    return { createDirectory: handleCreateDirectory };
};

export default useCreateDirectory;
