import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { checkIfSubscriptionTerminated } from '../';

/**
 * Check if set subscription payment method action allowed
 * @param subscription subscription to use in "set subscription payment method action allowed" check
 * @returns {boolean} "set subscription payment method action allowed" flag
 */
export default function checkIfSetSubscriptionPaymentMethodActionAllowed(subscription: SubscriptionResource) {
    if (subscription.mode === SubscriptionMode.MANUAL) {
        // Subscription mode is MANUAL
        return false;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription is terminated
        return false;
    }

    return true;
}
