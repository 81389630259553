import React from 'react';
import { Card, Colors } from '@blueprintjs/core';
import DevText from '@app/components/Text';
import RouterLink from '@app/components/RouterLink';
import { Endpoints } from '@app/data/consts';
import {
    NotificationResource,
    TaskMessageResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import RichText from '@app/components/RichText';
import { UserUtils } from '@containers/components/Notifications/utils';
import NotificationTemplate from '../../../NotificationTemplate';
export interface TaskMessageCreatedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    taskMessage: TaskMessageResource | null;
}

export type Props = TaskMessageCreatedNotificationProps;

const TaskMessageCreatedNotification: React.FC<Props> = ({ authorizedUserId, notification, taskMessage }) => {
    // Get message type label
    let messageTypeLabel = 'unknown message type';
    if (taskMessage) {
        if (taskMessage.is_internal) {
            messageTypeLabel = 'private note message';
        } else {
            messageTypeLabel = 'message';
        }
    }

    const renderContent = () => {
        if (!taskMessage) {
            // This is bad, task message is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Message was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} added{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_MESSAGES.replace(':taskId', taskMessage.task.id)}
                    >
                        {messageTypeLabel}
                    </RouterLink>{' '}
                    to request{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', taskMessage.task.id)}
                    >
                        {taskMessage.task.title}
                    </RouterLink>
                </DevText>

                <Card compact>
                    <RichText value={taskMessage.content} />
                </Card>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title={`New ${messageTypeLabel}`}
            icon="chat"
            at={notification.activity.at}
            content={renderContent()}
        />
    );
};

export default TaskMessageCreatedNotification;
