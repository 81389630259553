import moment from 'moment';
import { createApi } from 'effector';
import { CreateTeamInput } from 'dy-frontend-http-repository/lib/modules/Team/inputs';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { $teams } from './states';

export const teamsApi = createApi($teams, {
    push: (store, payload: { id: ID; input: CreateTeamInput }) => {
        const momentNowUtc = moment().utc().format();

        const team: TeamResource = {
            id: payload.id,
            title: payload.input.title,
            color: payload.input.color ?? '',
            country: payload.input.country ?? '',
            summary: payload.input.summary ?? '',
            timezone_name: payload.input.timezone_name ?? '',
            working_hours_start: payload.input.working_hours_start ?? '',
            working_hours_end: payload.input.working_hours_end ?? '',
            participants: [],
            archived_at: null,
            created_at: momentNowUtc,
        };

        if (!store) {
            return {
                cursor: null,
                paginator: null,
                sort: null, // TODO: what if sorting exist how do we approach it, and it's not question for team, but for other places where this could happen
                items: [team],
            };
        }

        return {
            ...store,
            items: [team, ...store.items],
        };
    },
});
