import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringShouldBeEqualTo = '';
const getDefaultStringShouldBeEqualToStringMessage = (stringShouldBeEqualTo: string) =>
    `String should be equal to ${stringShouldBeEqualTo}`;

const getStringEqualToStringValidator: GetValidatorFunction<
    {
        stringShouldBeEqualTo: string;
        stringShouldBeEqualToStringMessageFormatter?: (stringShouldBeEqualTo: string) => string;
    },
    string
> = (
    options = {
        stringShouldBeEqualTo: defaultStringShouldBeEqualTo,
        stringShouldBeEqualToStringMessageFormatter: getDefaultStringShouldBeEqualToStringMessage,
    }
) => {
    const { stringShouldBeEqualTo, stringShouldBeEqualToStringMessageFormatter } = options;

    return async (value) => {
        if (value === stringShouldBeEqualTo) {
            // String is NOT empty
            return null;
        } else {
            // String is empty
            if (stringShouldBeEqualToStringMessageFormatter) {
                return stringShouldBeEqualToStringMessageFormatter(stringShouldBeEqualTo);
            } else {
                return getDefaultStringShouldBeEqualToStringMessage(stringShouldBeEqualTo);
            }
        }
    };
};

export default getStringEqualToStringValidator;
