import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    ProcessLatestSubscriptionInvoiceInput,
    UpdateSubscriptionItemsBatchInput,
} from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';

// Get list of subscription plans
export const fetchSubscriptionPlans = domain.createEffect<PlanType, CollectionResource<PlanResource, {}>>({
    name: 'fetchSubscriptionPlans',
    handler: async (planType) => {
        return await repository.subscriptionPlan().get(planType);
    },
});

// Update subscription items
export const updateSubscriptionItems = domain.createEffect<
    { subscriptionId: ID; input: UpdateSubscriptionItemsBatchInput },
    SubscriptionRef
>({
    name: 'updateSubscriptionItems',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().updateSubscriptionItemsBatch(subscriptionId, input);
    },
});

// Process latest subscription invoice
export const processLatestSubscription = domain.createEffect<
    { subscriptionId: ID; input: ProcessLatestSubscriptionInvoiceInput },
    SubscriptionRef
>({
    name: 'processLatestSubscription',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().processLatestInvoice(subscriptionId, input);
    },
});
