import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { PlatformTaskFilterType } from '../../enums';
import { PlatformTaskFilterData, TaskFilterQueryParameters } from '../../types';
import { platformFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';

export default class PlatformTaskFilter {
    static convertHydratedQueryParametersToPlatformTaskFilterData(
        hydratedQueryParameters: HydratedQueryParameters
    ): PlatformTaskFilterData {
        // Create empty platform task filter data object
        const platformTaskFilterData: PlatformTaskFilterData = {
            query: '',
            clients: [],
            participants: [],
            publishTypes: [],
            queues: [],
            states: [],
            taskCategories: [],
            workspaces: [],
        };

        if (hydratedQueryParameters.query) {
            // Set query
            platformTaskFilterData.query = hydratedQueryParameters.query;
        }

        /* Static filters */

        if (hydratedQueryParameters.publishTypes) {
            // Set publish types
            platformTaskFilterData.publishTypes = hydratedQueryParameters.publishTypes;
        }

        if (hydratedQueryParameters.queues) {
            // Set queues
            platformTaskFilterData.queues = hydratedQueryParameters.queues;
        }

        if (hydratedQueryParameters.states) {
            // Set states
            platformTaskFilterData.states = hydratedQueryParameters.states;
        }

        /* Dynamic filters */

        if (hydratedQueryParameters.client_user) {
            // Set client user
            platformTaskFilterData.clients = hydratedQueryParameters.client_user;
        }

        if (hydratedQueryParameters.core_user) {
            // Set participants
            platformTaskFilterData.participants = hydratedQueryParameters.core_user;
        }

        if (hydratedQueryParameters.workspace) {
            // Set workspaces
            platformTaskFilterData.workspaces = hydratedQueryParameters.workspace;
        }

        if (hydratedQueryParameters.task_category) {
            // Set task categories
            platformTaskFilterData.taskCategories = hydratedQueryParameters.task_category;
        }

        return platformTaskFilterData;
    }

    static convertPlatformTaskFilterDataToTaskFilterInput(
        platformTaskFilterData: PlatformTaskFilterData
    ): TaskFilterInput {
        const taskFilterInput: TaskFilterInput = {};

        if (platformTaskFilterData.query) {
            // Set query
            taskFilterInput.query = platformTaskFilterData.query;
        }

        /* Static filters */

        if (platformTaskFilterData.publishTypes.length > 0) {
            // Set publish types
            taskFilterInput.publish_type = platformTaskFilterData.publishTypes;
        }

        if (platformTaskFilterData.queues.length > 0) {
            // Set queues
            taskFilterInput.queue = platformTaskFilterData.queues;
        }

        if (platformTaskFilterData.states.length > 0) {
            // Set states
            taskFilterInput.state = platformTaskFilterData.states;
        }

        /* Dynamic filters */

        if (platformTaskFilterData.clients.length > 0) {
            // Set client user
            taskFilterInput.owner_user_id = platformTaskFilterData.clients.map((client) => client.id);
        }

        if (platformTaskFilterData.participants.length > 0) {
            // Set participants
            taskFilterInput.participant_user_id = platformTaskFilterData.participants.map(
                (participant) => participant.id
            );
        }

        if (platformTaskFilterData.workspaces.length > 0) {
            // Set workspaces
            taskFilterInput.workspace_id = platformTaskFilterData.workspaces.map((workspace) => workspace.id);
        }

        if (platformTaskFilterData.taskCategories.length > 0) {
            // Set task categories
            taskFilterInput.task_category_id = platformTaskFilterData.taskCategories.map(
                (taskCategory) => taskCategory.id
            );
        }

        return taskFilterInput;
    }

    static convertTaskFilterInputToTaskFilterQueryParameters = (
        page: number,
        taskFilterInput: TaskFilterInput
    ): TaskFilterQueryParameters => {
        const taskFilterQueryParameters: TaskFilterQueryParameters = {
            query: '',
            page: '1',
            clientsIds: [],
            participantsIds: [],
            taskCategoriesIds: [],
            workspacesIds: [],
            publishTypes: [],
            queues: [],
            states: [],
        };

        // Set page
        taskFilterQueryParameters.page = `${page}`;

        // TODO: Uncomment if query need to be saved
        // // Set query
        // if (taskFilterInput.query) {
        //     taskFilterQueryParameters.query = taskFilterInput.query;
        // }

        // Set client IDs
        if (taskFilterInput.owner_user_id && taskFilterInput.owner_user_id.length > 0) {
            taskFilterQueryParameters.clientsIds = taskFilterInput.owner_user_id;
        }

        // Set participants IDs
        if (taskFilterInput.participant_user_id && taskFilterInput.participant_user_id.length > 0) {
            taskFilterQueryParameters.participantsIds = taskFilterInput.participant_user_id;
        }

        // Set task category IDs
        if (taskFilterInput.task_category_id && taskFilterInput.task_category_id.length > 0) {
            taskFilterQueryParameters.taskCategoriesIds = taskFilterInput.task_category_id;
        }

        // Set workspace IDs
        if (taskFilterInput.workspace_id && taskFilterInput.workspace_id.length > 0) {
            taskFilterQueryParameters.workspacesIds = taskFilterInput.workspace_id;
        }

        // Set publish types
        if (taskFilterInput.publish_type && taskFilterInput.publish_type.length > 0) {
            taskFilterQueryParameters.publishTypes = taskFilterInput.publish_type as TaskPublishType[];
        }

        // Set queue
        if (taskFilterInput.queue && taskFilterInput.queue.length > 0) {
            taskFilterQueryParameters.queues = taskFilterInput.queue as TaskQueue[];
        }

        // Set states
        if (taskFilterInput.state && taskFilterInput.state.length > 0) {
            taskFilterQueryParameters.states = taskFilterInput.state as TaskState[];
        }

        return taskFilterQueryParameters;
    };

    static convertTaskFilterQueryParametersToHydrateQueryInput = (
        taskFilterQueryParameters: Partial<TaskFilterQueryParameters>
    ) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        // Set client user ids
        if (taskFilterQueryParameters.clientsIds && taskFilterQueryParameters.clientsIds.length > 0) {
            hydrateQueryInput.client_user_id = taskFilterQueryParameters.clientsIds;
        }

        // Set participants ids
        if (taskFilterQueryParameters.participantsIds && taskFilterQueryParameters.participantsIds.length > 0) {
            hydrateQueryInput.core_user_id = taskFilterQueryParameters.participantsIds;
        }

        // Set task categories
        if (taskFilterQueryParameters.taskCategoriesIds && taskFilterQueryParameters.taskCategoriesIds.length > 0) {
            hydrateQueryInput.task_category_id = taskFilterQueryParameters.taskCategoriesIds;
        }

        // Set client user ids
        if (taskFilterQueryParameters.workspacesIds && taskFilterQueryParameters.workspacesIds.length > 0) {
            hydrateQueryInput.workspace_id = taskFilterQueryParameters.workspacesIds;
        }

        return hydrateQueryInput;
    };

    static getAppliedPlatformFilters(platformTaskFilterData: PlatformTaskFilterData): PlatformTaskFilterType[] {
        const appliedPlatformFilters: PlatformTaskFilterType[] = [];

        const platformTaskFilterTypeValues = Object.values(PlatformTaskFilterType);

        for (let i = 0; i < platformTaskFilterTypeValues.length; i++) {
            const platformTaskFilterType = platformTaskFilterTypeValues[i];

            if (platformFiltersInformation[platformTaskFilterType].isPinned) {
                // Platform task filter is pinned by default
                appliedPlatformFilters.push(platformTaskFilterType);
                continue;
            }

            switch (platformTaskFilterType) {
                /* Static */
                case PlatformTaskFilterType.TASK_PUBLISH_TYPES:
                    if (platformTaskFilterData.publishTypes.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PUBLISH_TYPES);
                    }
                    break;
                case PlatformTaskFilterType.TASK_STATES:
                    if (platformTaskFilterData.states.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_STATES);
                    }
                    break;
                case PlatformTaskFilterType.TASK_QUEUES:
                    if (platformTaskFilterData.queues.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_QUEUES);
                    }
                    break;
                /* Dynamic */
                case PlatformTaskFilterType.CLIENT_USERS:
                    if (platformTaskFilterData.clients.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.CLIENT_USERS);
                    }
                    break;
                case PlatformTaskFilterType.TASK_CATEGORIES:
                    if (platformTaskFilterData.taskCategories.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_CATEGORIES);
                    }
                    break;
                case PlatformTaskFilterType.TASK_PARTICIPANTS:
                    if (platformTaskFilterData.participants.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PARTICIPANTS);
                    }
                    break;
                // case PlatformTaskFilterType.WORKSPACES:
                //     if (platformTaskFilterData.workspaces.length > 0) {
                //         appliedPlatformFilters.push(PlatformTaskFilterType.WORKSPACES);
                //     }
                //     break;
            }
        }

        return appliedPlatformFilters;
    }
}
