import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';

const taskStateInformation: {
    [taskState in TaskState]: {
        title: string;
        color: string;
    };
} = {
    [TaskState.DRAFT]: {
        title: 'Draft',
        color: '#919191',
    },
    [TaskState.PUBLISHED]: {
        title: 'To Design',
        color: '#b6894a',
    },
    // TODO: check which color should be here
    [TaskState.PICKUP_READY]: {
        title: 'Pickup Ready',
        color: '#594862',
    },
    [TaskState.IN_PROGRESS]: {
        title: 'Processing',
        color: '#008075',
    },
    [TaskState.INTERNAL_QA]: {
        title: 'QA',
        color: '#14CCBD',
    },
    [TaskState.CLIENT_REVIEW]: {
        title: 'Client Review',
        color: '#106BA3',
    },
    [TaskState.REVISION_REQUIRED]: {
        title: 'Revision Required',
        color: '#2B95D6',
    },
    [TaskState.IN_REVISION]: {
        title: 'In Revision',
        color: '#568d96',
    },
    [TaskState.PRE_DELIVERED]: {
        title: 'Pre-Delivered',
        color: '#87A629',
    },
    [TaskState.DELIVERED]: {
        title: 'Delivered',
        color: '#1D7324',
    },
};

export default taskStateInformation;
