import { Button } from '@blueprintjs/core';
import FixedHeightCollapse from '@components/FixedHeightCollapse';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Link from '@components/Link';
import richEditorPlugins from '@components/RichEditor/plugins';
import RichText from '@components/RichText';
import DevText from '@components/Text';
import { $task } from '@pages/Task/store/states';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';

export interface BriefProps {
    isEditAllowed?: boolean;
}

export type Props = BriefProps & HTMLAttributes<HTMLDivElement>;

const Brief: React.FC<Props> = ({ isEditAllowed = true, ...props }) => {
    const task = useStore($task);

    const [isTaskDescriptionCollapsed, setIsTaskDescriptionCollapsed] = useState(true);

    if (!task) {
        return null;
    }

    const renderDescription = () => {
        let descriptionContent;
        const formattedDescriptionValueObject = new RichTextFormat(task.description, richEditorPlugins);

        // Description was NOT provided
        if (formattedDescriptionValueObject.isEmpty()) {
            descriptionContent = <DevText muted>Not provided</DevText>;
        } else {
            descriptionContent = <RichText value={formattedDescriptionValueObject.getState()} />;
        }

        const renderCollapseControl = () => {
            let collapseButtonText = 'Click to view in full...';

            if (!isTaskDescriptionCollapsed) {
                // Not collapsed
                collapseButtonText = 'Click to hide description...';
            }

            return (
                <Button minimal onClick={() => setIsTaskDescriptionCollapsed((prev) => !prev)}>
                    {collapseButtonText}
                </Button>
            );
        };

        return (
            <div className="mb-4">
                <DevText muted className="mb-small">
                    Description
                </DevText>

                <FixedHeightCollapse
                    isCollapsed={isTaskDescriptionCollapsed}
                    className="mb-1"
                    maxHeight={305}
                    renderCollapseControl={renderCollapseControl}
                >
                    {descriptionContent}
                </FixedHeightCollapse>
            </div>
        );
    };

    const renderReferences = () => {
        let referencesContent;

        // Task references were NOT provided
        if (task.reference_links.length === 0) {
            referencesContent = <DevText muted>Not provided</DevText>;
        } else {
            referencesContent = (
                <Flex direction="column">
                    {task.reference_links.map((referenceLink) => (
                        <Link target="_blank" href={referenceLink}>
                            {referenceLink}
                        </Link>
                    ))}
                </Flex>
            );
        }

        return (
            <div>
                <DevText muted className="mb-small">
                    References
                </DevText>

                {referencesContent}
            </div>
        );
    };

    const renderExactTextCopy = () => {
        let exactTextCopyContent;

        // Task exact text copy is and empty string
        if (task.exact_text_copy.trim().length === 0) {
            exactTextCopyContent = <DevText muted>Not provided</DevText>;
        } else {
            // TODO: whiteSpace="pre-wrap"
            exactTextCopyContent = <DevText>{task.exact_text_copy}</DevText>;
        }

        return (
            <div>
                <DevText muted className="mb-small">
                    Exact text copy
                </DevText>

                {exactTextCopyContent}
            </div>
        );
    };

    return (
        <div {...props}>
            {renderDescription()}
            <Grid container>
                <Grid className="mb-2" lg={12}>
                    {renderExactTextCopy()}
                </Grid>
                <Grid lg={12}>{renderReferences()}</Grid>
            </Grid>
        </div>
    );
};

export default Brief;
