import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../store/states';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import Archived from '@pages/Task/components/TaskFlowControls/components/Archived';
import Draft from '@pages/Task/components/TaskFlowControls/components/Draft';
import Published from '@pages/Task/components/TaskFlowControls/components/Published';
import PickupReady from '@pages/Task/components/TaskFlowControls/components/PickupReady';
import InProgress from '@pages/Task/components/TaskFlowControls/components/InProgress';
import InternalQa from '@pages/Task/components/TaskFlowControls/components/InternalQa';
import InRevision from '@pages/Task/components/TaskFlowControls/components/InRevision';
import ClientReview from '@pages/Task/components/TaskFlowControls/components/ClientReview';
import RevisionRequired from '@pages/Task/components/TaskFlowControls/components/RevisionRequired';
import PreDelivered from '@pages/Task/components/TaskFlowControls/components/PreDelivered';
import Delivered from '@pages/Task/components/TaskFlowControls/components/Delivered';
import Paused from '@pages/Task/components/TaskFlowControls/components/Paused';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskFlowControls: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const [isStateTransitionAllowed, setIsStateTransitionAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission set
        if (permissions.isRoot.task) {
            setIsStateTransitionAllowed(true);
            return;
        }

        // Base permission
        if (permissions.has(TaskPermission.STATE_TRANSITION)) {
            setIsStateTransitionAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsStateTransitionAllowed(isAssigned && permissions.has(TaskPermission.STATE_TRANSITION_MOD_ASSIGNED));
        }

    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // Task is archived
    if (task.archived_at !== null) {
        return <Archived />;
    }

    // Task is paused
    if (task.paused_at !== null) {
        return <Paused />;
    }

    // Render based on state
    switch (task.state) {
        case TaskState.DRAFT:
            return <Draft />;
        case TaskState.PUBLISHED:
            return <Published isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.PICKUP_READY:
            return <PickupReady isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.IN_PROGRESS:
            return <InProgress isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.INTERNAL_QA:
            return <InternalQa isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.IN_REVISION:
            return <InRevision isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.CLIENT_REVIEW:
            return <ClientReview isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.REVISION_REQUIRED:
            return <RevisionRequired isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.PRE_DELIVERED:
            return <PreDelivered isStateTransitionAllowed={isStateTransitionAllowed} />;
        case TaskState.DELIVERED:
            return <Delivered />;
    }

    return null;
};

export default TaskFlowControls;
