import Circle from '@app/components/Circle';
import { $permissions } from '@app/containers/store/states';
import { Button, Card, Colors, Icon, Intent, Spinner } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import AvatarStack from '@components/AvatarStack';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview } from '@data/consts';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { openModal } from '@modals/store/events';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import CreateTeamModal from './modals/CreateTeamModal';
import { fetchTeams } from './store/effects';
import { resetTeams } from './store/events';
import { $teams } from './store/states';
import { usePageTitle } from '@app/hooks';
import { TeamPermission } from 'dy-frontend-permissions/lib/permission';

const Teams: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.team) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Teams');

    const teams = useStore($teams);

    useEffect(() => {
        fetchTeams().catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        return () => {
            resetTeams();
        };
    }, []);

    if (!teams) {
        // Teams were NOT fetched yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderCreateTeamButton = () => {
        const isCreateTeamAvailable = permissions.isRoot.team || permissions.has(TeamPermission.CREATE);
        if (!isCreateTeamAvailable) {
            return null;
        }

        return (
            <Button icon="add" intent={Intent.PRIMARY} onClick={() => openModal({ ModalComponent: CreateTeamModal })}>
                Create new team
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h3">Teams</Heading>
                    {renderCreateTeamButton()}
                </Flex>
            </>
        );
    };

    const renderTeamList = () => {
        if (teams.items.length === 0) {
            // Teams were fetched, but teams were NOT created yet
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No teams found
                        </Heading>
                    }
                />
            );
        }

        const renderTeam = (team: TeamResource) => {
            // Get team color or set default if team NOT exist
            let teamColor = Colors.WHITE;
            if (team.color) {
                teamColor = team.color;
            }

            const renderLeadParticipantInformation = () => {
                // Get lead participant
                const leadParticipant = team.participants.find((x) => x.role === TeamParticipationRole.LEAD);

                if (!leadParticipant) {
                    // Lead participant was NOT found
                    return (
                        <>
                            <Heading type="h4" className="mb-1 mt-2">
                                Lead
                            </Heading>

                            <DevText muted>No lead assigned</DevText>
                        </>
                    );
                }

                // Get avatar src
                let avatarSrc: string | null = null;
                if (leadParticipant.user.image_hash) {
                    avatarSrc = imageHashPreview.userImage(leadParticipant.user.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <>
                        <Heading type="h4" className="mb-1 mt-2">
                            Lead
                        </Heading>

                        <Flex direction="row">
                            <Avatar
                                className="mr-1"
                                alt={leadParticipant.user.first_name}
                                width="42px"
                                height="42px"
                                src={avatarSrc}
                            />

                            <Flex align="flex-start" direction="column">
                                <RouterLink
                                    className="mr-1"
                                    color={Colors.WHITE}
                                    to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', leadParticipant.user.id)}
                                >
                                    {leadParticipant.user.first_name} {leadParticipant.user.last_name}
                                </RouterLink>

                                <DevText muted>Team lead</DevText>
                            </Flex>
                        </Flex>
                    </>
                );
            };

            const renderParticipantAvatarStack = () => {
                if (team.participants.length === 0) {
                    // Team participants are NOT attached to the team
                    return (
                        <>
                            <Heading type="h4" className="mb-1 mt-2">
                                Members
                            </Heading>

                            <DevText muted>No members</DevText>
                        </>
                    );
                }

                // Create AvatarStack avatars property
                const participantAvatarStackData = team.participants.map((x) => ({
                    id: x.user.id,
                    name: `${x.user.first_name} ${x.user.last_name}`,
                    src: x.user.image_hash
                        ? imageHashPreview.userImage(x.user.image_hash, ImageHashPreviewSize.SM)
                        : null,
                }));

                return (
                    <>
                        <Heading type="h4" className="mb-1 mt-2">
                            Members
                        </Heading>

                        <AvatarStack
                            withCounter
                            className="mr-2"
                            avatars={participantAvatarStackData}
                            avatarSize={40}
                        />
                    </>
                );
            };

            const renderTeamInformation = () => {
                const workingHoursStart = team.working_hours_start.slice(0, team.working_hours_start.lastIndexOf(':'));
                const workingHoursEnd = team.working_hours_end.slice(0, team.working_hours_start.lastIndexOf(':'));

                return (
                    <div className="mb-1">
                        <DevText>
                            <DevText inline muted className="mr-small">
                                Country:
                            </DevText>
                            {countriesData[team.country].countryName}
                        </DevText>
                        <DevText>
                            <DevText inline muted className="mr-small">
                                Timezone:
                            </DevText>
                            {team.timezone_name}
                        </DevText>
                        <DevText className="mr-1">
                            <DevText inline muted className="mr-small">
                                Working hours:
                            </DevText>
                            {workingHoursStart} - {workingHoursEnd}
                        </DevText>
                    </div>
                );
            };

            return (
                <Card style={{ height: '100%' }}>
                    <Flex fullHeight fullWidth direction="column" justify="space-between">
                        <div>
                            <Flex className="mb-1">
                                <Circle className="mt-unit mr-1" size="18px" color={teamColor} />

                                <RouterLink
                                    color={Colors.WHITE}
                                    to={Endpoints.TEAM_MEMBERS.replace(':teamId', team.id)}
                                >
                                    <Heading type="h4">{team.title}</Heading>
                                </RouterLink>
                            </Flex>
                            {renderTeamInformation()}
                        </div>

                        <div>
                            {renderLeadParticipantInformation()}
                            {renderParticipantAvatarStack()}
                        </div>
                    </Flex>
                </Card>
            );
        };

        return (
            <Grid container>
                {teams.items.map((team) => (
                    <Grid className="mb-2" key={`team-${team.id}`} lg={3}>
                        {renderTeam(team)}
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <FixedWidthPageContainer>
            {renderHeader()}
            {renderTeamList()}
        </FixedWidthPageContainer>
    );
};

export default Teams;
