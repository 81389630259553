import { useState } from 'react';
import { DefaultFormFieldReturnValue, FormField } from '../types';

export interface UseFormProps<Response, Input, Field> {
    fields: Field[];
    apiCall: () => Promise<{ response: Response; input?: Input }>;
    onSuccess?: (options: { response: Response; input?: Input }) => void;
    onFailure?: (error: string) => void;
}

export type Props<Response, Input, Field> = UseFormProps<Response, Input, Field>;

const commonApiErrorMessage = 'Something went wrong, try again';
function useForm<Response, Input = any, Field extends Omit<DefaultFormFieldReturnValue<any>, 'value'> = FormField>(
    props: Props<Response, Input, Field>
) {
    const { fields, apiCall, onSuccess, onFailure } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sendingError, setSendingError] = useState('');

    const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const errors = await Promise.all(fields.map((field) => field.hasError()));
        const isFormValid = errors.every((error) => !error);

        if (isFormValid) {
            setIsSubmitting(true);
            setSendingError('');

            try {
                const { response, input } = await apiCall();
                onSuccess?.({ response, input });
            } catch (err) {
                // TODO: handle error
                const msg = err instanceof Error ? err.message : commonApiErrorMessage;

                setSendingError(msg);
                onFailure?.(msg);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const hasFieldErrors = fields.some((field) => !!field.error);

    return {
        isSubmitting,
        sendingError,
        hasFieldErrors,
        handleFormSubmit,
    };
}

export default useForm;
