import React, { useRef } from 'react';
import Box, { Props as BoxProps } from '@components/Box';
import Avatar, { Props as AvatarProps } from '@components/Avatar';
import FileUploadProgress, { FileUploadProgressProps } from '@components/FileUploadProgress';
import { Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { ValidatorFunction } from '@app/hooks/validation/types';
import { checkValidators } from '@app/hooks/validation/functions';
import { ToastUtils } from '@app/data/utils';

export interface AvatarUploaderProps {
    disabled?: boolean;
    loading?: boolean;
    avatarProps: AvatarProps;
    fileUploadProgressProps?: Omit<FileUploadProgressProps, 'width' | 'height'>;
    validators?: ValidatorFunction<File>[];
    onFileSelect: (file: File) => void;
    onFileRemove: () => any;
}

export type Props = AvatarUploaderProps & BoxProps;

const AvatarUploader: React.FC<Props> = ({
    disabled = false,
    loading = false,
    avatarProps,
    fileUploadProgressProps,
    validators = [],
    onFileSelect,
    onFileRemove,
    ...props
}) => {
    // Get avatar cursor
    const avatarCursor = disabled ? 'default' : 'pointer';

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleTriggerFileInputClick = () => {
        if (disabled) {
            return;
        }

        if (!fileInputRef.current) {
            return;
        }

        fileInputRef.current.click();
    };

    const renderProgress = () => {
        return <FileUploadProgress width="100%" height="100%" {...fileUploadProgressProps} />;
    };

    const renderAvatarMenu = () => {
        return (
            <Menu>
                <MenuItem disabled={loading} text="Change" icon="upload" onClick={handleTriggerFileInputClick} />

                {onFileRemove && (
                    <MenuItem
                        disabled={loading}
                        text="Remove"
                        icon="remove"
                        intent={Intent.DANGER}
                        onClick={onFileRemove}
                    />
                )}
            </Menu>
        );
    };

    const renderAvatar = () => {
        if (!avatarProps.src) {
            // Avatar is NOT present
            return <Avatar width="100%" height="100%" onClick={handleTriggerFileInputClick} {...avatarProps} />;
        } else {
            // User already have avatar uploaded

            return (
                <Popover
                    disabled={disabled}
                    targetProps={{ style: { width: '100%', height: '100%', borderRadius: 'inherit' } }}
                    content={renderAvatarMenu()}
                >
                    <Avatar width="100%" height="100%" {...avatarProps} />
                </Popover>
            );
        }
    };

    const renderFileInput = () => {
        const isFileInputDisabled = disabled || loading;

        const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = (e.target as HTMLInputElement).files;

            if (!files || files.length === 0) {
                return;
            }

            const file = files[0];

            // Validate file
            if (validators.length > 0) {
                const validationResult = await checkValidators(file, validators);

                if (validationResult !== null) {
                    // NOT valid
                    ToastUtils.showToast({ message: validationResult, intent: Intent.DANGER });
                    return;
                }
            }

            onFileSelect(files[0]);
        };

        return (
            <input
                hidden
                type="file"
                accept=".png,.jpg,.jpeg,.gif,.webp,.svg"
                ref={fileInputRef}
                disabled={isFileInputDisabled}
                onChange={handleFileSelect}
            />
        );
    };

    const renderContent = () => {
        if (loading) {
            // Avatar is still uploading -> show file upload progress
            return renderProgress();
        } else {
            // Avatar was uploaded show avatar image
            return renderAvatar();
        }
    };

    return (
        <Box cursor={avatarCursor} borderRadius="50%" {...props}>
            {renderFileInput()}
            {renderContent()}
        </Box>
    );
};

export default AvatarUploader;
