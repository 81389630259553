import { fetchTaskCategory, fetchTaskCategoryAddons, fetchTaskCategoryGroups } from './effects';
import { $taskCategory, $taskCategoryAddons, $taskCategoryGroups } from './states';

// Fetch task category
$taskCategory.on(fetchTaskCategory.doneData, (_, data) => data);

// Fetch task category addons
$taskCategoryAddons.on(fetchTaskCategoryAddons.doneData, (_, data) => data);

// Fetch task category groups
$taskCategoryGroups.on(fetchTaskCategoryGroups.doneData, (_, data) => data);
