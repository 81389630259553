import {
    $workspacePaymentAccount,
    $workspacePaymentMethods,
    $workspaceSubscriptions,
    $workspaceTaxIds,
} from "./states";
import {
    fetchWorkspacePaymentAccount,
    fetchWorkspacePaymentMethods,
    fetchWorkspaceSubscriptions,
    fetchWorkspaceTaxIds,
} from "./effects";

// Payment account
$workspacePaymentAccount.on(
    fetchWorkspacePaymentAccount.doneData,
    (_, data) => data,
);

// List of payment methods
$workspacePaymentMethods.on(
    fetchWorkspacePaymentMethods.doneData,
    (_, data) => data,
);

// List of subscriptions
$workspaceSubscriptions.on(
    fetchWorkspaceSubscriptions.doneData,
    (_, data) => data,
);

// List of Tax IDs
$workspaceTaxIds.on(
    fetchWorkspaceTaxIds.doneData,
    (_, data) => data,
);
