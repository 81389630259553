import { Button, Icon, Popover, Card as BlueprintjsCard } from '@blueprintjs/core';
import Flex from '@components/Flex';
import IconTextBlock from '@components/IconTextBlock';
import PdfViewer from '@components/PdfViewer';
import DevText from '@components/Text';
import VideoViewer from '@components/VideoViewer';
import { ModalProps } from '@modals/types';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import React, { useEffect, useState } from 'react';
import { Card, DismissModalPanel, ModalContent, ViewerWrapper } from './styled';
import Overlay from '@components/Overlay';
import Image from '@components/Image';
import Box from '@app/components/Box';
import NonIdealState from '@app/components/NonIdealState';
import Heading from '@app/components/Heading';
import { FileViewerItem } from './types';

export interface FileViewerModalProps {
    currentFileIndex: number;
    files: FileViewerItem[];
}

type Props = ModalProps<FileViewerModalProps>;

const FileViewerModal: React.FC<Props> = ({ closeModal, data }) => {
    const [currentFileItemIndex, setCurrentFileItemIndex] = useState(data ? data.currentFileIndex : 0);
    const [isContentFitInPage, setIsContentFitInPage] = useState(false);

    const handleEscapeKeyPressed = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            closeModal?.();
        }
    };

    const handleCloseModal = () => {
        closeModal?.();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPressed);
        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPressed);
        };
        // eslint-disable-next-line
    }, []);

    if (!data || data.files.length === 0) {
        // Data was NOT provided or files is empty array, meaning nothing to view
        closeModal?.();
        return null;
    }

    const renderFileViewerFileItem = (file: FileViewerItem) => {
        const { src, extension } = file;

        // Get first & last first/last item flag for current file item
        const isCurrentFileItemFirst = currentFileItemIndex === 0;
        const isCurrentFileItemLast = currentFileItemIndex === data.files.length - 1;

        const handleGoToPreviousFileItem = () => {
            setIsContentFitInPage(false);

            if (isCurrentFileItemFirst) {
                // First element
                setCurrentFileItemIndex(data.files.length - 1);
                return;
            }

            setCurrentFileItemIndex((i) => i - 1);
        };

        const handleGoToNextFileItem = () => {
            setIsContentFitInPage(false);

            if (isCurrentFileItemLast) {
                // Last element
                setCurrentFileItemIndex(0);
                return;
            }

            setCurrentFileItemIndex((i) => i + 1);
        };

        const renderViewer = () => {
            let viewer = <></>;

            const isFileItemImage = FileUtils.isImage(extension);
            const isFileItemPdf = FileUtils.isPdf(extension);
            const isFileItemVideo = FileUtils.isVideo(extension);
            const isViewerExistForFileItem = [isFileItemImage, isFileItemPdf, isFileItemVideo].some((flag) => flag);

            if (!isViewerExistForFileItem) {
                // No viewer
                viewer = (
                    <Flex fullWidth fullHeight align="center" justify="center">
                        <NonIdealState
                            className="mt-2"
                            icon={<Icon className="mb-2" icon="box" size={70} />}
                            title={
                                <Heading type="h4" className="mb-1">
                                    {file.name}
                                    {!!file.extension && `.${file.extension}`}
                                </Heading>
                            }
                            description={
                                <DevText muted>File could not be viewed, you can download it on your computer</DevText>
                            }
                            action={
                                <Button className="mt-2" icon="download" onClick={() => window.open(src)}>
                                    Download
                                </Button>
                            }
                        />
                    </Flex>
                );
            } else {
                if (isFileItemImage) {
                    viewer = (
                        <img
                            style={{
                                width: '100%',
                                height: isContentFitInPage ? '100%' : 'auto',
                                minHeight: '100%',
                                objectFit: 'contain',
                            }}
                            src={src}
                            alt={src}
                        />
                    );
                } else if (isFileItemPdf) {
                    viewer = <PdfViewer src={src} />;
                } else if (isFileItemVideo) {
                    viewer = <VideoViewer withAspectRatio={false} url={src} />;
                }
            }

            // TODO: can't view viewer

            return <ViewerWrapper className="custom-thin-scroll">{viewer}</ViewerWrapper>;
        };

        const renderPreviousFileItemButton = () => {
            return <Button minimal icon="chevron-left" onClick={handleGoToPreviousFileItem} />;
        };

        const renderFileItemIndexCounter = () => {
            return (
                <DevText>
                    {currentFileItemIndex + 1} / {data.files.length}
                </DevText>
            );
        };

        const renderFitContentButton = () => {
            const isFileItemImage = FileUtils.isImage(extension);

            if (!isFileItemImage) {
                // Allow adjust fit settings only for images
                return null;
            }

            if (isContentFitInPage) {
                return (
                    <Button minimal className="mr-1" icon="rect-width" onClick={() => setIsContentFitInPage(false)}>
                        Fit to width
                    </Button>
                );
            } else {
                return (
                    <Button minimal className="mr-1" icon="zoom-to-fit" onClick={() => setIsContentFitInPage(true)}>
                        Fit to page
                    </Button>
                );
            }
        };

        const renderNextFileItemButton = () => {
            return <Button minimal icon="chevron-right" onClick={handleGoToNextFileItem} />;
        };

        const renderFileItemIndexControls = () => {
            if (data.files.length <= 1) {
                return null;
            }

            return (
                <Flex className="ml-1" direction="row" align="center">
                    <div className="mr-1">{renderPreviousFileItemButton()}</div>
                    <div className="mr-1">{renderFileItemIndexCounter()}</div>
                    <div>{renderNextFileItemButton()}</div>
                </Flex>
            );
        };

        const renderCopyFileItemSrcButton = () => {
            return (
                <Popover
                    content={
                        <BlueprintjsCard compact>
                            <DevText>Copied</DevText>
                        </BlueprintjsCard>
                    }
                >
                    <Button minimal icon="duplicate" onClick={() => navigator.clipboard.writeText(file.src)}>
                        Copy link
                    </Button>
                </Popover>
            );
        };

        const renderDownloadFileItemButton = () => {
            return (
                <Button minimal icon="download" onClick={() => window.open(file.src)}>
                    Download
                </Button>
            );
        };

        const renderFooter = () => {
            return (
                <Box width="100%" padding="8px">
                    <Flex justify="space-between" align="center">
                        <Flex direction="row" align="center">
                            <IconTextBlock className="mr-2" icon="document" text={extension} />
                            <Flex direction="column" justify="center">
                                <DevText>{file.name}</DevText>
                                <DevText muted>{FileUtils.getFormattedFileSize(file.size)}</DevText>
                            </Flex>
                        </Flex>

                        <Flex direction="row" align="center">
                            {renderFitContentButton()}
                            <div className="mr-1">{renderCopyFileItemSrcButton()}</div>
                            {renderDownloadFileItemButton()}
                            {renderFileItemIndexControls()}
                        </Flex>
                    </Flex>
                </Box>
            );
        };

        return (
            <Card onClick={(e) => e.stopPropagation()}>
                <Flex fullWidth fullHeight direction="column" align="flex-start" justify="flex-start">
                    {renderViewer()}
                    {renderFooter()}
                </Flex>
            </Card>
        );
    };

    const renderDismissModalPanel = () => {
        return (
            <DismissModalPanel onClick={handleCloseModal}>
                <Flex fullHeight fullWidth align="center" justify="center">
                    <Icon className="mr-1" icon="chevron-up" />
                    <DevText>Click here to close modal</DevText>
                    <Icon className="ml-1" icon="chevron-up" />
                </Flex>
            </DismissModalPanel>
        );
    };

    // Get current file item
    const currentFileItem = data.files[currentFileItemIndex];

    return (
        <Overlay isOpen noPadding height="100%" onClose={closeModal}>
            <ModalContent>
                {renderDismissModalPanel()}
                {renderFileViewerFileItem(currentFileItem)}
            </ModalContent>
        </Overlay>
    );
};

export default FileViewerModal;
