import TeamTag from '@app/containers/components/TeamTag';
import { usePageTitle } from '@app/hooks';
import { Button, Colors, Icon, Intent, Spinner } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import RouterLink from '@components/RouterLink';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview, userRoleInformation } from '@data/consts';
import { SessionStorage } from '@data/enums';
import { openModal } from '@modals/store/events';
import { CoreUserFilterInput } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';
import { CoreUserListItemResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlatformCoreUserFilter from './components/PlatformCoreUserFilter';
import { amountOfCoreUsersOnPage, initialPlatformCoreUserFilterData } from './consts';
import CreateCoreUserModal from './modals/CreateCoreUserModal';
import { fetchCoreUsers, hydrateQueryParameters } from './store/effects';
import {
    resetCoreUsers,
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformCoreUserFilterData,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformCoreUserFilterData,
} from './store/events';
import { $coreUsers, $isQueryHydrationFinished, $page, $platformCoreUserFilterData } from './store/states';
import { CoreUserFilterQueryParameters, PlatformCoreUserFilterData } from './types';
import { PlatformCoreUserFilter as PlatformCoreUserFilterValueObject } from './valueObjects';
import { $permissions } from '@containers/store/states';
import { CoreUserPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const CoreUsers: React.FC = () => {
    const permissions = useStore($permissions);

    usePageTitle('Members');

    const navigate = useNavigate();
    const location = useLocation();

    const page = useStore($page);
    const coreUsers = useStore($coreUsers);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformCoreUserFilterData = useStore($platformCoreUserFilterData);
    const isFetchingCoreUsers = useStore(fetchCoreUsers.pending);

    const handleLoadPage = async (newPage: number, filter?: CoreUserFilterInput) => {
        try {
            const pageOffset = (newPage - 1) * amountOfCoreUsersOnPage;

            await fetchCoreUsers({
                pagination: {
                    _pagination: { limit: amountOfCoreUsersOnPage, offset: pageOffset },
                },
                filter,
            });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Members were not found`,
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Empty query string, load from local storage
            const savedUrlQueryParameters = sessionStorage.getItem(SessionStorage.CORE_USER_FILTER);

            if (savedUrlQueryParameters && savedUrlQueryParameters.length > 0) {
                urlQueryParameters = savedUrlQueryParameters;
            }
        }

        // Core user filter query parameters
        const coreUserFilterParameters: Partial<CoreUserFilterQueryParameters> = qs.parse(urlQueryParameters);

        // Hydration input
        const hydrateQueryInput =
            PlatformCoreUserFilterValueObject.convertCoreUserFilterQueryParametersToHydrateQueryInput(
                coreUserFilterParameters,
            );

        // Platform core user filter data
        let platformCoreUserData: PlatformCoreUserFilterData = { ...initialPlatformCoreUserFilterData };

        // hydrate
        hydrateQueryParameters(hydrateQueryInput)
            .then(({ team }) => {
                platformCoreUserData =
                    PlatformCoreUserFilterValueObject.convertHydratedQueryParametersToPlatformCoreUserFilterData({
                        ...coreUserFilterParameters,
                        team: team ?? [],
                    });
            })
            .finally(() => {
                const page = coreUserFilterParameters.page ? parseInt(coreUserFilterParameters.page) : 1;

                setPage(page);
                setPlatformCoreUserFilterData(platformCoreUserData);
                setIsQueryHydrationFinished(true);
            });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            // Get core user filter input
            const coreUserFilterInput =
                PlatformCoreUserFilterValueObject.convertPlatformCoreUserFilterDataToCoreUserFilterInput(
                    platformCoreUserFilterData,
                );

            // Get core user filter query parameters string
            const coreUserFilterQueryParametersString = qs.stringify(
                PlatformCoreUserFilterValueObject.convertCoreUserFilterInputToCoreUserFilterQueryParameters(
                    page,
                    coreUserFilterInput,
                ),
            );

            // Save query parameters to session storage
            sessionStorage.setItem(SessionStorage.CORE_USER_FILTER, coreUserFilterQueryParametersString);

            // Update URL
            navigate(`${Endpoints.CORE_USERS}?${coreUserFilterQueryParametersString}`, { replace: true });

            // Fetch core user list with new page
            handleLoadPage(page, coreUserFilterInput);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformCoreUserFilterData]);

    useEffect(() => {
        return () => {
            resetCoreUsers();
            resetPage();
            resetIsQueryHydrationFinished();
            resetPlatformCoreUserFilterData();
        };
    }, []);

    if (!coreUsers) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        const renderAddButton = () => {
            if (!permissions.isRoot.coreUser && !permissions.has(CoreUserPermission.CREATE)) {
                return null;
            }

            return (
                <Button
                    intent={Intent.PRIMARY}
                    icon="add"
                    onClick={() => openModal({ ModalComponent: CreateCoreUserModal })}
                >
                    Add member
                </Button>
            );
        };

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Members</Heading>

                {renderAddButton()}
            </Flex>
        );
    };

    const renderCoreUsersTable = () => {
        if (coreUsers.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No members were found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                <tr>
                    <th>Member</th>
                    <th>Team</th>
                    <th>Email</th>
                    <th>Latest clients</th>
                    <th>Latest activity</th>
                </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const renderRow = (coreUser: CoreUserListItemResource) => {
                // Render Team table cell
                const renderTeamTableCell = () => {
                    // Check if user attached to any team
                    if (coreUser.team_participation.length === 0) {
                        return (
                            <TableCell verticalAlign="middle">
                                <DevText muted>No team</DevText>
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell verticalAlign="middle">
                            {coreUser.team_participation.map((teamParticipation) => (
                                <TeamTag
                                    className="mb-small mr-small"
                                    id={teamParticipation.team.id}
                                    name={teamParticipation.team.title}
                                    color={teamParticipation.team.color}
                                >
                                    {teamParticipation.team.title}
                                </TeamTag>
                            ))}
                        </TableCell>
                    );
                };

                const renderCompanyInfo = () => {
                    let companyInformation = userRoleInformation[coreUser.role].label;

                    if (coreUser.company_position.trim().length > 0) {
                        companyInformation += `, ${coreUser.company_position}`;
                    }

                    if (companyInformation.length === 0) {
                        return null;
                    }

                    return <DevText muted>{companyInformation}</DevText>;
                };

                // Get avatar src
                let avatarSrc: string | null = null;
                if (coreUser.image_hash) {
                    avatarSrc = imageHashPreview.userImage(coreUser.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <tr key={coreUser.id}>
                        {/* User */}
                        <TableCell verticalAlign="middle">
                            <Flex direction="row" align="center">
                                <Avatar
                                    className="mr-1"
                                    alt={coreUser.first_name}
                                    width="42px"
                                    height="42px"
                                    src={avatarSrc}
                                />
                                <Flex direction="column" justify="center">
                                    <Flex direction="column">
                                        <RouterLink
                                            color={Colors.WHITE}
                                            to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', coreUser.id)}
                                        >
                                            {coreUser.first_name} {coreUser.last_name}
                                        </RouterLink>

                                        {renderCompanyInfo()}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </TableCell>

                        {/* Teams */}
                        {renderTeamTableCell()}

                        {/* Email */}
                        <TableCell verticalAlign="middle">
                            <DevText>{coreUser.email}</DevText>
                        </TableCell>

                        {/* TODO: Latest clients */}
                        <TableCell verticalAlign="middle">
                            <DevText muted>No data</DevText>
                        </TableCell>

                        {/* TODO: Latest activity */}
                        <TableCell verticalAlign="middle">
                            <DevText muted>No data</DevText>
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{coreUsers.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingCoreUsers}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (coreUsers === null) {
            return null;
        }

        // Check if offset paginator exist
        if (coreUsers.paginator === null) {
            return;
        }

        // Only one page exist
        if (!coreUsers.paginator.has_more && coreUsers.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingCoreUsers}
                    hasMore={coreUsers.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfCoreUsersOnPage}
                    totalItems={coreUsers.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformCoreUserFilter className="mb-2" />

            {/* Core users table */}
            {renderCoreUsersTable()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default CoreUsers;
