import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Position, Radio } from '@blueprintjs/core';
import { PlatformCoreUserFilterData } from '@pages/CoreUsers/types';
import { initialPlatformCoreUserFilterData, platformCoreUserFiltersInformation } from '@pages/CoreUsers/consts';
import { PlatformCoreUserFilterType } from '@pages/CoreUsers/enums';
import { $isQueryHydrationFinished } from '@pages/CoreUsers/store/states';
import { setPlatformCoreUserFilterData } from '@pages/CoreUsers/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';

export interface CoreUserArchivedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformCoreUserFilterData: PlatformCoreUserFilterData;
    onRemove: () => void;
}

export type Props = CoreUserArchivedPlatformFilterProps;

// TODO: put to consts
const includeShutdownCoreUsersRadioValue = 'include';
const excludeShutdownCoreUsersRadioValue = 'exclude';

const CoreUserArchivedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformCoreUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isShutdownCoreUsersVisible, setIsShutdownCoreUsersVisible] = useState<
        NonNullable<PlatformCoreUserFilterData['isShutdownCoreUsersVisible']>
    >(!!platformCoreUserFilterData.isShutdownCoreUsersVisible);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsShutdownCoreUsersVisible(!!platformCoreUserFilterData.isShutdownCoreUsersVisible);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (!platformCoreUserFilterData.isShutdownCoreUsersVisible) {
            setIsShutdownCoreUsersVisible(!!platformCoreUserFilterData.isShutdownCoreUsersVisible);
        }
    }, [platformCoreUserFilterData.isShutdownCoreUsersVisible]);

    const getFilterTagLabel = () => {
        if (isShutdownCoreUsersVisible) {
            return 'Include archived members';
        } else {
            return 'Exclude archived members ';
        }
    };

    const getFilterTagTitle = () => {
        return 'Archived:';
    };

    const handleRemoveFilter = () => {
        setIsShutdownCoreUsersVisible(false);
        setPlatformCoreUserFilterData({
            ...platformCoreUserFilterData,
            isShutdownCoreUsersVisible: initialPlatformCoreUserFilterData['isShutdownCoreUsersVisible'],
        });
        setIsOpen(false);
        if (!platformCoreUserFiltersInformation[PlatformCoreUserFilterType.ARCHIVED].isPinned) {
            // Platform core user archived filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformCoreUserFilterData.isShutdownCoreUsersVisible !== isShutdownCoreUsersVisible) {
            setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, isShutdownCoreUsersVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
    };

    const renderRow = (key: number) => {
        const selectedRadioValue = isShutdownCoreUsersVisible
            ? includeShutdownCoreUsersRadioValue
            : excludeShutdownCoreUsersRadioValue;

        const handleArchivedFlagVisibility = (value: string) => {
            switch (value) {
                case includeShutdownCoreUsersRadioValue:
                    setIsShutdownCoreUsersVisible(true);
                    break;
                case excludeShutdownCoreUsersRadioValue:
                    setIsShutdownCoreUsersVisible(false);
                    break;
            }
        };
        
        return (
            <li className="mb-1" key={key}>
                <Radio
                    checked={selectedRadioValue === includeShutdownCoreUsersRadioValue}
                    className="mb-1"
                    label="Include archived members"
                    onChange={() => handleArchivedFlagVisibility(includeShutdownCoreUsersRadioValue)}
                />

                <Radio
                    checked={selectedRadioValue === excludeShutdownCoreUsersRadioValue}
                    label="Exclude archived members"
                    onChange={() => handleArchivedFlagVisibility(excludeShutdownCoreUsersRadioValue)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Archived"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformCoreUserFiltersInformation[PlatformCoreUserFilterType.ARCHIVED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default CoreUserArchivedPlatformFilter;
