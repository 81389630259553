import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export interface ClientReviewProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & ClientReviewProps;

const ClientReview: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isRevisionRequiredAllowed, setIsRevisionRequiredAllowed] = useState(false);
    const [isPreDeliveredAllowed, setIsPreDeliveredAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsRevisionRequiredAllowed(
            permissions.isRoot.task
            || permissions.has(TaskPermission.STATE_TRANSITION_REVISION_REQUIRED),
        );
        setIsPreDeliveredAllowed(
            permissions.isRoot.task
            || permissions.has(TaskPermission.STATE_TRANSITION_PRE_DELIVERED),
        );
    }, [task, permissions, me]);


    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            const res = await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    // TODO: Render helper actions

    const renderRevisionRequiredTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isRevisionRequiredAllowed) {
            return null;
        }

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.REVISION_REQUIRED}
                onClick={() => handleTransitionToState(TaskState.REVISION_REQUIRED)}
            >Request another revision</Button>
        );
    };

    const renderPreDeliveredTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isPreDeliveredAllowed) {
            return null;
        }

        return (
            <Button
                icon="endorsed"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.PRE_DELIVERED}
                onClick={() => handleTransitionToState(TaskState.PRE_DELIVERED)}
            >Approve request</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Client Review</Heading>
                <Flex>
                    <div className="mr-1">{renderRevisionRequiredTransitionButton()}</div>
                    <div>{renderPreDeliveredTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Deliverables have been sent to the client for review. Please standby for any feedback that the client
                might provide.
            </DevText>
            <DevText muted>You can manually request another revision or approve request on client's behalf</DevText>
        </Card>
    );
};

export default ClientReview;
