import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { BrandProfileResource } from 'dy-frontend-http-repository/lib/modules/BrandProfile/resources';
import { BrandProfileAssetListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import domain from './domain';
import { resetBrandProfile, resetBrandProfileAssets } from './events';

// Brand profile
export const $brandProfile = domain.createStore<BrandProfileResource | null>(null).reset(resetBrandProfile);

// Brand profile assets
export const $brandProfileAssets = domain
    .createStore<CollectionResource<BrandProfileAssetListItemResource, {}> | null>(null)
    .reset(resetBrandProfileAssets);
