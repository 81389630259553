import React, { HTMLAttributes } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { tabs } from './consts';
import PageTabs from '@app/components/PageTabs';
import { $clientPaymentAccount } from '../../store/states';
import { useStore } from 'effector-react';
import { Endpoints } from '@app/data/consts';
import { $permissions } from '@containers/store/states';

type Props = HTMLAttributes<HTMLDivElement>;

const Tabs: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { clientId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const paymentAccount = useStore($clientPaymentAccount);

    const getFormattedPath = (path: string) => {
        return path.replace(':clientId', clientId!);
    };

    // List of skipped tab router
    const ignoreTabRoutes: string[] = [];

    // Get tabs
    if (!paymentAccount || !permissions.isEnabled.paymentAccount) {
        ignoreTabRoutes.push(Endpoints.CLIENT_BILLING);
    }

    // Auth: task
    if (!permissions.isEnabled.task) {
        ignoreTabRoutes.push(Endpoints.CLIENT_TASK_FEEDBACK);
    }

    // Auth: brand profiles
    if (!permissions.isEnabled.brandProfile) {
        ignoreTabRoutes.push(Endpoints.CLIENT_BRAND_PROFILES);
    }

    // Auth: user notes
    if (!permissions.isEnabled.userNote) {
        ignoreTabRoutes.push(Endpoints.CLIENT_NOTES);
    }

    // Auth: licence
    if (!permissions.isEnabled.licence) {
        ignoreTabRoutes.push(Endpoints.CLIENT_LICENCES);
    }

    // Auth: user notification email
    if (!permissions.isEnabled.userNotificationEmail) {
        ignoreTabRoutes.push(Endpoints.CLIENT_NOTIFICATIONS);
    }

    const collectedTabs = tabs.filter((tab) => !ignoreTabRoutes.includes(tab.value))

    return (
        <PageTabs
            pathname={pathname}
            visibleAmount={6}
            tabs={collectedTabs}
            onSelectTab={navigate}
            pathFormatter={getFormattedPath}
            {...props}
        />
    );
};

export default Tabs;
