import { UnitUtils } from 'dy-frontend-shared/lib/utils';
import { setTimeMarker } from '../../store/events';

const useSetTimeMarker = () => {
    const handleSetTimeMarker = (time: number) => {
        setTimeMarker(UnitUtils.secToMs(time));
    };

    return { handleSetTimeMarker };
};

export default useSetTimeMarker;
