import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import { PlatformTaskClientFeedbackFilterType } from '../../enums';
import { PlatformTaskClientFeedbackFilterData, TaskClientFeedbackFilterQueryParameters } from '../../types';
import { platformFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';

export default class PlatformTaskClientFeedbackFilter {
    static convertHydratedQueryParametersToPlatformFilterData(
        hydratedQueryParameters: HydratedQueryParameters
    ): PlatformTaskClientFeedbackFilterData {
        // Create empty platform task filter data object
        const platformTaskClientFeedbackFilterData: PlatformTaskClientFeedbackFilterData = {
            clients: [],
            participants: [],
            teams: [],
            negative: [],
        };

        /* Static filters */
        if (hydratedQueryParameters.negative) {
            // Set negative scores
            platformTaskClientFeedbackFilterData.negative = hydratedQueryParameters.negative;
        }

        /* Dynamic filters */
        if (hydratedQueryParameters.client_user) {
            // Set client user
            platformTaskClientFeedbackFilterData.clients = hydratedQueryParameters.client_user;
        }

        if (hydratedQueryParameters.core_user) {
            // Set participants
            platformTaskClientFeedbackFilterData.participants = hydratedQueryParameters.core_user;
        }

        if (hydratedQueryParameters.team) {
            // Set workspaces
            platformTaskClientFeedbackFilterData.teams = hydratedQueryParameters.team;
        }

        return platformTaskClientFeedbackFilterData;
    }

    static convertPlatformFilterDataToFilterInput(
        platformTaskClientFeedbackFilterData: PlatformTaskClientFeedbackFilterData
    ): TaskClientFeedbackFilterInput {
        const taskFilterInput: TaskClientFeedbackFilterInput = {};

        /* Static filters */
        if (platformTaskClientFeedbackFilterData.negative.length > 0) {
            // Set negative
            taskFilterInput.negative = platformTaskClientFeedbackFilterData.negative;
        }

        /* Dynamic filters */
        if (platformTaskClientFeedbackFilterData.clients.length > 0) {
            // Set client user
            taskFilterInput.task_owner_user_id = platformTaskClientFeedbackFilterData.clients.map(
                (client) => client.id
            );
        }

        if (platformTaskClientFeedbackFilterData.participants.length > 0) {
            // Set participants
            taskFilterInput.participant_user_id = platformTaskClientFeedbackFilterData.participants.map(
                (participant) => participant.id
            );
        }

        if (platformTaskClientFeedbackFilterData.teams.length > 0) {
            // Set task categories
            taskFilterInput.team_id = platformTaskClientFeedbackFilterData.teams.map((team) => team.id);
        }

        return taskFilterInput;
    }

    static convertFilterInputToFilterQueryParameters = (
        page: number,
        taskFilterInput: TaskClientFeedbackFilterInput
    ): TaskClientFeedbackFilterQueryParameters => {
        const taskFilterQueryParameters: TaskClientFeedbackFilterQueryParameters = {
            page: '1',
            clientsIds: [],
            participantsIds: [],
            teamIds: [],
            negative: [],
        };

        // Set page
        taskFilterQueryParameters.page = `${page}`;

        // Set client IDs
        if (taskFilterInput.task_owner_user_id && taskFilterInput.task_owner_user_id.length > 0) {
            taskFilterQueryParameters.clientsIds = taskFilterInput.task_owner_user_id;
        }

        // Set participants IDs
        if (taskFilterInput.participant_user_id && taskFilterInput.participant_user_id.length > 0) {
            taskFilterQueryParameters.participantsIds = taskFilterInput.participant_user_id;
        }

        // Set team IDs
        if (taskFilterInput.team_id && taskFilterInput.team_id.length > 0) {
            taskFilterQueryParameters.teamIds = taskFilterInput.team_id;
        }

        // Set negative
        if (taskFilterInput.negative && taskFilterInput.negative.length > 0) {
            taskFilterQueryParameters.negative = taskFilterInput.negative;
        }

        return taskFilterQueryParameters;
    };

    static convertFilterQueryParametersToHydrateQueryInput = (
        taskFilterQueryParameters: Partial<TaskClientFeedbackFilterQueryParameters>
    ) => {
        const hydrateQueryInput: Omit<HydrateFilterDataFilterInput, 'plan_id' | 'task_category_id' | 'workspace_id'> =
            {};

        // Set client user ids
        if (taskFilterQueryParameters.clientsIds && taskFilterQueryParameters.clientsIds.length > 0) {
            hydrateQueryInput.client_user_id = taskFilterQueryParameters.clientsIds;
        }

        // Set participants ids
        if (taskFilterQueryParameters.participantsIds && taskFilterQueryParameters.participantsIds.length > 0) {
            hydrateQueryInput.core_user_id = taskFilterQueryParameters.participantsIds;
        }

        // Set task categories
        if (taskFilterQueryParameters.teamIds && taskFilterQueryParameters.teamIds.length > 0) {
            hydrateQueryInput.team_id = taskFilterQueryParameters.teamIds;
        }

        return hydrateQueryInput;
    };

    static getAppliedPlatformFilters(
        platformTaskClientFeedbackFilterData: PlatformTaskClientFeedbackFilterData
    ): PlatformTaskClientFeedbackFilterType[] {
        const appliedPlatformFilters: PlatformTaskClientFeedbackFilterType[] = [];

        const platformTaskFilterTypeValues = Object.values(PlatformTaskClientFeedbackFilterType);

        for (let i = 0; i < platformTaskFilterTypeValues.length; i++) {
            const platformTaskFilterType = platformTaskFilterTypeValues[i];

            if (platformFiltersInformation[platformTaskFilterType].isPinned) {
                // Platform task filter is pinned by default
                appliedPlatformFilters.push(platformTaskFilterType);
                continue;
            }

            switch (platformTaskFilterType) {
                /* Static */
                case PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE:
                    if (platformTaskClientFeedbackFilterData.negative.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE);
                    }
                    break;
                case PlatformTaskClientFeedbackFilterType.CLIENT_USERS:
                    if (platformTaskClientFeedbackFilterData.clients.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskClientFeedbackFilterType.CLIENT_USERS);
                    }
                    break;
                case PlatformTaskClientFeedbackFilterType.TEAMS:
                    if (platformTaskClientFeedbackFilterData.teams.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskClientFeedbackFilterType.TEAMS);
                    }
                    break;
                case PlatformTaskClientFeedbackFilterType.TASK_PARTICIPANTS:
                    if (platformTaskClientFeedbackFilterData.participants.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskClientFeedbackFilterType.TASK_PARTICIPANTS);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
