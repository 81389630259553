import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { ParsedDesignDimensionData } from 'dy-frontend-shared/lib/utils/TaskUtils/types';
import domain from './domain';
import {
    resetNormalizedTaskCategoryDimensions,
    resetTaskCategory,
    resetTaskCategoryAddons,
    resetTaskCategoryGroups,
} from './events';

// Task category
export const $taskCategory = domain.createStore<TaskCategoryResource | null>(null).reset(resetTaskCategory);

// Normalized task category dimensions
export const $normalizedTaskCategoryDimensions = domain
    .createStore<ParsedDesignDimensionData | null>(null)
    .reset(resetNormalizedTaskCategoryDimensions);

// Task category groups
export const $taskCategoryGroups = domain
    .createStore<CollectionResource<TaskCategoryGroupListItemResource, {}> | null>(null)
    .reset(resetTaskCategoryGroups);

// Task category addons
export const $taskCategoryAddons = domain
    .createStore<CollectionResource<TaskCategoryAddonResource, {}> | null>(null)
    .reset(resetTaskCategoryAddons);
