import { Button, Classes, Colors, InputGroup, Intent, Position } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilterWithDynamicSearch from '@components/PlatformListFilterWithDynamicSearch';
import { platformFiltersInformation } from '@pages/TaskClientFeedback/consts';
import { PlatformTaskClientFeedbackFilterType } from '@pages/TaskClientFeedback/enums';
import { fetchClientUserData } from '@pages/TaskClientFeedback/store/effects';
import { setPlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/store/events';
import { $isQueryHydrationFinished } from '@pages/TaskClientFeedback/store/states';
import { PlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/types';
import { ClientUserResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import DevText from '@components/Text';

export interface ClientUsersPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskClientFeedbackData: PlatformTaskClientFeedbackFilterData;
    onRemove: () => void;
}

export type Props = ClientUsersPlatformFilterProps;

const ClientUsersPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskClientFeedbackData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [query, setQuery] = useState('');
    const [isClientUsersSearching, setIsClientUsersSearching] = useState(false);
    const [selectedClientUsers, setSelectedClientUsers] = useState<PlatformTaskClientFeedbackFilterData['clients']>([]);
    const [foundClientUsers, setFoundClientUsers] = useState<PlatformTaskClientFeedbackFilterData['clients']>([]);

    const searchInputRef = useRef<null | HTMLInputElement>(null);

    useEffect(() => {
        if (query.trim().length === 0) {
            setFoundClientUsers([]);
        }
    }, [query]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedClientUsers(platformTaskClientFeedbackData.clients);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (query.trim().length !== 0) {
            fetchClientUserData({
                query,
                workspace_id: [],
                exclude_id: selectedClientUsers.map((client) => client.id),
            }).then((clientUsers) => setFoundClientUsers(clientUsers.items));
        }

        handleFocusInput();

        // eslint-disable-next-line
    }, [selectedClientUsers]);

    useEffect(() => {
        setSelectedClientUsers(platformTaskClientFeedbackData.clients);
        setFoundClientUsers([]);
    }, [platformTaskClientFeedbackData.clients]);

    const getFilterTagTitle = () => {
        return selectedClientUsers.length > 0 ? 'Clients:' : 'Clients';
    };

    const getFilterTagLabel = () => {
        if (selectedClientUsers.length === 0) return '';

        return selectedClientUsers
            .map((clientUser, index) => {
                if (index === selectedClientUsers.length - 1) {
                    // Last element
                    return `${clientUser.first_name} ${clientUser.last_name}`;
                }

                return `${clientUser.first_name} ${clientUser.last_name}, `;
            })
            .join('');
    };

    const handleRemoveFilter = () => {
        if (platformTaskClientFeedbackData.clients.length !== 0) {
            // Reset clients to empty array
            setPlatformTaskClientFeedbackFilterData({ ...platformTaskClientFeedbackData, clients: [] });
        }

        if (!platformFiltersInformation[PlatformTaskClientFeedbackFilterType.CLIENT_USERS].isPinned) {
            // Platform client filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleSearch = (searchValue: string = query) => {
        setIsClientUsersSearching(true);

        if (searchValue.trim().length !== 0) {
            // Search value is NOT empty string

            // Fetch users matched by searchValue
            fetchClientUserData({
                query: searchValue,
                workspace_id: [],
                exclude_id: selectedClientUsers.map((selectedClientUser) => selectedClientUser.id),
            })
                .then((clientUsers) => setFoundClientUsers(clientUsers.items))
                .finally(() => setTimeout(() => setIsClientUsersSearching(false), 1000));
        }
    };

    const handleAddClientUser = (newClientUser: ClientUserResource) => {
        if (!selectedClientUsers.find((clientUser) => clientUser.id === newClientUser.id)) {
            // Client user with such ID was not added yet
            setSelectedClientUsers([newClientUser, ...selectedClientUsers]);
        }
    };

    const handleRemoveClientUser = (id: ID) => {
        setSelectedClientUsers(selectedClientUsers.filter((clientUser) => clientUser.id !== id));
    };

    const handleFocusInput = () => {
        if (searchInputRef.current !== null) {
            // Focus input
            searchInputRef.current.focus();
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedClientUsers) !== JSON.stringify(platformTaskClientFeedbackData.clients)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskClientFeedbackFilterData({ ...platformTaskClientFeedbackData, clients: selectedClientUsers });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
        setQuery('');

        if (platformFiltersInformation[PlatformTaskClientFeedbackFilterType.CLIENT_USERS].isPinned) {
            return;
        }

        if (selectedClientUsers.length === 0) {
            onRemove();
        }
    };

    const renderSearchInput = () => {
        return (
            <InputGroup
                leftIcon="search"
                placeholder="Search for clients..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputRef={searchInputRef}
            />
        );
    };

    const renderSkeletonRow = (id: ID) => {
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <Flex className="mr-2">
                        <Avatar className={`mr-2 ${Classes.SKELETON}`} alt="name" width="35px" height="35px" />
                        <Flex direction="column" align="flex-start">
                            <DevText className={Classes.SKELETON}>first_name last_name</DevText>
                            <DevText muted className={Classes.SKELETON}>
                                company_name
                            </DevText>
                            <DevText muted className={Classes.SKELETON}>
                                company_position
                            </DevText>
                            <DevText muted className={Classes.SKELETON}>
                                workspace_title
                            </DevText>
                        </Flex>
                    </Flex>

                    <Button minimal icon="cross" className={Classes.SKELETON} intent={Intent.DANGER} />
                </Flex>
            </li>
        );
    };

    const renderSelectedClientUserRow = ({
        id,
        first_name,
        last_name,
        company_name,
        company_position,
        workspace_title,
        image_file_preview_url,
    }: ClientUserResource) => {
        
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <Flex className="mr-2">
                        <Avatar
                            className="mr-2"
                            width="35px"
                            height="35px"
                            alt={`${first_name} ${last_name}`}
                            src={image_file_preview_url}
                        />

                        <Flex direction="column" align="flex-start">
                            <DevText>
                                {first_name} {last_name}
                            </DevText>

                            <DevText muted>{company_name}</DevText>
                            <DevText muted>{company_position}</DevText>
                            <DevText muted>{workspace_title}</DevText>
                        </Flex>
                    </Flex>

                    <Button minimal icon="cross" intent={Intent.DANGER} onClick={() => handleRemoveClientUser(id)} />
                </Flex>
            </li>
        );
    };

    const renderFoundClientUserRow = ({
        id,
        first_name,
        last_name,
        company_name,
        company_position,
        workspace_id,
        workspace_title,
        image_file_preview_url,
    }: ClientUserResource) => {
        return (
            <li key={id}>
                <Flex justify="space-between">
                    <Flex className="mr-2">
                        <Avatar className="mr-2" alt={`${first_name} ${last_name}`} width="35px" height="35px" />

                        <Flex direction="column" align="flex-start">
                            <DevText>
                                {first_name} {last_name}
                            </DevText>

                            <DevText muted>{company_name}</DevText>
                            <DevText muted>{company_position}</DevText>
                            <DevText muted>{workspace_title}</DevText>
                        </Flex>
                    </Flex>

                    <Button
                        minimal
                        icon="plus"
                        intent={Intent.SUCCESS}
                        onClick={() =>
                            handleAddClientUser({
                                id,
                                first_name,
                                last_name,
                                company_name,
                                company_position,
                                workspace_id,
                                workspace_title,
                                image_file_preview_url,
                            })
                        }
                    />
                </Flex>
            </li>
        );
    };

    const renderEmptyList = () => {
        return (
            <div>
                <Flex className="mb-1" align="center">
                    <Circle className="mr-1" size="16px" color={Colors.GRAY2} />
                    <DevText>No results</DevText>
                </Flex>
                <DevText muted>Please try different search query</DevText>
            </div>
        );
    };

    return (
        <PlatformListFilterWithDynamicSearch<ClientUserResource>
            loading={isClientUsersSearching && query.trim().length > 0}
            isOpen={isOpen}
            removable={selectedClientUsers.length > 0}
            title="Clients"
            position={Position.BOTTOM_LEFT}
            search={query}
            maxListHeight={318}
            selectedList={selectedClientUsers}
            foundList={foundClientUsers}
            skeletonList={Array.from({ length: 3 }, (_, index) => `${index + 1}`)}
            selectedItemRenderer={renderSelectedClientUserRow}
            foundItemRenderer={renderFoundClientUserRow}
            emptyListRenderer={renderEmptyList}
            searchInputRenderer={renderSearchInput}
            skeletonItemRenderer={renderSkeletonRow}
            onSearch={handleSearch}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
            onOpened={handleFocusInput}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskClientFeedbackFilterType.CLIENT_USERS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilterWithDynamicSearch>
    );
};

export default ClientUsersPlatformFilter;
