import styled, { css } from 'styled-components';
import { OverlayProps } from '.';

export const Content = styled.div`
    position: relative;

    width: 100vw;
    height: 100vh;
    overflow-y: auto;
`;

export const ContentPositioner = styled.div<{ $height: OverlayProps['height']; $noPadding: OverlayProps['noPadding'] }>`
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 72px;
    width: 100%;
    min-height: 100%;

    ${({ $height }) =>
        $height &&
        css`
            height: ${$height};
        `}

    ${({ $noPadding }) =>
        $noPadding &&
        css`
            padding: 0;
        `}
`;
