import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import TextAreaFormFields from '@components/TextAreaFormField';
import { ModalProps } from '@modals/types';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { UpdateBrandProfileInformationInput } from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import React from 'react';
import { brandProfileApi } from '../../store/apis';
import { brandProfileIndustries } from './consts';

export interface UpdateBrandProfileMainInformationModalProps {
    brandProfileId: ID;
    title: string;
    industryName: string;
    values: string;
    targetAudience: string;
    productDescription: string;
    comment: string;
}

type Props = ModalProps<UpdateBrandProfileMainInformationModalProps>;

const brandProfileTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const brandProfileIndustryNameValidators = [];
const brandProfileValuesValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const brandProfileTargetAudienceValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 70 }),
    ]),
];
const brandProfileProductDescriptionValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 5 }),
        getStringMaxLengthValidator({ maxStringLength: 100 }),
    ]),
];
const brandProfileCommentValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 5 }),
        getStringMaxLengthValidator({ maxStringLength: 100 }),
    ]),
];

const UpdateBrandProfileMainInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const brandProfileTitle = useTextFormField({
        id: 'brand-profile-title',
        validators: brandProfileTitleValidators,
        initialValue: data?.title ?? '',
    });

    const brandProfileIndustryName = useCustomSelectFormField<string | null>({
        id: 'brand-profile-industry-name',
        validators: brandProfileIndustryNameValidators,
        initialValue: data?.industryName ?? null,
        formatValue: (value) => value ?? '',
    });

    const brandProfileValues = useTextFormField({
        id: 'brand-profile-values',
        validators: brandProfileValuesValidators,
        initialValue: data?.values ?? '',
    });

    const brandProfileTargetAudience = useTextFormField({
        id: 'brand-profile-target-audience',
        validators: brandProfileTargetAudienceValidators,
        initialValue: data?.targetAudience ?? '',
    });

    const brandProfileProductDescription = useTextFormField({
        id: 'brand-profile-product-description',
        validators: brandProfileProductDescriptionValidators,
        initialValue: data?.productDescription ?? '',
    });

    const brandProfileComment = useTextFormField({
        id: 'brand-profile-comment',
        validators: brandProfileCommentValidators,
        initialValue: data?.comment ?? '',
    });

    const form = useForm<BrandProfileRef>({
        fields: [
            brandProfileTitle,
            brandProfileIndustryName,
            brandProfileValues,
            brandProfileTargetAudience,
            brandProfileProductDescription,
            brandProfileComment,
        ],
        apiCall: async () => {
            try {
                const updateBrandProfileInput: UpdateBrandProfileInformationInput = {
                    title: brandProfileTitle.value,
                    industry_name: brandProfileIndustryName.value ?? undefined,
                    values: brandProfileValues.value,
                    target_audience: brandProfileTargetAudience.value,
                    product_description: brandProfileProductDescription.value,
                    comment: brandProfileComment.value,
                };

                const brandProfileRef = await updateBrandProfileInformation({
                    id: data!.brandProfileId,
                    input: updateBrandProfileInput,
                });

                brandProfileApi.update({ ...updateBrandProfileInput });

                return { response: brandProfileRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update brand profile</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        {/* Title */}
                        <Grid lg={12}>
                            <InputFormField
                                field={brandProfileTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter brand profile title' }}
                            />
                        </Grid>

                        {/* Industry name */}
                        <Grid lg={12}>
                            <SelectFormField
                                field={brandProfileIndustryName}
                                items={brandProfileIndustries}
                                formGroupProps={{ label: 'Industry' }}
                                selectProps={{ selectButtonProps: { placeholder: 'Select industry' } }}
                            />
                        </Grid>

                        {/* Values */}
                        <Grid lg={12}>
                            <InputFormField
                                field={brandProfileValues}
                                formGroupProps={{ label: 'Values' }}
                                inputProps={{ placeholder: 'Enter values' }}
                            />
                        </Grid>

                        {/* Target audience */}
                        <Grid lg={12}>
                            <InputFormField
                                field={brandProfileTargetAudience}
                                formGroupProps={{ label: 'Target audience' }}
                                inputProps={{ placeholder: 'Enter target audience' }}
                            />
                        </Grid>

                        {/* Product description */}
                        <Grid lg={12}>
                            <TextAreaFormFields
                                field={brandProfileProductDescription}
                                formGroupProps={{ label: 'Service/product description' }}
                                textAreaProps={{ placeholder: 'Enter service/product description' }}
                            />
                        </Grid>

                        {/* Comment */}
                        <Grid lg={12}>
                            <TextAreaFormFields
                                field={brandProfileComment}
                                formGroupProps={{ label: 'Additional remarks' }}
                                textAreaProps={{ placeholder: 'Enter additional remarks' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateBrandProfileMainInformationModal;
