import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { OpenTaskListTeamFilterInput } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/input';
import { OpenTaskListResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';

// Fetches open task list by owner ID
export const fetchTeamOpenTaskList = domain.createEffect<
    { filter: OpenTaskListTeamFilterInput },
    OpenTaskListResource
>({
    name: 'fetchTeamOpenTaskList',
    handler: async ({ filter }) => {
        return await repository.openTaskList().getByTeam(filter);
    },
});
