import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Position, Radio } from '@blueprintjs/core';
import { PlatformClientUserFilterData } from '@pages/Clients/types';
import { initialPlatformClientUserFilterData, platformClientUserFiltersInformation } from '@pages/Clients/consts';
import { PlatformClientUserFilterType } from '@pages/Clients/enums';
import { $isQueryHydrationFinished } from '@pages/Clients/store/states';
import { setPlatformClientUserFilterData } from '@pages/Clients/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';

export interface ClientUserVerifiedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

const includeVerifiedClientUsersRadioValue = 'include';
const excludeVerifiedClientUsersRadioValue = 'exclude';

export type Props = ClientUserVerifiedPlatformFilterProps;

const ClientUserVerifiedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isVerifiedClientUsersVisible, setIsVerifiedClientUsersVisible] = useState<
        NonNullable<PlatformClientUserFilterData['isVerifiedClientUsersVisible']>
    >(!!platformClientUserFilterData.isVerifiedClientUsersVisible);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsVerifiedClientUsersVisible(!!platformClientUserFilterData.isVerifiedClientUsersVisible);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (!platformClientUserFilterData.isVerifiedClientUsersVisible) {
            setIsVerifiedClientUsersVisible(!!platformClientUserFilterData.isVerifiedClientUsersVisible);
        }
    }, [platformClientUserFilterData.isVerifiedClientUsersVisible]);

    const getFilterTagLabel = () => {
        if (isVerifiedClientUsersVisible) {
            return 'Include verified clients';
        } else {
            return 'Exclude verified clients ';
        }
    };

    const getFilterTagTitle = () => {
        return 'Verified:';
    };

    const handleRemoveFilter = () => {
        setIsVerifiedClientUsersVisible(false);
        setPlatformClientUserFilterData({
            ...platformClientUserFilterData,
            isVerifiedClientUsersVisible: initialPlatformClientUserFilterData['isVerifiedClientUsersVisible'],
        });
        setIsOpen(false);
        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.VERIFIED].isPinned) {
            // Platform client user verified filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformClientUserFilterData.isVerifiedClientUsersVisible !== isVerifiedClientUsersVisible) {
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, isVerifiedClientUsersVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
    };

    const renderRow = (key: number) => {
        const selectedRadioValue = isVerifiedClientUsersVisible
            ? includeVerifiedClientUsersRadioValue
            : excludeVerifiedClientUsersRadioValue;

        const handleArchivedFlagVisibility = (value: string) => {
            switch (value) {
                case includeVerifiedClientUsersRadioValue:
                    setIsVerifiedClientUsersVisible(true);
                    break;
                case excludeVerifiedClientUsersRadioValue:
                    setIsVerifiedClientUsersVisible(false);
                    break;
            }
        };

        return (
            <li className="mb-1" key={key}>
                <Radio
                    checked={selectedRadioValue === includeVerifiedClientUsersRadioValue}
                    className="mb-1"
                    label="Include verified clients"
                    onChange={() => handleArchivedFlagVisibility(includeVerifiedClientUsersRadioValue)}
                />

                <Radio
                    checked={selectedRadioValue === excludeVerifiedClientUsersRadioValue}
                    label="Exclude verified clients"
                    onChange={() => handleArchivedFlagVisibility(excludeVerifiedClientUsersRadioValue)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Verified"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.VERIFIED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default ClientUserVerifiedPlatformFilter;
