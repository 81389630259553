import { TabItem } from '@app/data/types';
import { Endpoints } from '@data/consts';

const tabs: TabItem[] = [
    { icon: 'team', value: Endpoints.TEAM_MEMBERS, label: 'Members' },
    { icon: 'saved', value: Endpoints.TEAM_TASKS, label: 'Requests' },
    { icon: 'chart', value: Endpoints.TEAM_TASK_FEEDBACK, label: 'Client Feedback' },
];

export default tabs;
