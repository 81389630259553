import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $client } from '@pages/Client/store/states';
import NotificationEmailList from '@pages/Client/pages/ClientNotifications/components/NotificationEmailList';
import { usePageTitle } from '@app/hooks';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { Button, Intent } from '@blueprintjs/core';
import { fetchNotificationEmails } from './store/effects';
import { openModal } from '@app/containers/modals/store/events';
import CreateNotificationEmailModal, { CreateNotificationEmailModalProps } from './modals/CreateNotificationEmailModal';
import { resetNotificationEmails } from './store/events';
import { useNavigate } from 'react-router-dom';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { ToastUtils } from '@app/data/utils';
import { Endpoints } from '@app/data/consts';

const ClientNotifications: React.FC = () => {
    const { changeTitle } = usePageTitle('Client Notifications');
    const navigate = useNavigate();

    const client = useStore($client);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Notifications - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        if (!client) {
            return;
        }

        fetchNotificationEmails({ user_id: client.id }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Notifications for client with ID of ${client.id} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
            }
        });
    }, [client?.id]);

    useEffect(() => {
        return () => {
            resetNotificationEmails();
        };
    }, []);

    if (!client) {
        return null;
    }

    const renderNotificationEmailListSection = () => {
        const handleAddNotificationEmail = () => {
            openModal<CreateNotificationEmailModalProps>({
                ModalComponent: CreateNotificationEmailModal,
                data: {
                    userId: client.id,
                },
            });
        };

        const renderAddNotificationEmail = () => {
            return (
                <Button intent={Intent.PRIMARY} onClick={handleAddNotificationEmail}>
                    Add email
                </Button>
            );
        };

        return (
            <div>
                <Flex align="center" justify="space-between" className="mb-2">
                    <Heading type="h3">Additional notification emails</Heading>
                    {renderAddNotificationEmail()}
                </Flex>
                <NotificationEmailList />
            </div>
        );
    };

    return (
        <div>
            <div className="mb-4">{renderNotificationEmailListSection()}</div>
        </div>
    );
};

export default ClientNotifications;
