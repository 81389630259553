import { useState, useCallback } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';

export interface UseTextFormFieldReturnValue {
    handleChange: (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void;
    handleSetValue: (value: string) => void;
    handleBlur: () => void;
    clear: () => void;
}

export type ReturnValue = DefaultFormFieldReturnValue<string> & UseTextFormFieldReturnValue;
export type Props = DefaultFormFieldProps<string>;

function useTextFormField({ id, initialValue, validators }: Props): ReturnValue {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
            // Get new value
            const val = (event.target as HTMLInputElement).value;

            // Set new value
            setValue(val);

            // Check validity for a new value
            setError(await checkValidators(val, validators));
        },
        [validators]
    );

    const handleBlur = useCallback(async () => {
        // Check validity for a field
        setError(await checkValidators(value, validators));
    }, [value, validators]);

    const hasError = useCallback(async () => {
        // Check validity for a field
        const err = await checkValidators(value, validators);

        // Set validation result
        setError(err);

        return !!err;
    }, [value, validators]);

    const handleSetValue = (value: string) => {
        setValue(value);
    };

    const reset = () => {
        // Reset value
        setValue(initialValue);
    };

    const clear = () => {
        setValue('');
    };

    return {
        id,
        value,
        error,
        reset,
        hasError,
        handleChange,
        handleSetValue,
        handleBlur,
        clear,
    };
}

export default useTextFormField;
