import React, { HTMLAttributes } from 'react';
import { IconName } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';
import DevText from '@components/Text';
import { useNavigate } from 'react-router-dom';
import {
    CenterBlock,
    LeftBlock,
    RightBlock,
    Wrapper,
} from '@containers/components/Layout/components/NextMenu/components/MenuItem/styled';

export interface MenuItemProps {
    // Renders item with 'active' background
    active?: boolean;

    // Icon on the left side of the item
    icon?: IconName;

    // Icon color override
    iconColor?: string;

    // Title
    title: string;

    // Draws muted-text label on the right side of the item
    label?: string;

    // When provided, `click` event will navigate to link
    link?: string;

    // When provided, `click` event will trigger this callback
    onClick?: () => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & MenuItemProps;

const MenuItem: React.FC<Props> = ({
    active = false,
    icon,
    iconColor,
    title,
    label,
    link,
    onClick,
}) => {
    const navigate = useNavigate();

    const left: React.ReactNode | null = icon ? (
        <LeftBlock className="mr-1">
            <Icon icon={icon} color={iconColor ? iconColor : '#abb3bf'} />
        </LeftBlock>
    ) : null;

    const center = (
        <CenterBlock className="mr-1">
            <DevText>{title}</DevText>
        </CenterBlock>
    );

    const right: React.ReactNode | null = label ? (
        <RightBlock>
            <DevText disabled>{label}</DevText>
        </RightBlock>
    ) : null;

    // Resolve click callback
    // ... do nothing by default
    let handleClick: undefined | (() => void) = undefined;
    if (link) {
        // Link provided, use menu item as navigation
        handleClick = () => navigate(link);
    } else if (onClick) {
        // Custom callback provided, pass click to it
        handleClick = onClick;
    }

    return (
        <Wrapper onClick={handleClick} style={{ backgroundColor: active ? '#2F343C' : '' }}>
            {left}{center}{right}
        </Wrapper>
    );
};

export default MenuItem;
