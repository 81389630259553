import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CreateSubscriptionInput, ProcessLatestSubscriptionInvoiceInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';

// Fetch payment account for workspace
export const fetchWorkspacePaymentAccount = domain.createEffect<{ workspaceId: ID }, PaymentAccountResource>({
    name: 'fetchWorkspacePaymentAccount',
    handler: async ({ workspaceId }) => {
        return await repository.paymentAccount().getPaymentAccountAssociatedWithWorkspace(workspaceId);
    },
});

// Fetch payment account for user
export const fetchUserPaymentAccount = domain.createEffect<{ userId: ID }, PaymentAccountResource>({
    name: 'fetchUserPaymentAccount',
    handler: async ({ userId }) => {
        return await repository.paymentAccount().getPaymentAccountAssociatedWithUser(userId);
    },
});

// Fetch payment methods
export const fetchPaymentMethods = domain.createEffect<ID, CollectionResource<PaymentMethodResource, {}>>({
    name: 'fetchPaymentMethods',
    handler: async (paymentAccountId) => {
        return await repository.paymentMethod().get(paymentAccountId);
    },
});

// Get list of subscription plans
export const fetchSubscriptionPlans = domain.createEffect<PlanType, CollectionResource<PlanResource, {}>>({
    name: 'fetchSubscriptionPlans',
    handler: async (planType) => {
        return await repository.subscriptionPlan().get(planType);
    },
});

// Create new subscription
export const createSubscription = domain.createEffect<CreateSubscriptionInput, SubscriptionRef>({
    name: 'createSubscription',
    handler: async (input) => {
        return await repository.subscription().create(input);
    },
});

// Process latest subscription invoice
export const processLatestSubscription = domain.createEffect<
    { subscriptionId: ID; input: ProcessLatestSubscriptionInvoiceInput },
    SubscriptionRef
>({
    name: 'processLatestSubscription',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().processLatestInvoice(subscriptionId, input);
    },
});
