import moment from 'moment';
import { Intent, Toaster } from '@blueprintjs/core';
import { teamApi } from '../../store/apis';
import { archiveTeam } from '../../store/effects';

const useArchiveTeam = () => {
    const handleArchiveTeam = async ({ teamId }: { teamId: ID }) => {
        try {
            const archiveTeamResponse = await archiveTeam(teamId);

            const momentNowUtc = moment().utc().format();
            teamApi.archiveTeam({
                archivedAt: momentNowUtc,
            });
        } catch (e) {
            throw e;
        }
    };

    return { archiveTeam: handleArchiveTeam };
};

export default useArchiveTeam;
