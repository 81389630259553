import { ButtonProps, Button } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';

export interface FileUploadButtonProps {
    multiple?: boolean;
    extensionsToAccept?: string[];
    onAttachFiles: (files: FileWithPath[]) => any;
}

export type Props = ButtonProps & FileUploadButtonProps;

const FileUploadButton: React.FC<Props> = ({
    multiple = false,
    onAttachFiles,
    extensionsToAccept = [],
    children,
    className,
    ...buttonProps
}) => {
    const onDrop = useCallback((acceptedFiles: File[]) => onAttachFiles(acceptedFiles), [onAttachFiles]);

    const { getRootProps } = useDropzone({
        multiple,
        accept: extensionsToAccept.length > 0 ? { accept: extensionsToAccept } : undefined,
        onDrop,
    });

    return (
        <div className={className ?? ''} {...getRootProps()}>
            <Button {...buttonProps}>{children}</Button>
        </div>
    );
};

export default FileUploadButton;
