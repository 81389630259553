import { Card, Colors, Icon, Intent, Tag, Tooltip } from '@blueprintjs/core';
import AvatarStack from '@components/AvatarStack';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import DeadlineTag from '@containers/components/TaskDeadlineTag';
import TaskStateTag from '@containers/components/TaskStateTag';
import { Endpoints, imageHashPreview } from '@data/consts';
import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskListItemResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface TaskCardProps {
    task: TaskListItemResource;
}

export type Props = TaskCardProps & HTMLAttributes<HTMLDivElement>;

const TaskCard: React.FC<Props> = ({ task, style, ...props }) => {
    const renderTags = () => {
        const tags: React.ReactNode[] = [];

        // Add archive tag
        if (task.archived_at !== null) {
            tags.push(
                <Tag minimal icon="trash" intent={Intent.DANGER}>
                    Archived
                </Tag>
            );

            return tags;
        }

        // Add backlog tag
        if (task.queue === TaskQueue.BACKLOG) {
            tags.push(
                <Tag large={false} minimal className="mr-1">
                    Queued
                </Tag>
            );
        }

        // Add paused tag
        if (task.paused_at !== null) {
            tags.push(
                <Tag minimal icon="pause" intent={Intent.WARNING}>
                    Paused
                </Tag>
            );
        }

        // Add state tag
        if (task.paused_at === null && task.queue !== TaskQueue.BACKLOG) {
            // Not paused AND not in backlog
            tags.push(<TaskStateTag large={false} withCircle={false} state={task.state} />);
        }

        return tags;
    };

    const renderPriorityFlag = () => {
        if (!task.is_priority_elevated) {
            // Normal priority
            return null;
        }

        return <Icon icon="generate" intent={Intent.DANGER} />;
    };

    const renderCategory = () => {
        return (
            <Tooltip content={<DevText inline>Delivery: {task.category.average_delivery_days} day(s)</DevText>}>
                <DevText inline muted className="mr-2">
                    <Icon className="mr-1" icon="new-grid-item" color={Colors.GRAY2} />
                    {task.category.title}
                </DevText>
            </Tooltip>
        );
    };

    const renderTitle = () => {
        let taskLink = Endpoints.TASK_MESSAGES;

        // If task delivered or staff is not check task information yet then go to information page
        if ([TaskState.DRAFT, TaskState.PUBLISHED, TaskState.DELIVERED].includes(task.state)) {
            taskLink = Endpoints.TASK_INFORMATION;
        }

        return (
            <RouterLink color={Colors.WHITE} to={taskLink.replace(':taskId', task.id)}>
                <Heading type="h4">{task.title}</Heading>
            </RouterLink>
        );
    };

    const renderClientInformation = () => {
        let linkText = `${task.user.first_name} ${task.user.last_name}`;

        // If company name exist, then use it as a text for a link
        if (task.user.company_name.trim().length !== 0) {
            linkText = task.user.company_name;
        }

        // Client has not licences
        if (task.user.licences.length === 0) {
            return (
                <RouterLink
                    className="mr-1"
                    to={Endpoints.CLIENT_TASKS.replace(':clientId', task.user.id)}
                    color={Colors.GRAY2}
                    onClick={(e) => e.stopPropagation()}
                >
                    {linkText}
                </RouterLink>
            );
        }

        // Get client's first licence in array
        const clientLicence = task.user.licences[0];

        return (
            <div className="mr-1">
                <RouterLink
                    to={Endpoints.CLIENT_TASKS.replace(':clientId', task.user.id)}
                    onClick={(e) => e.stopPropagation()}
                    color={clientLicence.plan.color}
                >
                    {linkText}
                </RouterLink>

                {/* TODO: do pausing whenever it's implemented on backend */}
                {/* <SubscriptionPausedTag className="mr-1" restoreAt="2023-07-06T17:53:23Z" /> */}
            </div>
        );
    };

    const renderIcons = () => {
        const icons: { id: number; tooltipText: string; icon: React.ReactNode }[] = [];

        // Check if task was published
        if (task.publish) {
            // Task published via payment
            if (task.publish.type === TaskPublishType.PAYMENT) {
                icons.push({
                    id: 0,
                    tooltipText: 'Pay as you go',
                    icon: <Icon className="mr-1" icon="bank-account" color={Colors.GRAY2} />,
                });
            }

            // Task published via credits
            if (task.publish.type === TaskPublishType.CREDIT) {
                icons.push({
                    id: 1,
                    tooltipText: 'Paid via credits',
                    icon: <Icon className="mr-1" icon="layout-circle" color={Colors.GRAY2} />,
                });
            }
        }

        // TODO: add check for attached addons whenever it's done on backend
        // icons.push({
        //     id: 2,
        //     tooltipText: 'Has addons',
        //     icon: <Icon className="mr-1" icon={'geofence'} color="#A7A7A7" />,
        // });

        if (icons.length === 0) {
            return null;
        }

        return (
            <div>
                {icons.map(({ id, tooltipText, icon }) => (
                    <Tooltip key={id} content={tooltipText}>
                        {icon}
                    </Tooltip>
                ))}
            </div>
        );
    };

    const renderLastActivity = () => {
        // TODO: get activity user information
        // TODO: implement whenever done on backend

        // const activityUser = task.activity_user;

        // if (activityUser === null) {
        //     return null;
        // }

        // const { first_name, last_name } = activityUser.public_profile;

        return (
            <DevText inline className="ml-auto">
                <Tooltip
                    content={
                        <DevText inline>
                            {moment(task.state_latest_transition_at).format('D MMM, YYYY - HH:mm')}
                        </DevText>
                    }
                >
                    <DevText inline className="ml-auto">
                        <Icon className="mr-1" icon="walk" />
                        Last activity: <strong>{moment(task.state_latest_transition_at).fromNow()}</strong>
                    </DevText>
                </Tooltip>
            </DevText>
        );
    };

    const renderParticipants = () => {
        if (task.participants.length === 0) {
            return (
                <Tag large minimal icon="warning-sign">
                    No participants assigned
                </Tag>
            );
        }

        return (
            <AvatarStack
                tight={false}
                avatars={task.participants.map((p) => ({
                    id: p.user.id,
                    name: `${p.user.first_name} ${p.user.last_name}`,
                    src: p.user.image_hash
                        ? imageHashPreview.userImage(p.user.image_hash, ImageHashPreviewSize.SM)
                        : null,
                }))}
            />
        );
    };

    const renderUnresolvedCommentsCounter = () => {
        //TODO: implement whenever done on backend
        const amountOfUnresolvedComments = 2;

        return (
            <DevText inline>
                <Icon
                    icon="chat"
                    className="mr-small"
                    color={amountOfUnresolvedComments > 0 ? Colors.BLUE2 : Colors.GRAY2}
                />
                {amountOfUnresolvedComments}
            </DevText>
        );
    };

    const renderDeadlineTag = () => {
        return <DeadlineTag className="mr-2" deadlineAt={task.deadline_at} finalizedAt={task.finalized_at} />;
    };

    return (
        <Card style={{ height: '100%', ...style }} {...props}>
            <Flex fullHeight direction="column" align="stretch" justify="space-between">
                <div>
                    <Flex className="mb-2" direction="row" align="center" justify="space-between">
                        <Flex direction="row" flexWrap="wrap" className="mr-1">
                            {renderTags()}
                        </Flex>
                        {renderPriorityFlag()}
                    </Flex>

                    <div className="mb-1">{renderTitle()}</div>
                    <div className="mb-1">{renderCategory()}</div>

                    <Flex className="mb-1" align="flex-start" justify="space-between">
                        {renderClientInformation()}
                        {renderIcons()}
                    </Flex>
                </div>

                <div>
                    <div className="mb-1">{renderLastActivity()}</div>
                    <div className="mb-2">{renderParticipants()}</div>

                    <Flex align="center" justify="space-between">
                        {renderDeadlineTag()}
                        {/* {renderUnresolvedCommentsCounter()} */}
                    </Flex>
                </div>
            </Flex>
        </Card>
    );
};

export default TaskCard;
