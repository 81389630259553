import repository from 'dy-frontend-http-repository/lib/repository';
import { SwapTaskQueueInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import domain from './domain';
import { TaskRefList } from 'dy-frontend-http-repository/lib/modules/Task/refs';

// Swap task
export const swapTaskQueue = domain.createEffect<SwapTaskQueueInput, TaskRefList>({
    name: 'swapTaskQueue',
    handler: async (input) => {
        return await repository.task().swapQueue(input);
    },
});
