import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import {
    TaskClientFeedbackSummaryResource,
    TaskClientFeedbackResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
// Get task client feedback summary
export const fetchTaskClientFeedback = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: TaskClientFeedbackFilterInput },
    CollectionResource<TaskClientFeedbackResource, {}>
>({
    name: 'fetchTaskClientFeedback',
    handler: async ({ pagination, filter }) => {
        return await repository.taskClientFeedback().getList(pagination, filter);
    },
});

// Get task client feedback list
export const fetchTaskClientFeedbackSummary = domain.createEffect<
    { filter: TaskClientFeedbackFilterInput },
    TaskClientFeedbackSummaryResource
>({
    name: 'fetchTaskClientFeedbackSummary',
    handler: async ({ filter }) => {
        return await repository.taskClientFeedback().getSummary(filter);
    },
});
