import styled, { css } from 'styled-components';
import { Card, Colors } from '@blueprintjs/core';

export const IconCard = styled(Card)<{ $active: boolean }>`
    &&& {
        ${({ $active }) =>
            $active &&
            css`
                box-shadow: 0 0 0 1px ${Colors.GREEN4};
            `}
    }
`;
