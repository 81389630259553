import React, { HTMLAttributes, forwardRef } from 'react';
import { StyledBox } from './styled';

export interface BoxProps {
    position?: 'static' | 'relative' | 'absolute';
    zIndex?: number;
    top?: string;
    right?: string;
    left?: string;
    bottom?: string;
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    minWidth?: string;
    minHeight?: string;
    borderRadius?: string;
    borderTopLeftRadius?: string;
    borderTopRightRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
    backgroundColor?: string;
    backgroundColorOnHover?: string;
    padding?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingRight?: string;
    paddingLeft?: string;
    margin?: string;
    marginTop?: string;
    marginBottom?: string;
    marginRight?: string;
    marginLeft?: string;
    borderColor?: string;
    borderSize?: number;
    cursor?: 'default' | 'pointer' | 'move';
}

export type Props = HTMLAttributes<HTMLDivElement> & BoxProps;

const Box = forwardRef<HTMLDivElement, Props>(
    (
        {
            position,
            zIndex,
            top,
            right,
            bottom,
            left,
            width,
            height,
            maxWidth,
            maxHeight,
            minWidth,
            minHeight,
            borderRadius,
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            backgroundColor,
            backgroundColorOnHover,
            padding,
            paddingTop,
            paddingBottom,
            paddingRight,
            paddingLeft,
            margin,
            marginTop,
            marginBottom,
            marginRight,
            marginLeft,
            borderColor,
            borderSize,
            cursor,
            ...props
        },
        ref
    ) => {
        return (
            <StyledBox
                $position={position}
                $zIndex={zIndex}
                $top={top}
                $right={right}
                $bottom={bottom}
                $left={left}
                $width={width}
                $height={height}
                $maxWidth={maxWidth}
                $maxHeight={maxHeight}
                $minHeight={minHeight}
                $minWidth={minWidth}
                $borderRadius={borderRadius}
                $borderTopLeftRadius={borderTopLeftRadius}
                $borderTopRightRadius={borderTopRightRadius}
                $borderBottomLeftRadius={borderBottomLeftRadius}
                $borderBottomRightRadius={borderBottomRightRadius}
                $backgroundColor={backgroundColor}
                $backgroundColorOnHover={backgroundColorOnHover}
                $padding={padding}
                $paddingTop={paddingTop}
                $paddingBottom={paddingBottom}
                $paddingRight={paddingRight}
                $paddingLeft={paddingLeft}
                $margin={margin}
                $marginTop={marginTop}
                $marginBottom={marginBottom}
                $marginRight={marginRight}
                $marginLeft={marginLeft}
                $borderColor={borderColor}
                $borderSize={borderSize}
                $cursor={cursor}
                ref={ref}
                {...props}
            />
        );
    }
);

export default Box;
