import repository from 'dy-frontend-http-repository/lib/repository';
import {
    PublishTaskPublishCreditInput,
    PublishTaskPublishManualInput,
    PublishTaskPublishPaymentInput,
    PublishTaskPublishQuotaInput,
} from 'dy-frontend-http-repository/lib/modules/TaskPublish/inputs';
import { TaskPublishRef } from 'dy-frontend-http-repository/lib/modules/TaskPublish/refs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { QuotaUsageFilterInput } from 'dy-frontend-http-repository/lib/modules/QuotaUsage/inputs';
import { QuotaUsageResource } from 'dy-frontend-http-repository/lib/modules/QuotaUsage/resources';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { CreditWalletResource } from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import domain from './domain';
import { ComputedLicenceResource } from 'dy-frontend-http-repository/lib/modules/ComputedLicence/resources';

// Fetch task category
export const fetchTaskCategory = domain.createEffect<ID, TaskCategoryResource>({
    name: 'fetchTaskCategory',
    handler: async (taskCategoryId) => {
        return await repository.taskCategory().getById(taskCategoryId);
    },
});

// // Fetch client payment account
// export const fetchClientPaymentAccount = domain.createEffect<ID, PaymentAccountResource>({
//     name: 'fetchClientPaymentAccount',
//     handler: async (userId) => {
//         return await repository.paymentAccount().getPaymentAccountAssociatedWithUser(userId);
//     },
// });

// Fetch client credit wallet
export const fetchClientCreditWallet = domain.createEffect<ID, CreditWalletResource>({
    name: 'fetchClientCreditWallet',
    handler: async (userId) => {
        return await repository.creditWallet().getForUser(userId);
    },
});

// // Fetch client payment methods
// export const fetchClientPaymentMethods = domain.createEffect<ID, CollectionResource<PaymentMethodResource, {}>>({
//     name: 'fetchClientPaymentMethods',
//     handler: async (paymentAccountId) => {
//         return await repository.paymentMethod().get(paymentAccountId);
//     },
// });

// Fetch client computed licence
export const fetchClientComputedLicence = domain.createEffect<ID, ComputedLicenceResource>({
    name: 'fetchClientComputedLicence',
    handler: async (userId) => {
        return await repository.computedLicence().getForUser(userId);
    },
});

// Fetch client quota usage
export const fetchClientQuotaUsage = domain.createEffect<
    { clientId: ID; filter: QuotaUsageFilterInput },
    CollectionResource<QuotaUsageResource, {}>
>({
    name: 'fetchClientQuotaUsage',
    handler: async ({ clientId, filter }) => {
        return await repository.quotaUsage().get(clientId, filter);
    },
});

// Publish task via manual
export const publishTaskViaManual = domain.createEffect<PublishTaskPublishManualInput, TaskPublishRef>({
    name: 'publishTaskViaManual',
    handler: async (input) => {
        return await repository.taskPublish().publishViaManual(input);
    },
});

// Publish task via payment
export const publishTaskViaPayment = domain.createEffect<PublishTaskPublishPaymentInput, TaskPublishRef>({
    name: 'publishTaskViaPayment',
    handler: async (input) => {
        return await repository.taskPublish().publishViaPayment(input);
    },
});

// Publish task via quota
export const publishTaskViaQuota = domain.createEffect<PublishTaskPublishQuotaInput, TaskPublishRef>({
    name: 'publishTaskViaQuota',
    handler: async (input) => {
        return await repository.taskPublish().publishViaQuota(input);
    },
});

// Publish task via credit
export const publishViaCredit = domain.createEffect<PublishTaskPublishCreditInput, TaskPublishRef>({
    name: 'publishViaCredit',
    handler: async (input) => {
        return await repository.taskPublish().publishViaCredit(input);
    },
});

// Attempt to finalize publish
export const attemptToFinalizePublish = domain.createEffect<ID, TaskPublishRef>({
    name: 'attemptToFinalizePublish',
    handler: async (taskPublishId) => {
        return await repository.taskPublish().finalizePublish(taskPublishId);
    },
});
