import { v4 as uuidv4 } from 'uuid';
import '../CreateSubscriptionModal/store/init';
import '../UpdateSubscriptionItemsModal/store/init';
import '../UpdateSubscriptionPaymentMethodModal/store/init';
import '../UpdateSubscriptionSingleItemModal/store/init';
import { closeAllModals, closeModal, openModalEvent } from './events';
import { $stack } from './states';

$stack.on(openModalEvent, (prevValue, newModalData) => {
    return [
        ...prevValue,
        {
            id: uuidv4(),
            ...newModalData,
        },
    ];
});

$stack.on(closeModal, (prevValue, modalIdToClose) => {
    return prevValue.filter(({ id: modalId }) => modalId !== modalIdToClose);
});

$stack.on(closeAllModals, () => []);
