import { GetValidatorFunction } from '@app/hooks/validation/types';
import { FileUtils } from 'dy-frontend-shared/lib/utils';

const defaultFileSizeMax = 125000000; // 125 MB
const getDefaultFileSizeWentBeyondMaxSizeMessage = (maxSize: number, file: File) =>
    `Max file size is "${FileUtils.getFormattedFileSize(maxSize)}", but file "${
        file.name
    }" have size of "${FileUtils.getFormattedFileSize(file.size)}"`;
const getFileMaxSizeValidator: GetValidatorFunction<
    {
        maxSize: number;
        fileSizeWentBeyondMaxSizeMessageFormatter?: (maxSize: number, file: File) => string;
    },
    File
> = (
    options = {
        maxSize: defaultFileSizeMax,
        fileSizeWentBeyondMaxSizeMessageFormatter: getDefaultFileSizeWentBeyondMaxSizeMessage,
    }
) => {
    const { maxSize, fileSizeWentBeyondMaxSizeMessageFormatter } = options;

    return async (file) => {
        if (file.size <= maxSize) {
            return null;
        } else {
            if (fileSizeWentBeyondMaxSizeMessageFormatter) {
                return fileSizeWentBeyondMaxSizeMessageFormatter(maxSize, file);
            } else {
                return getDefaultFileSizeWentBeyondMaxSizeMessage(maxSize, file);
            }
        }
    };
};

export default getFileMaxSizeValidator;
