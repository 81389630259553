import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Button, Classes, Icon, Intent, Spinner } from '@blueprintjs/core';
import { BrandProfileAssetFilterInput } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/inputs';
import { openModal } from '@containers/modals/store/events';
import { fetchBrandProfileAssets, removeBrandProfileAsset } from '../../store/effects';
import { $brandProfile, $brandProfileAssets } from '../../store/states';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import { $permissions } from '@app/containers/store/states';
import Heading from '@components/Heading';
import AddBrandProfileAssetsModal from '../../modals/AddBrandProfileAssetsModal';
import { BrandProfileAssetCategory } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/enums';
import { BrandProfileAssetListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import FileTilePreview from '@app/components/FileTilePreview';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import FileViewerModal, { FileViewerModalProps } from '@app/containers/modals/FileViewerModal';
import { brandProfileAssetsApi } from '../../store/apis';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { BrandProfileAssetPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const BrandAssets: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const { brandProfileId } = useParams() as { brandProfileId: ID };

    const brandProfile = useStore($brandProfile);
    const brandProfileAssets = useStore($brandProfileAssets);

    const [removingAssetId, setRemovingAssetId] = useState<ID | null>(null);

    // Root permissions on brand profile asset
    const isAssetRootPermission = permissions.has(BrandProfileAssetPermission.ROOT);

    const handleFetchBrandProfileAsset = (filter: BrandProfileAssetFilterInput) => {
        fetchBrandProfileAssets(filter);
    };

    useEffect(() => {
        handleFetchBrandProfileAsset({
            brand_profile_id: brandProfileId,
            is_archived: '0',
        });

        // eslint-disable-next-line
    }, []);

    const getBrandProfileAssetsByCategory = (category: BrandProfileAssetCategory) => {
        if (!brandProfileAssets) {
            return [];
        }

        return brandProfileAssets.items.filter((asset) => asset.category === category);
    };

    const renderCreateButton = () => {
        const isAllowed = isAssetRootPermission || permissions.has(BrandProfileAssetPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                className="ml-1"
                icon="add"
                intent={Intent.PRIMARY}
                onClick={() => openModal({ ModalComponent: AddBrandProfileAssetsModal })}
            >
                Add new
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <>
                <Flex align="center" justify="space-between">
                    <Heading type="h4">Brand Assets</Heading>
                    {renderCreateButton()}
                </Flex>
            </>
        );
    };

    const renderAssetsByCategory = (title: string, assets: BrandProfileAssetListItemResource[]) => {
        if (assets.length === 0) {
            return null;
        }

        const renderViewAssetButton = (index: number) => {
            const handleViewBrandProfileAsset = () => {
                openModal<FileViewerModalProps>({
                    ModalComponent: FileViewerModal,
                    data: {
                        currentFileIndex: index,
                        files: assets
                            .filter((f) => f.file !== null)
                            .map((f) => ({
                                src: f.file!.url,
                                extension: f.file!.extension,
                                name: f.file!.original_name,
                                size: f.file!.size,
                            })),
                    },
                });
            };

            return <Button minimal icon="eye-open" onClick={handleViewBrandProfileAsset} />;
        };

        const renderRemoveAssetButton = (assetId: ID) => {
            const isAllowed = isAssetRootPermission || permissions.has(BrandProfileAssetPermission.ARCHIVE);
            if (!isAllowed) {
                return null;
            }

            const handleRemoveAsset = async () => {
                try {
                    setRemovingAssetId(assetId);
                    await removeBrandProfileAsset(assetId);
                    brandProfileAssetsApi.removeBrandProfileAsset(assetId);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                } finally {
                    setRemovingAssetId(null);
                }
            };

            return (
                <ConfirmationPopover
                    className="ml-small"
                    title="Do you want to remove asset?"
                    description="When confirmed, asset will be removed"
                    actions={[
                        <Button intent={Intent.DANGER} className={Classes.POPOVER_DISMISS} onClick={handleRemoveAsset}>
                            Yes, remove
                        </Button>,
                    ]}
                >
                    <Button minimal loading={removingAssetId !== null && removingAssetId === assetId} icon="trash"
                            intent={Intent.DANGER} />
                </ConfirmationPopover>
            );
        };

        return (
            <div className="mb-4">
                <Heading className="mb-1" type="h4">
                    {title}
                </Heading>

                {assets.map((asset, index) => (
                    <FileTilePreview
                        className={index === 0 ? '' : 'mt-1'}
                        key={asset.id}
                        fileName={asset.title}
                        src={asset.file?.preview_url ?? asset.file?.url}
                        fileSize={asset.file?.size ?? 0}
                        extension={FileUtils.getFileExtension(asset.file?.extension)}
                        actions={
                            <Flex direction="row" align="center">
                                {renderViewAssetButton(index)}
                                {renderRemoveAssetButton(asset.id)}
                            </Flex>
                        }
                    />
                ))}
            </div>
        );
    };

    const renderAssets = () => {
        // Client brand profiles were NOT fetched yet
        if (!brandProfileAssets) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client brand profiles were fetched, but where are no brand profiles existed for this client
        if (brandProfileAssets.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No brand profile assets were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <div>
                {renderAssetsByCategory('Logo', getBrandProfileAssetsByCategory(BrandProfileAssetCategory.LOGO))}
                {renderAssetsByCategory('Image', getBrandProfileAssetsByCategory(BrandProfileAssetCategory.IMAGE))}
                {renderAssetsByCategory(
                    'Style Guides',
                    getBrandProfileAssetsByCategory(BrandProfileAssetCategory.STYLE_GUIDE),
                )}
                {renderAssetsByCategory(
                    'Templates',
                    getBrandProfileAssetsByCategory(BrandProfileAssetCategory.TEMPLATE),
                )}
                {renderAssetsByCategory('Other', getBrandProfileAssetsByCategory(BrandProfileAssetCategory.OTHER))}
            </div>
        );
    };

    return (
        <div {...props}>
            <div className="mb-1">{renderHeader()}</div>
            {renderAssets()}
        </div>
    );
};

export default BrandAssets;
