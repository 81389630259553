import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import { Alignment, Button, Card, Divider, Elevation, Icon, Intent, RadioCard, RadioGroup } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import Overlay from '@components/Overlay';
import { ChangeUserPasswordMethod } from './enums';
import ChangePasswordForm from './components/ChangePasswordForm';
import ResetLinkForm from './components/ResetLinkForm';
import { ChangeUserPasswordMethod } from '@containers/components/UserSettings/modals/ChangeUserPasswordModal/enums';

export interface ChangeUserPasswordModalProps {
    userId: ID;
    workspaceId: ID | null;
    userEmail: string;
    isResetAllowed: boolean;
    isForcedAllowed: boolean;
}

type Props = ModalProps<ChangeUserPasswordModalProps>;

// TODO: Hints on password/repeat-password field?
const ChangeUserPasswordModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    // Selected method
    const [method, setMethod] = useState<ChangeUserPasswordMethod>(
        data && data.isForcedAllowed ?
            ChangeUserPasswordMethod.Immediate: ChangeUserPasswordMethod.ResetLink
    );

    const handleModalClose = () => {
        if (isProcessing) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return;
        }

        let heading = '';
        switch (method) {
            case ChangeUserPasswordMethod.Immediate:
                heading = 'User password successfully changed';
                break;
            case ChangeUserPasswordMethod.ResetLink:
                heading = `Password link was successfully sent`;
                break;
            default:
                heading = 'Success';
        }

        let caption = '';
        switch (method) {
            case ChangeUserPasswordMethod.Immediate:
                caption = 'User can now use new password during login';
                break;
            case ChangeUserPasswordMethod.ResetLink:
                caption = `Password reset link was successfully sent to email: ${data.userEmail}`;
                break;
            default:
                caption = 'Password successfully changed';
        }

        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">{heading}</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    {caption}
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderChangeUserEmailMethodForm = () => {
        const renderHeader = () => {
            return (
                <>
                    <Heading type="h5" className="mb-2">
                        How to change password?
                    </Heading>
                    <RadioGroup
                        disabled={isProcessing}
                        selectedValue={method}
                        onChange={(e) => setMethod(e.currentTarget.value as ChangeUserPasswordMethod)}
                        className="control-card-group-row"
                    >
                        {data.isForcedAllowed && (
                            <RadioCard
                                elevation={Elevation.TWO}
                                alignIndicator={Alignment.RIGHT}
                                label="Immediately"
                                value={ChangeUserPasswordMethod.Immediate}
                            >
                                <DevText className="mb-1">Force apply</DevText>
                                <DevText muted>Overwrite previous password with new one</DevText>
                            </RadioCard>
                        )}


                        <RadioCard
                            elevation={Elevation.TWO}
                            alignIndicator={Alignment.RIGHT}
                            label="With Verification"
                            value={ChangeUserPasswordMethod.ResetLink}
                        >
                            <DevText className="mb-1">Reset Link</DevText>
                            <DevText muted>Send password reset link to current user email</DevText>
                        </RadioCard>
                    </RadioGroup>
                </>
            );
        };

        const renderForm = () => {
            switch (method) {
                case ChangeUserPasswordMethod.Immediate:
                    return (
                        <ChangePasswordForm
                            userId={data.userId}
                            onCancel={() => closeModal?.()}
                            onChangePasswordSuccess={() => setIsCompleted(true)}
                            onProcessingFlagChange={setIsProcessing}
                        />
                    );
                case ChangeUserPasswordMethod.ResetLink:
                    return (
                        <ResetLinkForm
                            workspaceId={data.workspaceId}
                            email={data.userEmail}
                            onCancel={() => closeModal?.()}
                            onSendPasswordResetLinkSendSuccess={() => setIsCompleted(true)}
                            onProcessingFlagChange={setIsProcessing}
                        />
                    );
            }
        };

        return (
            <div>
                <div className="mb-3">{renderHeader()}</div>
                {renderForm()}
            </div>
        );
    };

    const renderContent = () => {
        if (isCompleted) {
            return renderProcessComplete();
        }

        return renderChangeUserEmailMethodForm();
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Change password
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isProcessing} />
                </Flex>

                <Divider className="mb-2" />

                {renderContent()}
            </Card>
        </Overlay>
    );
};

export default ChangeUserPasswordModal;
