import { createApi } from 'effector';
import { UpdateUserNoteInformationInput } from 'dy-frontend-http-repository/lib/modules/UserNote/inputs';
import { UserNoteListItemResource } from 'dy-frontend-http-repository/lib/modules/UserNote/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { $clientNotes } from './states';
import { amountOfNotesOnPage } from '../consts';

// API to manage client notes
export const clientNotesApi = createApi($clientNotes, {
    // Add at tail after successful fetch
    addAtTailAfterSuccessfulFetch: (
        store,
        payload: { clientNotes: CollectionResource<UserNoteListItemResource, unknown> }
    ) => {
        // Skip if client notes were NOT fetched yet
        if (!store) {
            return { ...payload.clientNotes };
        }

        return {
            ...store,
            cursor: payload.clientNotes.cursor,
            items: [...store.items, ...payload.clientNotes.items],
        };
    },

    // Add at tail after successful fetch
    addAtStartAfterSuccessfulCreation: (
        store,
        payload: { clientNotes: CollectionResource<UserNoteListItemResource, unknown> }
    ) => {
        const newNoteItems = [...payload.clientNotes.items].reverse();

        // Skip if client notes were NOT fetched yet
        if (!store) {
            // This happens whenever first note is created

            const { cursor, ...propsToSpread } = payload.clientNotes;

            // Create new cursor
            const newCursor: CollectionResource<UserNoteListItemResource, unknown>['cursor'] = {
                ...cursor,
                direction: 'desc',
                next_cursor_start: newNoteItems[newNoteItems.length - 1].id,
                has_more: true,
                limit: amountOfNotesOnPage,
            };

            return {
                ...propsToSpread,
                cursor: newCursor,
                items: newNoteItems,
            };
        }

        return {
            ...store,
            items: [...newNoteItems, ...store.items],
        };
    },

    // Update client note
    update: (store, payload: { clientNoteId: ID; input: UpdateUserNoteInformationInput }) => {
        // Skip if client notes were NOT fetched yet
        if (!store) {
            return store;
        }

        // Copy client notes
        const clientNoteItems = [...store.items];

        // Find client note item index in array
        const foundClientNoteItemIndex = clientNoteItems.findIndex(
            (clientNote) => clientNote.id === payload.clientNoteId
        );

        if (foundClientNoteItemIndex === -1) {
            return store;
        }

        // If client note index found, get client note at index
        const foundClientNote = clientNoteItems[foundClientNoteItemIndex];

        // Create updated client note
        const updatedClientNote: UserNoteListItemResource = {
            ...foundClientNote,
            ...payload.input,
        };

        // Replace draft task with updated one at found index
        clientNoteItems[foundClientNoteItemIndex] = updatedClientNote;

        return {
            ...store,
            items: clientNoteItems,
        };
    },

    // Remove client note
    remove: (store, payload: { clientNoteId: ID }) => {
        // Skip if client notes were NOT fetched yet
        if (!store) {
            return store;
        }

        // Copy client notes
        const clientNoteItems = [...store.items];

        // Find client note item index in array
        const foundClientNoteItemIndex = clientNoteItems.findIndex(
            (clientNote) => clientNote.id === payload.clientNoteId
        );

        if (foundClientNoteItemIndex === -1) {
            return store;
        }

        // Remove client note at found index
        clientNoteItems.splice(foundClientNoteItemIndex, 1);

        return {
            ...store,
            items: clientNoteItems,
        };
    },
});
