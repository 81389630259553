import { useCallback, useEffect, useRef, useState } from 'react';

// This is hooks which returns flag which states is user dragging something over or not
const useDraggingFlag = () => {
    const [dragCounter, setDragCounter] = useState<number>(0);

    const handleDragEnter = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            setDragCounter((prevValue) => prevValue + 1);
        },
        [dragCounter]
    );

    const handleDragLeave = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            setDragCounter((prevValue) => prevValue - 1);
        },
        [dragCounter]
    );

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            setDragCounter(0);
        },
        [dragCounter]
    );

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('drop', handleDrop);

        return () => {
            document.removeEventListener('dragover', handleDragEnter);
            document.removeEventListener('dragleave', handleDragLeave);
            document.removeEventListener('drop', handleDragLeave);
        };
    }, []);

    return {
        isDragging: dragCounter !== 0,
    };
};

export default useDraggingFlag;
