import React from 'react';
import { CardNumberElement, CardNumberElementProps } from '@stripe/react-stripe-js';
import { StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Colors } from '@blueprintjs/core';

export type Props = CardNumberElementProps;

const CardNumberInput: React.FC<Props> = ({ options, ...props }) => {
    const defaultOptions: StripeCardNumberElementOptions = {
        style: {
            base: {
                color: Colors.WHITE,
                '::placeholder': {
                    color: Colors.GRAY2,
                },
            },
            invalid: {
                color: Colors.RED2,
            },
        },
    };

    return <CardNumberElement options={{ ...defaultOptions, ...options }} {...props} />;
};

export default CardNumberInput;
