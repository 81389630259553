import Box from '@app/components/Box';
import Flex from '@app/components/Flex';
import InputGroupSpinnerElement from '@app/components/InputGroupSpinnerElement';
import { FormGroup, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import { Endpoints } from '@data/consts';
import { fetchCoreUser } from '@pages/CoreUsers/store/effects';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PlatformCoreUserFilterQueryMode } from './enums';

export interface PlatformTaskFilterQueryInputProps {
    loading: boolean;
    value: string;
    placeholder: string;
    shouldBeSearched: boolean;
    onChange: (e) => void;
    onSearch: (newSearch: string) => void;
}

export type Props = PlatformTaskFilterQueryInputProps & HTMLAttributes<HTMLDivElement>;

const PlatformTaskFilterQueryInput: React.FC<Props> = ({
    loading,
    value,
    shouldBeSearched,
    placeholder,
    onSearch,
    onChange,
    ...props
}) => {
    const navigate = useNavigate();

    const [mode, setMode] = useState(PlatformCoreUserFilterQueryMode.NONE);
    const [hint, setHint] = useState('');

    const handleAssignHint = () => {
        switch (mode) {
            case PlatformCoreUserFilterQueryMode.NONE:
                setHint(`Hint: type ‘#MemberId’ and press ‘enter’ to go directly to the member page`);
                break;
            case PlatformCoreUserFilterQueryMode.JUMP_TO_MEMBER:
                const trimmedValue = value.trim();
                const coreUserId = trimmedValue.slice(1);
                setHint(`Hint: press 'enter' to jump to #${coreUserId} member page`);
                break;
        }
    };

    const handleSetModeExecution = () => {
        switch (mode) {
            case PlatformCoreUserFilterQueryMode.JUMP_TO_MEMBER:
                const trimmedValue = value.trim();
                const coreUserId = trimmedValue.slice(1);
                fetchCoreUser(coreUserId)
                    .then(() => {
                        navigate(Endpoints.CORE_USER_TASKS.replace(':coreUserId', coreUserId));
                    })
                    .catch((e) => {
                        // TODO: handle error
                        console.error(e);
                    });
                break;
        }
    };

    const handleKeyPressed = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSetModeExecution();
        }
    };

    useEffect(() => {
        if (value.trim().length !== 0) {
            // Task ID - #NUMBER
            if (/^#\d+$/.test(value.trim())) {
                setMode(PlatformCoreUserFilterQueryMode.JUMP_TO_MEMBER);
            } else {
                setMode(PlatformCoreUserFilterQueryMode.NONE);
            }
        } else {
            setMode(PlatformCoreUserFilterQueryMode.NONE);
            setHint(`Hint: type ‘#MemberId’ and press ‘enter’ to go directly to member page`);
        }
    }, [mode, value]);

    useEffect(() => {
        if (shouldBeSearched && mode === PlatformCoreUserFilterQueryMode.NONE) {
            const timeoutId = setTimeout(() => {
                onSearch(value);
            }, 400);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        handleAssignHint();

        // eslint-disable-next-line
    }, [mode, value]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressed);

        return () => {
            document.removeEventListener('keydown', handleKeyPressed);
        };
    });

    const renderQueryInputElement = () => {
        if (loading) {
            return (
                <Box width="40px" height="41px">
                    <Flex fullWidth fullHeight align="center" justify="center">
                        <Spinner size={16} />
                    </Flex>
                </Box>
            );
        } else {
            return <Icon icon="search" size={16} />;
        }
    };

    return (
        <div {...props}>
            <FormGroup helperText={hint}>
                <InputGroup
                    large
                    placeholder={placeholder}
                    value={value}
                    leftElement={<InputGroupSpinnerElement loading={loading} />}
                    onChange={onChange}
                />
            </FormGroup>
        </div>
    );
};

export default PlatformTaskFilterQueryInput;
