import { TaskDeliverablesParsedInformation } from '@app/containers/pages/Task/types';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import domain from './domain';
import { resetApprovedTaskDeliverables, resetTaskDeliverablesTree } from './events';

// Approved task deliverables
export const $approvedTaskDeliverables = domain
    .createStore<CollectionResource<TaskDeliverableResource, {}> | null>(null)
    .reset(resetApprovedTaskDeliverables);

// Task deliverables tree
export const $taskDeliverablesTree = domain
    .createStore<TaskDeliverablesParsedInformation | null>(null)
    .reset(resetTaskDeliverablesTree);
