import { UserRole, UserType } from 'dy-frontend-http-repository/lib/data/enums';

const userRoleInformation: {
    [role in UserRole]: {
        label: string;
        userType: UserType;
    };
} = {
    // Unknown
    [UserRole.GHOST]: {
        label: 'Unknown user role',
        userType: UserType.CORE,
    },

    // Root
    [UserRole.CORE_ROOT]: {
        label: 'System account',
        userType: UserType.CORE,
    },
    [UserRole.CORE_ADMIN]: {
        label: 'DotYeti Admin',
        userType: UserType.CORE,
    },
    [UserRole.CORE_PLATFORM_DEV]: {
        label: 'DotYeti Platform Developer',
        userType: UserType.CORE,
    },
    [UserRole.CORE_OPERATIONS_MANAGER]: {
        label: 'Operations Manager',
        userType: UserType.CORE,
    },
    [UserRole.CORE_ACCOUNT_MANAGER]: {
        label: 'Account Manager',
        userType: UserType.CORE,
    },
    [UserRole.CORE_HR]: {
        label: 'Human Resources',
        userType: UserType.CORE,
    },
    [UserRole.CORE_PROJECT_MANAGER]: {
        label: 'Project Manager',
        userType: UserType.CORE,
    },
    [UserRole.CORE_ART_DIRECTOR]: {
        label: 'Art Director',
        userType: UserType.CORE,
    },
    [UserRole.CORE_ASSISTANT_ART_DIRECTOR]: {
        label: 'Assistant Art Director',
        userType: UserType.CORE,
    },
    [UserRole.CORE_DESIGNER]: {
        label: 'Designer',
        userType: UserType.CORE,
    },

    // Client
    [UserRole.CLIENT_STANDALONE]: {
        label: 'Client',
        userType: UserType.CLIENT,
    },

    // Workspace
    [UserRole.WORKSPACE_ADMIN]: {
        label: 'Workspace owner',
        userType: UserType.WORKSPACE,
    },
    [UserRole.WORKSPACE_MEMBER]: {
        label: 'Workspace member',
        userType: UserType.WORKSPACE,
    },
};

export default userRoleInformation;
