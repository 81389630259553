import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { openModal } from '@containers/modals/store/events';
import { $brandProfile } from '../../store/states';
import Flex from '@components/Flex';
import RichText from '@components/RichText';
import { $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import UpdateBrandProfileDescriptionModal, {
    UpdateBrandProfileDescriptionModalProps,
} from '../../modals/UpdateBrandProfileDescriptionModal';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

const Description: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    if (!brandProfile) {
        return null;
    }

    const renderDescriptionText = () => {
        if (brandProfile.description.trim().length === 0) {
            return <DevText muted>No description provided yet</DevText>;
        }

        return <RichText value={brandProfile.description} />;
    };

    const renderUpdateButton = () => {
        const isAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateBrandProfileDescriptionModalProps>({
                        ModalComponent: UpdateBrandProfileDescriptionModal,
                        data: {
                            brandProfileId: brandProfile.id,
                            description: brandProfile.description,
                        },
                    })
                }
            >
                Edit
            </Button>
        );
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Description</Heading>
                {renderUpdateButton()}
            </Flex>

            {renderDescriptionText()}
        </Card>
    );
};

export default Description;
