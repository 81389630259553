import domain from './domain';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/ClientSignup/refs';
import { SetupUserPasswordAuthInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';

// Setup password auth
export const setupUserPasswordAuth = domain.createEffect<
    {
        id: ID;
        input: SetupUserPasswordAuthInput;
    },
    UserRef
>({
    name: 'setupUserPasswordAuth',
    handler: async ({ id, input }) => {
        return await repository.user().setupPasswordAuth(id, input);
    },
});
