import { useState, useCallback } from 'react';
import { ValidatorResult, DefaultFormFieldProps, DefaultFormFieldReturnValue } from '../types';
import { checkValidators } from '../functions';

export interface UseCustomMultiSelectFormFieldProps<Item> {
    getSelectedItemIndex: (item: Item, items: Item[]) => number;
}

export interface UseCustomMultiSelectFormFieldReturnValue<Item> {
    handleChange: (item: Item) => void;
    handleUnselectItem: (item: Item) => void;
    handleSetValue: (items: Item[]) => void;
    handleClose: () => void;
}

export type ReturnValue<Item = any> = DefaultFormFieldReturnValue<Item[]> &
    UseCustomMultiSelectFormFieldReturnValue<Item>;
export type Props<Item> = DefaultFormFieldProps<Item[]> & UseCustomMultiSelectFormFieldProps<Item>;

function useCustomMultiSelectFormField<Item>({
    id,
    validators,
    initialValue,
    getSelectedItemIndex = (item, items) => items.findIndex((i) => i === item),
}: Props<Item>): ReturnValue<Item> {
    const [items, setItems] = useState<Item[]>(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleSetItems = useCallback(
        async (items: Item[]) => {
            setItems(items);
        },
        [validators]
    );

    const handleUnselectItem = useCallback(
        async (item: Item) => {
            const selectedItemIndex = getSelectedItemIndex(item, items);

            if (selectedItemIndex === -1) {
                return items;
            }

            const copiedArray = items.slice();
            copiedArray.splice(selectedItemIndex, 1);

            setItems(copiedArray);

            return copiedArray;
        },
        [items, validators]
    );

    const handleSelectItem = useCallback(
        async (item: Item) => {
            let updatedItems: Item[] = [];

            const selectedItemIndex = getSelectedItemIndex(item, items);

            if (selectedItemIndex !== -1) {
                return items;
            }

            updatedItems = [...items, item];
            setItems(updatedItems);

            return updatedItems;
        },
        [items, validators]
    );

    const handleChange = useCallback(
        async (item: Item) => {
            let updatedItems: Item[] = [];

            const selectedItemIndex = getSelectedItemIndex(item, items);
            if (selectedItemIndex === -1) {
                updatedItems = await handleSelectItem(item);
                setItems(updatedItems);
            } else {
                updatedItems = await handleUnselectItem(item);
            }

            setError(await checkValidators(updatedItems, validators));

            return updatedItems;
        },
        [items, validators]
    );

    const handleClose = useCallback(async () => {
        setError(await checkValidators(items, validators));
    }, [items, validators]);

    const hasError = useCallback(async () => {
        const err = await checkValidators(items, validators);
        setError(err);

        return !!err;
    }, [items, validators]);

    const reset = () => {
        setItems(initialValue);
    };

    return {
        id,
        value: items,
        error,
        reset,
        hasError,
        handleSetValue: handleSetItems,
        handleChange,
        handleUnselectItem,
        handleClose,
    };
}

export default useCustomMultiSelectFormField;
