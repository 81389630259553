import React, { HTMLAttributes } from 'react';

export default class ErrorBoundary extends React.Component<HTMLAttributes<HTMLDivElement>> {
    componentDidCatch(error) {
        // TODO: report to error API
        console.error(error);
    }

    render() {
        return this.props.children;
    }
}
