import repository from 'dy-frontend-http-repository/lib/repository';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    PickableTaskParticipantUserResource,
    TaskPublishResource,
    TaskResource,
    TaskStateTransitionResource,
} from 'dy-frontend-http-repository/lib/modules/Task/resources';
import {
    AssignTaskParticipantUsersInput,
    PickableTaskParticipantUserFilterInput,
    ReopenTaskInput,
    SetTaskDeadlineInput,
    TransitionTaskStateInput,
    UpdateTaskInformationInput,
} from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import domain from './domain';
import { TaskComplexityResource } from 'dy-frontend-http-repository/lib/modules/TaskComplexity/resources';
import { TaskComplexityFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskComplexity/inputs';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { UserOccupancyFilterInput } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/inputs';
import { AddonTaskFilterInput } from 'dy-frontend-http-repository/lib/modules/AddonTask/inputs';
import { AddonTaskResource } from 'dy-frontend-http-repository/lib/modules/AddonTask/resources';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { AddonTaskRef } from 'dy-frontend-http-repository/lib/modules/AddonTask/refs';

// Fetch task
export const fetchTask = domain.createEffect<ID, TaskResource>({
    name: 'fetchTask',
    handler: async (id) => {
        return await repository.task().getById(id);
    },
});

// Fetch task publish information
export const fetchTaskPublishInformation = domain.createEffect<ID, TaskPublishResource>({
    name: 'fetchTaskPublishInformation',
    handler: async (id) => {
        return await repository.task().getPublishInformation(id);
    },
});

// Fetch task sate transition log
export const fetchTaskStateTransitionLog = domain.createEffect<ID, CollectionResource<TaskStateTransitionResource, {}>>(
    {
        name: 'fetchTaskStateTransitionLog',
        handler: async (taskId) => {
            return await repository.task().getTaskStateTransitionLog(taskId);
        },
    }
);

// Transition task state
export const transitionTaskState = domain.createEffect<{ id: ID; input: TransitionTaskStateInput }, TaskRef>({
    name: 'transitionTaskState',
    handler: async ({ id, input }) => {
        return await repository.task().transitionState(id, input);
    },
});

// Update task information
export const updateTask = domain.createEffect<{ id: ID; input: UpdateTaskInformationInput }, TaskRef>({
    name: 'updateTask',
    handler: async ({ id, input }) => {
        return await repository.task().update(id, input);
    },
});

// Remove task
export const removeTask = domain.createEffect<ID, TaskRef>({
    name: 'removeTask',
    handler: async (taskId) => {
        return await repository.task().archive(taskId);
    },
});

// Restore task
export const restoreTask = domain.createEffect<ID, TaskRef>({
    name: 'restoreTask',
    handler: async (taskId) => {
        return await repository.task().restore(taskId);
    },
});

// Pause task
export const pauseTask = domain.createEffect<ID, TaskRef>({
    name: 'pauseTask',
    handler: async (taskId) => {
        return await repository.task().pause(taskId);
    },
});

// Resume task
export const resumeTask = domain.createEffect<ID, TaskRef>({
    name: 'resumeTask',
    handler: async (taskId) => {
        return await repository.task().resume(taskId);
    },
});

// Reopen task
export const reopenTask = domain.createEffect<{ taskId: ID; input: ReopenTaskInput }, TaskRef>({
    name: 'reopenTask',
    handler: async ({ taskId, input }) => {
        return await repository.task().reopen(taskId, input);
    },
});

// Set task deadline
export const setTaskDeadline = domain.createEffect<{ taskId: ID; input: SetTaskDeadlineInput }, TaskRef>({
    name: 'setTaskDeadline',
    handler: async ({ taskId, input }) => {
        return await repository.task().setDeadline(taskId, input);
    },
});

// Remove task deadline
export const removeTaskDeadline = domain.createEffect<ID, TaskRef>({
    name: 'removeTaskDeadline',
    handler: async (taskId) => {
        return await repository.task().removeDeadline(taskId);
    },
});

// Fetch task pickable participant users
export const fetchPickableTaskParticipantUsers = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: PickableTaskParticipantUserFilterInput },
    CollectionResource<PickableTaskParticipantUserResource, {}>
>({
    name: 'fetchPickableTaskParticipantUsers',
    handler: async ({ pagination, filter }) => {
        return await repository.task().getPickableParticipantUsers(pagination, filter);
    },
});

// Fetch user occupancy objects
export const fetchUserOccupancy = domain.createEffect<
    UserOccupancyFilterInput,
    CollectionResource<UserOccupancyResource, {}>
>({
    name: 'fetchUserOccupancy',
    handler: async (filter) => {
        return await repository.userOccupancy().get(filter);
    },
});

// Join task as participant
export const joinTaskAsParticipantUser = domain.createEffect<
    {
        taskId: ID;
    },
    TaskRef
>({
    name: 'joinTaskAsParticipantUser',
    handler: async ({ taskId }) => {
        return await repository.task().joinAsParticipant(taskId);
    },
});

// Assign users as a participant
export const assignTaskParticipantUsers = domain.createEffect<
    {
        taskId: ID;
        input: AssignTaskParticipantUsersInput;
    },
    TaskRef
>({
    name: 'assignTaskParticipantUsers',
    handler: async ({ taskId, input }) => {
        return await repository.task().assignParticipantUsers(taskId, input);
    },
});

// Remove user from task participants
export const removeTaskParticipant = domain.createEffect<{ taskId: ID; userId: ID }, TaskRef>({
    name: 'removeTaskParticipant',
    handler: async ({ taskId, userId }) => {
        return await repository.task().removeParticipation(taskId, userId);
    },
});

// Fetch team list
export const fetchTeams = domain.createEffect<void, CollectionResource<TeamResource, {}>>({
    name: 'fetchTeams',
    handler: async () => {
        return await repository.team().get();
    },
});

// Fetch task complexity
export const fetchTaskComplexity = domain.createEffect<
    TaskComplexityFilterInput,
    CollectionResource<TaskComplexityResource, {}>
>({
    name: 'fetchTaskComplexity',
    handler: async (filter) => {
        return await repository.taskComplexity().get(filter);
    },
});

// Get list of task addons
export const fetchTaskAddons = domain.createEffect<AddonTaskFilterInput, CollectionResource<AddonTaskResource, {}>>({
    name: 'fetchTaskAddons',
    handler: async (filter) => {
        return await repository.addonTask().get(filter);
    },
});

// Fetch client payment account
export const fetchClientPaymentAccount = domain.createEffect<ID, PaymentAccountResource>({
    name: 'fetchClientPaymentAccount',
    handler: async (userId) => {
        return await repository.paymentAccount().getPaymentAccountAssociatedWithUser(userId);
    },
});

// Remove task addon
export const removeTaskAddon = domain.createEffect<ID, AddonTaskRef>({
    name: 'removeTaskAddon',
    handler: async (taskAddonId) => {
        return await repository.addonTask().archive(taskAddonId);
    },
});

// Restore task addon
export const restoreTaskAddon = domain.createEffect<ID, AddonTaskRef>({
    name: 'restoreTaskAddon',
    handler: async (taskAddonId) => {
        return await repository.addonTask().restore(taskAddonId);
    },
});
