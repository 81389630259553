import { Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const StyledDropzoneArea = styled.div<{ $isVisible: boolean; $isHovering: boolean }>`
    height: 100px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px dashed ${Colors.GRAY4};

    ${({ $isVisible }) =>
        $isVisible
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `}

    ${({ $isHovering }) =>
        $isHovering &&
        css`
            opacity: 0.9;
        `}
`;
