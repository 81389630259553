import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { BrandProfileResource } from 'dy-frontend-http-repository/lib/modules/BrandProfile/resources';
import { BrandProfilesFilterInput } from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import domain from './domain';

// Fetch client brand profiles
export const fetchClientBrandProfiles = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: BrandProfilesFilterInput },
    CollectionResource<BrandProfileResource, {}>
>({
    name: 'fetchClientBrandProfiles',
    handler: async ({ pagination, filter }) => {
        return await repository.brandProfile().get(pagination, filter);
    },
});
