import domain from './domain';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { CreditWalletResource } from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import { PublishTaskStep } from '../enums';
import {
    resetClientComputedLicence,
    resetClientCreditWallet,
    resetClientQuotaUsage,
    resetStep,
    resetTaskCategory,
} from './events';
import { ComputedLicence, QuotaUsageContainer } from 'dy-frontend-shared/lib/data/valueObjects';

// Current step
export const $step = domain.createStore<PublishTaskStep>(PublishTaskStep.PUBLISH_METHOD_SELECT).reset(resetStep);

// Task category
export const $taskCategory = domain.createStore<TaskCategoryResource | null>(null).reset(resetTaskCategory);

// // Client payment account
// export const $clientPaymentAccount = domain
//     .createStore<PaymentAccountResource | null>(null)
//     .reset(resetClientPaymentAccount);

// Client credit wallet
export const $clientCreditWallet = domain.createStore<CreditWalletResource | null>(null).reset(resetClientCreditWallet);

// // Client payment methods
// export const $clientPaymentMethods = domain
//     .createStore<CollectionResource<PaymentMethodResource, {}> | null>(null)
//     .reset(resetClientPaymentMethods);

// Client computed licence
export const $clientComputedComputedLicence = domain
    .createStore<ComputedLicence | null>(null)
    .reset(resetClientComputedLicence);

// Client quota usage
export const $clientQuotaUsage = domain.createStore<QuotaUsageContainer | null>(null).reset(resetClientQuotaUsage);
