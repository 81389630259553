import { repository } from 'dy-frontend-http-repository/lib/modules';
import { UpdateUserNotificationEmailInput } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import { UserNotificationEmailRef } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/refs';
import domain from './domain';

// Update  notification email
export const updateNotificationEmail = domain.createEffect<
    { id: ID; input: UpdateUserNotificationEmailInput },
    UserNotificationEmailRef
>({
    name: 'updateNotificationEmail',
    handler: async ({ id, input }) => {
        return await repository.userNotificationEmail().update(id, input);
    },
});
