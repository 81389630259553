import { IconName } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '../enums';

const platformFiltersInformation: {
    [type in PlatformTaskFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    /* Static */
    [PlatformTaskFilterType.TASK_PUBLISH_TYPES]: {
        title: 'Publish type',
        icon: 'send-message',
        isPinned: false,
    },
    [PlatformTaskFilterType.TASK_QUEUES]: {
        title: 'Task queue',
        icon: 'time',
        isPinned: false,
    },
    [PlatformTaskFilterType.TASK_STATES]: {
        title: 'State',
        icon: 'confirm',
        isPinned: true,
    },
    /* Dynamic */
    [PlatformTaskFilterType.CLIENT_USERS]: {
        title: 'Client',
        icon: 'person',
        isPinned: true,
    },
    [PlatformTaskFilterType.TASK_PARTICIPANTS]: {
        title: 'Participants',
        icon: 'inherited-group',
        isPinned: true,
    },
    // [PlatformTaskFilterType.WORKSPACES]: {
    //     title: 'Workspaces',
    //     icon: 'user',
    //     isPinned: false,
    // },
    [PlatformTaskFilterType.TASK_CATEGORIES]: {
        title: 'Categories',
        icon: 'properties',
        isPinned: false,
    },
};

export default platformFiltersInformation;
