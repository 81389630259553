import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { fetchTasks } from '../../store/effects';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '../../consts';
import { PlatformTaskFilterType } from '../../enums';
import { resetPage, resetPlatformTaskFilterData, setPlatformTaskFilterData } from '../../store/events';
import { $isQueryHydrationFinished, $platformTaskFilterData } from '../../store/states';
import { PlatformTaskFilter as PlatformTaskFilterValueObject } from '../../valueObjects';
import ClientUsersPlatformFilter from './components/ClientUsersPlatformFilter';
import PlatformTaskFilterQueryInput from './components/PlatformTaskFilterQueryInput';
import TaskCategoriesPlatformFilter from './components/TaskCategoriesPlatformFilter';
import TaskParticipantsPlatformFilter from './components/TaskParticipantsPlatformFilter';
import TaskPublishTypesPlatformFilter from './components/TaskPublishTypesPlatformFilter';
import TaskQueuesPlatformFilter from './components/TaskQueuesPlatformFilter';
import TaskStatesPlatformFilter from './components/TaskStatesPlatformFilter';
import WorkspacesPlatformFilter from './components/WorkspacesPlatformFilter';
import { AddFilterPopoverContent } from './styled';
import DevText from '@components/Text';

const platformTaskFilterTypes = Object.values(PlatformTaskFilterType);

export type Props = HTMLAttributes<HTMLDivElement>;

const PlatformTaskFilter: React.FC<Props> = (props) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformTaskFilterData = useStore($platformTaskFilterData);
    const isFetching = useStore(fetchTasks.pending);

    const [query, setQuery] = useState('');
    const [isAddFilterPopoverOpened, setIsAddFilterPopoverOpened] = useState(false);
    const [platformFilters, setPlatformFilters] = useState<PlatformTaskFilterType[]>([]);
    const [isLastAddedShouldBeOpenedMode, setIsLastAddedShouldBeOpenedMode] = useState(false);

    const isAllFiltersAdded = platformTaskFilterTypes.length === platformFilters.length;

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setPlatformFilters(PlatformTaskFilterValueObject.getAppliedPlatformFilters(platformTaskFilterData));
            setQuery(platformTaskFilterData.query);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    const handleAddFilter = (type: PlatformTaskFilterType) => {
        if (!platformFilters.includes(type)) {
            setIsAddFilterPopoverOpened(false);
            setPlatformFilters((filters) => [...filters, type]);
        }
    };

    const renderPlatformTaskFilterQueryInput = () => {
        const trimmedCurrentQuery = query.trim();
        const isLastQueryDiffersFromCurrentOne = platformTaskFilterData.query.trim() !== trimmedCurrentQuery;

        return (
            <PlatformTaskFilterQueryInput
                loading={isFetching && (trimmedCurrentQuery.length !== 0 || isLastQueryDiffersFromCurrentOne)}
                shouldBeSearched={isLastQueryDiffersFromCurrentOne}
                className="mb-2"
                placeholder="Search by request title or ID"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onSearch={(newSearch: string) => {
                    setPlatformTaskFilterData({ ...platformTaskFilterData, query: newSearch });
                    resetPage();
                }}
            />
        );
    };

    const renderFilterOfType = (type: PlatformTaskFilterType, defaultIsOpen: boolean) => {
        const commonPlatformFilterProps = {
            defaultIsOpen,
            platformTaskFilterData: platformTaskFilterData,
            onRemove: () =>
                setPlatformFilters((platformFilters) =>
                    platformFilters.filter((platformFilterType) => platformFilterType !== type)
                ),
        };

        switch (type) {
            case PlatformTaskFilterType.CLIENT_USERS:
                return <ClientUsersPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskFilterType.TASK_CATEGORIES:
                return <TaskCategoriesPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskFilterType.TASK_PARTICIPANTS:
                return <TaskParticipantsPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskFilterType.TASK_PUBLISH_TYPES:
                return <TaskPublishTypesPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskFilterType.TASK_QUEUES:
                return <TaskQueuesPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskFilterType.TASK_STATES:
                return <TaskStatesPlatformFilter {...commonPlatformFilterProps} />;
            // case PlatformTaskFilterType.WORKSPACES:
            //     return <WorkspacesPlatformFilter {...commonPlatformFilterProps} />;
            default:
                return null;
        }
    };

    const renderPlatformFilters = () => {
        return platformFilters.map((type, index) => (
            <div key={type}>
                {renderFilterOfType(type, isLastAddedShouldBeOpenedMode && index === platformFilters.length - 1)}
            </div>
        ));
    };

    const renderAddPlatformFilterButton = () => {
        if (isAllFiltersAdded) {
            // No need to show "Add filter" button if all filters are already added
            return null;
        }

        const renderAddFilterPopoverContent = () => {
            const handleClickMenuItem = (platformTaskFilterType: PlatformTaskFilterType) => {
                if (isLastAddedShouldBeOpenedMode === false) {
                    setIsLastAddedShouldBeOpenedMode(true);
                }

                handleAddFilter(platformTaskFilterType);
            };

            return (
                <AddFilterPopoverContent>
                    <DevText muted>Select filter</DevText>

                    <Menu>
                        {Object.entries(platformFiltersInformation).map(([key, { icon, title }]) => {
                            const platformTaskFilterType = key as PlatformTaskFilterType;

                            if (platformFilters.includes(platformTaskFilterType)) {
                                // Platform filter is already added, allowing to add it again doesn't make sense
                                return null;
                            }

                            return (
                                <MenuItem
                                    className="mt-1"
                                    key={key}
                                    icon={icon}
                                    text={title}
                                    onClick={() => handleClickMenuItem(platformTaskFilterType)}
                                />
                            );
                        })}
                    </Menu>
                </AddFilterPopoverContent>
            );
        };

        return (
            <div>
                <Popover
                    isOpen={isAddFilterPopoverOpened}
                    disabled={!isQueryHydrationFinished}
                    content={renderAddFilterPopoverContent()}
                    onClose={() => setIsAddFilterPopoverOpened(false)}
                >
                    <Button
                        className="mr-1"
                        icon="filter-list"
                        minimal
                        onClick={() => setIsAddFilterPopoverOpened(!isAddFilterPopoverOpened)}
                        loading={!isQueryHydrationFinished}
                    >
                        Add filter
                    </Button>
                </Popover>
            </div>
        );
    };

    const renderResetButton = () => {
        if (JSON.stringify(platformTaskFilterData).length === JSON.stringify(initialPlatformTaskFilterData).length) {
            // Platform task filter data same as whenever it was initialized
            return null;
        }

        const handleResetButton = () => {
            // Reset page to 1
            resetPage();

            // Reset platform task filter data to initial platform task filter data
            resetPlatformTaskFilterData();

            // Create array of pinned platform filters
            const initialPlatformFilters: PlatformTaskFilterType[] = [];
            for (let i = 0; i < platformTaskFilterTypes.length; i++) {
                // Get platform task filter information for platform task filter type
                const platformTaskFilterInformation = platformFiltersInformation[platformTaskFilterTypes[i]];

                if (!platformTaskFilterInformation.isPinned) {
                    // We only push pinned platform filters
                    continue;
                }

                initialPlatformFilters.push(platformTaskFilterTypes[i]);
            }

            setPlatformFilters(initialPlatformFilters);
            setQuery('');
        };

        return (
            <Button minimal icon="trash" intent={Intent.DANGER} onClick={handleResetButton}>
                Reset
            </Button>
        );
    };

    return (
        <div {...props}>
            {renderPlatformTaskFilterQueryInput()}
            <Flex gap="8px" flexWrap="wrap">
                {renderPlatformFilters()}
                {renderAddPlatformFilterButton()}
                {renderResetButton()}
            </Flex>
        </div>
    );
};

export default PlatformTaskFilter;
