import domain from './domain';

// Reset task category
export const resetTaskCategory = domain.createEvent('resetTaskCategory');

// Reset task category
export const resetNormalizedTaskCategoryDimensions = domain.createEvent('resetNormalizedTaskCategoryDimensions');

// Reset task category addon
export const resetTaskCategoryAddons = domain.createEvent('resetTaskCategoryAddon');

// Reset task category groups
export const resetTaskCategoryGroups = domain.createEvent('resetTaskCategoryGroups');
