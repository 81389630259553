import { SubscriptionCancelReason } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';

const subscriptionCancelReasonInformation: { [reason in SubscriptionCancelReason] } = {
    [SubscriptionCancelReason.TOO_EXPENSIVE]: 'It’s too expensive',
    [SubscriptionCancelReason.MISSING_FEATURES]: 'Some features are missing',
    [SubscriptionCancelReason.SWITCHED_SERVICE]: 'I’m switching to a different service',
    [SubscriptionCancelReason.UNUSED]: 'I don’t use the service enough',
    [SubscriptionCancelReason.CUSTOMER_SERVICE]: 'Customer service was less than expected',
    [SubscriptionCancelReason.TOO_COMPLEX]: 'Ease of use was less than expected',
    [SubscriptionCancelReason.LOW_QUALITY]: 'Quality was less than expected',
    [SubscriptionCancelReason.OTHER]: 'Other reason',
};

export default subscriptionCancelReasonInformation;
