import { $clients, $page, $isQueryHydrationFinished, $platformClientUserFilterData } from './states';
import { fetchClients } from './effects';
import { setPage, setIsQueryHydrationFinished, setPlatformClientUserFilterData } from './events';

// Set page
$page.on(setPage, (_, data) => data);

// Fetch clients
$clients.on(fetchClients.doneData, (_, data) => data);

// Set query hydration finished flag
$isQueryHydrationFinished.on(setIsQueryHydrationFinished, (_, data) => data);

// Set platform client user filter data
$platformClientUserFilterData.on(setPlatformClientUserFilterData, (_, data) => data);
