import moment from 'moment';
import { createApi } from 'effector';
import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { SetTaskDeadlineInput, UpdateTaskInformationInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import {
    BrandProfileResource,
    FileAttachmentResource,
    PickableTaskParticipantUserResource,
    TaskParticipationResource,
    TaskStateTransitionResource,
} from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { $task, $taskAddons, $taskStateTransitionLog } from './states';

// API to manage task state locally
export const taskApi = createApi($task, {
    update: (store, payload: UpdateTaskInformationInput) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload,
        };
    },

    updateBrandProfile: (
        store,
        payload: {
            brandProfile: BrandProfileResource | null;
        }
    ) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            brand_profile: payload.brandProfile,
        };
    },

    addFiles: (store, payload: FileAttachmentResource[]) => {
        if (!store) {
            // Task was NOT fetched yet

            return store;
        }

        return {
            ...store,
            files: [...payload, ...store.files],
        };
    },

    removeFile: (store, payload: { fileId: ID }) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            files: store.files.filter((asset) => asset.file.id !== payload.fileId),
        };
    },

    // TODO: Rename to 'archive'
    remove: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            archived_at: moment().utc().format(),
        };
    },

    restore: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        // Check: is task published
        const isDraft = store.queue === TaskQueue.DRAFT || store.publish?.type === null;

        // Check: is delivered
        const isDelivered = store.state === TaskState.DELIVERED;

        // Change: will be moved to the backlog?
        const isBacklogMovementChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and not in backlog state already
            store.queue !== TaskQueue.BACKLOG;

        // Change: will be locked in backlog
        const isBacklogAutomationLockChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and published through `quota`
            store.publish?.type === TaskPublishType.QUOTA;

        return {
            ...store,
            archived_at: null,
            queue: isBacklogMovementChange ? TaskQueue.BACKLOG : store.queue,
            // TODO: Add "automation lock" flag when implemented (if required by `isBacklogAutomationLockChange`)
        };
    },

    pause: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            paused_at: moment().utc().format(),
            // TODO: Add pause user
        };
    },

    resume: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        // Check: is task published
        const isDraft = store.queue === TaskQueue.DRAFT || store.publish?.type === null;

        // Check: is delivered
        const isDelivered = store.state === TaskState.DELIVERED;

        // Change: will be moved to the backlog?
        const isBacklogMovementChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and not in backlog state already
            store.queue !== TaskQueue.BACKLOG;

        // Change: will be locked in backlog
        const isBacklogAutomationLockChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and published through `quota`
            store.publish?.type === TaskPublishType.QUOTA;

        return {
            ...store,
            paused_at: null,
            queue: isBacklogMovementChange ? TaskQueue.BACKLOG : store.queue,
            // TODO: Add "automation lock" flag when implemented (if required by `isBacklogAutomationLockChange`)
        };
    },

    reopen: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            finalized_at: null,
            state: TaskState.IN_REVISION,
            queue: TaskQueue.BACKLOG,
        };
    },

    transitionState: (store, payload: { state: TaskState }) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            state: payload.state,
        };
    },

    removeParticipationByUserId: (store, payload: { userId: ID }) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            participants: store.participants.filter((x) => x.user.id !== payload.userId),
        };
    },

    addParticipation: (store, payload: { input: PickableTaskParticipantUserResource[] }) => {
        if (!store) {
            // Task
            return store;
        }

        const momentNowUtc = moment().utc().format();
        const taskParticipants: TaskParticipationResource[] = payload.input.map((participant) => ({
            assigned_at: momentNowUtc,
            user: participant,
        }));

        return {
            ...store,
            participants: [...taskParticipants, ...store.participants],
        };
    },

    setDeadline: (store, payload: SetTaskDeadlineInput) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            deadline_timezone_name: payload.deadline_timezone_name,
            deadline_at: payload.deadline_at,
        };
    },

    removeDeadline: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            deadline_at: null,
        };
    },

    prioritize: (store) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            is_priority_elevated: true,
        };
    },

    removePrioritization: (store) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            is_priority_elevated: false,
        };
    },

    updateComplexityMultiplier: (store, payload: number) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            complexity_multiplier: payload,
        };
    },
});

// API to manage task addons state locally
export const taskAddonsApi = createApi($taskAddons, {
    restore: (store, payload: { taskAddonId: ID }) => {
        if (!store) {
            // Task addons were NOT initialized/fetched yet
            return store;
        }

        // Find task addon index
        const foundTaskAddonIndex = store.items.findIndex((addon) => addon.id === payload.taskAddonId);

        if (foundTaskAddonIndex === -1) {
            // Addon is NOT found nothing to restore
            return store;
        }

        // Copy task addons
        const taskAddons = [...store.items];

        // Get addon at index
        const foundTaskAddon = taskAddons[foundTaskAddonIndex];

        // Update task addon
        foundTaskAddon.archived_at = null;

        return { ...store, items: taskAddons };
    },

    remove: (store, payload: { taskAddonId: ID }) => {
        if (!store) {
            // Task addons were NOT initialized/fetched yet
            return store;
        }

        // Find task addon index
        const foundTaskAddonIndex = store.items.findIndex((addon) => addon.id === payload.taskAddonId);

        if (foundTaskAddonIndex === -1) {
            // Addon is NOT found nothing to restore
            return store;
        }

        // Copy task addons
        const taskAddons = [...store.items];

        // Get addon at index
        const foundTaskAddon = taskAddons[foundTaskAddonIndex];

        // Get current date
        const momentNowUtc = moment().utc().format();

        // Update task addon
        foundTaskAddon.archived_at = momentNowUtc;

        return { ...store, items: taskAddons };
    },
});

// API to manage task state locally
export const taskStateTransitionLogApi = createApi($taskStateTransitionLog, {
    update: (store, payload: TaskStateTransitionResource[]) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: [...payload, ...store.items],
        };
    },
});
