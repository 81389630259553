import styled from 'styled-components';
import { Colors } from '@blueprintjs/core';
import { Page as DefPage } from 'react-pdf';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px;
`;

export const Page = styled(DefPage)`
    position: relative;

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
    }

    & + & {
        margin-top: 30px;
    }
`;

export const PageNumber = styled.span`
    position: absolute;
    top: -16px;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
    height: 16px;
    font-weight: bold;
    background: ${Colors.DARK_GRAY2};
    color: ${Colors.WHITE};
`;
