import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { openModal } from '@app/containers/modals/store/events';
import { $task } from '@app/containers/pages/Task/store/states';
import { Button, Card } from '@blueprintjs/core';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import UpdateTaskComplexityModal, { UpdateTaskComplexityModalProps } from '../../modals/UpdateTaskComplexityModal';

type Props = HTMLAttributes<HTMLDivElement>;

const TaskComplexityManager: React.FC<Props> = ({ ...props }) => {
    const task = useStore($task);

    if (!task) {
        return null;
    }

    const isUpdateTaskComplexityMultiplierBlocked =
        task.archived_at !== null ||
        task.paused_at !== null ||
        [TaskState.DRAFT, TaskState.DELIVERED, TaskState.PRE_DELIVERED].some((state) => state === task.state);

    const handleUpdateTaskComplexityMultiplierButton = () => {
        openModal<UpdateTaskComplexityModalProps>({
            ModalComponent: UpdateTaskComplexityModal,
            data: {
                taskId: task.id,
                currentTaskComplexityMultiplier: Math.round(task.complexity_multiplier * 100),
            },
        });
    };

    const renderUpdateTaskComplexityMultiplierButton = () => {
        if (isUpdateTaskComplexityMultiplierBlocked) {
            return null;
        }

        return (
            <Button icon="edit" onClick={handleUpdateTaskComplexityMultiplierButton}>
                Adjust
            </Button>
        );
    };

    return (
        <Card compact {...props}>
            <Flex direction="row" justify="space-between">
                <div>
                    <Heading className="mb-1" type="h4">
                        Request complexity
                    </Heading>
                    <DevText muted>Current request complexity: {Math.round(task.complexity_multiplier * 100)}%</DevText>
                </div>

                {renderUpdateTaskComplexityMultiplierButton()}
            </Flex>
        </Card>
    );
};

export default TaskComplexityManager;
