import { usePageTitle } from '@app/hooks';
import { Button, Icon, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import RichEditor from '@components/RichEditor';
import richEditorPlugins from '@components/RichEditor/plugins';
import { $client } from '@pages/Client/store/states';
import {
    UpdateUserNoteInformationInput,
    UserNoteFilterInput,
} from 'dy-frontend-http-repository/lib/modules/UserNote/inputs';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useState } from 'react';
import ClientNoteListItem from './components/ClientNoteListItem';
import { amountOfNotesOnPage } from './consts';
import { clientNotesApi } from './store/apis';
import { createClientNote, fetchClientNotes, updateClientNote } from './store/effects';
import { resetClientNotes, resetEditingClientNote, setEditingClientNote } from './store/events';
import { $clientNotes, $editingClientNote } from './store/states';
import { $permissions } from '@containers/store/states';
import { UserNotePermission } from 'dy-frontend-permissions/lib/permission';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { useNavigate } from 'react-router-dom';

const ClientNotes: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Client Notes');
    const navigate = useNavigate();

    const client = useStore($client);
    const clientNotes = useStore($clientNotes);
    const editingClientNote = useStore($editingClientNote);
    const isFetchingClientNotes = useStore(fetchClientNotes.pending);

    const [isUpsertingNote, setIsUpsertingNote] = useState(false);
    const [note, setNote] = useState('');

    const handleFetchNext = useCallback(async () => {
        if (!client) return new Promise(() => '');

        // Common filters
        const filter: UserNoteFilterInput = {
            user_id: client.id,
            is_archived: '0',
        };

        let cursor: undefined | ID;

        if (clientNotes && clientNotes.cursor && clientNotes.cursor.next_cursor_start !== null) {
            cursor = clientNotes.cursor.next_cursor_start;
        }

        if (!permissions.isEnabled.userNote) {
            return;
        }

        try {
            // Fetch client notes
            const notes = await fetchClientNotes({
                pagination: {
                    _cursor: {
                        direction: 'desc',
                        limit: amountOfNotesOnPage,
                        start: cursor,
                    },
                },
                filter,
            });

            // Add client notes at the end
            clientNotesApi.addAtTailAfterSuccessfulFetch({ clientNotes: notes });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    }, [client, clientNotes]);

    useEffect(() => {
        handleFetchNext();
    }, []);

    useEffect(() => {
        // This happens in the situation whenever all client notes were removed, but there are more client notes to show, which should be fetched
        if (clientNotes && clientNotes.items.length === 0 && clientNotes.cursor && clientNotes.cursor.has_more) {
            handleFetchNext();
        }
    }, [clientNotes]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Notes - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetClientNotes();
            resetEditingClientNote();
        };
    }, []);

    if (!permissions.isEnabled.userNote) {
        return null;
    }

    if (!client) {
        return null;
    }

    const renderNoteEditor = () => {
        const isNoteEmpty = new RichTextFormat(note, richEditorPlugins).isEmpty();

        const requiredPermission = editingClientNote ? UserNotePermission.CONTENT_UPDATE : UserNotePermission.CREATE;
        const isAllowed = permissions.isRoot.userNote || permissions.has(requiredPermission);
        if (!isAllowed) {
            return null;
        }

        const handleUpsertNote = () => {
            if (editingClientNote) {
                handleUpdateNote();
            } else {
                handleCreateNote();
            }
        };

        const handleUpdateNote = async () => {
            if (!editingClientNote) {
                return;
            }

            setIsUpsertingNote(true);

            try {
                // Create update client note input
                const updateClientNoteInput: UpdateUserNoteInformationInput = {
                    content: note,
                };

                // Update client note
                const clientNoteRef = await updateClientNote({
                    id: editingClientNote.id,
                    input: updateClientNoteInput,
                });

                clientNotesApi.update({ clientNoteId: clientNoteRef.id, input: updateClientNoteInput });
                setEditingClientNote(null);
                setNote('');
            } catch (e) {
                // Log
                console.error(e);

                const response = (e as any).response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Notes for client with ID of ${client.id} was not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to client tasks page
                    navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
                }
            } finally {
                setIsUpsertingNote(false);
            }
        };

        const handleCreateNote = async () => {
            setIsUpsertingNote(true);

            try {
                await createClientNote({
                    user_id: client.id,
                    content: note,
                });

                const fetchedClientNotes = await fetchClientNotes({
                    pagination: {
                        _cursor: {
                            direction: 'asc',
                            limit: amountOfNotesOnPage,
                            start: clientNotes && clientNotes.items.length > 0 ? clientNotes.items[0].id : undefined,
                        },
                    },
                    filter: {
                        user_id: client.id,
                        is_archived: '0',
                    },
                });

                clientNotesApi.addAtStartAfterSuccessfulCreation({ clientNotes: fetchedClientNotes });
                setNote('');
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsUpsertingNote(false);
            }
        };

        return (
            <RichEditor
                className="mb-2"
                value={note}
                forceValue={editingClientNote ? editingClientNote.content : null}
                onChange={(data) => setNote(data)}
                placeholder="Enter client note"
                primaryAction={
                    <Flex>
                        {editingClientNote && (
                            <Button
                                className="ml-1"
                                outlined
                                icon="small-cross"
                                intent={Intent.DANGER}
                                onClick={() => {
                                    setEditingClientNote(null);
                                    setNote('');
                                }}
                            >
                                Cancel editing
                            </Button>
                        )}

                        <Button
                            disabled={isUpsertingNote || isNoteEmpty}
                            icon="direction-right"
                            className="ml-1"
                            intent={Intent.PRIMARY}
                            onClick={handleUpsertNote}
                        >
                            {editingClientNote ? 'Update' : 'Publish'}
                        </Button>
                    </Flex>
                }
            />
        );
    };

    const renderClientNotesList = () => {
        if (!clientNotes) {
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client notes were fetched, but where are no notes existed for this client
        if (clientNotes && clientNotes.items.length === 0 && clientNotes.cursor && !clientNotes.cursor.has_more) {
            return (
                <NonIdealState
                    className="mt-4"
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client notes were found
                        </Heading>
                    }
                />
            );
        }

        return (
            <div>
                {clientNotes?.items.map((clientNote) => (
                    <ClientNoteListItem
                        className="mt-2"
                        key={clientNote.id}
                        editingNote={editingClientNote}
                        note={clientNote}
                    />
                ))}

                {clientNotes && clientNotes.cursor && clientNotes.cursor.has_more && (
                    <Flex className="mt-2" justify="center" align="center">
                        <Button
                            loading={isFetchingClientNotes}
                            outlined
                            icon="refresh"
                            rightIcon="refresh"
                            onClick={handleFetchNext}
                        >
                            Show more
                        </Button>
                    </Flex>
                )}
            </div>
        );
    };

    return (
        <div>
            {renderNoteEditor()}
            {renderClientNotesList()}
        </div>
    );
};

export default ClientNotes;
