import React, { HTMLAttributes } from 'react';
import { TabItem } from '@app/data/types';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '../Flex';

export interface PageTabsProps {
    // Pathname used to check if tab is active
    pathname: string;

    // Amount of tabs to be seen in the main section, others will go to tabs menu
    visibleAmount: number;

    // Tabs information
    tabs: TabItem[];

    // Function to call whenever tab is elected
    onSelectTab: (path: string) => void;

    // Path formatter, mostly used to replace things like ":coreUserId" with real IDs
    pathFormatter: (value: TabItem['value']) => string;
}

export type Props = HTMLAttributes<HTMLDivElement> & PageTabsProps;

const PageTabs: React.FC<Props> = ({ visibleAmount, pathname, tabs, pathFormatter, onSelectTab, ...props }) => {
    const isTabMainSectionVisibleAmountSurpassed = tabs.length > visibleAmount;

    const renderMainSectionTab = ({ value, icon, label }: TabItem, index) => {
        const redirectPath = pathFormatter(value);

        return (
            <Button
                active={pathname === redirectPath}
                className={index === tabs.length - 1 ? '' : 'mr-1'}
                key={value}
                icon={icon}
                onClick={() => onSelectTab(redirectPath)}
            >
                {label}
            </Button>
        );
    };

    const renderSurpassedTabsButton = (tabs: TabItem[]) => {
        if (!isTabMainSectionVisibleAmountSurpassed) {
            // No need to show button, since tab visibility amount was NOT surpassed
            return null;
        }

        const renderSurpassedTabsMenuItem = ({ label, icon, value }: TabItem) => {
            const redirectPath = pathFormatter(value);

            return (
                <MenuItem
                    active={pathname === redirectPath}
                    text={label}
                    icon={icon}
                    onClick={() => onSelectTab(redirectPath)}
                />
            );
        };

        const renderSurpassedTabsMenu = () => {
            return <Menu>{tabs.map(renderSurpassedTabsMenuItem)}</Menu>;
        };

        return (
            <Popover content={renderSurpassedTabsMenu()}>
                <Button className="ml-1" icon="more" />
            </Popover>
        );
    };

    const mainSectionTabs = tabs.slice(0, visibleAmount);
    const surpassedSectionTabs = tabs.slice(visibleAmount);

    return (
        <div {...props}>
            <Flex direction="row" justify="space-between">
                <div>{mainSectionTabs.map(renderMainSectionTab)}</div>
                {renderSurpassedTabsButton(surpassedSectionTabs)}
            </Flex>
        </div>
    );
};

export default PageTabs;
