const taskMessageTemplateVariablesInformation = [
    {
        key: '[[me.first_name]]',
        description: 'Author first name',
    },
    {
        key: '[[me.last_name]]',
        description: 'Author last name',
    },
    {
        key: '[[me.company_position]]',
        description: 'Author company position',
    },
    {
        key: '[[request.title]]',
        description: 'Request title',
    },
    {
        key: '[[request.id]]',
        description: 'Request ID',
    },
    {
        key: '[[request.client.first_name]]',
        description: 'Client first name',
    },
    {
        key: '[[request.client.last_name]]',
        description: 'Client last name',
    },
];

export default taskMessageTemplateVariablesInformation;
