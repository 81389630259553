import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useForm } from '@app/hooks';
import { lockTaskQueueAutomation } from './store/effects';

export interface ConfirmTaskLockQueueAutomationModalProps {
    taskId: ID;
    onLockTaskQueueAutomation: () => void;
}

type Props = ModalProps<ConfirmTaskLockQueueAutomationModalProps>;

const ConfirmTaskLockQueueAutomationModal: React.FC<Props> = ({ closeModal, data }) => {
    const form = useForm({
        fields: [],
        apiCall: async () => {
            try {
                const taskRef = await lockTaskQueueAutomation(data?.taskId ?? '0');

                data?.onLockTaskQueueAutomation();

                return { response: taskRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Lock task queue automation flag
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Flex className="mt-2" align="center" justify="space-between">
                        <DevText muted>Proceed lock task queue automation?</DevText>
                        <div>
                            <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                                No, cancel
                            </Button>
                            <Button
                                intent={Intent.PRIMARY}
                                loading={form.isSubmitting}
                                disabled={form.hasFieldErrors}
                                type="submit"
                            >
                                Yes, lock
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default ConfirmTaskLockQueueAutomationModal;
