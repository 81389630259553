import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import {
    CreateTaskMessageTemplateCategoryInput,
    UpdateTaskMessageTemplateCategoryInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/inputs';
import {
    CreateTaskMessageTemplateInput,
    TaskMessageTemplateFilterInput,
    UpdateTaskMessageTemplateInput,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/inputs';
import { TaskMessageTemplateCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import { TaskMessageTemplateListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import { TaskMessageTemplateCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/refs';
import { TaskMessageTemplateRef } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/refs';

// Fetch task message template categories
export const fetchTaskMessageTemplateCategories = domain.createEffect<
    void,
    CollectionResource<TaskMessageTemplateCategoryListItemResource, {}>
>({
    name: 'fetchTaskMessageTemplateCategories',
    handler: async () => {
        return await repository.taskMessageTemplateCategory().get();
    },
});

// Fetch task message templates
export const fetchTaskMessageTemplates = domain.createEffect<
    {
        pagination: OffsetPaginationInput;
        filter?: TaskMessageTemplateFilterInput;
    },
    CollectionResource<TaskMessageTemplateListItemResource, {}>
>({
    name: 'fetchTaskMessageTemplates',
    handler: async ({ pagination, filter }) => {
        return await repository.taskMessageTemplate().get(pagination, filter);
    },
});

// Create task message template category
export const createTaskMessageTemplateCategory = domain.createEffect<
    CreateTaskMessageTemplateCategoryInput,
    TaskMessageTemplateCategoryRef
>({
    name: 'createTaskMessageTemplateCategory',
    handler: async (input) => {
        return await repository.taskMessageTemplateCategory().create(input);
    },
});

// Update task message template category
export const updateTaskMessageTemplateCategory = domain.createEffect<
    { id: ID; input: UpdateTaskMessageTemplateCategoryInput },
    TaskMessageTemplateCategoryRef
>({
    name: 'updateTaskMessageTemplateCategory',
    handler: async ({ id, input }) => {
        return await repository.taskMessageTemplateCategory().update(id, input);
    },
});

// Remove task message template category
export const removeTaskMessageTemplateCategory = domain.createEffect<ID, TaskMessageTemplateCategoryRef>({
    name: 'removeTaskMessageTemplateCategory',
    handler: async (id) => {
        return await repository.taskMessageTemplateCategory().archive(id);
    },
});

// Restore task message template category
export const restoreTaskMessageTemplateCategory = domain.createEffect<ID, TaskMessageTemplateCategoryRef>({
    name: 'restoreTaskMessageTemplateCategory',
    handler: async (taskMessageTemplateCategoryId) => {
        return await repository.taskMessageTemplateCategory().restore(taskMessageTemplateCategoryId);
    },
});

// Create task message template
export const createTaskMessageTemplate = domain.createEffect<CreateTaskMessageTemplateInput, TaskMessageTemplateRef>({
    name: 'createTaskMessageTemplate',
    handler: async (input) => {
        return await repository.taskMessageTemplate().create(input);
    },
});

// Update task message template
export const updateTaskMessageTemplate = domain.createEffect<
    { id: ID; input: UpdateTaskMessageTemplateInput },
    TaskMessageTemplateRef
>({
    name: 'updateTaskMessageTemplate',
    handler: async ({ id, input }) => {
        return await repository.taskMessageTemplate().update(id, input);
    },
});

// Remove task message template
export const removeTaskMessageTemplate = domain.createEffect<ID, TaskMessageTemplateRef>({
    name: 'removeTaskMessageTemplate',
    handler: async (id) => {
        return await repository.taskMessageTemplate().archive(id);
    },
});

// Restore task message template
export const restoreTaskMessageTemplate = domain.createEffect<ID, TaskMessageTemplateRef>({
    name: 'restoreTaskMessageTemplate',
    handler: async (taskMessageTemplateId) => {
        return await repository.taskMessageTemplate().restore(taskMessageTemplateId);
    },
});
