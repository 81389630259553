import { PickableTeamParticipantUserResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { assignTeamParticipantUsers } from '../../store/effects';
import { teamApi } from '../../store/apis';

const useAssignTeamParticipants = () => {
    const handleAssignTeamParticipants = async ({
        teamId,
        selectedUsers,
        role,
    }: {
        teamId: ID;
        selectedUsers: PickableTeamParticipantUserResource[];
        role: TeamParticipationRole;
    }) => {
        try {
            await assignTeamParticipantUsers({
                teamId,
                userIds: selectedUsers.map((user) => user.id),
                role,
            });

            teamApi.addParticipation({ pickableTeamParticipantUsers: selectedUsers });

            // TODO: Store event
        } catch (e) {
            throw e;
        }
    };

    return { assignTeamParticipants: handleAssignTeamParticipants };
};

export default useAssignTeamParticipants;
