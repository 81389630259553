import moment from 'moment';
import {
    CreateTaskCategoryGroupInput,
    UpdateTaskCategoryGroupInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/inputs';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { createApi } from 'effector';
import { $taskCategoryGroups } from './states';

// API to manage task category groups state locally
export const taskCategoryGroupsApi = createApi($taskCategoryGroups, {
    create: (store, payload: { taskCategoryGroupId: ID; input: CreateTaskCategoryGroupInput }) => {
        if (!store) {
            // Task category groups were NOT fetched yet
            return store;
        }

        // Create new task category group from input
        const input = payload.input;
        const momentNowUtc = moment().utc().format();
        const taskCategoryGroup: TaskCategoryGroupListItemResource = {
            id: payload.taskCategoryGroupId,
            archived_at: null,
            title: input.title,
            color: input.color ?? '',
            created_at: momentNowUtc,
        };

        return {
            ...store,
            items: [taskCategoryGroup, ...store.items],
        };
    },
    update: (store, payload: { taskCategoryGroupId: ID; input: UpdateTaskCategoryGroupInput }) => {
        if (!store) {
            // Task category groups were NOT fetched yet
            return store;
        }

        const input = payload.input;

        // Copy task category groups
        const copiedTaskCategoryGroupItems = store.items.slice();

        // Find task category group to update
        const foundTaskCategoryGroupItemIndex = copiedTaskCategoryGroupItems.findIndex(
            (taskCategoryGroup) => taskCategoryGroup.id === payload.taskCategoryGroupId
        );

        if (foundTaskCategoryGroupItemIndex === -1) {
            // Task category group was NOT found
            return store;
        }

        // Update task category group
        const foundTaskCategoryGroupItem = copiedTaskCategoryGroupItems[foundTaskCategoryGroupItemIndex];
        copiedTaskCategoryGroupItems[foundTaskCategoryGroupItemIndex] = {
            ...foundTaskCategoryGroupItem,
            ...input,
        };

        return {
            ...store,
            items: copiedTaskCategoryGroupItems,
        };
    },

    archive: (store, payload: { taskCategoryGroupId: ID }) => {
        if (!store) {
            // Task category groups were NOT fetched yet
            return store;
        }

        // Copy task category groups
        const copiedTaskCategoryGroupItems = store.items.slice();

        // Find task category group to archive
        const foundTaskCategoryGroupItem = copiedTaskCategoryGroupItems.find(
            (taskCategoryGroup) => taskCategoryGroup.id === payload.taskCategoryGroupId
        );

        if (!foundTaskCategoryGroupItem) {
            // Task category group was NOT found
            return store;
        }

        // Archive task category group
        const momentNowUtc = moment().utc().format();
        foundTaskCategoryGroupItem.archived_at = momentNowUtc;

        return {
            ...store,
            items: copiedTaskCategoryGroupItems,
        };
    },

    restore: (store, payload: { taskCategoryGroupId: ID }) => {
        if (!store) {
            // Task category groups were NOT fetched yet
            return store;
        }

        // Copy task category groups
        const copiedTaskCategoryGroupItems = store.items.slice();

        // Find task category group to restore
        const foundTaskCategoryGroupItem = copiedTaskCategoryGroupItems.find(
            (taskCategoryGroup) => taskCategoryGroup.id === payload.taskCategoryGroupId
        );

        if (!foundTaskCategoryGroupItem) {
            // Task category group was NOT found
            return store;
        }

        // Restore task category group
        foundTaskCategoryGroupItem.archived_at = null;

        return {
            ...store,
            items: copiedTaskCategoryGroupItems,
        };
    },
});
