import React from 'react';
import { Classes, PortalProvider } from '@blueprintjs/core';
import AppRoutes from '@containers/routes';
import RouterProvider from '@containers/providers/RouterProvider';
import ModalProvider from '@containers/providers/ModalProvider';
import ThemeProvider from '@containers/providers/ThemeProvider';
import StripeProvider from '@containers/providers/StripeProvider';
import HttpErrorHandlerProvider from '@containers/providers/HttpErrorHandlerProvider';
import UncaughtErrorHandlerProvider from '@containers/providers/UncaughtErrorHandlerProvider';
import { Wrapper } from './styled';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const App: React.FC = () => {
    return (
        <UncaughtErrorHandlerProvider>
            <ThemeProvider>
                <StripeProvider>
                    <RouterProvider>
                        <HttpErrorHandlerProvider />
                        <DndProvider backend={HTML5Backend}>
                            <PortalProvider portalClassName={Classes.DARK}>
                                <ModalProvider />
                            </PortalProvider>
                            <Wrapper className={Classes.DARK}>
                                <AppRoutes />
                            </Wrapper>
                        </DndProvider>
                    </RouterProvider>
                </StripeProvider>
            </ThemeProvider>
        </UncaughtErrorHandlerProvider>
    );
};

export default App;
