import { fetchCreditWalletForUser, fetchCreditWalletForWorkspace, fetchCreditWalletTransactions } from './effects';
import { $creditWallet, $creditWalletTransactions } from './states';

// Fetch credit wallet for user
$creditWallet.on(fetchCreditWalletForUser.doneData, (_, data) => data);

// Fetch credit wallet for workspace
$creditWallet.on(fetchCreditWalletForWorkspace.doneData, (_, data) => data);

// Fetch credit wallet transactions
$creditWalletTransactions.on(fetchCreditWalletTransactions.doneData, (_, data) => data);
