import React, { HTMLAttributes } from 'react';
import { useCreatePaymentMethod, useForm } from '@app/hooks';
import CardCvcInput from '@components/CardCvcInput';
import CardExpirationDateInput from '@components/CardExpirationDateInput';
import CardNumberInput from '@components/CardNumberInput';
import Grid from '@components/Grid';
import { Button, Intent } from '@blueprintjs/core';

export interface StripeFormProps {
    paymentAccountId: ID;
    buttonTitle?: string;
    onCreatePaymentMethod: (externalPaymentMethodId: string) => Promise<unknown>;
}

export type Props = HTMLAttributes<HTMLFormElement> & StripeFormProps;

const StripeForm: React.FC<Props> = ({
    buttonTitle = 'Pay now',
    paymentAccountId,
    onCreatePaymentMethod,
    ...props
}) => {
    const { createPaymentMethod } = useCreatePaymentMethod({
        paymentAccountId,
        onCreatePaymentMethod,
    });

    const form = useForm({
        fields: [],
        apiCall: async () => {
            try {
                const response = await createPaymentMethod();
                return { response };
            } catch (e) {
                throw e;
            }
        },
    });

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            <Grid container>
                <Grid className="mb-2" xs={12} lg={12}>
                    <CardNumberInput />
                </Grid>

                <Grid className="mb-2" xs={8} lg={8}>
                    <CardExpirationDateInput />
                </Grid>

                <Grid className="mb-2" xs={4} lg={4}>
                    <CardCvcInput />
                </Grid>
            </Grid>

            <Button fill loading={form.isSubmitting} disabled={form.isSubmitting} type="submit" intent={Intent.PRIMARY}>
                {buttonTitle}
            </Button>
        </form>
    );
};

export default StripeForm;
