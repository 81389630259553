import React from 'react';
import Flex from '@components/Flex';
import { Button, Card, Classes, Popover, PopoverProps } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';

export interface ConfirmationPopoverProps {
    // Confirmation title
    title: string;

    // Optional description. Will be rendered as muted text
    description?: string;

    // Optional dismiss label override (Default: 'Cancel')
    dismissLabel?: string;

    // List of action buttons. Each button MUST have 'Classes.POPOVER_DISMISS' in their class names
    actions: React.ReactNode[];

    // Maximum card width
    maxWidth?: string;
}

export type Props = ConfirmationPopoverProps & PopoverProps;

// TODO: I'm not sure how to force text to wrap, so I just set {maxWidth: 300px} to content card. Change if there is a better way
const ConfirmationPopover: React.FC<Props> = ({
    title,
    description,
    dismissLabel = 'Cancel',
    actions,
    children,
    maxWidth = '300px',
    ...props
}) => {
    const renderContent = () => {
        return (
            <Card style={{ maxWidth }}>
                <Heading type="h5" className="mb-2">
                    {title}
                </Heading>

                {description && (
                    <DevText muted running className="mb-3">
                        {description}
                    </DevText>
                )}

                <Flex direction="row" justify="flex-end">
                    <Button minimal className={Classes.POPOVER_DISMISS}>
                        {dismissLabel}
                    </Button>

                    {actions.map((action, index) => (
                        <div key={`confirm-${index}`} className="ml-1">
                            {action}
                        </div>
                    ))}
                </Flex>
            </Card>
        );
    };

    return (
        <Popover content={renderContent()} {...props}>
            {children}
        </Popover>
    );
};

export default ConfirmationPopover;
