import { usePageTitle } from '@app/hooks';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { $task } from '../../store/states';
import Addons from './components/Addons';
import Assets from './components/Assets';
import { $permissions } from '@containers/store/states';
import { AddonTaskPermission, TaskPermission } from 'dy-frontend-permissions/lib/permission';

const TaskBriefing: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Request Info');

    const task = useStore($task);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Info - ${task.title}`);
    }, [task?.title]);

    const renderAddons = () => {
        // Ensure access enabled
        const isAddonTaskAccess = (
            permissions.has(AddonTaskPermission._ENABLED)
            || permissions.has(AddonTaskPermission.ROOT)
        );
        if (!isAddonTaskAccess) {
            return null;
        }

        return <Addons className="mb-2" />;
    }

    return (
        <div>
            {renderAddons()}
            <Assets />
        </div>
    );
};

export default TaskBriefing;
