import { useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { UpdateTaskCategoryInput } from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import React, { useState } from 'react';
import { taskCategoryApi } from '../../store/apis';
import { updateTaskCategory } from '../../store/effects';
import PriceResolverInput, { PriceResolverInputValue } from '@components/PriceResolverInput';
import { PriceType } from 'dy-frontend-http-repository/lib/modules/Price/enums';

export interface UpdateTaskCategoryModalProps {
    taskCategory: TaskCategoryResource;
}

type Props = ModalProps<UpdateTaskCategoryModalProps>;

const categoryTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const categorySummaryValidators = [getOptionalStringValidator([getStringMaxLengthValidator({ maxStringLength: 255 })])];
const categoryDescriptionValidators = [
    getOptionalStringValidator([getStringMaxLengthValidator({ maxStringLength: 255 })]),
];
const categorySummaryDeliverablesValidators = [
    getOptionalStringValidator([getStringMaxLengthValidator({ maxStringLength: 255 })]),
];
const categoryAverageDeliveryDaysValidators = [
    getOptionalStringValidator([
        getStringNumericValueValidator(),
        getStringNumericValueMinValidator({ minValue: 0 }),
        getStringNumericValueOfTypeIntValidator(),
    ]),
];
const categoryCreditAmountValidators = [
    getOptionalStringValidator([
        getStringNumericValueValidator(),
        getStringNumericValueMinValidator({ minValue: 0 }),
        getStringNumericValueOfTypeIntValidator(),
    ]),
];

const UpdateTaskCategoryModal: React.FC<Props> = ({ closeModal, data }) => {

    const categoryTitle = useTextFormField({
        id: 'category-title',
        initialValue: data?.taskCategory.title ?? '',
        validators: categoryTitleValidators,
    });

    const categorySummary = useTextFormField({
        id: 'category-summary',
        initialValue: data?.taskCategory.summary ?? '',
        validators: categorySummaryValidators,
    });

    const categoryDescription = useTextFormField({
        id: 'category-description',
        initialValue: data?.taskCategory.description ?? '',
        validators: categoryDescriptionValidators,
    });

    const categorySummaryDeliverables = useTextFormField({
        id: 'category-summary-deliverables',
        initialValue: data?.taskCategory.summary_deliverables ?? '',
        validators: categorySummaryDeliverablesValidators,
    });

    const categoryAverageDeliveryDays = useTextFormField({
        id: 'category-average-delivery-days',
        initialValue: data?.taskCategory.average_delivery_days ? `${data.taskCategory.average_delivery_days}` : '',
        validators: categoryAverageDeliveryDaysValidators,
    });

    const categoryCreditAmount = useTextFormField({
        id: 'category-credit-amount',
        initialValue: data?.taskCategory.credit_amount ? `${data.taskCategory.credit_amount}` : '',
        validators: categoryCreditAmountValidators,
    });

    const [isCategoryCreditPublishSupported, setIsCategoryCreditPublishSupported] = useState(
        !!data?.taskCategory.is_credit_publish_supported,
    );
    const [isCategoryPaymentPublishSupported, setIsCategoryPaymentPublishSupported] = useState(
        !!data?.taskCategory.is_payment_publish_supported,
    );
    const [isCategoryEnabled, setIsCategoryEnabled] = useState(data?.taskCategory.is_enabled);

    const [
        paymentPublishPrice,
        setPaymentPublishPrice,
    ] = useState<PriceResolverInputValue | null>(data?.taskCategory.price ? {
        id: data.taskCategory.price.id,
        externalId: data.taskCategory.price.external_id,
        amount: data.taskCategory.price.unit_amount,
        currency: data.taskCategory.price.currency,
        type: PriceType.ONE_TIME,
        recurringInterval: null,
        recurringIntervalCount: null,
        planId: null,
        planTitle: null,
    } : null);

    const form = useForm<TaskCategoryRef>({
        fields: [
            categoryTitle,
            categorySummary,
            categoryDescription,
            categorySummaryDeliverables,
            categoryAverageDeliveryDays,
            categoryCreditAmount,
        ],
        apiCall: async () => {
            // Create input
            const updateTaskCategoryInput: UpdateTaskCategoryInput = {
                title: categoryTitle.value,
                summary: categorySummary.value,
                summary_deliverables: categorySummaryDeliverables.value,
                description: categoryDescription.value,
                is_credit_publish_supported: isCategoryCreditPublishSupported,
                is_payment_publish_supported: isCategoryPaymentPublishSupported,
                is_enabled: isCategoryEnabled,
                average_delivery_days: categoryAverageDeliveryDays.value
                    ? parseFloat(categoryAverageDeliveryDays.value)
                    : 0,
                credit_amount: categoryCreditAmount.value ? parseFloat(categoryCreditAmount.value) : 0,
                price_id: paymentPublishPrice ? paymentPublishPrice.id : null,
            };

            try {
                const taskCategoryRef = await updateTaskCategory({
                    id: data!.taskCategory.id,
                    input: updateTaskCategoryInput,
                });

                taskCategoryApi.update({ input: updateTaskCategoryInput });

                return { response: taskCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderTaskCategoryPublishSettingsFlagsInputs = () => {
        return (
            <>
                <Grid lg={6} xs={12}>
                    {/* Is category publish supported */}
                    <FormGroup>
                        <Checkbox
                            checked={isCategoryCreditPublishSupported}
                            label="Is Credit Publish Supported"
                            onChange={() => setIsCategoryCreditPublishSupported((prevValue) => !prevValue)}
                        />
                    </FormGroup>
                </Grid>

                <Grid lg={6} xs={12}>
                    {/* Is category enabled */}
                    <FormGroup>
                        <Checkbox
                            checked={isCategoryEnabled}
                            label="Is Category Enabled"
                            onChange={() => setIsCategoryEnabled((prevValue) => !prevValue)}
                        />
                    </FormGroup>
                </Grid>
            </>
        );
    };

    const renderTaskCategoryEnabledFlagInput = () => {
        return (
            <Grid lg={6} xs={12}>
                {/* Is category enabled */}
                <FormGroup>
                    <Checkbox
                        checked={isCategoryEnabled}
                        label="Is Category Enabled"
                        onChange={() => setIsCategoryEnabled((prevValue) => !prevValue)}
                    />
                </FormGroup>
            </Grid>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update category</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        <Grid lg={12}>
                            {/* Title */}
                            <InputFormField
                                field={categoryTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Summary */}
                            <InputFormField
                                field={categorySummary}
                                formGroupProps={{ label: 'Short description' }}
                                inputProps={{ placeholder: 'Enter short description' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Description */}
                            <InputFormField
                                field={categoryDescription}
                                formGroupProps={{ label: 'Description' }}
                                inputProps={{ placeholder: 'Enter description' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            <FormGroup fill label="Payment publish price">
                                <PriceResolverInput
                                    value={paymentPublishPrice}
                                    onChange={setPaymentPublishPrice}
                                    onViolationListRequested={(resolved) => {
                                        // Ensure one-time
                                        if (resolved.type !== PriceType.ONE_TIME) {
                                            return ['Must be one-time payment price'];
                                        }

                                        // TODO: Other violations
                                        return [];
                                    }}
                                />
                            </FormGroup>
                        </Grid>

                        <Grid lg={12}>
                            {/* Deliverables summary */}
                            <InputFormField
                                field={categorySummaryDeliverables}
                                formGroupProps={{ label: 'Deliverables description' }}
                                inputProps={{ placeholder: 'Enter deliverables description' }}
                            />
                        </Grid>

                        <Grid lg={6} xs={12}>
                            {/* Average delivery days */}
                            <InputFormField
                                field={categoryAverageDeliveryDays}
                                formGroupProps={{ label: 'Delivery days' }}
                                inputProps={{ placeholder: 'Enter delivery days' }}
                            />
                        </Grid>

                        <Grid lg={6} xs={12}>
                            {/* Credit amount */}
                            <InputFormField
                                field={categoryCreditAmount}
                                formGroupProps={{ label: 'Credit price' }}
                                inputProps={{ placeholder: 'Enter credit price' }}
                            />
                        </Grid>

                        {renderTaskCategoryPublishSettingsFlagsInputs()}
                        {renderTaskCategoryEnabledFlagInput()}
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskCategoryModal;
