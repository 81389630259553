import Flex from '@app/components/Flex';
import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Endpoints } from '@app/data/consts';
import { Colors, Icon } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface TeamTagProps {
    id: ID;
    name: string;
    color?: string | null;
}

export type Props = HTMLAttributes<HTMLDivElement> & TeamTagProps;

const TeamTag: React.FC<Props> = ({ id, name, color = undefined, ...props }) => {
    return (
        <Flex direction="row" align="center" {...props}>
            <Icon className="mr-1" icon="team" color={color} />
            <RouterLink lineHeight={1.2} color={Colors.WHITE} to={Endpoints.TEAM_MEMBERS.replace(':teamId', id)}>
                <DevText>{name}</DevText>
            </RouterLink>
        </Flex>
    );
};

export default TeamTag;
