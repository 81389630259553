import { $permissions } from '@app/containers/store/states';
import { Button, Card, Divider, Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import UpsertTaskCategoryExtensionsModal, {
    UpsertTaskCategoryExtensionsModalProps,
} from '../../modals/UpsertTaskCategoryExtensionsModal';
import { $taskCategory } from '../../store/states';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const DesignExtensions: React.FC<Props> = () => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);

    if (!taskCategory) {
        return null;
    }

    const renderUpdateTaskCategoryExtensions = (modalData: UpsertTaskCategoryExtensionsModalProps) => {
        const isAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpsertTaskCategoryExtensionsModalProps>({
                        ModalComponent: UpsertTaskCategoryExtensionsModal,
                        data: modalData,
                    })
                }
            >
                Edit
            </Button>
        );
    };

    const renderTaskCategoryExtensionsBlock = () => {
        const renderTaskCategoryExtensionList = () => {
            const renderTaskCategoryExtensionListItem = (extension: TaskCategoryResource['extensions'][number]) => {
                return <Tag className="mr-1">{extension.toUpperCase()}</Tag>;
            };

            if (taskCategory.extensions.length === 0) {
                return <DevText muted>No extensions yet</DevText>;
            }

            return <Flex>{taskCategory.extensions.map(renderTaskCategoryExtensionListItem)}</Flex>;
        };

        return (
            <Flex justify="space-between">
                <Flex direction="column">
                    <DevText muted className="mb-small">
                        Deliverables
                    </DevText>

                    {renderTaskCategoryExtensionList()}
                </Flex>

                {renderUpdateTaskCategoryExtensions({
                    extensionMode: 'deliverable',
                    selectedExtensions: taskCategory.extensions,
                    taskCategoryId: taskCategory.id,
                })}
            </Flex>
        );
    };

    const renderTaskCategorySourceExtensionsBlock = () => {
        const renderTaskCategorySourceExtensionList = () => {
            const renderTaskCategorySourceExtensionListItem = (
                sourceExtension: TaskCategoryResource['source_extensions'][number],
            ) => {
                return <Tag className="mr-1">{sourceExtension.toUpperCase()}</Tag>;
            };

            if (taskCategory.source_extensions.length === 0) {
                return <DevText muted>No source extensions yet</DevText>;
            }

            return <Flex>{taskCategory.source_extensions.map(renderTaskCategorySourceExtensionListItem)}</Flex>;
        };

        return (
            <Flex justify="space-between">
                <Flex direction="column">
                    <DevText muted className="mb-small">
                        Source files
                    </DevText>

                    {renderTaskCategorySourceExtensionList()}
                </Flex>

                {renderUpdateTaskCategoryExtensions({
                    extensionMode: 'source',
                    selectedExtensions: taskCategory.source_extensions,
                    taskCategoryId: taskCategory.id,
                })}
            </Flex>
        );
    };

    return (
        <Card className="mb-2">
            <Heading className="mb-2" type="h4">
                Design Extensions
            </Heading>

            {/* Extensions */}
            {renderTaskCategoryExtensionsBlock()}

            <Divider className="mt-2 mb-2" />

            {/* Source Extensions */}
            {renderTaskCategorySourceExtensionsBlock()}
        </Card>
    );
};

export default DesignExtensions;
