import { IconName, Button, Icon, ButtonProps } from '@blueprintjs/core';
import React from 'react';

export interface PasswordLockButtonProps {
    isPasswordVisible: boolean;
    changePasswordVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export type Props = ButtonProps & PasswordLockButtonProps;

const PasswordLockButton: React.FC<Props> = ({ isPasswordVisible, changePasswordVisibility, ...props }) => {
    let icon: IconName;

    // Show/Hide password button icon
    if (isPasswordVisible) {
        icon = 'unlock';
    } else {
        icon = 'lock';
    }

    return (
        <Button
            minimal
            tabIndex={-1}
            icon={<Icon icon={icon} size={24} />}
            onClick={() => changePasswordVisibility((prevValue) => !prevValue)}
            {...props}
        />
    );
};

export default PasswordLockButton;
