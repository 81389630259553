import { Colors, Tooltip } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';
import Box from '../Box';
import Flex from '../Flex';
import DevText from '../Text';
import { RatioIndexData } from './types';

export interface RatioTileProps {
    config: { [ratioKey in string]: RatioIndexData };
}
export type Props = HTMLAttributes<HTMLDivElement> & RatioTileProps;

// Calculate percentage from total
const calculatePercentage = (total: number, value: number) => {
    // TODO: move ".toFixed(2).replace(/[.,]0+$/, '')" to utils, this code works like that "5.00" will show as "5", but "25.256457457", will be "25.25", same for "65.55", it'll be "65.55"
    return ((value * 100) / total).toFixed(2).replace(/[.,]0+$/, '');
};

const RatioTile: React.FC<Props> = ({ config, ...props }) => {
    // Get total value
    const ratioIndexValueTotal = Object.values(config).reduce((accumulator, ratioIndexDataValue) => {
        return accumulator + ratioIndexDataValue.value;
    }, 0);

    const renderRatioIndexTile = (key: string, data: RatioIndexData, isFirstItem: boolean, isLastItem: boolean) => {
        // Calculate percentage
        const rationIndexValuePercentage = calculatePercentage(ratioIndexValueTotal, data.value);

        return (
            <Tooltip
                renderTarget={(props) => (
                    <Box
                        key={key}
                        borderTopLeftRadius={isFirstItem ? '2px' : undefined}
                        borderBottomLeftRadius={isFirstItem ? '2px' : undefined}
                        borderTopRightRadius={isLastItem ? '2px' : undefined}
                        borderBottomRightRadius={isLastItem ? '2px' : undefined}
                        width={`${rationIndexValuePercentage}%`}
                        height="100%"
                        cursor="pointer"
                        backgroundColor={data.color}
                        backgroundColorOnHover={`${data.color}cc`}
                        {...props}
                    />
                )}
                content={
                    <div>
                        <DevText>
                            {data.label} - {rationIndexValuePercentage}%
                        </DevText>
                        {data.description && (
                            <DevText muted className="mt-small">
                                {data.description}
                            </DevText>
                        )}
                    </div>
                }
            />
        );
    };

    return (
        <Box height="8px" width="100%" borderRadius="2px" backgroundColor={Colors.BLUE3} {...props}>
            <Flex fullWidth fullHeight align="flex-start" justify="flex-start">
                {Object.entries(config).map(([key, data], index) =>
                    data.value > 0
                        ? renderRatioIndexTile(key, data, index === 0, index === Object.values(config).length - 1)
                        : null
                )}
            </Flex>
        </Box>
    );
};

export default RatioTile;
