import React from 'react';
import { HTMLTableProps } from '@blueprintjs/core';
import { StyledTable } from './styled';

export interface TableProps {
    loading?: boolean;
}

export type Props = HTMLTableProps & TableProps;

const Table: React.FC<Props> = ({ loading = false, ...props }) => {
    return <StyledTable $loading={loading} {...props} />;
};

export default Table;
