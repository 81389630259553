import { fetchTaskClientFeedback, fetchTaskClientFeedbackSummary } from './effects';
import { setPage, setIsQueryHydrationFinished, setPlatformTaskClientFeedbackFilterData } from './events';
import { $isQueryHydrationFinished, $page, $taskClientFeedback, $taskClientFeedbackSummary, $platformTaskClientFeedbackData } from './states';

// Set page
$page.on(setPage, (_, data) => data);

// Fetch clients
$taskClientFeedback.on(fetchTaskClientFeedback.doneData, (_, data) => data);

// Fetch clients
$taskClientFeedbackSummary.on(fetchTaskClientFeedbackSummary.doneData, (_, data) => data);

// Set query hydration finished flag
$isQueryHydrationFinished.on(setIsQueryHydrationFinished, (_, data) => data);

// Set platform client user filter data
$platformTaskClientFeedbackData.on(setPlatformTaskClientFeedbackFilterData, (_, data) => data);