import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Callout, Intent, Tooltip } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { taskStateInformation } from '@data/consts';
import moment from 'moment';
import { openModal } from '@modals/store/events';
import ResumeTaskModal, { ResumeTaskModalProps } from '@pages/Task/modals/ResumeTaskModal';
import ArchiveTaskModal, { ArchiveTaskModalProps } from '@pages/Task/modals/ArchiveTaskModal';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Paused: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const task = useStore($task);

    const [isArchiveAllowed, setIsArchiveAllowed] = useState(false);
    const [isResumeAllowed, setIsResumeAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission
        if (permissions.isRoot.task) {
            setIsArchiveAllowed(true);
            setIsResumeAllowed(true);
            return;
        }

        // Permission: archive
        setIsArchiveAllowed(permissions.has(TaskPermission.ARCHIVE));

        // Permission: Resume
        if (permissions.has(TaskPermission.PAUSE_MANAGE)) {
            setIsResumeAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsResumeAllowed(isAssigned && permissions.has(TaskPermission.PAUSE_MANAGE_MOD_ASSIGNED));
        }

    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // Get archived timestamp
    const pausedAt = moment.utc(task.paused_at).format('DD MMM, YYYY');

    const renderTaskResumeFlowButton = () => {
        if (!isResumeAllowed) {
            return null;
        }

        return (
            <Button
                minimal
                icon="history"
                onClick={
                    () => openModal<ResumeTaskModalProps>({
                        ModalComponent: ResumeTaskModal, data: {
                            taskId: task.id,
                            ownerUserId: task.user.id,
                            taskPublishType: task.publish?.type,
                            currentTaskQueue: task.queue,
                            currentTaskState: task.state,
                        },
                    })
                }
            >Resume request</Button>
        );
    };

    const renderTaskArchiveFlowButton = () => {
        if (!isArchiveAllowed) {
            return null;
        }

        return (
            <Tooltip content="Archive request">
                <Button
                    minimal
                    icon="trash"
                    intent={Intent.DANGER}
                    onClick={
                        () => openModal<ArchiveTaskModalProps>({
                            ModalComponent: ArchiveTaskModal, data: {
                                taskId: task.id,
                            },
                        })
                    }
                />
            </Tooltip>
        );
    };

    // Implicit state: archived
    return (
        <Callout intent={Intent.WARNING} icon="pause">
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Heading type="h4">Request is paused @ {pausedAt}</Heading>
                </Flex>
                <Flex>
                    <div className="mr-1">{renderTaskResumeFlowButton()}</div>
                    <div>{renderTaskArchiveFlowButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Before it was paused, request was in "{taskStateInformation[task.state].title}" state
            </DevText>
        </Callout>
    );
};

export default Paused;
