import { $licences, $taskCategories, $taskCategoryGroups, $virtualLicences } from './states';
import { fetchLicences, fetchTaskCategories, fetchTaskCategoryGroups, fetchVirtualLicences } from './effects';

// Fetch virtual licence
$virtualLicences.on(fetchVirtualLicences.doneData, (_, data) => data);

// Fetch licences
$licences.on(fetchLicences.doneData, (_, data) => data);

// Fetch task category groups
$taskCategoryGroups.on(fetchTaskCategoryGroups.doneData, (_, data) => data);

// Fetch task categories
$taskCategories.on(fetchTaskCategories.doneData, (_, data) => data);
