import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription paused
 * @param subscription subscription for which pause should be checked
 * @returns {boolean} subscription pause flag
 */
export default function checkIfSubscriptionPaused(subscription: SubscriptionResource) {
    return subscription.pause.is_paused;
}
