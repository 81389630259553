import { createApi } from 'effector';
import { $authenticationFormStep } from './states';
import { AuthenticationFormStep } from '../enums';

// API to manage step pathway
export const authenticationFormStepApi = createApi($authenticationFormStep, {
    // Move to specified step
    moveToStep: (_, step: AuthenticationFormStep) => {
        return step;
    },
});
