import moment from 'moment';
import { createApi } from 'effector';
import { UpdatePlanInformationInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import {
    PlanQuotaAttachmentResource,
    PlanResource,
    PlanTaskCategoryAttachmentResource,
    PriceResource as PlanPriceResource,
    TaskCategoryGroupResource,
} from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Price/resources';
import { $plan } from './states';

export const planApi = createApi($plan, {
    update: (
        store,
        payload: {
            input: UpdatePlanInformationInput;
        }
    ) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload.input,
        };
    },

    addPrice: (
        store,
        payload: { input: UpdatePricePlanAttachmentDataInput; price: PriceResource | PlanPriceResource }
    ) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        const price: PlanPriceResource = {
            ...payload.price,
            is_legacy: payload.input.is_legacy,
            plan_visibility: payload.input.plan_visibility,
        };

        return {
            ...store,
            prices: [price, ...store.prices],
        };
    },

    updatePrice: (
        store,
        payload: { priceId: ID; input: UpdatePricePlanAttachmentDataInput; price: PriceResource | PlanPriceResource }
    ) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        // Copy plan prices
        const copiedPrices = [...store.prices];

        // Find price index in array
        const foundPriceIndex = copiedPrices.findIndex((price) => price.id === payload.priceId);

        if (foundPriceIndex === -1) {
            // Price was NOT found
            return store;
        }

        copiedPrices[foundPriceIndex] = {
            ...payload.price,
            is_legacy: payload.input.is_legacy,
            plan_visibility: payload.input.plan_visibility,
        };

        return {
            ...store,
            prices: copiedPrices,
        };
    },

    removePrice: (store, payload: { priceId: ID }) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        // Copy plan prices
        const copiedPrices = [...store.prices];

        // Find price index in array
        const foundPriceIndex = copiedPrices.findIndex((price) => price.id === payload.priceId);

        if (foundPriceIndex === -1) {
            // Price was NOT found
            return store;
        }

        // Remove price
        copiedPrices.splice(foundPriceIndex, 1);

        return {
            ...store,
            prices: copiedPrices,
        };
    },

    archive: (store) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        const momentNowUtc = moment().utc().format();
        const planCopy: PlanResource = { ...store, archived_at: momentNowUtc };

        return {
            ...planCopy,
        };
    },

    restore: (store) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        const planCopy: PlanResource = { ...store, archived_at: null };

        return {
            ...planCopy,
        };
    },

    publish: (store) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        return {
            ...store,
            published_at: moment().toISOString(),
        };
    },

    updateTaskCategoryGroupList: (store, payload: TaskCategoryGroupResource[]) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        return {
            ...store,
            task_category_groups: payload,
        };
    },

    updateTaskCategoryList: (store, payload: PlanTaskCategoryAttachmentResource[]) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        return {
            ...store,
            task_categories: payload,
        };
    },

    updateQuotaList: (store, payload: PlanQuotaAttachmentResource[]) => {
        if (!store) {
            // Plan was NOT fetched yet
            return store;
        }

        return {
            ...store,
            quotas: payload,
        };
    },
});
