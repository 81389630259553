import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringRequiredMessage = 'This field is required';
const getStringRequiredValidator: GetValidatorFunction<{ stringRequiredMessage?: string }, string> = (
    options = { stringRequiredMessage: defaultStringRequiredMessage }
) => {
    const { stringRequiredMessage } = options;

    return async (value) => {
        if (value) {
            // String is NOT empty
            return null;
        } else {
            // String is empty
            if (stringRequiredMessage) {
                return stringRequiredMessage;
            } else {
                return defaultStringRequiredMessage;
            }
        }
    };
};

export default getStringRequiredValidator;
