import { CoreUserResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { UserImageHashRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import { createApi } from 'effector';
import moment from 'moment';
import { $coreUser } from './states';

export const coreUserApi = createApi($coreUser, {
    // Update
    update: (store, payload: Partial<CoreUserResource>) => {
        // Core user was NOT fetched yet
        if (!store) {
            return store;
        }

        return {
            ...store,
            ...payload,
        };
    },

    // Verify
    verify: (store) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        // Get moment now UTC
        const momentNowUtc = moment().utc().format();

        return {
            ...store,
            verified_at: momentNowUtc,
        };
    },

    // Remove verification
    removeVerification: (store) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        return {
            ...store,
            verified_at: null,
        };
    },

    // Set image
    // TODO: move?
    setImageHash: (store, payload: UserImageHashRef) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: payload.hash,
        };
    },

    // Remove image
    // TODO: move?
    removeImage: (store) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: null,
        };
    },
});
