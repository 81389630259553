import React from 'react';
import Box, { Props as BoxProps } from '@components/Box';
import { StyledImage } from './styled';

export interface ImageProps {
    src?: string | null;
    alt?: string;
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down' | 'inherit' | 'initial' | 'unset';
}

export type Props = ImageProps & BoxProps;

const Image: React.FC<Props> = ({ objectFit = 'contain', src, alt, ...props }) => {
    const imageSrc = src ?? undefined;

    return (
        <Box {...props}>
            <StyledImage src={imageSrc} $objectFit={objectFit} alt={alt} />
        </Box>
    );
};

export default Image;
