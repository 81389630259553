import { BrandProfileAssetCategory } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/enums';

const brandProfileAssetCategoriesInformation: { [asset in BrandProfileAssetCategory]: string } = {
    [BrandProfileAssetCategory.IMAGE]: 'Image',
    [BrandProfileAssetCategory.LOGO]: 'Logo',
    [BrandProfileAssetCategory.OTHER]: 'Other',
    [BrandProfileAssetCategory.STYLE_GUIDE]: 'Style guide',
    [BrandProfileAssetCategory.TEMPLATE]: 'Template',
};

export default brandProfileAssetCategoriesInformation;
