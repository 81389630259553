import {
    PickableTeamParticipantUserResource,
    TeamResource,
} from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import domain from './domain';
import { resetTeam, resetTeamOccupancy, resetTeamPickableParticipantUsers, resetUserOccupancyByTeamParticipantUserIdMap } from './events';
import { TeamOccupancyResource } from 'dy-frontend-http-repository/lib/modules/TeamOccupancy/resources';
import { UserOccupancyByTeamParticipantUserIdMap } from '../types';

// Team pickable user
export const $teamPickableParticipantUsers = domain
    .createStore<CollectionResource<PickableTeamParticipantUserResource, {}> | null>(null)
    .reset(resetTeamPickableParticipantUsers);

// Team resource
export const $team = domain.createStore<TeamResource | null>(null).reset(resetTeam);

// Team occupancy
export const $teamOccupancy= domain.createStore<CollectionResource<TeamOccupancyResource, {}> | null>(null).reset(resetTeamOccupancy);

// Team members occupancy
export const $userOccupancyByTeamParticipantUserIdMap= domain.createStore<UserOccupancyByTeamParticipantUserIdMap | null>(null).reset(resetUserOccupancyByTeamParticipantUserIdMap);
