import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import {
    UserNotificationEmailFilterInput,
} from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import { UserNotificationEmailRef } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/refs';
import { UserNotificationEmailResource } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/resources';
import domain from './domain';

// Client notification emails
export const fetchNotificationEmails = domain.createEffect<
    UserNotificationEmailFilterInput,
    CollectionResource<UserNotificationEmailResource, {}>
>({
    name: 'fetchNotificationEmails',
    handler: async (filter) => {
        return await repository.userNotificationEmail().get(filter);
    },
});

// Remove  notification email
export const removeNotificationEmail = domain.createEffect<ID, UserNotificationEmailRef>({
    name: 'removeNotificationEmail',
    handler: async (notificationEmailId) => {
        return await repository.userNotificationEmail().remove(notificationEmailId);
    },
});

// Verify notification email
export const verifyNotificationEmail = domain.createEffect<ID, UserNotificationEmailRef>({
    name: 'verifyNotificationEmail',
    handler: async (notificationEmailId) => {
        return await repository.userNotificationEmail().verify(notificationEmailId);
    },
});

// Remove  notification verification
export const removeNotificationEmailVerification = domain.createEffect<ID, UserNotificationEmailRef>({
    name: 'removeNotificationEmailVerification',
    handler: async (notificationEmailId) => {
        return await repository.userNotificationEmail().removeVerification(notificationEmailId);
    },
});

// Generate new verification code
export const generateNotificationEmailVerificationCode = domain.createEffect<ID, UserNotificationEmailRef>({
    name: 'verifyNotificationEmail',
    handler: async (notificationEmailId) => {
        return await repository.userNotificationEmail().generateVerificationCode(notificationEmailId);
    },
});
