import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { PlanFilterInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import domain from './domain';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceRef } from 'dy-frontend-http-repository/lib/modules/Price/refs';

// Fetch plans
export const fetchPlans = domain.createEffect<PlanFilterInput, CollectionResource<PlanResource, {}>>({
    name: 'fetchPlans',
    handler: async (filter) => {
        return await repository.plan().get(filter);
    },
});

// Update plan to price attachment
export const updatePriceToPlanAttachment = domain.createEffect<
    { priceId: ID; input: UpdatePricePlanAttachmentDataInput },
    PriceRef
>({
    name: 'updatePriceToPlanAttachment',
    handler: async ({ priceId, input }) => {
        return await repository.price().updatePlanAttachmentData(priceId, input);
    },
});
