import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { openModal } from '@modals/store/events';
import { $coreUser } from '@pages/CoreUser/store/states';
import Flex from '@components/Flex';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import UpdateCoreUserMainInformationModal, {
    UpdateCoreUserMainInformationModalProps,
} from '@app/containers/pages/CoreUser/modals/UpdateCoreUserMainInformationModal';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Information: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const coreUser = useStore($coreUser);

    if (!coreUser || !me) {
        return null;
    }

    const isInfoUpdateAllowed = (
        coreUser.id === me.user.id ?
            permissions.isRoot.selfUser || permissions.has(SelfUserPermission.INFO_UPDATE)
            : permissions.isRoot.coreUser || permissions.has(CoreUserPermission.INFO_UPDATE)
    );

    const renderUpdateInfoButton = () => {
        if (!isInfoUpdateAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateCoreUserMainInformationModalProps>({
                        ModalComponent: UpdateCoreUserMainInformationModal,
                        data: {
                            coreUserId: coreUser.id,
                            firstName: coreUser.first_name,
                            lastName: coreUser.last_name,
                            companyPosition: coreUser.company_position,
                            companyName: coreUser.company_name,
                            email: coreUser.email,
                            timezoneName: coreUser.timezone_name,
                            phone: coreUser.phone,
                            addressCountry: coreUser.address_country,
                            bio: coreUser.bio,
                        },
                    })
                }
            />
        );
    }

    return (
        <Card {...props}>
            <Flex align="center" justify="space-between">
                <Heading type="h4">Main information</Heading>

                {renderUpdateInfoButton()}
            </Flex>

            {/* Email */}
            <CommonInformationBlock className="mt-2" icon="envelope" title="Email" text={coreUser.email} />

            {/* Phone number */}
            <CommonInformationBlock className="mt-2" icon="phone" title="Phone number" text={coreUser.phone} />

            {/* Country */}
            <CommonInformationBlock
                className="mt-2"
                icon="map-marker"
                title="Country"
                text={countriesData[coreUser.address_country]?.countryName ?? ''}
            />

            {/* Timezone */}
            <CommonInformationBlock className="mt-2" icon="time" title="Timezone" text={coreUser.timezone_name} />

            {/* About */}
            <CommonInformationBlock className="mt-2" title="About" text={coreUser.bio} />
        </Card>
    );
};

export default Information;
