import { checkValidators, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';
import { ItemRendererProps } from '@blueprintjs/select';
import Select from '@components/Select';
import { updateAnchorInformation } from '@data/consts';
import { SubscriptionUpdateAnchor } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import moment from 'moment';
import React, { HTMLAttributes, useEffect } from 'react';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

export interface SubscriptionUpdateAnchorSelectorProps {
    updateAnchor: SubscriptionUpdateAnchor | null;
    updateAnchorError: string | null;
    updateAnchorAt: Date | null;
    updateAnchorAtError: string | null;
    onChangeUpdateAnchor: (newUpdateAnchor: SubscriptionUpdateAnchor | null) => void;
    onChangeUpdateAnchorError: (newUpdateAnchorError: string | null) => void;
    onChangeUpdateAnchorAt: (newUpdateAnchorAt: Date | null) => void;
    onChangeUpdateAnchorAtError: (updateAnchorAtError: string | null) => void;
}
export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionUpdateAnchorSelectorProps;

const updateAnchorValidators = [getStringRequiredValidator()];
const updateAnchorAtValidators = [getStringRequiredValidator()];

const SubscriptionUpdateAnchorSelector: React.FC<Props> = ({
    updateAnchor,
    updateAnchorAt,
    updateAnchorError,
    updateAnchorAtError,
    onChangeUpdateAnchor,
    onChangeUpdateAnchorError,
    onChangeUpdateAnchorAt,
    onChangeUpdateAnchorAtError,
    ...props
}) => {
    const permissions = useStore($permissions);

    useEffect(() => {
        // Validate anchor
        const validateUpdateAnchor = async () => {
            const validationResult = await checkValidators<string>(updateAnchor ?? '', updateAnchorValidators);
            onChangeUpdateAnchorError(validationResult);
        };
        validateUpdateAnchor();
    }, [updateAnchor]);

    useEffect(() => {
        // Validate anchor at
        const validateUpdateAnchorAt = async () => {
            if (updateAnchor !== SubscriptionUpdateAnchor.MANUAL) {
                onChangeUpdateAnchorAtError(null);
                onChangeUpdateAnchorAt(null);
                return;
            }

            const validationResult = await checkValidators<string>(
                updateAnchorAt ? updateAnchorAt.toString() : '',
                updateAnchorAtValidators
            );
            onChangeUpdateAnchorAtError(validationResult);
        };

        validateUpdateAnchorAt();
    }, [updateAnchor, updateAnchorAt]);

    const renderUpdateAnchorSelect = () => {
        const renderItem = (item: SubscriptionUpdateAnchor, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item === updateAnchor;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item}
                    text={updateAnchorInformation[item]}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: SubscriptionUpdateAnchor) => {
            onChangeUpdateAnchor(item);
        };

        const anchorList = [
            SubscriptionUpdateAnchor.NOW,
            SubscriptionUpdateAnchor.CURRENT_PERIOD_END,
        ];
        const isManualAnchorAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.UPDATE_MANUAL_ANCHOR);
        if (isManualAnchorAllowed) {
            anchorList.push(SubscriptionUpdateAnchor.MANUAL);
        }

        return (
            <FormGroup
                label="Select when to apply this update"
                intent={!!updateAnchorError ? Intent.DANGER : Intent.NONE}
                helperText={updateAnchorError ?? ''}
            >
                <Select<SubscriptionUpdateAnchor>
                    items={anchorList}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select when to apply this update',
                        text: updateAnchor ? updateAnchorInformation[updateAnchor] : '',
                    }}
                />
            </FormGroup>
        );
    };

    const renderUpdateAnchorAtDateInput = () => {
        if (updateAnchor !== SubscriptionUpdateAnchor.MANUAL) {
            return null;
        }

        const handleChangeUpdateAnchorAt = (newUpdateAnchorAt: string | null) => {
            onChangeUpdateAnchorAt(moment(newUpdateAnchorAt).toDate());
        };

        return (
            <FormGroup
                intent={!!updateAnchorAtError ? Intent.DANGER : Intent.NONE}
                helperText={updateAnchorAtError ?? ''}
            >
                <DateInput3
                    fill
                    highlightCurrentDay
                    placeholder="Pick update date"
                    value={updateAnchorAt ? moment(updateAnchorAt).format() : null}
                    minDate={moment().add(1, 'days').toDate()}
                    initialMonth={moment().add(1, 'days').toDate()}
                    inputProps={{ intent: !!updateAnchorAtError ? Intent.DANGER : Intent.NONE, leftIcon: 'calendar' }}
                    popoverProps={{ usePortal: false }}
                    dayPickerProps={{ firstWeekContainsDate: 1 }}
                    formatDate={(date) => moment(date).format('D MMM, YYYY')}
                    parseDate={(str) => new Date(str)}
                    onChange={handleChangeUpdateAnchorAt}
                />
            </FormGroup>
        );
    };

    return (
        <div {...props}>
            {renderUpdateAnchorSelect()}
            {renderUpdateAnchorAtDateInput()}
        </div>
    );
};

export default SubscriptionUpdateAnchorSelector;
