import styled, { css } from 'styled-components';
import { PlatformListFilterProps } from '.';

export const List = styled.ul<{ $maxListHeight?: PlatformListFilterProps<any>['maxListHeight'] }>`
    padding: 0;
    margin: 0;
    list-style: none;

    li + li {
        margin-top: 8px;
    }

    ${({ $maxListHeight }) =>
        $maxListHeight &&
        css`
            max-height: ${$maxListHeight}px;
            overflow-y: auto;
        `}
`;

export const Content = styled.div<{
    $minWidth?: PlatformListFilterProps<any>['minWidth'];
}>`
    padding: 16px;
    min-width: 230px;

    ${({ $minWidth }) =>
        $minWidth &&
        css`
            min-width: ${$minWidth}px;
        `}
`;
