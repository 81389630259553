import { $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import UpdateTaskCategoryModal, { UpdateTaskCategoryModalProps } from '../../modals/UpdateTaskCategoryModal';
import { $taskCategory } from '../../store/states';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Information: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);

    if (!taskCategory) {
        return null;
    }

    const renderTitle = () => {
        if (taskCategory.title.length === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.title}</DevText>;
    };

    const renderSummary = () => {
        if (taskCategory.summary.length === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.summary}</DevText>;
    };

    const renderDescription = () => {
        if (taskCategory.description.length === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.description}</DevText>;
    };

    const renderDeliverablesSummary = () => {
        if (taskCategory.summary_deliverables.length === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.summary_deliverables}</DevText>;
    };

    const renderDeliveryDays = () => {
        if (taskCategory.average_delivery_days === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.average_delivery_days}</DevText>;
    };

    const renderPrice = () => {
        if (!taskCategory.price) {
            return <DevText muted>No price set</DevText>;
        }

        return (
            <DevText>
                {PriceUtils.formatPrice({ price: taskCategory.price.unit_amount, shouldDisplayCents: true })}
            </DevText>
        );
    };

    const renderCreditAmount = () => {
        if (taskCategory.credit_amount === 0) {
            return <DevText muted>No data</DevText>;
        }

        return <DevText>{taskCategory.credit_amount}</DevText>;
    };

    const renderUpdateTaskCategory = () => {

        const isAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateTaskCategoryModalProps>({
                        ModalComponent: UpdateTaskCategoryModal,
                        data: {
                            taskCategory,
                        },
                    })
                }
            >
                Edit
            </Button>
        );
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Main Information</Heading>
                {renderUpdateTaskCategory()}
            </Flex>

            <Grid container>
                <Grid className="mb-2" lg={6}>
                    <DevText muted className="mb-small">
                        Title
                    </DevText>

                    {renderTitle()}
                </Grid>

                <Grid className="mb-2" lg={6}>
                    <DevText muted className="mb-small">
                        Short description
                    </DevText>

                    {renderSummary()}
                </Grid>

                <Grid className="mb-2" lg={6}>
                    <DevText muted className="mb-small">
                        Full description
                    </DevText>

                    {renderDescription()}
                </Grid>

                <Grid className="mb-2" lg={6}>
                    <DevText muted className="mb-small">
                        Deliverables description
                    </DevText>

                    {renderDeliverablesSummary()}
                </Grid>

                <Grid className="mb-2" lg={6}>
                    <DevText muted className="mb-small">
                        Delivery days
                    </DevText>

                    {renderDeliveryDays()}
                </Grid>

                <Grid lg={6}>
                    <DevText muted className="mb-small">
                        Price
                    </DevText>

                    {renderPrice()}
                </Grid>

                <Grid lg={6}>
                    <DevText muted className="mb-small">
                        Task credit price
                    </DevText>

                    {renderCreditAmount()}
                </Grid>
            </Grid>
        </Card>
    );
};

export default Information;
