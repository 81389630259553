import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { Button, Card, Classes, Intent, Tag, Tooltip } from '@blueprintjs/core';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { openModal } from '@modals/store/events';
import CreatePlanToPriceAttachmentModal, {
    CreatePlanToPriceAttachmentModalProps,
} from '@pages/Plan/modals/CreatePlanToPriceAttachmentModal';
import { PricePlanVisibility } from 'dy-frontend-http-repository/lib/data/enums';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import UpdatePlanToPriceAttachmentModal, {
    UpsertPlanToPriceAttachmentModalProps,
} from '@pages/Plan/modals/UpdatePlanToPriceAttachmentModal';
import ConfirmationPopover from '@components/ConfirmationPopover';
import { removePriceFromPlan } from '@pages/Plan/store/effects';
import { planApi } from '@pages/Plan/store/apis';
import { $permissions } from '@containers/store/states';
import { PricePermission } from 'dy-frontend-permissions/lib/permission';

// Section: List of prices, referencing plan
const PriceList: React.FC = () => {
    const permissions = useStore($permissions);

    const plan = useStore($plan);

    const [removingPriceId, setRemovingPriceId] = useState<ID | null>(null);

    if (!plan) {
        return null;
    }

    // Flag if price auth module is enabled
    const isPriceAuthModuleEnabled = (
        permissions.has(PricePermission._ENABLED)
        || permissions.has(PricePermission.ROOT)
    );

    // Root access to prices
    const isPriceRootAccess = permissions.has(PricePermission.ROOT);

    const isPriceManagementAllowed = (
        isPriceAuthModuleEnabled
        && (isPriceRootAccess || permissions.has(PricePermission.PLAN_MANAGE))
    );

    // Handles removal of a price by its ID
    const handlePriceRemove = async (priceId: ID) => {
        setRemovingPriceId(priceId);

        try {
            await removePriceFromPlan(priceId);
            planApi.removePrice({ priceId: priceId });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingPriceId(null);
        }
    };

    // Renders single price item
    const renderItem = (item: PriceResource) => {
        // Renders usage info tag
        const renderUsageTag = () => {
            if (item.plan_visibility === PricePlanVisibility.PUBLIC) {
                return (
                    <Tooltip content="Visible and can be used from client's dashboard">
                        <Tag minimal intent={Intent.SUCCESS} className="mr-1">
                            Public usage
                        </Tag>
                    </Tooltip>
                );
            }

            if (item.plan_visibility === PricePlanVisibility.INTERNAL) {
                return (
                    <Tooltip content="Visible and can be used only in the internal dashboard">
                        <Tag minimal intent={Intent.WARNING} className="mr-1">
                            Only internal usage
                        </Tag>
                    </Tooltip>
                );
            }

            return null;
        };

        // Renders tag if price is marked as legacy
        const renderLegacyTag = () => {
            if (!item.is_legacy) {
                return null;
            }

            return (
                <Tooltip content="Price is marked as legacy">
                    <Tag minimal intent={Intent.NONE} className="mr-1">
                        Legacy
                    </Tag>
                </Tooltip>
            );
        };

        // Renders list of price-related actions
        const renderPriceActionList = () => {
            if (!isPriceManagementAllowed) {
                return null;
            }

            const isRemovingPrice = removingPriceId === item.id;

            return (
                <Flex>
                    <Button
                        className="mr-1"
                        icon="edit"
                        onClick={() =>
                            openModal<UpsertPlanToPriceAttachmentModalProps>({
                                ModalComponent: UpdatePlanToPriceAttachmentModal,
                                data: {
                                    planId: plan.id,
                                    price: item,
                                },
                            })
                        }
                    >
                        Update
                    </Button>
                    <ConfirmationPopover
                        title={'Remove price from plan?'}
                        description={'After removing, this price will no longer give uses this plan'}
                        actions={[
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS}
                                onClick={() => handlePriceRemove(item.id)}
                            >
                                Yes, remove
                            </Button>,
                        ]}
                    >
                        <Tooltip content="Remove price from plan">
                            <Button
                                minimal
                                disabled={removingPriceId !== null || isRemovingPrice}
                                loading={isRemovingPrice}
                                icon="trash"
                                intent={Intent.DANGER}
                            />
                        </Tooltip>
                    </ConfirmationPopover>
                </Flex>
            );
        };

        const formattedPrice = PriceUtils.formatPrice({
            price: item.unit_amount,
            shouldDisplayCents: true,
        });

        return (
            <Card className="mb-1">
                <Flex align="center" justify="space-between">
                    <Heading type="h4" className="mb-1">
                        {formattedPrice} {item.currency.toUpperCase()}{' '}
                        <DevText inline muted>
                            / every {item.recurring_interval_count} {item.recurring_interval}
                        </DevText>
                    </Heading>
                    <div>{renderPriceActionList()}</div>
                </Flex>
                <Flex align="center">
                    {renderUsageTag()}
                    {renderLegacyTag()}
                    <DevText muted>Stripe ID: {item.external_id}</DevText>
                </Flex>
            </Card>
        );
    };

    // Renders list of prices
    const renderList = (list: PriceResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No prices are associated with this plan...</DevText>
                </div>
            );
        }

        return list.map((i) => <div key={`plan-price-${i.id}`}>{renderItem(i)}</div>);
    };

    // Renders action, invoking price add flow
    const renderAddPriceAction = () => {
        if (!isPriceManagementAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() => {
                    openModal<CreatePlanToPriceAttachmentModalProps>({
                        ModalComponent: CreatePlanToPriceAttachmentModal,
                        data: {
                            planId: plan.id,
                            planType: plan.type,
                        },
                    });
                }}
            >
                Add price
            </Button>
        );
    };

    return (
        <div>
            <Flex justify="space-between">
                <Heading type="h4">Pricing</Heading>
                {renderAddPriceAction()}
            </Flex>
            <DevText muted className="mb-1">
                Pricing for this plan
            </DevText>
            {renderList(plan.prices)}
        </div>
    );
};

export default PriceList;
