import repository from 'dy-frontend-http-repository/lib/repository';
import { TransitionOpenTaskQueueInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';

// Change queue of the open task
export const changeTaskQueue = domain.createEffect<{ taskId: ID; input: TransitionOpenTaskQueueInput }, TaskRef>({
    name: 'changeTaskQueue',
    handler: async ({ taskId, input }) => {
        return await repository.task().transitionOpenTaskQueue(taskId, input);
    },
});
