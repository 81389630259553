import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { LicenceResource } from 'dy-frontend-http-repository/lib/modules/Licence/resources';
import { VirtualLicenceResource } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import domain from './domain';
import {
    resetVirtualLicences,
    resetLicences,
    resetTaskCategoryGroups,
    resetTaskCategories,
} from './events';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';

// Virtual licences
export const $virtualLicences = domain
    .createStore<CollectionResource<VirtualLicenceResource, {}> | null>(null)
    .reset(resetVirtualLicences);

// Licences
export const $licences = domain.createStore<CollectionResource<LicenceResource, {}> | null>(null).reset(resetLicences);

// Task category groups
export const $taskCategoryGroups = domain
    .createStore<CollectionResource<TaskCategoryGroupListItemResource, {}> | null>(null)
    .reset(resetTaskCategoryGroups);

// Task categories
export const $taskCategories = domain
    .createStore<CollectionResource<TaskCategoryListItemResource, {}> | null>(null)
    .reset(resetTaskCategories);
