import React, { HTMLAttributes } from 'react';
import { Classes, Colors } from '@blueprintjs/core';

export interface HeadingProps {
    muted?: boolean;
    type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: string;
}

export type Props = HTMLAttributes<HTMLElement> & HeadingProps;

const Heading: React.FC<Props> = ({
    muted = false,
    children,
    className,
    type = 'h1',
    color = Colors.WHITE,
    ...props
}) => {
    const classes = `
        ${Classes.HEADING}
        ${className ?? ''}
    `;

    const commonStyles = {
        color: muted ? Colors.GRAY4 : color,
    };

    const commonHeadingProps = {
        className: classes,
        ...props,
    };

    switch (type) {
        case 'h1':
            return (
                <h1 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h1>
            );
        case 'h2':
            return (
                <h2 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h2>
            );
        case 'h3':
            return (
                <h3 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h3>
            );
        case 'h4':
            return (
                <h4 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h4>
            );
        case 'h5':
            return (
                <h5 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h5>
            );
        case 'h6':
            return (
                <h6 style={commonStyles} {...commonHeadingProps}>
                    {children}
                </h6>
            );
        default:
            return (
                <h1 style={{ backgroundColor: '#ff0000' }} {...commonHeadingProps}>
                    {children}
                </h1>
            );
    }
};

export default Heading;
