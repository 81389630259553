import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, Radio } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import React, { useState } from 'react';
import { updateOccupancySettings } from './store/effects';

export interface UpdateCoreUserOccupancySettingsModalProps {
    coreUserId: ID;
    maxTotalTaskComplexity: number;
}

type Props = ModalProps<UpdateCoreUserOccupancySettingsModalProps>;

const maxTotalTaskComplexityValidators = [
    getStringRequiredValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 1 }),
];

const UpdateCoreUserOccupancySettingsModal: React.FC<Props> = ({ closeModal, data }) => {
    const maxTotalTaskComplexity = useTextFormField({
        id: 'max-total-task-complexity',
        validators: maxTotalTaskComplexityValidators,
        initialValue: data?.maxTotalTaskComplexity ? `${data.maxTotalTaskComplexity}` : '100',
    });

    const [isDefaultMaxTotalTaskComplexity, setIsDefaultMaxTotalTaskComplexity] = useState(false);

    const handleCloseModal = () => {
        closeModal?.();
    };

    const getFormFields = () => {
        if (isDefaultMaxTotalTaskComplexity) {
            return [];
        }

        return [maxTotalTaskComplexity];
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            if (!data) {
                throw new Error('Data is not provided');
            }

            try {
                await updateOccupancySettings({
                    coreUserId: data.coreUserId,
                    input: {
                        max_total_task_complexity: isDefaultMaxTotalTaskComplexity
                            ? null
                            : parseInt(maxTotalTaskComplexity.value),
                    },
                });
                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            handleCloseModal();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        handleCloseModal();
        return null;
    }

    const renderMaxTotalTaskComplexityInput = () => {
        if (isDefaultMaxTotalTaskComplexity) {
            return null;
        }

        return (
            <InputFormField
                field={maxTotalTaskComplexity}
                inputProps={{ placeholder: 'Enter a link' }}
            />
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update occupancy ratio</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <FormGroup label="Max total request complexity">
                        <Radio
                            className="mb-1"
                            checked={isDefaultMaxTotalTaskComplexity}
                            label="Use default"
                            onChange={() => setIsDefaultMaxTotalTaskComplexity(true)}
                        />

                        <Radio
                            className="mb-1"
                            checked={!isDefaultMaxTotalTaskComplexity}
                            label="Use custom"
                            onChange={() => setIsDefaultMaxTotalTaskComplexity(false)}
                        />

                        {renderMaxTotalTaskComplexityInput()}
                    </FormGroup>

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateCoreUserOccupancySettingsModal;
