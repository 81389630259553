import React, { AnchorHTMLAttributes } from 'react';
import { Intent } from '@blueprintjs/core';
import { StyledLink } from './styled';

export interface LinkProps {
    underline?: boolean;
    showUnderlineOnHover?: boolean;
    fontSize?: number;
    fontWeight?: number;
    lineHeight?: number;
    inline?: boolean;
    color?: string;
}

export type Props = LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link: React.FC<Props> = ({
    underline = false,
    showUnderlineOnHover = true,
    inline = true,
    fontSize,
    fontWeight,
    lineHeight,
    color,
    ...props
}) => {
    return (
        <StyledLink
            $underline={underline}
            $showUnderlineOnHover={showUnderlineOnHover}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $inline={inline}
            $color={color}
            {...props}
        />
    );
};

export default Link;
