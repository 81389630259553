import { GoogleEmbedType } from './enums';

export default class TaskDeliverableGoogleEmbed {
    link: string;

    constructor(link: string) {
        this.link = link;
    }

    // TODO: put validation to config in order to not adjust this class every time new GoogleEmbedType is added (even though it will probably not happen)

    isTypeOf = (type: GoogleEmbedType): boolean => {
        switch (type) {
            case GoogleEmbedType.DOCUMENT:
                return this.link.includes('/document');
            case GoogleEmbedType.FOLDERS:
                return this.link.includes('/folders') && this.link.includes('?usp=sharing');
            case GoogleEmbedType.PRESENTATION:
                return this.link.includes('/presentation');
            case GoogleEmbedType.SPREADSHEETS:
                return this.link.includes('/spreadsheets');
        }
    };

    getType = (): GoogleEmbedType | null => {
        if (this.isTypeOf(GoogleEmbedType.DOCUMENT)) {
            return GoogleEmbedType.DOCUMENT;
        }

        if (this.isTypeOf(GoogleEmbedType.FOLDERS)) {
            return GoogleEmbedType.FOLDERS;
        }

        if (this.isTypeOf(GoogleEmbedType.PRESENTATION)) {
            return GoogleEmbedType.PRESENTATION;
        }

        if (this.isTypeOf(GoogleEmbedType.SPREADSHEETS)) {
            return GoogleEmbedType.SPREADSHEETS;
        }

        return null;
    };

    getViewerLink = (): string => {
        const googleEmbedType = this.getType();

        if (!googleEmbedType) {
            return this.link;
        }

        switch (googleEmbedType) {
            case GoogleEmbedType.FOLDERS:
                const splittedLinkArr = this.link.split('/');
                const idIndex = splittedLinkArr.indexOf('folders') + 1;

                if (splittedLinkArr[idIndex].includes('?')) {
                    const folderId = splittedLinkArr[idIndex].split('?')[0];
                    return `https://drive.google.com/embeddedfolderview?id=${folderId}`;
                } else {
                    return splittedLinkArr[idIndex];
                }
            default:
                return this.link;
        }
    };
}
