import { Card, Elevation, Icon, Intent, Tag, Tooltip } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';
import Box from '@components/Box';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import FilePreview from '@app/components/FilePreview';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import Flex from '@app/components/Flex';
import Link from '@app/components/Link';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import DevText from '@app/components/Text';
import moment from 'moment';

export interface TaskDeliverableListGridViewListItemProps {
    taskDeliverable: TaskDeliverableResource;
    actions?: React.ReactNode;
    onOpenViewer: () => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskDeliverableListGridViewListItemProps;

const TaskDeliverableListGridViewListItem: React.FC<Props> = ({ taskDeliverable, actions, onOpenViewer, ...props }) => {
    const renderTaskDeliverableApprovementTag = () => {
        if (taskDeliverable.approved_at) {
            // Approved
            return null;
        }

        // Not approved
        return (
            <Tag minimal icon="warning-sign" intent={Intent.WARNING}>
                Not approved
            </Tag>
        );
    };

    const renderTaskDeliverablePreview = () => {
        if (taskDeliverable.file) {
            return (
                <FilePreview
                    className="mr-1"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    style={{ cursor: 'pointer' }}
                    extension={taskDeliverable.file.extension}
                    src={taskDeliverable.file.preview_url}
                    onClick={onOpenViewer}
                />
            );
        }

        // Get icon
        let icon: JSX.Element;
        switch (taskDeliverable.type) {
            case TaskDeliverableType.BLANK:
                icon = <Icon className="mr-1" icon="circle" size={96} />;
                break;
            case TaskDeliverableType.TEXT:
                icon = <Icon className="mr-1" icon="text-highlight" size={96} />;
                break;
            case TaskDeliverableType.EMBED:
                icon = <Icon className="mr-1" icon="insert" size={96} />;
                break;
            default:
                icon = <Icon className="mr-1" icon="error" size={96} />;
        }

        return (
            <Flex
                fullHeight
                fullWidth
                align="center"
                justify="center"
                style={{ cursor: 'pointer' }}
                onClick={onOpenViewer}
            >
                {icon}
            </Flex>
        );
    };

    return (
        <Card compact elevation={Elevation.ONE} {...props}>
            <Box className="mb-1" borderRadius="inherit" height="280px" minHeight="280px" width="100%">
                {renderTaskDeliverablePreview()}
            </Box>

            <div>
                <Flex className="mb-small" direction="row" align="center" justify="space-between">
                    <Tooltip content={taskDeliverable.title}>
                        <Link className="mr-1" onClick={onOpenViewer}>
                            {TextFormatUtils.truncate(taskDeliverable.title, 45)}
                        </Link>
                    </Tooltip>

                    {actions && <Flex align="center">{actions}</Flex>}
                </Flex>

                <Flex direction="row" align="center" justify="space-between">
                    {renderTaskDeliverableApprovementTag()}
                    <DevText muted className="ml-auto">
                        {moment(taskDeliverable.created_at).format('D MMM, YYYY')}
                    </DevText>
                </Flex>
            </div>
        </Card>
    );
};

export default TaskDeliverableListGridViewListItem;
