import styled, {css} from 'styled-components';

export const LogoBlock = styled.div`
    border-radius: 2px;
    background-color: #383E47;
    width: 45px;
    height: 45px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 9px;
`;
