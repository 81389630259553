const popularFonts = [
    'Helvetica',
    'Arial',
    'Arial Black',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Impact',
    'Gill Sans',
    'Times New Roman',
    'Georgia',
    'Palation',
    'Baskerville',
    'Andale Mono',
    'Courier',
    'Lucida',
    'Monaco',
    'Bradley Hand',
    'Brush Script MT',
    'Luminari',
    'Comic Sans MS',
];

export default popularFonts;
