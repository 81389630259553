import React, { useMemo, useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Checkbox, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { $clientOpenTaskListData } from '../../store/states';
import { useStore } from 'effector-react';
import { TaskPublishType, TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { swapTaskQueue } from './store/effects';
import DevText from '@app/components/Text';
import TaskStateTag from '@app/containers/components/TaskStateTag';
import Circle from '@app/components/Circle';
import { SwapTaskQueueInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { clientOpenTaskListDataApi } from '../../store/apis';
import { OpenTaskType } from '@app/data/enums';
import OpenTaskCard from '@app/containers/components/OpenTaskCard';
import { OpenTaskListData } from 'dy-frontend-shared/lib/data/types';

export interface SwapTaskQueueModalProps {
    task: TaskResource;
}

type Props = ModalProps<SwapTaskQueueModalProps>;

const SwapTaskQueueModal: React.FC<Props> = ({ closeModal, data }) => {
    const openTaskListData = useStore($clientOpenTaskListData);
    const isSwappingTaskQueue = useStore(swapTaskQueue.pending);

    const [selectedTargetTask, setSelectedTargetTask] = useState<TaskResource | null>(null);
    const [isQueueAutomationLockPreserved, setIsQueueAutomationLockPreserved] = useState(true);

    const targetTasks = useMemo<TaskResource[]>(() => {
        if (!data) {
            return [];
        }

        if (!openTaskListData) {
            // Open task list was NOT fetched/initialized yet
            return [];
        }

        let tasks: TaskResource[] = [];
        if (data.task.queue === TaskQueue.ACTIVE) {
            tasks = Object.values(openTaskListData.tasks.backlog);
        } else {
            tasks = Object.values(openTaskListData.tasks.active);
        }

        return tasks.filter((task) => task.publish.type === TaskPublishType.QUOTA);
    }, []);

    const handleModalClose = () => {
        closeModal?.();
    };

    if (!data || !openTaskListData) {
        handleModalClose();
        return null;
    }

    const handleSwapTask = async () => {
        if (!selectedTargetTask) {
            return;
        }

        try {
            // Create swap task queue input
            const swapTaskQueueInput: SwapTaskQueueInput = {
                left_task_id: data.task.id,
                right_task_id: selectedTargetTask.id,
                is_queue_automation_lock_preserved: isQueueAutomationLockPreserved,
            };

            // Swap task queue
            await swapTaskQueue(swapTaskQueueInput);

            // Swap task queue in local state
            clientOpenTaskListDataApi.swapTaskQueue({
                isQueueAutomationLockPreserved,
                activeTaskId: data.task.queue === TaskQueue.ACTIVE ? data.task.id : selectedTargetTask.id,
                backlogTaskId: data.task.queue === TaskQueue.BACKLOG ? data.task.id : selectedTargetTask.id,
            });

            // Close modal
            handleModalClose();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderTaskContent = (task: TaskResource) => {
        // Get task owner
        const taskOwner = openTaskListData.users[task.user_id];

        // Get task data
        const taskData: OpenTaskListData = {
            tasks: {
                [OpenTaskType.ACTIVE]: {},
                [OpenTaskType.BACKLOG]: {},
                [OpenTaskType.PAUSED]: {},
            },
            participant_users: {},
            plans: {},
            teams: {},
            users: {
                [taskOwner.id]: taskOwner,
            },
        };

        return <OpenTaskCard hideClientInformation elevation={Elevation.ONE} task={task} taskData={taskData} />;
    };

    const renderSwapTaskInformation = () => {
        return (
            <div>
                <Heading className="mb-1" type="h5">
                    1. Request to swap
                </Heading>

                {renderTaskContent(data.task)}
            </div>
        );
    };

    const renderTargetTaskList = () => {
        const handleSelectTask = (task: TaskResource) => {
            // Task selection flag
            const isTaskSelected = !!selectedTargetTask && task.id === selectedTargetTask.id;

            if (isTaskSelected) {
                return;
            }

            setSelectedTargetTask(task);
        };

        const renderTargetTask = (task: TaskResource, index: number) => {
            // Task selection flag
            const isTaskSelected = !!selectedTargetTask && task.id === selectedTargetTask.id;

            // Get task owner
            const taskOwner = openTaskListData.users[task.user_id];

            // Get task data
            const taskData: OpenTaskListData = {
                tasks: {
                    [OpenTaskType.ACTIVE]: {},
                    [OpenTaskType.BACKLOG]: {},
                    [OpenTaskType.PAUSED]: {},
                },
                participant_users: {},
                plans: {},
                teams: {},
                users: {
                    [taskOwner.id]: taskOwner,
                },
            };

            return (
                <OpenTaskCard
                    compact
                    interactive
                    hideClientInformation
                    selected={isTaskSelected}
                    key={task.id}
                    className={index === 0 ? '' : 'mt-2'}
                    elevation={Elevation.ONE}
                    task={task}
                    taskData={taskData}
                    onClick={() => handleSelectTask(task)}
                />
            );
        };

        return targetTasks.map(renderTargetTask);
    };

    const renderTargetTaskListBlock = () => {
        return (
            <div>
                <Heading className="mb-1" type="h5">
                    2. Swap with
                </Heading>
                {renderTargetTaskList()}
            </div>
        );
    };

    const renderControls = () => {
        if (targetTasks.length === 0) {
            // No tasks to swap with
            return (
                <Flex direction="row" justify="flex-end">
                    <Button intent={Intent.PRIMARY} onClick={handleModalClose}>
                        Close modal
                    </Button>
                </Flex>
            );
        }

        // Disable swap button flag
        const isSwapButtonDisabled = selectedTargetTask === null;

        return (
            <Flex direction="row" justify="flex-end" align="center">
                <Button className="mr-1" onClick={handleModalClose}>
                    Cancel
                </Button>

                <Button
                    disabled={isSwapButtonDisabled}
                    loading={isSwappingTaskQueue}
                    intent={Intent.PRIMARY}
                    onClick={handleSwapTask}
                >
                    Swap
                </Button>
            </Flex>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '658px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Swap request
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <div className="mb-2">{renderSwapTaskInformation()}</div>
                <div className="mb-2">{renderTargetTaskListBlock()}</div>
                <div className="mb-2">
                    <Checkbox
                        checked={isQueueAutomationLockPreserved}
                        label="Preserve lock"
                        onChange={() => setIsQueueAutomationLockPreserved((prevValue) => !prevValue)}
                    />
                </div>

                {renderControls()}
            </Card>
        </Overlay>
    );
};

export default SwapTaskQueueModal;
