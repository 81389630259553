import {
    $clientPaymentAccount,
    $task,
    $taskAddons,
    $taskComplexity,
    $taskPickableParticipantUsers,
    $taskStateTransitionLog,
    $teams,
} from './states';
import {
    fetchClientPaymentAccount,
    fetchPickableTaskParticipantUsers,
    fetchTask,
    fetchTaskAddons,
    fetchTaskComplexity,
    fetchTaskPublishInformation,
    fetchTaskStateTransitionLog,
    fetchTeams,
} from './effects';
import '../modals/PublishTaskModal/store/init';
import '../modals/TaskDeliverableViewerModal/store/init';
import '../pages/TaskInformation/modals/UpdateTaskInformationModal/store/init';
import '../pages/TaskMessages/store/init';
import '../pages/TaskDeliverables/store/init';

// Task pickable participant user list
$taskPickableParticipantUsers.on(fetchPickableTaskParticipantUsers.doneData, (_, data) => data);

// Task
$task.on(fetchTask.doneData, (_, data) => data);

// Task publish information
$task.on(fetchTaskPublishInformation.doneData, (task, taskPublishInformation) => {
    if (!task) {
        return task;
    }

    return {
        ...task,
        publish: taskPublishInformation,
    };
});

// Fetch task state transition log
$taskStateTransitionLog.on(fetchTaskStateTransitionLog.doneData, (_, data) => data);

// Fetch teams
$teams.on(fetchTeams.doneData, (_, data) => data);

// Fetch task complexity
$taskComplexity.on(fetchTaskComplexity.doneData, (_, data) => data);

// Fetch task addons
$taskAddons.on(fetchTaskAddons.doneData, (_, data) => data);

// Client payment account
$clientPaymentAccount.on(fetchClientPaymentAccount.doneData, (_, data) => data);
