import repository from 'dy-frontend-http-repository/lib/repository';
import { AddonTaskRef, AddonTaskCheckoutRef } from 'dy-frontend-http-repository/lib/modules/AddonTask/refs';
import {
    CreateAddonTaskInput,
    InitializeAddonTaskCheckoutInput,
} from 'dy-frontend-http-repository/lib/modules/AddonTask/inputs';
import { TaskCategoryAddonFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/inputs';
import domain from './domain';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';

// Create task addon
export const createTaskAddon = domain.createEffect<CreateAddonTaskInput, AddonTaskRef>({
    name: 'createTaskAddon',
    handler: async (input) => {
        return await repository.addonTask().create(input);
    },
});

// Initialize task addon checkout
export const initializeTaskAddonCheckout = domain.createEffect<InitializeAddonTaskCheckoutInput, AddonTaskCheckoutRef>({
    name: 'initializeTaskAddonCheckout',
    handler: async (input) => {
        return await repository.addonTask().initializeCheckout(input);
    },
});

// Attempt addon task checkout finalization
export const finalizeTaskAddonCheckout = domain.createEffect<ID, AddonTaskCheckoutRef>({
    name: 'finalizeTaskAddonCheckout',
    handler: async (addonTaskCheckoutId) => {
        return await repository.addonTask().attemptCheckoutFinalization(addonTaskCheckoutId);
    },
});

// Get list of task category addons
export const fetchTaskCategoryAddons = domain.createEffect<
    TaskCategoryAddonFilterInput,
    CollectionResource<TaskCategoryAddonResource, {}>
>({
    name: 'fetchTaskCategoryAddons',
    handler: async (filter) => {
        return await repository.taskCategoryAddon().get(filter);
    },
});
