import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import InputFormField from '@app/components/InputFormField';
import { customerTaxIdsInformation } from 'dy-frontend-shared/lib/data/consts';
import { CreateTaxIdInput } from 'dy-frontend-http-repository/lib/modules/TaxId/inputs';
import { createTaxId, fetchTaxIds } from '../../store/effects';
import Select from '@components/Select';
import { CustomerTaxIdInformation } from 'dy-frontend-shared/lib/data/types';
import { ItemRendererProps } from '@blueprintjs/select';
import Image from '@components/Image';
import { CountryFlagUtils } from 'dy-frontend-shared/lib/utils';

const taxIdNormalizedTypeValidators = [getStringRequiredValidator()];
const taxIdValueValidators = [getStringRequiredValidator()];

export interface CreateTaxIdModalProps {
    paymentAccountId: ID;
}

type Props = ModalProps<CreateTaxIdModalProps>;

const CreateTaxIdModal: React.FC<Props> = ({ closeModal, data }) => {
    const taxIdNormalizedType = useCustomSelectFormField<string | null>({
        id: 'tax-id-normalized-type',
        validators: taxIdNormalizedTypeValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    // Get custom tax ID information for type
    const taxIdInformationByNormalizedType =
        taxIdNormalizedType.value === null
            ? null
            : (customerTaxIdsInformation[taxIdNormalizedType.value] as CustomerTaxIdInformation);

    const getTaxIdValueValidators = () => {
        if (taxIdInformationByNormalizedType === null) {
            return taxIdValueValidators;
        }

        return [
            ...taxIdValueValidators,
            getStringMinLengthValidator({ minStringLength: taxIdInformationByNormalizedType.taxIdExample.length }),
            getStringMaxLengthValidator({ maxStringLength: taxIdInformationByNormalizedType.taxIdExample.length }),
        ];
    };

    const taxIdValue = useTextFormField({
        id: 'tax-id-value',
        validators: getTaxIdValueValidators(),
        initialValue: '',
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    const form = useForm({
        fields: [taxIdNormalizedType, taxIdValue],
        apiCall: async () => {
            try {
                if (!taxIdInformationByNormalizedType) {
                    throw new Error('Tax ID information was not found for provided tax ID type');
                }

                const paymentAccountId = data?.paymentAccountId;
                if (!paymentAccountId) {
                    throw new Error('Payment account ID is not found');
                }

                const createTaxIdInput: CreateTaxIdInput = {
                    payment_account_id: paymentAccountId,
                    type: taxIdInformationByNormalizedType.type ?? '0',
                    value: taxIdValue!.value ?? '0',
                };

                const createTaxIdRef = await createTaxId(createTaxIdInput);
                await fetchTaxIds({ payment_account_id: paymentAccountId });

                return { response: createTaxIdRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            handleModalClose();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    if (!data) {
        handleModalClose();
        return null;
    }

    const getFormattedTaxIdType = (taxIdType: string) => {
        return taxIdType.split('_').join(' ').toUpperCase();
    };

    const renderCountryImage = (countryKey: string) => {
        return (
            <Image
                className="mr-small"
                width="20px"
                height="20px"
                minHeight="20px"
                minWidth="20px"
                maxHeight="20px"
                maxWidth="20px"
                src={CountryFlagUtils.getCountryFlagUrlByKey(countryKey)}
                alt={countryKey}
            />
        );
    };

    const renderTaxIdTypeSelect = () => {
        const renderItem = (normalizedType: string, { handleClick }: ItemRendererProps) => {
            const taxIdInformationByNormalizedTypeForMenuItem = customerTaxIdsInformation[
                normalizedType
            ] as CustomerTaxIdInformation;

            const isMenuItemActive = taxIdInformationByNormalizedTypeForMenuItem.normalizedType === taxIdValue.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={normalizedType}
                    text={taxIdInformationByNormalizedTypeForMenuItem.countryName}
                    label={getFormattedTaxIdType(taxIdInformationByNormalizedTypeForMenuItem.type)}
                    icon={renderCountryImage(taxIdInformationByNormalizedTypeForMenuItem.countryKey)}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (normalizedType: string) => {
            taxIdNormalizedType.handleChange(normalizedType);
        };

        return (
            <FormGroup
                label="Tax ID value"
                intent={!!taxIdNormalizedType.error ? Intent.DANGER : Intent.NONE}
                helperText={taxIdNormalizedType.error}
            >
                <Select
                    items={Object.keys(customerTaxIdsInformation)}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select tax ID value',
                        icon: taxIdInformationByNormalizedType
                            ? renderCountryImage(taxIdInformationByNormalizedType.countryKey)
                            : undefined,
                        text: taxIdInformationByNormalizedType
                            ? taxIdInformationByNormalizedType.countryName
                            : 'Select Tax ID type',
                        label: taxIdInformationByNormalizedType
                            ? getFormattedTaxIdType(taxIdInformationByNormalizedType.type)
                            : null,
                    }}
                />
            </FormGroup>
        );
    };

    const renderTaxIdValueInput = () => {
        return (
            <InputFormField
                field={taxIdValue}
                formGroupProps={{
                    fill: true,
                    label: 'Tax ID value',
                }}
                inputProps={{
                    disabled: !taxIdInformationByNormalizedType,
                    placeholder: taxIdInformationByNormalizedType
                        ? `i.e. "${taxIdInformationByNormalizedType.taxIdExample}"`
                        : 'Select tax ID type first',
                }}
            />
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Add Tax ID
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {/* Tax type */}
                    <div className="mb-2">{renderTaxIdTypeSelect()}</div>

                    {/* Tax value */}
                    {renderTaxIdValueInput()}

                    <Flex className="mt-2" align="center" justify="space-between">
                        <DevText muted>Add tax ID for client?</DevText>
                        <div>
                            <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                                No, cancel
                            </Button>
                            <Button
                                intent={Intent.PRIMARY}
                                loading={form.isSubmitting}
                                disabled={form.hasFieldErrors}
                                type="submit"
                            >
                                Yes, add
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateTaxIdModal;
