import { createApi } from 'effector';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskKanbanResource } from 'dy-frontend-http-repository/lib/modules/TaskKanban/resources';
import { TaskKanbanBoardFormatted } from '../types';
import { $taskKanbanBoardFormatted } from './states';

export const taskKanbanBoardFormattedApi = createApi($taskKanbanBoardFormatted, {
    initialize: (store, payload: { taskKanbanBoard: TaskKanbanResource }) => {
        // Create task kanban board formatted object
        const taskKanbanBoardFormattedObj: TaskKanbanBoardFormatted = {
            participant_users: {},
            plans: {},
            tasks: {
                [TaskState.DRAFT]: {},
                [TaskState.PUBLISHED]: {},
                [TaskState.PICKUP_READY]: {},
                [TaskState.IN_PROGRESS]: {},
                [TaskState.INTERNAL_QA]: {},
                [TaskState.CLIENT_REVIEW]: {},
                [TaskState.REVISION_REQUIRED]: {},
                [TaskState.IN_REVISION]: {},
                [TaskState.PRE_DELIVERED]: {},
                [TaskState.DELIVERED]: {},
            },
            teams: {},
            users: {},
        };

        // Populate participant users in task kanban board formatted object
        payload.taskKanbanBoard.participant_users.forEach((participantUser) => {
            taskKanbanBoardFormattedObj.participant_users[participantUser.id] = participantUser;
        });

        // Populate plan in task kanban board formatted object
        payload.taskKanbanBoard.plans.forEach((plan) => {
            taskKanbanBoardFormattedObj.plans[plan.id] = plan;
        });

        // Populate task in task kanban board formatted object
        payload.taskKanbanBoard.tasks.forEach((task) => {
            taskKanbanBoardFormattedObj.tasks[task.state][task.id] = task;
        });

        // Populate team in task kanban board formatted object
        payload.taskKanbanBoard.teams.forEach((team) => {
            taskKanbanBoardFormattedObj.teams[team.id] = team;
        });

        // Populate user in task kanban board formatted object
        payload.taskKanbanBoard.users.forEach((user) => {
            taskKanbanBoardFormattedObj.users[user.id] = user;
        });

        return taskKanbanBoardFormattedObj;
    },
});
