import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Intent } from '@blueprintjs/core';
import Cropper, { Area } from 'react-easy-crop';
import DevText from '@components/Text';
import Flex from '@components/Flex';

export interface ImageCropperModalProps {
    // Input image. Can be obtained by calling URL.createObjectURL(...) with file as an argument
    inputImageUrl: string;

    // Cropper aspect ratio. Unless square (=1) set as explicit math operation for readability (i.e. "3 / 4")
    aspect: number;

    // Crop shape. Defaults to 'rect'
    shape?: 'rect' | 'round';

    // Preferred output extension. Defaults to 'jpeg'
    outputExtension?: 'jpeg' | 'png';

    // Dimension of bounding box on each axis. Output image will be fit into this box. Defaults to 1024
    boundingBoxDimension?: number;

    // Callback when cropping is complete
    onCropComplete: (file: File) => void;
}

type Props = ModalProps<ImageCropperModalProps>;

const ImageCropperModal: React.FC<Props> = ({
    closeModal,
    data,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    const onCropComplete = (_: Area, areaPixels: Area) => {
        setCroppedAreaPixels(areaPixels);
    };

    const getImageAfterCropping = async () => {
        if (!croppedAreaPixels) {
            return;
        }

        const image = await new Promise<HTMLImageElement>((resolve, reject) => {
            const temp = new Image();
            temp.addEventListener('load', () => resolve(temp));
            temp.addEventListener('error', reject);
            temp.setAttribute('crossOrigin', 'anonymous');
            temp.src = data.inputImageUrl;
        });

        // Create canvas + context
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }

        // Set canvas size
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        // Scale to fit in square
        const targetSquareFit = data.boundingBoxDimension !== undefined ? data.boundingBoxDimension : 1024;
        if (canvas.width > targetSquareFit || canvas.height > targetSquareFit) {
            // Scale down to fit in square
            const ratio = Math.min(targetSquareFit / canvas.width, targetSquareFit / canvas.height);
            canvas.width = canvas.width * ratio;
            canvas.height = canvas.height * ratio;
        }

        // Draw image
        ctx.drawImage(
            image,
            croppedAreaPixels.x, croppedAreaPixels.y,
            croppedAreaPixels.width, croppedAreaPixels.height,
            0, 0,
            canvas.width, canvas.height,
        );

        // Resolve output image type
        const outputFileType = data.outputExtension !== undefined ? data.outputExtension : 'jpeg';

        // Resolve mime type
        const outputFileMime = {
            'jpeg': 'image/jpeg',
            'png': 'image/png',
        }[outputFileType] ?? 'image/jpeg';

        // Resolve extension
        const outputFileExtension = {
            'jpeg': 'jpg',
            'png': 'png',
        }[outputFileType] ?? 'jpg';


        // As blob
        return new Promise<File>((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob === null) {
                    return reject('failed');
                }
                resolve(new File([blob], `image.${outputFileExtension}`, {
                    type: outputFileMime
                }))
            }, outputFileMime);
        })
    };

    const handleUseImage = async () => {
        const file = await getImageAfterCropping();
        if (!file) {
            closeModal?.();
            return;
        }

        data.onCropComplete(file);
        closeModal?.();
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card compact style={{ width: '550px' }}>
                <div className={'mb-2'} style={{
                    height: '0',
                    width: '100%',
                    paddingBottom: '100%',
                    position: 'relative',
                }}>
                    <Cropper
                        image={data.inputImageUrl}
                        crop={crop}
                        zoom={zoom}
                        cropShape={data.shape !== undefined ? data.shape : 'rect'}
                        aspect={data.aspect}
                        zoomSpeed={0.3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>

                <Flex align="center" justify="space-between">
                    <DevText muted>Use this image?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1">
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            onClick={handleUseImage}
                        >
                            Yes, use this image
                        </Button>
                    </div>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default ImageCropperModal;
