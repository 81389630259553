import ConfirmationPopover from '@app/components/ConfirmationPopover';
import UpsertTaskCategoryAddonModal, {
    UpsertTaskCategoryAddonModalProps,
} from '@app/containers/pages/TaskCategory/modals/UpsertTaskCategoryAddonModal';
import { $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Intent, Menu, MenuItem, Popover, Spinner, Tag } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { taskCategoryAddonsApi } from '@pages/TaskCategory/store/apis';
import {
    fetchTaskCategoryAddons,
    removeTaskCategoryAddon,
    restoreTaskCategoryAddon,
} from '@pages/TaskCategory/store/effects';
import { $taskCategory, $taskCategoryAddons } from '@pages/TaskCategory/store/states';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { TaskCategoryAddonPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Addons: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);
    const taskCategoryAddons = useStore($taskCategoryAddons);

    const [removingTaskCategoryAddonId, setRemovingTaskCategoryAddonId] = useState<ID | null>(null);
    const [restoringTaskCategoryAddonId, setRestoringTaskCategoryAddonId] = useState<ID | null>(null);

    useEffect(() => {
        if (!taskCategory) {
            return;
        }

        fetchTaskCategoryAddons({ task_category_id: taskCategory.id }).catch((e) => {
            // TODO: handle error
            console.error(e);
        });

        // eslint-disable-next-line
    }, []);

    if (!taskCategory) {
        // Task category was NOT fetched yet
        return null;
    }

    // Root permissions on task category addon
    const isTaskCategoryAddonRootPermission = permissions.has(TaskCategoryAddonPermission.ROOT);

    const renderTaskCategoryAddonList = () => {
        if (!taskCategoryAddons) {
            // Task category addons were NOT fetched yet
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        const renderCreateTaskCategoryAddonButton = () => {
            const isAllowed = isTaskCategoryAddonRootPermission || permissions.has(TaskCategoryAddonPermission.CREATE);
            if (!isAllowed) {
                return null;
            }

            return (
                <Button
                    icon="add"
                    minimal
                    onClick={() =>
                        openModal<UpsertTaskCategoryAddonModalProps>({
                            ModalComponent: UpsertTaskCategoryAddonModal,
                            data: {
                                taskCategoryId: taskCategory.id,
                            },
                        })
                    }
                >
                    Add new
                </Button>
            );
        };

        if (taskCategoryAddons.items.length === 0) {
            // Task category addons do NOT exist yet for this task category
            return (
                <div>
                    <DevText muted className="mb-1">
                        There are no addons for this task category
                    </DevText>

                    {renderCreateTaskCategoryAddonButton()}
                </div>
            );
        }

        const renderTaskCategoryAddonListItem = (taskCategoryAddon: TaskCategoryAddonResource) => {
            const renderMoreButton = () => {
                const isUpdateAllowed = isTaskCategoryAddonRootPermission || permissions.has(TaskCategoryAddonPermission.UPDATE);
                const isRemoveAllowed = isTaskCategoryAddonRootPermission || permissions.has(TaskCategoryAddonPermission.ARCHIVE);
                const isRestoreAllowed = isTaskCategoryAddonRootPermission || permissions.has(TaskCategoryAddonPermission.RESTORE);
                if (!isUpdateAllowed && !isRemoveAllowed && !isRestoreAllowed) {
                    return null;
                }

                const renderTaskCategoryAddonControls = () => {
                    const renderUpdateTaskCategoryAddonMenuItem = () => {
                        if (!isUpdateAllowed) {
                            return null;
                        }

                        const handleUpdateTaskCategoryAddon = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                            e.stopPropagation();

                            openModal<UpsertTaskCategoryAddonModalProps>({
                                ModalComponent: UpsertTaskCategoryAddonModal,
                                data: {
                                    taskCategoryId: taskCategory.id,
                                    taskCategoryAddon,
                                },
                            });
                        };

                        return <MenuItem icon="edit" text="Edit" onClick={handleUpdateTaskCategoryAddon} />;
                    };

                    const renderRemoveTaskCategoryAddonMenuItem = () => {
                        if (taskCategoryAddon.archived_at !== null) {
                            // Task category archived
                            return null;
                        }

                        if (!isRemoveAllowed) {
                            return null;
                        }

                        const handleRemoveTaskCategoryAddon = async () => {
                            setRemovingTaskCategoryAddonId(taskCategoryAddon.id);

                            try {
                                await removeTaskCategoryAddon(taskCategoryAddon.id);
                                taskCategoryAddonsApi.archive({ taskCategoryAddonId: taskCategoryAddon.id });
                            } catch (e) {
                                // TODO: handle error
                                console.error(e);
                            } finally {
                                setRemovingTaskCategoryAddonId(null);
                            }
                        };

                        const isRemovingTaskCategoryAddon = removingTaskCategoryAddonId === taskCategoryAddon.id;

                        return (
                            <ConfirmationPopover
                                fill
                                title="Are you sure want to remove request category addon?"
                                description="When confirmed, request category addon will be removed"
                                actions={[
                                    <Button
                                        intent={Intent.DANGER}
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={handleRemoveTaskCategoryAddon}
                                    >
                                        Yes, remove addon
                                    </Button>,
                                ]}
                            >
                                <MenuItem
                                    shouldDismissPopover={false}
                                    disabled={isRemovingTaskCategoryAddon}
                                    intent={Intent.DANGER}
                                    text="Remove"
                                    icon="trash"
                                />
                            </ConfirmationPopover>
                        );
                    };

                    const renderRestoreTaskCategoryAddonMenuItem = () => {
                        if (taskCategoryAddon.archived_at === null) {
                            // Task category addon is NOT archived to be restored
                            return null;
                        }

                        if (!isRestoreAllowed) {
                            return null;
                        }

                        const handleRestoreTaskCategoryAddon = async () => {
                            setRestoringTaskCategoryAddonId(taskCategoryAddon.id);

                            try {
                                await restoreTaskCategoryAddon(taskCategoryAddon.id);
                                taskCategoryAddonsApi.restore({ taskCategoryAddonId: taskCategoryAddon.id });
                            } catch (e) {
                                // TODO: handle error
                                console.error(e);
                            } finally {
                                setRestoringTaskCategoryAddonId(null);
                            }
                        };

                        const isRestoringTaskCategoryAddon = restoringTaskCategoryAddonId === taskCategoryAddon.id;

                        return (
                            <ConfirmationPopover
                                fill
                                title="Are you sure want to restore request category addon?"
                                description="When confirmed, request category addon will be restored"
                                actions={[
                                    <Button
                                        intent={Intent.SUCCESS}
                                        className={Classes.POPOVER_DISMISS}
                                        onClick={handleRestoreTaskCategoryAddon}
                                    >
                                        Yes, restore addon
                                    </Button>,
                                ]}
                            >
                                <MenuItem
                                    shouldDismissPopover={false}
                                    disabled={isRestoringTaskCategoryAddon}
                                    intent={Intent.SUCCESS}
                                    text="Restore"
                                    icon="confirm"
                                />
                            </ConfirmationPopover>
                        );
                    };

                    return (
                        <Menu>
                            {renderUpdateTaskCategoryAddonMenuItem()}
                            {renderRemoveTaskCategoryAddonMenuItem()}
                            {renderRestoreTaskCategoryAddonMenuItem()}
                        </Menu>
                    );
                };

                return (
                    <Popover className="ml-small" content={renderTaskCategoryAddonControls()}>
                        <Button minimal icon="more" />
                    </Popover>
                );
            };

            const renderTaskCategoryAddonTags = () => {
                const tagClassName = 'mr-small mb-small';

                const renderTaskCategoryArchivedTag = () => {
                    if (!taskCategoryAddon.archived_at) {
                        return null;
                    }

                    return (
                        <Tag intent={Intent.DANGER} className={tagClassName}>
                            Archived
                        </Tag>
                    );
                };

                const renderTaskCategoryAddonPriceTag = () => {
                    let tagText = 'Not specified';

                    if (taskCategoryAddon.is_payment_flexible) {
                        tagText = 'Price Negotiable';
                    }

                    const price = taskCategoryAddon.price;
                    if (price) {
                        tagText = PriceUtils.formatPrice({ price: price.unit_amount, shouldDisplayCents: true });
                    }

                    return <Tag className={tagClassName}>{tagText}</Tag>;
                };

                const renderTaskCategoryEnabledTag = () => {
                    if (taskCategoryAddon.is_enabled) {
                        return null;
                    }

                    return <Tag className={tagClassName}>Not Enabled</Tag>;
                };

                return (
                    <Flex className="mt-1" flexWrap="wrap">
                        {renderTaskCategoryArchivedTag()}
                        {renderTaskCategoryAddonPriceTag()}
                        {renderTaskCategoryEnabledTag()}
                    </Flex>
                );
            };

            return (
                <div className="mb-2" key={taskCategoryAddon.id}>
                    <Flex className="mb-small" justify="space-between">
                        <Flex align="center" justify="space-between">
                            <DevText className="mr-1">{taskCategoryAddon.title}</DevText>
                        </Flex>

                        {renderMoreButton()}
                    </Flex>
                    <DevText muted>{taskCategoryAddon.summary}</DevText>
                    {renderTaskCategoryAddonTags()}
                </div>
            );
        };

        return (
            <div>
                {taskCategoryAddons.items.map(renderTaskCategoryAddonListItem)}
                {renderCreateTaskCategoryAddonButton()}
            </div>
        );
    };

    return (
        <Card {...props}>
            <Heading className="mb-2" type="h4">
                Addons
            </Heading>

            {renderTaskCategoryAddonList()}
        </Card>
    );
};

export default Addons;
