import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';

const subscriptionModeAllowedPlanTypesInformation: {
    [subscriptionMode in SubscriptionMode]: PlanType[];
} = {
    [SubscriptionMode.MANUAL]: [],
    [SubscriptionMode.SEATS]: [PlanType.SEAT],
    [SubscriptionMode.USER]: [PlanType.USER],
    [SubscriptionMode.WORKSPACE]: [PlanType.WORKSPACE],
};

export default subscriptionModeAllowedPlanTypesInformation;
