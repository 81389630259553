import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
    position: relative;

    height: 100%;
    width: 400px;
    min-width: 400px;
`;

export const NotificationPositioner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 32px 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: ${Colors.DARK_GRAY1};
`;
