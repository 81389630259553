import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringNumericValueMin = -Infinity;
const defaultStringShouldBeNumericValueMessage = 'This field should be number';
const getDefaultNumericValueWentBeyondMinValueMessage = (minValue: number) =>
    `Number should be greater or equal to ${minValue}`;

const getStringNumericValueMinValidator: GetValidatorFunction<
    {
        minValue: number;
        stringShouldBeNumericValueMessage?: string;
        numericValueWentBeyondMinValueMessageFormatter?: (minValue: number) => string;
    },
    string
> = (
    options = {
        minValue: defaultStringNumericValueMin,
        stringShouldBeNumericValueMessage: defaultStringShouldBeNumericValueMessage,
        numericValueWentBeyondMinValueMessageFormatter: getDefaultNumericValueWentBeyondMinValueMessage,
    }
) => {
    const { minValue, stringShouldBeNumericValueMessage, numericValueWentBeyondMinValueMessageFormatter } = options;

    return async (value) => {
        if (isNaN(value as any)) {
            // Value is NOT number
            if (stringShouldBeNumericValueMessage) {
                return stringShouldBeNumericValueMessage;
            } else {
                return defaultStringShouldBeNumericValueMessage;
            }
        } else {
            // Value is number
            const parsedNumericValue = parseFloat(value);

            if (parsedNumericValue >= minValue) {
                // Value passed min check
                return null;
            } else {
                // Valid did NOT pass min check
                if (numericValueWentBeyondMinValueMessageFormatter) {
                    return numericValueWentBeyondMinValueMessageFormatter(minValue);
                } else {
                    return getDefaultNumericValueWentBeyondMinValueMessage(minValue);
                }
            }
        }
    };
};

export default getStringNumericValueMinValidator;
