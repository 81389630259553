import { $permissions } from '@app/containers/store/states';
import { Button, Card, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@containers/modals/store/events';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useMemo } from 'react';
import UpdateBrandProfileFontsModal, {
    UpdateBrandProfileFontsModalProps,
} from '../../modals/UpdateBrandProfileFontsModal';
import { StyledCard } from './styled';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';
import { BrandProfileFontPrefix } from 'dy-frontend-shared/lib/data/enums';

type Props = HTMLAttributes<HTMLDivElement>;

const Fonts: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    const fonts = useMemo<{ [type in string]: string | string[] | null }>(() => {
        if (!brandProfile || brandProfile.fonts.length === 0) {
            // Brand profile is NOT fetched yet or there are not fonts added yet
            return {
                [BrandProfileFontPrefix.PRIMARY]: null,
                [BrandProfileFontPrefix.SECONDARY]: null,
                [BrandProfileFontPrefix.ADDITIONAL]: null,
            };
        }

        // Get primary & secondary & additional fonts
        const primaryFont =
            brandProfile.fonts
                .find((font) => font.startsWith(BrandProfileFontPrefix.PRIMARY))
                ?.split(BrandProfileFontPrefix.PRIMARY)[1] ?? null;
        const secondaryFont =
            brandProfile.fonts
                .find((font) => font.startsWith(BrandProfileFontPrefix.SECONDARY))
                ?.split(BrandProfileFontPrefix.SECONDARY)[1] ?? null;

        const filteredAdditionalFonts = brandProfile.fonts.filter((font) =>
            font.startsWith(BrandProfileFontPrefix.ADDITIONAL)
        );
        const additionalFonts =
            filteredAdditionalFonts.length > 0
                ? filteredAdditionalFonts.map((font) => font.split(BrandProfileFontPrefix.ADDITIONAL)[1])
                : null;

        return {
            [BrandProfileFontPrefix.PRIMARY]: primaryFont,
            [BrandProfileFontPrefix.SECONDARY]: secondaryFont,
            [BrandProfileFontPrefix.ADDITIONAL]: additionalFonts,
        };
    }, [brandProfile?.fonts]);

    if (!brandProfile) {
        return null;
    }

    const renderFonts = (title: string, font: string | string[] | null) => {
        if (!font) {
            return (
                <div>
                    <DevText muted className="mb-small">
                        {title}
                    </DevText>
                    <DevText muted>No data</DevText>
                </div>
            );
        }

        const renderFontLabel = (font: string, withSeparator = false) => {
            return (
                <Flex align="center">
                    <Popover
                        className="mr-small"
                        content={
                            <Menu>
                                <MenuItem
                                    icon="font"
                                    text={`Search "${font}" on Google Fonts`}
                                    onClick={() =>
                                        window.open(`https://fonts.google.com/?query=${encodeURIComponent(font)}`)
                                    }
                                />

                                <MenuItem
                                    icon="geosearch"
                                    text={`Google "${font} font"`}
                                    onClick={() =>
                                        window.open(
                                            `https://www.google.com/search?q=${encodeURIComponent(font + ' font')}`
                                        )
                                    }
                                />
                            </Menu>
                        }
                    >
                        <Tooltip content={`Search for ${font}`}>
                            <Button minimal small icon="search" />
                        </Tooltip>
                    </Popover>
                    <DevText>{TextFormatUtils.capitalize(font)}</DevText>
                    {withSeparator && ', '}
                </Flex>
            );
        };

        return (
            <div>
                <DevText muted className="mb-small">
                    {title}
                </DevText>

                {font instanceof Array ? (
                    <Flex gap="8px" direction="row" flexWrap="wrap">
                        {font.map((f, index) => (
                            <Flex direction="row" key={`${index}-${f}`}>
                                {renderFontLabel(f, index !== font.length - 1)}
                            </Flex>
                        ))}
                    </Flex>
                ) : (
                    renderFontLabel(font)
                )}
            </div>
        );
    };

    const renderUpdateFontsButton = () => {
        const isAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateBrandProfileFontsModalProps>({
                        ModalComponent: UpdateBrandProfileFontsModal,
                        data: {
                            brandProfileId: brandProfile.id,
                            fonts: brandProfile.fonts,
                        },
                    })
                }
            >
                Edit
            </Button>
        );
    };

    return (
        <StyledCard {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Fonts</Heading>
                {renderUpdateFontsButton()}
            </Flex>

            <div className="mb-2">{renderFonts('Primary font', fonts[BrandProfileFontPrefix.PRIMARY])}</div>
            <div className="mb-2">{renderFonts('Secondary font', fonts[BrandProfileFontPrefix.SECONDARY])}</div>
            {renderFonts('Additional font', fonts[BrandProfileFontPrefix.ADDITIONAL])}
        </StyledCard>
    );
};

export default Fonts;
