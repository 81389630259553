import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { Colors, Icon, Tooltip } from '@blueprintjs/core';
import { deadlineAtDateFormatter, deadlineAtDateFormatterMinimal } from './consts';
import Flex from '@components/Flex';
import DevText from '@components/Text';

export interface DeadlineTagProps {
    minimal?: boolean;
    deadlineAt: string | null;
    finalizedAt: string | null;
    noDeadlineRenderer?: () => JSX.Element;
}

export type Props = HTMLAttributes<HTMLSpanElement> & DeadlineTagProps;

const DeadlineTag: React.FC<Props> = ({ minimal = false, deadlineAt, finalizedAt, noDeadlineRenderer, ...props }) => {
    const getDeadlineAtFormatted = (deadlineAt: string): [string, string] => {
        const deadlineAtMoment = moment(deadlineAt);
        let deadlineAtFormatted: string;

        deadlineAtFormatted = `${deadlineAtMoment.format(deadlineAtDateFormatter)} at ${deadlineAtMoment.format(
            'HH:mm'
        )}`;

        if (minimal) {
            deadlineAtFormatted = `${deadlineAtMoment.format(
                deadlineAtDateFormatterMinimal
            )} at ${deadlineAtMoment.format('HH:mm')}`;
        }

        if (deadlineAtMoment.hours() === 23 && deadlineAtMoment.minutes() === 59) {
            deadlineAtFormatted = `${deadlineAtMoment.format(deadlineAtDateFormatter)}`;

            if (minimal) {
                deadlineAtFormatted = `${deadlineAtMoment.format(deadlineAtDateFormatterMinimal)}`;
            }
        }

        return [deadlineAtFormatted, `${deadlineAtMoment.fromNow()}`];
    };

    if (!deadlineAt) {
        // Deadline is NOT set yet

        if (noDeadlineRenderer) {
            // Custom renderer for no deadline
            return noDeadlineRenderer();
        }

        return (
            <Flex align="center" {...props}>
                <Icon className="mr-small" icon="time" color={Colors.WHITE} size={minimal ? 14 : 16} />
                <DevText inline small={minimal}>
                    Due in: To be set
                </DevText>
            </Flex>
        );
    }

    if (finalizedAt) {
        // Task was finalized
        return (
            <DevText inline muted small={minimal} {...props}>
                Delivered {moment(finalizedAt).fromNow()} - {moment(finalizedAt).format(deadlineAtDateFormatter)}
            </DevText>
        );
    }

    // Get time frame limits
    const expiredTimeFrameLimit = 0;
    const dangerTimeFrameLimit = 24 * 60;
    const warningTimeFrameLimit = 48 * 60;

    // Get moment from deadline at date
    const momentDeadlineAt = moment(deadlineAt);

    // Get formatted deadline dates
    const [deadlineAtFormatted, deadlineAtFormattedFromNow] = getDeadlineAtFormatted(deadlineAt);

    // Deadline is reached
    const isDeadlineExpired = momentDeadlineAt.diff(moment(), 'seconds') <= expiredTimeFrameLimit;

    // 24 hours or less left until deadline
    const isDeadlineDangerLimitReached =
        momentDeadlineAt.diff(moment(), 'seconds') > 0 &&
        momentDeadlineAt.diff(moment(), 'minutes') <= dangerTimeFrameLimit;

    // More than 24 hours and less or equal then 48hours left
    const isDeadlineWarningLimitReached =
        momentDeadlineAt.diff(moment(), 'minutes') > dangerTimeFrameLimit &&
        momentDeadlineAt.diff(moment(), 'minutes') <= warningTimeFrameLimit;

    const renderDeadlineLabel = ({
        tooltipText,
        color,
        label,
    }: {
        tooltipText: string;
        color: string;
        label: string;
    }) => {
        return (
            <Tooltip content={tooltipText}>
                <Flex align="center" {...props}>
                    <Icon className="mr-1" icon="time" color={color} size={minimal ? 14 : 16} />
                    <DevText inline small={minimal} color={color}>
                        {label}
                    </DevText>
                </Flex>
            </Tooltip>
        );
    };

    if (isDeadlineExpired) {
        return renderDeadlineLabel({
            tooltipText: deadlineAtFormatted,
            color: Colors.RED2,
            label: `Past due: ${deadlineAtFormattedFromNow}`,
        });
    } else if (isDeadlineDangerLimitReached) {
        return renderDeadlineLabel({
            tooltipText: deadlineAtFormatted,
            color: Colors.RED2,
            label: `Due: ${deadlineAtFormattedFromNow}`,
        });
    } else if (isDeadlineWarningLimitReached) {
        return renderDeadlineLabel({
            tooltipText: deadlineAtFormattedFromNow,
            color: Colors.ORANGE4,
            label: `Due: ${deadlineAtFormatted}`,
        });
    } else {
        return renderDeadlineLabel({
            tooltipText: deadlineAtFormattedFromNow,
            color: Colors.WHITE,
            label: `Due: ${deadlineAtFormatted}`,
        });
    }
};

export default DeadlineTag;
