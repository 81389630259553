import repository from 'dy-frontend-http-repository/lib/repository';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { UpdateSubscriptionPauseInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import domain from './domain';

// Pause subscription
export const pauseSubscription = domain.createEffect<
    { subscriptionId: ID; input: UpdateSubscriptionPauseInput },
    SubscriptionRef
>({
    name: 'pauseSubscription',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().updatePause(subscriptionId, input);
    },
});
