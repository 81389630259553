import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { TaskPublishType } from 'dy-frontend-http-repository/lib/data/enums';
import { taskPublishTypeInformation } from '@data/consts';
import { PlatformTaskFilterData } from '@pages/Tasks/types';
import { platformFiltersInformation } from '@pages/Tasks/consts';
import { PlatformTaskFilterType } from '@pages/Tasks/enums';
import { $isQueryHydrationFinished } from '@pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@pages/Tasks/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';

export interface TaskPublishTypesPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskPublishTypesPlatformFilterProps;

const TaskPublishTypesPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformTaskFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedTaskPublishTypes, setSelectedTaskPublishTypes] = useState<PlatformTaskFilterData['publishTypes']>(
        platformTaskFilterData.publishTypes
    );

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedTaskPublishTypes(platformTaskFilterData.publishTypes);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.publishTypes.length === 0) {
            setSelectedTaskPublishTypes([]);
        }
    }, [platformTaskFilterData.publishTypes]);

    const getFilterTagLabel = () => {
        if (selectedTaskPublishTypes.length === 0) return '';

        return selectedTaskPublishTypes
            .map((taskPublishType, index) => {
                if (index === selectedTaskPublishTypes.length - 1) {
                    // Last element
                    return `${taskPublishTypeInformation[taskPublishType].title}`;
                }

                return `${taskPublishTypeInformation[taskPublishType].title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedTaskPublishTypes.length > 0) {
            // At least 1 task task publish type selected
            return 'Publish types:';
        }

        return 'Publish types';
    };

    const handleRemoveFilter = () => {
        if (selectedTaskPublishTypes.length !== 0) {
            // At least 1 task task publish type was selected

            setSelectedTaskPublishTypes([]);

            if (platformTaskFilterData.publishTypes.length !== 0) {
                // Reset task publish type to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, publishTypes: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_PUBLISH_TYPES].isPinned) {
            // Platform task publish type filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (key: PlatformTaskFilterData['publishTypes'][number]) => {
        if (selectedTaskPublishTypes.includes(key)) {
            // Task publish type is already checked
            setSelectedTaskPublishTypes(selectedTaskPublishTypes.filter((taskPublishType) => taskPublishType !== key));
        } else {
            // Task publish type is NOT checked yet
            setSelectedTaskPublishTypes([...selectedTaskPublishTypes, key]);
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedTaskPublishTypes) !== JSON.stringify(platformTaskFilterData.publishTypes)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, publishTypes: selectedTaskPublishTypes });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_PUBLISH_TYPES].isPinned) {
            return;
        }

        if (selectedTaskPublishTypes.length === 0) {
            onRemove();
        }
    };

    const renderRow = (key: PlatformTaskFilterData['publishTypes'][number]) => {
        return (
            <li className="mb-1" key={key}>
                <Checkbox
                    checked={selectedTaskPublishTypes.includes(key)}
                    className="mr-2"
                    label={taskPublishTypeInformation[key].title}
                    onClick={() => handleCheckboxClick(key)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['publishTypes'][number]>
            isOpen={isOpen}
            removable={selectedTaskPublishTypes.length > 0}
            title="Publish types"
            position={Position.BOTTOM_LEFT}
            list={Object.values(TaskPublishType)}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_PUBLISH_TYPES].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskPublishTypesPlatformFilter;
