import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { PointMarkerByTaskDeliverableCommentIdMap, RootTaskDeliverableCommentsByIdMap } from '../types';
import domain from './domain';
import {
    resetHighlightedTaskDeliverableCommentId,
    resetIsImageContentFitInPage,
    resetPointMarker,
    resetPointMarkerByTaskDeliverableCommentIdMap,
    resetQueuedVideoTimelinePositionMS,
    resetRootTaskDeliverableCommentsByIdMap,
    resetSelectedTaskDeliverable,
    resetTaskDeliverableComments,
    resetTimeMarker,
} from './events';

// Selected task deliverable
export const $selectedTaskDeliverable = domain
    .createStore<TaskDeliverableResource | null>(null)
    .reset(resetSelectedTaskDeliverable);

// Task deliverable comments
export const $taskDeliverableComments = domain
    .createStore<CollectionResource<TaskDeliverableCommentResource, {}> | null>(null)
    .reset(resetTaskDeliverableComments);

// Root task deliverable comments by ID
export const $rootTaskDeliverableCommentsByIdMap = domain
    .createStore<RootTaskDeliverableCommentsByIdMap | null>(null)
    .reset(resetRootTaskDeliverableCommentsByIdMap);

// Time marker
export const $timeMarker = domain
    .createStore<TaskDeliverableCommentResource['marker_time'] | null>(null)
    .reset(resetTimeMarker);

// Point marker
export const $pointMarker = domain
    .createStore<TaskDeliverableCommentResource['marker_point'] | null>(null)
    .reset(resetPointMarker);

// Point markers by task deliverable IDs
export const $pointMarkerByTaskDeliverableCommentIdMap = domain
    .createStore<PointMarkerByTaskDeliverableCommentIdMap>({})
    .reset(resetPointMarkerByTaskDeliverableCommentIdMap);

// Highlighted task deliverable comment ID
export const $highlightedTaskDeliverableCommentId = domain
    .createStore<ID | null>(null)
    .reset(resetHighlightedTaskDeliverableCommentId);

// Highlighted point marker task deliverable comment ID
export const $highlightedPointMarkerTaskDeliverableCommentId = domain
    .createStore<ID | null>(null)
    .reset(resetHighlightedTaskDeliverableCommentId);

// Queued video timeline position in milliseconds
export const $queuedVideoTimelinePositionMS = domain
    .createStore<number | null>(null)
    .reset(resetQueuedVideoTimelinePositionMS);

// Image content fit in page flag
export const $isImageContentFitInPage = domain.createStore<boolean>(false).reset(resetIsImageContentFitInPage);
