import FileTilePreview from '@app/components/FileTilePreview';
import Grid from '@app/components/Grid';
import DevText from '@app/components/Text';
import FileViewerModal, { FileViewerModalProps } from '@app/containers/modals/FileViewerModal';
import { openModal } from '@app/containers/modals/store/events';
import { Button } from '@blueprintjs/core';
import { FileAttachmentResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import React, { HTMLAttributes } from 'react';

export interface TaskMessageFileAttachmentsProps {
    attachments: FileAttachmentResource[];
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskMessageFileAttachmentsProps;

const TaskMessageFileAttachments: React.FC<Props> = ({ attachments, ...props }) => {
    if (attachments.length === 0) {
        return null;
    }

    const renderViewFile = (index: number) => {
        const handleViewFile = () => {
            openModal<FileViewerModalProps>({
                ModalComponent: FileViewerModal,
                data: {
                    files: attachments.map((f) => ({
                        name: f.file.original_name,
                        extension: f.file.extension,
                        size: f.file.size,
                        src: f.file.url,
                    })),
                    currentFileIndex: index,
                },
            });
        };

        return <Button minimal icon="eye-open" onClick={() => handleViewFile()} />;
    };

    const renderFile = (attachment: FileAttachmentResource, index: number) => {
        let src: string | undefined = undefined;

        if (attachment.file.preview_url) {
            src = attachment.file.preview_url;
        } else {
            src = attachment.file.url;
        }

        return (
            <FileTilePreview
                fileName={attachment.file.original_name}
                fileSize={attachment.file.size}
                extension={attachment.file.extension}
                src={src}
                actions={renderViewFile(index)}
            />
        );
    };

    return (
        <div {...props}>
            <DevText muted className="mb-small">
                Files
            </DevText>

            <Grid container>
                {attachments.map((attachment, index) => (
                    <Grid className={index === 0 || index === 1 ? '' : 'mt-2'} key={attachment.file.id} lg={6} xs={12}>
                        {renderFile(attachment, index)}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default TaskMessageFileAttachments;
