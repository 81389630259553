import {
    useCustomSelectFormField,
    useForm,
    useMapFormField,
    useRichTextFormField,
    useTextArrayFormField,
    useTextFormField,
} from '@app/hooks';
import {
    getOptionalStringValidator,
    getRichStringMinLengthValidator,
    getRichStringRequiredValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
    getStringWebURLValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, InputGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import RichEditorFormField from '@components/RichEditorFormField';
import Select from '@components/Select';
import DevText from '@components/Text';
import TextAreaFormField from '@components/TextAreaFormField';
import { ModalProps } from '@modals/types';
import { taskApi } from '@pages/Task/store/apis';
import { updateTask } from '@pages/Task/store/effects';
import { $task } from '@pages/Task/store/states';
import { UpdateTaskInformationInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import { BrandProfileResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { TaskUtils, TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { ParsedDesignDimensionData } from 'dy-frontend-shared/lib/utils/TaskUtils/types';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { fetchClientBrandProfiles } from './store/effects';
import { $clientBrandProfiles } from './store/states';

type Props = ModalProps;

const taskTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const taskDescriptionValidators = [
    getRichStringRequiredValidator(),
    getRichStringMinLengthValidator({ minRichStringLength: 20 }),
];
const taskExactTextCopyValidators = [getOptionalStringValidator([getStringRequiredValidator()])];
const taskCommentValidators = [
    getOptionalStringValidator([getStringRequiredValidator(), getStringMinLengthValidator({ minStringLength: 6 })]),
];
const taskReferenceLinksValidators = [getOptionalStringValidator([getStringWebURLValidator()])];
const taskDeliverableExtensionsValidators = [];
const taskSourceExtensionsValidators = [];
const taskDimensionsValidators = [];
const selectedBrandProfileIdValidators = [];

const UpdateTaskInformationModal: React.FC<Props> = ({ closeModal }) => {
    const task = useStore($task);
    const clientBrandProfiles = useStore($clientBrandProfiles);

    const taskTitle = useTextFormField({
        id: 'task-title',
        validators: taskTitleValidators,
        initialValue: task?.title ?? '',
    });

    const taskDescription = useRichTextFormField({
        id: 'task-description',
        validators: taskDescriptionValidators,
        initialValue: task?.description ?? '',
    });

    const taskExactTextCopy = useTextFormField({
        id: 'task-exact-text-copy',
        validators: taskExactTextCopyValidators,
        initialValue: task?.exact_text_copy ?? '',
    });

    const taskComment = useTextFormField({
        id: 'task-comment',
        validators: taskCommentValidators,
        initialValue: task?.comment ?? '',
    });

    const taskReferenceLinks = useTextArrayFormField({
        id: 'task-reference-links',
        validators: taskReferenceLinksValidators,
        initialValue: task?.reference_links ? [...task.reference_links, ''] : [''],
    });

    const taskDeliverableExtensions = useTextArrayFormField({
        id: 'task-deliverable-extensions',
        validators: taskDeliverableExtensionsValidators,
        initialValue: task?.extensions ?? [],
    });

    const taskSourceExtensions = useTextArrayFormField({
        id: 'task-deliverable-source-extensions',
        validators: taskSourceExtensionsValidators,
        initialValue: task?.source_extensions ?? [],
    });

    const taskDimensionData = useMapFormField<ParsedDesignDimensionData>({
        id: 'task-dimensions-data',
        validators: taskDimensionsValidators,
        initialValue: TaskUtils.normalizeDesignDimensions(task?.dimensions ?? []),
    });

    const taskSelectedBrandProfileId = useCustomSelectFormField<BrandProfileResource | null>({
        id: 'task-selected-brand-profile-id',
        validators: selectedBrandProfileIdValidators,
        initialValue: task && task.brand_profile ? task.brand_profile : null,
        formatValue: (brandProfile) => (brandProfile ? brandProfile.title : ''),
    });

    const form = useForm<TaskRef>({
        fields: [
            taskTitle,
            taskDescription,
            taskExactTextCopy,
            taskComment,
            taskReferenceLinks,
            taskDeliverableExtensions,
            taskSourceExtensions,
            taskDimensionData,
            taskSelectedBrandProfileId,
        ],
        apiCall: async () => {
            const input: UpdateTaskInformationInput = {
                title: taskTitle.value,
                comment: taskComment.value,
                description: taskDescription.value,
                dimensions: TaskUtils.denormalizeDesignDimensions(taskDimensionData.value),
                exact_text_copy: taskExactTextCopy.value,
                reference_links: taskReferenceLinks.value.filter((link) => link.trim().length > 0), // TODO: clear empty strings
                extensions: taskDeliverableExtensions.value,
                source_extensions: taskSourceExtensions.value,
                brand_profile_id: taskSelectedBrandProfileId.value?.id,
            };

            const taskRef = await updateTask({
                id: task!.id,
                input,
            });

            taskApi.update(input);
            taskApi.updateBrandProfile({ brandProfile: taskSelectedBrandProfileId.value });

            return { response: taskRef };
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    useEffect(() => {
        if (task) {
            fetchClientBrandProfiles({
                pagination: {
                    _pagination: { limit: 50, offset: 0 }, // TODO: for now we need to pass pagination to fetch brandProfiles, we'll have to adjust it later
                },
                filter: {
                    user_id: task.user.id,
                },
            }).catch((e) => {
                // TODO: handle error
                console.error(e);
            });
        }

        // eslint-disable-next-line
    }, []);

    // Task was NOT fetched yet
    if (!task) {
        return null;
    }

    const renderReferenceLinkInputs = () => {
        const normalizeReferenceLinks = (referenceLinks: string[]) => {
            const normalizedReferenceLinks = referenceLinks.filter((x) => x.trim().length !== 0);
            normalizedReferenceLinks.push('');
            return normalizedReferenceLinks;
        };

        const updateReferenceLinkAtIndex = (index: number, referenceLink: string) => {
            let newReferenceLinks = [...taskReferenceLinks.value];
            newReferenceLinks[index] = referenceLink;
            newReferenceLinks = normalizeReferenceLinks(newReferenceLinks);
            taskReferenceLinks.handleChange(newReferenceLinks);
        };

        const renderReferenceLinkInput = (link: string, index: number) => {
            return (
                <InputGroup
                    fill
                    intent={!!taskReferenceLinks.errors[index] ? Intent.DANGER : Intent.NONE}
                    className="mb-1"
                    placeholder="URL"
                    value={link}
                    onChange={(e) => updateReferenceLinkAtIndex(index, e.target.value)}
                    onBlur={() => taskReferenceLinks.handleBlur(index)}
                />
            );
        };

        return (
            <FormGroup label="Reference links" helperText="Extra fields will be added as you type">
                {taskReferenceLinks.value.map(renderReferenceLinkInput)}
            </FormGroup>
        );
    };

    const renderDimensionsSelect = () => {
        // TODO: refactor this poop

        type SelectableDimension = { title: string; dimension: string; isTitle: boolean; dimensionsCount?: number };

        const parsedTaskCategoryDimensions = TaskUtils.normalizeDesignDimensions(task.category.dimensions);

        // Populate custom dimensions in parsedTaskCategoryDimensions with dimensions added through Select, this need in order to be able to unselect custom dimensions, which user add through Select component
        parsedTaskCategoryDimensions['dimensions'] = Array.from(
            new Set([...parsedTaskCategoryDimensions['dimensions'], ...taskDimensionData.value['dimensions']])
        );

        const selectDimensionItems: SelectableDimension[] = [];

        for (const dimensionTitle in parsedTaskCategoryDimensions) {
            const dimensions = parsedTaskCategoryDimensions[dimensionTitle];

            // Add title
            selectDimensionItems.push({
                title: dimensionTitle,
                dimension: '',
                isTitle: true,
                dimensionsCount: dimensions.length,
            });

            // Add dimensions
            dimensions.forEach((dimension) =>
                selectDimensionItems.push({
                    title: dimensionTitle,
                    dimension: dimension,
                    isTitle: false,
                })
            );
        }

        const filterItemOption = (query: string, item: SelectableDimension) => {
            return item.dimension.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: SelectableDimension, { handleClick }: ItemRendererProps) => {
            const key = item.isTitle ? item.title : `${item.title}::${item.dimension}`;
            const text = item.isTitle ? TextFormatUtils.capitalize(item.title) : item.dimension;
            let isActive = false;

            // No need to render item if title there are NO items of those type (Works for dimension titles)
            if (item.dimensionsCount !== undefined && item.dimensionsCount === 0) {
                return null;
            }

            // Check if item active
            if (taskDimensionData.value[item.title] && taskDimensionData.value[item.title].includes(item.dimension)) {
                isActive = true;
            }

            return <MenuItem disabled={item.isTitle} active={isActive} key={key} text={text} onClick={handleClick} />;
        };

        const renderCreateItemOption = (
            query: string,
            active: boolean,
            handleClick: React.MouseEventHandler<HTMLElement>
        ) => {
            return (
                <MenuItem shouldDismissPopover={false} active={active} icon="add" text={query} onClick={handleClick} />
            );
        };

        const handleItemSelect = (item: SelectableDimension) => {
            if (item.isTitle) {
                return;
            }

            const copyDimensions = { ...taskDimensionData.value };
            let isItemPresent = false;

            if (copyDimensions[item.title] && copyDimensions[item.title].includes(item.dimension)) {
                isItemPresent = true;
            }

            // Item present
            if (isItemPresent) {
                copyDimensions[item.title] = copyDimensions[item.title].filter((d) => d !== item.dimension);
            } else {
                if (copyDimensions[item.title]) {
                    copyDimensions[item.title].push(item.dimension);
                } else {
                    copyDimensions[item.title] = [item.dimension];
                }
            }

            taskDimensionData.handleChange(copyDimensions);
        };

        return (
            <FormGroup
                label="Dimensions"
                intent={!!taskDimensionData.error ? Intent.DANGER : Intent.NONE}
                helperText={taskDimensionData.error}
            >
                <Select<SelectableDimension>
                    fill
                    filterable
                    items={selectDimensionItems}
                    createNewItemFromQuery={(query) => ({
                        title: 'dimensions',
                        dimension: query,
                        isTitle: false,
                    })}
                    inputProps={{
                        placeholder: 'Add keywords / Search',
                    }}
                    selectButtonProps={{
                        fill: true,
                        icon: 'add',
                        placeholder: 'Select dimensions',
                        text: 'Select dimensions',
                    }}
                    popoverProps={{ usePortal: false }}
                    itemRenderer={renderItem}
                    itemPredicate={filterItemOption}
                    createNewItemRenderer={renderCreateItemOption}
                    onItemSelect={handleItemSelect}
                />
            </FormGroup>
        );
    };

    const renderSelectedDimensions = () => {
        return Object.entries(taskDimensionData.value).map(([dimensionTitle, d]) => {
            // This will happen whenever there are no default design dimension, but there are NO default dimensions exist
            if (dimensionTitle === 'dimensions' && d.length === 0) {
                return null;
            }

            return (
                <Flex className="mb-2" direction="column" key={dimensionTitle}>
                    <DevText muted className="mb-small">
                        {TextFormatUtils.capitalize(dimensionTitle)}
                    </DevText>

                    {d.map((dimension) => (
                        <DevText>{dimension}</DevText>
                    ))}
                </Flex>
            );
        });
    };

    const renderBrandProfileFormFieldSelect = () => {
        const renderItem = (item: BrandProfileResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive =
                !!taskSelectedBrandProfileId.value && item.id === taskSelectedBrandProfileId.value.id;

            return <MenuItem active={isMenuItemActive} key={item.id} text={item.title} onClick={handleClick} />;
        };

        const handleItemSelect = (item: BrandProfileResource) => {
            taskSelectedBrandProfileId.handleChange(item);
        };

        return (
            <FormGroup
                label="Brand profile"
                intent={!!taskSelectedBrandProfileId.error ? Intent.DANGER : Intent.NONE}
                helperText={taskSelectedBrandProfileId.error}
            >
                <Select<BrandProfileResource>
                    filterable
                    disabled={!clientBrandProfiles || clientBrandProfiles.items.length === 0}
                    items={clientBrandProfiles ? clientBrandProfiles.items : []}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select brand profile',
                        text: taskSelectedBrandProfileId.value?.title,
                    }}
                />
            </FormGroup>
        );
    };

    const renderDeliverableExtensionsCheckboxFormField = () => {
        return (
            <FormGroup
                label="Deliverable extensions"
                intent={!!taskDeliverableExtensions.error ? Intent.DANGER : Intent.NONE}
                helperText={taskDeliverableExtensions.error}
            >
                {/* // TODO: we need to create map with keys as extensions and values as checked/not checked, because this takes O(n^2) */}
                {task.category.extensions.map((deliverableExtension) => {
                    const isChecked = taskDeliverableExtensions.value.includes(deliverableExtension);

                    return (
                        <Checkbox
                            checked={isChecked}
                            key={deliverableExtension}
                            label={deliverableExtension.toUpperCase()}
                            onChange={() => {
                                if (isChecked) {
                                    taskDeliverableExtensions.handleChange(
                                        taskDeliverableExtensions.value.filter(
                                            (value) => value !== deliverableExtension
                                        )
                                    );
                                } else {
                                    taskDeliverableExtensions.handleChange([
                                        ...taskDeliverableExtensions.value,
                                        deliverableExtension,
                                    ]);
                                }
                            }}
                        />
                    );
                })}
            </FormGroup>
        );
    };

    const renderSourceDeliverableExtensionsCheckboxFormField = () => {
        return (
            <FormGroup
                label="Source extensions"
                intent={!!taskSourceExtensions.error ? Intent.DANGER : Intent.NONE}
                helperText={taskSourceExtensions.error}
            >
                {/* // TODO: we need to create map with keys as extensions and values as checked/not checked, because this takes O(n^2) */}
                {task.category.source_extensions.map((sourceExtension) => {
                    const isChecked = taskSourceExtensions.value.includes(sourceExtension);

                    return (
                        <Checkbox
                            checked={isChecked}
                            key={sourceExtension}
                            label={sourceExtension.toUpperCase()}
                            onChange={() => {
                                if (isChecked) {
                                    taskSourceExtensions.handleChange(
                                        taskSourceExtensions.value.filter((value) => value !== sourceExtension)
                                    );
                                } else {
                                    taskSourceExtensions.handleChange([...taskSourceExtensions.value, sourceExtension]);
                                }
                            }}
                        />
                    );
                })}
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen enforceFocus={false} onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update task information</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        {/* Title */}
                        <Grid className="mb-2" lg={12}>
                            <InputFormField
                                field={taskTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        {/* Description */}
                        <Grid className="mb-2" lg={12}>
                            <RichEditorFormField
                                field={taskDescription}
                                formGroupProps={{ label: 'Description' }}
                                richEditorProps={{ placeholder: 'Enter description' }}
                            />
                        </Grid>

                        {/* Exact text copy */}
                        <Grid className="mb-2" lg={12}>
                            <TextAreaFormField
                                field={taskExactTextCopy}
                                formGroupProps={{ label: 'Exact text copy' }}
                                textAreaProps={{ placeholder: 'Enter exact text copy' }}
                            />
                        </Grid>

                        {/* Comment */}
                        <Grid className="mb-2" lg={12}>
                            <TextAreaFormField
                                field={taskComment}
                                formGroupProps={{ label: 'Comment' }}
                                textAreaProps={{ placeholder: 'Enter comment' }}
                            />
                        </Grid>

                        {/* Brand profile */}
                        <Grid className="mb-2" lg={12}>
                            {renderBrandProfileFormFieldSelect()}
                        </Grid>

                        {/* Deliverable extensions */}
                        <Grid className="mb-2" lg={6} xs={12}>
                            {renderDeliverableExtensionsCheckboxFormField()}
                        </Grid>

                        {/* Source extensions */}
                        <Grid className="mb-2" lg={6} xs={12}>
                            {renderSourceDeliverableExtensionsCheckboxFormField()}
                        </Grid>

                        {/* Dimensions */}
                        <Grid lg={12}>
                            {renderDimensionsSelect()}
                            {renderSelectedDimensions()}
                        </Grid>

                        {/* Reference links */}
                        <Grid lg={12}>{renderReferenceLinkInputs()}</Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskInformationModal;
