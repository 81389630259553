import { WorkspaceInitDataResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceInitData/resources';
import { WorkspaceSignupStep } from '../enums';
import { WorkspaceSignupData } from '../types';
import domain from './domain';
import { resetStep, resetWorkspaceInitData, resetWorkspaceSignupData, resetWorkspaceSignupId } from './events';

// Step
export const $step = domain.createStore<WorkspaceSignupStep>(WorkspaceSignupStep.COMPANY_NAME).reset(resetStep);

// Workspace signup data
export const $workspaceSignupData = domain
    .createStore<WorkspaceSignupData>({
        name: '',
        website: '',
        key: '',
        adminFirstName: '',
        adminLastName: '',
        adminEmail: '',
        adminPassword: '',
    })
    .reset(resetWorkspaceSignupData);

// Workspace init data
export const $workspaceInitData = domain
    .createStore<WorkspaceInitDataResource | null>(null)
    .reset(resetWorkspaceInitData);

// Workspace signup ID ref
export const $workspaceSignupId = domain.createStore<ID | null>(null).reset(resetWorkspaceSignupId);
