import { Colors, ControlGroup, ControlGroupProps, InputGroup } from '@blueprintjs/core';
import React from 'react';
import Box, { BoxProps } from '../Box';

export interface ColorTitleProps {
    // This field going to change cursor
    interactive?: boolean;

    // Color to show
    color: string;

    // Override for input value whenever colors string is empty
    emptyColorLabel?: string;
}

export type Props = ControlGroupProps & ColorTitleProps;

// This component is used for showing color square block and color's hash value
const ColorTile: React.FC<Props> = ({ interactive = false, emptyColorLabel, color, ...props }) => {
    const cursor = interactive ? 'pointer' : 'default';
    const colorInputValue = color === '' ? emptyColorLabel ?? 'Not set' : color;

    const renderColorBlock = () => {
        // Get common props for color block
        const commonColorBlockProps: BoxProps = {
            width: '30px',
            height: '30px',
            minHeight: '30px',
            minWidth: '30px',
            maxHeight: '30px',
            maxWidth: '30px',
            borderRadius: '2px',
            cursor,
        };

        if (color) {
            // Color is set
            return <Box {...commonColorBlockProps} backgroundColor={color} />;
        } else {
            // Color is NOT set
            return <Box {...commonColorBlockProps} backgroundColor={Colors.BLACK} />;
        }
    };

    return (
        <ControlGroup fill {...props}>
            {renderColorBlock()}
            <InputGroup style={{ cursor }} readOnly value={colorInputValue} />
        </ControlGroup>
    );
};

export default ColorTile;
