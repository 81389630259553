import repository from 'dy-frontend-http-repository/lib/repository';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Price/resources';
import {
    PlanQuotaAttachmentInput,
    PlanTaskCategoryAttachmentInput,
    UpdatePlanInformationInput,
} from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceRef } from 'dy-frontend-http-repository/lib/modules/Price/refs';
import { PlanRef } from 'dy-frontend-http-repository/lib/modules/Plan/refs';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import domain from './domain';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';

// Fetch plan
export const fetchPlan = domain.createEffect<ID, PlanResource>({
    name: 'fetchPlan',
    handler: async (planId) => {
        return await repository.plan().getById(planId);
    },
});

// Fetch task categories
export const fetchTaskCategories = domain.createEffect<
    void,
    CollectionResource<TaskCategoryListItemResource, {}>
>({
    name: 'fetchTaskCategories',
    handler: async () => {
        return await repository.taskCategory().get();
    },
});

// Fetch task category groups
export const fetchTaskCategoryGroups = domain.createEffect<
    void,
    CollectionResource<TaskCategoryGroupListItemResource, {}>
>({
    name: 'fetchTaskCategoryGroups',
    handler: async () => {
        return await repository.taskCategoryGroup().get();
    },
});

// Update plan
export const updatePlan = domain.createEffect<{ planId: ID; input: UpdatePlanInformationInput }, PlanRef>({
    name: 'updatePlan',
    handler: async ({ planId, input }) => {
        return await repository.plan().update(planId, input);
    },
});

// Remove plan
export const removePlan = domain.createEffect<ID, PlanRef>({
    name: 'removePlan',
    handler: async (planId) => {
        return await repository.plan().archive(planId);
    },
});

// Restore plan
export const restorePlan = domain.createEffect<ID, PlanRef>({
    name: 'restorePlan',
    handler: async (planId) => {
        return await repository.plan().restore(planId);
    },
});

// Fetch price by external ID
export const fetchPriceByExternalId = domain.createEffect<string, PriceResource>({
    name: 'fetchPriceByExternalId',
    handler: async (priceExternalId) => {
        return await repository.price().getByExternalId(priceExternalId);
    },
});

// Update plan to price attachment
export const updatePriceToPlanAttachment = domain.createEffect<
    { priceId: ID; input: UpdatePricePlanAttachmentDataInput },
    PriceRef
>({
    name: 'updatePriceToPlanAttachment',
    handler: async ({ priceId, input }) => {
        return await repository.price().updatePlanAttachmentData(priceId, input);
    },
});

// Remove attached price from plan
export const removePriceFromPlan = domain.createEffect<ID, PriceRef>({
    name: 'removePriceFromPlan',
    handler: async (priceId) => {
        return await repository.price().removeAssignedPlan(priceId);
    },
});

// Publishes plan
export const publishPlan = domain.createEffect<ID, PlanRef>({
    name: 'publishPlan',
    handler: async (planId) => {
        return await repository.plan().publish(planId);
    },
});

// Applies plan task category group attachment list
export const updatePlanTaskCategoryGroupAttachmentList = domain.createEffect<{ planId: ID, input: ID[] }, PlanRef>({
    name: 'updatePlanTaskCategoryGroupAttachmentList',
    handler: async ({ planId, input }) => {
        return await repository.plan().setTaskCategoryGroups(planId, input);
    },
});

// Applies plan task category attachment list
export const updatePlanTaskCategoryAttachmentList = domain.createEffect<{
    planId: ID,
    input: PlanTaskCategoryAttachmentInput[]
}, PlanRef>({
    name: 'updatePlanTaskCategoryAttachmentList',
    handler: async ({ planId, input }) => {
        return await repository.plan().setTaskCategoryAttachmentList(planId, input);
    },
});

// Applies plan quota attachment list
export const updatePlanQuotaAttachmentList = domain.createEffect<{
    planId: ID,
    input: PlanQuotaAttachmentInput[]
}, PlanRef>({
    name: 'updatePlanQuotaAttachmentList',
    handler: async ({ planId, input }) => {
        return await repository.plan().setQuotaAttachmentList(planId, input);
    },
});
