import { BaseDefaultTheme } from 'styled-components';

const base: BaseDefaultTheme = {
    breakpoints: {
        xs: '600px',
        sm: '960px',
        md: '1280px',
        lg: '1920px',
    },
};

export default base;
