import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Button, Icon, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import { PlanFilterInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import { HTTPErrorType, PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { Endpoints, planTypeInformation } from '@data/consts';
import { openModal } from '@modals/store/events';
import { $plans } from './store/states';
import { fetchPlans } from './store/effects';
import { resetPlans } from './store/events';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Select from '@components/Select';
import PlanItem from './components/PlanItem';
import { $permissions } from '@app/containers/store/states';
import Heading from '@components/Heading';
import CreatePlanModal from './modals/CreatePlanModal';
import { usePageTitle } from '@app/hooks';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const Plans: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.plan) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Plans');

    const navigate = useNavigate();

    const [planType, setPlanType] = useState<PlanType | null>(null);

    const plans = useStore($plans);

    useEffect(() => {
        const planFilterInput: PlanFilterInput = {};

        if (planType) {
            planFilterInput.type = [planType];
        }

        fetchPlans(planFilterInput).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: 'Plans were not found',
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        });
    }, [planType]);

    useEffect(() => {
        return () => {
            resetPlans();
        };
    }, []);

    const renderCreatePlanButton = () => {
        const isAllowed = permissions.isRoot.plan || permissions.has(PlanPermission.CREATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="new-object"
                intent={Intent.PRIMARY}
                onClick={() => openModal({ ModalComponent: CreatePlanModal })}
            >
                Create Plan
            </Button>
        );
    };

    const renderPlanTypeSelect = () => {
        const renderItem = (item: PlanType | null, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item === planType;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item}
                    text={item ? planTypeInformation[item] : 'All'}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: PlanType | null) => {
            setPlanType(item);
        };

        return (
            <Select<PlanType | null>
                items={[null, ...Object.values(PlanType)]}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                popoverProps={{
                    usePortal: false,
                    matchTargetWidth: false,
                }}
                selectButtonProps={{
                    fill: true,
                    rightIcon: 'double-caret-vertical',
                    text: planType ? planTypeInformation[planType] : 'All',
                }}
            />
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Plans</Heading>
                {renderCreatePlanButton()}
            </Flex>
        );
    };

    const renderPlanList = () => {
        // Plans were NOT fetched yet
        if (!plans) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Plans were fetched, but plans were NOT created yet
        if (plans.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No plans were found
                        </Heading>
                    }
                />
            );
        }

        return plans.items.map((plan) => (
            <PlanItem
                key={plan.id}
                className="mb-2"
                plan={plan}
                onClick={() => navigate(Endpoints.PLAN.replace(':planId', `${plan.id}`))}
            />
        ));
    };

    return (
        <FixedWidthPageContainer>
            {renderHeader()}
            {renderPlanList()}
        </FixedWidthPageContainer>
    );
};

export default Plans;
