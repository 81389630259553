import { ValidatorFunction, ValidatorResult } from '../types';

const checkValidators = async <T>(value: T, validators: ValidatorFunction<T>[]): Promise<ValidatorResult> => {
    let validatorResult: ValidatorResult = null;

    for (let i = 0; validatorResult === null && i < validators.length; i++) {
        // Get validator
        const currentValidator = validators[i];

        // Validate value
        const res = await currentValidator(value);

        if (res) {
            // Value passed current validator validation
            validatorResult = res;
        }
    }

    return validatorResult;
};

export default checkValidators;
