import FixedWidthPageContainer from '@app/components/FixedWidthPageContainer';
import { SessionStorage } from '@app/data/enums';
import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import amountOfTaskClientFeedbacksOnPage from './consts/amountOfTaskClientFeedbacksOnPage';
import { fetchTaskClientFeedback, fetchTaskClientFeedbackSummary, hydrateQueryParameters } from './store/effects';
import {
    $isQueryHydrationFinished,
    $page,
    $platformTaskClientFeedbackData,
    $taskClientFeedback,
    $taskClientFeedbackSummary,
} from './store/states';
import { PlatformTaskClientFeedbackFilter as PlatformTaskClientFeedbackFilterValueObject } from './valueObjects';
import qs from 'qs';
import { PlatformTaskClientFeedbackFilterData, TaskClientFeedbackFilterQueryParameters } from './types';
import {
    resetIsQueryHydrationFinished,
    resetPlatformTaskClientFeedbackData,
    resetTaskClientFeedback,
    resetTaskClientFeedbackSummary,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformTaskClientFeedbackFilterData,
} from './store/events';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { resetPage } from '../Tasks/store/events';
import Pagination from '@app/components/Pagination';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { Spinner } from '@blueprintjs/core';
import PlatformTaskClientFeedbackFilter from './components/PlatformTaskClientFeedbackFilter';
import { default as TaskClientFeedbackTable } from '@containers/components/TaskClientFeedback';
import { usePageTitle } from '@app/hooks';

const TaskClientFeedback: React.FC = () => {
    usePageTitle('Feedback Summary');

    const navigate = useNavigate();

    const page = useStore($page);
    const taskClientFeedback = useStore($taskClientFeedback);
    const taskClientFeedbackSummary = useStore($taskClientFeedbackSummary);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformTaskFilterData = useStore($platformTaskClientFeedbackData);
    const isFetchingTaskClientFeedback = useStore(fetchTaskClientFeedback.pending);
    const isFetchingTaskClientFeedbackSummary = useStore(fetchTaskClientFeedbackSummary.pending);

    const handleLoadPage = async (newPage: number, filter: TaskClientFeedbackFilterInput) => {
        const pageOffset = (newPage - 1) * amountOfTaskClientFeedbacksOnPage;

        try {
            // Fetch client feedback
            fetchTaskClientFeedback({
                pagination: {
                    _pagination: { limit: amountOfTaskClientFeedbacksOnPage, offset: pageOffset },
                },
                filter,
            });

            // Fetch client summary
            fetchTaskClientFeedbackSummary({ filter });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Empty query string, load from local storage
            const savedUrlQueryParameters = sessionStorage.getItem(SessionStorage.TASK_CLIENT_FEEDBACK_FILTER);

            if (savedUrlQueryParameters && savedUrlQueryParameters.length > 0) {
                urlQueryParameters = savedUrlQueryParameters;
            }
        }

        // Task filter query parameters
        const taskFilterQueryParameters: Partial<TaskClientFeedbackFilterQueryParameters> =
            qs.parse(urlQueryParameters);

        // Hydration input
        const hydrateQueryInput =
            PlatformTaskClientFeedbackFilterValueObject.convertFilterQueryParametersToHydrateQueryInput(
                taskFilterQueryParameters
            );

        // Platform task filter data
        const platformTaskFilterData: PlatformTaskClientFeedbackFilterData = {
            negative: taskFilterQueryParameters.negative ?? [],
            clients: [],
            participants: [],
            teams: [],
        };

        // hydrate
        hydrateQueryParameters(hydrateQueryInput)
            .then(({ client_user, core_user, team }) => {
                platformTaskFilterData.clients = client_user ?? [];
                platformTaskFilterData.participants = core_user ?? [];
                platformTaskFilterData.teams = team ?? [];
            })
            .finally(() => {
                const page = taskFilterQueryParameters.page ? parseInt(taskFilterQueryParameters.page) : 1;

                setPage(page);
                setPlatformTaskClientFeedbackFilterData(platformTaskFilterData);
                setIsQueryHydrationFinished(true);
            });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            // Get task client feedback filter input
            const tasksFilterInput =
                PlatformTaskClientFeedbackFilterValueObject.convertPlatformFilterDataToFilterInput(
                    platformTaskFilterData
                );

            // Get task client feedback filter query parameters string
            const taskClientFeedbackFilterQueryParametersString = qs.stringify(
                PlatformTaskClientFeedbackFilterValueObject.convertFilterInputToFilterQueryParameters(
                    page,
                    tasksFilterInput
                )
            );

            // Save query parameters to session storage
            sessionStorage.setItem(
                SessionStorage.TASK_CLIENT_FEEDBACK_FILTER,
                taskClientFeedbackFilterQueryParametersString
            );

            // Update URL
            navigate(`${Endpoints.TASK_CLIENT_FEEDBACK}?${taskClientFeedbackFilterQueryParametersString}`, {
                replace: true,
            });

            // Fetch task list with new page
            handleLoadPage(page, tasksFilterInput);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformTaskFilterData]);

    useEffect(() => {
        return () => {
            resetTaskClientFeedback();
            resetIsQueryHydrationFinished();
            resetTaskClientFeedbackSummary();
            resetPage();
            resetPlatformTaskClientFeedbackData();
        };
    }, []);

    if (!taskClientFeedback || !taskClientFeedbackSummary) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Feedback summary</Heading>
            </Flex>
        );
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (taskClientFeedback === null) {
            return null;
        }

        // Check if offset paginator exist
        if (taskClientFeedback.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskClientFeedback.paginator.has_more && taskClientFeedback.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTaskClientFeedback}
                    hasMore={taskClientFeedback.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTaskClientFeedbacksOnPage}
                    totalItems={taskClientFeedback.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformTaskClientFeedbackFilter className="mb-2" />

            {/* Task client feedback */}
            <TaskClientFeedbackTable
                loading={isFetchingTaskClientFeedback}
                loadingSummary={isFetchingTaskClientFeedbackSummary}
                taskClientFeedback={taskClientFeedback.items}
                taskClientFeedbackSummary={taskClientFeedbackSummary}
            />

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default TaskClientFeedback;
