import React, { useEffect, useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Checkbox, Divider, Elevation, FormGroup, HTMLSelect, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { useStore } from 'effector-react';
import { $taskCategories } from '../../store/states';
import { fetchTaskCategories } from '../../store/effects';
import {
    getStringRequiredValidator,
    getStringMinLengthValidator,
    getStringMaxLengthValidator,
    getStringNumericValueValidator,
    getStringNumericValueOfTypeIntValidator,
} from '@app/hooks/validation/functions';
import { useForm, useTextFormField } from '@app/hooks';
import { updateTaskCategoryFeaturedBlock } from './store/effects';
import Grid from '@app/components/Grid';
import InputFormField from '@app/components/InputFormField';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import DevText from '@app/components/Text';
import { taskCategoryFeaturedBlocksApi } from '../../store/apis';
import { UpdateTaskCategoryFeaturedBlockInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/inputs';

const taskCategoryFeaturedBlockTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];

const taskCategoryFeaturedBlockSummaryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];

const taskCategoryFeaturedBlockPositionValidators = [
    getStringRequiredValidator(),
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
];

export interface UpdateTaskCategoryFeaturedBlockModalProps {
    isEnabled: boolean;
    title: string;
    summary: string;
    position: number;
    taskCategoryFeaturedBlockId: ID;
    selectedTaskCategoryIds: ID[];
}

type Props = ModalProps<UpdateTaskCategoryFeaturedBlockModalProps>;

const UpdateTaskCategoryFeaturedBlockModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskCategories = useStore($taskCategories);

    const [isTaskCategoryFeaturedBlockEnabled, setIsTaskCategoryFeaturedBlockEnabled] = useState(!!data?.isEnabled);
    const [selectedTaskCategoryId, setSelectedTaskCategoryId] = useState<ID | null>(null);
    const [selectedTaskCategories, setSelectedTaskCategories] = useState<TaskCategoryResource[] | null>(null);

    const taskCategoryFeaturedBlockTitle = useTextFormField({
        id: 'task-category-featured-block-title',
        validators: taskCategoryFeaturedBlockTitleValidators,
        initialValue: data?.title ?? '',
    });

    const taskCategoryFeaturedBlockSummary = useTextFormField({
        id: 'task-category-featured-block-summary',
        validators: taskCategoryFeaturedBlockSummaryValidators,
        initialValue: data?.summary ?? '',
    });

    const taskCategoryFeaturedBlockPosition = useTextFormField({
        id: 'task-category-featured-block-position',
        validators: taskCategoryFeaturedBlockPositionValidators,
        initialValue: data ? `${data.position}` : '',
    });

    const form = useForm({
        fields: [taskCategoryFeaturedBlockTitle, taskCategoryFeaturedBlockSummary, taskCategoryFeaturedBlockPosition],
        apiCall: async () => {
            try {
                if (!data) {
                    throw new Error('Modal data was not passed');
                }

                // Create input
                const updateTaskCategoryFeaturedBlockInput: UpdateTaskCategoryFeaturedBlockInput = {
                    is_enabled: isTaskCategoryFeaturedBlockEnabled,
                    position: parseInt(taskCategoryFeaturedBlockPosition.value),
                    summary: taskCategoryFeaturedBlockSummary.value,
                    task_category_ids: selectedTaskCategories
                        ? selectedTaskCategories.map((item) => item.id)
                        : data.selectedTaskCategoryIds,
                    title: taskCategoryFeaturedBlockTitle.value,
                };

                // Update task category featured block
                await updateTaskCategoryFeaturedBlock({
                    taskCategoryFeaturedBlockId: data.taskCategoryFeaturedBlockId,
                    input: updateTaskCategoryFeaturedBlockInput,
                });

                // Call update success callback
                taskCategoryFeaturedBlocksApi.update({
                    taskCategoryFeaturedBlockId: data.taskCategoryFeaturedBlockId,
                    input: updateTaskCategoryFeaturedBlockInput,
                    selectedTaskCategories: selectedTaskCategories
                        ? selectedTaskCategories.map((taskCategory) => ({
                              id: taskCategory.id,
                              archived_at: taskCategory.archived_at,
                              groups: taskCategory.groups.map((group) => ({
                                  id: group.id,
                                  archived_at: null,
                                  title: group.title,
                                  color: group.color,
                              })),
                              is_enabled: taskCategory.is_enabled,
                              title: taskCategory.title,
                          }))
                        : [],
                });

                // Close modal
                handleModalClose();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        const handleInitializeSelectedTaskCategories = (list: TaskCategoryResource[]) => {
            if (data && data.selectedTaskCategoryIds.length > 0) {
                setSelectedTaskCategories(
                    list.filter((category) => data.selectedTaskCategoryIds.includes(category.id))
                );
            } else {
                setSelectedTaskCategories([]);
            }
        };

        const handleInitialize = async () => {
            try {
                if (taskCategories) {
                    // Task categories already fetched
                    handleInitializeSelectedTaskCategories(taskCategories.items);
                    return;
                }

                // Fetch task categories
                const fetchedTaskCategories = await fetchTaskCategories();

                // Initialize selected task categories
                handleInitializeSelectedTaskCategories(fetchedTaskCategories.items);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        };

        handleInitialize();
    }, []);

    useEffect(() => {
        if (!data) {
            handleModalClose();
            return;
        }
    }, [data]);

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderTaskCategoryPicker = () => {
        if (!selectedTaskCategories) {
            return null;
        }

        if (!taskCategories) {
            return null;
        }

        const availableTaskCategories = taskCategories.items.filter(
            (category) => !selectedTaskCategories.find((a) => a.id === category.id)
        );

        const handleAddAttachment = () => {
            // Find task category
            const foundTaskCategory = taskCategories.items.find((category) => category.id === selectedTaskCategoryId);
            if (!foundTaskCategory) {
                return;
            }

            setSelectedTaskCategories((prevSelectedTaskCategories) =>
                prevSelectedTaskCategories
                    ? [
                          ...prevSelectedTaskCategories,
                          {
                              ...foundTaskCategory,
                          },
                      ]
                    : null
            );
        };

        return (
            <FormGroup label="Select request category to add">
                <Flex>
                    <HTMLSelect
                        fill
                        disabled={!taskCategories || availableTaskCategories.length === 0}
                        className="mr-1"
                        value={String(selectedTaskCategoryId)}
                        onChange={(e) => setSelectedTaskCategoryId(e.target.value)}
                    >
                        <option value={undefined}>Select request category</option>
                        {availableTaskCategories.map((category) => (
                            <option key={`qt-${category.id}`} value={category.id}>
                                {category.title}
                            </option>
                        ))}
                    </HTMLSelect>

                    <Button
                        disabled={selectedTaskCategoryId === null}
                        onClick={() => {
                            if (selectedTaskCategoryId === null) {
                                return;
                            }

                            handleAddAttachment();
                            setSelectedTaskCategoryId(null);
                        }}
                    >
                        Add
                    </Button>
                </Flex>
            </FormGroup>
        );
    };

    const renderSelectedTaskCategories = () => {
        if (!selectedTaskCategories) {
            return (
                <Flex direction="row" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        const handleSwapSelectedTaskCategories = (leftIndex: number, rightIndex: number) => {
            setSelectedTaskCategories((prevSelectedTaskCategories) => {
                if (prevSelectedTaskCategories === null) {
                    return null;
                }

                // Get list copy
                const copyList = [...prevSelectedTaskCategories];

                // Swap
                const temp = { ...copyList[leftIndex] };
                copyList[leftIndex] = { ...copyList[rightIndex] };
                copyList[rightIndex] = temp;

                return copyList;
            });
        };

        const handleMoveTaskCategoryAbove = (leftIndex: number) => {
            let rightIndex = 0;
            if (leftIndex === 0) {
                return;
            } else {
                rightIndex = leftIndex - 1;
            }

            handleSwapSelectedTaskCategories(leftIndex, rightIndex);
        };

        const handleMoveTaskCategoryBelow = (leftIndex: number, maxIndex: number) => {
            let rightIndex = maxIndex;
            if (leftIndex === maxIndex) {
                return;
            } else {
                rightIndex = leftIndex + 1;
            }

            handleSwapSelectedTaskCategories(leftIndex, rightIndex);
        };

        const renderTaskCategory = (taskCategory: TaskCategoryResource, index: number, maxIndex: number) => {
            const isFirstElement = index === 0;
            const isLastElement = index === maxIndex;

            return (
                <Card compact elevation={Elevation.ONE}>
                    <Flex direction="row" justify="space-between">
                        <div>
                            <Heading type="h6">{taskCategory.title}</Heading>
                            <DevText>{taskCategory.summary}</DevText>
                        </div>

                        <Flex direction="row">
                            <Button
                                minimal
                                disabled={isFirstElement}
                                className="mr-1"
                                icon="chevron-up"
                                onClick={() => handleMoveTaskCategoryAbove(index)}
                            />
                            <Button
                                minimal
                                disabled={isLastElement}
                                className="mr-1"
                                icon="chevron-down"
                                onClick={() => handleMoveTaskCategoryBelow(index, maxIndex)}
                            />
                            <Button
                                minimal
                                className="mr-1"
                                icon="trash"
                                intent={Intent.DANGER}
                                onClick={() =>
                                    setSelectedTaskCategories((prevValues) =>
                                        prevValues ? prevValues.filter((v) => v.id !== taskCategory.id) : prevValues
                                    )
                                }
                            />
                        </Flex>
                    </Flex>
                </Card>
            );
        };

        return (
            <div>
                {selectedTaskCategories.map((taskCategory, index) => (
                    <div key={taskCategory.id} className={index === 0 ? '' : 'mt-1'}>
                        {renderTaskCategory(taskCategory, index, selectedTaskCategories.length - 1)}
                    </div>
                ))}
            </div>
        );
    };

    const isFormValid =
        (!form.hasFieldErrors &&
            isTaskCategoryFeaturedBlockEnabled &&
            selectedTaskCategories !== null &&
            selectedTaskCategories.length > 0) ||
        !isTaskCategoryFeaturedBlockEnabled;

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update request category featured block</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        <Grid lg={6} xs={6}>
                            <InputFormField
                                className="mb-2"
                                field={taskCategoryFeaturedBlockTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter featured block title' }}
                            />
                        </Grid>

                        <Grid lg={6} xs={6}>
                            <InputFormField
                                className="mb-2"
                                field={taskCategoryFeaturedBlockPosition}
                                formGroupProps={{ label: 'Position' }}
                                inputProps={{ placeholder: 'Enter featured block position' }}
                            />
                        </Grid>

                        <Grid lg={12} xs={12}>
                            <TextAreaFormField
                                field={taskCategoryFeaturedBlockSummary}
                                formGroupProps={{ label: 'Summary' }}
                                textAreaProps={{ placeholder: 'Enter featured block summary' }}
                            />
                        </Grid>

                        <Grid className="mb-2" lg={12} xs={12}>
                            <Checkbox
                                checked={isTaskCategoryFeaturedBlockEnabled}
                                label="Is Enabled?"
                                onChange={() => setIsTaskCategoryFeaturedBlockEnabled((prevValue) => !prevValue)}
                            />
                        </Grid>

                        <Grid lg={12} xs={12}>
                            {renderTaskCategoryPicker()}
                        </Grid>

                        <Grid lg={12} xs={12}>
                            {renderSelectedTaskCategories()}
                        </Grid>
                    </Grid>

                    <Flex className="mt-2" align="center" justify="flex-end">
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        {/* TODO: add disabled prop & think should we allow submit if "isEnabled=true" and no task categories selected */}
                        <Button
                            loading={form.isSubmitting}
                            disabled={!isFormValid}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Yes, update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskCategoryFeaturedBlockModal;
