import React, { HTMLAttributes } from 'react';
import { StyledFixedWidthPageContainer } from './styled';

export type Props = HTMLAttributes<HTMLDivElement>;

const FixedWidthPageContainer: React.FC<Props> = ({ children, ...props }) => {
    return <StyledFixedWidthPageContainer {...props}>{children}</StyledFixedWidthPageContainer>;
};

export default FixedWidthPageContainer;
