import { fetchAuthorizedUser } from '@app/containers/store/effects';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import useLogout from '../useLogout';

const useFetchAuthorizedUser = () => {
    const logout = useLogout();

    const handleFetchAuthorizedUser = async () => {
        try {
            const authorizedUser = await fetchAuthorizedUser();
            if (authorizedUser.user.type !== UserType.CORE) {
                // Current user is NOT core user, DO NOT ALLOW THEM TO THE PLATFORM
                logout();
            }

            return authorizedUser;
        } catch (e) {
            throw e;
        }
    };

    return handleFetchAuthorizedUser;
};

export default useFetchAuthorizedUser;
