import React, { useState, useEffect } from 'react';

const useDebouncedState = <T>(state: T, delay: number) => {
    const [debouncedState, setDebouncedState] = useState(state);
    useEffect(() => {
        const handler = setTimeout(() => setDebouncedState(state), delay);
        return () => clearTimeout(handler);
    }, [state, delay]);

    return debouncedState;
};

export default useDebouncedState;
