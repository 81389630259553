import repository from 'dy-frontend-http-repository/lib/repository';
import { UpdateUserInformationInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';
import { CoreUserResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { UserImageHashRef, UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import domain from './domain';
import { UserOccupancyFilterInput } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/inputs';
import { UserOccupancyResource } from 'dy-frontend-http-repository/lib/modules/UserOccupancy/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';

// Fetch core user
export const fetchCoreUser = domain.createEffect<ID, CoreUserResource>({
    name: 'fetchCoreUser',
    handler: async (clientId) => {
        return await repository.coreUser().getById(clientId);
    },
});

// Update core user information
export const updateCoreUserInformation = domain.createEffect<{ id: ID; input: UpdateUserInformationInput }, UserRef>({
    name: 'updateCoreUserInformation',
    handler: async ({ id, input }) => {
        return await repository.user().update(id, input);
    },
});

// Fetch core user occupancy information
export const fetchCoreUserOccupancy = domain.createEffect<
    UserOccupancyFilterInput,
    CollectionResource<UserOccupancyResource, {}>
>({
    name: 'fetchCoreUserOccupancy',
    handler: async (filter) => {
        return await repository.userOccupancy().get(filter);
    },
});

// Upload user image
// TODO: move?
export const uploadAndSetCoreUserImage = domain.createEffect<{ id: ID; file: File }, UserImageHashRef>({
    name: 'uploadAndSetCoreUserImage',
    handler: async ({ id, file }) => {
        return await repository.user().uploadAndSetImage(id, file);
    },
});

// Remove user image
// TODO: move?
export const removeCoreUserImage = domain.createEffect<ID, UserRef>({
    name: 'removeCoreUserImage',
    handler: async (id) => {
        return await repository.user().removeImage(id);
    },
});
