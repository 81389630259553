import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';
import { SetCoreUserOccupancySettings } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';

// Update occupancy settings
export const updateOccupancySettings = domain.createEffect<
    { coreUserId: ID; input: SetCoreUserOccupancySettings },
    TaskRef
>({
    name: 'updateOccupancySettings',
    handler: async ({ coreUserId, input }) => {
        return await repository.coreUser().updateOccupancySettings(coreUserId, input);
    },
});
