import { $currentPathLocation, $taskDeliverables } from './states';
import { fetchTaskDeliverables } from './effects';
import { setCurrentPathLocation } from './events';

// Fetch task deliverables
$taskDeliverables.on(fetchTaskDeliverables.doneData, (_, data) => {
    // Collect parent deliverable IDs
    const parentDeliverableIds: ID[] = [];
    data.items.forEach((deliverable) => {
        if (deliverable.parent_task_deliverable !== null) {
            parentDeliverableIds.push(deliverable.parent_task_deliverable.id);
        }
    });

    return {
        ...data,
        items: data.items.filter((deliverable) => !parentDeliverableIds.includes(deliverable.id)),
    };
});

// Set current path location
$currentPathLocation.on(setCurrentPathLocation, (_, data) => data);
