import { GetValidatorFunction } from '@app/hooks/validation/types';
import richEditorPlugins from '@components/RichEditor/plugins';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';

const defaultRichStringRequiredMessage = 'This field is required';
const getRichStringRequiredValidator: GetValidatorFunction<{ richStringRequiredMessage?: string }, string> = (
    options = { richStringRequiredMessage: defaultRichStringRequiredMessage }
) => {
    const { richStringRequiredMessage } = options;

    return async (value) => {
        if (new RichTextFormat(value, richEditorPlugins).isEmpty()) {
            // Rich string is empty
            if (richStringRequiredMessage) {
                return richStringRequiredMessage;
            } else {
                return defaultRichStringRequiredMessage;
            }
        } else {
            // Rich string is NOT empty
            return null;
        }
    };
};

export default getRichStringRequiredValidator;
