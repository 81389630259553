import repository from 'dy-frontend-http-repository/lib/repository';
import { BrandProfileListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfile/resources';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import {
    CreateBrandProfileInput,
    BrandProfilesFilterInput,
} from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import domain from './domain';

// Fetch client brand profiles
export const fetchClientBrandProfiles = domain.createEffect<
    { pagination: OffsetPaginationInput; filter: BrandProfilesFilterInput },
    CollectionResource<BrandProfileListItemResource, {}>
>({
    name: 'fetchClientBrandProfiles',
    handler: async ({ pagination, filter }) => {
        return await repository.brandProfile().get(pagination, filter);
    },
});

// Create client brand profile
export const createClientBrandProfile = domain.createEffect<CreateBrandProfileInput, BrandProfileRef>({
    name: 'createClientBrandProfile',
    handler: async (input) => {
        return await repository.brandProfile().create(input);
    },
});
