import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    TaskMessageTemplateListItemResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import {
    TaskMessageTemplateCategoryListItemResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import { resetTaskMessages, resetTaskMessageTemplateCategories } from './events';
import domain from './domain';

// Task message template categories
export const $taskMessageTemplateCategories = domain
    .createStore<CollectionResource<TaskMessageTemplateCategoryListItemResource, {}> | null>(null)
    .reset(resetTaskMessageTemplateCategories);

// Task message templates
export const $taskMessageTemplates = domain
    .createStore<CollectionResource<TaskMessageTemplateListItemResource, {}> | null>(null)
    .reset(resetTaskMessages);
