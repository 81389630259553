import { InvoiceBillingReason } from 'dy-frontend-http-repository/lib/modules/Invoice/enums';

// TODO: adjust label texts with backend developer
const invoiceBillingReasonInformation: { [reason in InvoiceBillingReason]: string } = {
    [InvoiceBillingReason.MANUAL]: 'Manually created invoice',
    [InvoiceBillingReason.SUBSCRIPTION]: 'Subscription payment',
    [InvoiceBillingReason.SUBSCRIPTION_CREATE]: 'Subscription creation',
    [InvoiceBillingReason.SUBSCRIPTION_CYCLE]: 'Subscription period',
    [InvoiceBillingReason.SUBSCRIPTION_THRESHOLD]: 'Subscription billing threshold',
    [InvoiceBillingReason.SUBSCRIPTION_UPDATE]: 'Subscription update',
    [InvoiceBillingReason.UPCOMING]: 'Upcoming invoice',
};

export default invoiceBillingReasonInformation;
