import React from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import { Button, Callout, Classes, Intent } from '@blueprintjs/core';
import DevText from '@components/Text';
import ConfirmationPopover from '@components/ConfirmationPopover';
import { publishPlan } from '@pages/Plan/store/effects';
import { planApi } from '@pages/Plan/store/apis';

// Top: Warning about unpublished plan
const PublishManager: React.FC = () => {
    const plan = useStore($plan);

    const isPublishing = useStore(publishPlan.pending);

    if (!plan) {
        return null;
    }

    // Skip if published
    if (plan.published_at !== null) {
        return null;
    }

    const handlePublish = async () => {
        try {
            await publishPlan(plan.id);
            planApi.publish();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    return (
        <Callout icon={null} intent={Intent.WARNING} title="This plan is still in draft" className="mb-2">
            <DevText running className="mb-2">
                This plan is still in draft, meaning it cannot be used, until it will be published. You can edit plan
                now, and when you are ready, click "Publish Plan" below.
            </DevText>
            <ConfirmationPopover
                title="Publish plan"
                description="After publishing plan can be used in the platform, proceed?"
                actions={[
                    <Button intent={Intent.PRIMARY} className={Classes.POPOVER_DISMISS} onClick={handlePublish}>
                        Yes, publish plan
                    </Button>,
                ]}
            >
                <Button loading={isPublishing} disabled={isPublishing} intent={Intent.WARNING}>
                    Publish plan
                </Button>
            </ConfirmationPopover>
        </Callout>
    );
};

export default PublishManager;
