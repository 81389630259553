import repository from 'dy-frontend-http-repository/lib/repository';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { CancelSubscriptionInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import domain from './domain';

// Pause subscription
export const cancelSubscription = domain.createEffect<
    { subscriptionId: ID; input: CancelSubscriptionInput },
    SubscriptionRef
>({
    name: 'cancelSubscription',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().cancelSubscription(subscriptionId, input);
    },
});
