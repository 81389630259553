import moment from 'moment';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription has trial
 * @param subscription subscription which should be checked for trial presence
 * @returns {boolean} subscription trial presence flag
 */
export default function checkIfSubscriptionTrialPresent(subscription: SubscriptionResource) {
    const trial = subscription.trial;

    if (trial.start_at === null || trial.end_at === null) {
        // If any of the trial dates are null, then trial is NOT present
        return false;
    }

    const currentDate = moment();
    if (moment(currentDate).isBefore(trial.start_at) || moment(currentDate).isAfter(trial.end_at)) {
        // If current date is not in the interval of trial [start_at, end_at], then there is no trial
        return false;
    }

    return true;
}
