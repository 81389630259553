import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { Endpoints } from '@data/consts';
import { ModalProps } from '@modals/types';
import { createClientBrandProfile } from '@pages/Client/pages/ClientBrandProfiles/store/effects';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';

export interface CreateBrandProfileModalProps {
    userId: ID;
}

type Props = ModalProps<CreateBrandProfileModalProps>;

const brandProfileTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];

const CreateBrandProfileModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const isForcedCreationAllowed = (permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.CREATE_FORCED));
    const [isCreationForced, setIsCreationForced] = useState(isForcedCreationAllowed);

    const brandProfileTitle = useTextFormField({
        id: 'brand-profile-title',
        validators: brandProfileTitleValidators,
        initialValue: '',
    });

    const form = useForm<BrandProfileRef>({
        fields: [brandProfileTitle],
        apiCall: async () => {
            try {
                const brandProfileRef = await createClientBrandProfile({
                    title: brandProfileTitle.value,
                    user_id: data!.userId,
                    is_forced: isCreationForced,
                });
                return { response: brandProfileRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: brandProfileRef }) => {
            closeModal?.();
            navigate(Endpoints.BRAND_PROFILE.replace(':brandProfileId', brandProfileRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create Brand Profile</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <InputFormField
                        field={brandProfileTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter brand profile title' }}
                    />

                    {isForcedCreationAllowed && (
                        <Checkbox
                            checked={isCreationForced}
                            label="Ignore limit check"
                            onChange={() => setIsCreationForced((prevValue) => !prevValue)}
                        />
                    )}

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Add
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateBrandProfileModal;
