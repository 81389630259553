import { UserNoteListItemResource } from 'dy-frontend-http-repository/lib/modules/UserNote/resources';
import domain from './domain';

// Set editing client note
export const setEditingClientNote = domain.createEvent<UserNoteListItemResource | null>('setEditingClientNote');

// Reset client notes
export const resetClientNotes = domain.createEvent('resetClientNotes');

// Reset editing client note
export const resetEditingClientNote = domain.createEvent('resetEditingClientNote');
