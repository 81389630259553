import { repository } from "dy-frontend-http-repository/lib/modules";
import domain from "./domain";
import { WorkspaceResource } from "dy-frontend-http-repository/lib/modules/Workspace/resources";

// Fetch workspace
export const fetchWorkspace = domain.createEffect<ID, WorkspaceResource>({
    name: "fetchWorkspace",
    handler: async (workspaceId) => {
        return await repository.workspace().getById(workspaceId);
    },
});
