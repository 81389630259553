import domain from './domain';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AuthSchemaFilterInput } from 'dy-frontend-http-repository/lib/modules/Auth/inputs';
import { AuthSchemaResource } from 'dy-frontend-http-repository/lib/modules/Auth/resources';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/ClientSignup/refs';
import { SetUserEmailInput } from 'dy-frontend-http-repository/lib/modules/User/inputs';
import { CreateUserEmailChangeInput } from 'dy-frontend-http-repository/lib/modules/UserEmailChange/inputs';
import { UserEmailChangeRef } from 'dy-frontend-http-repository/lib/modules/UserEmailChange/refs';

// Fetch authentication schema by email
export const fetchAuthenticationSchemaByEmail = domain.createEffect<
    { filter: AuthSchemaFilterInput },
    AuthSchemaResource
>({
    name: 'fetchAuthenticationSchemaByEmail',
    handler: async ({ filter }) => {
        return await repository.auth().getEmailSchema(filter);
    },
});

// Update user email immediately
export const setUserEmail = domain.createEffect<{ id: ID; input: SetUserEmailInput }, UserRef>({
    name: 'setUserEmail',
    handler: async ({ id, input }) => {
        return await repository.user().setEmail(id, input);
    },
});

// Creates user email change intent
export const createUserEmailChangeIntent = domain.createEffect<CreateUserEmailChangeInput, UserEmailChangeRef>({
    name: 'createUserEmailChangeIntent',
    handler: async (input) => {
        return await repository.userEmailChange().create(input);
    },
});
