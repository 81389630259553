import { createApi } from 'effector';
import { ClientUserResource } from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import { $client } from './states';
import { UserImageHashRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import moment from 'moment';

// API to manage client
export const clientApi = createApi($client, {
    // Update client information
    update: (store, payload: Partial<ClientUserResource>) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload,
        };
    },

    // Verify
    verify: (store) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        // Get moment now UTC
        const momentNowUtc = moment().utc().format();

        return {
            ...store,
            verified_at: momentNowUtc,
            initial_verification_at: store.initial_verification_at ? store.initial_verification_at : momentNowUtc,
        };
    },

    // Remove verification
    removeVerification: (store) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        return {
            ...store,
            verified_at: null,
        };
    },

    // Set image
    // TODO: move?
    setImageHash: (store, payload: UserImageHashRef) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        return {
            ...store,
            image_hash: payload.hash,
        };
    },

    // Remove image
    // TODO: move?
    removeImage: (store) => {
        if (!store) {
            // Client is NOT fetched yet
            return store;
        }

        return {
            ...store,
            image_hash: null,
        };
    },
});
