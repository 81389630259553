import React, { HTMLAttributes } from 'react';
import { FormGroup, FormGroupProps, Intent, TextArea, TextAreaProps } from '@blueprintjs/core';
import { ReturnValue as TextFormFieldReturnValue } from '@app/hooks/validation/useTextFormField';

export interface TextAreaFormFieldProps {
    field: TextFormFieldReturnValue;
    formGroupProps?: FormGroupProps;
    textAreaProps?: TextAreaProps;
}

export type Props = HTMLAttributes<HTMLDivElement> & TextAreaFormFieldProps;

const TextAreaFormField: React.FC<Props> = ({ field, formGroupProps, textAreaProps, ...props }) => {
    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <TextArea
                fill
                id={field.id}
                intent={!!field.error ? Intent.DANGER : Intent.NONE}
                value={field.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                {...textAreaProps}
            />
        </FormGroup>
    );
};

export default TextAreaFormField;
