import React, { HTMLAttributes } from 'react';
import { FormGroup, FormGroupProps, Intent } from '@blueprintjs/core';
import { ReturnValue as ColorTilePickerFormFieldReturnValue } from '@app/hooks/validation/useColorPickerFormField';
import ColorTilePicker, { ColorTilePickerProps } from '../ColorTilePicker';

export interface ColorTilePickerFormFieldProps {
    field: ColorTilePickerFormFieldReturnValue;
    formGroupProps?: FormGroupProps;
    colorTilePickerProps?: Partial<Omit<ColorTilePickerProps, 'color' | 'onChange'>>;
}

export type Props = HTMLAttributes<HTMLDivElement> & ColorTilePickerFormFieldProps;

// This component is ColorTilePicker component with ability to connect validation to it
const ColorTilePickerFormField: React.FC<Props> = ({ field, formGroupProps, colorTilePickerProps, ...props }) => {
    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <ColorTilePicker color={field.value} onChange={field.handleChange} {...colorTilePickerProps} />
        </FormGroup>
    );
};

export default ColorTilePickerFormField;
