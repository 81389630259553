import domain from './domain';

// Set current path location
export const setCurrentPathLocation = domain.createEvent<string>('setCurrentPathLocation');

// Reset task deliverables
export const resetTaskDeliverables = domain.createEvent('resetTaskDeliverables');

// Reset task deliverables tree
export const resetTaskDeliverablesTree = domain.createEvent('resetTaskDeliverablesTree');

// Reset current path location
export const resetCurrentPathLocation = domain.createEvent('resetCurrentPathLocation');
