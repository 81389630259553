import React, { HTMLAttributes } from 'react';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import DYSquareLogo from '@assets/dy/logo_square.png';
import { InfoBlock, LogoBlock, Wrapper } from '@containers/components/Layout/components/NextMenu/components/MenuHeader/styled';

export interface MenuHeaderProps {
    workspaceId: string | null;
    title: string;
    subtitle: string;
    logoImageUrl?: string | null;
}

export type Props = HTMLAttributes<HTMLDivElement> & MenuHeaderProps;

const MenuHeader: React.FC<Props> = ({
    className,
    workspaceId,
    title,
    subtitle,
    logoImageUrl,
}) => {
    // Resolve image
    let backgroundImageValue = workspaceId === null ? `url(${DYSquareLogo})` : undefined;
    if (backgroundImageValue === undefined && logoImageUrl) {
        backgroundImageValue = `url(${logoImageUrl})`;
    }

    return (
        <Wrapper style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 9px',
        }} className={className}>
            <div className="mr-2">
                <LogoBlock style={{
                    backgroundImage: backgroundImageValue,
                }}>
                    {workspaceId !== null && !logoImageUrl && (
                        <Heading type="h2">{title.substring(0, 1).toUpperCase()}</Heading>
                    )}
                </LogoBlock>
            </div>
            <InfoBlock>
                <div>
                    <Heading type="h3">{title}</Heading>
                </div>
                <div>
                    <DevText muted>{subtitle}</DevText>
                </div>
            </InfoBlock>
        </Wrapper>
    );
};


export default MenuHeader;
