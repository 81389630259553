import React, { HTMLAttributes } from 'react';
import { FormGroup, FormGroupProps, Intent } from '@blueprintjs/core';
import { ReturnValue as TimeInputFormFieldReturnValue } from '@app/hooks/validation/useTimeFormField';
import { TimePickerProps, TimePicker } from '@blueprintjs/datetime';

export interface TimeInputFormFieldProps {
    field: TimeInputFormFieldReturnValue;
    formGroupProps?: FormGroupProps;
    timeInputProps?: TimePickerProps;
}

export type Props = HTMLAttributes<HTMLDivElement> & TimeInputFormFieldProps;

const TimeInputFormField: React.FC<Props> = ({ field, formGroupProps, timeInputProps, ...props }) => {
    return (
        <FormGroup
            intent={!!field.error ? Intent.DANGER : Intent.NONE}
            helperText={field.error}
            {...formGroupProps}
            {...props}
        >
            <TimePicker
                value={field.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                {...timeInputProps}
            />
        </FormGroup>
    );
};

export default TimeInputFormField;
