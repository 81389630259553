import domain from "./domain";
import repository from "dy-frontend-http-repository/lib/repository";
import { CollectionResource } from "dy-frontend-http-repository/lib/data/resources";
import { WorkspaceResource } from "dy-frontend-http-repository/lib/modules/Workspace/resources";
import { OffsetPaginationInput } from "dy-frontend-http-repository/lib/data/inputs";
import { WorkspaceRef } from "dy-frontend-http-repository/lib/modules/Workspace/refs";
import { CreateWorkspaceInput } from "dy-frontend-http-repository/lib/modules/Workspace/inputs";

// Fetch workspace list
export const fetchWorkspaces = domain.createEffect<
    { pagination: OffsetPaginationInput },
    CollectionResource<WorkspaceResource, {}>
>({
    name: "fetchWorkspaces",
    handler: async ({ pagination }) => {
        return await repository.workspace().get(pagination);
    },
});

// Create workspace
export const createWorkspace = domain.createEffect<CreateWorkspaceInput, WorkspaceRef>({
    name: "createWorkspace",
    handler: async (input) => {
        return await repository.workspace().create(input);
    },
});
