import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';

// Fetch payment methods
export const fetchPaymentMethods = domain.createEffect<ID, CollectionResource<PaymentMethodResource, {}>>({
    name: 'fetchPaymentMethods',
    handler: async (paymentAccountId) => {
        return await repository.paymentMethod().get(paymentAccountId);
    },
});

// Set/Update subscription payment method
export const updateSubscriptionPaymentMethod = domain.createEffect<
    { subscriptionId: ID; paymentMethodId: ID },
    SubscriptionRef
>({
    name: 'updateSubscriptionPaymentMethod',
    handler: async ({ subscriptionId, paymentMethodId }) => {
        return await repository
            .subscription()
            .updatePaymentMethod(subscriptionId, { payment_method_id: paymentMethodId });
    },
});
