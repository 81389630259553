import { Button, Icon, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { coreUserOpenTaskListDataApi } from './store/apis';
import { fetchCoreUserOpenTaskList } from './store/effects';
import { resetCoreUserOpenTaskListData } from './store/events';
import { $coreUserOpenTaskListData } from './store/states';
import NonIdealState from '@app/components/NonIdealState';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { PlatformTaskFilter } from '@app/containers/pages/Tasks/valueObjects';
import qs from 'qs';
import { $coreUser } from '../../store/states';
import OpenTaskCard from '@app/containers/components/OpenTaskCard';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const CoreUserTasks: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Member Tasks');

    const navigate = useNavigate();
    const coreUser = useStore($coreUser);

    const coreUserOpenTaskListData = useStore($coreUserOpenTaskListData);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        if (!permissions.isEnabled.task) {
            return;
        }

        fetchCoreUserOpenTaskList({ filter: { user_id: coreUser.id } })
            .then((openTaskList) => coreUserOpenTaskListDataApi.initialize({ openTaskList }))
            .catch((e) => {
                // Log
                console.error(e);

                const response = (e as any).response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Open requests for member with ID of ${coreUser.id} was not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to core users page
                    navigate(Endpoints.TASKS);
                }
            });

        return () => {
            resetCoreUserOpenTaskListData();
        };

        // eslint-disable-next-line
    }, [coreUser?.id]);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Member Requests - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    if (!coreUser) {
        return null;
    }

    if (!permissions.isEnabled.task) {
        return null;
    }

    if (!coreUserOpenTaskListData) {
        // Core user open task list data is NOT fetched yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderHeader = () => {
        const handleNavigateToAllCoreUserTasks = () => {
            const taskFilterQueryParametersString = qs.stringify(
                PlatformTaskFilter.convertTaskFilterInputToTaskFilterQueryParameters(1, {
                    participant_user_id: [coreUser.id],
                })
            );
            console.log('taskFilterQueryParametersString: ', taskFilterQueryParametersString);
            navigate(`${Endpoints.TASKS}?${taskFilterQueryParametersString}`);
        };

        return (
            <Flex className="mb-2" justify="space-between" align="center">
                <Heading type="h3">Current user requests</Heading>

                <Button intent={Intent.PRIMARY} className="mr-1" onClick={handleNavigateToAllCoreUserTasks}>
                    View all requests
                </Button>
            </Flex>
        );
    };

    const renderTasksList = (tasks: TaskResource[]) => {
        return tasks.map((task, index) => (
            <OpenTaskCard
                key={task.id}
                className={index === 0 ? '' : 'mt-1'}
                task={task}
                taskData={coreUserOpenTaskListData}
            />
        ));
    };

    const renderPausedTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Paused
                </Heading>

                {renderTasksList(tasks)}
            </div>
        );
    };

    const renderActiveTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Active
                </Heading>
                {renderTasksList(tasks)}
            </div>
        );
    };

    const renderBacklogTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Backlog
                </Heading>
                {renderTasksList(tasks)}
            </div>
        );
    };

    const renderOpenTasks = () => {
        const activeTasks = Object.values(coreUserOpenTaskListData.tasks[OpenTaskType.ACTIVE]);
        const backlogTasks = Object.values(coreUserOpenTaskListData.tasks[OpenTaskType.BACKLOG]);
        const pausedTasks = Object.values(coreUserOpenTaskListData.tasks[OpenTaskType.PAUSED]);

        const isTaskListEmpty = ![activeTasks, backlogTasks, pausedTasks].some((tasks) => tasks.length > 0);
        if (isTaskListEmpty) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            User do not participate in any requests
                        </Heading>
                    }
                />
            );
        }

        return (
            <div>
                <div className="mb-3">{renderPausedTasks(pausedTasks)}</div>
                <div className="mb-3">{renderActiveTasks(activeTasks)}</div>
                <div>{renderBacklogTasks(backlogTasks)}</div>
            </div>
        );
    };

    return (
        <div>
            {renderHeader()}
            {renderOpenTasks()}
        </div>
    );
};

export default CoreUserTasks;
