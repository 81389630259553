import { PlatformTaskClientFeedbackFilterData } from '../types';
import domain from './domain';

// Set page
export const setPage = domain.createEvent<number>('setPage');

// Set query hydration finished flag
export const setIsQueryHydrationFinished = domain.createEvent<boolean>('setIsQueryHydrationFinished');

// Set task client feedback platform filter data
export const setPlatformTaskClientFeedbackFilterData = domain.createEvent<PlatformTaskClientFeedbackFilterData>(
    'setPlatformTaskClientFeedbackFilterData'
);

// Reset task client feedback
export const resetTaskClientFeedback = domain.createEvent('resetTaskClientFeedback');

// Reset task client feedback summary
export const resetTaskClientFeedbackSummary = domain.createEvent('resetTaskClientFeedbackSummary');

// Reset query hydration finished flag
export const resetIsQueryHydrationFinished = domain.createEvent('resetIsQueryHydrationFinished');

// Reset page
export const resetPage = domain.createEvent('resetPage');

// Reset platform task client feedback data
export const resetPlatformTaskClientFeedbackData = domain.createEvent('resetPlatformTaskClientFeedbackData');
