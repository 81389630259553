import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription paused externally (from Stripe)
 * @param subscription subscription for which pause externally flag should be checked
 * @returns {boolean} subscription external pause flag
 */
export default function checkIfSubscriptionPausedExternally(subscription: SubscriptionResource) {
    return subscription.pause.is_paused && !subscription.pause_schedule.is_active;
}
