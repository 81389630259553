import Heading from '@app/components/Heading';
import PageTabs from '@app/components/PageTabs';
import DevText from '@app/components/Text';
import { Endpoints } from '@app/data/consts';
import { usePageTitle } from '@app/hooks';
import { Card, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { $task } from '../../store/states';
import { tabs } from './consts';
import { taskDeliverablesTreeApi } from './store/apis';
import { fetchTaskDeliverables } from './store/effects';
import {
    resetCurrentPathLocation,
    resetTaskDeliverables,
    resetTaskDeliverablesTree,
    setCurrentPathLocation,
} from './store/events';
import { $taskDeliverables, $taskDeliverablesTree } from './store/states';
import { openModal } from '@app/containers/modals/store/events';
import TaskDeliverableViewerModal, { TaskDeliverableViewerModalProps } from '../../modals/TaskDeliverableViewerModal';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskDeliverablesTreeUtils } from '../../valueObjects';

const TaskDeliverables: React.FC = () => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const { taskId } = useParams() as { taskId: ID };
    const { changeTitle } = usePageTitle('Request Deliverables');
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const task = useStore($task);
    const taskDeliverables = useStore($taskDeliverables);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const [isAuthorizedUserParticipant, setIsAuthorizedUserParticipant] = useState(false);

    const [isInitialized, setIsInitialized] = useState(false);
    const [lastProcessedPath, setLastProcessedPath] = useState<string | null>(null);
    const [lastProcessedTaskDeliverableId, setLastProcessedTaskDeliverableId] = useState<ID | null>(null);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        setIsAuthorizedUserParticipant(task.participants.find(p => p.user.id === me.user.id) !== undefined);
    }, [task, me]);

    useEffect(() => {
        return () => {
            resetCurrentPathLocation();
            resetTaskDeliverables();
            resetTaskDeliverablesTree();
            setLastProcessedPath(null);
            setLastProcessedTaskDeliverableId(null);
            setIsInitialized(false);
        };
    }, [taskId]);

    useEffect(() => {
        if (!permissions.isEnabled.taskDeliverable) {
            return;
        }

        if (task && task.id === taskId && !taskDeliverables) {
            fetchTaskDeliverables({ task_id: task.id }).catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Deliverables for request with ID of ${taskId} were not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to task information page
                    navigate(Endpoints.TASK_INFORMATION.replace(':taskId', taskId));
                }
            });
        }

        // eslint-disable-next-line
    }, [task?.id, taskDeliverables]);

    useEffect(() => {
        if (!taskDeliverablesTree) {
            // Task deliverables tree was NOT initialized yet

            if (taskDeliverables) {
                // Task deliverables were fetched

                // Initial insertion of task deliverables data
                taskDeliverablesTreeApi.insert({ taskDeliverables: taskDeliverables.items });

                // Set initialized flag to true
                setIsInitialized(true);
            }
        }

        // eslint-disable-next-line
    }, [taskDeliverables]);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Deliverables - ${task.title}`);
    }, [task?.title]);

    useEffect(() => {
        if (!isInitialized || !task || task.id !== taskId || !taskDeliverables || !taskDeliverablesTree) {
            return;
        }

        // Get query parameters
        const path = searchParams.get('path');
        const taskDeliverableId = searchParams.get('taskDeliverableId');

        // Set path
        if (path && path !== lastProcessedPath) {
            // Path was passed

            setLastProcessedPath(path);

            // Check if path is present
            const isPathPresent = TaskDeliverablesTreeUtils.isPathPresent({
                path: path as string,
                directories: taskDeliverablesTree.directories,
            });

            if (isPathPresent) {
                // Path present
                setCurrentPathLocation(path as string);
            } else {
                console.error('path is not present: ', path);
                searchParams.delete('path');
                setSearchParams(searchParams);
            }
        }

        // Set task deliverable ID
        if (taskDeliverableId && taskDeliverableId !== lastProcessedTaskDeliverableId) {
            // Task deliverable ID exist

            setLastProcessedTaskDeliverableId(taskDeliverableId);

            // Find task deliverable
            let foundTaskDeliverable: TaskDeliverableResource | undefined;
            if (
                path &&
                taskDeliverablesTree.deliverables[path] &&
                taskDeliverablesTree.deliverables[path][taskDeliverableId]
            ) {
                foundTaskDeliverable = taskDeliverablesTree.deliverables[path][taskDeliverableId];
            } else {
                foundTaskDeliverable = taskDeliverables.items.find(
                    (taskDeliverable) => taskDeliverable.id === taskDeliverableId,
                );
            }

            if (foundTaskDeliverable) {
                // Found task deliverable
                openModal<TaskDeliverableViewerModalProps>({
                    ModalComponent: TaskDeliverableViewerModal,
                    data: {
                        taskDeliverableId: foundTaskDeliverable.id,
                        taskDeliverables: Object.values(taskDeliverablesTree.deliverables[foundTaskDeliverable.path]),
                        taskId: task.id,
                        isAssignedToTask: isAuthorizedUserParticipant,
                        onViewerClose: () => {
                            setSearchParams({});
                            setLastProcessedTaskDeliverableId(null);
                        },
                    },
                });
            } else {
                console.error('taskDeliverable is not present: ', taskDeliverableId);
                searchParams.delete('taskDeliverableId');
                setSearchParams(searchParams);
            }
        }
    }, [isInitialized, searchParams]);

    if (!permissions.isEnabled.taskDeliverable) {
        return null;
    }

    if (!isInitialized) {
        // Task was NOT fetched yet OR Task deliverables were NOT fetched yet OR task deliverables tree was NOT initialized yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const getFormattedPath = (path: string) => {
        return path.replace(':taskId', task!.id);
    };

    const renderTabs = () => {
        let pageTitle = '';
        let pageDescription = '';
        if (pathname === Endpoints.TASK_DELIVERABLES_APPROVED.replace(':taskId', task!.id)) {
            // Approved page
            pageTitle = 'Pending approval deliverables';
            pageDescription = 'List of deliverables which are not approved yet';
        } else {
            // All
            pageTitle = 'All deliverables';
            pageDescription = 'List of all deliverables';
        }

        return (
            <Card>
                <Flex direction="row" justify="space-between" align="flex-start">
                    <div>
                        <Heading type="h6" className="mb-small">
                            {pageTitle}
                        </Heading>
                        <DevText muted>{pageDescription}</DevText>
                    </div>

                    <PageTabs
                        pathname={pathname}
                        visibleAmount={7}
                        tabs={tabs}
                        onSelectTab={navigate}
                        pathFormatter={getFormattedPath}
                    />
                </Flex>
            </Card>
        );
    };

    return (
        <div>
            <div className="mb-2">{renderTabs()}</div>
            <Outlet />
        </div>
    );
};

export default TaskDeliverables;
