import { useForm, useRichTextFormField } from '@app/hooks';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import RichEditorFormField from '@components/RichEditorFormField';
import { ModalProps } from '@modals/types';
import { updateBrandProfileInformation } from '@pages/BrandProfile/store/effects';
import { BrandProfileRef } from 'dy-frontend-http-repository/lib/modules/BrandProfile/refs';
import React from 'react';
import { brandProfileApi } from '../../store/apis';

export interface UpdateBrandProfileDescriptionModalProps {
    brandProfileId: ID;
    description: string;
}

type Props = ModalProps<UpdateBrandProfileDescriptionModalProps>;

const brandProfileDescriptionValidators = [];

const UpdateBrandProfileDescriptionModal: React.FC<Props> = ({ closeModal, data }) => {
    const brandProfileDescription = useRichTextFormField({
        id: 'brand-profile-description',
        validators: brandProfileDescriptionValidators,
        initialValue: data?.description ?? '',
    });

    const form = useForm<BrandProfileRef>({
        fields: [brandProfileDescription],
        apiCall: async () => {
            try {
                const brandProfileRef = await updateBrandProfileInformation({
                    id: data!.brandProfileId,
                    input: { description: brandProfileDescription.value },
                });

                brandProfileApi.update({ description: brandProfileDescription.value });

                return { response: brandProfileRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Edit Description</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {/* Description */}
                    <RichEditorFormField
                        field={brandProfileDescription}
                        formGroupProps={{ label: 'Description' }}
                        richEditorProps={{ placeholder: 'Enter description' }}
                    />

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateBrandProfileDescriptionModal;
