import { PlanSubscriptionSetupMode } from 'dy-frontend-http-repository/lib/data/enums';

const planSubscriptionSetupModeInformation: {
    [subscriptionSetupMode in PlanSubscriptionSetupMode]: string;
} = {
    [PlanSubscriptionSetupMode.PUBLIC]: 'Public',
    [PlanSubscriptionSetupMode.INTERNAL]: 'Internal',
    [PlanSubscriptionSetupMode.DISABLED]: 'Disabled',
};

export default planSubscriptionSetupModeInformation;
