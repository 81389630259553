import richEditorPlugins from '@components/RichEditor/plugins';
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import React, { forwardRef, useMemo } from 'react';
import { Wrapper } from './styled';

export interface RichTextProps {
    value: string | EditorState;
}

export type Props = { className?: string } & RichTextProps;

const RichText = forwardRef<Editor, Props>(({ className, value, ...props }, ref) => {
    const richTextFormat = useMemo(() => new RichTextFormat(value, richEditorPlugins), [value]);

    return (
        <Wrapper className={className} {...props}>
            <Editor readOnly ref={ref} editorState={richTextFormat.getState()} onChange={() => undefined} />
        </Wrapper>
    );
});

export default RichText;
