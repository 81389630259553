import { SubscriptionPauseUnusedPeriodType } from '@data/enums';
import moment from 'moment';

const subscriptionPauseUnusedPeriodTypeInformation: {
    [type in SubscriptionPauseUnusedPeriodType]: (unusedPeriodInDays: string) => string;
} = {
    [SubscriptionPauseUnusedPeriodType.CALCULATE_AUTOMATICALLY]: (unusedPeriodInDays) =>
        `Keep current: ${moment().to(moment().add(unusedPeriodInDays, 'days'), true)}`,
    [SubscriptionPauseUnusedPeriodType.CUSTOM]: () => 'Custom unused period',
};

export default subscriptionPauseUnusedPeriodTypeInformation;
