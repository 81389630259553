import { UpdateTaskCategoryFeaturedBlockInput } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/inputs';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/resources';
import { createApi } from 'effector';
import { $taskCategoryFeaturedBlocks } from './states';
import moment from 'moment';

// API to manage task category featured blocks api
export const taskCategoryFeaturedBlocksApi = createApi($taskCategoryFeaturedBlocks, {
    update: (
        store,
        payload: {
            taskCategoryFeaturedBlockId: ID;
            input: UpdateTaskCategoryFeaturedBlockInput;
            selectedTaskCategories: TaskCategoryResource[];
        }
    ) => {
        if (!store) {
            // Task category featured blocks are not fetched
            return store;
        }

        // Copy task category featured blocks
        const taskCategoryFeaturedBlocksCopy = [...store.items];

        // Find task category feature block index
        const foundTaskCategoryFeaturedBlockIndex = taskCategoryFeaturedBlocksCopy.findIndex(
            (block) => block.id === payload.taskCategoryFeaturedBlockId
        );

        if (foundTaskCategoryFeaturedBlockIndex === -1) {
            // Task category featured block is NOT found
            return store;
        }

        // Get task category featured block by index
        const foundTaskCategoryFeaturedBlock = taskCategoryFeaturedBlocksCopy[foundTaskCategoryFeaturedBlockIndex];

        // Update task category featured block
        foundTaskCategoryFeaturedBlock.items = payload.selectedTaskCategories.map((category, index) => ({
            position: index,
            task_category: category,
        }));
        foundTaskCategoryFeaturedBlock.title = payload.input.title ?? foundTaskCategoryFeaturedBlock.title;
        foundTaskCategoryFeaturedBlock.summary = payload.input.summary ?? foundTaskCategoryFeaturedBlock.summary;
        foundTaskCategoryFeaturedBlock.position =
            payload.input.position === undefined ? foundTaskCategoryFeaturedBlock.position : payload.input.position;
        foundTaskCategoryFeaturedBlock.is_enabled =
            payload.input.is_enabled === undefined
                ? foundTaskCategoryFeaturedBlock.is_enabled
                : payload.input.is_enabled;

        return {
            ...store,
            items: taskCategoryFeaturedBlocksCopy,
        };
    },

    remove: (store, taskCategoryFeaturedBlockId: ID) => {
        if (!store) {
            // Task category featured blocks are not fetched
            return store;
        }

        // Copy task category featured blocks
        const taskCategoryFeaturedBlocksCopy = [...store.items];

        // Find task category feature block index
        const foundTaskCategoryFeaturedBlockIndex = taskCategoryFeaturedBlocksCopy.findIndex(
            (block) => block.id === taskCategoryFeaturedBlockId
        );

        if (foundTaskCategoryFeaturedBlockIndex === -1) {
            // Task category featured block is NOT found
            return store;
        }

        // Get task category featured block by index
        const foundTaskCategoryFeaturedBlock = taskCategoryFeaturedBlocksCopy[foundTaskCategoryFeaturedBlockIndex];

        // Update task category featured block
        const momentNowUtc = moment().utc().format();
        foundTaskCategoryFeaturedBlock.archived_at = momentNowUtc;

        return {
            ...store,
            items: taskCategoryFeaturedBlocksCopy,
        };
    },

    restore: (store, taskCategoryFeaturedBlockId: ID) => {
        if (!store) {
            // Task category featured blocks are not fetched
            return store;
        }

        // Copy task category featured blocks
        const taskCategoryFeaturedBlocksCopy = [...store.items];

        // Find task category feature block index
        const foundTaskCategoryFeaturedBlockIndex = taskCategoryFeaturedBlocksCopy.findIndex(
            (block) => block.id === taskCategoryFeaturedBlockId
        );

        if (foundTaskCategoryFeaturedBlockIndex === -1) {
            // Task category featured block is NOT found
            return store;
        }

        // Get task category featured block by index
        const foundTaskCategoryFeaturedBlock = taskCategoryFeaturedBlocksCopy[foundTaskCategoryFeaturedBlockIndex];

        // Update task category featured block

        // Create moment now in UTC
        foundTaskCategoryFeaturedBlock.archived_at = null;

        return {
            ...store,
            items: taskCategoryFeaturedBlocksCopy,
        };
    },
});
