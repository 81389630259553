import React from 'react';
import { fileExtensionImages } from '@data/consts';
import Flex from '@components/Flex';
import Image from '@components/Image';
import Box, { Props as BoxProps } from '@components/Box';

export interface FileExtensionIconProps {
    extension: string;
}

export type Props = FileExtensionIconProps & BoxProps;

const FileExtensionIcon: React.FC<Props> = ({ extension, ...props }) => {
    const renderContent = () => {
        let imageSrc = fileExtensionImages[extension];

        if (!imageSrc) {
            imageSrc = fileExtensionImages['default'];
        }

        return <Image width="100%" height="100%" objectFit="contain" src={imageSrc} alt={extension} />;
    };

    return (
        <Box {...props}>
            <Flex fullWidth fullHeight align="center" justify="center">
                {renderContent()}
            </Flex>
        </Box>
    );
};

export default FileExtensionIcon;
