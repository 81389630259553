import { useCallback, useEffect, useState } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';

export interface UseTextArrayFormFieldReturnValue {
    errors: ValidatorResult[];
    handleChange: (val: string[]) => void;
    handleBlur: (index: number) => void;
}

export type ReturnValue<Value = any, E = ValidatorResult> = DefaultFormFieldReturnValue<Value, E> &
    UseTextArrayFormFieldReturnValue;
export type Props<Value, InitialValue> = DefaultFormFieldProps<Value, InitialValue>;

const useTextArrayFormField = ({ id, initialValue, validators }: Props<string, string[]>): ReturnValue<string[]> => {
    const [values, setValues] = useState<string[]>(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);
    const [errors, setErrors] = useState<ValidatorResult[]>(initialValue.map(() => null));

    useEffect(() => {
        setError(errors.find((error) => !!error) ?? null);
    }, [errors]);

    const handleChange = useCallback(
        async (val: string[]) => {
            // Set new value
            setValues(val);

            // Validate
            const newErrors: ValidatorResult[] = [];
            for (let i = 0; i < val.length; i++) {
                const value = val[i];

                if (value === values[i]) {
                    // Value didn't change, no need to validate again push it as it is
                    newErrors.push(errors[i]);
                    continue;
                } else {
                    // Value changed need to validate again
                    const err = await checkValidators(value, validators);
                    newErrors.push(err);
                }
            }

            setErrors(newErrors);
        },
        [values, errors, validators]
    );

    const handleBlur = useCallback(
        async (index) => {
            // Check validity for value
            const value = values[index];
            const checkValidatorsResult = await checkValidators(value, validators);
            setErrors((prevErrors) => {
                const copyPrevErrors = prevErrors.slice();
                copyPrevErrors[index] = checkValidatorsResult;
                return copyPrevErrors;
            });
        },
        [values, validators]
    );

    const hasError = useCallback(async () => {
        const newErrors: ValidatorResult[] = [];

        for (let i = 0; i < errors.length; i++) {
            const value = values[i];
            const err = await checkValidators(value, validators);
            newErrors.push(err);
        }

        setErrors(newErrors);
        return newErrors.some((error) => !!error);
    }, [values, validators]);

    const reset = () => {
        setValues(initialValue);
    };

    return {
        id,
        value: values,
        error: error,
        errors: errors,
        reset,
        hasError,
        handleChange,
        handleBlur,
    };
};

export default useTextArrayFormField;
