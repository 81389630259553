import repository from 'dy-frontend-http-repository/lib/repository';
import { UserNoteListItemResource } from 'dy-frontend-http-repository/lib/modules/UserNote/resources';
import { UserNoteRef } from 'dy-frontend-http-repository/lib/modules/UserNote/refs';
import {
    UserNoteFilterInput,
    CreateUserNoteInput,
    UpdateUserNoteInformationInput,
} from 'dy-frontend-http-repository/lib/modules/UserNote/inputs';
import { CursorPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import domain from './domain';

// Fetch client notes
export const fetchClientNotes = domain.createEffect<
    { pagination: CursorPaginationInput; filter: UserNoteFilterInput },
    CollectionResource<UserNoteListItemResource, {}>
>({
    name: 'fetchClientNotes',
    handler: async ({ pagination, filter }) => {
        return await repository.userNote().get(pagination, filter);
    },
});

// Create client note
export const createClientNote = domain.createEffect<CreateUserNoteInput, UserNoteRef>({
    name: 'createClientNote',
    handler: async (input) => {
        return await repository.userNote().create(input);
    },
});

// Remove client note
export const removeClientNote = domain.createEffect<ID, UserNoteRef>({
    name: 'removeClientNote',
    handler: async (userNoteId) => {
        return await repository.userNote().archive(userNoteId);
    },
});

// Update client note
export const updateClientNote = domain.createEffect<{ id: ID; input: UpdateUserNoteInformationInput }, UserNoteRef>({
    name: 'updateClientNote',
    handler: async ({ id, input }) => {
        return await repository.userNote().update(id, input);
    },
});
