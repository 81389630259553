import styled, { css } from 'styled-components';
import { ImageProps } from './';

export const StyledImage = styled.img<{
    $objectFit: ImageProps['objectFit'];
}>`
    width: 100%;
    height: 100%;
    border-radius: inherit;

    ${({ $objectFit }) =>
        $objectFit &&
        css`
            object-fit: ${$objectFit};
        `}
`;
