import CommonInformationBlock from '@app/components/CommonInformationBlock';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import OccupancyRate from '@app/containers/components/OccupancyRate';
import { Card, Tooltip } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { $teamOccupancy } from '../../store/states';

export type Props = HTMLAttributes<HTMLDivElement>;

const TeamOccupancyInformation: React.FC<Props> = (props) => {
    const teamOccupancy = useStore($teamOccupancy);

    if (!teamOccupancy || teamOccupancy.items.length === 0) {
        return null;
    }

    const occupancy = teamOccupancy.items[0];

    if (!occupancy) {
        return occupancy;
    }

    return (
        <Card {...props}>
            <Heading className='mb-1' type="h4">Team occupancy</Heading>

            <Tooltip
                fill
                content={
                    <div>
                        <DevText>Total complexity: {occupancy.total_task_complexity}</DevText>
                        <DevText>Average complexity: {occupancy.average_task_complexity}</DevText>
                    </div>
                }
            >
                <OccupancyRate interactive score={occupancy.score} />
            </Tooltip>
        </Card>
    );
};

export default TeamOccupancyInformation;
