import domain from './domain';

// Reset payment account
export const resetClientPaymentAccount = domain.createEvent('resetClientPaymentAccount');

// Reset client
export const resetClient = domain.createEvent('resetClient');

// Reset computed licence
export const resetComputedLicence = domain.createEvent('resetComputedLicence');

// Reset quota usage
export const resetQuotaUsage = domain.createEvent('resetQuotaUsage');
