import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { useForm } from '@app/hooks';
import { Callout, Intent, Divider, Button } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';
import { createUserPasswordResetIntent } from '../../store/effects';

export interface ResetLinkFormProps {
    workspaceId: ID | null;
    email: string;
    onCancel: () => void;
    onSendPasswordResetLinkSendSuccess: () => void;
    onProcessingFlagChange: (isProcessing: boolean) => void;
}

export type Props = ResetLinkFormProps & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const ResetLinkForm: React.FC<Props> = ({
    email,
    workspaceId,
    onCancel,
    onSendPasswordResetLinkSendSuccess,
    onProcessingFlagChange,
    ...props
}) => {
    const form = useForm({
        fields: [],
        apiCall: async () => {
            onProcessingFlagChange(true);

            try {
                const a = await createUserPasswordResetIntent({
                    email: email,
                    workspace_id: workspaceId,
                });
                
                onSendPasswordResetLinkSendSuccess();
            } catch (e) {
                throw e;
            } finally {
                onProcessingFlagChange(false);
            }

            return { response: true };
        },
        onFailure: (e) => {
            // TODO: handle error;
            console.error(e);
        },
    });

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            <Callout intent={Intent.PRIMARY} className="mb-3">
                Password reset link will be sent to email: {email}
            </Callout>

            <Divider className="mb-2" />

            <Flex align="center" justify="space-between">
                <DevText muted>Proceed with password change?</DevText>
                <div>
                    <Button minimal onClick={onCancel} className="mr-1" disabled={form.isSubmitting}>
                        No, cancel
                    </Button>
                    <Button intent={Intent.PRIMARY} loading={form.isSubmitting} type="submit">
                        Yes, send link
                    </Button>
                </div>
            </Flex>
        </form>
    );
};

export default ResetLinkForm;
