import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { CoreUserListItemResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { initialPlatformCoreUserFilterData } from '../consts';
import { PlatformCoreUserFilterData } from '../types';
import domain from './domain';
import { resetCoreUsers, resetPlatformCoreUserFilterData, resetIsQueryHydrationFinished, resetPage } from './events';

// Core users
export const $coreUsers = domain
    .createStore<CollectionResource<CoreUserListItemResource, {}> | null>(null)
    .reset(resetCoreUsers);

// Page
export const $page = domain.createStore<number>(1).reset(resetPage);

// Initialization flag for hydration finished
export const $isQueryHydrationFinished = domain.createStore<boolean>(false).reset(resetIsQueryHydrationFinished);

// Platform core user filter data
export const $platformCoreUserFilterData = domain
    .createStore<PlatformCoreUserFilterData>(initialPlatformCoreUserFilterData)
    .reset(resetPlatformCoreUserFilterData);
