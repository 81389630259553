import React, { HTMLAttributes, useState } from 'react';
import moment from 'moment';
import { Button, Callout, Classes, Colors, Divider, Intent } from '@blueprintjs/core';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { checkIfSubscriptionTerminated } from '@data/functions/subscription';
import { doNotCancelSubscription, fetchSubscription } from '../../store/effects';
import { $permissions } from '@app/containers/store/states';
import { useStore } from 'effector-react';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

export interface SubscriptionCancellationBarProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionCancellationBarProps;

const SubscriptionCancellationBar: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    const [isDoNotCancelActionProcessing, setIsDoNotCancelActionProcessing] = useState(false);

    const cancellation = subscription.cancellation;

    if (!cancellation.is_canceled) {
        // Request for subscription cancellation was NOT done
        return null;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription was terminated
        return null;
    }

    // Get title
    let title;
    if (cancellation.pending_at) {
        title = `Subscription will be canceled ${moment(cancellation.pending_at).fromNow()}:
              ${moment(cancellation.pending_at).format('D MMM, YYYY - HH:mm')}`;
    } else {
        title = 'Subscription will be canceled';
    }

    const renderDoNotCancelSubscriptionButton = () => {
        const isAllowed = permissions.isRoot.subscription || permissions.has(SubscriptionPermission.AUTO_RENEW_ENABLE);
        if (!isAllowed) {
            return null;
        }

        const handleDoNotCancelSubscription = async () => {
            setIsDoNotCancelActionProcessing(true);

            try {
                await doNotCancelSubscription(subscription.id);
                await fetchSubscription(subscription.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsDoNotCancelActionProcessing(false);
            }
        };

        return (
            <ConfirmationPopover
                title="Are you sure you want to resume subscription?"
                description="When confirmed, subscription will be resumed"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleDoNotCancelSubscription}
                    >
                        Yes, resume
                    </Button>,
                ]}
            >
                <Button
                    loading={isDoNotCancelActionProcessing}
                    disabled={isDoNotCancelActionProcessing}
                    icon="unlock"
                    className="mt-2"
                    intent={Intent.WARNING}
                >
                    Do not cancel subscription
                </Button>
            </ConfirmationPopover>
        );
    };

    return (
        <div {...props}>
            <Callout icon={null} intent={Intent.WARNING}>
                <Heading className="mb-1" type="h4" color={Colors.ORANGE4}>
                    {title}
                </Heading>

                <DevText>
                    Subscription is pending cancellation. You can check cancellation details below. If you do not want
                    this subscription, please click button below
                </DevText>

                {renderDoNotCancelSubscriptionButton()}
            </Callout>

            <Divider className="mt-2" />
        </div>
    );
};

export default SubscriptionCancellationBar;
