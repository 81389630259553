import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AllocateManualLicenceInput } from 'dy-frontend-http-repository/lib/modules/Licence/inputs';
import { LicenceRef } from 'dy-frontend-http-repository/lib/modules/Licence/refs';
import domain from './domain';

// Create licence
export const createLicence = domain.createEffect<AllocateManualLicenceInput, LicenceRef>({
    name: 'createLicence',
    handler: async (input) => {
        return await repository.licence().allocateManual(input);
    },
});
