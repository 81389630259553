import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import domain from './domain';
import { resetEditingTaskMessage, resetTaskMessages } from './events';

// Task messages
export const $taskMessages = domain
    .createStore<CollectionResource<TaskMessageListItemResource, {}> | null>(null)
    .reset(resetTaskMessages);

// Task message which is being edited
export const $editingTaskMessage = domain
    .createStore<TaskMessageListItemResource | null>(null)
    .reset(resetEditingTaskMessage);
