import domain from './domain';
import { MeResource } from 'dy-frontend-http-repository/lib/modules/Me/resources';
import { WorkspaceInitDataResource } from 'dy-frontend-http-repository/lib/modules/WorkspaceInitData/resources';
import {
    resetAuthorizedUser,
    resetIsAuthorized,
    resetPageBreadcrumbs,
    resetPermissions,
    resetWorkspaceInitData,
} from './events';
import { BreadcrumbProps } from '@blueprintjs/core';
import PermissionContainer from '@app/lib/PermissionContainer';

// Authorization flag
export const $isAuthorized = domain.createStore<boolean>(false).reset(resetIsAuthorized);

// Authorized user
export const $authorizedUser = domain.createStore<MeResource | null>(null).reset(resetAuthorizedUser);

// Workspace init data
export const $workspaceInitData = domain
    .createStore<WorkspaceInitDataResource | null>(null)
    .reset(resetWorkspaceInitData);

// Permissions of the current user
export const $permissions = domain.createStore<PermissionContainer>(new PermissionContainer([])).reset(resetPermissions);

// Dev mode flag
export const $isDevModeEnabled = domain.createStore<boolean>(true);

// Page breadcrumbs
export const $pageBreadcrumbs = domain.createStore<BreadcrumbProps[] | null>(null).reset(resetPageBreadcrumbs);
