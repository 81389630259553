import { usePageTitle } from '@app/hooks';
import Flex from '@components/Flex';
import FullScreenSpinner from '@components/FullScreenSpinner';
import { fetchWorkspaceInitialDataByWorkspaceKey } from '@containers/store/effects';
import { Endpoints } from '@data/consts';
import { WorkspaceUrlUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import EmailCheckForm from './components/EmailCheckForm';
import SigninForm from './components/SigninForm';
import { AuthenticationFormStep } from './enums';
import { resetAuthenticationFormStep, resetEmailSchema } from './store/events';
import { $authenticationFormStep } from './store/states';
import { Wrapper } from './styled';

const Authentication: React.FC = () => {
    usePageTitle('Sign In');

    const authenticationFormStep = useStore($authenticationFormStep);

    const [isWorkspacePresenceChecked, setIsWorkspacePresenceChecked] = useState(false);

    useEffect(() => {
        if (window.location.origin === process.env.INTERNAL_PLATFORM_URL) {
            // User is at DotYeti internal platform

            setIsWorkspacePresenceChecked(true);
            return;
        }

        if (window.location.origin === process.env.WORKSPACE_BASE_PLATFORM_URL) {
            // User is at workspace base platform url (without key)

            window.location.replace(`${process.env.WORKSPACE_BASE_PLATFORM_URL!}${Endpoints.WORKSPACE_ROUTER}`);
            return;
        }

        fetchWorkspaceInitialDataByWorkspaceKey(
            WorkspaceUrlUtils.getWorkspaceKeyFromLocation(
                process.env.WORKSPACE_BASE_PLATFORM_WITH_WORKSPACE_KEY_URL ?? ''
            )
        )
            .then(() => {
                setIsWorkspacePresenceChecked(true);
            })
            .catch((e) => {
                // TODO: handle error

                window.location.replace(`${process.env.WORKSPACE_BASE_PLATFORM_URL!}${Endpoints.WORKSPACE_ROUTER}`);
            });
    }, []);

    useEffect(() => {
        return () => {
            resetAuthenticationFormStep();
            resetEmailSchema();
        };
    }, []);

    const renderAuthenticationForm = () => {
        switch (authenticationFormStep) {
            case AuthenticationFormStep.EMAIL_CHECK:
                // Email check form
                return <EmailCheckForm />;
            case AuthenticationFormStep.SIGN_IN:
                // Sign in form
                return <SigninForm />;
        }
    };

    if (!isWorkspacePresenceChecked) {
        return <FullScreenSpinner />;
    }

    return (
        <Wrapper>
            <Flex fullHeight fullWidth align="center" justify="center">
                {renderAuthenticationForm()}
            </Flex>
        </Wrapper>
    );
};

export default Authentication;
