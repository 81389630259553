import { botName } from '@app/data/consts';
import { ActorUserResource, UserResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';

export default class UserUtils {
    static getActorUserName = (actor: ActorUserResource | null, authorizedUserId: ID, meLabel = 'You') => {
        if (!actor) {
            // System send this message
            return botName;
        }

        if (actor.id === authorizedUserId) {
            // Actor is authorized user
            return meLabel;
        } else {
            // Actor is NOT authorized user
            return `${actor.first_name} ${actor.last_name}`;
        }
    };

    static getPayloadUserName = (payloadUser: UserResource | null, authorizedUserId: ID, meLabel = 'Yourself') => {
        if (!payloadUser) {
            // System send this message
            return 'Unknown';
        }

        if (payloadUser.id === authorizedUserId) {
            // Payload user is authorized user
            return meLabel;
        } else {
            // Payload user is NOT authorized user
            return `${payloadUser.first_name} ${payloadUser.last_name}`;
        }
    };
}
