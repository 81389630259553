import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultMinStringLength = 2;
const getDefaultStringLengthWentBeyondMinStringLengthMessage = (minStringLength: number) =>
    `String should be greater or equal to ${minStringLength}`;

const getStringMinLengthValidator: GetValidatorFunction<
    {
        minStringLength: number;
        stringLengthWentBeyondMinStringLengthValueMessageFormatter?: (minStringLength: number) => string;
    },
    string
> = (
    options = {
        minStringLength: defaultMinStringLength,
        stringLengthWentBeyondMinStringLengthValueMessageFormatter:
            getDefaultStringLengthWentBeyondMinStringLengthMessage,
    }
) => {
    const { minStringLength, stringLengthWentBeyondMinStringLengthValueMessageFormatter } = options;

    if (minStringLength <= 0) {
        throw new Error(`Validator getStringMinLengthValidator expects positive value, but got ${minStringLength}`);
    }

    return async (value) => {
        if (value.length >= minStringLength) {
            // Passed validation
            return null;
        } else {
            // Did NOT pass validation
            if (stringLengthWentBeyondMinStringLengthValueMessageFormatter) {
                return stringLengthWentBeyondMinStringLengthValueMessageFormatter(minStringLength);
            } else {
                return getDefaultStringLengthWentBeyondMinStringLengthMessage(minStringLength);
            }
        }
    };
};

export default getStringMinLengthValidator;
