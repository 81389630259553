import { InvoiceState } from 'dy-frontend-http-repository/lib/data/enums';

// TODO: adjust label texts with backend developer
const invoiceStateDescriptionInformation: {
    [state in InvoiceState]: string;
} = {
    [InvoiceState.DRAFT]: "Invoice isn't ready to use. Initial state of any invoice",
    [InvoiceState.OPEN]: 'Finalized and awaiting payment',
    [InvoiceState.PAID]: 'Invoice is paid',
    [InvoiceState.UNCOLLECTIBLE]: 'Custom is unlikely to pay the invoice or there is incomplete payment',
    [InvoiceState.VOID]: 'Invoice is canceled',
};

export default invoiceStateDescriptionInformation;
