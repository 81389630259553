import Table, { Props as TableProps } from '@app/components/Table';
import TableCell from '@app/components/TableCell';
import DevText from '@app/components/Text';
import React from 'react';
import { taskMessageTemplateVariablesInformation } from './consts';

export type Props = TableProps;

const TaskMessageTemplateVariablesTable: React.FC<Props> = ({ ...props }) => {
    const renderTableRow = (key: string, description: string) => {
        return (
            <tr key={key}>
                <TableCell>
                    <DevText>{key}</DevText>
                </TableCell>
                <TableCell>
                    <DevText muted>{description}</DevText>
                </TableCell>
            </tr>
        );
    };

    return (
        <Table striped {...props}>
            <thead>
                <th>Name</th>
                <th>Description</th>
            </thead>
            <tbody>
                {taskMessageTemplateVariablesInformation.map((info) => renderTableRow(info.key, info.description))}
            </tbody>
        </Table>
    );
};

export default TaskMessageTemplateVariablesTable;
