import React from 'react';
import { Outlet } from 'react-router-dom';
import { Wrapper, Content } from './styled';
import Navbar from './components/Navbar';
import Box from '@components/Box';
import EnvironmentInformation from './components/EnvironmentInformation';
import { Environment } from '@app/data/enums';
import NextMenu from '@containers/components/Layout/components/NextMenu';
import Notifications from '../Notifications';

const Layout: React.FC = () => {
    return (
        <Box style={{ display: 'flex', flexDirection: 'column' }} width="100%">
            <EnvironmentInformation environment={(process.env.ENVIRONMENT as Environment) ?? null} />

            <Wrapper>
                <NextMenu />

                <Content className="main-content custom-thin-scroll">
                    <Navbar className="mb-2" />
                    <Outlet />
                </Content>

                <Notifications />
            </Wrapper>
        </Box>
    );
};

export default Layout;
