import { useState, useCallback } from 'react';
import { checkValidators } from '../functions';
import { DefaultFormFieldProps, DefaultFormFieldReturnValue, ValidatorResult } from '../types';

export interface UseColorPickerFormFieldReturnValue {
    handleChange: (color: string) => void;
    handleRemove: () => void;
}

export type ReturnValue<Value = string> = DefaultFormFieldReturnValue<Value> & UseColorPickerFormFieldReturnValue;
export type Props<Value = string> = DefaultFormFieldProps<Value>;

function useColorPickerFormField({ id, initialValue, validators }: Props): ReturnValue {
    const [color, setColor] = useState(initialValue);
    const [error, setError] = useState<ValidatorResult>(null);

    const handleChange = useCallback(
        async (color: string) => {
            // Set new color
            setColor(color);

            // Check validity for a new value
            setError(await checkValidators(color, validators));
        },
        [validators]
    );

    const handleRemove = useCallback(async () => handleChange(''), [validators, handleChange]);

    const hasError = useCallback(async () => {
        // Check validity for a field
        const err = await checkValidators(color, validators);

        // Set validation result
        setError(err);

        return !!err;
    }, [color, validators]);

    const reset = () => {
        setColor(initialValue);
    };

    return {
        id,
        value: color,
        error,
        reset,
        hasError,
        handleChange,
        handleRemove,
    };
}

export default useColorPickerFormField;
