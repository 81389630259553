import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { CoreUserFilterInput } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';
import { PlatformCoreUserFilterType } from '../../enums';
import { PlatformCoreUserFilterData, CoreUserFilterQueryParameters } from '../../types';
import { initialPlatformCoreUserFilterData, platformCoreUserFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';

export default class PlatformCoreUserFilter {
    static convertHydratedQueryParametersToPlatformCoreUserFilterData(
        hydratedQueryParameters: HydratedQueryParameters
    ): PlatformCoreUserFilterData {
        // Create empty platform core user filter data object
        const platformCoreUserFilterData: PlatformCoreUserFilterData = { ...initialPlatformCoreUserFilterData };

        // Set query
        if (hydratedQueryParameters.query) {
            platformCoreUserFilterData.query = hydratedQueryParameters.query;
        }

        /* Static filters */
        if (hydratedQueryParameters.isShutdownCoreUsersVisible !== undefined) {
            // Set shutdown core users visible flag
            platformCoreUserFilterData.isShutdownCoreUsersVisible =
                hydratedQueryParameters.isShutdownCoreUsersVisible === 'true' ? true : false;
        }

        /* Dynamic filters */
        if (hydratedQueryParameters.team) {
            // Set publish types
            platformCoreUserFilterData.teams = hydratedQueryParameters.team;
        }

        return platformCoreUserFilterData;
    }

    static convertPlatformCoreUserFilterDataToCoreUserFilterInput(
        platformCoreUserFilterData: PlatformCoreUserFilterData
    ): CoreUserFilterInput {
        const coreUserFilterInput: CoreUserFilterInput = {};

        if (platformCoreUserFilterData.query) {
            // Set query
            coreUserFilterInput.query = platformCoreUserFilterData.query;
        }

        /* Static filters */

        // Set shutdown core users visibility flag
        if (platformCoreUserFilterData.isShutdownCoreUsersVisible !== undefined) {
            coreUserFilterInput.is_shutdown = platformCoreUserFilterData.isShutdownCoreUsersVisible ? '1' : '0';
        }

        /* Dynamic filters */
        if (platformCoreUserFilterData.teams.length > 0) {
            // Set teams
            coreUserFilterInput.team_id = platformCoreUserFilterData.teams.map((team) => team.id);
        }

        return coreUserFilterInput;
    }

    static convertCoreUserFilterInputToCoreUserFilterQueryParameters = (
        page: number,
        coreUserFilterInput: CoreUserFilterInput
    ): CoreUserFilterQueryParameters => {
        const coreUserFilterQueryParameters: CoreUserFilterQueryParameters = {
            query: '',
            page: '1',
            teamIds: [],
        };

        // Set page
        coreUserFilterQueryParameters.page = `${page}`;

        // Set shutdown core users visibility flag
        if (coreUserFilterInput.is_shutdown !== undefined) {
            coreUserFilterQueryParameters.isShutdownCoreUsersVisible =
                coreUserFilterInput.is_shutdown === '1' ? 'true' : 'false';
        }

        // Set team IDs
        if (coreUserFilterInput.team_id && coreUserFilterInput.team_id.length > 0) {
            coreUserFilterQueryParameters.teamIds = coreUserFilterInput.team_id;
        }

        return coreUserFilterQueryParameters;
    };

    static convertCoreUserFilterQueryParametersToHydrateQueryInput = (
        coreUserFilterQueryParameters: Partial<CoreUserFilterQueryParameters>
    ) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        // Set core user team ids
        if (coreUserFilterQueryParameters.teamIds && coreUserFilterQueryParameters.teamIds.length > 0) {
            hydrateQueryInput.team_id = coreUserFilterQueryParameters.teamIds;
        }

        return hydrateQueryInput;
    };

    static getAppliedPlatformFilters(
        platformCoreUserFilterData: PlatformCoreUserFilterData
    ): PlatformCoreUserFilterType[] {
        const appliedPlatformFilters: PlatformCoreUserFilterType[] = [];

        const platformCoreUserFilterTypeValues = Object.values(PlatformCoreUserFilterType);

        for (let i = 0; i < platformCoreUserFilterTypeValues.length; i++) {
            const platformCoreUserFilterType = platformCoreUserFilterTypeValues[i];

            if (platformCoreUserFiltersInformation[platformCoreUserFilterType].isPinned) {
                // Platform core user filter is pinned by default
                appliedPlatformFilters.push(platformCoreUserFilterType);
                continue;
            }

            switch (platformCoreUserFilterType) {
                /* Static */
                case PlatformCoreUserFilterType.ARCHIVED:
                    if (platformCoreUserFilterData.isShutdownCoreUsersVisible !== undefined) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.ARCHIVED);
                    }
                    break;

                /* Dynamic */
                case PlatformCoreUserFilterType.TEAMS:
                    if (platformCoreUserFilterData.teams.length > 0) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.TEAMS);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
