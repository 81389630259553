import React, { HTMLAttributes } from 'react';
import Flex from '@components/Flex';
import { Spinner } from '@blueprintjs/core';

type Props = HTMLAttributes<HTMLDivElement>;

const FullScreenSpinner: React.FC<Props> = (props) => {
    return (
        <Flex flexGrow={1} align="center" justify="center" {...props}>
            <Spinner size={85} />
        </Flex>
    );
};

export default FullScreenSpinner;
