import React from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import { TaskCategoryGroupResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { Button, Card, Colors } from '@blueprintjs/core';
import DevText from '@components/Text';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { openModal } from '@modals/store/events';
import SetPlanTaskCategoryGroupListModal, {
    SetPlanTaskCategoryGroupListModalProps,
} from '@pages/Plan/modals/SetPlanTaskCategoryGroupListModal';
import Circle from '@components/Circle';
import { $permissions } from '@containers/store/states';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';

// TODO: consider moving to container for reusing on ClientLicences page
// Section: List of task category group attachments
const TaskCategoryGroupList: React.FC = () => {
    const permissions = useStore($permissions);

    const plan = useStore($plan);

    if (!plan) {
        return null;
    }

    const isRootAccess = permissions.has(PlanPermission.ROOT);

    // Renders single task category group attachment item
    const renderItem = (item: TaskCategoryGroupResource) => {
        return (
            <Card className="mb-1">
                <Flex>
                    <Circle color={item.color !== '' ? item.color : Colors.GRAY2} className="mr-1" />
                    <DevText>{item.title}</DevText>
                </Flex>
            </Card>
        );
    };

    // Renders current task category group attachment item list
    const renderList = (list: TaskCategoryGroupResource[]) => {
        if (!list.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No request category groups added...</DevText>
                </div>
            );
        }

        return list.map((i) => (
            <div key={`plan-task-category-group-${i.id}`}>
                {renderItem(i)}
            </div>
        ));
    };

    // Renders button, invoking attachment list update flow
    const renderUpdateAction = () => {
        const isAllowed = isRootAccess || permissions.has(PlanPermission.ATTACHMENT_MANAGE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                onClick={() => openModal<SetPlanTaskCategoryGroupListModalProps>({
                    ModalComponent: SetPlanTaskCategoryGroupListModal,
                    data: {
                        planId: plan.id,
                        initialAttachmentList: plan.task_category_groups,
                    },
                })}
            >Update</Button>
        );
    };

    return (
        <div>
            <Flex justify="space-between">
                <Heading type="h4">Request category groups</Heading>
                {renderUpdateAction()}
            </Flex>
            <DevText muted className="mb-1">Request category groups, covered by this plan</DevText>
            {renderList(plan.task_category_groups)}
        </div>
    );
};

export default TaskCategoryGroupList;
