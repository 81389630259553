import { repository } from 'dy-frontend-http-repository/lib/modules';
import domain from './domain';
import { PaymentAccountResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { PaymentMethodListItemResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { SubscriptionListItemResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { TaxIdResource } from 'dy-frontend-http-repository/lib/modules/TaxId/resources';
import { CreateTaxIdInput } from 'dy-frontend-http-repository/lib/modules/TaxId/inputs';
import { TaxIdRef } from 'dy-frontend-http-repository/lib/modules/TaxId/refs';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { DisableSubscriptionAutoRenewInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { LicenceRef } from 'dy-frontend-http-repository/lib/modules/Licence/refs';

// Fetch workspace payment account
export const fetchWorkspacePaymentAccount = domain.createEffect<ID, PaymentAccountResource>({
    name: 'fetchWorkspacePaymentAccount',
    handler: async (workspaceId) => {
        return await repository.paymentAccount().getPaymentAccountAssociatedWithWorkspace(workspaceId);
    },
});

// Fetch workspace payment methods
export const fetchWorkspacePaymentMethods = domain.createEffect<
    ID,
    CollectionResource<PaymentMethodListItemResource, {}>
>({
    name: 'fetchWorkspacePaymentMethods',
    handler: async (paymentAccountId) => {
        return await repository.paymentMethod().get(paymentAccountId);
    },
});

// Fetch workspace subscriptions
export const fetchWorkspaceSubscriptions = domain.createEffect<
    ID,
    CollectionResource<SubscriptionListItemResource, {}>
>({
    name: 'fetchWorkspaceSubscriptions',
    handler: async (paymentAccountId) => {
        return await repository.subscription().get({ payment_account_id: paymentAccountId });
    },
});

// Syncs subscription licences
export const syncWorkspaceSubscriptionLicences = domain.createEffect<ID, LicenceRef>({
    name: 'syncWorkspaceSubscriptionLicences',
    handler: async (subscriptionId) => {
        return await repository.licence().sync({ subscription_id: subscriptionId });
    },
});

// Remove current subscription schedule
export const removeWorkspaceSubscriptionSchedule = domain.createEffect<ID, SubscriptionRef>({
    name: 'removeWorkspaceSubscriptionSchedule',
    handler: async (subscriptionId) => {
        return await repository.subscription().cancelSchedule(subscriptionId);
    },
});

// Disable auto-renew
export const disableWorkspaceSubscriptionAutoRenew = domain.createEffect<
    { subscriptionId: ID; input: DisableSubscriptionAutoRenewInput },
    SubscriptionRef
>({
    name: 'disableWorkspaceSubscriptionAutoRenew',
    handler: async ({ subscriptionId, input }) => {
        return await repository.subscription().disableAutoRenew(subscriptionId, input);
    },
});

// Enable auto-renew
export const enableWorkspaceSubscriptionAutoRenew = domain.createEffect<ID, SubscriptionRef>({
    name: 'enableWorkspaceSubscriptionAutoRenew',
    handler: async (id) => {
        return await repository.subscription().enableAutoRenew(id);
    },
});

// Fetch workspace Tax IDs
export const fetchWorkspaceTaxIds = domain.createEffect<ID, CollectionResource<TaxIdResource, {}>>({
    name: 'fetchWorkspaceTaxIds',
    handler: async (paymentAccountId) => {
        return await repository.taxId().get({ payment_account_id: paymentAccountId });
    },
});

// Create TaxId
export const createWorkspaceTaxId = domain.createEffect<CreateTaxIdInput, TaxIdRef>({
    name: 'createWorkspaceTaxId',
    handler: async (input) => {
        return await repository.taxId().create(input);
    },
});

// Remove TaxID
export const removeWorkspaceTaxId = domain.createEffect<ID, TaxIdRef>({
    name: 'removeWorkspaceTaxId',
    handler: async (id) => {
        return await repository.taxId().remove(id);
    },
});
