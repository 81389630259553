import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import {
    UpdateSubscriptionItemInput,
} from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import { SubscriptionRef } from 'dy-frontend-http-repository/lib/modules/Subscription/refs';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';

// Get list of subscription plans
export const fetchSubscriptionPlans = domain.createEffect<PlanType, CollectionResource<PlanResource, {}>>({
    name: 'fetchSubscriptionPlans',
    handler: async (planType) => {
        return await repository.subscriptionPlan().get(planType);
    },
});

// Update subscription single item
export const updateSubscriptionSingleItem = domain.createEffect<
    { subscriptionId: ID; oldPriceId: ID; input: UpdateSubscriptionItemInput },
    SubscriptionRef
>({
    name: 'updateSubscriptionSingleItem',
    handler: async ({ subscriptionId, oldPriceId, input }) => {
        return await repository.subscription().updateSubscriptionItemPrice(subscriptionId, oldPriceId, input);
    },
});
