import {
    $clientComputedComputedLicence,
    $clientCreditWallet,
    $clientQuotaUsage,
    $step,
    $taskCategory,
} from './states';
import { changeStep } from './events';
import {
    fetchClientComputedLicence,
    fetchClientCreditWallet,
    fetchClientQuotaUsage,
    fetchTaskCategory,
} from './effects';
import { ComputedLicence, QuotaUsageContainer } from 'dy-frontend-shared/lib/data/valueObjects';


// Step
$step.on(changeStep, (_, payload) => payload);

// Task category
$taskCategory.on(fetchTaskCategory.doneData, (_, data) => data);

// Client computed licence
$clientComputedComputedLicence.on(
    fetchClientComputedLicence.doneData,
    (_, data) => ComputedLicence.createFromRepositoryResource(data),
);

// Client quota usage
$clientQuotaUsage.on(
    fetchClientQuotaUsage.doneData, (current, data) => {
        // Build next container
        const next = QuotaUsageContainer.createFromRepositoryResourceList(data.items);

        // Merge if already present
        return current !== null ? current.merge(next) : next;
    },
);

// // Client payment methods
// $clientPaymentMethods.on(fetchClientPaymentMethods.doneData, (_, data) => data);

// // Client payment account
// $clientPaymentAccount.on(fetchClientPaymentAccount.doneData, (_, data) => data);

// Client payment account
$clientCreditWallet.on(fetchClientCreditWallet.doneData, (_, data) => data);
