import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Callout, Card, Colors, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import DevText from '@components/Text';
import { Endpoints } from '@data/consts';
import { ModalProps } from '@modals/types';
import { createClientTask } from '@pages/Client/pages/ClientTasks/store/effects';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTaskCategories } from './store/effects';
import { resetTaskCategories } from './store/events';
import { $taskCategories } from './store/states';

export interface CreateTaskModalProps {
    userId: ID;
}
 
type Props = ModalProps<CreateTaskModalProps>;

const taskTitleValidators = [getStringMaxLengthValidator({ maxStringLength: 70 })];
const taskSelectedTaskCategoryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringRequiredValidator(),
];

const CreateTaskModal: React.FC<Props> = ({ closeModal, data }) => {
    const navigate = useNavigate();

    const taskCategories = useStore($taskCategories);

    const taskTitle = useTextFormField({
        id: 'task-title',
        validators: taskTitleValidators,
        initialValue: '',
    });

    const taskSelectedTaskCategory = useCustomSelectFormField<TaskCategoryListItemResource | null>({
        id: 'task-title',
        validators: taskSelectedTaskCategoryValidators,
        initialValue: null,
        formatValue: (item) => item?.title ?? '',
    });

    const form = useForm<TaskRef>({
        fields: [taskTitle, taskSelectedTaskCategory],
        apiCall: async () => {
            try {
                const taskRef = await createClientTask({
                    title: taskTitle.value,
                    task_category_id: taskSelectedTaskCategory.value!.id,
                    user_id: data!.userId,
                });

                return { response: taskRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: taskRef }) => {
            closeModal?.();
            navigate(Endpoints.TASK_INFORMATION.replace(':taskId', taskRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        fetchTaskCategories({
            filter: {
                is_enabled: '1',
                is_archived: '0',
            },
        }).catch((e) => {
            // TODO: handle error
        });
    }, []);

    useEffect(() => {
        return () => {
            resetTaskCategories();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderCategoryFormFieldSelect = () => {
        const renderItemColorIcon = (item: TaskCategoryListItemResource) => {
            let color = Colors.GRAY2;
            const taskCategoryGroup = item.groups[0];

            if (taskCategoryGroup) {
                // TODO: what if there are 2 groups, what color to show
                color = taskCategoryGroup.color;
            }

            return <Circle color={color} />;
        };

        const renderItem = (item: TaskCategoryListItemResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = !!(
                taskSelectedTaskCategory.value && taskSelectedTaskCategory.value.id === item.id
            );

            let menuItemLabel = '';
            if (item.price) {
                menuItemLabel = PriceUtils.formatPrice({ price: item.price.unit_amount, shouldDisplayCents: true });
            }

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.id}
                    text={item.title}
                    label={menuItemLabel}
                    icon={renderItemColorIcon(item)}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: TaskCategoryListItemResource) => {
            taskSelectedTaskCategory.handleChange(item);
        };

        return (
            <FormGroup
                label="Request category"
                intent={!!taskSelectedTaskCategory.error ? Intent.DANGER : Intent.NONE}
                helperText={taskSelectedTaskCategory.error}
            >
                <Select<TaskCategoryListItemResource>
                    disabled={!taskCategories}
                    items={taskCategories?.items ?? []}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a category',
                        icon: taskSelectedTaskCategory.value && renderItemColorIcon(taskSelectedTaskCategory.value),
                        text: taskSelectedTaskCategory.value && taskSelectedTaskCategory.value.title,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create New Request</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <Callout className="mb-2" icon="warning-sign" intent={Intent.WARNING}>
                    <DevText>This will first create a draft request, which you can publish afterwards.</DevText>
                </Callout>

                <form onSubmit={form.handleFormSubmit}>
                    {/* Title */}
                    <InputFormField
                        field={taskTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter request title' }}
                    />

                    {/* Task category */}
                    {renderCategoryFormFieldSelect()}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateTaskModal;
