import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { fetchTaskClientFeedback } from '../../store/effects';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '../../consts';
import { PlatformTaskClientFeedbackFilterType } from '../../enums';
import {
    resetPage,
    resetPlatformTaskClientFeedbackData,
    setPlatformTaskClientFeedbackFilterData,
} from '../../store/events';
import { $isQueryHydrationFinished, $platformTaskClientFeedbackData } from '../../store/states';
import ClientUsersPlatformFilter from './components/ClientUsersPlatformFilter';
import TaskParticipantsPlatformFilter from './components/TaskParticipantsPlatformFilter';
import TaskStatesPlatformFilter from './components/NegativeScorePlatformFilter';
import { AddFilterPopoverContent } from './styled';
import DevText from '@components/Text';
import { PlatformTaskClientFeedbackFilter as PlatformTaskClientFeedbackFilterValueObject } from '../../valueObjects';
import TeamsPlatformFilter from './components/TeamsPlatformFilter';
import NegativeScorePlatformFilter from './components/NegativeScorePlatformFilter';

const platformTaskClientFeedbackFilterTypes = Object.values(PlatformTaskClientFeedbackFilterType);

export type Props = HTMLAttributes<HTMLDivElement>;

const PlatformTaskFilter: React.FC<Props> = (props) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformTaskClientFeedbackData = useStore($platformTaskClientFeedbackData);

    const [query, setQuery] = useState('');
    const [isAddFilterPopoverOpened, setIsAddFilterPopoverOpened] = useState(false);
    const [platformFilters, setPlatformFilters] = useState<PlatformTaskClientFeedbackFilterType[]>([]);
    const [isLastAddedShouldBeOpenedMode, setIsLastAddedShouldBeOpenedMode] = useState(false);

    const isAllFiltersAdded = platformTaskClientFeedbackFilterTypes.length === platformFilters.length;

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setPlatformFilters(
                PlatformTaskClientFeedbackFilterValueObject.getAppliedPlatformFilters(platformTaskClientFeedbackData)
            );
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    const handleAddFilter = (type: PlatformTaskClientFeedbackFilterType) => {
        if (!platformFilters.includes(type)) {
            setIsAddFilterPopoverOpened(false);
            setPlatformFilters((filters) => [...filters, type]);
        }
    };

    const renderFilterOfType = (type: PlatformTaskClientFeedbackFilterType, defaultIsOpen: boolean) => {
        const commonPlatformFilterProps = {
            defaultIsOpen,
            platformTaskClientFeedbackData: platformTaskClientFeedbackData,
            onRemove: () =>
                setPlatformFilters((platformFilters) =>
                    platformFilters.filter((platformFilterType) => platformFilterType !== type)
                ),
        };

        switch (type) {
            case PlatformTaskClientFeedbackFilterType.CLIENT_USERS:
                return <ClientUsersPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskClientFeedbackFilterType.TASK_PARTICIPANTS:
                return <TaskParticipantsPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskClientFeedbackFilterType.TEAMS:
                return <TeamsPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE:
                return <NegativeScorePlatformFilter {...commonPlatformFilterProps} />;

            default:
                return null;
        }
    };

    const renderPlatformFilters = () => {
        return platformFilters.map((type, index) => (
            <div key={type}>
                {renderFilterOfType(type, isLastAddedShouldBeOpenedMode && index === platformFilters.length - 1)}
            </div>
        ));
    };

    const renderAddPlatformFilterButton = () => {
        if (isAllFiltersAdded) {
            // No need to show "Add filter" button if all filters are already added
            return null;
        }

        const renderAddFilterPopoverContent = () => {
            const handleClickMenuItem = (platformTaskFilterType: PlatformTaskClientFeedbackFilterType) => {
                if (isLastAddedShouldBeOpenedMode === false) {
                    setIsLastAddedShouldBeOpenedMode(true);
                }

                handleAddFilter(platformTaskFilterType);
            };

            return (
                <AddFilterPopoverContent>
                    <DevText muted>Select filter</DevText>

                    <Menu>
                        {Object.entries(platformFiltersInformation).map(([key, { icon, title }]) => {
                            const platformTaskFilterType = key as PlatformTaskClientFeedbackFilterType;

                            if (platformFilters.includes(platformTaskFilterType)) {
                                // Platform filter is already added, allowing to add it again doesn't make sense
                                return null;
                            }

                            return (
                                <MenuItem
                                    className="mt-1"
                                    key={key}
                                    icon={icon}
                                    text={title}
                                    onClick={() => handleClickMenuItem(platformTaskFilterType)}
                                />
                            );
                        })}
                    </Menu>
                </AddFilterPopoverContent>
            );
        };

        return (
            <div>
                <Popover
                    isOpen={isAddFilterPopoverOpened}
                    disabled={!isQueryHydrationFinished}
                    content={renderAddFilterPopoverContent()}
                    onClose={() => setIsAddFilterPopoverOpened(false)}
                >
                    <Button
                        className="mr-1"
                        icon="filter-list"
                        minimal
                        onClick={() => setIsAddFilterPopoverOpened(!isAddFilterPopoverOpened)}
                        loading={!isQueryHydrationFinished}
                    >
                        Add filter
                    </Button>
                </Popover>
            </div>
        );
    };

    const renderResetButton = () => {
        if (
            JSON.stringify(platformTaskClientFeedbackData).length ===
            JSON.stringify(initialPlatformTaskFilterData).length
        ) {
            // Platform task filter data same as whenever it was initialized
            return null;
        }

        const handleResetButton = () => {
            // Reset page to 1
            resetPage();

            // Reset platform task filter data to initial platform task filter data
            resetPlatformTaskClientFeedbackData();

            // Create array of pinned platform filters
            const initialPlatformFilters: PlatformTaskClientFeedbackFilterType[] = [];
            for (let i = 0; i < platformTaskClientFeedbackFilterTypes.length; i++) {
                // Get platform task filter information for platform task filter type
                const platformTaskFilterInformation =
                    platformFiltersInformation[platformTaskClientFeedbackFilterTypes[i]];

                if (!platformTaskFilterInformation.isPinned) {
                    // We only push pinned platform filters
                    continue;
                }

                initialPlatformFilters.push(platformTaskClientFeedbackFilterTypes[i]);
            }

            setPlatformFilters(initialPlatformFilters);
            setQuery('');
        };

        return (
            <Button minimal icon="trash" intent={Intent.DANGER} onClick={handleResetButton}>
                Reset
            </Button>
        );
    };

    return (
        <div {...props}>
            <Flex gap="8px" flexWrap="wrap">
                {renderPlatformFilters()}
                {renderAddPlatformFilterButton()}
                {renderResetButton()}
            </Flex>
        </div>
    );
};

export default PlatformTaskFilter;
