/*
  Filter accepts:
  - query: string;                                                      // Search input (string)
  - state: TaskState[];                                                 // Static filter (Task state)
  - participant_user_id: ID[];                                          // Dynamic filter (FilterTaskParticipationResource[])
  - owner_user_id: ID[];                                                // Dynamic filter (ClientUserResource[])
  - workspace_id: ID[];                                                 // Dynamic filter (WorkspaceResource[])
  - task_category_id: ID[];                                             // Dynamic filter (TaskCategoryResource[])
  - publish_type: TaskPublishType[];                                    // Static filter (TaskPublishType[])
  - queue: TaskQueue[];                                                 // Static filter (TaskQueue[])
  - deadline_at: {gte?: string; lte?: string;};                         // ???: do we combine it to Date filter ???
  - archived_at: "1" | "0";                                             // ???: do we combine it to Date filter ???
*/

enum PlatformTaskFilterType {
    TASK_STATES = 'TASK_STATES',
    CLIENT_USERS = 'CLIENT_USERS',
    TASK_CATEGORIES = 'TASK_CATEGORIES',
    TASK_PUBLISH_TYPES = 'TASK_PUBLISH_TYPES',
    TASK_QUEUES = 'TASK_QUEUES',
    // WORKSPACES = 'WORKSPACES',
    TASK_PARTICIPANTS = 'TASK_PARTICIPANTS',
}

export default PlatformTaskFilterType;
