import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentMethod/resources';
import domain from './domain';
import { resetPaymentMethods } from './events';


// Payment account
export const $paymentMethods = domain
    .createStore<CollectionResource<PaymentMethodResource, {}> | null>(null)
    .reset(resetPaymentMethods);
