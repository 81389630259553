import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AddUserNotificationEmailInput } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import { UserNotificationEmailRef } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/refs';
import domain from './domain';

// Create  notification email
export const createNotificationEmail = domain.createEffect<AddUserNotificationEmailInput, UserNotificationEmailRef>({
    name: 'createNotificationEmail',
    handler: async (input) => {
        return await repository.userNotificationEmail().add(input);
    },
});
