import AvatarUploaderNEW from '@app/components/AvatarUploaderNEW';
import ImageCropperModal, { ImageCropperModalProps } from '@app/containers/modals/ImageCropperModal';
import { openModal } from '@app/containers/modals/store/events';
import { $permissions } from '@app/containers/store/states';
import { getFileAllowedTypesValidator, getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import { Card } from '@blueprintjs/core';
import { imageHashPreview } from '@data/consts';
import { taskCategoryApi } from '@pages/TaskCategory/store/apis';
import {
    removeTaskCategoryIcon,
    removeTaskCategoryImage,
    uploadAndSetTaskCategoryIcon,
    uploadAndSetTaskCategoryImage,
} from '@pages/TaskCategory/store/effects';
import { $taskCategory } from '@pages/TaskCategory/store/states';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { TaskCategoryPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const taskCategoryImageValidators = [
    getFileAllowedTypesValidator({ allowedTypes: ['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'] }),
    getFileMaxSizeValidator({ maxSize: 25000000 }),
];

const taskCategoryIconValidators = [
    getFileAllowedTypesValidator({ allowedTypes: ['jpg', 'png', 'jpeg', 'svg', 'webp', 'gif'] }),
    getFileMaxSizeValidator({ maxSize: 25000000 }),
];

const Images: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const taskCategory = useStore($taskCategory);

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [isRemovingImage, setIsRemovingImage] = useState(false);

    const [isUploadingIcon, setIsUploadingIcon] = useState(false);
    const [isRemovingIcon, setIsRemovingIcon] = useState(false);

    if (!taskCategory) {
        return null;
    }

    const handleRemoveImage = async () => {
        setIsRemovingImage(true);

        try {
            const taskCategoryRef = await removeTaskCategoryImage(taskCategory.id);
            taskCategoryApi.removeImage();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemovingImage(false);
        }
    };

    const handleRemoveIcon = async () => {
        setIsRemovingIcon(true);

        try {
            const taskCategoryRef = await removeTaskCategoryIcon(taskCategory.id);
            taskCategoryApi.removeIcon();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsRemovingIcon(false);
        }
    };

    const handleUploadImage = async (file: File) => {
        setIsUploadingImage(true);

        try {
            const taskCategoryImageHashRef = await uploadAndSetTaskCategoryImage({
                taskCategoryId: taskCategory.id,
                file: file,
            });
            taskCategoryApi.setImageHash(taskCategoryImageHashRef);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUploadingImage(false);
        }
    };

    const handleUploadIcon = async (file: File) => {
        setIsUploadingIcon(true);

        try {
            const taskCategoryIconHashRef = await uploadAndSetTaskCategoryIcon({
                taskCategoryId: taskCategory.id,
                file: file,
            });
            taskCategoryApi.setIconHash(taskCategoryIconHashRef);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsUploadingIcon(false);
        }
    };

    const handleSelectImageFile = (file: File) => {
        const isImage = file.type.split('/')[0] === 'image';
        if (!isImage) {
            alert('Not an image');
            return;
        }

        const isSvg = file.type === 'image/svg+xml';

        // Non-svg files can be adjusted
        if (!isSvg) {
            openModal<ImageCropperModalProps>({
                ModalComponent: ImageCropperModal,
                data: {
                    inputImageUrl: URL.createObjectURL(file),
                    aspect: 4 / 3,
                    shape: 'rect',
                    onCropComplete: handleUploadImage,
                },
            });
            return;
        }

        // Svg are uploaded directly
        handleUploadImage(file);
    };

    const handleSelectIconFile = (file: File) => {
        const isImage = file.type.split('/')[0] === 'image';
        if (!isImage) {
            alert('Not an image');
            return;
        }

        const isSvg = file.type === 'image/svg+xml';

        // Non-svg files can be adjusted
        if (!isSvg) {
            openModal<ImageCropperModalProps>({
                ModalComponent: ImageCropperModal,
                data: {
                    inputImageUrl: URL.createObjectURL(file),
                    aspect: 1,
                    shape: 'round',
                    onCropComplete: handleUploadIcon,
                },
            });
            return;
        }

        // Svg are uploaded directly
        handleUploadIcon(file);
    };

    const renderImageAvatar = () => {
        const isUpdateAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);

        // Get avatar src
        let avatarSrc: string | null = null;
        if (taskCategory.image_hash) {
            avatarSrc = imageHashPreview.taskCategoryImage(taskCategory.image_hash, ImageHashPreviewSize.MD);
        }

        return (
            <AvatarUploaderNEW
                disabled={!isUpdateAllowed}
                loading={isUploadingImage || isRemovingImage}
                width="100%"
                height="200px"
                borderRadius="inherit"
                borderBottomLeftRadius="0"
                borderBottomRightRadius="0"
                validators={taskCategoryImageValidators}
                avatarProps={{
                    alt: taskCategory.title,
                    src: avatarSrc,
                    borderRadius: 'inherit',
                }}
                fileUploadProgressProps={{
                    spinnerProps: {
                        size: 70,
                    },
                }}
                onFileSelect={handleSelectImageFile}
                onFileRemove={handleRemoveImage}
            />
        );
    };

    const renderIconAvatar = () => {
        const isUpdateAllowed = permissions.isRoot.taskCategory || permissions.has(TaskCategoryPermission.UPDATE);

        // Get avatar src
        let avatarSrc: string | null = null;
        if (taskCategory.icon_hash) {
            avatarSrc = imageHashPreview.taskCategoryIcon(taskCategory.icon_hash, ImageHashPreviewSize.SM);
        }

        return (
            <AvatarUploaderNEW
                disabled={!isUpdateAllowed}
                loading={isUploadingIcon || isRemovingIcon}
                position="absolute"
                left="calc(50% - 35px)"
                bottom="0px"
                width="70px"
                height="70px"
                borderRadius="50%"
                borderSize={3}
                validators={taskCategoryIconValidators}
                avatarProps={{
                    alt: taskCategory.title,
                    src: avatarSrc,
                    borderRadius: 'inherit',
                }}
                fileUploadProgressProps={{
                    spinnerProps: {
                        size: 70,
                    },
                }}
                onFileSelect={handleSelectIconFile}
                onFileRemove={handleRemoveIcon}
            />
        );
    };

    return (
        <div {...props}>
            <Card style={{ padding: 0, position: 'relative', height: '250px' }}>
                {renderImageAvatar()}
                {renderIconAvatar()}
            </Card>
        </div>
    );
};

export default Images;
