import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { $client } from '../../store/states';
import ClientBadge from './components/ClientBadge';
import Information from './components/Information';

export type Props = HTMLAttributes<HTMLDivElement>;

const Aside: React.FC<Props> = (props) => {
    const client = useStore($client);

    if (!client) {
        return null;
    }

    return (
        <div {...props}>
            <ClientBadge className="mb-2" />
            <Information />
        </div>
    );
};

export default Aside;
