import domain from './domain';

// Reset task
export const resetTask = domain.createEvent('resetTask');

// Reset task state transition log
export const resetTaskStateTransitionLog = domain.createEvent('resetTaskStateTransitionLog');

// Reset task pickable participant users
export const resetTaskPickableParticipantUsers = domain.createEvent('resetTaskPickableParticipantUsers');

// Reset teams
export const resetTeams = domain.createEvent('resetTeams');

// Reset task complexity
export const resetTaskComplexity = domain.createEvent('resetTaskComplexity');

// Reset task addons
export const resetTaskAddons = domain.createEvent('resetTaskAddons');

// Reset client payment account
export const resetClientPaymentAccount = domain.createEvent('resetClientPaymentAccount');
