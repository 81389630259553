import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { LicenceFilterInput } from 'dy-frontend-http-repository/lib/modules/Licence/inputs';
import { LicenceRef } from 'dy-frontend-http-repository/lib/modules/Licence/refs';
import { LicenceResource } from 'dy-frontend-http-repository/lib/modules/Licence/resources';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { VirtualLicenceCurrentListFilterInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { VirtualLicenceResource } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import domain from './domain';

// Fetch virtual licences
export const fetchVirtualLicences = domain.createEffect<
    VirtualLicenceCurrentListFilterInput,
    CollectionResource<VirtualLicenceResource, {}>
>({
    name: 'fetchVirtualLicences',
    handler: async (filter) => {
        return await repository.virtualLicence().getCurrentList(filter);
    },
});

// Fetch licences
export const fetchLicences = domain.createEffect<LicenceFilterInput, CollectionResource<LicenceResource, {}>>({
    name: 'fetchLicences',
    handler: async (filter) => {
        return await repository.licence().get(filter);
    },
});

// Fetch task categories
export const fetchTaskCategories = domain.createEffect<void, CollectionResource<TaskCategoryListItemResource, {}>>({
    name: 'fetchTaskCategories',
    handler: async () => {
        return await repository.taskCategory().get();
    },
});

// Fetch task category groups
export const fetchTaskCategoryGroups = domain.createEffect<
    void,
    CollectionResource<TaskCategoryGroupListItemResource, {}>
>({
    name: 'fetchTaskCategoryGroups',
    handler: async () => {
        return await repository.taskCategoryGroup().get();
    },
});

// Shutdown licence
export const shutdownLicence = domain.createEffect<ID, LicenceRef>({
    name: 'shutdownLicence',
    handler: async (licenceId) => {
        return await repository.licence().shutdown(licenceId);
    },
});

// Suspend virtual licence
export const suspendVirtualLicence = domain.createEffect<ID, LicenceRef>({
    name: 'suspendVirtualLicence',
    handler: async (virtualLicenceId) => {
        return await repository.virtualLicence().suspend(virtualLicenceId);
    },
});

// Resume virtual licence
export const resumeVirtualLicence = domain.createEffect<ID, LicenceRef>({
    name: 'resumeVirtualLicence',
    handler: async (virtualLicenceId) => {
        return await repository.virtualLicence().resume(virtualLicenceId);
    },
});

// End virtual licence
export const endVirtualLicence = domain.createEffect<ID, LicenceRef>({
    name: 'endVirtualLicence',
    handler: async (virtualLicenceId) => {
        return await repository.virtualLicence().end(virtualLicenceId);
    },
});
