import React from 'react';
import { useStore } from 'effector-react';
import { $plan } from '@pages/Plan/store/states';
import { Button, Card, Colors, Icon } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { PlanSubscriptionSetupMode, PlanType } from 'dy-frontend-http-repository/lib/data/enums';
import { openModal } from '@modals/store/events';
import UpdatePlanModal, { UpdatePlanModalProps } from '@pages/Plan/modals/UpdatePlanModal';
import { $permissions } from '@containers/store/states';
import { PlanPermission } from 'dy-frontend-permissions/lib/permission';

const Info: React.FC = () => {
    const permissions = useStore($permissions);

    const plan = useStore($plan);

    if (!plan) {
        return null;
    }

    const isRootAccess = permissions.has(PlanPermission.ROOT);

    const renderType = () => {
        const readable = {
            [PlanType.USER]: 'Used by clients',
            [PlanType.SEAT]: 'Assignable seat',
            [PlanType.WORKSPACE]: 'Workspace plan',
        };

        return (
            <>
                <DevText muted className="mb-small">
                    Type
                </DevText>
                <DevText>{readable[plan.type] ?? 'Unknown type'}</DevText>
            </>
        );
    };

    const renderSubscriptionSetupMode = () => {
        const readable = {
            [PlanSubscriptionSetupMode.PUBLIC]: 'Clients can setup subscription with this plan',
            [PlanSubscriptionSetupMode.INTERNAL]: 'Subscription can be setup only in the internal panel',
            [PlanSubscriptionSetupMode.DISABLED]: 'Plan not allowed to be setup in the subscription',
        };

        return (
            <>
                <DevText muted className="mb-small">
                    Subscription setup mode
                </DevText>
                <DevText>{readable[plan.subscription_setup_mode] ?? 'Unknown'}</DevText>
            </>
        );
    };

    const renderSummary = () => {
        return (
            <>
                <DevText muted className="mb-small">
                    Summary
                </DevText>
                <DevText>{plan.summary}</DevText>
            </>
        );
    };

    const renderUpdateAction = () => {
        const isAllowed = isRootAccess || permissions.has(PlanPermission.INFO_UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() => {
                    openModal<UpdatePlanModalProps>({
                        ModalComponent: UpdatePlanModal,
                        data: {
                            plan,
                        },
                    });
                }}
            />
        );
    };

    const renderFeatures = () => {
        if (plan.description_feature_list.length === 0) {
            return null;
        }

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Features
                </DevText>

                {plan.description_feature_list.map((feature, index) => (
                    <DevText running className={index === 0 ? '' : 'mt-1'} key={feature}>
                        <Icon className="mr-small" icon="tick" color={Colors.GRAY2} />
                        {feature}
                    </DevText>
                ))}
            </div>
        );
    };

    return (
        <Card>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Main Information</Heading>
                {renderUpdateAction()}
            </Flex>

            <div className="mb-2">{renderType()}</div>

            <div className="mb-2">{renderSubscriptionSetupMode()}</div>

            <div>{renderSummary()}</div>

            {renderFeatures()}
        </Card>
    );
};

export default Info;
