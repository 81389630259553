import React from 'react';
import { LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { StyledRouterLink } from './styled';

export interface RouterLinkProps {
    underline?: boolean;
    showUnderlineOnHover?: boolean;
    fontSize?: number;
    fontWeight?: number;
    lineHeight?: number;
    inline?: boolean;
    color?: string;
}

export type Props = RouterLinkProps & ReactRouterLinkProps;

const RouterLink: React.FC<Props> = ({
    underline = false,
    inline = true,
    showUnderlineOnHover = true,
    fontSize,
    fontWeight,
    lineHeight,
    color,
    ...props
}) => {
    return (
        <StyledRouterLink
            $underline={underline}
            $showUnderlineOnHover={showUnderlineOnHover}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $inline={inline}
            $color={color}
            {...props}
        />
    );
};

export default RouterLink;
