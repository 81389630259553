import PasswordLockButton from '@app/components/PasswordLockButton';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringEmailValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { Endpoints, userRoleInformation } from '@data/consts';
import { ModalProps } from '@modals/types';
import { createCoreUser } from '@pages/CoreUsers/store/effects';
import { UserRole } from 'dy-frontend-http-repository/lib/data/enums';
import { CoreUserRef } from 'dy-frontend-http-repository/lib/modules/CoreUser/refs';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $permissions } from '@containers/store/states';

type Props = ModalProps;

const coreUserRoleIdValidators = [];
const coreUserFirstNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];
const coreUserLastNameValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 2 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];
const coreUserEmailValidators = [getStringRequiredValidator(), getStringEmailValidator()];
const coreUserPasswordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const CreateClientModal: React.FC<Props> = ({ closeModal }) => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [role, setRole] = useState<UserRole>(UserRole.CORE_DESIGNER);

    const coreUserFirstName = useTextFormField({
        id: 'core-first-name',
        validators: coreUserFirstNameValidators,
        initialValue: '',
    });

    const coreUserLastName = useTextFormField({
        id: 'core-last-name',
        validators: coreUserLastNameValidators,
        initialValue: '',
    });

    const coreUserEmail = useTextFormField({
        id: 'core-email',
        validators: coreUserEmailValidators,
        initialValue: '',
    });

    const coreUserPassword = useTextFormField({
        id: 'core-password',
        validators: coreUserPasswordValidators,
        initialValue: '',
    });

    const form = useForm<CoreUserRef>({
        fields: [coreUserFirstName, coreUserLastName, coreUserEmail, coreUserPassword],
        apiCall: async () => {
            try {
                const coreUserRef = await createCoreUser({
                    first_name: coreUserFirstName.value,
                    last_name: coreUserLastName.value,
                    email: coreUserEmail.value,
                    password: coreUserPassword.value,
                    role: role,
                });

                return { response: coreUserRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: coreUserRef }) => {
            closeModal?.();
            navigate(Endpoints.CORE_USER_TASKS.replace(':coreUserId', coreUserRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const renderForm = () => {
        const renderRoleSelect = () => {
            const allowed = [
                UserRole.CORE_ADMIN,
                UserRole.CORE_OPERATIONS_MANAGER,
                UserRole.CORE_ACCOUNT_MANAGER,
                UserRole.CORE_HR,
                UserRole.CORE_PROJECT_MANAGER,
                UserRole.CORE_ART_DIRECTOR,
                UserRole.CORE_ASSISTANT_ART_DIRECTOR,
                UserRole.CORE_DESIGNER,
            ];

            return (
                <FormGroup label={'Role'}>
                    <HTMLSelect value={role} onChange={(e) => setRole(e.currentTarget.value as UserRole)}>
                        {allowed.map((r) => (
                            <option value={r}>{userRoleInformation[r].label}</option>
                        ))}
                    </HTMLSelect>
                </FormGroup>
            );
        };

        return (
            <form onSubmit={form.handleFormSubmit}>
                <Grid container>
                    <Grid lg={6}>
                        <InputFormField
                            field={coreUserFirstName}
                            formGroupProps={{ label: 'First name' }}
                            inputProps={{ placeholder: 'Enter first name' }}
                        />
                    </Grid>
                    <Grid lg={6}>
                        <InputFormField
                            field={coreUserLastName}
                            formGroupProps={{ label: 'Last name' }}
                            inputProps={{ placeholder: 'Enter last name' }}
                        />
                    </Grid>
                    <Grid lg={12}>
                        <InputFormField
                            field={coreUserEmail}
                            formGroupProps={{ label: 'Email' }}
                            inputProps={{ placeholder: 'Enter email' }}
                        />
                    </Grid>

                    <Grid lg={12}>
                        <InputFormField
                            field={coreUserPassword}
                            formGroupProps={{ label: 'Password' }}
                            inputProps={{
                                placeholder: 'Enter password',
                                type: isPasswordVisible ? 'text' : 'password',
                                rightElement: (
                                    <PasswordLockButton
                                        isPasswordVisible={isPasswordVisible}
                                        changePasswordVisibility={setIsPasswordVisible}
                                    />
                                ),
                            }}
                        />
                    </Grid>

                    <Grid lg={12}>{renderRoleSelect()}</Grid>
                </Grid>

                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Create
                    </Button>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create member account</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderForm()}
            </Card>
        </Overlay>
    );
};

export default CreateClientModal;
