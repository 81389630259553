import React, { HTMLAttributes } from 'react';

export interface GridProps {
    container?: boolean;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
}

export type Props = GridProps & HTMLAttributes<HTMLDivElement>;

const Grid: React.FC<Props> = ({ container = false, xs = 12, sm = 12, md = 12, lg = 12, className = '', ...props }) => {
    const generateClassName = () => {
        if (container) {
            if (className.length > 0) {
                return `row ${className}`;
            } else {
                return `row`;
            }
        } else {
            if (className.length > 0) {
                return `${className} col-lg-${lg} col-md-${md} col-sm-${sm} col-xs-${xs}`;
            } else {
                return `col-lg-${lg} col-md-${md} col-sm-${sm} col-xs-${xs}`;
            }
        }
    };

    return <div className={generateClassName()} {...props} />;
};

export default Grid;
