import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { TaskCategoryFeaturedBlockResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/resources';
import domain from './domain';
import { resetTaskCategories, resetTaskCategoryFeaturedBlocks } from './events';

// Task category featured blocks
export const $taskCategoryFeaturedBlocks = domain
    .createStore<CollectionResource<TaskCategoryFeaturedBlockResource, {}> | null>(null)
    .reset(resetTaskCategoryFeaturedBlocks);

    // Task categories
export const $taskCategories = domain
.createStore<CollectionResource<TaskCategoryListItemResource, {}> | null>(null)
.reset(resetTaskCategories);
