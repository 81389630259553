import ColorTilePickerFormField from '@app/components/ColorTilePickerFormField';
import { useColorPickerFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import {
    CreateTaskCategoryGroupInput,
    UpdateTaskCategoryGroupInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/inputs';
import { TaskCategoryGroupRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/refs';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import React from 'react';
import { taskCategoryGroupsApi } from '../../store/apis';
import { createTaskCategoryGroup, updateTaskCategoryGroup } from '../../store/effects';

export interface UpsertTaskCategoryGroupModalProps {
    taskCategoryGroupId: ID | null;
    title: TaskCategoryGroupListItemResource['title'];
    color: TaskCategoryGroupListItemResource['color'];
}

type Props = ModalProps<UpsertTaskCategoryGroupModalProps>;

const taskCategoryGroupTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 6 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const taskCategoryGroupColorValidators = [getStringRequiredValidator()];

const UpsertTaskCategoryGroupModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskCategoryGroupTitle = useTextFormField({
        id: 'plan-summary',
        validators: taskCategoryGroupTitleValidators,
        initialValue: data?.title ? data.title : '',
    });

    const taskCategoryGroupColor = useColorPickerFormField({
        id: 'color',
        validators: taskCategoryGroupColorValidators,
        initialValue: data?.color ? data.color : '',
    });

    const handleCreateTaskCategoryGroup = async (): Promise<TaskCategoryGroupRef> => {
        const input: CreateTaskCategoryGroupInput = {
            title: taskCategoryGroupTitle.value,
            color: taskCategoryGroupColor.value,
        };

        try {
            const taskCategoryGroupRef = await createTaskCategoryGroup(input);
            taskCategoryGroupsApi.create({ taskCategoryGroupId: taskCategoryGroupRef.id, input });
            return taskCategoryGroupRef;
        } catch (e) {
            throw e;
        }
    };

    const handleUpdateTaskCategoryGroup = async (taskCategoryGroupId: ID) => {
        const input: UpdateTaskCategoryGroupInput = {
            title: taskCategoryGroupTitle.value,
            color: taskCategoryGroupColor.value,
        };

        try {
            const taskCategoryRef = await updateTaskCategoryGroup({
                taskCategoryGroupId: taskCategoryGroupId,
                input,
            });
            taskCategoryGroupsApi.update({ taskCategoryGroupId: taskCategoryGroupId, input });
            return taskCategoryRef;
        } catch (e) {
            throw e;
        }
    };

    const form = useForm<TaskCategoryGroupRef>({
        fields: [taskCategoryGroupTitle, taskCategoryGroupColor],
        apiCall: async () => {
            try {
                let taskCategoryGroupRef: TaskCategoryGroupRef | null = null;

                if (data?.taskCategoryGroupId) {
                    // Update task category group
                    taskCategoryGroupRef = await handleUpdateTaskCategoryGroup(data?.taskCategoryGroupId);
                } else {
                    // Create new task category group
                    taskCategoryGroupRef = await handleCreateTaskCategoryGroup();
                }

                return { response: taskCategoryGroupRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const modalTitleText = data?.taskCategoryGroupId ? 'Update task category group' : 'Create task category group';
    const submitButtonText = data?.taskCategoryGroupId ? 'Update' : 'Create';

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">{modalTitleText}</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        <Grid lg={3} xs={12}>
                            {/* Color */}
                            <ColorTilePickerFormField field={taskCategoryGroupColor} formGroupProps={{ label: 'Color' }} />
                        </Grid>
                        <Grid lg={9}>
                            {/* Title */}
                            <InputFormField
                                field={taskCategoryGroupTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter category group title' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            {submitButtonText}
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertTaskCategoryGroupModal;
