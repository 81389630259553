import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { TaskDeliverablesParsedInformation } from '../../../../types';
import { taskDeliverablesTreeApi } from '../../store/apis';
import { archiveTaskDeliverablesBatch } from '../../store/effects';

const useRemoveDirectory = () => {
    const handleRemoveDirectory = async ({
        taskDeliverablesTree,
        directoryPathToRemove,
        directoryNameToRemove,
    }: {
        taskDeliverablesTree: TaskDeliverablesParsedInformation;
        directoryPathToRemove: string;
        directoryNameToRemove: string;
    }) => {
        const fullDirectoryPathWithNameToRemove = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: directoryPathToRemove,
            directoryName: directoryNameToRemove,
        });

        let deliverableIdsToRemove: ID[] = [];
        for (const deliverablePath in taskDeliverablesTree.deliverables) {
            const shouldDeliverablesBeRemoved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
                path: deliverablePath,
                subPath: fullDirectoryPathWithNameToRemove,
            });

            if (shouldDeliverablesBeRemoved) {
                // Deliverables should be removed

                deliverableIdsToRemove = [
                    ...deliverableIdsToRemove,
                    ...Object.keys(taskDeliverablesTree.deliverables[deliverablePath]),
                ];
            }
        }

        if (deliverableIdsToRemove.length > 0) {
            try {
                await archiveTaskDeliverablesBatch({
                    task_deliverable_ids: deliverableIdsToRemove,
                });
            } catch (e) {
                throw e;
            }
        }

        taskDeliverablesTreeApi.removeDirectory({
            directoryName: directoryNameToRemove,
            directoryPath: directoryPathToRemove,
        });
    };

    return { removeDirectory: handleRemoveDirectory };
};

export default useRemoveDirectory;
