import React, { HTMLAttributes, useState } from 'react';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { AnchorButton, Button, Card, Classes, Icon, Intent, Spinner, Tooltip } from '@blueprintjs/core';
import DevText from '@components/Text';
import { UserNotificationEmailResource } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/resources';
import { useStore } from 'effector-react';
import { $notificationEmails } from '../../store/states';
import NonIdealState from '@app/components/NonIdealState';
import { openModal } from '@app/containers/modals/store/events';
import UpdateNotificationEmailModal, {
    UpdateNotificationEmailModalProps,
} from '../../modals/UpdateNotificationEmailModal';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import {
    generateNotificationEmailVerificationCode,
    removeNotificationEmail,
    removeNotificationEmailVerification,
    verifyNotificationEmail,
} from '../../store/effects';
import { notificationEmailsApi } from '../../store/apis';
import moment from 'moment';

export type Props = HTMLAttributes<HTMLDivElement>;

const NotificationEmailList: React.FC<Props> = (props) => {
    const notificationEmails = useStore($notificationEmails);

    const [removingNotificationEmailId, setRemovingNotificationEmailId] = useState<ID | null>(null);
    const [removingNotificationEmailVerificationId, setRemovingNotificationEmailVerificationId] = useState<ID | null>(
        null
    );
    const [verifyingNotificationEmailId, setVerifyingNotificationEmailId] = useState<ID | null>(null);

    const renderEmailLabel = (notificationEmail: UserNotificationEmailResource) => {
        if (notificationEmail.name.trim().length > 0) {
            return (
                <Heading type="h4">
                    <DevText inline muted>
                        {notificationEmail.name}:
                    </DevText>{' '}
                    {notificationEmail.email}
                </Heading>
            );
        } else {
            return <Heading type="h4">{notificationEmail.email}</Heading>;
        }
    };

    const renderEmailInformation = (notificationEmail: UserNotificationEmailResource) => {
        if (notificationEmail.verified_at === null) {
            // Not verified
            return (
                <Flex align="center">
                    <Icon icon="circle" className="mr-1" size={15} />
                    {renderEmailLabel(notificationEmail)}
                </Flex>
            );
        } else {
            // Verified
            return (
                <Flex align="center">
                    <Icon icon="confirm" className="mr-1" intent={Intent.SUCCESS} size={15} />
                    {renderEmailLabel(notificationEmail)}
                </Flex>
            );
        }
    };

    const renderVerifyNotificationEmailButton = (notificationEmail: UserNotificationEmailResource) => {
        if (notificationEmail.verified_at !== null) {
            // Notification is verified
            return null;
        }

        // Check if 5 minutes passed since last generation of the verification code
        const isGenerateVerificationCodeButtonDisabled =
            notificationEmail.verification_last_generated_at !== null &&
            moment().diff(moment(notificationEmail.verification_last_generated_at), 'minutes') <= 5;

        const handleVerifyNotificationEmail = async () => {
            setVerifyingNotificationEmailId(notificationEmail.id);
            try {
                // Verify notification email
                await verifyNotificationEmail(notificationEmail.id);

                // Update local state
                notificationEmailsApi.verify(notificationEmail.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setVerifyingNotificationEmailId(null);
            }
        };

        const handleGenerateVerificationCode = async () => {
            if (isGenerateVerificationCodeButtonDisabled) {
                return;
            }

            setVerifyingNotificationEmailId(notificationEmail.id);
            try {
                // Verify notification email
                await generateNotificationEmailVerificationCode(notificationEmail.id);

                // Update local state
                notificationEmailsApi.generateVerificationCode(notificationEmail.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setVerifyingNotificationEmailId(null);
            }
        };

        return (
            <ConfirmationPopover
                className="mr-1"
                title="Verify notification email"
                description={`Choose "Manually" if you want to verify instantly, or "Send verification" to send verification link to this email`}
                maxWidth="500px"
                actions={[
                    <Button className={Classes.POPOVER_DISMISS} onClick={handleVerifyNotificationEmail}>
                        Manually verify
                    </Button>,
                    <Tooltip
                        disabled={!isGenerateVerificationCodeButtonDisabled}
                        content="Please wait until generating verification code again"
                    >
                        <AnchorButton
                            disabled={isGenerateVerificationCodeButtonDisabled}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleGenerateVerificationCode}
                        >
                            Send verification
                        </AnchorButton>
                    </Tooltip>,
                ]}
            >
                <Button
                    loading={verifyingNotificationEmailId === notificationEmail.id}
                    disabled={verifyingNotificationEmailId === notificationEmail.id}
                    intent={Intent.PRIMARY}
                >
                    Verify
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderRemoveNotificationEmailVerificationButton = (notificationEmail: UserNotificationEmailResource) => {
        if (notificationEmail.verified_at === null) {
            // Notification is NOT verified
            return null;
        }

        const handleRemoveNotificationEmailVerification = async () => {
            setRemovingNotificationEmailVerificationId(notificationEmail.id);
            try {
                // Remove notification email verification
                await removeNotificationEmailVerification(notificationEmail.id);

                // Update local state
                notificationEmailsApi.removeVerification(notificationEmail.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setRemovingNotificationEmailVerificationId(null);
            }
        };

        return (
            <ConfirmationPopover
                className="mr-1"
                title="Are you sure want to remove verification?"
                description="When confirmed, notification email will be not verified"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleRemoveNotificationEmailVerification}
                    >
                        Yes, remove
                    </Button>,
                ]}
            >
                <Button
                    loading={removingNotificationEmailVerificationId === notificationEmail.id}
                    disabled={removingNotificationEmailVerificationId === notificationEmail.id}
                >
                    Remove verification
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderUpdateNotificationEmailButton = (notificationEmail: UserNotificationEmailResource) => {
        const handleUpdateNotificationEmail = () => {
            openModal<UpdateNotificationEmailModalProps>({
                ModalComponent: UpdateNotificationEmailModal,
                data: { notificationEmailId: notificationEmail.id, name: notificationEmail.name },
            });
        };

        return (
            <Button className="mr-1" icon="edit" onClick={handleUpdateNotificationEmail}>
                Update
            </Button>
        );
    };

    const renderRemoveNotificationEmailButton = (notificationEmail: UserNotificationEmailResource) => {
        const handleRemoveNotificationEmail = async () => {
            setRemovingNotificationEmailId(notificationEmail.id);
            try {
                // Remove notification email
                await removeNotificationEmail(notificationEmail.id);

                // Update local state
                notificationEmailsApi.remove(notificationEmail.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setRemovingNotificationEmailId(null);
            }
        };

        return (
            <ConfirmationPopover
                title="Are you sure want to remove notification email?"
                description="When confirmed, notification email will be removed"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleRemoveNotificationEmail}
                    >
                        Yes, remove
                    </Button>,
                ]}
            >
                <Tooltip content="Remove">
                    <Button
                        minimal
                        loading={removingNotificationEmailId === notificationEmail.id}
                        disabled={removingNotificationEmailId === notificationEmail.id}
                        icon="trash"
                        intent={Intent.DANGER}
                    />
                </Tooltip>
            </ConfirmationPopover>
        );
    };

    const renderNotificationEmailControls = (notificationEmail: UserNotificationEmailResource) => {
        return (
            <Flex align="center">
                {renderUpdateNotificationEmailButton(notificationEmail)}
                {renderVerifyNotificationEmailButton(notificationEmail)}
                {renderRemoveNotificationEmailVerificationButton(notificationEmail)}
                {renderRemoveNotificationEmailButton(notificationEmail)}
            </Flex>
        );
    };

    const renderNotificationEmailVerificationLabel = (notificationEmail: UserNotificationEmailResource) => {
        if (notificationEmail.verified_at === null) {
            return <DevText muted>Email is not verified yet</DevText>;
        } else {
            return <DevText muted>Verified at: {moment(notificationEmail.verified_at).format('D MMM, YYYY')}</DevText>;
        }
    };

    const renderNotificationEmail = (notificationEmail: UserNotificationEmailResource) => {
        return (
            <Card compact>
                <Flex justify="space-between">
                    <div>
                        <div className="mb-1">{renderEmailInformation(notificationEmail)}</div>
                        {renderNotificationEmailVerificationLabel(notificationEmail)}
                    </div>

                    {renderNotificationEmailControls(notificationEmail)}
                </Flex>
            </Card>
        );
    };

    const renderNotificationEmailList = () => {
        if (!notificationEmails) {
            return (
                <Flex direction="row" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (notificationEmails.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No notification emails were added yet
                        </Heading>
                    }
                />
            );
        }

        return notificationEmails.items.map((notificationEmail, index) => (
            <div key={notificationEmail.id} className={index === 0 ? '' : 'mt-2'}>
                {renderNotificationEmail(notificationEmail)}
            </div>
        ));
    };

    return <div {...props}>{renderNotificationEmailList()}</div>;
};

export default NotificationEmailList;
