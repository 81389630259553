import { UpdateTaskCategoryInput } from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import { TaskCategoryIconHashRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import {
    CreateTaskCategoryAddonInput,
    UpdateTaskCategoryAddonInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/inputs';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { UserImageHashRef } from 'dy-frontend-http-repository/lib/modules/User/refs';
import { TaskUtils } from 'dy-frontend-shared/lib/utils';
import { ParsedDesignDimensionData } from 'dy-frontend-shared/lib/utils/TaskUtils/types';
import { createApi } from 'effector';
import moment from 'moment';
import { $normalizedTaskCategoryDimensions, $taskCategory, $taskCategoryAddons } from './states';

// API to manage task category state locally
export const taskCategoryApi = createApi($taskCategory, {
    update: (
        store,
        payload: {
            input: UpdateTaskCategoryInput;
        }
    ) => {
        if (!store) {
            // Task category was NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload.input,
        };
    },

    updateImageFilePreview: (
        store,
        payload: {
            imageFilePreviewUrl: string;
        }
    ) => {
        if (!store) {
            // Task category was NOT fetched yet
            return store;
        }

        return {
            ...store,
            image_file_preview_url: payload.imageFilePreviewUrl,
        };
    },

    updateIconFilePreview: (
        store,
        payload: {
            iconFilePreviewUrl: string;
        }
    ) => {
        if (!store) {
            // Task category was NOT fetched yet
            return store;
        }

        return {
            ...store,
            icon_file_preview_url: payload.iconFilePreviewUrl,
        };
    },

    updateGroups: (
        store,
        payload: {
            input: TaskCategoryResource['groups'];
        }
    ) => {
        if (!store) {
            // Task category was NOT fetched yet
            return store;
        }

        return {
            ...store,
            groups: [...payload.input],
        };
    },

    archive: (store) => {
        if (!store) {
            // Task category addons were NOT fetched yet
            return store;
        }

        const momentNowUtc = moment().utc().format();

        return {
            ...store,
            archived_at: momentNowUtc,
        };
    },

    restore: (store) => {
        if (!store) {
            // Task category was NOT fetched yet
            return store;
        }

        return {
            ...store,
            archived_at: null,
        };
    },
    // Set image
    // TODO: move?
    setImageHash: (store, payload: UserImageHashRef) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: payload.hash,
        };
    },

    // Remove image
    // TODO: move?
    removeImage: (store) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            image_hash: null,
        };
    },

    // Set image
    // TODO: move?
    setIconHash: (store, payload: TaskCategoryIconHashRef) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            icon_hash: payload.hash,
        };
    },

    // Remove Icon
    // TODO: move?
    removeIcon: (store) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            icon_hash: null,
        };
    },
});

// API to manage normalized task category dimensions
export const normalizedTaskCategoryDimensionsApi = createApi($normalizedTaskCategoryDimensions, {
    initialize: (
        store,
        payload: {
            dimensions: TaskCategoryResource['dimensions'];
        }
    ) => {
        return TaskUtils.normalizeDesignDimensions(payload.dimensions);
    },

    update: (store, payload: { normalizedDimensions: ParsedDesignDimensionData }) => {
        return { ...payload.normalizedDimensions };
    },
});

// API to manage task category addons state locally
export const taskCategoryAddonsApi = createApi($taskCategoryAddons, {
    create: (
        store,
        payload: {
            taskCategoryAddonId: ID;
            price: TaskCategoryAddonResource['price'];
            input: CreateTaskCategoryAddonInput;
        }
    ) => {
        if (!store) {
            // Task category addons were NOT fetched yet
            return store;
        }

        // Create new task category addon from payload price and input
        const input = payload.input;
        const price = payload.price;
        const momentNowUtc = moment().utc().format();
        const taskCategoryAddon: TaskCategoryAddonResource = {
            id: payload.taskCategoryAddonId,
            price,
            archived_at: null,
            created_at: momentNowUtc,
            updated_at: momentNowUtc,
            ...input,
        };

        return {
            ...store,
            items: [...store.items, taskCategoryAddon],
        };
    },

    update: (
        store,
        payload: {
            taskCategoryAddonId: ID;
            price: TaskCategoryAddonResource['price'];
            input: UpdateTaskCategoryAddonInput;
        }
    ) => {
        if (!store) {
            // Task category addons were NOT fetched yet
            return store;
        }

        const input = payload.input;
        const price = payload.price;

        // Copy task category addons
        const copiedTaskCategoryAddonItems = store.items.slice();

        // Find task category addon to update
        const foundTaskCategoryAddonItemIndex = copiedTaskCategoryAddonItems.findIndex(
            (taskCategoryAddon) => taskCategoryAddon.id === payload.taskCategoryAddonId
        );

        if (foundTaskCategoryAddonItemIndex === -1) {
            // Task category addon was NOT found
            return store;
        }

        // Update task category addon
        const foundTaskCategoryAddonItem = copiedTaskCategoryAddonItems[foundTaskCategoryAddonItemIndex];
        copiedTaskCategoryAddonItems[foundTaskCategoryAddonItemIndex] = {
            ...foundTaskCategoryAddonItem,
            ...input,
            price,
        };

        return {
            ...store,
            items: copiedTaskCategoryAddonItems,
        };
    },

    archive: (store, payload: { taskCategoryAddonId: ID }) => {
        if (!store) {
            // Task category addons were NOT fetched yet
            return store;
        }

        // Copy task category addons
        const copiedTaskCategoryAddonItems = store.items.slice();

        // Find task category addon to archive
        const foundTaskCategoryAddonItem = copiedTaskCategoryAddonItems.find(
            (taskCategoryAddon) => taskCategoryAddon.id === payload.taskCategoryAddonId
        );

        if (!foundTaskCategoryAddonItem) {
            // Task category addon was NOT found
            return store;
        }

        // Archive task category addon
        const momentNowUtc = moment().utc().format();
        foundTaskCategoryAddonItem.archived_at = momentNowUtc;

        return {
            ...store,
            items: copiedTaskCategoryAddonItems,
        };
    },

    restore: (store, payload: { taskCategoryAddonId: ID }) => {
        if (!store) {
            // Task category addons were NOT fetched yet
            return store;
        }

        // Copy task category addons
        const copiedTaskCategoryAddonItems = store.items.slice();

        // Find task category addon to restore
        const foundTaskCategoryAddonItem = copiedTaskCategoryAddonItems.find(
            (taskCategoryAddon) => taskCategoryAddon.id === payload.taskCategoryAddonId
        );

        if (!foundTaskCategoryAddonItem) {
            // Task category addon was NOT found
            return store;
        }

        // Restore task category addon
        foundTaskCategoryAddonItem.archived_at = null;

        return {
            ...store,
            items: copiedTaskCategoryAddonItems,
        };
    },
});
