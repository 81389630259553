import styled from 'styled-components';
import { Button, Colors } from '@blueprintjs/core';
import { BlueprintVersion } from '@data/consts';
import { Select } from '@blueprintjs/select';

export const StyledSelect = styled(Select)``;
export const SelectButton = styled(Button)`
    height: 30px;
    max-height: 30px;
    padding: 8px 10px;
    border-radius: 2px;
    color: ${Colors.WHITE};
    background-color: ${Colors.DARK_GRAY2} !important;
    outline: none;

    & .${BlueprintVersion}-icon {
        color: ${Colors.WHITE};
    }
`;
