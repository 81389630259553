import domain from './domain';
import { UserIdentityResource } from 'dy-frontend-http-repository/lib/modules/User/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { UserRef } from 'dy-frontend-http-repository/lib/modules/User/refs';

// Fetches identity
export const fetchUserIdentity = domain.createEffect<ID, UserIdentityResource>({
    name: 'fetchUserIdentity',
    handler: async (userId) => {
        return await repository.user().getIdentity(userId);
    },
});

// Manually verify
export const verifyUser = domain.createEffect<ID, UserRef>({
    name: 'verifyUser',
    handler: async (id) => {
        return await repository.user().verify(id);
    },
});

// Remove verification
export const removeUserVerification = domain.createEffect<ID, UserRef>({
    name: 'removeUserVerification',
    handler: async (id) => {
        return await repository.user().removeVerification(id);
    },
});

// Disable password auth
export const disableUserPasswordAuth = domain.createEffect<ID, UserRef>({
    name: 'disableUserPasswordAuth',
    handler: async (id) => {
        return await repository.user().disablePasswordAuth(id);
    },
});

// Disable google auth
export const disableUserGoogleAuth = domain.createEffect<ID, UserRef>({
    name: 'disableUserGoogleAuth',
    handler: async (id) => {
        return await repository.user().disableGoogleAuth(id);
    },
});
