import styled, { css } from 'styled-components';
import { Link as DefLink } from 'react-router-dom';
import { RouterLinkProps } from './';
import { UnitUtils } from 'dy-frontend-shared/lib/utils';

export const StyledRouterLink = styled(DefLink)<{
    $color: RouterLinkProps['color'];
    $underline: RouterLinkProps['underline'];
    $showUnderlineOnHover: RouterLinkProps['showUnderlineOnHover'];
    $fontSize: RouterLinkProps['fontSize'];
    $fontWeight: RouterLinkProps['fontWeight'];
    $lineHeight: RouterLinkProps['lineHeight'];
    $inline: RouterLinkProps['inline'];
}>`
    &&& {
        &:active,
        &:focus {
            outline: 0;
            border: none;
        }

        ${({ $underline, $inline, $lineHeight, $fontWeight }) => css`
            display: ${$inline ? 'inline-block' : 'block'};
            text-decoration: ${$underline ? 'underline' : 'none'};
            line-height: ${$lineHeight ?? 1.7};
            font-weight: ${$fontWeight ?? 400};
        `}

        ${({ $underline, $showUnderlineOnHover }) =>
            $showUnderlineOnHover &&
            !$underline &&
            css`
                &:hover {
                    text-decoration: underline;
                }
            `}

        ${({ $fontSize }) =>
            $fontSize &&
            css`
                font-size: ${UnitUtils.pxToRem($fontSize, 14)};
            `}

        ${({ $color }) =>
            $color &&
            css`
                color: ${$color};
            `}
    }
`;
