import Box from '@app/components/Box';
import FilePreview from '@app/components/FilePreview';
import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { Card, CardProps, Elevation, Icon, Intent, Tag, Tooltip } from '@blueprintjs/core';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import React, { ReactNode } from 'react';
import Link from '@app/components/Link';
import moment from 'moment';

export interface TaskDeliverableListViewListItemProps {
    createdAt: string;
    approvementTagVisible?: boolean;
    taskDeliverable: TaskDeliverableResource;
    actions?: ReactNode;
    onOpenViewer?: () => void;
}

export type Props = CardProps & TaskDeliverableListViewListItemProps;

const TaskDeliverableListViewListItem: React.FC<Props> = ({
    selected = false,
    approvementTagVisible = true,
    createdAt,
    taskDeliverable,
    actions,
    style,
    onOpenViewer,
    ...props
}) => {
    const renderPreview = () => {
        if (taskDeliverable.file) {
            // Task deliverable has file attached to it
            return (
                <FilePreview
                    width="100%"
                    height="100%"
                    minWidth="100%"
                    minHeight="100%"
                    objectFit="cover"
                    style={{ cursor: 'pointer' }}
                    onClick={onOpenViewer}
                    extension={taskDeliverable.file.extension}
                    src={taskDeliverable.file.preview_url}
                />
            );
        }

        // Get icon
        let icon: JSX.Element;
        switch (taskDeliverable.type) {
            case TaskDeliverableType.BLANK:
                icon = <Icon icon="circle" size={42} />;
                break;
            case TaskDeliverableType.TEXT:
                icon = <Icon icon="text-highlight" size={42} />;
                break;
            case TaskDeliverableType.EMBED:
                icon = <Icon icon="insert" size={42} />;
                break;
            default:
                icon = <Icon icon="error" size={42} />;
        }

        return (
            <Box width="56px" height="56px" minHeight="56px" minWidth="56px" cursor="pointer" onClick={onOpenViewer}>
                <Flex fullHeight fullWidth align="center" justify="center">
                    {icon}
                </Flex>
            </Box>
        );
    };

    const renderApprovementTag = () => {
        if (!approvementTagVisible) {
            // Do not show approvement tag
            return null;
        }

        if (taskDeliverable.approved_at) {
            // Approved
            return null;
        }

        // Not approved
        return (
            <Tag minimal className="ml-1" icon="warning-sign" intent={Intent.WARNING}>
                Not approved
            </Tag>
        );
    };

    const renderTitle = () => {
        return (
            <Tooltip content={taskDeliverable.title}>
                <Link onClick={onOpenViewer}>{TextFormatUtils.truncate(taskDeliverable.title, 70)}</Link>
            </Tooltip>
        );
    };

    return (
        <Card elevation={Elevation.ONE} style={{ padding: '8px', ...style }} {...props}>
            <Flex direction="row" align="center" justify="space-between">
                <Flex direction="row" align="center">
                    <Box className="mr-1" width="56px" height="56px" minWidth="56px" minHeight="56px">
                        <Flex fullHeight align="center" justify="center">
                            {renderPreview()}
                        </Flex>
                    </Box>

                    <div>
                        {renderTitle()}
                        <Flex direction="row" align="center">
                            <DevText muted className="mr-1">
                                {moment(createdAt).format('D MMM, YYYY')}
                            </DevText>
                            {renderApprovementTag()}
                        </Flex>
                    </div>
                </Flex>

                {actions && <Flex align="center">{actions}</Flex>}
            </Flex>
        </Card>
    );
};

export default TaskDeliverableListViewListItem;
