import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../store/states';
import { resetEditingTaskMessage, resetTaskMessages } from './store/events';
import TaskMessageEditor from './components/TaskMessageEditor';
import TaskMessageList from './components/TaskMessageList';
import { usePageTitle } from '@app/hooks';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { TaskMessagePermission } from 'dy-frontend-permissions/lib/permission';

const TaskMessages: React.FC = () => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { changeTitle } = usePageTitle('Request Messages');

    const [isMessageModuleAccessAllowed, setIsMessageModuleAccessAllowed] = useState(false);
    const [isPublicMessageComposeAllowed, setIsPublicMessageComposeAllowed] = useState(false);
    const [isInternalMessageComposeAllowed, setIsInternalMessageComposeAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // No access to message module
        if (!permissions.isRoot.taskMessage && !permissions.isEnabled.taskMessage) {
            setIsMessageModuleAccessAllowed(false);
            setIsPublicMessageComposeAllowed(false);
            setIsInternalMessageComposeAllowed(false);
            return;
        } else {
            setIsMessageModuleAccessAllowed(true);
        }

        // Root permission set
        if (permissions.isRoot.taskMessage) {
            setIsMessageModuleAccessAllowed(true);
            setIsPublicMessageComposeAllowed(true);
            setIsInternalMessageComposeAllowed(true);
            return;
        }

        // Permission: create public
        if (permissions.has(TaskMessagePermission.CREATE)) {
            setIsPublicMessageComposeAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsPublicMessageComposeAllowed(
                isAssigned
                && permissions.has(TaskMessagePermission.CREATE_MOD_TASK_ASSIGNED),
            );
        }

        // Permission: create internal
        if (permissions.has(TaskMessagePermission.CREATE_INTERNAL)) {
            setIsInternalMessageComposeAllowed(true);
        } else {
            const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;
            setIsInternalMessageComposeAllowed(
                isAssigned
                && permissions.has(TaskMessagePermission.CREATE_INTERNAL_MOD_TASK_ASSIGNED),
            );
        }
    }, [task, permissions, me]);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request Messages - ${task.title}`);
    }, [task?.title]);

    useEffect(() => {
        return () => {
            resetTaskMessages();
            resetEditingTaskMessage();
        };
    }, []);

    if (!task) {
        return null;
    }

    if (!isMessageModuleAccessAllowed) {
        return null;
    }

    return (
        <div>
            <TaskMessageEditor
                isPublicComposeAllowed={isPublicMessageComposeAllowed}
                isInternalComposeAllowed={isInternalMessageComposeAllowed}
                className="mb-4"
            />
            <TaskMessageList />
        </div>
    );
};

export default TaskMessages;
1;
