import { Button, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import ShutdownUserModal, { ShutdownUserModalProps } from '../../modals/ShutdownUserModal';
import { userIdentityApi } from '../../store/apis';
import { $userIdentity } from '../../store/states';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { ClientUserPermission, CoreUserPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const DangerZone: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    if (!identity || !me) {
        return null;
    }

    if (identity.user_id === me.user.id) {
        return null;
    }

    let isSectionViewAllowed = false;
    if ((identity.type === UserType.CLIENT) && permissions.isEnabled.clientUser) {
        isSectionViewAllowed = (
            permissions.isRoot.clientUser || permissions.has(ClientUserPermission.SHUTDOWN)
        );
    } else if ((identity.type === UserType.CORE) && permissions.isEnabled.coreUser) {
        isSectionViewAllowed = (
            permissions.isRoot.coreUser || permissions.has(CoreUserPermission.SHUTDOWN)
        );
    }
    if (!isSectionViewAllowed) {
        return null;
    }

    // Determine if danger zone should be rendered at all
    const isSectionVisible =
        // If user is not shutdown: shutdown control card visible
        identity.shutdown_at === null;
    if (!isSectionVisible) {
        return null;
    }

    const renderShutdownAccountControl = () => {
        if (!identity) {
            return null;
        }

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="trash" className="mr-1" size={15} />
                        <Heading type="h4">Shutdown account</Heading>
                    </Flex>
                    <Flex align="center">
                        <Button
                            className="mr-1"
                            intent={Intent.DANGER}
                            onClick={() =>
                                openModal<ShutdownUserModalProps>({
                                    ModalComponent: ShutdownUserModal,
                                    data: {
                                        userId: identity.user_id,
                                        onShutdownUserSuccess: (shutdownReason) => {
                                            userIdentityApi.shutdown({ reason: shutdownReason });
                                        },
                                    },
                                })
                            }
                        >
                            Start shutdown flow
                        </Button>
                    </Flex>
                </Flex>
                <DevText muted>Shutdown or ban this user account</DevText>
            </Card>
        );
    };

    return (
        <div {...props}>
            <Divider className="mt-3 mb-2" />

            <Flex align="center" className="mb-1">
                <Icon icon="warning-sign" intent={Intent.DANGER} className="mr-1" />
                <Heading type="h3">Danger Zone</Heading>
            </Flex>
            <DevText muted className="mb-1">
                Settings and actions below will affect user account in a major way
            </DevText>
            <div className="mb-1">{renderShutdownAccountControl()}</div>
        </div>
    );
};

export default DangerZone;
