import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { TaskCategoryFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import domain from './domain';

// Fetch task categories
export const fetchTaskCategories = domain.createEffect<
    { filter?: TaskCategoryFilterInput },
    CollectionResource<TaskCategoryListItemResource, {}>
>({
    name: 'fetchTaskCategories',
    handler: async ({ filter }) => {
        return await repository.taskCategory().get(filter);
    },
});
