import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskMessageTemplateCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import { TaskMessageTemplateListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import domain from './domain';
import {
    resetSelectedTaskMessageTemplateCategoryId,
    resetTaskMessageTemplateCategories,
    resetTaskMessageTemplates,
} from './events';

export const $taskMessageTemplateCategories = domain
    .createStore<CollectionResource<TaskMessageTemplateCategoryListItemResource, {}> | null>(null)
    .reset(resetTaskMessageTemplateCategories);

// If <code>selectedMessageTemplateCategoryId === null</code> it will show ALL task message templates without binding to any task message template category
export const $selectedTaskMessageTemplateCategoryId = domain
    .createStore<ID | null>(null)
    .reset(resetSelectedTaskMessageTemplateCategoryId);

export const $taskMessageTemplates = domain
    .createStore<CollectionResource<TaskMessageTemplateListItemResource, {}> | null>(null)
    .reset(resetTaskMessageTemplates);
