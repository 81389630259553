import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverableFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import domain from './domain';

// Fetch list of task deliverables
export const fetchApprovedTaskDeliverables = domain.createEffect<
    TaskDeliverableFilterInput,
    CollectionResource<TaskDeliverableResource, {}>
>({
    name: 'fetchApprovedTaskDeliverables',
    handler: async (filter) => {
        return await repository.taskDeliverable().get(filter);
    },
});
