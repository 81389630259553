import { createApi } from 'effector';
import {
    TaskMessageListItemResource,
    TaskMessageResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import { $taskMessages } from './states';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { amountOfTaskMessagesOnPage } from '../components/TaskMessageList/consts';

export const taskMessagesApi = createApi($taskMessages, {
    // Add at tail after successful fetch
    addAtTailAfterSuccessfulFetch: (
        store,
        payload: { taskMessages: CollectionResource<TaskMessageListItemResource, unknown> },
    ) => {
        // Skip if task messages were NOT fetched yet
        if (!store) {
            return { ...payload.taskMessages };
        }

        return {
            ...store,
            cursor: payload.taskMessages.cursor,
            items: [...store.items, ...payload.taskMessages.items],
        };
    },

    // Add at tail after successful creation
    addAtStartAfterSuccessfulCreation: (
        store,
        payload: { taskMessages: CollectionResource<TaskMessageListItemResource, unknown> },
    ) => {
        const newNoteItems = [...payload.taskMessages.items].reverse();

        // Skip if task messages were NOT fetched yet
        if (!store) {
            // This happens whenever first message is created

            const { cursor, ...propsToSpread } = payload.taskMessages;

            // Create new cursor
            const newCursor: CollectionResource<TaskMessageListItemResource, unknown>['cursor'] = {
                ...cursor,
                direction: 'desc',
                next_cursor_start: newNoteItems[newNoteItems.length - 1].id,
                has_more: true,
                limit: amountOfTaskMessagesOnPage,
            };

            return {
                ...propsToSpread,
                cursor: newCursor,
                items: newNoteItems,
            };
        }

        return {
            ...store,
            items: [...newNoteItems, ...store.items],
        };
    },

    // Remove task message
    remove: (store, id: ID) => {
        // Task messages were NOT fetched yet
        if (!store) {
            return store;
        }

        const itemsCopy = [...store.items];

        const filteredItemsCopy = itemsCopy.filter((item) => item.id !== id);

        return {
            ...store,
            items: filteredItemsCopy,
        };
    },

    // Update task message
    update: (store, payload: { taskMessage: TaskMessageResource }) => {
        // Skip if task messages were NOT fetched yet
        if (!store) {
            return store;
        }

        // Copy task messages
        const taskMessages = [...store.items];

        // Find task message item index in array
        const foundTaskMessageIndex = taskMessages.findIndex((t) => t.id === payload.taskMessage.id);

        if (foundTaskMessageIndex === -1) {
            return store;
        }
        // Replace draft task with updated one at found index
        taskMessages[foundTaskMessageIndex] = { ...payload.taskMessage };

        return {
            ...store,
            items: taskMessages,
        };
    },

    // Pin task message
    pin: (store, id: ID) => {
        // Task messages were NOT fetched yet
        if (!store) {
            return store;
        }

        // Copy task messages
        const taskMessageItemsCopy = [...store.items];

        // Find task message to pin
        const foundTaskMessage = taskMessageItemsCopy.find((taskMessage) => taskMessage.id === id);

        // Task message, which user want to pin was NOT found
        if (!foundTaskMessage) {
            return store;
        }

        // Pin message
        foundTaskMessage.is_pinned = true;

        return {
            ...store,
            items: taskMessageItemsCopy,
        };
    },

    // Unpin task message
    unpin: (store, id: ID) => {
        // Task messages were NOT fetched yet
        if (!store) {
            return store;
        }

        // Copy task messages
        const taskMessageItemsCopy = [...store.items];

        // Find task message to pin
        const foundTaskMessage = taskMessageItemsCopy.find((taskMessage) => taskMessage.id === id);

        // Task message, which user want to pin was NOT found
        if (!foundTaskMessage) {
            return store;
        }

        // Pin message
        foundTaskMessage.is_pinned = false;

        return {
            ...store,
            items: taskMessageItemsCopy,
        };
    },
});
