import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultStringShouldBeNumericValueMessage = 'This field should be number';

const getStringNumericValueValidator: GetValidatorFunction<{ stringShouldBeNumericValueMessage?: string }, string> = (
    options = { stringShouldBeNumericValueMessage: defaultStringShouldBeNumericValueMessage }
) => {
    const { stringShouldBeNumericValueMessage } = options;

    return async (value) => {
        if (isNaN(value as any)) {
            // Value is NOT number
            if (stringShouldBeNumericValueMessage) {
                return stringShouldBeNumericValueMessage;
            } else {
                return defaultStringShouldBeNumericValueMessage;
            }
        } else {
            // Value is number
            return null;
        }
    };
};

export default getStringNumericValueValidator;
