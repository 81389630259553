import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { taskApi } from '@pages/Task/store/apis';
import { pauseTask } from '@pages/Task/store/effects';

export interface PauseTaskModalProps {
    taskId: ID;
}

type Props = ModalProps<PauseTaskModalProps>;

const PauseTaskModal: React.FC<Props> = ({
    closeModal,
    data,
}) => {
    // Task restoring
    const [isTaskPausing, setIsTaskPausing] = useState(false);

    // O_o?
    if (!data) {
        closeModal?.();
        return null;
    }

    const handlePauseFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsTaskPausing(true);

        pauseTask(data.taskId)
            .then(() => {
                taskApi.pause();
                closeModal?.();
            })
            .catch((e) => console.error)
            .finally(() => setIsTaskPausing(false));

        return null;
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">Pause this request?</Heading>
                        <DevText muted>Proceeding will mark this request as "Paused"</DevText>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={isTaskPausing} />
                </Flex>

                <DevText running className="mb-2">
                    Paused requests won't be hidden from client list, however they must be treated as requests where
                    all work and revisions are suspended, until request is resumed. Additionally, paused requests are
                    not counted as part of total active/backlog requests. You can resume request at any time
                </DevText>

                <form onSubmit={handlePauseFormSubmit}>
                    <Flex align="center" justify="space-between">
                        <DevText muted>Proceed with pause?</DevText>
                        <div>
                            <Button minimal onClick={closeModal} className="mr-1" disabled={isTaskPausing}>
                                No, cancel
                            </Button>
                            <Button
                                intent={Intent.PRIMARY}
                                loading={isTaskPausing}
                                type="submit"
                            >
                                Yes, pause request
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default PauseTaskModal;
