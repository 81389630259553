import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { Button, Card, Intent } from '@blueprintjs/core';
import { AuthenticationType } from 'dy-frontend-http-repository/lib/modules/Auth/enums';
import { useForm, useTextFormField } from '@app/hooks';
import { getStringEmailValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { authenticationPriority } from '../../consts';
import { AuthenticationFormStep } from '../../enums';
import { $workspaceInitData } from '@containers/store/states';
import { authenticationFormStepApi } from '../../store/apis';
import { fetchEmailSchema } from '../../store/effects';
import InputFormField from '@components/InputFormField';
import Heading from '@components/Heading';

export type Props = HTMLAttributes<HTMLDivElement>;

const emailValidators = [getStringRequiredValidator(), getStringEmailValidator()];

const EmailCheckForm: React.FC<Props> = () => {
    const workspaceInitData = useStore($workspaceInitData);

    const email = useTextFormField({
        id: 'email',
        validators: emailValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [email],
        apiCall: async () => {
            try {
                const emailSchema = await fetchEmailSchema({
                    email: email.value,
                    workspace_id: workspaceInitData?.workspace.id,
                });

                // Entered email was found/registered
                if (emailSchema.authentication.length === 0) {
                    // Should never happens, because if the email is found/registered, then it should have at least 1 authentication
                    throw new Error(
                        'Found/Registered email should have at least 1 authentication in the list of authentications'
                    );
                }

                // Get prioritized authentication
                let prioritizedAuthentication = AuthenticationType.EMAIL_PASSWORD;
                for (let i = 0; i < emailSchema.authentication.length; i++) {
                    const authentication = emailSchema.authentication[i];

                    if (authenticationPriority[authentication] > authenticationPriority[prioritizedAuthentication]) {
                        // Found authentication priority is higher then the one stored in prioritizedAuthentication
                        prioritizedAuthentication = authentication;
                    }
                }

                switch (prioritizedAuthentication) {
                    case AuthenticationType.GOOGLE:
                        // TODO: if google sign up will be allowed for internal users open google frame and wait for callback with token which will be used for google authentication
                        break;
                    case AuthenticationType.EMAIL_PASSWORD:
                        // Redirect to SignIn form

                        // Move to SignIn page
                        authenticationFormStepApi.moveToStep(AuthenticationFormStep.SIGN_IN);
                        break;
                }

                return { response: emailSchema };
            } catch (e) {
                // TODO: handle error
                throw e;
            }
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    const renderHeader = () => {
        if (workspaceInitData) {
            // Workspace
            return (
                <Heading className="mb-2" type="h3">
                    Login to {workspaceInitData.workspace.key} platform
                </Heading>
            );
        } else {
            // Internal DotYeti platform
            return (
                <Heading className="mb-2" type="h3">
                    Login to DotYeti platform
                </Heading>
            );
        }
    };

    return (
        <Card style={{ width: '400px' }}>
            {renderHeader()}

            <form onSubmit={form.handleFormSubmit}>
                <InputFormField
                    field={email}
                    formGroupProps={{ label: 'Email' }}
                    inputProps={{ placeholder: 'Enter email' }}
                />

                <Button
                    fill
                    loading={form.isSubmitting}
                    disabled={form.hasFieldErrors}
                    type="submit"
                    intent={Intent.PRIMARY}
                >
                    Continue
                </Button>
            </form>
        </Card>
    );
};

export default EmailCheckForm;
