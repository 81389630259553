import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import InputFormField from '@app/components/InputFormField';
import DevText from '@app/components/Text';
import { ModalProps } from '@app/containers/modals/types';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getOptionalStringValidator,
    getStringEmailValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import {
    Card,
    Button,
    Divider,
    Intent,
    RadioGroup,
    RadioCard,
    Alignment,
    Elevation,
    FormGroup,
} from '@blueprintjs/core';
import Overlay from '@components/Overlay';
import { AddUserNotificationEmailInput } from 'dy-frontend-http-repository/lib/modules/UserNotificationEmail/inputs';
import React, { useState } from 'react';
import { notificationEmailsApi } from '../../store/apis';
import { NotificationEmailVerificationType } from './enums';
import { createNotificationEmail } from './store/effects';

const emailValidators = [getStringRequiredValidator(), getStringEmailValidator()];
const firstNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];
const lastNameValidators = [
    getOptionalStringValidator([
        getStringMinLengthValidator({ minStringLength: 2 }),
        getStringMaxLengthValidator({ maxStringLength: 50 }),
    ]),
];

export interface CreateNotificationEmailModalProps {
    userId: ID;
}

export type Props = ModalProps<CreateNotificationEmailModalProps>;

const CreateNotificationEmailModal: React.FC<Props> = ({ closeModal, data }) => {
    const [verificationType, setVerificationType] = useState<NotificationEmailVerificationType>(
        NotificationEmailVerificationType.MANUAL
    );

    const email = useTextFormField({
        id: 'email',
        validators: emailValidators,
        initialValue: '',
    });

    const firstName = useTextFormField({
        id: 'first-name',
        validators: firstNameValidators,
        initialValue: '',
    });

    const lastName = useTextFormField({
        id: 'last-name',
        validators: lastNameValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [email, firstName, lastName],
        apiCall: async () => {
            try {
                // Create input
                const createNotificationEmailInput: AddUserNotificationEmailInput = {
                    email: email.value,
                    user_id: data?.userId ?? '0',
                    name: `${firstName.value} ${lastName.value}`.trim(),
                    is_verified: verificationType === NotificationEmailVerificationType.MANUAL ? true : false,
                    is_verification_code_create_enabled:
                        verificationType === NotificationEmailVerificationType.VERIFICATION_CODE ? true : false,
                };

                // Create notification email
                const notificationEmailRef = await createNotificationEmail(createNotificationEmailInput);

                // Update local state
                notificationEmailsApi.create({ id: notificationEmailRef.id, input: createNotificationEmailInput });

                // Close modal
                closeModal?.();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Add notification email</Heading>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        {/* Email */}
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={email}
                                formGroupProps={{ label: 'Email' }}
                                inputProps={{ placeholder: 'Enter email' }}
                            />
                        </Grid>

                        {/* First name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={firstName}
                                formGroupProps={{ label: 'First name' }}
                                inputProps={{ placeholder: 'Enter first name' }}
                            />
                        </Grid>

                        {/* Last name */}
                        <Grid lg={6} xs={12}>
                            <InputFormField
                                field={lastName}
                                formGroupProps={{ label: 'Last name' }}
                                inputProps={{ placeholder: 'Enter last name' }}
                            />
                        </Grid>

                        {/* Verification type */}
                        <Grid lg={12} xs={12}>
                            <FormGroup label="How to verify email?">
                                <RadioGroup
                                    disabled={form.isSubmitting}
                                    selectedValue={verificationType}
                                    onChange={(e) =>
                                        setVerificationType(e.currentTarget.value as NotificationEmailVerificationType)
                                    }
                                    className="control-card-group-row"
                                >
                                    <RadioCard
                                        elevation={Elevation.TWO}
                                        alignIndicator={Alignment.RIGHT}
                                        label="Immediately"
                                        value={NotificationEmailVerificationType.MANUAL}
                                    >
                                        <DevText className="mb-1">Immediately</DevText>
                                        <DevText muted>Without additional verification</DevText>
                                    </RadioCard>

                                    <RadioCard
                                        elevation={Elevation.TWO}
                                        alignIndicator={Alignment.RIGHT}
                                        label="With Verification"
                                        value={NotificationEmailVerificationType.VERIFICATION_CODE}
                                    >
                                        <DevText className="mb-1">With Verification</DevText>
                                        <DevText muted>Verification link will be sent to provided email</DevText>
                                    </RadioCard>
                                </RadioGroup>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Flex className="mt-2" direction="row" justify="flex-end">
                        <Button minimal onClick={handleModalClose} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            loading={form.isSubmitting}
                            disabled={form.isSubmitting || form.hasFieldErrors}
                            type="submit"
                        >
                            Yes, add
                        </Button>
                    </Flex> 
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateNotificationEmailModal;
