import { repository } from 'dy-frontend-http-repository/lib/modules';
import { AllocateVirtualLicenceInput } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/inputs';
import { VirtualLicenceRef } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/refs';
import domain from './domain';

// Create virtual licence
export const createVirtualLicence = domain.createEffect<AllocateVirtualLicenceInput, VirtualLicenceRef>({
    name: 'createVirtualLicence',
    handler: async (input) => {
        return await repository.virtualLicence().allocate(input);
    },
});
