import React, { useEffect, useState } from 'react';
import {
    Button,
    Callout,
    Card,
    Colors,
    Elevation,
    InputGroup,
    Intent,
    Popover,
    PopoverPosition,
    Spinner,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { PriceType } from 'dy-frontend-http-repository/lib/modules/Price/enums';
import { PriceRecurringInterval } from 'dy-frontend-http-repository/lib/data/enums';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { useDebouncedState } from '@app/hooks';
import Circle from '@components/Circle';

export interface PriceResolverInputValue {
    id: ID;
    externalId: string;
    amount: number;
    currency: string;
    type: PriceType;
    recurringInterval: PriceRecurringInterval | null;
    recurringIntervalCount: number | null;
    planId: ID | null;
    planTitle: string | null;
}

export interface PriceResolverInputProps {
    // Currently selected price
    value: PriceResolverInputValue | null;

    // Callback when new price is selected
    onChange?: (value: PriceResolverInputValue | null) => void;

    // Callback, which returns list of price violations. If 0 size array is returned, price can be used
    onViolationListRequested?: (value: PriceResolverInputValue) => string[];
}

export type Props = PriceResolverInputProps;

const PriceResolverInput: React.FC<Props> = ({ value, onChange, onViolationListRequested }) => {
    // Flag if resolve is in-flight
    const [isResolveResultOpen, setIsResolveResultOpen] = useState(false);

    // What user is typing in the search box
    const [searchInputValue, setSearchInputValue] = useState('');
    const debouncedSearchInputValue = useDebouncedState<string>(searchInputValue, 1000);

    const [isResolving, setIsResolving] = useState(false);
    const [lastResolveError, setLastResolveError] = useState<string | null>(null);
    const [currentResolvedPrice, setCurrentResolvedPrice] = useState<PriceResolverInputValue | null>(null);
    const [currentViolationList, setCurrentViolationList] = useState<string[]>([]);

    // Debounced callback when user types something
    useEffect(() => {
        // Skip if price is not provided
        if (!debouncedSearchInputValue) {
            return;
        }

        setIsResolving(true);
        setLastResolveError(null);
        setCurrentViolationList([]);

        repository
            .price()
            .getByExternalId(debouncedSearchInputValue)
            .then((price) => {
                const next: PriceResolverInputValue = {
                    id: price.id,
                    externalId: price.external_id,
                    amount: price.unit_amount,
                    currency: price.currency,
                    type: price.type,
                    recurringInterval: price.recurring_interval,
                    recurringIntervalCount: price.recurring_interval_count,
                    planId: price.plan ? price.plan.id : null,
                    planTitle: price.plan?.title ?? null,
                };

                // Process violations
                if (onViolationListRequested) {
                    setCurrentViolationList(onViolationListRequested(next));
                }

                // Update current price
                setCurrentResolvedPrice(next);
            })
            .catch((e) => {
                setLastResolveError(`Price "${debouncedSearchInputValue}" is not found`);
                setCurrentResolvedPrice(null);
            })
            .finally(() => setIsResolving(false));
    }, [debouncedSearchInputValue]);

    const handlePriceValueChanged = (data: PriceResolverInputValue | null) => {
        if (!onChange) {
            return;
        }

        // Invoke callback
        onChange(data);
    };

    // TODO: code below for rendering price card should be reviewed, use utils
    const getReadableTypeInfo = (
        type: PriceType,
        recurringInterval?: PriceRecurringInterval | null,
        recurringIntervalCount?: number | null
    ): string => {
        const isOneTime = type === PriceType.ONE_TIME;
        if (isOneTime) {
            return 'One-time payment';
        }

        const isRecurring =
            type === PriceType.RECURRING &&
            recurringInterval !== undefined &&
            recurringInterval !== null &&
            recurringIntervalCount !== undefined &&
            recurringIntervalCount !== null;
        if (isRecurring) {
            return `Every ${recurringIntervalCount} ${recurringInterval}`;
        }

        return '(!) Unknown price change frequency';
    };

    const renderPriceValue = () => {
        if (!value) {
            return null;
        }

        const { externalId, amount, currency, type, recurringInterval, recurringIntervalCount } = value;

        return (
            <Card compact elevation={Elevation.TWO}>
                <Flex align="center" justify="space-between">
                    <Flex direction="column">
                        <Flex align="center">
                            <DevText className="mr-1">
                                {amount / 100} {currency.toUpperCase()}
                            </DevText>
                            <Circle color={Colors.GRAY2} size={'6px'} className="mr-1" />
                            <DevText muted>
                                {getReadableTypeInfo(type, recurringInterval, recurringIntervalCount)}
                            </DevText>
                        </Flex>
                        <Flex>
                            <DevText muted>Stripe ID: {externalId}</DevText>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Button minimal icon="cross" onClick={() => handlePriceValueChanged(null)}>
                            Change
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const handleSearchInputValueChange = (query: string) => {
        const formatted = query.replace(/[^a-zA-Z0-9_]/g, '');
        setSearchInputValue(formatted);
    };

    const renderResolveResultContent = () => {
        if (!searchInputValue) {
            return (
                <Card>
                    <DevText muted>Enter price Stripe ID to search</DevText>
                </Card>
            );
        }

        // Searching in-flight
        if (isResolving) {
            return (
                <Card>
                    <Flex align="center">
                        <Spinner size={16} className="mr-1" />
                        <DevText muted>Looking up price...</DevText>
                    </Flex>
                </Card>
            );
        }

        // There is an error
        if (lastResolveError) {
            return (
                <Card>
                    <DevText muted>{lastResolveError}</DevText>
                </Card>
            );
        }

        // Price is known
        if (currentResolvedPrice) {
            const { externalId, amount, currency, type, recurringInterval, recurringIntervalCount } =
                currentResolvedPrice;

            const renderViolationList = () => {
                if (currentViolationList.length === 0) {
                    return;
                }

                return (
                    <Callout compact icon={null} intent={Intent.WARNING}>
                        <DevText>Cannot use this price:</DevText>
                        {currentViolationList.map((v, i) => (
                            <DevText key={`violation-${i}`}>- {v}</DevText>
                        ))}
                    </Callout>
                );
            };

            return (
                <Card>
                    <Flex align="center">
                        <DevText className="mr-1">
                            {amount / 100} {currency.toUpperCase()}
                        </DevText>
                        <Circle color={Colors.GRAY2} size={'6px'} className="mr-1" />
                        <DevText muted>{getReadableTypeInfo(type, recurringInterval, recurringIntervalCount)}</DevText>
                    </Flex>
                    <Flex className={currentViolationList.length > 0 ? 'mb-2' : ''}>
                        <DevText muted>Stripe ID: {externalId}</DevText>
                    </Flex>
                    {renderViolationList()}
                </Card>
            );
        }

        return (
            <Card>
                <DevText muted>Enter price Stripe ID to search</DevText>
            </Card>
        );
    };

    const renderPriceResolveForm = () => {
        const isPriceUseAllowed =
            // There are no in-flight resolves
            !isResolving &&
            // Current resolved price is present
            currentResolvedPrice !== null &&
            // Input matches external ID of the resolved price
            searchInputValue === currentResolvedPrice?.externalId &&
            // There are no violations
            currentViolationList.length === 0;

        return (
            <>
                <Popover
                    fill
                    matchTargetWidth
                    minimal
                    isOpen={isResolveResultOpen}
                    autoFocus={false}
                    canEscapeKeyClose={false}
                    position={PopoverPosition.TOP}
                    content={renderResolveResultContent()}
                >
                    <div style={{ minWidth: '100%', height: '1px' }} />
                </Popover>
                <InputGroup
                    fill
                    onFocus={() => setIsResolveResultOpen(true)}
                    onBlur={() => setIsResolveResultOpen(false)}
                    placeholder="Enter price Stripe ID to search..."
                    onChange={(e) => handleSearchInputValueChange(e.target.value)}
                    value={searchInputValue}
                    rightElement={
                        isPriceUseAllowed ? (
                            <Button
                                minimal
                                intent={Intent.SUCCESS}
                                onClick={() => handlePriceValueChanged(currentResolvedPrice)}
                            >
                                Use this price
                            </Button>
                        ) : undefined
                    }
                    leftIcon={'tag'}
                />
            </>
        );
    };

    return value ? renderPriceValue() : renderPriceResolveForm();
};

export default PriceResolverInput;
