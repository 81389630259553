import { Button, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';

export interface PaginationProps {
    hasMore: boolean;
    fetching?: boolean;
    page: number;
    totalItems: number | null;
    amountOfItemsOnPage: number;
    onPageChange: (newPage: number) => void;
}

export type Props = PaginationProps & HTMLAttributes<HTMLDivElement>;

const Pagination: React.FC<Props> = ({
    hasMore,
    fetching = false,
    page,
    totalItems,
    amountOfItemsOnPage,
    onPageChange,
    ...props
}) => {
    const [isFirstPageButtonLoading, setIsFirstPageButtonLoading] = useState(false);
    const [isLastPageButtonLoading, setIsLastPageButtonLoading] = useState(false);
    const [isNextPageButtonLoading, setIsNextPageButtonLoading] = useState(false);
    const [isPreviousPageButtonLoading, setIsPreviousPageButtonLoading] = useState(false);

    useEffect(() => {
        if (!fetching) {
            setIsFirstPageButtonLoading(false);
            setIsLastPageButtonLoading(false);
            setIsNextPageButtonLoading(false);
            setIsPreviousPageButtonLoading(false);
        }
    }, [fetching]);

    const { pages, amountOfPages } = useMemo(() => {
        if (!totalItems) {
            return { pages: [], amountOfPages: null };
        }

        const amountOfButtonsToShow = 5;

        // Calculate amount of pages
        const amountOfPages = Math.ceil(totalItems / amountOfItemsOnPage);

        let startButtonPageNumber: number;
        let endButtonPageNumber: number;

        // Calculate "startButton" and "endButton"
        if (page <= amountOfButtonsToShow - 2 || amountOfPages < amountOfButtonsToShow) {
            startButtonPageNumber = 1;
            endButtonPageNumber = Math.min(amountOfButtonsToShow, amountOfPages);
        } else {
            if (page + 2 >= amountOfPages) {
                // Calculate how many pages after "page"
                const amountOfButtonsAfterCurrentPage = amountOfPages - page;

                // Calculate how many pages before "page"
                const amountOfButtonsBeforeCurrentPage = amountOfButtonsToShow - 1 - (amountOfPages - page);

                endButtonPageNumber = page + amountOfButtonsAfterCurrentPage;
                startButtonPageNumber = page - amountOfButtonsBeforeCurrentPage;
            } else {
                startButtonPageNumber = page - 2;
                endButtonPageNumber = Math.min(page + 2, amountOfPages);
            }
        }

        // Create array of numbers [startButtonPageNumber, ..., endButtonPageNumber]
        const pages: number[] = [];
        for (let i = startButtonPageNumber; i <= endButtonPageNumber; i++) {
            pages.push(i);
        }

        return { amountOfPages, pages };
    }, [page, totalItems, amountOfItemsOnPage]);

    const isFistPageActive = page === 1;
    const isLastPageActive = !hasMore;

    // First page handler
    const handleChangeToFirstPage = () => {
        onPageChange(1);
        setIsFirstPageButtonLoading(true);
    };

    // Next page handler
    const handleChangeToNextPage = () => {
        if (isLastPageActive) {
            return;
        }

        onPageChange(page + 1);
        setIsNextPageButtonLoading(true);
    };

    // Previous page handler
    const handleChangeToPreviousPage = () => {
        if (isFistPageActive) {
            return;
        }

        onPageChange(page - 1);
        setIsPreviousPageButtonLoading(true);
    };

    // Last page handler
    const handleChangeToLastPage = (lastPage: number) => {
        setIsLastPageButtonLoading(true);
    };

    const renderGoToFirstPageButton = () => {
        const isButtonDisabled = isFistPageActive || (fetching && !isFirstPageButtonLoading);
        const isButtonLoading = fetching && isFirstPageButtonLoading;

        if (totalItems === null && page === 1) {
            return null;
        }

        return (
            <>
                <Button
                    minimal
                    disabled={isButtonDisabled}
                    loading={isButtonLoading}
                    className="mr-small"
                    icon="double-chevron-left"
                    onClick={handleChangeToFirstPage}
                >
                    Go to first page
                </Button>
                <Divider style={{ alignSelf: 'stretch' }} />
            </>
        );
    };

    const renderGoToPreviousPageButton = () => {
        const isButtonDisabled = isFistPageActive || (fetching && !isPreviousPageButtonLoading);
        const isButtonLoading = fetching && isPreviousPageButtonLoading;

        return (
            <Button
                disabled={isButtonDisabled}
                loading={isButtonLoading}
                className="ml-small"
                minimal
                icon="chevron-left"
                onClick={handleChangeToPreviousPage}
            >
                Prev
            </Button>
        );
    };

    const renderGoToNextPageButton = () => {
        const isButtonDisabled = isLastPageActive || (fetching && !isNextPageButtonLoading);
        const isButtonLoading = fetching && isNextPageButtonLoading;

        return (
            <Button
                disabled={isButtonDisabled}
                loading={isButtonLoading}
                className="ml-small"
                minimal
                rightIcon="chevron-right"
                onClick={handleChangeToNextPage}
            >
                Next
            </Button>
        );
    };

    const renderGoToLastPageButton = () => {
        const isButtonDisabled = isLastPageActive || (fetching && !isLastPageButtonLoading);
        const isButtonLoading = fetching && isLastPageButtonLoading;

        if (totalItems === null) {
            return null;
        }

        if (amountOfPages === null) {
            return null;
        }

        return (
            <Button
                disabled={isButtonDisabled}
                loading={isButtonLoading}
                className="ml-small"
                minimal
                rightIcon="double-chevron-right"
                onClick={() => handleChangeToLastPage(amountOfPages)}
            >
                Last
            </Button>
        );
    };

    const renderPageButtons = () => {
        return pages.map((p) => {
            const isPageActive = p === page;

            return (
                <Button
                    className="ml-small"
                    minimal={!isPageActive}
                    outlined={isPageActive}
                    intent={p === page ? Intent.PRIMARY : Intent.NONE}
                    key={p}
                    onClick={() => onPageChange(p)}
                >
                    {p}
                </Button>
            );
        });
    };

    return (
        <Flex align="center" {...props}>
            {renderGoToFirstPageButton()}
            {renderGoToPreviousPageButton()}
            {renderPageButtons()}
            {renderGoToNextPageButton()}
            {renderGoToLastPageButton()}
        </Flex>
    );
};

export default Pagination;
