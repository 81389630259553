import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export interface InternalQaProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & InternalQaProps;

const InternalQa: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isInRevisionAllowed, setIsInRevisionAllowed] = useState(false);
    const [isClientReviewAllowed, setIsClientReviewAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsInRevisionAllowed(
            permissions.isRoot.task
            || permissions.has(TaskPermission.STATE_TRANSITION_IN_REVISION),
        );
        setIsClientReviewAllowed(
            permissions.isRoot.task
            || permissions.has(TaskPermission.STATE_TRANSITION_CLIENT_REVIEW),
        );
    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // TODO: Render helper actions

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            const res = await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const renderInRevisionTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isInRevisionAllowed) {
            return null;
        }

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >Request revision from designer</Button>
        );
    };

    const renderClientReviewTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isClientReviewAllowed) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="geosearch"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.CLIENT_REVIEW}
                onClick={() => handleTransitionToState(TaskState.CLIENT_REVIEW)}
            >Deliver to client</Button>
        );
    };

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Heading type="h4" className="mb-1">Internal QA</Heading>
                <Flex>
                    <div className="mr-1">{renderInRevisionTransitionButton()}</div>
                    <div>{renderClientReviewTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Request is currently undergoing internal QA.</DevText>
            <DevText muted>
                Make sure that the briefing and any additional messages from the client are well-understood and
                information from brand profile is used well.
            </DevText>
        </Card>
    );
};

export default InternalQa;
