import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 9px 8px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
        background-color: #2F343C;
    }
`;

export const LeftBlock = styled.div`
    flex-shrink: 0;
`;

export const CenterBlock = styled.div`
    flex-grow: 1;
`;

export const RightBlock = styled.div`
    flex-shrink: 0;
`;
