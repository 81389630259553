import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { Endpoints } from '@data/consts';
import { $task } from '@pages/Task/store/states';
import RouterLink from '@components/RouterLink';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Card, Colors } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskCategoryInformation: React.FC<Props> = (props) => {
    const task = useStore($task);

    if (!task) {
        return null;
    }

    const taskCategory = task.category;

    const renderTaskCategoryTitle = () => {
        return (
            <div>
                <DevText muted className="mb-small">
                    Title
                </DevText>

                <RouterLink
                    color={Colors.WHITE}
                    to={Endpoints.TASK_CATEGORY.replace(':taskCategoryId', taskCategory.id)}
                    fontWeight={600}
                >
                    {taskCategory.title}
                </RouterLink>
            </div>
        );
    };

    const renderDeliverablesSummary = () => {
        return (
            <CommonInformationBlock
                className="mt-2"
                title="Summary deliverables"
                text={taskCategory.summary_deliverables}
            />
        );
    };

    const renderAverageDeliveryDays = () => {
        return (
            <CommonInformationBlock
                className="mt-2"
                title="Average delivery days"
                icon="time"
                text={taskCategory.average_delivery_days}
            />
        );
    };

    return (
        <Card {...props}>
            <Heading className="mb-2" type="h4">
                Category
            </Heading>

            {renderTaskCategoryTitle()}
            {renderDeliverablesSummary()}
            {renderAverageDeliveryDays()}
        </Card>
    );
};

export default TaskCategoryInformation;
