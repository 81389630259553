import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';
import { resetPlan, resetTaskCategories, resetTaskCategoryGroups } from './events';
import domain from './domain';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';

// Plan
export const $plan = domain.createStore<PlanResource | null>(null).reset(resetPlan);

// Task category groups
export const $taskCategoryGroups = domain
    .createStore<CollectionResource<TaskCategoryGroupListItemResource, {}> | null>(null)
    .reset(resetTaskCategoryGroups);

// Task categories
export const $taskCategories = domain
    .createStore<CollectionResource<TaskCategoryListItemResource, {}> | null>(null)
    .reset(resetTaskCategories);
