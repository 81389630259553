import { GoogleEmbedType } from '../enums';
import googleDriveIconImage from '@assets/icons/google/google_drive.png';
import googleDocsIconImage from '@assets/icons/google/google_docs.svg';
import googleSlidesIconImage from '@assets/icons/google/google_slides.svg';
import googleSheetsIconImage from '@assets/icons/google/google_sheets.svg';

const googleEmbedTypesConfiguration: {
    [key in GoogleEmbedType]: {
        icon: string;
        title: string;
        instructions: string[];
    };
} = {
    [GoogleEmbedType.FOLDERS]: {
        title: 'Drive',
        icon: googleDriveIconImage,
        instructions: [
            'Go to your Google Drive folder',
            'Press the "people" icon on the top next to the folder name',
            'On the "Get link" section press "Change"',
            'Click on "DotYeti.com" and select "Anyone with the link"',
            'Set the "viewer" option to "editor"',
            'Click "Copy link"',
            'Paste the link above"',
        ],
    },
    [GoogleEmbedType.DOCUMENT]: {
        title: 'Docs',
        icon: googleDocsIconImage,
        instructions: [
            'Go to your Google Doc file',
            'Press "File" on the top left',
            'Select "Publish to the web"',
            'At the "Link" section press "Publish"',
            'Select "Ok" to publish',
            'Copy the link',
            'Paste the link above"',
        ],
    },
    [GoogleEmbedType.PRESENTATION]: {
        title: 'Slides',
        icon: googleSlidesIconImage,
        instructions: [
            'Go to your Google Slides file',
            'Press "File" on the top left',
            'Select "Publish to the web"',
            'At the "Link" section press "Publish"',
            'Select "Ok" to publish',
            'Copy the link',
            'Paste the link above',
        ],
    },
    [GoogleEmbedType.SPREADSHEETS]: {
        title: 'Sheets',
        icon: googleSheetsIconImage,
        instructions: [
            'Go to your Google Sheets file',
            'Press "File" on the top left',
            'Select "Publish to the web"',
            'At the "Link" section press "Publish"',
            'Select "Ok" to publish',
            'Copy the link',
            'Paste the link above',
        ],
    },
};

export default googleEmbedTypesConfiguration;
