import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { openModal } from '@modals/store/events';
import { $client } from '@pages/Client/store/states';
import Flex from '@components/Flex';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Button, Card, Colors, Icon, Tooltip } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import UpdateClientMainInformationModal, {
    UpdateClientMainInformationModalProps,
} from '@app/containers/pages/Client/modals/UpdateClientMainInformationModal';
import { $permissions } from '@containers/store/states';
import { ClientUserPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = HTMLAttributes<HTMLDivElement>;

const Information: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const client = useStore($client);

    if (!client) {
        return null;
    }

    const renderEditInfoButton = () => {
        // Auth
        const isAllowed = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.INFO_UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateClientMainInformationModalProps>({
                        ModalComponent: UpdateClientMainInformationModal,
                        data: {
                            clientId: client.id,
                            firstName: client.first_name,
                            lastName: client.last_name,
                            email: client.email,
                            timezoneName: client.timezone_name,
                            website: client.website,
                            companyBusinessType: client.company_business_type,
                            companyName: client.company_name,
                            companyPosition: client.company_position,
                            phone: client.phone,
                            addressCountry: client.address_country,
                            addressPostalCode: client.address_postal_code,
                            addressCity: client.address_city,
                            addressFirstLine: client.address_first_line,
                            addressSecondLine: client.address_second_line,
                            bio: client.bio,
                        },
                    })
                }
            />
        );
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Main information</Heading>

                {renderEditInfoButton()}
            </Flex>

            {/* Email */}
            <div className="mb-2">
                <DevText muted className="mb-small">
                    <Icon className="mr-1" icon="envelope" size={16} color={Colors.GRAY2} />
                    Email
                </DevText>

                <Flex className="mb-2" align="center" justify="space-between">
                    <DevText>{client.email}</DevText>

                    {client.verified_at && (
                        <Tooltip content={<DevText>Verified</DevText>}>
                            <Icon icon="endorsed" size={16} />
                        </Tooltip>
                    )}
                </Flex>
            </div>

            {/* Country */}
            <CommonInformationBlock
                className="mb-2"
                icon="map-marker"
                title="Country"
                text={countriesData[client.address_country]?.countryName}
            />

            {/* Timezone */}
            <CommonInformationBlock className="mb-2" icon="time" title="Timezone" text={client.timezone_name} />

            {/* Website */}
            <CommonInformationBlock className="mb-2" icon="globe-network" title="Website" text={client.website} />

            {/* Type of business */}
            <CommonInformationBlock
                className="mb-2"
                icon="heat-grid"
                title="Type of business"
                text={client.company_business_type}
            />

            {/* Phone number */}
            <CommonInformationBlock className="mb-2" icon="phone" title="Phone number" text={client.phone} />

            {/* City */}
            <CommonInformationBlock className="mb-2" icon="home" title="City" text={client.address_city} />

            {/* Address 1 */}
            <CommonInformationBlock className="mb-2" icon="home" title="Address 1" text={client.address_first_line} />

            {/* Address 2 */}
            <CommonInformationBlock className="mb-2" icon="home" title="Address 2" text={client.address_second_line} />

            {/* About */}
            <CommonInformationBlock icon="user" title="About" text={client.bio} />
        </Card>
    );
};

export default Information;
