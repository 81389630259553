import ConfirmationPopover from '@app/components/ConfirmationPopover';
import UpdateTaskMessageTemplateCategoryModal, {
    UpdateTaskMessageTemplateCategoryModalProps,
} from '@app/containers/pages/TaskMessageTemplates/modals/UpdateTaskMessageTemplateCategoryModal';
import { Button, Classes, Colors, Intent, Menu, MenuItem, Popover, Tag } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { taskMessageTemplateCategoriesApi } from '@pages/TaskMessageTemplates/store/apis';
import {
    removeTaskMessageTemplateCategory,
    restoreTaskMessageTemplateCategory,
} from '@pages/TaskMessageTemplates/store/effects';
import { setTaskMessageTemplateCategoryId } from '@pages/TaskMessageTemplates/store/events';
import {
    TaskMessageTemplateCategoryListItemResource,
} from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplateCategory/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { StyledCategoryListItem } from './styled';
import { $permissions } from '@containers/store/states';
import { TaskMessageTemplateCategoryPermission } from 'dy-frontend-permissions/lib/permission';

export interface TaskMessageTemplateCategoryListItemProps {
    active: boolean;
    category: TaskMessageTemplateCategoryListItemResource | null;
}

export type Props = TaskMessageTemplateCategoryListItemProps & HTMLAttributes<HTMLDivElement>;

const TaskMessageTemplateCategoryListItem: React.FC<Props> = ({ active, category }) => {
    const permission = useStore($permissions);

    const isRemoving = useStore(removeTaskMessageTemplateCategory.pending);
    const isRestoring = useStore(restoreTaskMessageTemplateCategory.pending);

    const renderMoreButton = () => {
        if (!category) {
            return null;
        }

        // Auth
        const isUpdateAllowed = permission.isRoot.taskMessageTemplateCategory || permission.has(TaskMessageTemplateCategoryPermission.UPDATE);
        const isRemoveAllowed = permission.isRoot.taskMessageTemplateCategory || permission.has(TaskMessageTemplateCategoryPermission.ARCHIVE);
        const isRestoreAllowed = permission.isRoot.taskMessageTemplateCategory || permission.has(TaskMessageTemplateCategoryPermission.RESTORE);
        const isAnyActionAllowed = (
            isUpdateAllowed || isRemoveAllowed || isRestoreAllowed
        );
        if (!isAnyActionAllowed) {
            return null;
        }

        const renderTaskMessageTemplateCategoryControls = () => {
            const renderUpdateTaskMessageTemplateCategoryMenuItem = () => {
                if (!isUpdateAllowed) {
                    return null;
                }

                const handleOpenUpdateTaskMessageTemplateCategoryModal = () => {
                    openModal<UpdateTaskMessageTemplateCategoryModalProps>({
                        ModalComponent: UpdateTaskMessageTemplateCategoryModal,
                        data: {
                            taskMessageTemplateCategoryId: category.id,
                            title: category.title,
                            color: category.color,
                        },
                    });
                };
                return (
                    <MenuItem
                        icon="edit"
                        text="Edit"
                        disabled={isRemoving}
                        onClick={handleOpenUpdateTaskMessageTemplateCategoryModal}
                    />
                );
            };

            const renderRemoveTaskMessageTemplateCategoryMenuItem = () => {
                if (category.archived_at) {
                    // Task message template category was already archived
                    return null;
                }

                if (!isRemoveAllowed) {
                    return null;
                }

                const handleRemoveTaskMessageTemplateCategory = async () => {
                    try {
                        await removeTaskMessageTemplateCategory(category.id);
                        taskMessageTemplateCategoriesApi.remove({ taskMessageTemplateCategoryId: category.id });
                    } catch (e) {
                        // TODO: handle error
                        console.error(e);
                    }
                };

                return (
                    <ConfirmationPopover
                        fill
                        title="Are you sure you want to remove request message template category?"
                        description="When confirmed, request message template category will be removed"
                        actions={[
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS}
                                onClick={handleRemoveTaskMessageTemplateCategory}
                            >
                                Yes, remove template
                            </Button>,
                        ]}
                    >
                        <MenuItem
                            shouldDismissPopover={false}
                            disabled={isRemoving}
                            intent={Intent.DANGER}
                            text="Remove"
                            icon="trash"
                        />
                    </ConfirmationPopover>
                );
            };

            const renderRestoreTaskMessageTemplateCategoryMenuItem = () => {
                if (!category.archived_at) {
                    // Task message template category was NOT archived
                    return null;
                }

                if (!isRestoreAllowed) {
                    return null;
                }

                const handleRestoreTaskMessageTemplateCategory = async () => {
                    try {
                        await restoreTaskMessageTemplateCategory(category.id);
                        taskMessageTemplateCategoriesApi.restore({ taskMessageTemplateCategoryId: category.id });
                    } catch (e) {
                        // TODO: handle error
                        console.error(e);
                    }
                };

                return (
                    <ConfirmationPopover
                        fill
                        title="Are you sure you want to restore request message template category?"
                        description="When confirmed, request message template category will be restored"
                        actions={[
                            <Button
                                intent={Intent.SUCCESS}
                                className={Classes.POPOVER_DISMISS}
                                onClick={handleRestoreTaskMessageTemplateCategory}
                            >
                                Yes, restore template
                            </Button>,
                        ]}
                    >
                        <MenuItem
                            shouldDismissPopover={false}
                            disabled={isRestoring}
                            intent={Intent.SUCCESS}
                            icon="confirm"
                            text="Restore"
                        />
                    </ConfirmationPopover>
                );
            };

            return (
                <Menu>
                    {renderUpdateTaskMessageTemplateCategoryMenuItem()}
                    {renderRemoveTaskMessageTemplateCategoryMenuItem()}
                    {renderRestoreTaskMessageTemplateCategoryMenuItem()}
                </Menu>
            );
        };

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Popover content={renderTaskMessageTemplateCategoryControls()}>
                    <Button minimal icon="more" />
                </Popover>
            </div>
        );
    };

    if (category) {
        return (
            <StyledCategoryListItem $active={active} onClick={() => setTaskMessageTemplateCategoryId(category.id)}>
                <Flex fullWidth justify="space-between" align="center">
                    <Flex className="mr-small" align="center">
                        {category.color && <Circle className="mr-1" size="16px" color={category.color} />}
                        <DevText className="mr-small">{category.title}</DevText>

                        {category.archived_at && (
                            <Tag minimal className="mt-small" intent={Intent.DANGER}>
                                Archived
                            </Tag>
                        )}
                    </Flex>

                    {renderMoreButton()}
                </Flex>
            </StyledCategoryListItem>
        );
    } else {
        return (
            <StyledCategoryListItem $active={active} onClick={() => setTaskMessageTemplateCategoryId(null)}>
                <Flex align="center">
                    <Circle className="mr-1" size="16px" color="none" borderColor={Colors.GRAY2} />
                    <DevText className="mr-small">All</DevText>
                </Flex>
            </StyledCategoryListItem>
        );
    }
};

export default TaskMessageTemplateCategoryListItem;
