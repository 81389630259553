import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { TaskCategoryListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { TaskCategoryFeaturedBlockRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/refs';
import { TaskCategoryFeaturedBlockResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryFeaturedBlock/resources';
import domain from './domain';

// Fetch list of featured block
export const fetchTaskCategoryFeaturedBlocks = domain.createEffect<
    { pagination: OffsetPaginationInput },
    CollectionResource<TaskCategoryFeaturedBlockResource, {}>
>({
    name: 'fetchTaskCategoryFeaturedBlocks',
    handler: async ({ pagination }) => {
        return await repository.taskCategoryFeaturedBlock().getList(pagination);
    },
});

// Fetch task categories
export const fetchTaskCategories = domain.createEffect<void, CollectionResource<TaskCategoryListItemResource, {}>>({
    name: 'fetchTaskCategories',
    handler: async () => {
        return await repository.taskCategory().get();
    },
});

// Remove task category featured block
export const removeTaskCategoryFeaturedBlock = domain.createEffect<ID, TaskCategoryFeaturedBlockRef>({
    name: 'removeTaskCategoryFeaturedBlock',
    handler: async (taskCategoryFeaturedBlockId) => {
        return await repository.taskCategoryFeaturedBlock().archive(taskCategoryFeaturedBlockId);
    },
});

// Restore task category featured block
export const restoreTaskCategoryFeaturedBlock = domain.createEffect<ID, TaskCategoryFeaturedBlockRef>({
    name: 'restoreTaskCategoryFeaturedBlock',
    handler: async (taskCategoryFeaturedBlockId) => {
        return await repository.taskCategoryFeaturedBlock().restore(taskCategoryFeaturedBlockId);
    },
});
