import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringNumericValueMaxValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { FormField } from '@app/hooks/validation/types';
import { Button, Card, Divider, FormGroup, Intent } from '@blueprintjs/core';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import { SubscriptionPauseUnusedPeriodType } from '@data/enums';
import { ModalProps } from '@modals/types';
import { UpdateSubscriptionPauseInput } from 'dy-frontend-http-repository/lib/modules/Subscription/inputs';
import React from 'react';
import { subscriptionPauseUnusedPeriodTypeInformation } from './consts';
import { pauseSubscription } from './store/effects';
import Flex from '@app/components/Flex';
import moment from 'moment';
import { DateInput3 } from '@blueprintjs/datetime2';
import Heading from '@components/Heading';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';

export interface PauseSubscriptionModalProps {
    subscriptionId: ID;
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<PauseSubscriptionModalProps>;

const subscriptionPauseReleaseAtValidators = [getStringRequiredValidator()];
const subscriptionPauseUnusedPeriodTypeValidators = [getStringRequiredValidator()];
const subscriptionPauseUnusedPeriodValidators = [
    getStringRequiredValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 1 }),
    getStringNumericValueMaxValidator({ maxValue: 1095 }),
];

const PauseSubscriptionModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const subscriptionPauseReleaseAt = useCustomSelectFormField<Date | null>({
        id: 'subscription-pause-release-at',
        initialValue: null,
        formatValue: (date) => (date ? date.toString() : ''),
        validators: subscriptionPauseReleaseAtValidators,
    });

    const subscriptionPauseUnusedPeriodType = useCustomSelectFormField<string | null>({
        id: 'subscription-pause-unused-period-type',
        initialValue: SubscriptionPauseUnusedPeriodType.CALCULATE_AUTOMATICALLY,
        formatValue: (type) => type ?? '',
        validators: subscriptionPauseUnusedPeriodTypeValidators,
    });

    const subscriptionPauseUnusedPeriod = useTextFormField({
        id: 'subscription-pause-unused-period',
        initialValue: '',
        validators: subscriptionPauseUnusedPeriodValidators,
    });

    const getFormFields = () => {
        const defaultFormFields: FormField[] = [subscriptionPauseReleaseAt, subscriptionPauseUnusedPeriodType];

        if (subscriptionPauseUnusedPeriodType.value === SubscriptionPauseUnusedPeriodType.CUSTOM) {
            defaultFormFields.push(subscriptionPauseUnusedPeriod);
        }

        return defaultFormFields;
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            try {
                // Create input
                const pauseSubscriptionInput: UpdateSubscriptionPauseInput = {
                    release_at: moment(subscriptionPauseReleaseAt.value!).utc().format(),
                };

                if (subscriptionPauseUnusedPeriodType.value === SubscriptionPauseUnusedPeriodType.CUSTOM) {
                    // Get unused period as number
                    const unusedPeriodInDays = parseInt(subscriptionPauseUnusedPeriod.value!);

                    // Get seconds in a day
                    const amountOfSecondsInDay = 86400;

                    // Convert unused period from days to seconds
                    const unusedPeriodInSeconds = unusedPeriodInDays * amountOfSecondsInDay;
                    pauseSubscriptionInput.unused_period = unusedPeriodInSeconds;
                }

                // Pause subscription
                const subscriptionRef = await pauseSubscription({
                    subscriptionId: data!.subscriptionId,
                    input: pauseSubscriptionInput,
                });

                // Refetch if refetch function provided
                if (data?.refetchOnSuccess) {
                    const refetchResponse = await data!.refetchOnSuccess();
                }

                return { response: subscriptionRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderReleaseAtDateInput = () => {
        const handleChangeUpdateAnchorAt = (newUpdateAnchorAt: string | null) => {
            subscriptionPauseReleaseAt.handleChange(moment(newUpdateAnchorAt).toDate());
        };

        return (
            <FormGroup
                label="Resume date"
                intent={!!subscriptionPauseReleaseAt.error ? Intent.DANGER : Intent.NONE}
                helperText={subscriptionPauseReleaseAt.error ?? ''}
            >
                <DateInput3
                    fill
                    highlightCurrentDay
                    placeholder="Pick subscription resume date"
                    value={subscriptionPauseReleaseAt.value ? moment(subscriptionPauseReleaseAt.value).format() : null}
                    popoverProps={{ usePortal: false }}
                    inputProps={{
                        intent: !!subscriptionPauseReleaseAt.error ? Intent.DANGER : Intent.NONE,
                        leftIcon: 'calendar',
                    }}
                    minDate={moment().add(1, 'days').toDate()}
                    initialMonth={moment().add(1, 'days').toDate()}
                    dayPickerProps={{ firstWeekContainsDate: 1 }}
                    formatDate={(date) => moment(date).format('D MMM, YYYY')}
                    parseDate={(str) => new Date(str)}
                    onChange={handleChangeUpdateAnchorAt}
                />
            </FormGroup>
        );
    };

    const renderUnusedPeriodInput = () => {
        if (subscriptionPauseUnusedPeriodType.value !== SubscriptionPauseUnusedPeriodType.CUSTOM) {
            return null;
        }

        return (
            <InputFormField
                field={subscriptionPauseUnusedPeriod}
                formGroupProps={{ label: 'Unused period in days' }}
                inputProps={{ placeholder: 'Enter unused period in days' }}
            />
        );
    };

    const renderContent = () => {
        const unusedPeriodTypeList = [SubscriptionPauseUnusedPeriodType.CALCULATE_AUTOMATICALLY];
        const isManualPeriodAllowed =
            permissions.isRoot.subscription || permissions.has(SubscriptionPermission.PAUSE_CUSTOM);
        if (isManualPeriodAllowed) {
            unusedPeriodTypeList.push(SubscriptionPauseUnusedPeriodType.CUSTOM);
        }

        return (
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Pause subscription</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <form onSubmit={form.handleFormSubmit}>
                    {renderReleaseAtDateInput()}

                    <Divider className="mb-2 mt-2" />

                    <SelectFormField
                        field={subscriptionPauseUnusedPeriodType}
                        items={unusedPeriodTypeList}
                        formGroupProps={{ label: 'Unused period' }}
                        selectProps={{ selectButtonProps: { placeholder: 'Select unused period type' } }}
                        itemToReadableFormatter={(item) => subscriptionPauseUnusedPeriodTypeInformation[item]}
                    />

                    {renderUnusedPeriodInput()}

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button disabled={form.hasFieldErrors} loading={form.isSubmitting} type="submit">
                            Pause
                        </Button>
                    </Flex>
                </form>
            </Card>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            {renderContent()}
        </Overlay>
    );
};

export default PauseSubscriptionModal;
