import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { PlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/types';
import {
    platformFiltersInformation,
    taskClientFeedbackNegativeTypeInformation,
} from '@pages/TaskClientFeedback/consts';
import { PlatformTaskClientFeedbackFilterType } from '@pages/TaskClientFeedback/enums';
import { $isQueryHydrationFinished } from '@pages/TaskClientFeedback/store/states';
import { setPlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';
import Flex from '@components/Flex';
import { TaskClientFeedbackNegativeType } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/enums';

export interface TaskStatesPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskClientFeedbackData: PlatformTaskClientFeedbackFilterData;
    onRemove: () => void;
}

export type Props = TaskStatesPlatformFilterProps;

const TaskStatesPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformTaskClientFeedbackData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedNegativeScores, setSelectedNegativeScores] = useState<
        PlatformTaskClientFeedbackFilterData['negative']
    >(platformTaskClientFeedbackData.negative);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedNegativeScores(platformTaskClientFeedbackData.negative);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskClientFeedbackData.negative.length === 0) {
            setSelectedNegativeScores([]);
        }
    }, [platformTaskClientFeedbackData.negative]);

    const getFilterTagLabel = () => {
        if (selectedNegativeScores.length === 0) return '';

        return selectedNegativeScores
            .map((key, index) => {
                if (index === selectedNegativeScores.length - 1) {
                    // Last element
                    return `${taskClientFeedbackNegativeTypeInformation[key].title}`;
                }

                return `${taskClientFeedbackNegativeTypeInformation[key].title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedNegativeScores.length > 0) {
            // At least 1 task client feedback negative type selected
            return 'Negative score:';
        }

        return 'Negative score';
    };

    const handleRemoveFilter = () => {
        if (selectedNegativeScores.length !== 0) {
            // At least 1 task state was selected

            setSelectedNegativeScores([]);

            if (platformTaskClientFeedbackData.negative.length !== 0) {
                // Reset task states to empty array
                setPlatformTaskClientFeedbackFilterData({ ...platformTaskClientFeedbackData, negative: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE].isPinned) {
            // Platform task state filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (key: PlatformTaskClientFeedbackFilterData['negative'][number]) => {
        if (selectedNegativeScores.includes(key)) {
            // Task client feedback negative type is already checked
            setSelectedNegativeScores(selectedNegativeScores.filter((state) => state !== key));
        } else {
            // Task client feedback negative type is NOT checked yet
            setSelectedNegativeScores([...selectedNegativeScores, key]);
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedNegativeScores) !== JSON.stringify(platformTaskClientFeedbackData.negative)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskClientFeedbackFilterData({
                ...platformTaskClientFeedbackData,
                negative: selectedNegativeScores,
            });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE].isPinned) {
            return;
        }

        if (selectedNegativeScores.length === 0) {
            onRemove();
        }
    };

    const renderRow = (key: PlatformTaskClientFeedbackFilterData['negative'][number]) => {
        return (
            <li className="mb-1" key={key}>
                <Flex justify="space-between" align="center">
                    <Checkbox
                        checked={selectedNegativeScores.includes(key)}
                        className="mr-2"
                        label={taskClientFeedbackNegativeTypeInformation[key].title}
                        onClick={() => handleCheckboxClick(key)}
                    />
                </Flex>
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskClientFeedbackFilterData['negative'][number]>
            isOpen={isOpen}
            removable={selectedNegativeScores.length > 0}
            title="Negative score"
            position={Position.BOTTOM_LEFT}
            list={Object.values(TaskClientFeedbackNegativeType)}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskStatesPlatformFilter;
