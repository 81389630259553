import repository from 'dy-frontend-http-repository/lib/repository';
import {
    ChangeOpenTaskPositionWithinSameQueueInput,
    CreateTaskDraftInput,
} from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';
import { OpenTaskListOwnerFilterInput } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/input';
import { OpenTaskListResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';

// Fetches open task list by owner ID
export const fetchClientOpenTaskList = domain.createEffect<
    { filter: OpenTaskListOwnerFilterInput },
    OpenTaskListResource
>({
    name: 'fetchClientOpenTaskList',
    handler: async ({ filter }) => {
        return await repository.openTaskList().getByOwnerUser(filter);
    },
});

// Create client task
export const createClientTask = domain.createEffect<CreateTaskDraftInput, TaskRef>({
    name: 'createClientTask',
    handler: async (input) => {
        return await repository.task().create(input);
    },
});

// Change open task position within same queue
export const changeTaskPositionWithinSameQueue = domain.createEffect<
    { taskId: ID; input: ChangeOpenTaskPositionWithinSameQueueInput },
    TaskRef
>({
    name: 'changeTaskPositionWithinSameQueue',
    handler: async ({ taskId, input }) => {
        return await repository.task().changeOpenTaskPositionWithinSameQueue(taskId, input);
    },
});