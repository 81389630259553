import { useCustomSelectFormField, useForm, useRichTextFormField, useTextFormField } from '@app/hooks';
import {
    getRichStringRequiredValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import richEditorPlugins from '@components/RichEditor/plugins';
import RichEditorFormField from '@components/RichEditorFormField';
import Select from '@components/Select';
import TextAreaFormField from '@components/TextAreaFormField';
import { ModalProps } from '@modals/types';
import { UpdateTaskMessageTemplateInput } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/inputs';
import { TaskMessageTemplateRef } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/refs';
import { TaskMessageTemplateCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskMessageTemplate/resources';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import { RichTextUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React from 'react';
import TaskMessageTemplateVariablesTable from '../../components/TaskMesageTemplateVariablesTable';
import { taskMessageTemplatesApi } from '../../store/apis';
import { updateTaskMessageTemplate } from '../../store/effects';
import { $taskMessageTemplateCategories } from '../../store/states';

export interface UpdateTaskMessageTemplateModalProps {
    taskMessageTemplateCategoryId: ID;
    taskMessageTemplateId: ID;
    title: string;
    summary?: string;
    content?: string;
}

type Props = ModalProps<UpdateTaskMessageTemplateModalProps>;

const taskMessageTemplateTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const taskMessageTemplateTaskCategoryIdValidators = [getStringRequiredValidator()];
const taskMessageTemplateSummaryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];
const taskMessageTemplateContentValidators = [getRichStringRequiredValidator()];

const UpdateTaskMessageTemplateModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskMessageTemplateCategories = useStore($taskMessageTemplateCategories);

    const taskMessageTemplateTitle = useTextFormField({
        id: 'task-message-template-title',
        validators: taskMessageTemplateTitleValidators,
        initialValue: data?.title ?? '',
    });

    const taskMessageTemplateTaskCategoryId = useCustomSelectFormField<TaskMessageTemplateCategoryResource | null>({
        id: 'task-message-template-task-category-id',
        validators: taskMessageTemplateTaskCategoryIdValidators,
        initialValue:
            taskMessageTemplateCategories?.items.find(
                (category) => category.id === data?.taskMessageTemplateCategoryId
            ) ?? null,
        formatValue: (value) => value?.title ?? '',
    });

    const taskMessageTemplateSummary = useTextFormField({
        id: 'task-message-template-summary',
        validators: taskMessageTemplateSummaryValidators,
        initialValue: data?.summary ?? '',
    });

    const taskMessageTemplateContent = useRichTextFormField({
        id: 'task-message-template-content',
        validators: taskMessageTemplateContentValidators,
        initialValue: data?.content
            ? new RichTextFormat(RichTextUtils.convertHTMLToEditorState(data?.content), richEditorPlugins).toString()
            : '',
    });

    const form = useForm<TaskMessageTemplateRef>({
        fields: [
            taskMessageTemplateTitle,
            taskMessageTemplateTaskCategoryId,
            taskMessageTemplateSummary,
            taskMessageTemplateContent,
        ],
        apiCall: async () => {
            try {
                // Create "update task message template" input
                const updateTaskMessageTemplateInput: UpdateTaskMessageTemplateInput = {
                    task_message_template_category_id: taskMessageTemplateTaskCategoryId.value!.id,
                    title: taskMessageTemplateTitle.value,
                    summary: taskMessageTemplateSummary.value,
                    content: RichTextUtils.convertEditorStateToHTML(
                        new RichTextFormat(taskMessageTemplateContent.value, richEditorPlugins).getState()
                    ),
                };

                // Find task message template category
                const foundTaskMessageTemplateCategory = taskMessageTemplateCategories!.items.find(
                    (category) => category.id === taskMessageTemplateTaskCategoryId.value!.id
                );

                if (!foundTaskMessageTemplateCategory) {
                    throw new Error('Task message template category was not found');
                }

                const taskMessageTemplateRef = await updateTaskMessageTemplate({
                    id: data?.taskMessageTemplateId ?? '0',
                    input: updateTaskMessageTemplateInput,
                });

                taskMessageTemplatesApi.update({
                    taskMessageTemplateId: taskMessageTemplateRef.id,
                    taskMessageCategory: foundTaskMessageTemplateCategory,
                    input: updateTaskMessageTemplateInput,
                });

                return { response: taskMessageTemplateRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    // Data was not provided
    if (!data) {
        closeModal?.();
        return null;
    }

    // Task message template categories were not fetched yet
    if (!taskMessageTemplateCategories) {
        return null;
    }

    const renderCategoriesFormFieldSelect = () => {
        const renderItem = (item: TaskMessageTemplateCategoryResource, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.id === taskMessageTemplateTaskCategoryId.value?.id;

            return <MenuItem active={isMenuItemActive} key={item.id} text={item.title} onClick={handleClick} />;
        };

        return (
            <FormGroup
                label="Category"
                intent={!!taskMessageTemplateTaskCategoryId.error ? Intent.DANGER : Intent.NONE}
                helperText={taskMessageTemplateTaskCategoryId.error}
            >
                <Select<TaskMessageTemplateCategoryResource>
                    disabled={taskMessageTemplateCategories.items.length === 0}
                    items={taskMessageTemplateCategories.items}
                    itemRenderer={renderItem}
                    onItemSelect={taskMessageTemplateTaskCategoryId.handleChange}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                        onClose: taskMessageTemplateTaskCategoryId.handleClose,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        icon: 'add',
                        placeholder: 'Select category',
                        text: taskMessageTemplateTaskCategoryId.value?.title,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update message template</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid className="mb-2" container>
                        <Grid lg={12} xs={12}>
                            <InputFormField
                                field={taskMessageTemplateTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter message template title' }}
                            />
                        </Grid>

                        <Grid lg={12} xs={12}>
                            {renderCategoriesFormFieldSelect()}
                        </Grid>

                        <Grid lg={12}>
                            <TextAreaFormField
                                field={taskMessageTemplateSummary}
                                formGroupProps={{ label: 'Short description' }}
                                textAreaProps={{ placeholder: 'Enter a short description' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            <RichEditorFormField
                                field={taskMessageTemplateContent}
                                formGroupProps={{ label: 'Content' }}
                                richEditorProps={{ placeholder: 'Enter message template content' }}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>

                <Heading className="mb-1" type="h5">
                    Template Variables
                </Heading>
                <TaskMessageTemplateVariablesTable />
            </Card>
        </Overlay>
    );
};

export default UpdateTaskMessageTemplateModal;
