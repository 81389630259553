import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskRef } from 'dy-frontend-http-repository/lib/modules/Task/refs';
import domain from './domain';

// Unlock task queue automation
export const unlockTaskQueueAutomation = domain.createEffect<ID, TaskRef>({
    name: 'unlockTaskQueueAutomation',
    handler: async (taskId) => {
        return await repository.task().unlockTaskQueueAutomation(taskId);
    },
});