import { closeModal } from '@app/containers/modals/store/events';
import { $stack } from '@modals/store/states';
import { useStore } from 'effector-react';
import React from 'react';

const ModalProvider: React.FC = () => {
    const modalStack = useStore($stack);

    return (
        <>
            {modalStack.map(({ id, data, ModalComponent }, index) => {
                return (
                    <ModalComponent
                        style={{ zIndex: 20 + index }}
                        data={data}
                        key={id}
                        closeModal={() => closeModal(id)}
                    />
                );
            })}
        </>
    );
};

export default ModalProvider;
