import { $plan, $taskCategories, $taskCategoryGroups } from './states';
import { fetchPlan, fetchTaskCategories, fetchTaskCategoryGroups } from './effects';

// Fetch plan
$plan.on(fetchPlan.doneData, (_, data) => data);

// Fetch task category groups
$taskCategoryGroups.on(fetchTaskCategoryGroups.doneData, (_, data) => data);

// Fetch task categories
$taskCategories.on(fetchTaskCategories.doneData, (_, data) => data);
