import { Button, Card, Elevation, Icon, InputGroup } from '@blueprintjs/core';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { PlanResource, PriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes } from 'react';

export interface SelectedSubscriptionPriceProps {
    quantity: string;
    price: PriceResource;
    plan?: PlanResource;
    onChangeQuantity?: (newQuantity: string) => void;
    onRemoveQuantity?: () => void;
}

export type Props = HTMLAttributes<HTMLDivElement> & SelectedSubscriptionPriceProps;

const SelectedSubscriptionPrice: React.FC<Props> = ({
    price,
    plan,
    quantity,
    onChangeQuantity,
    onRemoveQuantity,
    ...props
}) => {
    if (!plan) {
        return null;
    }

    // Get price recurring interval
    const priceRecurringInterval = PriceUtils.getRecurringInterval(
        price.recurring_interval,
        price.recurring_interval_count
    );

    // Get price title
    const priceRecurringIntervalLabel = `${PriceUtils.formatPrice({
        price: price.unit_amount,
        shouldDisplayCents: true,
    })} / ${priceRecurringInterval}`;

    return (
        <Card key={price.id} elevation={Elevation.ONE} {...props}>
            <Flex align="center" justify="space-between">
                <div>
                    <Flex align="center">
                        <Icon className="mr-1" color={plan.color} icon="box" />
                        <DevText color={plan.color}>{plan.title}</DevText>
                    </Flex>

                    <DevText muted>{priceRecurringIntervalLabel}</DevText>
                </div>

                <Flex align="center">
                    <DevText muted className="mr-1">
                        Quantity:
                    </DevText>
                    <InputGroup
                        disabled={!onChangeQuantity}
                        className="mr-1"
                        width={50}
                        value={`${quantity}`}
                        onChange={(e) => onChangeQuantity && onChangeQuantity(e.target.value)}
                    />
                    {onRemoveQuantity && <Button minimal icon="cross" onClick={onRemoveQuantity} />}
                </Flex>
            </Flex>
        </Card>
    );
};

export default SelectedSubscriptionPrice;
