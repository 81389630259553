import { GetValidatorFunction } from '@app/hooks/validation/types';

const defaultMaxArrayLength = 20;
const getDefaultArrayLengthValueWentBeyondMaxValueMessage = (maxArrayLength: number) =>
    `Maximum amount of items is ${maxArrayLength}`;

const getArrayMaxLengthValidator: GetValidatorFunction<
    { maxArrayLength: number; arrayLengthValueWentBeyondMaxValueMessageFormatter?: (maxArrayLength: number) => string },
    unknown[]
> = (
    options = {
        maxArrayLength: defaultMaxArrayLength,
        arrayLengthValueWentBeyondMaxValueMessageFormatter: getDefaultArrayLengthValueWentBeyondMaxValueMessage,
    }
) => {
    const { maxArrayLength, arrayLengthValueWentBeyondMaxValueMessageFormatter } = options;

    if (maxArrayLength <= 0) {
        throw new Error(`Validator getArrayMinLengthValidator expects positive value, but got ${maxArrayLength}`);
    }

    return async (value) => {
        if (value.length <= maxArrayLength) {
            // Passed validation
            return null;
        } else {
            // Did NOT pass validation
            if (arrayLengthValueWentBeyondMaxValueMessageFormatter) {
                return arrayLengthValueWentBeyondMaxValueMessageFormatter(maxArrayLength);
            } else {
                return getDefaultArrayLengthValueWentBeyondMaxValueMessage(maxArrayLength);
            }
        }
    };
};

export default getArrayMaxLengthValidator;
