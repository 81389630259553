import Flex from '@app/components/Flex';
import Grid from '@app/components/Grid';
import Heading from '@app/components/Heading';
import NonIdealState from '@app/components/NonIdealState';
import { openModal } from '@app/containers/modals/store/events';
import { $task } from '@app/containers/pages/Task/store/states';
import { BreadcrumbProps, Breadcrumbs, Button, ButtonGroup, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TaskDeliverableDirectoryListItem from '../../components/TaskDeliverableDirectoryListItem';
import TaskDeliverableListGridView from '../../components/TaskDeliverableListGridView';
import TaskDeliverableListView from '../../components/TaskDeliverableListView';
import { $currentPathLocation, $taskDeliverablesTree } from '../../store/states';
import { TaskDeliverablesTreeView } from './enums';
import CreateTaskDeliverableFileModal, {
    CreateTaskDeliverableFileModalProps,
} from './modals/CreateTaskDeliverableFileModal';
import CreateTaskDeliverableGoogleEmbedModal, {
    CreateTaskDeliverableGoogleEmbedModalProps,
} from './modals/CreateTaskDeliverableGoogleEmbedModal';
import CreateTaskDeliverableTextModal, {
    CreateTaskDeliverableTextModalProps,
} from './modals/CreateTaskDeliverableTextModal';
import UpsertTaskDeliverableDirectoryModal, {
    UpsertTaskDeliverableDirectoryModalProps,
} from './modals/UpsertTaskDeliverableDirectoryModal';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { TaskDeliverablePermission } from 'dy-frontend-permissions/lib/permission';
import { rootTaskDeliverablesPathLabel } from '@app/containers/pages/Task/consts';

const TaskDeliverablesAll: React.FC = () => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const task = useStore($task);

    const [searchParams, setSearchParams] = useSearchParams();

    const currentPathLocation = useStore($currentPathLocation);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);
    const [taskDeliverablesTreeView, setTaskDeliverablesTreeView] = useState<TaskDeliverablesTreeView>(
        TaskDeliverablesTreeView.GRID,
    );

    const [isCreateAllowed, setIsCreateAllowed] = useState(false);
    const [isApproveAllowed, setIsApproveAllowed] = useState(false);
    const [isArchiveAllowed, setIsArchiveAllowed] = useState(false);
    const [isRestoreAllowed, setIsRestoreAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission set
        if (permissions.isRoot.taskDeliverable) {
            setIsCreateAllowed(true);
            setIsApproveAllowed(true);
            setIsArchiveAllowed(true);
            setIsRestoreAllowed(true);
            return;
        }

        // Precalculate if current user is assigned to a task
        const isAssigned = task.participants.find(p => p.user.id === me.user.id) !== undefined;

        // Permission: create
        setIsCreateAllowed(
            permissions.has(TaskDeliverablePermission.CREATE)
            || (
                isAssigned
                && permissions.has(TaskDeliverablePermission.CREATE_MOD_TASK_ASSIGNED)
            ),
        );

        // Permission: approve
        setIsApproveAllowed(
            permissions.has(TaskDeliverablePermission.APPROVE)
            || (
                isAssigned
                && permissions.has(TaskDeliverablePermission.APPROVE_MOD_TASK_ASSIGNED)
            ),
        );

        // Permission: archive
        setIsArchiveAllowed(
            permissions.has(TaskDeliverablePermission.ARCHIVE)
            || (
                isAssigned
                && permissions.has(TaskDeliverablePermission.ARCHIVE_MOD_TASK_ASSIGNED)
            ),
        );

        // Permission: restore
        setIsRestoreAllowed(
            permissions.has(TaskDeliverablePermission.RESTORE)
            || (
                isAssigned
                && permissions.has(TaskDeliverablePermission.RESTORE_MOD_TASK_ASSIGNED)
            ),
        );

    }, [task, permissions, me]);

    if (!task || !taskDeliverablesTree) {
        return null;
    }

    const handleCreateFileDeliverable = () => {
        openModal<CreateTaskDeliverableFileModalProps>({
            ModalComponent: CreateTaskDeliverableFileModal,
            data: { taskId: task.id },
        });
    };

    const handleCreateGoogleEmbedDeliverable = () => {
        openModal<CreateTaskDeliverableGoogleEmbedModalProps>({
            ModalComponent: CreateTaskDeliverableGoogleEmbedModal,
            data: { taskId: task.id },
        });
    };

    const handleCreateTextDeliverables = () => {
        openModal<CreateTaskDeliverableTextModalProps>({
            ModalComponent: CreateTaskDeliverableTextModal,
            data: { taskId: task.id },
        });
    };

    const handleCreateDirectory = () => {
        openModal<UpsertTaskDeliverableDirectoryModalProps>({
            ModalComponent: UpsertTaskDeliverableDirectoryModal,
            data: { directoryPath: currentPathLocation },
        });
    };

    const renderFileTreePathBreadcrumbs = () => {
        const breadcrumbItems: BreadcrumbProps[] = [];

        if (currentPathLocation === '/') {
            // Current path location is "/"
            return null;
        }

        // Add breadcrumb for "home" directory
        breadcrumbItems.push({ text: rootTaskDeliverablesPathLabel, onClick: () => setSearchParams({ path: '/' }) });

        // Add directories for non "home" directory
        const parsedCurrentPathLocation = currentPathLocation.split('/');

        // /to/folder

        let previousPathRelativeToCurrentDirectory = '';

        for (let i = 1; i < parsedCurrentPathLocation.length; i++) {
            const directory = parsedCurrentPathLocation[i];

            // Save previous path relative to current directory in order to form full path location
            previousPathRelativeToCurrentDirectory += `/${directory}`;

            // Because of js closures we have to save previousPathRelativeToCurrentDirectory whenever it's at i position of the cycle
            const previousPathRelativeToCurrentDirectoryAtCycleStep = previousPathRelativeToCurrentDirectory;

            breadcrumbItems.push({
                text: directory,
                onClick: () => setSearchParams({ path: `${previousPathRelativeToCurrentDirectoryAtCycleStep}` }),
            });
        }

        return <Breadcrumbs className="mb-1" items={breadcrumbItems} />;
    };

    const renderDirectoriesAtPathLocation = () => {
        const currentPathLocationDirectories = taskDeliverablesTree.directories[currentPathLocation];

        if (!currentPathLocationDirectories) {
            // No directories information for currentPathLocation in the tree

            return null;
        }

        const directories = Object.keys(currentPathLocationDirectories);

        if (directories.length === 0) {
            // No directories found

            return null;
        }

        return (
            <Grid container>
                {directories.map((directoryName) => (
                    <Grid className="mb-1" key={directoryName} lg={6}>
                        <TaskDeliverableDirectoryListItem
                            taskDeliverablesTree={taskDeliverablesTree}
                            directoryPath={currentPathLocation}
                            directoryName={directoryName}
                            onVisitDirectory={() => {
                                if (currentPathLocation === '/') {
                                    setSearchParams({ path: `${currentPathLocation}${directoryName}` });
                                } else {
                                    setSearchParams({ path: `${currentPathLocation}/${directoryName}` });
                                }
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderTaskDeliverablesAtPathLocation = () => {
        const currentPathLocationTaskDeliverables = taskDeliverablesTree.deliverables[currentPathLocation];

        if (!currentPathLocationTaskDeliverables) {
            // No task deliverable information for currentPathLocation in the tree

            return (
                <NonIdealState
                    className="mt-2"
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No deliverables found
                        </Heading>
                    }
                />
            );
        }

        const taskDeliverables = Object.values(currentPathLocationTaskDeliverables);

        switch (taskDeliverablesTreeView) {
            case TaskDeliverablesTreeView.LIST:
                return <TaskDeliverableListView
                    isApproveAllowed={isApproveAllowed}
                    isArchiveAllowed={isArchiveAllowed}
                    isRestoreAllowed={isRestoreAllowed}
                    taskDeliverables={taskDeliverables}
                />;
            case TaskDeliverablesTreeView.GRID: {
                return <TaskDeliverableListGridView
                    isApproveAllowed={isApproveAllowed}
                    isArchiveAllowed={isArchiveAllowed}
                    isRestoreAllowed={isRestoreAllowed}
                    taskDeliverables={taskDeliverables}
                />;
            }
        }
    };

    const renderTaskDeliverableTreeViewControls = () => {
        return (
            <>
                <Button
                    active={taskDeliverablesTreeView === TaskDeliverablesTreeView.LIST}
                    className="mr-1"
                    minimal
                    icon="th"
                    onClick={() => setTaskDeliverablesTreeView(TaskDeliverablesTreeView.LIST)}
                />

                <Button
                    active={taskDeliverablesTreeView === TaskDeliverablesTreeView.GRID}
                    minimal
                    icon="grid-view"
                    onClick={() => setTaskDeliverablesTreeView(TaskDeliverablesTreeView.GRID)}
                />
            </>
        );
    };

    const renderControlsMenu = () => {
        return (
            <Menu>
                <MenuItem icon="globe" text="Google Embed" onClick={handleCreateGoogleEmbedDeliverable} />
                <MenuItem icon="text-highlight" text="Text" onClick={handleCreateTextDeliverables} />
            </Menu>
        );
    };

    const renderControlsButton = () => {
        return (
            <ButtonGroup>
                <Button icon="upload" onClick={handleCreateFileDeliverable}>Upload files</Button>
                <Popover content={renderControlsMenu()}>
                    <Button rightIcon="chevron-down" />
                </Popover>
            </ButtonGroup>
        );
    };

    const renderControls = () => {
        const renderAddControls = () => {
            if (!isCreateAllowed) {
                return null;
            }

            return (
                <>
                    {renderControlsButton()}
                    <Button className="ml-1" icon="folder-new" onClick={handleCreateDirectory}>
                        Add new directory
                    </Button>
                </>
            );
        };

        return (
            <Flex className="mb-1" align="center" justify="flex-end">
                {renderAddControls()}
                <div className="ml-2">{renderTaskDeliverableTreeViewControls()}</div>
            </Flex>
        );
    };

    return (
        <div>
            {renderControls()}
            {renderFileTreePathBreadcrumbs()}
            <div className="mb-2">{renderDirectoriesAtPathLocation()}</div>
            {renderTaskDeliverablesAtPathLocation()}
        </div>
    );
};

export default TaskDeliverablesAll;
