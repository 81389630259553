import { $page, $coreUsers, $platformCoreUserFilterData, $isQueryHydrationFinished } from './states';
import { fetchCoreUsers } from './effects';
import { setIsQueryHydrationFinished, setPlatformCoreUserFilterData, setPage } from './events';

// Set page
$page.on(setPage, (_, data) => data);

// Fetch core users
$coreUsers.on(fetchCoreUsers.doneData, (_, data) => data);

// Set query hydration finished flag
$isQueryHydrationFinished.on(setIsQueryHydrationFinished, (_, data) => data);

// Set platform core user filter data
$platformCoreUserFilterData.on(setPlatformCoreUserFilterData, (_, data) => data);
