import { useCustomSelectFormField, useForm } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import { pricePlanVisibilityInformation } from '@data/consts';
import { ModalProps } from '@modals/types';
import { PlanType, PricePlanVisibility, PriceRecurringInterval } from 'dy-frontend-http-repository/lib/data/enums';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceRef } from 'dy-frontend-http-repository/lib/modules/Price/refs';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Price/resources';
import React, { useState } from 'react';
import { planApi } from '../../store/apis';
import { fetchPriceByExternalId, updatePriceToPlanAttachment } from '../../store/effects';
import PriceResolverInput, { PriceResolverInputValue } from '@components/PriceResolverInput';
import { PriceType } from 'dy-frontend-http-repository/lib/modules/Price/enums';

export interface CreatePlanToPriceAttachmentModalProps {
    planId: ID;
    planType: PlanType;
}

type Props = ModalProps<CreatePlanToPriceAttachmentModalProps>;

const planVisibilityValidators = [getStringRequiredValidator()];

const CreatePlanToPriceAttachmentModal: React.FC<Props> = ({ closeModal, data }) => {

    const planPriceVisibility = useCustomSelectFormField<string | null>({
        id: 'plan-price-visibility',
        validators: planVisibilityValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const [
        price,
        setPrice,
    ] = useState<PriceResolverInputValue | null>(null);

    const [isPriceLegacy, setIsPriceLegacy] = useState(false);

    const form = useForm<PriceRef>({
        fields: [planPriceVisibility],
        apiCall: async () => {
            if (!price) {
                // TODO: handle error
                throw new Error('Attempting to send form without price');
            }

            let priceResource: PriceResource | null = null;

            try {
                // Fetch plan price ID from external price ID
                priceResource = await fetchPriceByExternalId(price.externalId);
            } catch (e) {
                throw e;
            }

            try {
                // Create input
                const createPriceToPlanAttachmentInput: UpdatePricePlanAttachmentDataInput = {
                    plan_id: data!.planId,
                    is_legacy: isPriceLegacy,
                    plan_visibility: planPriceVisibility.value,
                };

                // Create price to plan attachment
                const priceRef = await updatePriceToPlanAttachment({
                    priceId: price.id,
                    input: createPriceToPlanAttachmentInput,
                });

                planApi.addPrice({ input: createPriceToPlanAttachmentInput, price: priceResource });

                return { response: priceRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Attach price to plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {/* Price */}
                    <FormGroup fill label="Price ID (Stripe ID)" className="mb-2">
                        <PriceResolverInput
                            value={price}
                            onChange={setPrice}
                            onViolationListRequested={(resolved) => {
                                // Ensure recurring
                                if (resolved.type !== PriceType.RECURRING) {
                                    return ['Price must be recurring'];
                                }

                                const violations: string[] = [];

                                // Not assigned to any other plan
                                if (resolved.planId !== null) {
                                    violations.push(resolved.planId === data.planId ?
                                        'Price already associated with this plan'
                                        : `Price associated with other plan - ${resolved.planTitle}`,
                                    );
                                }

                                // Plans of type 'seat' can only receive recurring prices with monthly schema
                                if (data.planType === PlanType.SEAT) {
                                    const isSeatRecurringSchemaCorrect = (
                                        resolved.recurringInterval === PriceRecurringInterval.MONTH
                                        && resolved.recurringIntervalCount === 1
                                    );
                                    if (!isSeatRecurringSchemaCorrect) {
                                        violations.push('Seat plans can only have prices with monthly payments');
                                    }
                                }

                                return violations;
                            }}
                        />
                    </FormGroup>

                    {/* Plan price visibility */}
                    <SelectFormField
                        items={Object.values(PricePlanVisibility)}
                        field={planPriceVisibility}
                        formGroupProps={{ label: 'Price visibility' }}
                        selectProps={{ selectButtonProps: { placeholder: 'Select price visibility' } }}
                        itemToReadableFormatter={(item) => pricePlanVisibilityInformation[item]}
                    />

                    {/* Is price legacy */}
                    <FormGroup>
                        <Checkbox
                            label="Price is legacy"
                            checked={isPriceLegacy}
                            onChange={() => {
                                setIsPriceLegacy((prevValue) => !prevValue);
                            }}
                        />
                    </FormGroup>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors || !price}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Attach
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreatePlanToPriceAttachmentModal;
