import { TeamRef } from 'dy-frontend-http-repository/lib/modules/Team/refs';
import { teamApi } from '../../store/apis';
import { updateTeamInformation } from '../../store/effects';

const useUpdateTeam = () => {
    const handleUpdateTeam = async ({
        teamId,
        title,
        color,
        summary,
        country,
        timezoneName,
        workingHoursStart,
        workingHoursEnd,
    }: {
        teamId: ID;
        title: string;
        color: string;
        summary: string;
        country: string;
        timezoneName: string;
        workingHoursStart: string;
        workingHoursEnd: string;
    }): Promise<TeamRef> => {
        try {
            const teamRef = await updateTeamInformation({
                teamId,
                input: {
                    title,
                    color,
                    summary,
                    country,
                    timezone_name: timezoneName,
                    working_hours_start: workingHoursStart,
                    working_hours_end: workingHoursEnd,
                },
            });

            teamApi.updateTeamInformation({
                title,
                summary,
                color: color,
                country,
                timezone_name: timezoneName,
                working_hours_start: workingHoursStart,
                working_hours_end: workingHoursEnd,
            });

            return teamRef;
        } catch (e) {
            throw e;
        }
    };

    return { updateTeam: handleUpdateTeam };
};

export default useUpdateTeam;
