import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import RichText from '@components/RichText';
import { Card } from '@blueprintjs/core';
import DevText from '@components/Text';
import TaskMessageDeliverableAttachments from '../TaskMessageDeliverableAttachments';
import TaskMessageFileAttachments from '../TaskMessageFileAttachments';

export interface BotMessageProps {
    isAuthorizedUserParticipant?: boolean;
    taskMessage: TaskMessageListItemResource;
}

export type Props = BotMessageProps & HTMLAttributes<HTMLDivElement>;

const BotMessage: React.FC<Props> = ({ isAuthorizedUserParticipant = false, taskMessage, ...props }) => {
    /*
     * TODO: think about what actions can be performed on BOT message
     * - remove
     */

    return (
        <Card {...props}>
            <div className="mb-small">
                <DevText muted>{moment(taskMessage.updated_at).format('D MMM YYYY, HH:mm')}</DevText>
            </div>

            <RichText value={taskMessage.content} />

            {/* Attached files */}
            <TaskMessageFileAttachments className="mt-2" attachments={taskMessage.files} />

            {/* Attached deliverables */}
            <TaskMessageDeliverableAttachments
                isAuthorizedUserParticipant={isAuthorizedUserParticipant}
                className="mt-2"
                taskId={taskMessage.task_id}
                attachments={taskMessage.task_deliverables}
            />
        </Card>
    );
};

export default BotMessage;
