import Box from '@app/components/Box';
import DevText from '@app/components/Text';
import { Colors, Icon, Intent, ProgressBar } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface OccupancyRateProps {
    interactive?: boolean;
    score: number;
}
export type Props = HTMLAttributes<HTMLDivElement> & OccupancyRateProps;

// Calculate percentage from total
const calculatePercentage = (value: number) => {
    // TODO: move ".toFixed(2).replace(/[.,]0+$/, '')" to utils, this code works like that "5.00" will show as "5", but "25.256457457", will be "25.25", same for "65.55", it'll be "65.55"
    return (value * 100).toFixed(2).replace(/[.,]0+$/, '');
};

const OccupancyRate: React.FC<Props> = ({ interactive = false, score, ...props }) => {
    // Get color & intent information
    let scoreColor = Colors.GREEN4;
    let occupancyRateIntent: Intent = Intent.SUCCESS;
    if (score > 0.49 && score < 0.8) {
        // Occupancy is medium
        occupancyRateIntent = Intent.WARNING;
        scoreColor = Colors.ORANGE4;
    } else if (score > 0.79) {
        // Occupancy is high
        occupancyRateIntent = Intent.DANGER;
        scoreColor = Colors.RED4;
    }

    const renderOccupancyOverloadedInformation = () => {
        if (score < 1.01) {
            return null;
        }

        return <Icon icon="warning-sign" color={scoreColor} />;
    };

    return (
        <Box cursor={interactive ? 'pointer' : 'default'} {...props}>
            <DevText className="mb-1" color={scoreColor}>
                {renderOccupancyOverloadedInformation()}
                {calculatePercentage(score)}%
            </DevText>
            <ProgressBar animate={false} stripes={false} intent={occupancyRateIntent} value={score} />
        </Box>
    );
};

export default OccupancyRate;
