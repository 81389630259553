import { Button, Card, Classes, Icon, Intent } from '@blueprintjs/core';
import ConfirmationPopover from '@components/ConfirmationPopover';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { openModal } from '@modals/store/events';
import { disableUserPasswordAuth } from '../../../../store/effects';
import { $userIdentity } from '../../../../store/states';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { userIdentityApi } from '../../../../store/apis';
import ChangeUserPasswordModal, {
    ChangeUserPasswordModalProps,
} from '@app/containers/components/UserSettings/modals/ChangeUserPasswordModal';
import SetupUserPasswordModal, {
    SetupUserPasswordModalProps,
} from '@app/containers/components/UserSettings/modals/SetupUserPasswordModal';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { ClientUserPermission, CoreUserPermission, SelfUserPermission } from 'dy-frontend-permissions/lib/permission';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

export type Props = HTMLAttributes<HTMLDivElement>;

const PasswordAuthControl: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    const isDisabling = useStore(disableUserPasswordAuth.pending);

    if (!identity || !me) {
        return null;
    }

    const isAllowed = {
        setup: false,
        update: false,
        updateForced: false,
        remove: false,
    };
    if (identity.user_id === me.user.id) {
        // Self permissions
        isAllowed.setup = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_PASSWORD_SETUP);
        isAllowed.update = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_PASSWORD_UPDATE);
        isAllowed.updateForced = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_PASSWORD_UPDATE_FORCED);
        isAllowed.remove = permissions.isRoot.selfUser || permissions.has(SelfUserPermission.AUTH_PASSWORD_REMOVE);

    } else if ((identity.type === UserType.CLIENT) && permissions.isEnabled.clientUser) {
        // Self permissions
        isAllowed.setup = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_PASSWORD_SETUP);
        isAllowed.update = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_PASSWORD_UPDATE);
        isAllowed.updateForced = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_PASSWORD_UPDATE_FORCED);
        isAllowed.remove = permissions.isRoot.clientUser || permissions.has(ClientUserPermission.AUTH_PASSWORD_REMOVE);

    } else if ((identity.type === UserType.CORE) && permissions.isEnabled.coreUser) {
        // Self permissions
        isAllowed.setup = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_PASSWORD_SETUP);
        isAllowed.update = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_PASSWORD_UPDATE);
        isAllowed.updateForced = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_PASSWORD_UPDATE_FORCED);
        isAllowed.remove = permissions.isRoot.coreUser || permissions.has(CoreUserPermission.AUTH_PASSWORD_REMOVE);
    }

    const handleDisable = async () => {
        try {
            await disableUserPasswordAuth(identity.user_id);
            userIdentityApi.disablePasswordAuth();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    // Renders control card for this auth method, when method is set up
    const renderIsSetupStateControlCard = () => {
        const renderDisableButton = () => {
            if (!isAllowed.remove) {
                return null;
            }

            return (
                <ConfirmationPopover
                    title="Disable password authentication?"
                    description="When confirmed, user won't be able to login using password"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleDisable}
                        >
                            Yes, disable password
                        </Button>,
                    ]}
                >
                    <Button minimal outlined={isDisabling} loading={isDisabling} disabled={isDisabling}
                            intent={Intent.DANGER}>
                        Disable
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderUpdateButton = () => {
            if (!isAllowed.update && !isAllowed.updateForced) {
                return null;
            }

            return (
                <Button
                    minimal
                    className="mr-1"
                    disabled={isDisabling}
                    onClick={() =>
                        openModal<ChangeUserPasswordModalProps>({
                            ModalComponent: ChangeUserPasswordModal,
                            data: {
                                userId: identity.user_id,
                                workspaceId: identity.workspace_id,
                                userEmail: identity.email,
                                isResetAllowed: isAllowed.update,
                                isForcedAllowed: isAllowed.updateForced,
                            },
                        })
                    }
                >
                    Change password
                </Button>
            );
        };

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="tick-circle" className="mr-1" intent={Intent.SUCCESS} size={15} />
                        <Heading type="h4">Password</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderUpdateButton()}
                        {renderDisableButton()}
                    </Flex>
                </Flex>
                <DevText muted>This user can login using account email and password</DevText>
            </Card>
        );
    };

    // Renders control card when auth is not set up
    const renderNotSetupStateControlCard = () => {
        const renderSetupButton = () => {
            if (!isAllowed.setup) {
                return null;
            }

            return (
                <Button
                    minimal
                    intent={Intent.PRIMARY}
                    onClick={() =>
                        openModal<SetupUserPasswordModalProps>({
                            ModalComponent: SetupUserPasswordModal,
                            data: {
                                userId: identity.user_id,
                                onSetupUserPasswordAuthSuccess: () => userIdentityApi.enablePasswordAuth(),
                            },
                        })
                    }
                >
                    Setup
                </Button>
            );
        };

        return (
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Flex align="center">
                        <Icon icon="cross-circle" className="mr-1" size={15} />
                        <Heading type="h4">Password</Heading>
                    </Flex>
                    <Flex align="center">
                        {renderSetupButton()}
                    </Flex>
                </Flex>
                <DevText muted>Password-based authentication is not setup</DevText>
            </Card>
        );
    };

    return <>{identity.is_password_auth_setup ? renderIsSetupStateControlCard() : renderNotSetupStateControlCard()}</>;
};

export default PasswordAuthControl;
