import React, { HTMLAttributes } from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import DevText from '@components/Text';
import Heading from '@components/Heading';
import { QuotaMergeMode } from 'dy-frontend-http-repository/lib/data/enums';
import { VirtualLicenceQuotaAttachmentResource } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import { quotaTypeInformation } from '@app/data/consts';

export interface VirtualLicenceQuotaListProps {
    quotas: VirtualLicenceQuotaAttachmentResource[];
}

export type Props = HTMLAttributes<HTMLDivElement> & VirtualLicenceQuotaListProps;

// TODO: make in reusable, same used on ClientLicence page
const VirtualLicenceQuotaList: React.FC<Props> = ({ quotas, ...props }) => {
    const renderQuotaLabel = (quota: VirtualLicenceQuotaAttachmentResource) => {
        const quotaTypeLabel = quotaTypeInformation[quota.type] ?? 'Unknown quota';

        switch (quota.merge_mode) {
            case QuotaMergeMode.SET:
                return (
                    <DevText>
                        Set "{quotaTypeLabel}" to {quota.value}
                    </DevText>
                );
                break;
            case QuotaMergeMode.SUM:
                return (
                    <DevText>
                        Add {quota.value} to "{quotaTypeLabel}"
                    </DevText>
                );
                break;
            default:
                return (
                    <DevText>
                        {quota.merge_mode} "{quota.type}" to "{quota.value}"
                    </DevText>
                );
        }
    };

    const renderQuota = (quota: VirtualLicenceQuotaAttachmentResource) => {
        return (
            <Card compact elevation={Elevation.ONE}>
                {renderQuotaLabel(quota)}
            </Card>
        );
    };

    const renderQuotaList = () => {
        if (!quotas.length) {
            return (
                <div className="mt-1">
                    <DevText muted>No quota adjustments added...</DevText>
                </div>
            );
        }

        return quotas.map((quota, index) => (
            <div key={`quota-${quota.type}`} className={index === 0 ? '' : 'mt-1'}>
                {renderQuota(quota)}
            </div>
        ));
    };

    return (
        <div {...props}>
            <Heading type="h6" className="mb-small">
                Quota list / Limitations
            </Heading>
            <DevText muted className="mb-1">
                List of quota customizations
            </DevText>

            {renderQuotaList()}
        </div>
    );
};

export default VirtualLicenceQuotaList;
