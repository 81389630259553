import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';

/**
 * Check if subscription pause scheduled
 * @param subscription subscription for which pause schedule should be checked
 * @returns subscription pause scheduled flag
 */
export default function checkIfSubscriptionPauseScheduled(subscription: SubscriptionResource) {
    return subscription.pause_schedule.is_active;
}
