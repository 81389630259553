import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { $selectedTaskDeliverable } from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import { TaskDeliverableGoogleEmbed } from '@pages/Task/pages/TaskDeliverables/valueObjects';
import { Wrapper, Iframe, SpinnerWrapper } from './styled';
import { Spinner } from '@blueprintjs/core';

// Docs - https://docs.google.com/document/d/e/2PACX-1vS9TLBu3ZPdZ9y0k0kbUXdU_QX9bffJPVxl9kxo_gJU85aLZQ2XVEEPmV__Hu0l9ZqMaSl5iPCFYT6Z/pub?embedded=true
// GSheets - https://docs.google.com/spreadsheets/d/e/2PACX-1vTJsJOzkPsKJNd1oZZaRGvlyCn1fk9DEAe7VszIu5xDFdfy-Kaly0R6g_A_4grN-Xw2ZEbFxQihx_FE/pubhtml?widget=true&amp;headers=false
// Slides - https://docs.google.com/presentation/d/e/2PACX-1vQjrOBcBf8cqMEDSUyJkMI7UXoKrPYXZP-UWByTKBdO0iokFoL07WQd-SswMmbX6QVj0pvdsbPAU97k/embed?start=false&loop=false&delayms=3000
// Folders - https://drive.google.com/drive/folders/1koRop_v6Mq9Z54Ar4pBI9_Iskg5QWRdt?usp=sharing

const TaskDeliverableGoogleDriveViewer: React.FC = () => {
    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    const [isContentLoaded, setIsContentLoaded] = useState(false);

    if (!selectedTaskDeliverable || !selectedTaskDeliverable.url) {
        return null;
    }

    const link = new TaskDeliverableGoogleEmbed(selectedTaskDeliverable.url).getViewerLink();

    return (
        <Wrapper>
            <Iframe
                id="google-drive-iframe"
                style={{ display: isContentLoaded ? 'block' : 'none' }}
                onLoad={() => setIsContentLoaded(true)}
                src={link}
            />

            {!isContentLoaded && (
                <SpinnerWrapper>
                    <Spinner size={50} />
                </SpinnerWrapper>
            )}
        </Wrapper>
    );
};

export default TaskDeliverableGoogleDriveViewer;
