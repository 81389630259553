import { createApi } from 'effector';
import { $taxIds } from './states';

// API to manage tax IDs
export const taxIdsApi = createApi($taxIds, {
    remove: (store, payload: ID) => {
        if (!store) {
            // Tax IDs are NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: store.items.filter((taxId) => taxId.id !== payload),
        };
    },
});
