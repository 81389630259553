import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodResult, SetupIntentResult } from '@stripe/stripe-js';
import repository from 'dy-frontend-http-repository/lib/repository';
import { PaymentAccountSetupIntentResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { declineCodeInformation } from 'dy-frontend-shared/lib/data/consts';
import { ToastUtils } from '@app/data/utils';
import { Intent } from '@blueprintjs/core';

export interface UseCreatePaymentMethod {
    paymentAccountId: ID;
    delay?: number;
    onCreatePaymentMethod: (externalPaymentMethodId: string) => Promise<any>;
}

export type Props = UseCreatePaymentMethod;

const useCreatePaymentMethod = ({ paymentAccountId, delay = 3000, onCreatePaymentMethod }: Props) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleCreatePaymentMethod = async () => {
        try {
            if (!stripe) {
                // Stripe is NOT initialized
                throw new Error('Stripe is not initialized');
            }

            if (!elements) {
                // Elements do NOT exist
                throw new Error('Stripe elements do not exist');
            }

            // Get elements
            const cardNumber = elements.getElement(CardNumberElement);
            elements.getElement(CardExpiryElement);
            elements.getElement(CardCvcElement);

            if (!cardNumber) {
                // Stripe card number is not valid
                throw new Error('Stripe card number do not exist');
            }

            // Create payment method
            const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumber,
            });
            if (paymentMethodError) {
                throw paymentMethodError;
            }

            // Get client secret
            let paymentAccountSetupIntent: PaymentAccountSetupIntentResource;
            try {
                paymentAccountSetupIntent = await repository.paymentAccount().createSetupIntent(paymentAccountId);
            } catch (e) {
                // Ignore HTTP error
                return;
            }

            // Confirm card setup
            const { error: cardSetupError } = await stripe.confirmCardSetup(paymentAccountSetupIntent.client_secret, {
                payment_method: paymentMethod.id,
            });
            if (cardSetupError) {
                throw cardSetupError;
            }

            await new Promise((resolve, reject) => {
                setTimeout(async () => {
                    try {
                        onCreatePaymentMethod(paymentMethod.id);
                    } catch (e) {
                        reject(e);
                    }

                    resolve(true);
                }, delay);
            });
        } catch (e) {
            // Log
            console.error(e);

            const errorDeclineCode: string | undefined | null = (e as any).decline_code;
            if (errorDeclineCode) {
                // Get message by decline code
                const declineCodeMessageInformation = declineCodeInformation[errorDeclineCode];
                if (declineCodeMessageInformation.message) {
                    // Existing decline code
                    ToastUtils.showToast({ message: declineCodeMessageInformation.message, intent: Intent.DANGER });
                    return;
                }
            }

            // Not existing decline code
            ToastUtils.showToast({ message: 'Unknown payment error occurred, code #1404', intent: Intent.DANGER });
        }
    };

    return { createPaymentMethod: handleCreatePaymentMethod };
};

export default useCreatePaymentMethod;
