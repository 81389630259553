import domain from './domain';
import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Price/resources';
import {
    UpdateAttachedTaskCategoryGroupsInput,
    UpdateTaskCategoryInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import {
    TaskCategoryIconHashRef,
    TaskCategoryImageHashRef,
    TaskCategoryRef,
} from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import {
    CreateTaskCategoryAddonInput,
    TaskCategoryAddonFilterInput,
    UpdateTaskCategoryAddonInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/inputs';
import { TaskCategoryAddonRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/refs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import { TaskCategoryGroupListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryGroup/resources';

// Fetch task category
export const fetchTaskCategory = domain.createEffect<ID, TaskCategoryResource>({
    name: 'fetchTaskCategory',
    handler: async (id) => {
        return await repository.taskCategory().getById(id);
    },
});

// Fetch task category groups
export const fetchTaskCategoryGroups = domain.createEffect<
    void,
    CollectionResource<TaskCategoryGroupListItemResource, {}>
>({
    name: 'fetchTaskCategoryGroups',
    handler: async () => {
        return await repository.taskCategoryGroup().get();
    },
});

// Fetch price by external ID
export const fetchPriceByExternalId = domain.createEffect<string, PriceResource>({
    name: 'fetchPriceByExternalId',
    handler: async (externalId) => {
        return await repository.price().getByExternalId(externalId);
    },
});

// Update task category
export const updateTaskCategory = domain.createEffect<{ id: ID; input: UpdateTaskCategoryInput }, TaskCategoryRef>({
    name: 'updateTaskCategory',
    handler: async ({ id, input }) => {
        return await repository.taskCategory().update(id, input);
    },
});

// Update task category attached groups
export const updateAttachedTaskCategoryGroups = domain.createEffect<
    { taskCategoryId: ID; input: UpdateAttachedTaskCategoryGroupsInput },
    TaskCategoryRef
>({
    name: 'updateAttachedTaskCategoryGroups',
    handler: async ({ taskCategoryId, input }) => {
        return await repository.taskCategory().updateAttachedTaskCategoryGroups(taskCategoryId, input);
    },
});

// Remove task category
export const removeTaskCategory = domain.createEffect<ID, TaskCategoryRef>({
    name: 'removeTaskCategory',
    handler: async (id) => {
        return await repository.taskCategory().archive(id);
    },
});

// Restore task category
export const restoreTaskCategory = domain.createEffect<ID, TaskCategoryRef>({
    name: 'restoreTaskCategory',
    handler: async (id) => {
        return await repository.taskCategory().restore(id);
    },
});

/* Addons */

// Fetch task category addons
export const fetchTaskCategoryAddons = domain.createEffect<
    TaskCategoryAddonFilterInput,
    CollectionResource<TaskCategoryAddonResource, {}>
>({
    name: 'fetchTaskCategoryAddons',
    handler: async (input) => {
        return await repository.taskCategoryAddon().get(input);
    },
});

// Create new task category addon
export const createTaskCategoryAddon = domain.createEffect<CreateTaskCategoryAddonInput, TaskCategoryAddonRef>({
    name: 'createTaskCategoryAddon',
    handler: async (input) => {
        return await repository.taskCategoryAddon().create(input);
    },
});

// Update task category addon
export const updateTaskCategoryAddon = domain.createEffect<
    { taskCategoryAddonId: ID; input: UpdateTaskCategoryAddonInput },
    TaskCategoryAddonRef
>({
    name: 'updateTaskCategoryAddon',
    handler: async ({ taskCategoryAddonId, input }) => {
        return await repository.taskCategoryAddon().update(taskCategoryAddonId, input);
    },
});

// Remove task category addon
export const removeTaskCategoryAddon = domain.createEffect<ID, TaskCategoryRef>({
    name: 'removeTaskCategoryAddon',
    handler: async (taskCategoryAddonId) => {
        return await repository.taskCategoryAddon().archive(taskCategoryAddonId);
    },
});

// Restore task category addon
export const restoreTaskCategoryAddon = domain.createEffect<ID, TaskCategoryRef>({
    name: 'restoreTaskCategoryAddon',
    handler: async (taskCategoryAddonId) => {
        return await repository.taskCategoryAddon().restore(taskCategoryAddonId);
    },
});

// Upload task category image
// TODO: move?
export const uploadAndSetTaskCategoryImage = domain.createEffect<
    { taskCategoryId: ID; file: File },
    TaskCategoryImageHashRef
>({
    name: 'uploadAndSetTaskCategoryImage',
    handler: async ({ taskCategoryId, file }) => {
        return await repository.taskCategory().uploadAndSetImage(taskCategoryId, file);
    },
});

// Upload task category image
// TODO: move?
export const uploadAndSetTaskCategoryIcon = domain.createEffect<
    { taskCategoryId: ID; file: File },
    TaskCategoryImageHashRef
>({
    name: 'uploadAndSetTaskCategoryIcon',
    handler: async ({ taskCategoryId, file }) => {
        return await repository.taskCategory().uploadAndSetIcon(taskCategoryId, file);
    },
});

// Remove task category image
// TODO: move?
export const removeTaskCategoryImage = domain.createEffect<ID, TaskCategoryRef>({
    name: 'removeTaskCategoryImage',
    handler: async (id) => {
        return await repository.taskCategory().removeImage(id);
    },
});

// Remove task category icon
// TODO: move?
export const removeTaskCategoryIcon = domain.createEffect<ID, TaskCategoryRef>({
    name: 'removeTaskCategoryIcon',
    handler: async (id) => {
        return await repository.taskCategory().removeIcon(id);
    },
});
